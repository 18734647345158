import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export class PlanName extends Component {
  static propTypes = {
    plan: PropTypes.object,
  }

  static defaultProps = {
    plan: {},
  }

  decorateInterval = () => {
    const { plan_interval_length: interval } = this.props.plan

    if (interval === 12) {
      return 'Yearly'
    }
    return interval === 1 ? 'Monthly' : `${interval} Month`
  }

  decoratePlan = () => {
    const { plan_type } = this.props.plan

    switch (plan_type) {
      case 'first_member':
        return `FIRST - ${this.decorateInterval()}`
      default:
        return 'Basic Account'
    }
  }

  render() {
    return <span className='plan-name qa-plan-name'>{this.decoratePlan()}</span>
  }
}

export default PlanName
