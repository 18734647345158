import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { AvatarLink, FollowBtn } from 'common/components'
import { Badges, PostActionsContainer } from 'common/containers'
import { Sticky } from 'communityApp/components'
import {
  toDestinationPath,
  toDestinationString,
  hasRole,
  postTimeFromNow,
  ROLE_ADMIN,
  ROLE_GLOBAL_MODERATOR,
} from 'common/helpers'
import { PostContext } from './index'

export default function PostHeader() {
  return (
    <PostContext.Consumer>
      {({
        featureFlags,
        getPermalink,
        isModerator,
        isReply,
        isRepost,
        post,
        preventFullPost,
        repost,
        showFollowBtn,
        showMuted,
        sticky,
        toggleMute,
        user,
      }) => {
        let {
          author,
          created_at: createdAt,
          deleted_at: deletedAt,
          shadowbanned,
          updated_at: editedAt,
          owner,
        } = post
        author = author || {}
        const ownerName = author.name
        const ownerText = toDestinationString(owner, user)
        const ownerLink = toDestinationPath(owner)
        if (post && post.repostId && !post.body) {
          return (
            <div className='post-header simple-repost'>
              <i className='icon icon-repeat' />
              <Link onClick={preventFullPost} to={`/g/user/${ownerName}`}>
                <span className='reposter'>{ownerName}</span>
              </Link>
              <span className='reposted'>Reposted to</span>
              <Link
                to={ownerLink}
                className='post-link post-feed'
                onClick={preventFullPost}
              >
                {ownerText}
              </Link>
              {shadowbanned && (
                <span className='shadowbanned-badge'>shadowbanned</span>
              )}
              {!isRepost && (
                <div
                  className='post-header-right'
                  onClick={e => preventFullPost(e, false)}
                >
                  {sticky && <Sticky post={post} />}
                  <PostActionsContainer post={post} />
                </div>
              )}
            </div>
          )
        }

        // ------
        // Render
        // ------
        return (
          <div className='post-header'>
            <AvatarLink
              className='post-avatar'
              name={author.name}
              onClick={preventFullPost}
              src={author.profile_picture}
            />
            <div>
              <div className='post-name'>
                <Link
                  className='post-link'
                  to={`/g/user/${ownerName}`}
                  onClick={preventFullPost}
                >
                  {ownerName}
                </Link>
                <Badges
                  isModerator={isModerator}
                  user={author}
                  context='community'
                />
                {shadowbanned && (
                  <span className='shadowbanned-badge'>shadowbanned</span>
                )}
              </div>
              <div className='post-meta'>
                <Link
                  to={getPermalink()}
                  className='post-link post-age'
                  onClick={preventFullPost}
                >
                  {postTimeFromNow(createdAt)}
                </Link>
                {ownerText && (
                  <span>
                    <span className='post-meta__separator'>&bull;</span>
                    <Link
                      to={ownerLink}
                      className='post-link post-feed'
                      onClick={preventFullPost}
                    >
                      {ownerText}
                    </Link>
                  </span>
                )}
                {editedAt !== createdAt ? (
                  <span
                    className='comment-edited'
                    title={'last edited ' + moment(editedAt).fromNow()}
                  >
                    *
                  </span>
                ) : (
                  ''
                )}
                {(hasRole(ROLE_ADMIN, user) ||
                  hasRole(ROLE_GLOBAL_MODERATOR, user)) &&
                  deletedAt && <span className='deleted-badge'>deleted</span>}
              </div>
            </div>
            <div
              className='post-header-right'
              onClick={e => preventFullPost(e, false)}
            >
              {showFollowBtn && (
                <FollowBtn
                  userUuid={String(author.id)}
                  className='qa-besties-follow-btn'
                />
              )}
              {showMuted && (
                <div className='muted-controls'>
                  <div>
                    <i className='icon icon-eye-slash muted-icon' />
                    You muted this User
                  </div>
                  <span onClick={toggleMute} className='post-link'>
                    View Post
                  </span>
                </div>
              )}
              {sticky && <Sticky post={post} />}
              {!isRepost && <PostActionsContainer post={post} />}
            </div>
          </div>
        )
      }}
    </PostContext.Consumer>
  )
}
