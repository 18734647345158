import log from './log'
import community from './community'
import related from './related'
import socialMedia from './social-media'
import achievements from './achievements'
import chat from './chat'
import { combineReducers } from 'redux'

function slug(state = '', { type, slug }) {
  if (type === 'communityApp/subCommunity/slug') {
    return slug
  } else {
    return state
  }
}

function id(state = '', { type, data }) {
  if (type === 'communityApp/subCommunity_RESP') {
    return data.id
  } else {
    return state
  }
}

export default combineReducers({
  log,
  slug,
  id,
  community,
  related,
  socialMedia,
  achievements,
  chat,
})
