import { combineReducers } from 'redux'

import {
  ADD_MESSAGE,
  ADD_ROOM,
  BLOCKED_USER_ADD_SUCCESS,
  BLOCKED_USER_REMOVE_SUCCESS,
  BLOCKED_USERS_FAILURE,
  BLOCKED_USERS_REQUEST,
  BLOCKED_USERS_SUCCESS,
  CHANGE_ROOM,
  LOAD_MESSAGES,
  LOAD_MESSAGES_FAIL,
  LOAD_MESSAGES_SUCCESS,
  LOAD_ROOMS,
  LOAD_ROOMS_FAIL,
  LOAD_ROOMS_SUCCESS,
  MUTED_USER_ADD_SUCCESS,
  MUTED_USER_REMOVE_SUCCESS,
  MUTED_USERS_FAILURE,
  // v2
  MUTED_USERS_REQUEST,
  MUTED_USERS_SUCCESS,
  RECEIVE_MESSAGE,
  REMOVE_ROOM,
} from '../actions'

const activeRoom = (
  state = {
    name: 'Lobby',
    type: 'official',
  },
  action
) => {
  switch (action.type) {
    case CHANGE_ROOM:
      return {
        name: action.room.name,
        type: action.room.type,
      }

    default:
      return state
  }
}

const rooms = (
  state = {
    loaded: false,
    data: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_ROOM:
      if (
        state.data.filter(room => room.name === action.room.name).length !== 0
      ) {
        return state
      }
      return { ...state, data: [...state.data, action.room] }
    case REMOVE_ROOM:
      return {
        ...state,
        data: state.data.filter(room => room.name !== action.room.name),
      }
    case LOAD_ROOMS:
      return { ...state, loaded: false, loading: true }
    case LOAD_ROOMS_SUCCESS:
      return { ...state, loading: false, loaded: true, data: [...action.json] }
    case LOAD_ROOMS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: [...state.data],
      }
    default:
      return state
  }
}

const messages = (
  state = {
    loaded: false,
    data: [],
    fetchHistory: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state, data: [...state.data, action.message] }
    case RECEIVE_MESSAGE:
      return { ...state, data: [...state.data, action.message] }
    case LOAD_MESSAGES:
      return { ...state, loaded: false, loading: true }
    case LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        fetchHistory: [
          ...state.fetchHistory,
          { lastFetch: action.date, roomName: action.room },
        ],
        data: [...action.json],
      }
    case LOAD_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        data: [...state.data],
      }
    default:
      return state
  }
}

// v2

const users = (
  state = {
    blocked: [],
    muted: [],
  },
  action
) => {
  switch (action.type) {
    case MUTED_USERS_REQUEST:
      return { ...state, muted: [] }
    case MUTED_USERS_FAILURE:
      return { ...state, muted: [] }
    case MUTED_USERS_SUCCESS:
      return { ...state, muted: action.userIds }
    case MUTED_USER_ADD_SUCCESS:
      return { ...state, muted: [...state.muted, action.userId] }
    case MUTED_USER_REMOVE_SUCCESS:
      return { ...state, muted: state.muted.filter(m => m !== action.userId) }
    case BLOCKED_USERS_REQUEST:
      return { ...state, blocked: [] }
    case BLOCKED_USERS_FAILURE:
      return { ...state, blocked: [] }
    case BLOCKED_USERS_SUCCESS:
      return { ...state, blocked: action.userIds }
    case BLOCKED_USER_ADD_SUCCESS:
      return { ...state, blocked: [...state.blocked, action.userId] }
    case BLOCKED_USER_REMOVE_SUCCESS:
      return {
        ...state,
        blocked: state.blocked.filter(m => m !== action.userId),
      }
    default:
      return state
  }
}

const chatReducer = combineReducers({
  activeRoom,
  rooms,
  messages,
  users,
})

export default chatReducer
