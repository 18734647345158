import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Stars = ({ rating }) => {
  const fullStars = Math.floor(rating)
  const halfStars = rating - fullStars
  const remainStars = 5 - (fullStars + (halfStars > 0.2 ? 1 : 0))
  let key = 0
  const html = []
  for (let i = 0; i < fullStars; i += 1) {
    html.push(<FullStar key={(key += 1)} />)
  }
  if (halfStars > 0.2) {
    html.push(<HalfStar key={(key += 1)} />)
  }
  for (let i = 0; i < remainStars; i += 1) {
    html.push(<EmptyStar key={(key += 1)} />)
  }
  return <div className='star-rating'>{html}</div>
}

export const FullStar = () => <i className='icon-heart_filled' />

export const HalfStar = () => <i className='icon-heart_half' />

export const EmptyStar = () => <i className='icon-heart_empty' />

Stars.propTypes = {
  rating: PropTypes.number,
}
Stars.defaultProps = {
  rating: null,
}

export default Stars
