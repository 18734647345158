import rtApi from '@roosterteethproductions/svod-api'

// Thin wrapper around rtApi login functions. These funcs all accomplish the same thing, but with different IDPs.
const getRtApiLoginFuncFromIdp = serviceId => {
  if (serviceId === 'apple') {
    return token => rtApi.auth.continueWithApple({ code: token })
  }
  if (serviceId === 'facebook') {
    return token => rtApi.auth.loginFacebookV2({ token })
  }
  if (serviceId === 'google') {
    return token => rtApi.auth.loginGoogle({ token })
  }

  // If no valid serviceId was given, return no function.
  return false
}

export default getRtApiLoginFuncFromIdp
