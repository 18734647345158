import { createSelector } from '@reduxjs/toolkit'

export const getLivestreams = state =>
  state.commonReducer.livestreams.livestreams
export const getVideo = state => state.commonReducer.video
export const getVideos = state => getVideo(state).videos

export const getLivestreamBySlug = (state, slug) => {
  if (!slug) return
  return getLivestreams(state).find(
    livestream => livestream.attributes.slug === slug
  )
}

export const getVideoBySlug = (state, slug) => {
  if (!slug) return
  return getVideos(state).find(video => {
    if (Object.keys(video).length !== 0) {
      return video.attributes.content_slug === slug
    }
    return false
  })
}

export const getFeatureFlags = state => state.commonReducer.featureFlags

export const getURLSearch = state => state.router.location.search
export const getURLSearchObj = createSelector(
  getURLSearch,
  search => new URLSearchParams(search)
)
