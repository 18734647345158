import * as t from './constants'

const getAnalyticsTabName = tab =>
  ({
    [t.TAB_ALL_RESULTS]: 'all_results',
    [t.TAB_SERIES]: 'series',
    [t.TAB_EPISODES]: 'episodes',
    [t.TAB_BONUS_FEATURES]: 'bonus_features',
    [t.TAB_GROUPS]: 'groups',
    [t.TAB_USERS]: 'users',
    recommended: 'recommended',
  }[tab])

export default getAnalyticsTabName
