// useDateBasedFeatureFlag.js
import { propOr } from 'ramda'
import { useSelector } from 'react-redux'

import { getFeatureFlags } from 'common/selectors'

const useDateBasedFeatureFlag = (featureFlag, endDate = null) => {
  const featureFlags = useSelector(getFeatureFlags)
  const flagEnabled = !!propOr(false, featureFlag, featureFlags)

  // Convert the dates to the US/Central timezone
  const currentDate = new Date().toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  })
  const startDateTime = new Date(flagEnabled).toLocaleString('en-US', {
    timeZone: 'America/Chicago',
  })
  const endDateTime = endDate
    ? new Date(endDate).toLocaleString('en-US', { timeZone: 'America/Chicago' })
    : null

  // Check if the current date falls within the specified range
  const withinDateRange =
    new Date(currentDate) >= new Date(startDateTime) &&
    (!endDateTime || new Date(currentDate) <= new Date(endDateTime))

  return flagEnabled && withinDateRange
}

export default useDateBasedFeatureFlag
