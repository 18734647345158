/** **********************
 *
 *  Validation
 *
 ************************ */

export const getInputPattern = pattern =>
  pattern.toString().replace(/(^\/|\/$)/g, '')

// ----- Email ----- //

export const emailPattern = /^([\w.\-+]*?)@([\w-]+)\.([\w.-]*?)[0-9a-zA-Z]$/
export const emailInputPattern = getInputPattern(emailPattern)
export const emailInputError = 'Enter a valid email address'

export const emailAttributes = {
  autoCapitalize: 'off',
  autoComplete: 'email',
  autoCorrect: 'off',
  pattern: emailInputPattern,
  spellCheck: 'false',
}

// ----- Password ----- //

export const confirmPasswordError = 'The passwords don’t match'
export const passwordError = 'Enter 6 or more characters'
export const passwordMinLength = 6

export const passwordAttributes = {
  autoComplete: 'password',
  minLength: passwordMinLength,
}

export const newPasswordAttributes = {
  ...passwordAttributes,
  autoComplete: 'new-password',
}

// ----- Pin ----- //

export const pinPattern = /^[ABCDEFHJKLMNPRTUVWXYabcdefhjklmnprtuvwxy346789]{5}$/
export const pinInputPattern = getInputPattern(pinPattern)
export const pinInputError = 'Enter a valid pin'

export const pinAttributes = {
  autoCapitalize: 'on',
  autoComplete: 'off',
  autoCorrect: 'off',
  pattern: pinInputPattern,
  spellCheck: 'false',
}

// ----- Username ----- //

export const usernameAttributes = {
  autoCapitalize: 'off',
  autoComplete: 'username',
  autoCorrect: 'off',
  minLength: 3,
  spellCheck: 'false',
}

export const usernameError = 'Enter a valid username'
