import rtApi from '@roosterteethproductions/svod-api'

import { config, endpoints, refreshTokenKey } from 'common/config'
import { jsonHeaders, noCache } from 'common/headers'
import {
  getCookie,
  handleErrors,
  HTTP_RT_INVALID_UPSTREAM_API_TOKEN,
  setAccessTokenCookie,
  setRefreshTokenCookie,
} from 'common/helpers'
import { initialState } from 'common/reducers/featureFlagReducer'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  POST_PIN_AUTH_FAILURE,
  POST_PIN_AUTH_REQUEST,
  POST_PIN_AUTH_SUCCESS,
  POST_REFRESH_TOKEN_FAILURE,
  POST_REFRESH_TOKEN_REQUEST,
  POST_REFRESH_TOKEN_SUCCESS,
  POST_SHOPIFY_SESSION_FAILURE,
  POST_SHOPIFY_SESSION_REQUEST,
} from './types'

// Refresh Token Actions

const getRefreshTokenRequest = () => ({
  type: POST_REFRESH_TOKEN_REQUEST,
})

const getRefreshTokenFail = error => ({
  type: POST_REFRESH_TOKEN_FAILURE,
  error,
})

const getRefreshTokenSuccess = () => ({
  type: POST_REFRESH_TOKEN_SUCCESS,
})

let activePromise

// NOTE: this functionality is only used in community/sagas
// rtApi has this functioality built in so you will never have to manually refresh tokens
export function refreshTokenFetch() {
  if (activePromise) {
    return activePromise
  }

  const refreshBody = {
    client_id: config.auth.clientId,
    grant_type: 'refresh_token',
    refresh_token: getCookie(refreshTokenKey),
  }

  if (initialState.noLegacyAuth) {
    delete refreshBody.refresh_token
  }

  activePromise = fetch(`${endpoints.auth}/oauth/token`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: { ...jsonHeaders, ...noCache },
    body: JSON.stringify(refreshBody),
  })
    .then(response => {
      const clonedResponse = response.clone()
      return clonedResponse.json().then(json => {
        if (response.ok) {
          const { access_token, refresh_token } = json
          setAccessTokenCookie(access_token)
          setRefreshTokenCookie(refresh_token)
        }
        return response
      })
    })
    .then(
      r => {
        activePromise = null
        return r
      },
      e => {
        activePromise = null
        return Promise.reject(e)
      }
    )

  return activePromise
}

// NOTE: this functionality is only used in community/sagas
// rtApi has this functioality built in so you will never have to manually refresh tokens
export const refreshToken = () => (dispatch, _getState) => {
  dispatch(getRefreshTokenRequest())

  return refreshTokenFetch()
    .then(handleErrors)
    .then(_json =>
      // console.log('getRefreshToken success', json)
      // Dispatch the success action
      Promise.resolve(dispatch(getRefreshTokenSuccess()))
    )
    .catch(ex => {
      // console.log('getRefreshToken fail', ex)
      dispatch(getRefreshTokenFail(ex))
      return Promise.reject(new Error())
    })
}

// Shopify Session Actions

const getShopifySessionRequest = storeReturnTo => ({
  type: POST_SHOPIFY_SESSION_REQUEST,
  storeReturnTo,
})

const getShopifySessionFail = error => ({
  type: POST_SHOPIFY_SESSION_FAILURE,
  error,
})

export const shopifySession = store_return_to => (dispatch, _getState) => {
  // We dispatch requestLogin to kickoff the call to the API
  dispatch(getShopifySessionRequest(store_return_to))
  return rtApi.business
    .fetchShopifySession({ store_return_to })
    .then(json => json.url)
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      dispatch(getShopifySessionFail(ex))
      return Promise.reject(new Error())
    })
}

// Pin Auth

const getPinAuthRequest = pin => ({
  type: POST_PIN_AUTH_REQUEST,
  pin,
})

const getPinAuthFail = error => ({
  type: POST_PIN_AUTH_FAILURE,
  error,
})

const getPinAuthSuccess = () => ({
  type: POST_PIN_AUTH_SUCCESS,
})

export const pinAuth = (pin, errorCallback) => (dispatch, _getState) => {
  dispatch(getPinAuthRequest(pin))
  return rtApi.auth
    .activatePin({ pin, errorCallback })
    .then(() => {
      Promise.resolve(dispatch(getPinAuthSuccess()))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      dispatch(getPinAuthFail(ex))
      return Promise.reject(new Error())
    })
}
