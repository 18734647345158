import React, { useMemo } from 'react'

import CommunityGroup from 'common/components/CommunityGroup'
import fixedGroups from 'common/helpers/fixedGroups'
import useGenericContentBlockItemTracking from 'common/hooks/useGenericContentBlockItemTracking'

import ContentBlockBase, {
  ContentBlockHeaderLink,
} from '../ContentBlockBase/index'

const CommunityGroupOuter = ({ contentBlock, fixedGroup, location }) => {
  // These Community Groups come from fixtures that do not follow the JSON-API spec like all other content block `item`s, so for now we'll piece together the shape we need for tracking.
  const item = useMemo(
    () => ({
      type: 'community',
      attributes: { uuid: fixedGroup.id },
    }),
    [fixedGroup]
  )

  const handleClick = useGenericContentBlockItemTracking({
    contentBlock,
    item,
    location,
  })

  return (
    <div className='content-block__community-groups-list-item'>
      <div className='content-block__community-groups-list-item-inner'>
        <CommunityGroup
          logo={fixedGroup.logo}
          backgroundImage={fixedGroup.header}
          name={fixedGroup.name}
          membersCount={fixedGroup.metadata?.memberships?.count}
          onClick={handleClick}
          slug={fixedGroup.slug}
        />
      </div>
    </div>
  )
}

const ContentBlockCommunityGroups = ({ contentBlock, headerUrl, location }) => (
  <ContentBlockBase>
    <div className='content-block__community-groups-container'>
      <ContentBlockHeaderLink
        contentBlock={contentBlock}
        header1="Let's hear from you"
        header2='Join the community'
        location={location}
        url={headerUrl}
      />
      <div className='content-block__community-groups-list'>
        {fixedGroups?.map(fixedGroup => (
          <CommunityGroupOuter
            contentBlock={contentBlock}
            fixedGroup={fixedGroup}
            key={fixedGroup.id}
          />
        ))}
      </div>
    </div>
  </ContentBlockBase>
)

export default ContentBlockCommunityGroups
