import { fallbackDescription, fallbackTitle } from 'server/defaultTmplParams'

import useSetPageMetaTags from 'common/hooks/useSetPageMetaTags'

const PageMetaTags = ({
  title = fallbackTitle,
  description = fallbackDescription,
  shortTitle = false,
  canonicalUrl = undefined,
}) => {
  useSetPageMetaTags({
    title,
    description,
    shortTitle,
    canonicalUrl,
  })
  return null
}

export default PageMetaTags
