import { isTimeInFuture } from 'common/helpers'

import {
  GET_LIVESTREAMS_FAILURE,
  GET_LIVESTREAMS_REQUEST,
  GET_LIVESTREAMS_SCHEDULE_FAILURE,
  GET_LIVESTREAMS_SCHEDULE_REQUEST,
  GET_LIVESTREAMS_SCHEDULE_SUCCESS,
  GET_LIVESTREAMS_SHOWS_FAILURE,
  GET_LIVESTREAMS_SHOWS_REQUEST,
  GET_LIVESTREAMS_SHOWS_SUCCESS,
  GET_LIVESTREAMS_SUCCESS,
  REFRESH_LIVESTREAMS,
} from '../actions/types'

export default (
  state = {
    filter: '',
    isFetching: false,
    isFetchingSchedule: false,
    isFetchingShows: false,
    isLiveEventOn: false,
    isLoggedIn: null,
    lastUpdated: null,
    liveNow: [],
    livestreamSchedule: [],
    livestreamShows: [],
    livestreams: [],
    notLiveNow: [],
    refreshLivestreams: false,
    scheduleLastUpdated: null,
    showsLastUpdated: null,
    selectedChannel: null,
  },
  action
) => {
  switch (action.type) {
    case GET_LIVESTREAMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_LIVESTREAMS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_LIVESTREAMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        isLoggedIn: action.isLoggedIn,
        livestreams: action.livestreams,
        liveNow: action.livestreams.filter(
          i => !isTimeInFuture(i.attributes.starts_at)
        ),
        notLiveNow: action.livestreams.filter(i =>
          isTimeInFuture(i.attributes.starts_at)
        ),
        selectedChannel: action.selectedChannel,
      }
    case GET_LIVESTREAMS_SCHEDULE_REQUEST:
      return {
        ...state,
        isFetchingSchedule: true,
      }
    case GET_LIVESTREAMS_SCHEDULE_FAILURE:
      return {
        ...state,
        isFetchingSchedule: false,
        error: action.error,
      }
    case GET_LIVESTREAMS_SCHEDULE_SUCCESS:
      const episode = action.livestreamSchedule[0]
      const currentTime = Date.parse(new Date())
      const startsAt = Date.parse(episode.attributes.start_time)
      // convert time until Live into minutes
      const timeLeft = (startsAt - currentTime) / 1000 / 60
      // if the livestream is live then show Live icon
      const isLiveNow = timeLeft < 0
      return {
        ...state,
        isFetchingSchedule: false,
        showsLastUpdated: action.showsLastUpdated,
        livestreamSchedule: action.livestreamSchedule,
        isLiveEventOn: episode.type === 'livestream_show' && isLiveNow,
      }
    case GET_LIVESTREAMS_SHOWS_REQUEST:
      return {
        ...state,
        isFetchingShows: true,
      }
    case GET_LIVESTREAMS_SHOWS_FAILURE:
      return {
        ...state,
        isFetchingShows: false,
        error: action.error,
      }
    case GET_LIVESTREAMS_SHOWS_SUCCESS:
      return {
        ...state,
        isFetchingShows: false,
        showsLastUpdated: action.showsLastUpdated,
        livestreamShows: action.livestreamShows,
        isLiveEventOn: action.livestreamShows[0].attributes.is_live,
      }
    case REFRESH_LIVESTREAMS:
      return {
        ...state,
        liveNow: state.livestreams.filter(
          i => !isTimeInFuture(i.attributes.starts_at)
        ),
        notLiveNow: state.livestreams.filter(i =>
          isTimeInFuture(i.attributes.starts_at)
        ),
      }
    default:
      return state
  }
}
