import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION_CATEGORIES_FAILURE,
  GET_NOTIFICATION_CATEGORIES_REQUEST,
  GET_NOTIFICATION_CATEGORIES_SUCCESS,
  GET_NOTIFICATION_SETTINGS_FAILURE,
  GET_NOTIFICATION_SETTINGS_REQUEST,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_UNSEEN_FAILURE,
  GET_NOTIFICATIONS_UNSEEN_REQUEST,
  GET_NOTIFICATIONS_UNSEEN_SUCCESS,
  PUT_ALL_NOTIFICATIONS_READ_FAILURE,
  PUT_ALL_NOTIFICATIONS_READ_REQUEST,
  PUT_ALL_NOTIFICATIONS_READ_SUCCESS,
  PUT_ALL_NOTIFICATIONS_SEEN_FAILURE,
  PUT_ALL_NOTIFICATIONS_SEEN_REQUEST,
  PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS,
  PUT_EMAIL_UNSUBSCRIBE_FAILURE,
  PUT_EMAIL_UNSUBSCRIBE_REQUEST,
  PUT_EMAIL_UNSUBSCRIBE_SUCCESS,
  PUT_NOTIFICATION_READ,
  PUT_NOTIFICATION_READ_FAILURE,
  PUT_NOTIFICATION_SEEN,
  PUT_NOTIFICATION_SEEN_FAILURE,
  PUT_NOTIFICATION_SETTINGS_FAILURE,
  PUT_NOTIFICATION_SETTINGS_REQUEST,
} from './types'

const NOTIFICATIONS_PER_PAGE = 10

// Mark as Seen
export const markNotificationsSeenSuccess = data => ({
  type: PUT_NOTIFICATION_SEEN,
  seen: data,
})

export const markNotificationsSeenFailure = message => ({
  type: PUT_NOTIFICATION_SEEN_FAILURE,
  message,
})

export const markNotificationsSeen = id => dispatch =>
  rtApi.notify
    .markSeen({ id })
    .then(json =>
      Promise.resolve(dispatch(markNotificationsSeenSuccess(json.data)))
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(markNotificationsSeenFailure(ex)))
    })

const putAllNotificationsSeenRequest = () => ({
  type: PUT_ALL_NOTIFICATIONS_SEEN_REQUEST,
})

const putAllNotificationsSeenSuccess = data => ({
  type: PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS,
  seen: data,
})

const putAllNotificationsSeenFailure = message => ({
  type: PUT_ALL_NOTIFICATIONS_SEEN_FAILURE,
  message,
})

export const putAllNotificationsSeen = () => (dispatch, _getState) => {
  dispatch(putAllNotificationsSeenRequest)
  return rtApi.notify
    .markAllSeen()
    .then(json => dispatch(putAllNotificationsSeenSuccess(json.data)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(putAllNotificationsSeenFailure(ex))
    })
}

// Mark as Read
export const markNotificationReadSuccess = (data, id) => ({
  type: PUT_NOTIFICATION_READ,
  id,
  read: data,
})

export const markNotificationReadFailure = message => ({
  type: PUT_NOTIFICATION_READ_FAILURE,
  message,
})

export const markNotificationRead = id => dispatch =>
  rtApi.notify
    .markRead({ id })
    .then(json =>
      Promise.resolve(dispatch(markNotificationReadSuccess(json.data, id)))
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(markNotificationReadFailure(ex)))
    })

const putAllNotificationsReadRequest = () => ({
  type: PUT_ALL_NOTIFICATIONS_READ_REQUEST,
})

const putAllNotificationsReadSuccess = data => ({
  type: PUT_ALL_NOTIFICATIONS_READ_SUCCESS,
  read: data,
})

const putAllNotificationsReadFailure = message => ({
  type: PUT_ALL_NOTIFICATIONS_READ_FAILURE,
  message,
})

export const putAllNotificationsRead = () => dispatch => {
  dispatch(putAllNotificationsReadRequest)
  return rtApi.notify
    .markAllRead()
    .then(json => dispatch(putAllNotificationsReadSuccess(json.data)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(putAllNotificationsReadFailure(ex))
    })
}

// Get all notifications for logged in user

const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
})

const getNotificationsSuccess = (notifications, links, meta, merge) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  merge,
  notifications,
  links,
  meta,
})

const getNotificationsFailure = message => ({
  type: GET_NOTIFICATIONS_FAILURE,
  message,
})

export const getNotifications = (options = {}) => dispatch => {
  // default merge to false
  const merge = options.merge === true
  const params = {
    per_page: NOTIFICATIONS_PER_PAGE,
    inbox: true,
    skip_expired: true,
  }

  dispatch(getNotificationsRequest())
  return rtApi.notify
    .fetchNotifications({ params })
    .then(json =>
      Promise.resolve(
        dispatch(
          getNotificationsSuccess(json.data, json.links, json.meta, merge)
        )
      )
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(getNotificationsFailure(ex)))
    })
}

// Get unseen count for logged in user

const getUnseenNotificationsCountRequest = () => ({
  type: GET_NOTIFICATIONS_UNSEEN_REQUEST,
})

const getUnseenNotificationsCountSuccess = unseen => ({
  type: GET_NOTIFICATIONS_UNSEEN_SUCCESS,
  unseen,
})

const getUnseenNotificationsCountFailure = message => ({
  type: GET_NOTIFICATIONS_UNSEEN_FAILURE,
  message,
})

export const getUnseenNotificationsCount = () => (dispatch, _getState) => {
  dispatch(getUnseenNotificationsCountRequest())
  return rtApi.notify
    .fetchUnseenCount()
    .then(json =>
      Promise.resolve(dispatch(getUnseenNotificationsCountSuccess(json.data)))
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(getUnseenNotificationsCountFailure(ex)))
    })
}

export const getNextPageNotifications = () => (dispatch, getState) => {
  const { nextLink } = getState().notificationsReducer
  const merge = true

  dispatch(getNotificationsRequest())
  return rtApi.notify
    .fetchByLink({ link: nextLink })
    .then(json =>
      Promise.resolve(
        dispatch(
          getNotificationsSuccess(json.data, json.links, json.meta, merge)
        )
      )
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(getNotificationsFailure(ex)))
    })
}

const getNotificationsCategoriesRequest = () => ({
  type: GET_NOTIFICATION_CATEGORIES_REQUEST,
})

const getNotificationsCategoriesSuccess = ({ categories }) => ({
  type: GET_NOTIFICATION_CATEGORIES_SUCCESS,
  categories,
})

const getNotificationsCategoriesFailure = () => ({
  type: GET_NOTIFICATION_CATEGORIES_FAILURE,
})

export const getNotificationsCategories = () => dispatch => {
  dispatch(getNotificationsCategoriesRequest())
  return rtApi.notify
    .fetchCategories()
    .then(categories =>
      Promise.resolve(
        dispatch(getNotificationsCategoriesSuccess({ categories }))
      )
    )
    .catch(ex =>
      Promise.reject(dispatch(getNotificationsCategoriesFailure(ex)))
    )
}

const getNotificationsSettingsRequest = () => ({
  type: GET_NOTIFICATION_SETTINGS_REQUEST,
})

const getNotificationsSettingsSuccess = ({ categories, settings }) => ({
  type: GET_NOTIFICATION_SETTINGS_SUCCESS,
  categories,
  settings,
})

const getNotificationsSettingsFailure = message => ({
  type: GET_NOTIFICATION_SETTINGS_FAILURE,
  message,
})

export const getNotificationsSettings = () => dispatch => {
  dispatch(getNotificationsSettingsRequest())
  return rtApi.notify
    .fetchSettings()
    .then(({ data: categories, included: settings }) =>
      Promise.resolve(
        dispatch(getNotificationsSettingsSuccess({ categories, settings }))
      )
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      Promise.reject(dispatch(getNotificationsSettingsFailure(ex)))
    })
}

const putNotificationsSettingsRequest = () => ({
  type: PUT_NOTIFICATION_SETTINGS_REQUEST,
})

const putNotificationsSettingsSuccess = ({ categories, settings }) => ({
  type: GET_NOTIFICATION_SETTINGS_SUCCESS,
  categories,
  settings,
})

const putNotificationsSettingsFailure = message => ({
  type: PUT_NOTIFICATION_SETTINGS_FAILURE,
  message,
})

export const putNotificationSettings = ({ user_categories, user_settings }) => (
  dispatch,
  _getState
) => {
  dispatch(putNotificationsSettingsRequest())
  return rtApi.notify
    .updateSettings({ user_categories, user_settings })
    .then(({ data: categories, included: settings }) =>
      Promise.resolve(
        dispatch(putNotificationsSettingsSuccess({ categories, settings }))
      )
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(putNotificationsSettingsFailure(ex)))
    })
}

const putEmailUnsubscribeRequest = () => ({
  type: PUT_EMAIL_UNSUBSCRIBE_REQUEST,
})

const putEmailUnsubscribeSuccess = () => ({
  type: PUT_EMAIL_UNSUBSCRIBE_SUCCESS,
})

const putEmailUnsubscribeFailure = message => ({
  type: PUT_EMAIL_UNSUBSCRIBE_FAILURE,
  message,
})

export const putEmailUnsubscribe = ({ category, id }) => (
  dispatch,
  _getState
) => {
  dispatch(putEmailUnsubscribeRequest())
  return rtApi.notify
    .emailUnsubscribe({ category, id })
    .then(() => Promise.resolve(dispatch(putEmailUnsubscribeSuccess())))
    .catch(ex =>
      Promise.reject(dispatch(putEmailUnsubscribeFailure(ex.message)))
    )
}

// add new notification
export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  notification,
})
