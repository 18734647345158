import React, { useCallback, useMemo } from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'

import EmptySearch from '../shared/EmptySearch'
import EpisodesColumn from './columns/EpisodesColumn'
import GroupsColumn from './columns/GroupsColumn'
import SeriesColumn from './columns/SeriesColumn'
import UsersColumn from './columns/UsersColumn'

import './styles.scss'

const Column = ({ children, setTab, tabTitle }) => {
  // "true" here indicates to setTab that this is happening from "view more" rather than the header.
  const setTabFromViewMore = useCallback(() => setTab(true), [setTab])

  return (
    <div className='search-v2-all-results__column'>
      <div className='search-v2-all-results__column-header'>
        <div className='search-v2-all-results__column-title'>{tabTitle}</div>
        <div
          {...a11yButtonProps(setTabFromViewMore)}
          className='search-v2-all-results__column-view-all'
        >
          View all <i className='icon icon-chevron-right' />
        </div>
      </div>
      <div className='search-v2-all-results__column-contents'>{children}</div>
    </div>
  )
}

const TabAllResults = ({
  episodes,
  series,
  users,
  groups,
  bonusFeatures,

  setTabSeries,
  setTabEpisodes,
  setTabBonusFeatures,
  setTabGroups,
  setTabUsers,

  searchTerm,
  goToUserPage,
  closeSearchV2,
}) => {
  // Limit the number of results for each column to 20 on the "All results" page
  const limitedEpisodes = useMemo(() => episodes.data.slice(0, 20), [
    episodes.data,
  ])
  const limitedSeries = useMemo(() => series.data.slice(0, 20), [series.data])
  const limitedUsers = useMemo(() => users.data.slice(0, 20), [users.data])
  const limitedGroups = useMemo(() => groups.data.slice(0, 20), [groups.data])
  const limitedBonusFeatures = useMemo(() => bonusFeatures.data.slice(0, 20), [
    bonusFeatures.data,
  ])

  if (
    !limitedEpisodes.length &&
    !limitedSeries.length &&
    !limitedUsers.length &&
    !limitedGroups.length &&
    !limitedBonusFeatures.length
  ) {
    return <EmptySearch />
  }

  return (
    <div className='search-v2-all-results'>
      <Column setTab={setTabSeries} tabTitle='Series'>
        <SeriesColumn
          closeSearchV2={closeSearchV2}
          series={limitedSeries}
          searchTerm={searchTerm}
        />
      </Column>
      <Column setTab={setTabEpisodes} tabTitle='Episodes'>
        <EpisodesColumn
          closeSearchV2={closeSearchV2}
          episodes={limitedEpisodes}
          searchTerm={searchTerm}
        />
      </Column>
      <Column setTab={setTabBonusFeatures} tabTitle='Bonus Features'>
        <EpisodesColumn
          closeSearchV2={closeSearchV2}
          episodes={limitedBonusFeatures}
          isBonusFeatures
          searchTerm={searchTerm}
        />
      </Column>
      <Column setTab={setTabGroups} tabTitle='Groups'>
        <GroupsColumn
          closeSearchV2={closeSearchV2}
          groups={limitedGroups}
          searchTerm={searchTerm}
        />
      </Column>
      <Column setTab={setTabUsers} tabTitle='Users'>
        <UsersColumn
          closeSearchV2={closeSearchV2}
          goToUserPage={goToUserPage}
          users={limitedUsers}
          searchTerm={searchTerm}
        />
      </Column>
    </div>
  )
}

export default TabAllResults
