import React from 'react'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

// Snowflakes need a radius and an opacity
function resetParticle(particle) {
  particle.radius = 1 + Math.random() * 2
  particle.alpha = 0.5 + Math.random() * 0.5
}

function drawParticle(context, particle) {
  context.fillStyle = '#f7f7f8'
  context.globalAlpha = particle.alpha
  context.beginPath()
  context.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2, false)
  context.closePath()
  context.fill()
}

const SnowflakesEasterEggButton = ({ element }) => (
  <FallingParticlesEasterEggButton
    drawParticle={drawParticle}
    element={element}
    resetParticle={resetParticle}
  />
)

export default SnowflakesEasterEggButton
