const fixedGroups = [
  {
    id: '36ace2e0-afe1-43a9-9a3b-9fc9fad43016',
    slug: 'rooster-teeth',
    name: 'Rooster Teeth',
    description:
      'Welcome to the Rooster Teeth community! Not sure where to go first? You can introduce yourself, make friends, and talk about anything RT related here! ',
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/98f8f009e9d33d21f09574d63742c0060c738e2ea592fda085b8d7cdaccef16c.png',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/ac7b52c7c3b3875bd9e10a0b6bea042c8e6f3e304f1dbf17f3c9999c4158dd84.png',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: '',
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: null,
    official: true,
    created_at: '2022-09-30T15:35:10.000Z',
    metadata: {
      memberships: {
        count: 7404,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'e8209cf3-1311-481b-84ab-9f84c63a3947',
        name: 'Barbara',
        member_tier: 'first',
        roles: [
          'admin',
          'cast_and_crew_admin',
          'global_group_admin',
          'global_forum_admin',
          'news_admin',
          'podcast_admin',
          'press_admin',
          'shows_admin',
          'site_information_admin',
          'store_admin',
          'user_admin',
          'user_super_admin',
          'business_admin',
          'community_admin',
          'creatures_video_admin',
          'chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin'],
        display_title: '',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/e8209cf3-1311-481b-84ab-9f84c63a3947/b15bd5b6a92ac5ffa4234f3f8b8c49f13c1728b583cc0c4ec6ad301214e26170.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 32,
          followers_count: 1345,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
  {
    id: 'b3178c7c-2785-465c-9927-d2112a251aa8',
    slug: 'achievement-hunter',
    name: 'Achievement Hunter',
    description:
      "The best gameplay videos, live streams, & podcasts you'll possibly regret watching.",
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/f899152d-a2cc-46ef-a610-019fcfd07de6/776cf9aa0468dc099833e2d3569fe46eed23144adad3f94405fd35a687397cb6.jpg',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/e706005f2021c2d015fe8cc2c6258d412e7a149d075ecbad92bf6b37a3a90db6.png',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: '',
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: null,
    official: true,
    created_at: '2022-09-30T15:35:11.000Z',
    metadata: {
      memberships: {
        count: 13257,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'e8209cf3-1311-481b-84ab-9f84c63a3947',
        name: 'Barbara',
        member_tier: 'first',
        roles: [
          'admin',
          'cast_and_crew_admin',
          'global_group_admin',
          'global_forum_admin',
          'news_admin',
          'podcast_admin',
          'press_admin',
          'shows_admin',
          'site_information_admin',
          'store_admin',
          'user_admin',
          'user_super_admin',
          'business_admin',
          'community_admin',
          'creatures_video_admin',
          'chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin'],
        display_title: '',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/e8209cf3-1311-481b-84ab-9f84c63a3947/b15bd5b6a92ac5ffa4234f3f8b8c49f13c1728b583cc0c4ec6ad301214e26170.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 32,
          followers_count: 1345,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
  {
    id: 'f6fa2125-f7a1-4ebb-ab80-821586bfd715',
    slug: 'rwby',
    name: 'RWBY',
    description:
      'The future-fantasy world of Remnant is filled with ravenous monsters, treacherous terrain, and more villains than you can shake a sniper-scythe at. Fortunately, Beacon Academy is training Huntsmen and Huntresses to battle the evils of the world.',
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/d0a125b5f5a494526f5db35b450f159a15897af802ee4c783030f6359931433d.webp',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/f899152d-a2cc-46ef-a610-019fcfd07de6/720d0d2e52b367e75df9dda76d6b97df9ee70be1320428d83917b287f1ae7f4f.png',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: 'Remnant',
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: null,
    official: true,
    created_at: '2022-09-30T15:35:09.000Z',
    metadata: {
      memberships: {
        count: 6650,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'e8209cf3-1311-481b-84ab-9f84c63a3947',
        name: 'Barbara',
        member_tier: 'first',
        roles: [
          'admin',
          'cast_and_crew_admin',
          'global_group_admin',
          'global_forum_admin',
          'news_admin',
          'podcast_admin',
          'press_admin',
          'shows_admin',
          'site_information_admin',
          'store_admin',
          'user_admin',
          'user_super_admin',
          'business_admin',
          'community_admin',
          'creatures_video_admin',
          'chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin'],
        display_title: '',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/e8209cf3-1311-481b-84ab-9f84c63a3947/b15bd5b6a92ac5ffa4234f3f8b8c49f13c1728b583cc0c4ec6ad301214e26170.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 32,
          followers_count: 1345,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
  {
    id: '9cbf1329-61fe-426f-bcd3-6490bba179c1',
    slug: 'funhaus',
    name: 'Funhaus',
    description: "Gameplays and podcasts. We don't know why we're here either.",
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/4c70bb46f1c1e2d4b76f7c51e84a5ffd7b5b898bc42ee5ba3f5b1df8e02c60ed.jpg',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/13617d8f8ba2aefa95fa09744408909677ea7fc3ca754f72f8ad5a539d1ddd82.jpg',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: '',
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: null,
    official: true,
    created_at: '2022-09-30T15:35:08.000Z',
    metadata: {
      memberships: {
        count: 2905,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'e8209cf3-1311-481b-84ab-9f84c63a3947',
        name: 'Barbara',
        member_tier: 'first',
        roles: [
          'admin',
          'cast_and_crew_admin',
          'global_group_admin',
          'global_forum_admin',
          'news_admin',
          'podcast_admin',
          'press_admin',
          'shows_admin',
          'site_information_admin',
          'store_admin',
          'user_admin',
          'user_super_admin',
          'business_admin',
          'community_admin',
          'creatures_video_admin',
          'chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin'],
        display_title: '',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/e8209cf3-1311-481b-84ab-9f84c63a3947/b15bd5b6a92ac5ffa4234f3f8b8c49f13c1728b583cc0c4ec6ad301214e26170.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 32,
          followers_count: 1345,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
  {
    id: '67c9ccb5-fd43-462b-a679-3a0151fc6810',
    slug: 'red-vs-blue',
    name: 'Red vs Blue',
    description: "Ever wonder why we're here? ",
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/3031e9bb7933293e5eb89e3616e9e6e79784b21ae1679a8ab87f829015927bef.webp',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/8f237d610f3f425d80edcad2f77a1a66acabd854ed8cf23bcab722c581c533d3.webp',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: null,
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: null,
    official: true,
    created_at: '2022-09-30T15:35:04.000Z',
    metadata: {
      memberships: {
        count: 1980,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
  {
    id: '6d74e724-32b1-409f-9e8c-3909465630da',
    slug: 'rooster-teeth-discord',
    name: 'Rooster Teeth Discord',
    description:
      'Welcome to the RT Gaming Community group! We host daily game nights on discord.gg/roosterteeth ',
    logo:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/b5bb50fefc08a545829c18475bd53b747cdc37748d114c6d589c741002dd593d.png',
    header:
      'https://cdn.roosterteeth.com/image/upload/f_auto/comm/a83d511f-65a7-42d5-97d3-84849d62ea22/5615cbb4af918dd612ab95906bd85f1de51c657e006955c377d80ea82b5b39e9.png',
    is_first: false,
    public: true,
    banned: false,
    active: true,
    staff_community: false,
    chat_enabled: true,
    location_name: '',
    location_lat: null,
    location_lon: null,
    state: null,
    rules: null,
    stickied_post_id: '253f4ef7-4462-4159-9f9e-742516dbf43f',
    official: true,
    created_at: '2022-09-30T15:35:03.000Z',
    metadata: {
      memberships: {
        count: 1653,
      },
    },
    chat: {},
    moderators: [
      {
        id: 'a83d511f-65a7-42d5-97d3-84849d62ea22',
        name: 'Chelsea',
        member_tier: 'first',
        roles: [
          'admin',
          'user_admin',
          'user_super_admin',
          'community_admin',
          'chat_admin',
          'global_user_admin',
          'global_chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin', 'Global Moderator'],
        display_title: 'RT Community Manager',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/a83d511f-65a7-42d5-97d3-84849d62ea22/06fd3dc2c1d5e9c0db087e9795e3bccfd87e6d4679cec986d2ab31a609acd71f.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 246,
          followers_count: 625,
          following: false,
        },
        posts: [],
      },
      {
        id: 'adca2fa9-8739-49ea-bc2a-9fab990301a8',
        name: 'Becca',
        member_tier: 'first',
        roles: [],
        badges: ['Cast'],
        display_title: 'RT Torturer',
        profile_picture:
          'https://s3.amazonaws.com/cdn.roosterteeth.com/uploads/images/f39715bb-3bbc-4b3c-aab9-b3dbf4c2a5c9/sm/21-1519850623798-IMG_7646_2.JPG',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 12,
          followers_count: 430,
          following: false,
        },
        posts: [],
      },
      {
        id: 'e8209cf3-1311-481b-84ab-9f84c63a3947',
        name: 'Barbara',
        member_tier: 'first',
        roles: [
          'admin',
          'cast_and_crew_admin',
          'global_group_admin',
          'global_forum_admin',
          'news_admin',
          'podcast_admin',
          'press_admin',
          'shows_admin',
          'site_information_admin',
          'store_admin',
          'user_admin',
          'user_super_admin',
          'business_admin',
          'community_admin',
          'creatures_video_admin',
          'chat_admin',
          'global_chat_moderator',
          'global_moderator',
          'global_community_admin',
        ],
        badges: ['Cast', 'Staff', 'Site Admin'],
        display_title: '',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/e8209cf3-1311-481b-84ab-9f84c63a3947/b15bd5b6a92ac5ffa4234f3f8b8c49f13c1728b583cc0c4ec6ad301214e26170.jpg',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 32,
          followers_count: 1345,
          following: false,
        },
        posts: [],
      },
      {
        id: 'f899152d-a2cc-46ef-a610-019fcfd07de6',
        name: 'kriss',
        member_tier: 'first',
        roles: [],
        badges: [],
        display_title: 'Community Manager ',
        profile_picture:
          'https://s3.amazonaws.com/community-prod/user/f899152d-a2cc-46ef-a610-019fcfd07de6/63ee9ecf5d28b1048a604ab65eb7201df1b99e416a70dc112d742971b9e68bcd.png',
        muted: false,
        metadata: {
          muted: false,
          followed: false,
          following_count: 337,
          followers_count: 924,
          following: false,
        },
        posts: [],
      },
    ],
  },
]

export default fixedGroups
