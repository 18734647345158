import { useState } from 'react'
import { connect } from 'react-redux'

import { getGeneratedUsernames } from 'auth/actions'

export function GenerateUsername({ getGeneratedUsernames, render }) {
  const [usernames, setUsernames] = useState([])

  const getRandomUsername = () => {
    let username

    if (usernames.length === 0) {
      return getGeneratedUsernames().then(({ usernames }) => {
        username = usernames[0]
        setUsernames(usernames.slice(1, usernames.length))

        return Promise.resolve(username)
      })
    }

    username = usernames[0]
    setUsernames(usernames.slice(1, usernames.length))

    return Promise.resolve(username)
  }

  return render({ getRandomUsername })
}

const mapDispatchToProps = {
  getGeneratedUsernames,
}

export default connect(
  null,
  mapDispatchToProps
)(GenerateUsername)
