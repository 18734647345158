import { useEffect, useMemo, useState } from 'react'
import throttle from 'lodash.throttle'

const detectWindowWidth = () => window.innerWidth

/*
  Returns a boolean which indicates whether the current window width meets the `thresholdWidth`. The return value is throttled (`throttleMs`) to prevent this hook from causing too many renders.

  Example:

      const Component = () => {
        const isWideWindow = useWidthThreshold(1500)

        return isWideWindow ? <BigComponent /> : <SmallComponent />
      }
*/
const useWidthThreshold = (thresholdWidth, throttleMs = 250) => {
  const [detectedWindowWidth, setDetectedWindowWidth] = useState(
    detectWindowWidth
  )

  const throttledDetectWindowWidth = useMemo(
    () =>
      throttle(() => setDetectedWindowWidth(detectWindowWidth()), throttleMs, {
        leading: false,
      }),
    [throttleMs]
  )

  useEffect(
    () => {
      window.addEventListener('resize', throttledDetectWindowWidth)
      return () =>
        window.removeEventListener('resize', throttledDetectWindowWidth)
    },
    [throttledDetectWindowWidth]
  )

  return detectedWindowWidth >= thresholdWidth
}

export default useWidthThreshold
