import React from 'react'

import firstLogo from './first_logo.svg'

import './styles.scss'

const PremiumBadge = ({ userIsFirst = false }) => (
  <div
    className={`premium__badge__wrapper ${userIsFirst ? 'first--user' : ''}`}
  >
    <span className='premium__badge__tag'>Watch First</span>
    {!userIsFirst && (
      <img className='premium__badge__img' src={firstLogo} alt='first logo' />
    )}
  </div>
)

export default PremiumBadge
