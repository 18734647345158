import React, { useEffect, useState } from 'react'

import './styles.scss'

// Whenever a URL has been loaded already, don't worry about loading it as a new node
const loadedSrcs = []

const preloadImgSrc = src =>
  new Promise(resolve => {
    if (loadedSrcs.includes(src)) resolve()
    const img = document.createElement('img')
    img.onload = () => {
      loadedSrcs.push(src)
      resolve()
    }
    img.src = src
  })

const RTBlurredBackground = ({ background }) => {
  const [[topBg, bottomBg], setBgs] = useState([undefined, undefined])
  useEffect(
    () => {
      // If we receive undefined, assume that something new is coming afterwards. so prefer the background we currently have until we get the new one.
      if (!background) return
      let timeoutId
      preloadImgSrc(background).then(() => {
        setBgs(([_, existingBottomBg]) => [background, existingBottomBg])
        // Fade out topBg so that only bottomBg (background) is visible
        timeoutId = setTimeout(() => {
          setBgs([undefined, background])
        }, 2000)
      })
      return () => clearTimeout(timeoutId)
    },
    [background, setBgs]
  )

  return (
    <>
      {bottomBg && (
        <div
          style={{
            backgroundImage: `url(${bottomBg})`,
          }}
          className='rt-blurred-bg rt-blurred-bg--bottom'
        />
      )}
      {/* The top BG is only visible when we are fading into a new BG. */}
      {topBg && (
        <div
          style={{
            backgroundImage: `url(${topBg})`,
          }}
          className='rt-blurred-bg rt-blurred-bg--top'
        />
      )}
    </>
  )
}

export default RTBlurredBackground
