import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

export default function ChatMentionList(props) {
  const { mentionList, selectedMention } = props
  return (
    <div className='mention-autocomplete'>
      <div className='mention-autocomplete__tail-container'>
        <div className='mention-autocomplete__tail' />
      </div>
      <div className='mention-autocomplete__container'>
        {mentionList.length === 0 && (
          <div className='autocomplete-matches'>
            <div className='autocomplete-matches__item'>
              <div className='autocomplete-matches__item-container'>
                <span>No Matches</span>
              </div>
            </div>
          </div>
        )}
        {mentionList.length !== 0 &&
          mentionList.map((name, i) => (
            <div className='autocomplete-matches' key={`mention-${name}`}>
              <button
                className={classnames({
                  'autocomplete-matches__item': true,
                  'autocomplete-matches__item--selected':
                    name === selectedMention,
                })}
                onClick={() => props.fillMention(name)}
                onFocus={() => null}
                onMouseOver={() =>
                  props.setMention({
                    selectedMention: name,
                    selectedMentionIndex: i,
                  })
                }
                type='button'
              >
                <div className='autocomplete-matches__item-container'>
                  <span>{name}</span>
                </div>
              </button>
            </div>
          ))}
      </div>
    </div>
  )
}
ChatMentionList.propTypes = {
  mentionList: PropTypes.array,
  selectedMention: PropTypes.string,
}

ChatMentionList.defaultProps = {
  mentionList: [],
  selectedMention: '',
}
