const initialState = {
  isChecking: false,
  checkUrl: null,
  isValid: false,
}

export default (
  state = initialState,
  { type, _resp, _pagination, _data, req }
) => {
  switch (type) {
    case 'common/RICHMEDIA_CHECK_LINK_REQ':
      return {
        ...state,
        isChecking: true,
        checkUrl: req.body.url,
      }
    case 'common/RICHMEDIA_CHECK_LINK_RESP':
      return {
        ...state,
        isChecking: false,
        isValid: true,
      }
    case 'common/RICHMEDIA_CHECK_LINK_ERR':
      return {
        ...state,
        isValid: false,
      }
    default:
      return state
  }
}
