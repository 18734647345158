import React from 'react'

const ProfileImage = ({ profileImage }) => (
  <div className='my-hub__profile-img-container'>
    <div className='my-hub__profile-img-container-inner'>
      <div className='my-hub__profile-img-shadow' />
      <img
        alt='Your profile image'
        className='my-hub__profile-img'
        src={profileImage}
      />
    </div>
  </div>
)

export default ProfileImage
