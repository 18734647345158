import React from 'react'

import appIcon from 'common/assets/icon-rt-app.png'
import PrimaryBtn from 'common/components/Buttons/BtnBase'
import LinkBase from 'common/components/Buttons/LinkBase'

import './styles.scss'

const SmartAppBanner = ({
  onOpen,
  platform,
  downloadLink,
  trackButtonClick,
}) => (
  <div className='smart-app-banner__banner-container'>
    <div className='smart-app-banner__app-image-container'>
      <img
        className='smart-app-banner__app-image'
        alt='app image'
        src={appIcon}
      />
    </div>
    <div className='smart-app-banner__app-info-container'>
      <div className='smart-app-banner__app-name'>Rooster Teeth</div>
      <div className='smart-app-banner__app-description'>
        Watch all of our content on our app
      </div>
    </div>
    <div className='smart-app-banner__app-link-container'>
      {platform === 'android' ? (
        <PrimaryBtn
          type='button'
          onClick={onOpen}
          className='smart-app-banner__app-link-button'
          aria-hidden='true'
        >
          OPEN
        </PrimaryBtn>
      ) : (
        <LinkBase
          className='smart-app-banner__app-link-button'
          linkTo={downloadLink}
          external
          onClick={trackButtonClick}
        >
          OPEN
        </LinkBase>
      )}
    </div>
  </div>
)

export default React.memo(SmartAppBanner)
