import React from 'react'
import { Transition } from 'react-transition-group'

const WelcomeMessage = ({ loaded, username }) => (
  <>
    <Transition
      appear
      in={!loaded}
      timeout={{ enter: 400, exit: 800 }}
      unmountOnExit
    >
      {state => (
        <div className={`my-hub__welcome my-hub__welcome--${state}`}>
          <div className='my-hub__welcome-inner'>Welcome to Rooster Teeth</div>
        </div>
      )}
    </Transition>

    <Transition
      appear
      in={!loaded}
      timeout={{ enter: 600, exit: 1200 }}
      unmountOnExit
    >
      {state => (
        <div className={`my-hub__username my-hub__username--${state}`}>
          <div className='my-hub__username-inner'>
            <span>{username}</span>
          </div>
        </div>
      )}
    </Transition>
  </>
)

export default WelcomeMessage
