import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'

import getPositionOffsetsFromTheme from 'common/components/RTDropdown/getPositionOffsetsFromTheme'
import borrowPosition from 'common/helpers/borrowPosition'

import './styles.scss'

// Z Index for Materialize dropdowns is 9999
const DEFAULT_Z_INDEX = 10000

const RTDropdown = ({
  dropdownContents,
  maxWidth = 360,
  minWidth = 300,
  opened: openedFromProps,
  position = 'top-right-inner',
  theme = 'default',
  triggerElement,
  zIndex = DEFAULT_Z_INDEX,
}) => {
  const triggerElementRef = useRef()
  const dropdownElementRef = useRef()

  const [offsetX, offsetY] = getPositionOffsetsFromTheme(theme, position)

  useLayoutEffect(() => {
    borrowPosition({
      getSourceElem: () => triggerElementRef.current,
      getTargetElem: () => dropdownElementRef.current,
      offsetX,
      offsetY,
    })
  })
  const [openedFromState, setOpened] = useState(false)

  const closeDropdownEventListener = useCallback(
    () => {
      setOpened(false)
      document.removeEventListener('click', closeDropdownEventListener)
    },
    [setOpened]
  )

  const open = useCallback(
    () => {
      setOpened(true)
      // If we emitted the "open" event via onFocus or other non-click handlers, we may have also emitted a separate click event at the same time which would be caught immediately by the following event listener. To prevent this, we wait a moment before binding the "close" event listener to clicks.
      setTimeout(
        () =>
          document.addEventListener('click', closeDropdownEventListener, {
            capture: true,
          }),
        100
      )
    },
    [closeDropdownEventListener]
  )

  const close = useCallback(
    () => {
      setOpened(false)
    },
    [setOpened]
  )

  const outerStyle = useMemo(() => ({ zIndex }), [zIndex])
  const style = useMemo(
    () => ({ maxWidth: `${maxWidth}px`, minWidth: `${minWidth}px` }),
    [maxWidth, minWidth]
  )

  let isOpened = openedFromState
  if (typeof openedFromProps === 'boolean') isOpened = openedFromProps

  return (
    <>
      {triggerElement({ open, close, ref: triggerElementRef, isOpened })}
      <div
        ref={dropdownElementRef}
        className={`rt-dropdown-item-container ${
          isOpened ? 'rt-dropdown-item-container--opened' : ''
        } ${
          theme === 'white-bubble'
            ? 'rt-dropdown-item-container--white-bubble'
            : ''
        }`}
        style={outerStyle}
      >
        <div
          style={style}
          className={`rt-dropdown-item-container__inner rt-dropdown-item-container__inner--${position}`}
        >
          {dropdownContents}
        </div>
      </div>
    </>
  )
}

const positions = [
  'center',
  'top',
  'top-right',
  'right',
  'bottom-right',
  'bottom',
  'bottom-left',
  'left',
  'top-left',
  'top-inner',
  'top-right-inner',
  'right-inner',
  'bottom-right-inner',
  'bottom-inner',
  'bottom-left-inner',
  'left-inner',
  'top-left-inner',
]
const themes = ['default', 'white-bubble']

RTDropdown.propTypes = {
  dropdownContents: PropTypes.element.isRequired,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  position: PropTypes.oneOf(positions),
  theme: PropTypes.oneOf(themes),
  triggerElement: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
}

export { RTDropdown }
