export const GET_LAST_PLAYED_REQUEST = 'showsApp/GET_LAST_PLAYED_REQUEST'
export const GET_LAST_PLAYED_FAILURE = 'showsApp/GET_LAST_PLAYED_FAILURE'
export const GET_LAST_PLAYED_SUCCESS = 'showsApp/GET_LAST_PLAYED_SUCCESS'
export const GET_SHOWS_REQUEST = 'showsApp/GET_SHOWS_REQUEST'
export const GET_SHOWS_FAILURE = 'showsApp/GET_SHOWS_FAILURE'
export const GET_SHOWS_SUCCESS = 'showsApp/GET_SHOWS_SUCCESS'
export const GET_SHOW_REQUEST = 'showsApp/GET_SHOW_REQUEST'
export const GET_SHOW_FAILURE = 'showsApp/GET_SHOW_FAILURE'
export const GET_SHOW_SUCCESS = 'showsApp/GET_SHOW_SUCCESS'
export const GET_BONUS_REQUEST = 'showsApp/GET_BONUS_REQUEST'
export const GET_BONUS_FAILURE = 'showsApp/GET_BONUS_FAILURE'
export const GET_BONUS_SUCCESS = 'showsApp/GET_BONUS_SUCCESS'
export const GET_BONUS_PERCENT_REQUEST = 'showsApp/GET_BONUS_PERCENT_REQUEST'
export const GET_BONUS_PERCENT_FAILURE = 'showsApp/GET_BONUS_PERCENT_FAILURE'
export const GET_BONUS_PERCENT_SUCCESS = 'showsApp/GET_BONUS_PERCENT_SUCCESS'
export const LAST_PLAYED_RESET = 'showsApp/LAST_PLAYED_RESET'
export const RESET_SHOWS = 'showsApp/RESET_SHOWS'
export const SET_PENDING_RSS_ACTIONS = 'showsApp/SET_PENDING_RSS_ACTIONS'

// V2 Shows page actions.
// TODO: Remove old types, actions, and other code during cleanup ticket when feature flag is removed.
export const V2_GET_SHOWS_REQUEST = 'showsApp/V2_GET_SHOWS_REQUEST'
export const V2_GET_SHOWS_FAILURE = 'showsApp/V2_GET_SHOWS_FAILURE'
export const V2_GET_SHOWS_SUCCESS = 'showsApp/V2_GET_SHOWS_SUCCESS'
export const V2_RESET_SHOWS = 'showsApp/V2_RESET_SHOWS'
