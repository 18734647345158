import React from 'react'
import { connect } from 'react-redux'

import { PlanName } from 'common/components'

const CurrentPlanName = ({ plan }) => (
  <PlanName className='qa-current-plan-name' plan={plan} />
)

const mapStateToProps = state => ({
  plan: state.subscriptions.account.rt_subscription_plan || {},
})

export default connect(mapStateToProps)(CurrentPlanName)
