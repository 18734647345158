import rtApi from '@roosterteethproductions/svod-api'

import getAuthenticateFuncFromIdp from 'common/helpers/getAuthenticateFuncFromIdp'
import processOAuthErrorResponse from 'common/helpers/processOAuthErrorResponse'

import { loginUser3Party } from 'auth/actions/loginActions'
import { getUserObject } from 'auth/actions/userActions'

import { POST_USER_FAILURE, POST_USER_REQUEST } from './types'

// Signup User Actions

export const postUserRequest = () => ({
  type: POST_USER_REQUEST,
})

export const postUserFailure = ({ message }) => ({
  type: POST_USER_FAILURE,
  message,
})

export const postUser = (
  { email, password, recaptcha_response, extraHeaders },
  errorCallback
) => (dispatch, _getState) => {
  dispatch(postUserRequest())
  return rtApi.users
    .create({
      body: {
        recaptcha_response,
        user: {
          email,
          password,
        },
      },
      errorCallback,
      extraHeaders: extraHeaders || {},
    })
    .then(json => Promise.resolve(json))
    .catch(ex => Promise.reject(dispatch(postUserFailure(ex))))
}

export const authenticateWith3Party = serviceId => (dispatch, _getState) =>
  new Promise((resolve, reject) => {
    const authenticateFunc = getAuthenticateFuncFromIdp(serviceId)
    if (!authenticateFunc) return reject(new Error('Invalid serviceId'))
    return authenticateFunc()
      .then(({ token }) => dispatch(loginUser3Party(serviceId, token)))
      .then(({ status }) =>
        dispatch(getUserObject())
          .then(({ user }) => resolve({ user, status }))
          .catch(authErrorResponse =>
            reject(processOAuthErrorResponse(authErrorResponse))
          )
      )
      .catch(authErrorResponse =>
        reject(processOAuthErrorResponse(authErrorResponse))
      )
  })
