export const REQUEST_PROFILE = 'profileApp/REQUEST_PROFILE'
export const RECEIVE_PROFILE = 'profileApp/RECEIVE_PROFILE'
export const SAVE_PROFILE = 'profileApp/SAVE_PROFILE'
export const TOGGLE_EDITING = 'profileApp/TOGGLE_EDITING'
export const TOGGLE_FOLLOW = 'profileApp/TOGGLE_FOLLOW'
export const TOGGLE_FRIEND = 'profileApp/TOGGLE_FRIEND'
export const TOGGLE_LIKE = 'profileApp/TOGGLE_LIKE'
export const TOGGLE_SAVING = 'profileApp/TOGGLE_SAVING'
export const TRIGGER_MESSAGE = 'profileApp/TRIGGER_MESSAGE'
export const TRIGGER_UPDATE_COVER = 'profileApp/TRIGGER_UPDATE_COVER'
export const TRIGGER_UPDATE_PHOTO = 'profileApp/TRIGGER_UPDATE_PHOTO'
export const PUT_UPDATE_PROFILE = 'profileApp/PUT_UPDATE_PROFILE'
export const PUT_UPDATE_PROFILE_ERR = 'profileApp/PUT_UPDATE_PROFILE_ERR'
export const PUT_UPDATE_PROFILE_IMAGE_RESP =
  'profileApp/PUT_UPDATE_PROFILE_IMAGE_RESP'
export const PUT_UPDATE_PROFILE_IMAGE_ERR =
  'profileApp/PUT_UPDATE_PORFILE_IMAGE_ERR'
