// Routes
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_SIGNUP_SELECT_PLAN = '/signup/select-plan'
export const ROUTE_SIGNUP_BILLING = '/signup/billing'
export const ROUTE_SIGNUP_CUSTOMIZE = '/signup/customize'

// Views (these correspond with the routes above)
export const VIEW_CREATE_ACCOUNT = 'VIEW_CREATE_ACCOUNT'
export const VIEW_SELECT_A_PLAN = 'VIEW_SELECT_A_PLAN'
export const VIEW_BILLING = 'VIEW_BILLING'
export const VIEW_CUSTOMIZE_ACCOUNT = 'VIEW_CUSTOMIZE_ACCOUNT'

// Misc
export const genericCreateAccountError =
  'An error occurred while trying to create an account.'

// Plans
export const PLAN_ANNUALLY = '1Year'
export const PLAN_SEMIANNUALLY = '6month'
export const PLAN_MONTHLY = '1month'
