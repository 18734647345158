/* Login */
export const POST_LOGIN_REQUEST = 'auth/POST_LOGIN_REQUEST'
export const POST_LOGIN_SUCCESS = 'auth/POST_LOGIN_SUCCESS'
export const POST_LOGIN_FAILURE = 'auth/POST_LOGIN_FAILURE'

/* Third Party Login */
export const GET_3_PARTY_LOGIN_REQUEST = 'auth/GET_3_PARTY_LOGIN_REQUEST'
export const GET_3_PARTY_LOGIN_SUCCESS = 'auth/GET_3_PARTY_LOGIN_SUCCESS'
export const GET_3_PARTY_LOGIN_FAILURE = 'auth/GET_3_PARTY_LOGIN_FAILURE'

/* Signup */
export const POST_USER_REQUEST = 'auth/POST_USER_REQUEST'
export const POST_USER_FAILURE = 'auth/POST_USER_FAILURE'

/* Third Party Signup */
export const POST_3_PARTY_USER_REQUEST = 'auth/POST_3_PARTY_USER_REQUEST'
export const POST_3_PARTY_USER_FAILURE = 'auth/POST_3_PARTY_USER_FAILURE'
export const POST_3_PARTY_CONNECT_REQUEST = 'POST_3_PARTY_CONNECT_REQUEST'
export const POST_3_PARTY_CONNECT_SUCCESS = 'POST_3_PARTY_CONNECT_SUCCESS'
export const POST_3_PARTY_CONNECT_FAILURE = 'POST_3_PARTY_CONNECT_FAILURE'
export const DELETE_3_PARTY_DISCONNECT_REQUEST =
  'DELETE_3_PARTY_DISCONNECT_REQUEST'
export const DELETE_3_PARTY_DISCONNECT_SUCCESS =
  'DELETE_3_PARTY_DISCONNECT_SUCCESS'
export const DELETE_3_PARTY_DISCONNECT_FAILURE =
  'DELETE_3_PARTY_DISCONNECT_FAILURE'

/* Password */
export const GET_VERIFY_PASSWORD_TOKEN_REQUEST =
  'auth/GET_VERIFY_PASSWORD_TOKEN_REQUEST'
export const GET_VERIFY_PASSWORD_TOKEN_SUCCESS =
  'auth/GET_VERIFY_PASSWORD_TOKEN_SUCCESS'
export const GET_VERIFY_PASSWORD_TOKEN_FAILURE =
  'auth/GET_VERIFY_PASSWORD_TOKEN_FAILURE'
export const POST_FORGOT_PASSWORD_REQUEST = 'auth/POST_FORGOT_PASSWORD_REQUEST'
export const POST_FORGOT_PASSWORD_SUCCESS = 'auth/POST_FORGOT_PASSWORD_SUCCESS'
export const POST_FORGOT_PASSWORD_FAILURE = 'auth/POST_FORGOT_PASSWORD_FAILURE'
export const PUT_PASSWORD_REQUEST = 'auth/PUT_PASSWORD_REQUEST'
export const PUT_PASSWORD_SUCCESS = 'auth/PUT_PASSWORD_SUCCESS'
export const PUT_PASSWORD_FAILURE = 'auth/PUT_PASSWORD_FAILURE'
export const PUT_RESET_PASSWORD_FAILURE = 'auth/PUT_RESET_PASSWORD_FAILURE'
export const PUT_RESET_PASSWORD_REQUEST = 'auth/PUT_RESET_PASSWORD_REQUEST'
export const PUT_RESET_PASSWORD_SUCCESS = 'auth/PUT_RESET_PASSWORD_SUCCESS'

/* Logout */
export const POST_LOGOUT_REQUEST = 'auth/POST_LOGOUT_REQUEST'
export const POST_LOGOUT_SUCCESS = 'auth/POST_LOGOUT_SUCCESS'
export const POST_LOGOUT_FAILURE = 'auth/POST_LOGOUT_FAILURE'

/* Client auth */
export const POST_CLIENT_TOKEN_SUCCESS = 'auth/POST_CLIENT_TOKEN_SUCCESS'
export const POST_CLIENT_TOKEN_FAILURE = 'auth/POST_CLIENT_TOKEN_FAILURE'
export const POST_CLIENT_TOKEN_REQUEST = 'auth/POST_CLIENT_TOKEN_REQUEST'

/* Email actions */

export const POST_RESEND_VERIFICATION_SUCCESS =
  'auth/POST_RESEND_VERIFICATION_SUCCESS'
export const POST_RESEND_VERIFICATION_FAILURE =
  'auth/POST_RESEND_VERIFICATION_FAILURE'
export const POST_RESEND_VERIFICATION_REQUEST =
  'auth/POST_RESEND_VERIFICATION_REQUEST'

/* Pin Auth */
export const POST_PIN_AUTH_SUCCESS = 'auth/POST_PIN_AUTH_SUCCESS'
export const POST_PIN_AUTH_FAILURE = 'auth/POST_PIN_AUTH_FAILURE'
export const POST_PIN_AUTH_REQUEST = 'auth/POST_PIN_AUTH_REQUEST'

/* Shopify session */
export const POST_SHOPIFY_SESSION_FAILURE = 'auth/POST_SHOPIFY_SESSION_FAILURE'
export const POST_SHOPIFY_SESSION_REQUEST = 'auth/POST_SHOPIFY_SESSION_REQUEST'

/* Refresh token */
export const POST_REFRESH_TOKEN_SUCCESS = 'auth/POST_REFRESH_TOKEN_SUCCESS'
export const POST_REFRESH_TOKEN_FAILURE = 'auth/POST_REFRESH_TOKEN_FAILURE'
export const POST_REFRESH_TOKEN_REQUEST = 'auth/POST_REFRESH_TOKEN_REQUEST'

/* user actions */

export const GET_GENERATED_USERNAMES_SUCCESS =
  'auth/GET_GENERATED_USERNAMES_SUCCESS'
export const GET_GENERATED_USERNAMES_FAILURE =
  'auth/GET_GENERATED_USERNAMES_FAILURE'
export const GET_GENERATED_USERNAMES_REQUEST =
  'auth/GET_GENERATED_USERNAMES_REQUEST'
export const GET_USER_SUCCESS = 'auth/GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'auth/GET_USER_FAILURE'
export const GET_USER_REQUEST = 'auth/GET_USER_REQUEST'
export const PUT_USER_SUCCESS = 'auth/PUT_USER_SUCCESS'
export const PUT_USER_FAILURE = 'auth/PUT_USER_FAILURE'
export const PUT_USER_REQUEST = 'auth/PUT_USER_REQUEST'
export const PUT_USER_SETTINGS_SUCCESS = 'auth/PUT_USER_SETTINGS_SUCCESS'
export const PUT_USER_SETTINGS_FAILURE = 'auth/PUT_USER_SETTINGS_FAILURE'
export const PUT_USER_SETTINGS_REQUEST = 'auth/PUT_USER_SETTINGS_REQUEST'
