import { all } from 'redux-saga/effects'

import { common } from 'common/sagas'

import { community } from 'communityApp/sagas'
import signupV2 from 'signupApp/sagas'

export default function* rootSaga() {
  yield all([community(), common(), signupV2()])
}
