import { useCallback, useState } from 'react'

const useToggle = (initialState = false) => {
  const [toggle, setToggle] = useState(initialState)

  const toggleOn = useCallback(() => setToggle(true), [setToggle])

  const toggleOff = useCallback(() => setToggle(false), [setToggle])

  return [toggle, toggleOn, toggleOff]
}

export default useToggle
