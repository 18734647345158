import rtApi from '@roosterteethproductions/svod-api'
import { push } from 'redux-first-history'

import { authenticateGoogleUser, notice } from 'common/helpers'
import {
  handleChannelsSuccess,
  handleError,
  handleSuccess,
} from 'common/reducers/youtubeAuthReducer'

import { SCOPE_YOUTUBE_READONLY } from './constants'

const getYouTubeChannelIdFromResponse = data =>
  data?.items.find(({ id }) => !!id)?.id

const getYouTubeChannel = (token, selectedChannel) => async dispatch => {
  const response = await fetch(
    'https://www.googleapis.com/youtube/v3/channels?part=id&mine=true',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  const payload = await response.json()
  const channelId = getYouTubeChannelIdFromResponse(payload)
  if (!channelId) {
    return dispatch(
      handleError(
        "We're sorry, we couldn't find a valid YouTube membership to link to your account for activating your FIRST benefits. Please ensure you have a valid membership and try again. Contact customer support if the issue persists."
      )
    )
  }
  rtApi.business
    .createYouTubeSubscription({ channelId, channelName: selectedChannel })
    .then(payload => {
      dispatch(handleSuccess(payload))
      dispatch(push('/'))
      notice({
        header: '&nbsp;&nbsp;Your Account has been Successfully Linked',
        body: '&nbsp;&nbsp;Reload Page to See Changes',
        hideTime: 60000,
      })
    })
    .catch(error => dispatch(handleError(error.message)))
}

export const fetchYouTubeChannels = () => dispatch => {
  rtApi.business.fetchYouTubeChannels().then(({ data }) => {
    dispatch(handleChannelsSuccess(data))
  })
}

export const youTubeConnect = () => (dispatch, getState) => {
  const { selectedChannel } = getState().commonReducer.youtubeAuth
  rtApi.business
    .checkUserSubscription({
      errorCallback: error => {
        dispatch(handleError(error.message))
      },
    })
    .then(async () => {
      try {
        const { token } = await authenticateGoogleUser({
          scope: SCOPE_YOUTUBE_READONLY,
          ux_mode: 'popup',
        })
        if (token) dispatch(getYouTubeChannel(token, selectedChannel))
      } catch (_e) {
        dispatch(
          handleError(
            'Oops! We need your permission to access your Google account. Please try again and grant us permission so we can finish linking your account.'
          )
        )
      }
    })
    .catch(error => {
      dispatch(handleError(error.message))
    })
}
