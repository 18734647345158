export const checkScrollbars = () => {
  const element = document.createElement('div')
  let scrollbars

  element.style.cssText = 'width:100px;height:100px;overflow:scroll'

  document.body.appendChild(element)

  if (element.offsetWidth === element.clientWidth) {
    scrollbars = 'hidden'
  } else {
    scrollbars = 'visible'
  }

  element.parentNode.removeChild(element)

  return scrollbars
}

/*
  Checks to see whether an HTMLElement (`elem`) is in the viewport or not.

  * The offset options (e.g., `bottomOffset`) can extend the area to check. For example, if we want to return true while an element is still 500px from being scrolled into view, we can use `bottomOffset: 500`.
  * The `mode` option is used to determine how to compare the element's dimensions to the viewport's dimensions.
    * If `mode` is `"all-visible"`, then the whole element must be in the viewport (plus offsets).
    * If `mode` is `"any-visible"`, then _any_ of the element must be in the viewport (plus offsets).
*/
export const isInViewport = (
  elem,
  {
    topOffset,
    rightOffset,
    bottomOffset,
    leftOffset,
    mode = 'all-visible',
  } = {}
) => {
  const bounding = elem.getBoundingClientRect()
  const topBoundary = 0 - (topOffset || 0)
  const leftBoundary = 0 - (leftOffset || 0)
  const bottomBoundary =
    (window.innerHeight || document.documentElement.clientHeight) +
    (bottomOffset || 0)
  const rightBoundary =
    (window.innerWidth || document.documentElement.clientWidth) +
    (rightOffset || 0)

  if (mode === 'all-visible') {
    return (
      bounding.top >= topBoundary &&
      bounding.left >= leftBoundary &&
      bounding.bottom <= bottomBoundary &&
      bounding.right <= rightBoundary
    )
  }

  return (
    bounding.top >= topBoundary ||
    bounding.left >= leftBoundary ||
    bounding.bottom <= bottomBoundary ||
    bounding.right <= rightBoundary
  )
}
