import React from 'react'

import heartEmoji from 'common/assets/icon-emoji-pride-heart.png'
import { Tooltip } from 'common/components'
import { trackEvent } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const heartFlagImage = new Image(90, 90)
heartFlagImage.src = heartEmoji

function resetParticle(particle) {
  particle.size = 45 - Math.random() * 10
}

function drawParticle(context, particle) {
  context.globalAlpha = particle.alpha
  context.drawImage(
    heartFlagImage,
    particle.x,
    particle.y,
    particle.size,
    particle.size
  )
}

const trackClick = () => trackEvent('Heart Button Clicked')

const PrideHearts = () => (
  <FallingParticlesEasterEggButton
    compression={0.8}
    drawParticle={drawParticle}
    particleCount={100}
    resetParticle={resetParticle}
    speed={1.2}
    stopAfter={100}
    element={({ toggleFalling, falling }) => (
      <Tooltip text='Celebrate Pride'>
        <div
          className={`pride-hearts-nav-button ${falling ? 'active' : ''}`}
          role='button'
          onClick={() => {
            trackClick()
            toggleFalling()
          }}
          onKeyPress={toggleFalling}
          tabIndex={0}
        >
          <img src={heartEmoji} alt='Pride heart' />
        </div>
      </Tooltip>
    )}
  />
)

export default PrideHearts
