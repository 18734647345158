import short from 'short-uuid'
import URLSearchParams from 'url-search-params'

import { accessTokenKey } from 'common/config'

import { getCookie, setAccessTokenCookie } from './storage'

export * from './authenticate-apple-user'
export * from './authenticate-facebook-user'
export * from './authenticate-google-user'
export * from './clipboard'
export * from './comments'
export * from './community'
export * from './compliance'
export * from './conversion'
export * from './get-urls'
export * from './http-statuses'
export * from './images'
export * from './is-mobile'
export * from './notice'
export * from './notifications'
export * from './paths'
export * from './percentages'
export * from './platform'
export * from './post'
export * from './preview'
export * from './pricing'
export * from './roles'
export * from './scroll'
export * from './sort'
export * from './storage'
export * from './time'
export * from './timeout-promise'
export * from './uploads'
export * from './validation'
export * from './withDateBasedFeatureFlag'

/** *****************
 *
 *  Helpers
 *
 ******************* */

export const addOrReplacePositionObj = (arr, obj) => {
  let index = -1
  const newArr = arr || []
  newArr.filter((el, pos) => {
    if (el.uuid === obj.uuid) {
      delete newArr[(index = pos)]
    }
    return true
  })
  if (obj.uuid && obj.value) {
    index === -1 ? newArr.push(obj) : (newArr[index] = obj)
  }
  return newArr
}

export function handleInputChange({ target }, cb) {
  const { name, type } = target
  const value = type === 'checkbox' ? target.checked : target.value

  this.setState(
    (state, _props) => ({
      formData: { ...state.formData, [name]: value },
    }),
    cb
  )
}

// Return true if a variable is undefined
export const isUndef = obj => typeof obj === 'undefined'

// Handle API error messages
export const handleErrors = (response, errorCallback) => {
  if (!response.ok) {
    // If we have a 400 error, we won't have a body so return statusText as error
    let error
    const status = response.status
    if (!response.bodyUsed) {
      return response
        .clone()
        .json()
        .then(message => {
          if (typeof errorCallback === 'function') {
            errorCallback(message)
          }
          if (message.extra_info) {
            error = Error(message.extra_info)
          } else if (message.errors) {
            if (Array.isArray(message.errors)) {
              error = Error(message.errors.join(', '))
            } else {
              for (const key in message.errors) {
                if (Object.prototype.hasOwnProperty.call(message.errors, key)) {
                  error = Error(`${key}: ${message.errors[key].join(', ')}`)
                }
              }
            }
          } else if (message.message) {
            error = Error(message.message)
          } else if (message.error) {
            if (message.error.message) {
              error = Error(message.error.message)
            } else {
              error = Error(message.error)
            }
          }
          if (error === undefined) {
            error = Error('Unknown Error')
          }
          error.status = status
          throw error
        })
    }
    error = Error(response.statusText)
    error.status = status
    throw error
  }
  return response
}

export const getDomain = (url, subdomain) => {
  subdomain = subdomain || false
  url = url || window.location.href
  url = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (!subdomain) {
    url = url.split('.')
    url = url.slice(url.length - 2).join('.')
  }

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0]
  }
  return url
}

// Get access token from cookies or from the current URL.
export const getAccessToken = () => {
  // Check to see if the token exists in the URL. If it does, set it in cookies and return it.
  const tokenURL = new URLSearchParams(window.location.search).get('token')
  if (tokenURL) {
    setAccessTokenCookie(tokenURL)
    return tokenURL
  }

  // Check to see if the token exists in cookies.
  return getCookie(accessTokenKey) || null
}

export const getEnv = () => {
  const domain = getDomain(null, true)
  const env = domain
    .split('.')
    .filter(d => ['staging', 'local', 'qa', 'pe'].indexOf(d) !== -1)
  if (env.length !== 0) {
    if (env.indexOf('pe') !== -1) return `${domain.split('.')[0]}.pe.`
    return `${env[0]}.`
  }
  return ''
}

export const pageTitle = string => `${string} - Rooster Teeth`

const redacted = '[redacted]'
export function redactState(state) {
  const initUser = state.authReducer?.user
  let defaultAttrs = {}
  if (initUser) {
    defaultAttrs = initUser.attributes
  }
  const attributes = {
    ...defaultAttrs,
    birthday: redacted,
    email: redacted,
  }

  const user = {
    ...state.authReducer?.user,
    attributes,
    global_login_token: redacted,
  }

  const authReducer = {
    ...state.authReducer,
    user,
    client_credentials: redacted,
  }

  return { ...state, authReducer }
}

export const getQueryParams = query => {
  if (!query) {
    return {}
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=')

      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''

      return params
    }, {})
}

export const getQueryString = obj =>
  Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&')

export const objectsByKey = (array, key) => {
  const object = {}

  for (const item of array) {
    object[item[key].toLowerCase()] = item
  }

  return object
}

// given an element recurse until document or form is found
export function findForm(el) {
  if (!el || el === document.body) {
    return false
  }
  if (el.tagName === 'FORM') {
    return el
  }
  return findForm(el.parentElement)
}

// takes a String and returns it truncated by number of characters
export function truncStringByChar(string, limit) {
  return string.substring(0, limit - 1) + (string.length > limit ? '...' : '')
}

// takes a uuid String and shortens it
export function encodeUuid(uuid) {
  const translator = short()
  return translator.fromUUID(uuid)
}

// takes a shortened uuid String and returns the original
export function decodeUuid(uuid) {
  const translator = short()
  return translator.toUUID(uuid)
}

// given a form element return all inputs as object
export function formToObject(formElement) {
  const form = new FormData(formElement)
  const formData = {}

  for (const [input, value] of form.entries()) {
    formData[input] = value
  }
  return formData
}

// given an element, return true if that element is inside a #modal
export function containedByModal(el) {
  if (!el || el === document.body) {
    return false
  }
  if (el.id === 'modal') {
    return true
  }
  return containedByModal(el.parentElement)
}

export function waitForExistence(getValue, timeout = 300, checkTruthy = false) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        // some logic to check if script is loaded
        // usually it something global in window object
        if (
          (checkTruthy && getValue()) ||
          (!checkTruthy && getValue() !== undefined)
        ) {
          return resolve()
        }
        if (waited >= timeout * 1000) {
          return reject(
            new Error(
              `The desired condition was never met. Waited ${timeout} second(s).`
            )
          )
        }
        wait(interval * 2)
      }, interval)
    }

    wait(30)
  })
}

export function waitForTruthy(getValue, timeout = 300) {
  return waitForExistence(getValue, timeout, true)
}

export function waitForGlobal(name, timeout = 300) {
  return waitForExistence(() => window[name], timeout)
}

export function copyStyles(sourceDoc, targetDoc) {
  for (const styleSheet of Array.from(sourceDoc.styleSheets)) {
    // console.log('style', styleSheet)

    if (styleSheet.href) {
      // true for stylesheets loaded from a URL
      const newLinkEl = sourceDoc.createElement('link')

      newLinkEl.rel = 'stylesheet'
      newLinkEl.href = styleSheet.href
      targetDoc.head.appendChild(newLinkEl)
    } else if (styleSheet?.cssRules) {
      // true for inline styles
      const newStyleEl = sourceDoc.createElement('style')

      for (const cssRule of Array.from(styleSheet.cssRules)) {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText))
      }

      targetDoc.head.appendChild(newStyleEl)
    }
  }
}
