import React, { Component } from 'react'
import { Dropdown } from 'materialize-css'
import { Link } from 'react-router-dom'

export default class CommunityDropdown extends Component {
  dropdownRef = React.createRef()

  componentDidMount() {
    Dropdown.init(this.dropdownRef.current, {
      alignment: 'left',
      constrainWidth: false,
      coverTrigger: false,
    })
  }

  render() {
    const { communities } = this.props

    return (
      <>
        <a
          className='nav-explore dropdown-trigger community-nav__link'
          data-target='community-dropdown'
          href='#groups-menu'
          ref={this.dropdownRef}
        >
          Groups <span className='icon icon-angle-down' />
        </a>

        <ul id='community-dropdown' className='dropdown-content'>
          <li>
            <Link className='community-nav__dropdown-link' to='/g/explore'>
              Explore All
            </Link>
          </li>

          <li className='divider' />
          <li className='section-header'>
            <div>Subscriptions</div>
          </li>
          {communities.map(({ community }) => (
            <li key={`group-dropdown-${community.id}`}>
              <Link
                className='community-nav__dropdown-link'
                to={`/g/${community.slug}`}
              >
                {community.name}
              </Link>
            </li>
          ))}
        </ul>
      </>
    )
  }
}
