import {
  GET_FEEDS_FAILURE,
  GET_FEEDS_REQUEST,
  GET_FEEDS_SUCCESS,
} from 'common/actions/types'

const initialState = {
  feeds: {},
  isLoading: false,
}

function feedReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_FEEDS_SUCCESS:
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [action.slug]: action.feed,
        },
        isLoading: false,
      }
    case GET_FEEDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default feedReducer
