// Return true if a nav should be sticky, and false if it should not be.
const handleNavStickiness = () => {
  // if the marketing banner is live then adjust the main nav with this offset
  const marketingBanner = document.querySelector('.top-marketing-banner__wrap')
  let topOffset = 0

  if (marketingBanner) {
    // Use at least 50px padding if the marketing banner exists but the image has not yet loaded
    topOffset = topOffset + marketingBanner?.offsetHeight || 50
  }

  return window.scrollY >= topOffset
}

export default handleNavStickiness
