import { isTimeInFuture } from './time'

export const ROLE_ADMIN = 'admin'
export const ROLE_CHAT_ADMIN = 'chat_admin'
export const ROLE_COMMUNITY_ADMIN = 'community_admin'
export const ROLE_GLOBAL_COMMUNITY_ADMIN = 'global_community_admin'
export const ROLE_GLOBAL_CHAT_ADMIN = 'global_chat_admin'
export const ROLE_GLOBAL_CHAT_MODERATOR = 'global_chat_moderator'
export const ROLE_GLOBAL_STORE_ADMIN = 'global_store_admin'
export const ROLE_GLOBAL_MODERATOR = 'global_moderator'

// Checks for roles on either user or user.attributes to allow calling with different user structures.
const getRoles = user => user?.attributes?.roles || user?.roles || false

export const hasRole = (role, user) => {
  const roles = getRoles(user)

  return roles?.includes ? roles.includes(role) : false
}

export const canUserWatch = (user, content) => {
  if (!content) return false

  return pureCanUserWatch(
    user?.attributes?.member_tier_i,
    content.attributes.member_tier_i,
    content.attributes.member_golive_at
  )
}

export const pureCanUserWatch = (
  userMemberTier,
  contentMemberTier,
  contentMemberGoLiveAt
) => {
  // check if content is free (logged-in only)
  if (contentMemberTier >= 0) {
    // compare content permissions to user permissions and return true if the user can watch the current content
    return userMemberTier >= contentMemberTier
  }
  // account for windowed content (content that is viewable for FIRST members before it is viewable to everyone else)
  if (isTimeInFuture(contentMemberGoLiveAt)) {
    return userMemberTier > 0
  }
  return true
}

export const isUserFirst = user => user?.attributes?.member_tier === 'first'
