import { rem } from 'polished'
import styled from 'styled-components'

import scssVariables from 'rootApp/components/TopNav/styles.scss'

export const SearchBtnBase = styled.button`
  align-items: center;
  appearance: none !important; // Materialize
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  filter: drop-shadow(var(--top-nav-shadow));
  height: ${scssVariables.topNavHeight};
  margin-right: var(--top-nav-account-item-margin);
  padding: 0;
  transition: color var(--top-nav-icon-transition);

  &:active,
  &:hover {
    color: #f7f7f8;
  }

  &:focus {
    background: none;
  }

  > svg {
    fill: currentColor;
    height: ${rem(22, 15)};
    width: ${rem(22, 15)};
  }
`

SearchBtnBase.defaultProps = {
  type: 'button',
}
