import React, { Component, Fragment } from 'react'
import { Dropdown } from 'materialize-css'
import PropTypes from 'prop-types'

class IconDropdown extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    options: PropTypes.object,
  }

  static defaultProps = {
    options: {},
  }

  id = `${Math.random()}`

  instance = null

  toggleRef = React.createRef()

  componentDidMount() {
    const { options } = this.props

    this.instance = Dropdown.init(this.toggleRef.current, {
      alignment: 'right',
      constrainWidth: false,
      ...options,
    })
  }

  componentWillUnmount() {
    this.instance.destroy()
  }

  render() {
    const { children } = this.props

    return (
      <Fragment>
        <a
          className='dropdown-control link-overlay'
          data-target={this.id}
          href='#toggle-dropdown'
          ref={this.toggleRef}
        >
          <i className='icon icon-more_horiz lg' />
        </a>

        <ul id={this.id} className='dropdown-content'>
          {children}
        </ul>
      </Fragment>
    )
  }
}

export default IconDropdown
