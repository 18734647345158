import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'
import {
  DELETE_3_PARTY_DISCONNECT_FAILURE,
  DELETE_3_PARTY_DISCONNECT_REQUEST,
  DELETE_3_PARTY_DISCONNECT_SUCCESS,
  POST_3_PARTY_CONNECT_FAILURE,
  POST_3_PARTY_CONNECT_REQUEST,
  POST_3_PARTY_CONNECT_SUCCESS,
} from 'auth/actions/types'

const delete3PartyDisconnectRequest = () => ({
  type: DELETE_3_PARTY_DISCONNECT_REQUEST,
})

const delete3PartyDisconnectSuccess = () => ({
  type: DELETE_3_PARTY_DISCONNECT_SUCCESS,
})

const delete3PartyDisconnectFailure = message => ({
  type: DELETE_3_PARTY_DISCONNECT_FAILURE,
  message,
})

const post3PartyConnectRequest = () => ({
  type: POST_3_PARTY_CONNECT_REQUEST,
})

const post3PartyConnectSuccess = () => ({
  type: POST_3_PARTY_CONNECT_SUCCESS,
})

const post3PartyConnectFailure = message => ({
  type: POST_3_PARTY_CONNECT_FAILURE,
  message,
})

export const delete3PartyDisconnect = (type, errorCallback, token = null) => (
  dispatch,
  _getState
) => {
  if (!type) {
    return Promise.reject(
      new Error('Please provide third party type [apple, facebook, google]')
    )
  }
  dispatch(delete3PartyDisconnectRequest())
  let apiCall = null
  if (type === 'apple') {
    apiCall = () => rtApi.auth.disconnectApple({ errorCallback })
  } else if (type === 'facebook') {
    apiCall = () => rtApi.auth.disconnectFacebook({ errorCallback })
  } else if (type === 'google') {
    apiCall = () => rtApi.auth.disconnectGoogle({ token, errorCallback })
  }
  return apiCall()
    .then(_json => Promise.resolve(dispatch(delete3PartyDisconnectSuccess())))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(
        dispatch(delete3PartyDisconnectFailure(ex.toString()))
      )
    })
}

export const post3PartyConnect = (type, { token }, errorCallback) => (
  dispatch,
  _getState
) => {
  if (!type) {
    return Promise.reject(
      new Error('Please provide third party type [apple, facebook, google]')
    )
  }
  dispatch(post3PartyConnectRequest())
  let apiCall = null

  if (type === 'apple') {
    apiCall = () => rtApi.auth.connectApple({ code: token, errorCallback })
  } else if (type === 'facebook') {
    apiCall = () => rtApi.auth.connectFacebook({ token, errorCallback })
  } else if (type === 'google') {
    apiCall = () => rtApi.auth.connectGoogle({ token, errorCallback })
  }

  return apiCall()
    .then(_json => Promise.resolve(dispatch(post3PartyConnectSuccess())))
    .catch(error => {
      if (error.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(
        dispatch(post3PartyConnectFailure(error.toString()))
      )
    })
}
