import rtApi from '@roosterteethproductions/svod-api'
import Honeybadger from 'honeybadger-js'
import { all, call, put, select } from 'redux-saga/effects'

import getEnterpriseReCAPTCHAToken from 'common/helpers/get-enterprise-recaptcha-token'
import { getAnalyticsMemberTier } from 'common/metrics'

import * as authActions from 'auth/actions'
import trackRssSignupConversion from 'showsApp/sagas/trackRssSignupConversion'
import {
  setEmailError,
  setPasswordError,
  setTermsAgreedCheckedError,
} from 'signupApp/actions'
import {
  genericCreateAccountError,
  VIEW_CREATE_ACCOUNT,
} from 'signupApp/constants'
import { trackSignupFlowEvent } from 'signupApp/helpers'
import getUserObjectSaga from 'signupApp/sagas/getUserObjectSaga'
import { getCampaign, getSignupReducer } from 'signupApp/selectors'

const createUserAndPreserveResponse = ({ reCAPTCHAToken, email, password }) =>
  new Promise(resolve =>
    rtApi.users
      .create({
        body: {
          recaptcha_response: reCAPTCHAToken,
          user: { email, password },
        },
        extraHeaders: window.skipRecaptchaToken
          ? { SKIP_RECAPTCHA: window.skipRecaptchaToken }
          : {},

        // rtApi doesn't give us all the errors it received from the Rails services, which can be valuable. We grab those from here.
        errorCallback: originalErrors => resolve(originalErrors),
      })
      .then(success => resolve(success))
      // This call to `resolve` is ignored if `errorCallback` happens first. This only gets called if an rtApi error happens that doesn't fire `errorCallback`. Not sure what would cause this to be fired without errorCallback.
      .catch(wrappedError => resolve(wrappedError))
  )

const CREATED_STATUS_CREATED = 'CREATED_STATUS_CREATED'
const CREATED_STATUS_INVALID = 'CREATED_STATUS_INVALID'
const CREATED_STATUS_ALREADY_EXISTS = 'CREATED_STATUS_ALREADY_EXISTS'

// NOTE: This saga basically just re-composes logic from the thunks `postUser`, `loginUser`, and `getUserObject` defined in `signupActions` and `userActions`. It emits actions such as `loginRequest` and `loginSuccess` to be consistent with the existing signup flow and auth reducer.
function* createUserSaga() {
  const token = yield call(getEnterpriseReCAPTCHAToken, ['Signup V2'])

  try {
    const { email, password, termsAgreedChecked } = yield select(
      getSignupReducer
    )

    const passedPreliminaryErrorCheck = yield call(
      handlePreliminaryErrorsSaga,
      {
        email,
        password,
        termsAgreedChecked,
      }
    )

    if (!passedPreliminaryErrorCheck) return

    const createParams = { reCAPTCHAToken: token, email, password }
    const createdStatus = yield call(
      createUserFromReCAPTCHATokenSaga,
      createParams
    )
    if (createdStatus === CREATED_STATUS_INVALID) return

    const loggedIn = yield call(loginUserSaga, { email, password })

    if (!loggedIn) {
      if (createdStatus === CREATED_STATUS_ALREADY_EXISTS) {
        // We attempted to log the existing user in using the signup form, but the credentials were invalid.
        // signupV2
        yield put(setEmailError('That email has been taken'))
      }

      return
    }

    // Get the user object and allow the components to perform the redirect based on the current view and the logged in user.
    const user = yield call(getUserObjectSaga)

    const campaign = yield select(getCampaign)
    trackSignupFlowEvent(VIEW_CREATE_ACCOUNT, {
      campaign,
      option_selected: 'email',
      state: 'exited',
      user_tier: getAnalyticsMemberTier(user),
    })

    yield call(trackRssSignupConversion)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Unhandled error in createUserSaga', error)
  }
}

// If these issues are present in the form, we don't even attempt to submit an API request, but instead just show client side errors. Returns true if no preliminary errors were seen. Returns false if there were errors.
function* handlePreliminaryErrorsSaga({ email, password, termsAgreedChecked }) {
  const preliminaryErrorActions = []

  // Terms not checked
  if (!termsAgreedChecked) {
    preliminaryErrorActions.push(
      // signupV2
      put(
        setTermsAgreedCheckedError(
          'Agreeing to the Terms of Use is required to create your account'
        )
      )
    )
  }

  // Email is an empty string
  if (!email) {
    preliminaryErrorActions.push(
      // signupV2
      put(setEmailError('Please provide an email address.'))
    )
  }

  // Password is an empty string
  if (!password) {
    preliminaryErrorActions.push(
      // signupV2
      put(setPasswordError('Please provide a password.'))
    )
  }

  if (preliminaryErrorActions.length > 0) {
    yield all(preliminaryErrorActions)

    return false
  }

  return true
}

// Will return CREATED_STATUS_CREATED, CREATED_STATUS_ALREADY_EXISTS, or CREATED_STATUS_INVALID
function* createUserFromReCAPTCHATokenSaga({
  reCAPTCHAToken,
  email,
  password,
}) {
  // authReducer
  yield put(authActions.postUserRequest())

  const createUserResult = yield call(createUserAndPreserveResponse, {
    reCAPTCHAToken,
    email,
    password,
  })

  // All is well if we created a user.
  if (createUserResult.type === 'user') {
    return CREATED_STATUS_CREATED
  }

  // Otherwise, call the error handler (it will call postUserFailure for us.)
  const createdStatus = yield call(handleCreateUserErrorsSaga, {
    createUserResult,
    email,
    reCAPTCHAToken,
  })

  return createdStatus
}

// Will return either CREATED_STATUS_ALREADY_EXISTS or CREATED_STATUS_INVALID
function* handleCreateUserErrorsSaga({
  createUserResult,
  email,
  reCAPTCHAToken,
}) {
  // If the email address already exists, attempt to simply log the user in via the email and password provided.
  if (createUserResult?.errors?.email?.includes('has already been taken')) {
    yield put(
      // authReducer
      authActions.postUserFailure(
        'Could not create a user because that email has been taken.'
      )
    )

    return CREATED_STATUS_ALREADY_EXISTS
  }

  // For any other errors, just say the corresponding field is not valid.
  const errorActions = []
  if (createUserResult?.errors?.email) {
    errorActions.push(put(setEmailError('Please enter a valid email address.')))
  }

  if (createUserResult?.errors?.password) {
    errorActions.push(put(setPasswordError('Please enter a valid password.')))
  }

  const responseMentionsReCAPTCHA = JSON.stringify(createUserResult)?.match(
    /captcha/i
  )

  if (responseMentionsReCAPTCHA) {
    Honeybadger.notify('reCAPTCHA failed while trying to create a new user.', {
      context: { createUserApiResult: createUserResult, email },
    })
    // Notify the console so that UATs / Devs can verify whether the generic error was related to reCAPTCHA.
    // eslint-disable-next-line no-console
    console.error(genericCreateAccountError, {
      reCAPTCHAToken,
      createUserResult,
    })
  }

  // If we couldn't identify a specific issue above, make sure the user at least sees a generic error message.
  if (errorActions.length === 0) {
    errorActions.push(put(setEmailError(genericCreateAccountError)))
  }

  // signupV2
  yield all(errorActions)

  // Put action for authReducer
  yield put(
    authActions.postUserFailure(
      'An error occurred while trying to create an account.'
    )
  )

  return CREATED_STATUS_INVALID
}

// Returns true if the user was logged in, returns false if the user was not logged in.
function* loginUserSaga({ email, password }) {
  // authReducer
  yield put(authActions.loginRequest())

  const loginResult = yield call(() =>
    // Don't reject. Just return the error.
    rtApi.auth.login({ username: email, password }).catch(e => e)
  )

  const userNotLoggedIn = !loginResult.uuid
  if (userNotLoggedIn) {
    let error
    if (loginResult.message) {
      error = loginResult.message
    } else {
      error =
        'An unexpected error occured while trying to sign a user in for the first time after account creation.'
    }
    if (error.includes('reCAPTCHA')) {
      yield put(
        // signupV2
        setEmailError("Uh oh, looks like reCAPTCHA doesn't think you're human.")
      )
    }

    Honeybadger.notify(error, { context: { loginApiResponse: loginResult } })

    // authReducer
    yield put(authActions.loginError(error))
    return false
  }

  // authReducer
  yield put(authActions.loginSuccess())
  return true
}

export default createUserSaga
