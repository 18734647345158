export default (state = {}, { type, data, req, pagination }) => {
  switch (type) {
    case 'communityApp/c/getTaggedPosts_RESP':
      return {
        ...state,
        [req.tag]: data,
      }
    default:
      return state
  }
}
