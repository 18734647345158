import Linkify from 'linkify-it'
import tlds from 'tlds'

const linkifyInstance = Linkify()
linkifyInstance.tlds(tlds).add('ftp:', null) // remove ftp

// @note we're using linkify-it because it's already being used by
// linkify-react AND it actually minifies for web :D
/**
 * Pulls urls from a string
 * @param {String} str - string to pull urls from
 * @return {Array} array of matches
 */
export function getUrls(str) {
  if (!str) {
    return []
  }
  const matches = linkifyInstance.match(str)
  if (!matches) {
    return []
  }
  return matches.map(match => match.url)
}
