import { takeEvery } from 'redux-saga/effects'

import { config } from 'common/config'
import { simpleGET } from 'common/helpers/saga'

export function* checkLink({ url }) {
  const endcodedUrl = encodeURIComponent(url)
  yield simpleGET({
    url: `https://api.embedly.com/1/oembed?key=${
      config.embedly.publicKey
    }&url=${endcodedUrl}`,
    type: 'common/RICHMEDIA_CHECK_LINK',
    method: 'GET',
  })
}

export function* richMedia() {
  yield takeEvery('common/RICHMEDIA_CHECK_LINK', checkLink)
}
