import rtApi from '@roosterteethproductions/svod-api'

import { getEpisodePercentages } from 'common/helpers'

import {
  GET_ALL_EPS_PERCENT_FAILURE,
  GET_ALL_EPS_PERCENT_REQUEST,
  GET_ALL_EPS_PERCENT_SUCCESS,
  GET_EPISODE_FAILURE,
  GET_EPISODE_REQUEST,
  GET_EPISODE_SUCCESS,
  GET_EPISODES_FAILURE,
  GET_EPISODES_REQUEST,
  GET_EPISODES_SUCCESS,
  GET_NEXT_PAGE_EPISODES_FAILURE,
  GET_NEXT_PAGE_EPISODES_REQUEST,
  GET_NEXT_PAGE_EPISODES_SUCCESS,
} from './types'

const EPISODES_PER_PAGE = 24

// --------
// GET one episode
// --------
const requestEpisode = () => ({
  type: GET_EPISODE_REQUEST,
})

const receiveEpisode = json => ({
  type: GET_EPISODE_SUCCESS,
  episode: json.data[0] || {},
  receivedAt: Date.now(),
})

const requestEpisodeFailure = error => ({
  type: GET_EPISODE_FAILURE,
  error,
})

export const fetchEpisode = slug => dispatch => {
  dispatch(requestEpisode())
  rtApi.episodes
    .fetchId({ id: slug })
    .then(json => {
      if (!json.data || !json.data.length) {
        throw new Error('Resource not found')
      } else {
        dispatch(receiveEpisode(json))
      }
    })
    .catch(ex => {
      dispatch(requestEpisodeFailure(ex.message))
    })
}

// --------
// GET all episodes
// --------
const requestAllEpisodes = (filter, sort) => ({
  type: GET_EPISODES_REQUEST,
  filter,
  sort,
})

const receiveAllEpisodes = (
  filter,
  { data: items, page, total_pages, total_results }
) => ({
  type: GET_EPISODES_SUCCESS,
  filter,
  items,
  page,
  total_pages,
  total_results,
  receivedAt: Date.now(),
})

const fetchAllEpisodesFailure = ex => ({
  type: GET_EPISODES_FAILURE,
  error: ex.message,
})

export const fetchAllEpisodes = (myFilter, sort, page = 1) => dispatch => {
  dispatch(requestAllEpisodes(myFilter, sort))
  let filter = myFilter
  if (myFilter.value === 'all') {
    filter = undefined
  }
  rtApi.episodes
    .fetchAll({ filter, sort, page })
    .then(json => dispatch(receiveAllEpisodes(myFilter, json)))
    .then(json => dispatch(fetchAllEpisodesPercentage(json)))
    .catch(ex => dispatch(fetchAllEpisodesFailure(ex)))
}

// Get All Episodes Watched percentage
const requestAllEpisodesPercent = () => ({
  type: GET_ALL_EPS_PERCENT_REQUEST,
})

const receiveAllEpisodesPercent = items => ({
  type: GET_ALL_EPS_PERCENT_SUCCESS,
  items,
})

const fetchAllEpisodesPercentFailure = ex => ({
  type: GET_ALL_EPS_PERCENT_FAILURE,
  error: ex.message,
})

export const fetchAllEpisodesPercentage = allData =>
  getEpisodePercentages({
    failureAction: fetchAllEpisodesPercentFailure,
    items: allData.items,
    requestAction: requestAllEpisodesPercent,
    successAction: receiveAllEpisodesPercent,
  })

// --------
// GET episodes one page at a time
// --------
const getNextPageEpisodes = (filter, sort) => ({
  type: GET_NEXT_PAGE_EPISODES_REQUEST,
  filter,
  sort,
})

const receiveNextPageEpisodes = (
  filter,
  { data: items, page, total_pages, total_results }
) => ({
  type: GET_NEXT_PAGE_EPISODES_SUCCESS,
  filter,
  items,
  page,
  total_pages,
  total_results,
  receivedAt: Date.now(),
})

const fetchNextPageEpisodesFailure = ex => ({
  type: GET_NEXT_PAGE_EPISODES_FAILURE,
  error: ex.message,
})

export const fetchNextPageEpisodes = (myFilter, sort, page = 1) => dispatch => {
  dispatch(getNextPageEpisodes(filter, sort))
  let filter = myFilter
  if (myFilter.value === 'all') {
    filter = undefined
  }
  return rtApi.episodes
    .fetchAll({ filter, sort, page, perPage: EPISODES_PER_PAGE })
    .then(json => dispatch(receiveNextPageEpisodes(filter, json)))
    .then(json => dispatch(fetchAllEpisodesPercentage(json)))
    .catch(ex => dispatch(fetchNextPageEpisodesFailure(ex)))
}
