import { replace } from 'redux-first-history'

import {
  removeParamFromSearchString,
  setParamInSearchString,
} from 'common/helpers/search-params-util'

export const removeSearchParam = param => (dispatch, getState) => {
  const location = getState().router.location

  dispatch(
    replace({
      ...location,
      search: removeParamFromSearchString(location.search, param),
    })
  )
}

export const setSearchParam = (param, value) => (dispatch, getState) => {
  const location = getState().router.location

  dispatch(
    replace({
      ...location,
      search: setParamInSearchString(location.search, param, value),
    })
  )
}
