import { LOCATION_CHANGE } from 'redux-first-history'
import URLSearchParams from 'url-search-params'

import { config } from 'common/config'

// Stickied flags pulled from localstorage and override env vars
function getFlagsFromStorage() {
  const flagsFromStorage = {}
  for (const storeKey in localStorage) {
    if (storeKey.startsWith('ff_')) {
      const storeValue = window.localStorage.getItem(storeKey)
      flagsFromStorage[storeKey.slice(3)] = storeValue
    }
  }
  return flagsFromStorage
}

function getFlagsFromSearch(params) {
  const searchFlags = {}

  for (const [queryParamName, queryParamValue] of params) {
    if (queryParamName && queryParamName.startsWith('ff_')) {
      const flagName = queryParamName.slice(3)
      const flagEnabled = queryParamValue !== 'false'
      searchFlags[flagName] = flagEnabled
    }
  }
  return searchFlags
}

// set a feature flag via querystring param eg ff_comments=true
// make that the default for your browser via sticky
// clear via ?sticky&ff_flag=false
// set default for everyone above in initial state
function locationChange(state, action) {
  if (action.payload.location.search.includes('ff_')) {
    const params = new URLSearchParams(action.payload.location.search)
    const searchFlags = getFlagsFromSearch(params)
    if (params.get('sticky') !== null && params.get('sticky') !== 'false') {
      for (const flagName of Object.keys(searchFlags)) {
        const isEnabled = searchFlags[flagName]
        if (isEnabled) {
          window.localStorage.setItem(`ff_${flagName}`, isEnabled)
        } else {
          localStorage.removeItem(`ff_${flagName}`)
        }
      }
    }

    return { ...state, ...searchFlags }
  }
  return state
}

// All flags default to false
// use env vars to flip flag for everyone
// flags should be removed once feature is stable
// though this is a good place to put an 'emergency shut-off' for a set of features
// see common/config
export const initialState = { ...config.ff, ...getFlagsFromStorage() }

// TMR need to figure out what to set on location change, other
export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return locationChange(state, action)
    default:
      return state
  }
}
