// Get user
export const FETCH_COMMUNITY_USER = 'communityv2/FETCH_COMMUNITY_USER'
export const FETCH_COMMUNITY_USER_SUCCESSFUL =
  'communityv2/FETCH_COMMUNITY_USER_SUCCESSFUL'
export const FETCH_COMMUNITY_USER_FAILED =
  'communityv2/FETCH_COMMUNITY_USER_FAILED'

// See who a user follows
export const FETCH_COMMUNITY_USER_FOLLOWING =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWING'
export const FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL'
export const FETCH_COMMUNITY_USER_FOLLOWING_FAILED =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWING_FAILED'

// See who follows a user
export const FETCH_COMMUNITY_USER_FOLLOWERS =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWERS'
export const FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL'
export const FETCH_COMMUNITY_USER_FOLLOWERS_FAILED =
  'communityv2/FETCH_COMMUNITY_USER_FOLLOWERS_FAILED'

// See groups in which a user is a member
export const FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER =
  'communityv2/FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER'
// See groups in which logged in user is a member
export const FETCH_MY_MEMBERSHIPS = 'communityv2/FETCH_MY_MEMBERSHIPS'
// These two actions have the same success/fail types
export const FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL =
  'communityv2/FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL'
export const FETCH_COMMUNITY_USER_MEMBERSHIPS_FAILED =
  'communityv2/FETCH_COMMUNITY_USER_MEMBERSHIPS_FAILED'

// Mute user
export const MUTE_COMMUNITY_USER = 'communityv2/MUTE_COMMUNITY_USER'
export const MUTE_COMMUNITY_USER_SUCCESSFUL =
  'communityv2/MUTE_COMMUNITY_USER_SUCCESSFUL'
export const MUTE_COMMUNITY_USER_FAILED =
  'communityv2/MUTE_COMMUNITY_USER_FAILED'

// Unmute user
export const UNMUTE_COMMUNITY_USER = 'communityv2/UNMUTE_COMMUNITY_USER'
export const UNMUTE_COMMUNITY_USER_SUCCESSFUL =
  'communityv2/UNMUTE_COMMUNITY_USER_SUCCESSFUL'
export const UNMUTE_COMMUNITY_USER_FAILED =
  'communityv2/UNMUTE_COMMUNITY_USER_FAILED'

// Follow user
export const FOLLOW_COMMUNITY_USER = 'communityv2/FOLLOW_COMMUNITY_USER'
export const FOLLOW_COMMUNITY_USER_SUCCESSFUL =
  'communityv2/FOLLOW_COMMUNITY_USER_SUCCESSFUL'
export const FOLLOW_COMMUNITY_USER_FAILED =
  'communityv2/FOLLOW_COMMUNITY_USER_FAILED'

// Unfollow user
export const UNFOLLOW_COMMUNITY_USER = 'communityv2/UNFOLLOW_COMMUNITY_USER'
export const UNFOLLOW_COMMUNITY_USER_SUCCESSFUL =
  'communityv2/UNFOLLOW_COMMUNITY_USER_SUCCESSFUL'
export const UNFOLLOW_COMMUNITY_USER_FAILED =
  'communityv2/UNFOLLOW_COMMUNITY_USER_FAILED'

// Conveniences for failing if user is logged out
export const FOLLOW_USER_IF_ALLOWED = 'communityv2/FOLLOW_USER_IF_ALLOWED'
export const UNFOLLOW_USER_IF_ALLOWED = 'communityv2/UNFOLLOW_USER_IF_ALLOWED'

// See home feed for logged in user
export const FETCH_HOME_FEED = 'communityv2/FETCH_HOME_FEED'
export const FETCH_HOME_FEED_SUCCESSFUL =
  'communityv2/FETCH_HOME_FEED_SUCCESSFUL'
export const FETCH_HOME_FEED_FAILED = 'communityv2/FETCH_HOME_FEED_FAILED'

// See activity for a community profile user
export const FETCH_USER_ACTIVITY_FEED = 'communityv2/FETCH_USER_ACTIVITY_FEED'
export const FETCH_USER_ACTIVITY_FEED_SUCCESSFUL =
  'communityv2/FETCH_USER_ACTIVITY_FEED_SUCCESSFUL'
export const FETCH_USER_ACTIVITY_FEED_FAILED =
  'communityv2/FETCH_USER_ACTIVITY_FEED_FAILED'

// Get sub-community
export const FETCH_SUB_COMMUNITY = 'communityv2/FETCH_SUB_COMMUNITY'
export const FETCH_SUB_COMMUNITY_SUCCESSFUL =
  'communityv2/FETCH_SUB_COMMUNITY_SUCCESSFUL'
export const FETCH_SUB_COMMUNITY_FAILED =
  'communityv2/FETCH_SUB_COMMUNITY_FAILED'

// See feed of activity within a sub-community
export const FETCH_SUB_COMMUNITY_FEED = 'communityv2/FETCH_SUB_COMMUNITY_FEED'
export const FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL =
  'communityv2/FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL'
export const FETCH_SUB_COMMUNITY_FEED_FAILED =
  'communityv2/FETCH_SUB_COMMUNITY_FEED_FAILED'

// See members of a sub-community
export const FETCH_MEMBERS_FOR_SUB_COMMUNITY =
  'communityv2/FETCH_MEMBERS_FOR_SUB_COMMUNITY'
export const FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL =
  'communityv2/FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL'
export const FETCH_MEMBERS_FOR_SUB_COMMUNITY_FAILED =
  'communityv2/FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY_FAILED'
export const FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY =
  'communityv2/FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY'

// Leave sub-community
export const LEAVE_SUB_COMMUNITY = 'communityv2/LEAVE_SUB_COMMUNITY'
export const LEAVE_SUB_COMMUNITY_SUCCESSFUL =
  'communityv2/LEAVE_SUB_COMMUNITY_SUCCESSFUL'
export const LEAVE_SUB_COMMUNITY_FAILED =
  'communityv2/LEAVE_SUB_COMMUNITY_FAILED'

// Join sub-community
export const JOIN_SUB_COMMUNITY = 'communityv2/JOIN_SUB_COMMUNITY'
export const JOIN_SUB_COMMUNITY_SUCCESSFUL =
  'communityv2/JOIN_SUB_COMMUNITY_SUCCESSFUL'
export const JOIN_SUB_COMMUNITY_FAILED = 'communityv2/JOIN_SUB_COMMUNITY_FAILED'

// Like, unlike, and resulting action post
export const LIKE_POST = 'communityv2/LIKE_POST'
export const UNLIKE_POST = 'communityv2/UNLIKE_POST'
export const LIKE_OR_UNLIKE_POST_SUCCESSFUL =
  'communityv2/LIKE_OR_UNLIKE_POST_SUCCESSFUL'

// Flag post
export const FLAG_POST = 'communityv2/FLAG_POST'

// Pin post to community
export const PIN_POST = 'communityv2/PIN_POST'
export const UNPIN_POST = 'communityv2/UNPIN_POST'

// Get own user and memberships, which are treated as one piece of information
export const FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS =
  'communityv2/FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS'

// Get some communities and posts to show on the "explore" page for both anonymous or logged in users.
export const FETCH_EXPLORE_POSTS_AND_COMMUNITIES =
  'communityv2/FETCH_EXPLORE_POSTS_AND_COMMUNITIES'
export const FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL =
  'communityv2/FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL'
export const FETCH_EXPLORE_POSTS_AND_COMMUNITIES_FAILED =
  'communityv2/FETCH_EXPLORE_POSTS_AND_COMMUNITIES_FAILED'

// Update user form fields
export const SET_IS_EDITING_USER = 'communityv2/SET_IS_EDITING_USER'
export const UPDATE_USER_FORM = 'communityv2/UPDATE_USER_FORM'

// Update user
export const SUBMIT_USER_UPDATE_FORM = 'communityv2/SUBMIT_USER_UPDATE_FORM'
export const SUBMIT_USER_UPDATE_FORM_SUCCESSFUL =
  'communityv2/SUBMIT_USER_UPDATE_FORM_SUCCESSFUL'
export const SUBMIT_USER_UPDATE_FORM_FAILED =
  'communityv2/SUBMIT_USER_UPDATE_FORM_FAILED'
