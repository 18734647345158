// Content Blocks Actions
export const FETCH_CONTENT_BLOCKS_REQUEST =
  'homeApp/FETCH_CONTENT_BLOCKS_REQUEST'
export const FETCH_CONTENT_BLOCKS_FAILED = 'homeApp/FETCH_CONTENT_BLOCKS_FAILED'
export const FETCH_CONTENT_BLOCKS_SUCCESSFUL =
  'homeApp/FETCH_CONTENT_BLOCKS_SUCCESSFUL'

// Featured Actions
export const GET_FEATURED_EP_REQUEST = 'homeApp/GET_FEATURED_EP_REQUEST'
export const GET_FEATURED_EP_SUCCESS = 'homeApp/GET_FEATURED_EP_SUCCESS'
export const GET_FEATURED_EP_FAILURE = 'homeApp/GET_FEATURED_EP_FAILURE'
export const GET_FEATURED_ITEM_V3_REQUEST =
  'homeApp/GET_FEATURED_ITEM_V3_REQUEST'
export const GET_FEATURED_ITEM_V3_SUCCESS =
  'homeApp/GET_FEATURED_ITEM_V3_SUCCESS'
export const GET_FEATURED_GEAR_REQUEST = 'homeApp/GET_FEATURED_GEAR_REQUEST'
export const GET_FEATURED_GEAR_SUCCESS = 'homeApp/GET_FEATURED_GEAR_SUCCESS'
export const GET_FEATURED_GEAR_FAILURE = 'homeApp/GET_FEATURED_GEAR_FAILURE'
export const GET_FEATURED_SHOW_REQUEST = 'homeApp/GET_FEATURED_SHOW_REQUEST'
export const GET_FEATURED_SHOW_SUCCESS = 'homeApp/GET_FEATURED_SHOW_SUCCESS'
export const GET_FEATURED_SHOW_FAILURE = 'homeApp/GET_FEATURED_SHOW_FAILURE'
