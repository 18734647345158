import rtApi from '@roosterteethproductions/svod-api'
import { replace } from 'redux-first-history'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN, notice } from 'common/helpers'
import communityApi from 'common/helpers/communityApi'
import wait from 'common/helpers/wait'
import {
  trackJoinSubCommunity,
  trackLeaveSubCommunity,
  trackLikeEvent,
  trackMute,
} from 'common/metrics'

import { logoutSuccess } from 'auth/actions'
import {
  getLoggedInUser,
  getLoggedInUserId,
  isLoggedIn,
  isVerified,
} from 'auth/selectors'
import {
  fetchCommunityUserFailed,
  fetchCommunityUserFollowersSuccesful,
  fetchCommunityUserFollowingSuccesful,
  fetchCommunityUserMembershipsSuccesful,
  fetchCommunityUserSuccesful,
  fetchExplorePostsAndCommunitiesSuccessful,
  fetchHomeFeedSuccessful,
  fetchMembersForSubCommunity,
  fetchMembersForSubCommunitySuccessful,
  fetchSubCommunityFailed,
  fetchSubCommunityFeedSuccessful,
  fetchSubCommunitySuccesful,
  fetchUserActivityFeedSuccessful,
  followUser,
  followUserSuccessful,
  likeOrUnlikePostSuccessful,
  muteUserSuccessful,
  submitUserUpdateFormFailed,
  submitUserUpdateFormSuccessful,
  unfollowUser,
  unfollowUserSuccessful,
  unmuteUserSuccessful,
  updateUserForm,
} from 'communityApp/actions/actions'
import {
  FETCH_COMMUNITY_USER,
  FETCH_COMMUNITY_USER_FOLLOWERS,
  FETCH_COMMUNITY_USER_FOLLOWING,
  FETCH_EXPLORE_POSTS_AND_COMMUNITIES,
  FETCH_HOME_FEED,
  FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY,
  FETCH_MEMBERS_FOR_SUB_COMMUNITY,
  FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER,
  FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS,
  FETCH_MY_MEMBERSHIPS,
  FETCH_SUB_COMMUNITY,
  FETCH_SUB_COMMUNITY_FEED,
  FETCH_USER_ACTIVITY_FEED,
  FLAG_POST,
  FOLLOW_COMMUNITY_USER,
  FOLLOW_USER_IF_ALLOWED,
  JOIN_SUB_COMMUNITY,
  LEAVE_SUB_COMMUNITY,
  LIKE_POST,
  MUTE_COMMUNITY_USER,
  PIN_POST,
  SET_IS_EDITING_USER,
  SUBMIT_USER_UPDATE_FORM,
  UNFOLLOW_COMMUNITY_USER,
  UNFOLLOW_USER_IF_ALLOWED,
  UNLIKE_POST,
  UNMUTE_COMMUNITY_USER,
  UNPIN_POST,
} from 'communityApp/actions/types'
import { StickyPrompt } from 'communityApp/components'
import { normalizeCommunityUser } from 'communityApp/helpersV2'
import {
  getCurrentSubCommunity,
  getLoggedInCommunityUser,
  getMyFeed,
  getSubCommunityById,
  getUserActivityFeed,
  getUserInfoFromStateAndUrl,
  getUserUpdateForm,
} from 'communityApp/selectors'
import { showModal } from 'modalApp/actions'

function* logoutOnFail(callback, afterLogout = () => {}) {
  try {
    yield callback()
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log('ex', ex)

    if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
      yield put(logoutSuccess())
      yield afterLogout()
    }
  }
}

function* muteUserSaga({ userId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.muteCommunityUser(userId)
    if (result?.muted?.length) {
      yield put(muteUserSuccessful(userId))
      const loggedInUserId = yield select(s => getLoggedInUserId(s))
      trackMute({ targetUserId: userId, userId: loggedInUserId })
    }
  })
}

function* unmuteUserSaga({ userId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.unmuteCommunityUser(userId)
    if (result?.unmuted?.length) yield put(unmuteUserSuccessful(userId))
  })
}

function* followUserSaga({ userId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.followCommunityUser(userId)
    if (result.errors) return
    yield put(followUserSuccessful(userId))
  })
}

function* unfollowUserSaga({ userId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.unfollowCommunityUser(userId)
    if (result.errors) return

    const loggedInUserId = yield select(s => getLoggedInUserId(s))
    yield put(unfollowUserSuccessful({ userId, loggedInUserId }))
  })
}

// TODO: Move all "fail if ( logged out | not verified | banned )" to saga level so that containers can be leaner. That way, we won't need mergeProps. That is, all "failIfNotAllowed" logic should move here and replace "doFollowChangeSaga" below.
function* followUserIfAllowedSaga({ userId }) {
  yield doFollowChangeSaga({ userId }, followUser)
}

function* unfollowUserIfAllowedSaga({ userId }) {
  yield doFollowChangeSaga({ userId }, unfollowUser)
}

function* doFollowChangeSaga({ userId }, actionCreator) {
  yield logoutOnFail(function* inner() {
    const loggedIn = yield select(s => isLoggedIn(s))
    const verified = yield select(s => isVerified(s))
    if (!loggedIn) {
      yield put(showModal('USER_LOGIN'))
    } else if (loggedIn && !verified) {
      yield put(showModal('VERIFY_EMAIL'))
    } else {
      yield put(actionCreator(userId))
    }
  })
}

const BUSINESS_SERVICE_404_MESSAGE = "Couldn't find User"
function* fetchCommunityUserSaga({ username }) {
  yield logoutOnFail(function* inner() {
    // Get user from the Business service by username.
    let userFromBusinessService
    try {
      userFromBusinessService = yield rtApi.users.fetchProfile({ username })
    } catch (businessServiceError) {
      // If Business Service got a 404, leave userFromBusinessService undefined to be handled further along in the saga. Otherwise, rethrow.
      if (businessServiceError.message !== BUSINESS_SERVICE_404_MESSAGE) {
        throw businessServiceError
      }
    }

    // If that user exists, get user from the Community service by ID.
    let userFromCommunityService
    if (userFromBusinessService) {
      const resp = yield communityApi.getCommunityUser(
        userFromBusinessService.id
      )

      userFromCommunityService = resp?.data
    }

    // We need a user to exist in both the business and community services in order to be considered valid for a profile.
    if (!userFromBusinessService || !userFromCommunityService) {
      yield put(fetchCommunityUserFailed({ username, reason: '404' }))
    } else {
      const user = normalizeCommunityUser({
        userFromBusinessService,
        userFromCommunityService,
      })
      yield put(fetchCommunityUserSuccesful(user))
    }
  })
}

function* fetchMyCommunityUserSaga() {
  yield logoutOnFail(function* inner() {
    const loggedInUser = yield select(s => getLoggedInUser(s))
    if (!loggedInUser) return
    yield fetchCommunityUserSaga({ username: loggedInUser.attributes.username })

    // Many views require us to have our group membership information available, so we always fetch it with the Community User.
    yield fetchMyMembershipsSaga()
  })
}

function* fetchCommunityUserFollowingSaga() {
  yield logoutOnFail(function* inner() {
    const { profileUser: user } = yield select(s =>
      getUserInfoFromStateAndUrl(s)
    )
    const userId = user.id
    const params = { limit: 12 }

    // If we're requesting the next page, use a pagination cursor.
    const paginationCursor = user?.followingList?.pagination?.next
    if (paginationCursor) params.cursor = paginationCursor

    const result = yield communityApi.getCommunityUserFollowing(userId, params)
    if (result.errors) return
    yield put(
      fetchCommunityUserFollowingSuccesful({
        userId,
        users: result.data,
        pagination: result.pagination,
      })
    )
  })
}

function* fetchCommunityUserFollowersSaga() {
  yield logoutOnFail(function* inner() {
    const { profileUser: user } = yield select(s =>
      getUserInfoFromStateAndUrl(s)
    )
    const userId = user.id
    const params = { limit: 12 }

    // If we're requesting the next page, use a pagination cursor.
    const paginationCursor = user?.followersList?.pagination?.next
    if (paginationCursor) params.cursor = paginationCursor

    const result = yield communityApi.getCommunityUserFollowers(userId, params)
    if (result.errors) return
    yield put(
      fetchCommunityUserFollowersSuccesful({
        userId,
        users: result.data,
        pagination: result.pagination,
      })
    )
  })
}

// Always fetch all memberships. The number should never be very large, and the performance impact is unappreciable.
function* fetchMembershipsForCurrentProfileUserSaga() {
  yield logoutOnFail(function* inner() {
    const { profileUser } = yield select(s => getUserInfoFromStateAndUrl(s))
    const userId = profileUser.id
    yield fetchCommunityUserMembershipsSaga({
      userId,
    })
  })
}

function* fetchMyMembershipsSaga() {
  const loggedInCommunityUser = yield select(s => getLoggedInCommunityUser(s))
  const userId = loggedInCommunityUser.id
  yield fetchCommunityUserMembershipsSaga({
    userId,
  })
}

function* fetchCommunityUserMembershipsSaga({ userId }) {
  yield logoutOnFail(function* inner() {
    const params = { limit: 1000 }
    const result = yield communityApi.getCommunityUserMemberships(
      userId,
      params
    )
    if (result.errors) return
    yield put(
      fetchCommunityUserMembershipsSuccesful({
        userId,
        memberships: result.data,
      })
    )
  })
}

function* fetchHomeFeedSaga({ resetPagination }) {
  yield logoutOnFail(function* inner() {
    const loggedInUserId = yield select(s => getLoggedInUserId(s))
    const homeFeed = yield select(s => getMyFeed(s))
    if (!loggedInUserId) return
    const paginationCursor = homeFeed?.pagination?.next
    const params = { limit: 20 }
    if (paginationCursor && !resetPagination) params.cursor = paginationCursor

    const result = yield communityApi.getHomeFeedForLoggedInUser(
      loggedInUserId,
      params
    )
    if (result.errors) return
    yield put(
      fetchHomeFeedSuccessful({
        posts: result.data,
        pagination: result.pagination,
        resetPagination,
      })
    )

    // Whenever we are fetching any kind of feed for the first time, we need to fetch our own community user and memberships to determine what actions we may take against specific posts.
    if (!paginationCursor) {
      yield fetchMyCommunityUserSaga()
    }
  })
}

function* fetchUserActivityFeedSaga({ userId, resetPagination }) {
  yield logoutOnFail(function* inner() {
    const loggedInUserId = yield select(s => getLoggedInUserId(s))
    const activity = yield select(s => getUserActivityFeed(s, userId))
    const paginationCursor = activity?.pagination?.next
    const params = { limit: 20 }
    if (paginationCursor && !resetPagination) params.cursor = paginationCursor
    const result = yield communityApi.getUserActivityFeed(userId, params)
    if (result.errors) return
    yield put(
      fetchUserActivityFeedSuccessful({
        posts: result.data,
        pagination: result.pagination,
        userId,
        resetPagination,
      })
    )

    if (!paginationCursor) {
      if (loggedInUserId === userId) {
        // Whenever we are fetching our own activity feed for the first time, we should not re-fetch our community user because that would overwrite the part of state we are concerned with. Just fetch our memberships to determine permissions.
        yield fetchMyMembershipsSaga()
      } else {
        // Whenever we are fetching another user's activity feed for the first time, we need to fetch our own community user and memberships to determine what actions we may take against specific posts.
        yield fetchMyCommunityUserSaga()
      }
    }
  })
}

function* fetchSubCommunityFeedSaga({ subCommunityId, resetPagination }) {
  yield logoutOnFail(function* inner() {
    const subCommunity = yield select(state =>
      getSubCommunityById(state, subCommunityId)
    )

    const paginationCursor = subCommunity?.feed?.pagination?.next

    const params = { limit: 20 }
    if (paginationCursor && !resetPagination) params.cursor = paginationCursor

    const result = yield communityApi.getSubCommunityFeed(
      subCommunityId,
      params
    )
    if (result.errors) return
    yield put(
      fetchSubCommunityFeedSuccessful({
        subCommunityId,
        posts: result.data,
        stickiedPost: result.stickied_post,
        pagination: result.pagination,
        resetPagination,
      })
    )

    // Whenever we are viewing any kind of feed, we need to fetch our own community user and memberships to determine what actions we may take against specific posts.
    yield fetchMyCommunityUserSaga()
  })
}

function* fetchSubCommunitySaga({ subCommunitySlug }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.getSubCommunity(subCommunitySlug)
    if (result.errors) {
      if (result.errors.message === 'Invalid slug or id') {
        yield put(fetchSubCommunityFailed({ subCommunitySlug, reason: '404' }))
      }
    } else {
      yield put(fetchSubCommunitySuccesful(result.data))
    }
  })
}

function* fetchMembersForCurrentSubCommunitySaga() {
  yield logoutOnFail(function* inner() {
    const subCommunityId = yield select(s => getCurrentSubCommunity(s)?.id)
    yield put(fetchMembersForSubCommunity(subCommunityId))
  })
}

function* fetchMembersForSubCommunitySaga({ subCommunityId }) {
  yield logoutOnFail(function* inner() {
    const subCommunity = yield select(s =>
      getSubCommunityById(s, subCommunityId)
    )
    const params = { limit: 12 }

    // If we're requesting the next page, use a pagination cursor.
    const paginationCursor = subCommunity?.members?.pagination?.next
    if (paginationCursor) params.cursor = paginationCursor

    const result = yield communityApi.getSubCommunityMembers(
      subCommunityId,
      params
    )

    if (!result.data) return
    // FIXME: There is a bug on the backend which allows memberships without users ({ "user": null }).
    // This must be fixed on the backend, but for now we'll just filter out those invalid memberships.
    const dataWithoutNulls = result.data.filter(membership => !!membership.user)
    yield put(
      fetchMembersForSubCommunitySuccessful({
        subCommunityId,
        users: dataWithoutNulls.map(membership => membership.user),
        pagination: result.pagination,
        totalMembersCount: result?.metadata?.memberships?.count,
      })
    )
  })
}

function* leaveSubCommunitySaga({ subCommunityId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.leaveSubCommunity(subCommunityId)
    if (!result.data) return

    const userId = yield select(s => getLoggedInUserId(s))
    trackLeaveSubCommunity({ communityUuid: subCommunityId, userId })

    // Refresh our memberships now that we've left the group
    yield fetchMyMembershipsSaga()
  })
}

function* joinSubCommunitySaga({ subCommunityId }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.joinSubCommunity(subCommunityId)
    if (!result.data) return

    const userId = yield select(s => getLoggedInUserId(s))
    trackJoinSubCommunity({ communityUuid: subCommunityId, userId })

    // FIXME: It takes ElasticSearch a moment to reindex, so fetching immediately will not show the new membership.
    yield wait(750)
    // Refresh our memberships now that we've joined the group
    yield fetchMyMembershipsSaga()
  })
}

function* likePostSaga(action) {
  yield likeOrUnlikePostSaga(action, true)
}

function* unlikePostSaga(action) {
  yield likeOrUnlikePostSaga(action, false)
}

function* likeOrUnlikePostSaga({ postId }, likingPost) {
  yield logoutOnFail(function* inner() {
    const apiRequest = likingPost
      ? communityApi.likePost
      : communityApi.unlikePost
    const { likes } = yield apiRequest(postId)
    if (likingPost) trackLikeEvent(postId)
    yield put(likeOrUnlikePostSuccessful({ postId, likes }))
  })
}

function* flagPostSaga({ postId, flagType }) {
  yield logoutOnFail(function* inner() {
    const result = yield communityApi.flagPost(postId, flagType)
    const errorMessage = result.message
    if (errorMessage) {
      notice({
        icon: 'icon-close2',
        iconColor: 'red',
        header: '',
        body: `&nbsp;&nbsp;${errorMessage}`,
      })
    } else {
      notice({
        iconColor: 'blue',
        header: '',
        body: `&nbsp;&nbsp;Post flagged as ${flagType}`,
      })
    }
  })
}

function* pinPostSaga({ post }) {
  yield logoutOnFail(function* inner() {
    const performRequest = async () => {
      const communityId = post?.owner?.data?.id
      const result = await communityApi.pinPostToCommunity(post.id, communityId)

      if (result.data) {
        const communityName = post?.owner?.data?.name
        notice({
          iconColor: 'blue',
          header: '',
          body: `&nbsp;&nbsp;Pinned post to ${communityName}. Reload to see changes.`,
        })
      }
    }

    const existingPinId = post?.owner?.data?.stickied_post_id
    if (existingPinId && existingPinId !== post.id) {
      yield put(
        showModal('COMPONENT_MODAL', {
          component: StickyPrompt,
          post,
          className: 'modal-overlay modal-prompt modal-mobile-page',
          onConfirm: performRequest,
        })
      )
    } else {
      yield performRequest()
    }
  })
}

function* unpinPostSaga({ post }) {
  yield logoutOnFail(function* inner() {
    const performRequest = async () => {
      const communityId = post?.owner?.data?.id
      const result = await communityApi.unpinPostInCommunity(communityId)

      if (result.data) {
        const communityName = post?.owner?.data?.name
        notice({
          iconColor: 'blue',
          header: '',
          body: `&nbsp;&nbsp;Removed pinned post from ${communityName}. Reload to see changes.`,
        })
      }
    }

    yield put(
      showModal('COMPONENT_MODAL', {
        component: StickyPrompt,
        post,
        className: 'modal-overlay modal-prompt modal-mobile-page',
        text:
          'This will unpin this post, are you sure you would like to do that?',
        approveText: 'Confirm',
        removeSticky: true,
        onConfirm: performRequest,
      })
    )
  })
}

function* fetchExplorePostsAndCommunitiesSaga() {
  yield logoutOnFail(function* inner() {
    const [exampleCommunitiesResult, examplePostsResult] = yield Promise.all([
      communityApi.getSubCommunities({ limit: 3 }),
      communityApi.getExamplePosts({ limit: 10 }),
    ])

    yield put(
      fetchExplorePostsAndCommunitiesSuccessful({
        examplePosts: examplePostsResult?.data,
        exampleCommunities: exampleCommunitiesResult?.data,
      })
    )
  })
}

// For consistency with the Community API, don't throw for 4XX---instead, just provide the response.
const updateUserWithPromise = updateObj =>
  new Promise(resolve =>
    rtApi.users
      .update({ ...updateObj, errorCallback: errorJson => resolve(errorJson) })
      .then(successJson => resolve(successJson))
      .catch(() => {})
  ).catch(() => {})

function* updateUserSaga() {
  yield logoutOnFail(function* inner() {
    const loggedInUser = yield select(s => getLoggedInUser(s))
    const userAttributes = yield select(s => getUserUpdateForm(s))
    const result = yield updateUserWithPromise({
      id: loggedInUser.id,
      user: userAttributes,
    })
    const newUsername = userAttributes.username
    const usernameChanged = loggedInUser.username !== newUsername
    if (result.errors) {
      yield put(submitUserUpdateFormFailed(result.errors))
    } else {
      yield put(submitUserUpdateFormSuccessful(result))
      notice({
        body: `&nbsp;&nbsp;Good job ${newUsername}, your profile has been updated.`,
      })

      // If the username changed and we were on the logged in user's profile page, replace the URL with the new username.
      if (usernameChanged) {
        const hasUserProfileUrl = window.location.pathname.match('/g/user/')
        const urlDoesNotMatchUsername = !window.location.pathname.match(
          new RegExp(`${newUsername}(/|$)`)
        )
        if (hasUserProfileUrl && urlDoesNotMatchUsername) {
          yield put(replace(`/g/user/${newUsername}`))
        }
      }
    }
  })
}

// TODO: This saga only exists to take user info from authReducer (currently logged in user), and provide it to the communityV2Reducer because communityV2Reducer doesn't know who is logged in. This user info is used to pre-populate the "Edit" form on Community Profile. This is sort of confusing and could perhaps be handled in a more elegant way.
function* setIsEditingUserSaga({ isEditingUser }) {
  yield logoutOnFail(function* inner() {
    if (!isEditingUser) return
    const loggedInCommunityUser = yield select(s => getLoggedInCommunityUser(s))
    const { username, about, location } = loggedInCommunityUser

    yield put(updateUserForm({ username, about, location }))
  })
}

export function* communityV2() {
  // Get user
  yield takeEvery(FETCH_COMMUNITY_USER, fetchCommunityUserSaga)

  // Mute or unmute specific user
  yield takeEvery(MUTE_COMMUNITY_USER, muteUserSaga)
  yield takeEvery(UNMUTE_COMMUNITY_USER, unmuteUserSaga)

  // Follow or unfollow specific user
  yield takeEvery(FOLLOW_COMMUNITY_USER, followUserSaga)
  yield takeEvery(UNFOLLOW_COMMUNITY_USER, unfollowUserSaga)
  // Conveniences for following
  yield takeLatest(FOLLOW_USER_IF_ALLOWED, followUserIfAllowedSaga)
  yield takeLatest(UNFOLLOW_USER_IF_ALLOWED, unfollowUserIfAllowedSaga)

  // See who a user is following
  yield takeLatest(
    FETCH_COMMUNITY_USER_FOLLOWING,
    fetchCommunityUserFollowingSaga
  )

  // See who follows a user
  yield takeLatest(
    FETCH_COMMUNITY_USER_FOLLOWERS,
    fetchCommunityUserFollowersSaga
  )

  // See groups that the profile user is in
  yield takeLatest(
    FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER,
    fetchMembershipsForCurrentProfileUserSaga
  )

  // Get groups that the logged in user is in (this may be the profile user, but is also used by some other business logic)
  yield takeLatest(FETCH_MY_MEMBERSHIPS, fetchMyMembershipsSaga)

  // See feed for logged in user
  yield takeLatest(FETCH_HOME_FEED, fetchHomeFeedSaga)

  // See activity feed for a user
  yield takeLatest(FETCH_USER_ACTIVITY_FEED, fetchUserActivityFeedSaga)

  // Get sub-community
  yield takeLatest(FETCH_SUB_COMMUNITY, fetchSubCommunitySaga)

  // See members of current sub-community
  yield takeLatest(
    FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY,
    fetchMembersForCurrentSubCommunitySaga
  )

  // See members of sub-community
  yield takeLatest(
    FETCH_MEMBERS_FOR_SUB_COMMUNITY,
    fetchMembersForSubCommunitySaga
  )

  // Get feed for sub-community
  yield takeLatest(FETCH_SUB_COMMUNITY_FEED, fetchSubCommunityFeedSaga)

  // Leave sub-community
  yield takeLatest(LEAVE_SUB_COMMUNITY, leaveSubCommunitySaga)

  // Join sub-community
  yield takeLatest(JOIN_SUB_COMMUNITY, joinSubCommunitySaga)

  // Like or unlike post
  yield takeLatest(LIKE_POST, likePostSaga)
  yield takeLatest(UNLIKE_POST, unlikePostSaga)

  // Flag post as abuse or spam
  yield takeLatest(FLAG_POST, flagPostSaga)

  // Pin post to community
  yield takeLatest(PIN_POST, pinPostSaga)
  yield takeLatest(UNPIN_POST, unpinPostSaga)

  // Get own user and memberships, which are treated as one piece of information
  yield takeLatest(
    FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS,
    fetchMyCommunityUserSaga
  )

  yield takeLatest(
    FETCH_EXPLORE_POSTS_AND_COMMUNITIES,
    fetchExplorePostsAndCommunitiesSaga
  )

  yield takeLatest(SET_IS_EDITING_USER, setIsEditingUserSaga)

  yield takeLatest(SUBMIT_USER_UPDATE_FORM, updateUserSaga)
}
