import React from 'react'
import PropTypes from 'prop-types'

import { swapCommunityAPIForCDN } from 'common/helpers/paths'

import * as Styled from './styled'

function Avatar({ alt, border, className, onClick, size, src }) {
  const [imgSrc, imgSrc2x, imgSrc3x] = [1, 2, 3].map(i => {
    const srcSize = size || 120
    return swapCommunityAPIForCDN(
      src,
      `c_fill,w_${srcSize * i},h_${srcSize * i},fl_lossy,q_auto:best`
    )
  })

  // Sometimes the consumer may bring their own CSS for height and width.
  const sizeProps = size !== undefined ? { width: size, height: size } : {}

  const onClickProps = onClick ? { onClick } : {}

  if (!src) {
    return (
      <Avatar.BaseNoSrc
        border={border}
        className={className}
        // Div must use CSS width and height rather than attributes.
        style={sizeProps}
        {...onClickProps}
      />
    )
  }

  return (
    <Avatar.Base
      alt={alt}
      border={border}
      className={className}
      loading='lazy'
      src={imgSrc}
      srcSet={`${imgSrc}, ${imgSrc2x} 2x, ${imgSrc3x} 3x`}
      {...onClickProps}
      {...sizeProps}
    />
  )
}

Avatar.propTypes = {
  alt: PropTypes.string,
  border: PropTypes.number,
  size: PropTypes.number,
  src: PropTypes.string,
}

Avatar.defaultProps = {
  alt: '',
}

Avatar.Base = Styled.AvatarBase
Avatar.BaseNoSrc = Styled.AvatarBaseNoSrc

export default Avatar
