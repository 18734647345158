import React from 'react'

import TorchwickImg from 'common/assets/Torchwick_01.png'

const EmptySearch = () => (
  <div className='search-v2-empty-message'>
    <img
      className='search-v2-empty-message__image'
      src={TorchwickImg}
      alt='Torchwick'
    />
    <div className='search-v2-empty-message__header'>
      Nothing to see here...
    </div>
    <div className='search-v2-empty-message__body'>
      Sorry! We couldn&apos;t find what you were looking for.
    </div>
  </div>
)

export default EmptySearch
