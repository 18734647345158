import styled from 'styled-components'

import firstLogo from 'common/assets/logo-first-white.svg'
import PrimaryBtn from 'common/components/Buttons/Primary'

import scssVariables from 'rootApp/components/TopNav/styles.scss'

export const TryFirstBase = styled(PrimaryBtn)`
  --try-first-padding: 13px;
  align-items: center;
  display: inline-flex;
  height: 28px;
  line-height: 1;
  margin-right: var(--top-nav-account-item-margin);
  padding-left: var(--try-first-padding);
  padding-right: var(--try-first-padding);
`

export const TryFirstLogo = styled.img`
  height: 13px;
  position: relative;
  top: -1px; // Baseline correction
  width: auto;

  @media (max-width: calc(${scssVariables.screenS} - 1px)) {
    height: 11px;
  }
`

TryFirstLogo.defaultProps = {
  alt: '',
  src: firstLogo,
}
