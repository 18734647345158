import store from 'store'

import { trackEvent } from 'common/metrics'
import { getFeatureFlags } from 'common/selectors'

import {
  VIEW_BILLING,
  VIEW_CREATE_ACCOUNT,
  VIEW_CUSTOMIZE_ACCOUNT,
  VIEW_SELECT_A_PLAN,
} from 'signupApp/constants'

const VIEW_ORDER = [
  VIEW_CREATE_ACCOUNT,
  VIEW_SELECT_A_PLAN,
  VIEW_BILLING,
  VIEW_CUSTOMIZE_ACCOUNT,
]

export const getPositionFromCurrentView = (view, viewToCompare) => {
  if (VIEW_ORDER.indexOf(view) > VIEW_ORDER.indexOf(viewToCompare)) {
    return 'past'
  }
  if (VIEW_ORDER.indexOf(view) === VIEW_ORDER.indexOf(viewToCompare)) {
    return 'present'
  }
  if (VIEW_ORDER.indexOf(view) < VIEW_ORDER.indexOf(viewToCompare)) {
    return 'future'
  }
}

// Simple helper to change a "view" constant to a numerical step.
export const trackSignupFlowEvent = (view, options) => {
  // Don't emit any events if we're an admin debugging the signup assets.
  const { debugSignupAnimation } = getFeatureFlags(store.getState())
  if (debugSignupAnimation) return

  // Steps are 1-indexed, 1-4.
  const step = VIEW_ORDER.indexOf(view) + 1

  trackEvent('Signup Flow Event', {
    step,
    ...options,
  })
}

export const RECURLY_CREDIT_CARD_FORM_ID = 'recurly-credit-card-form'

export const getRecurlyCreditCardFormElement = () =>
  document.getElementById(RECURLY_CREDIT_CARD_FORM_ID)
