import rtApi from '@roosterteethproductions/svod-api'

import {
  GET_MERCH_FAILURE,
  GET_MERCH_REQUEST,
  GET_MERCH_SUCCESS,
} from './types'

const requestMerch = filter => ({
  type: GET_MERCH_REQUEST,
  filter,
})

const receiveMerch = (filter, json) => ({
  type: GET_MERCH_SUCCESS,
  filter,
  collection: json.filter(item => Object.keys(item).length !== 0),
  lastUpdated: Date.now(),
})

const fetchMerchFailure = (filter, ex) => ({
  type: GET_MERCH_FAILURE,
  filter,
  error: ex.message,
})

// NOTE: This function is only called for series on the series page (shows)
export const fetchMerch = filter => dispatch => {
  dispatch(requestMerch(filter))
  const getRequest = () => {
    // FIXME: This works because "shows" pass their UUID in the "name" property of filters. The merch code for channel/series/episode/featured is pretty hard to follow.
    // Our shows have a "product_collections" field which provides specific merch links. Determine whether that field is desired or if rtApi should be used instead.
    if (filter && filter.type === 'shows' && filter.name) {
      return rtApi.shows.fetchMerch({ id: filter.name })
    }
    return rtApi.featured.fetchProducts({ filter })
  }
  getRequest()
    .then(json => {
      if (json.data.length !== 0) {
        dispatch(receiveMerch(filter, json.data[0].included.collections))
      } else {
        dispatch(fetchMerchFailure(filter, { message: 'No collection found' }))
      }
    })
    .catch(ex => dispatch(fetchMerchFailure(filter, ex)))
}
