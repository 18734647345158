import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  hasRole,
  isAdminOfPost,
  isModeratorOfPost,
  ROLE_ADMIN,
  ROLE_GLOBAL_MODERATOR,
} from 'common/helpers'
import { StickyPrompt } from 'communityApp/components'
import { showModal } from 'modalApp/actions'
import classnames from 'classnames'

export class Sticky extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  }

  // ------
  // Methods
  // ------
  canSticky() {
    const { post, memberships, user } = this.props
    return (
      hasRole(ROLE_ADMIN, user) ||
      hasRole(ROLE_GLOBAL_MODERATOR, user) ||
      isAdminOfPost({ post, memberships }) ||
      isModeratorOfPost({ post, memberships })
    )
  }

  stickyPrompt = () => {
    if (!this.canSticky()) {
      return
    }
    const { post, dispatch } = this.props
    dispatch(
      showModal('COMPONENT_MODAL', {
        component: StickyPrompt,
        post,
        className: 'modal-overlay modal-prompt modal-mobile-page',
        text:
          'This will unpin this post, are you sure you would like to do that?',
        approveText: 'Confirm',
        removeSticky: true,
      })
    )
  }

  // ------
  // Render
  // ------
  render() {
    const canSticky = this.canSticky()
    const classes = classnames({
      'icon icon-thumbtack rotate-45': true,
      unclickable: !canSticky,
    })
    return <i className={classes} onClick={this.stickyPrompt} />
  }
}

function mapStateToProps(state, ownProps) {
  const { memberships } = state.communityReducer
  const { user } = state.authReducer
  return { memberships, user }
}

export default connect(mapStateToProps)(Sticky)
