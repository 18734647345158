import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import character1 from 'common/assets/stinky-dragon-easter-egg/character-1.png'
import character2 from 'common/assets/stinky-dragon-easter-egg/character-2.png'
import character3 from 'common/assets/stinky-dragon-easter-egg/character-3.png'
import character4 from 'common/assets/stinky-dragon-easter-egg/character-4.png'
import navButton from 'common/assets/stinky-dragon-easter-egg/nav-button.png'
import { Tooltip } from 'common/components'
import getRandom from 'common/helpers/getRandom'
import { trackEvent } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const useParticleHelpers = () =>
  useMemo(() => {
    const characterImages = [
      character1,
      character2,
      character3,
      character4,
    ].map(src => {
      const cardImage = new Image()
      cardImage.src = src
      return cardImage
    })

    function resetParticle(particle) {
      particle.image = getRandom(characterImages)
    }

    function drawParticle(context, particle) {
      context.shadowOffsetX = 2
      context.shadowOffsetY = 5
      context.shadowColor = 'rgba(0, 0, 0, 0.6)'
      context.shadowBlur = 20

      context.drawImage(particle.image, particle.x, particle.y, 80, 80)
    }

    return {
      drawParticle,
      resetParticle,
    }
  }, [])

const StinkyDragonButton = React.memo(({ push }) => {
  const { drawParticle, resetParticle } = useParticleHelpers()

  return (
    <FallingParticlesEasterEggButton
      compression={0.2}
      drawParticle={drawParticle}
      particleCount={50}
      resetParticle={resetParticle}
      speed={4}
      speedVariance={0.5}
      startYOffset={200}
      stopAfter={50}
      element={({ toggleFalling, falling }) => (
        <Tooltip text='Watch Stinky Dragon Adventures'>
          <div
            className='stinky-dragon-nav-button'
            role='button'
            onClick={() => {
              trackEvent('Stinky Dragon Icon Clicked')
              push('/series/stinky-dragon-adventures')
              if (falling) toggleFalling()
              else setTimeout(toggleFalling, 300)
            }}
            onKeyPress={toggleFalling}
            tabIndex={0}
          >
            <img src={navButton} alt='Stinky Dragon Logo' />
          </div>
        </Tooltip>
      )}
    />
  )
})

const mapDispatchToProps = { push }

export default connect(
  null,
  mapDispatchToProps
)(StinkyDragonButton)
