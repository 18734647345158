import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import iconHamburger from 'common/assets/nav-logo-hamburger.svg'
import iconHome from 'common/assets/nav-logo-home.svg'
import iconSearch from 'common/assets/nav-logo-search.svg'
import iconStore from 'common/assets/nav-logo-store.svg'
import { ExternalLink } from 'common/components'
import { a11yButtonProps } from 'common/helpers/accessibility'
import getStoreHref from 'common/helpers/getStoreHref'
import handleHomeClick from 'common/helpers/handleHomeClick'
import { trackClick } from 'common/metrics'

import './styles.scss'

const MobileBottomNav = ({
  isAuthenticated,
  openNav,
  openSearch,
  pathname,
}) => {
  const handleHomeButtonClick = useCallback(
    e => handleHomeClick(e, pathname, 'mobile_home'),
    [pathname]
  )

  const handleStoreClick = useCallback(() => trackClick('mobile_store'), [])

  const handleOpenSearch = useCallback(
    () => {
      trackClick('mobile_search')
      openSearch()
    },
    [openSearch]
  )

  const handleOpenNav = useCallback(
    () => {
      openNav()
      trackClick('mobile_open_nav')
    },
    [openNav]
  )

  const isOnHome = pathname === '/'

  const storeHref = getStoreHref(isAuthenticated)

  return (
    <div className='mobile-bottom-nav'>
      <Link
        to='/'
        className={`mobile-bottom-nav__child ${
          isOnHome ? 'mobile-bottom-nav__child--selected' : ''
        }`}
        {...a11yButtonProps(handleHomeButtonClick)}
      >
        <img src={iconHome} alt='Home icon' />
      </Link>
      <ExternalLink
        href={storeHref}
        className='mobile-bottom-nav__child'
        onClick={handleStoreClick}
      >
        <img src={iconStore} alt='Store icon' />
      </ExternalLink>
      <div
        className='mobile-bottom-nav__child'
        {...a11yButtonProps(handleOpenSearch)}
      >
        <img src={iconSearch} alt='Search icon' />
      </div>
      <div
        className='mobile-bottom-nav__child'
        {...a11yButtonProps(handleOpenNav)}
      >
        <img src={iconHamburger} alt='Nav icon' />
      </div>
    </div>
  )
}

export default MobileBottomNav
