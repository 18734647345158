// NOTE: This particle effect was taken (and modified) from https://codepen.io/radum/pen/xICAB
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable no-plusplus */

// shim layer with setTimeout fallback
const shimmedRequestAnimationFrame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60)
    }
  )
})()

/**
 * Starts a falling-particle animation.
 *
 * @param {object} options
 * @param {string} options.executeReCAPTCHAProp A string which is the prop that will be provided to the wrapped component, which will be used to execute reCAPTCHA. We use this to keep the wrapped component's API pure.
 * @param {number} options.compression Float between 0 and 1. Default 0. Allows the particles to be grouped more closely together vertically when initializing in offscreen space. When compression is high, the particles will all initialize closer to the top of the screen. For example, if compression is 0.9 and the screen is 1000px tall, then particles will initialize only in the 100px offscreen above the canvas. If compression is 0.1 and the screen is 1000px tall, then particles will initialize in the 900px offscreen above the canvas.
 * @param {function} options.drawParticle Callback function for rendering the actual particle on the canvas.
 * @param {function} options.onComplete Callback to be triggered whenever the final update animation has completed (when stopAfter is turned on).
 * @param {number} options.particleCount Number of particles to all be initialized in the initial update and kept in the animation. Default 300.
 * @param {function} options.resetParticle Allows the caller to provide additional state modification on particles when they are being initialized.
 * @param {number} options.speed Applies an overall speed modifier to the vertical travel of particles.
 * @param {number} options.speedVariance A number between 0 and 1. When 0, all particles move at the same speed. When 1, particles move at very different speeds.
 * @param {number} options.stopAfter Optional timeout after which no particles should be reinitialized after falling to the bottom of the screen.
 */
const startFalling = ({
  compression = 0,
  drawParticle,
  inverted,
  onComplete,
  particleCount = 300,
  resetParticle,
  speed = 1,
  speedVariance = 1,
  startYOffset = 0,
  stopAfter,
}) => {
  const containerElem = document.querySelector(
    '.falling-particles-easter-egg-container'
  )
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  let width = containerElem.clientWidth
  let height = containerElem.clientHeight
  let particles = []
  let shouldStop = false

  if (stopAfter) {
    setTimeout(() => {
      shouldStop = true
    }, stopAfter)
  }

  canvas.style.position = 'absolute'
  canvas.style.left = canvas.style.top = '0'

  const Particle = function() {
    this.x = 0
    if (inverted) {
      this.y = 0
    } else {
      this.y = height
    }
    this.travelY = 0
    this.travelX = 0
    this.r = 0

    this.reset()
  }

  Particle.prototype.reset = function() {
    this.x = Math.random() * width
    if (inverted) {
      this.y =
        Math.random() * (1 - compression) * height - startYOffset + height
    } else {
      this.y = Math.random() * (1 - compression) * -height - startYOffset
    }

    // See "speedVariance" above.
    const positive = Math.random() > 0.5
    let randomFactor = Math.random() * 0.5 * speedVariance
    if (positive) randomFactor = 0.5 + randomFactor
    else randomFactor = 0.5 - randomFactor

    this.travelY = 1 + randomFactor * 3 * speed
    this.travelX = 0.5 - Math.random()
    if (resetParticle) resetParticle(this)
  }

  function generateParticlesArray() {
    particles = []
    for (let i = 0; i < particleCount; i++) {
      const particle = new Particle()
      particles.push(particle)
    }
  }

  generateParticlesArray()

  function update() {
    context.clearRect(0, 0, width, height)

    // This will remain false if all the particles have reached the bottom of the screen.
    let hasRemainingUpdates = false

    for (let i = 0; i < particleCount; i++) {
      const particle = particles[i]

      // If the particle has exceeded the bottom of the screen (or the top of the screen in inverted mode)...
      const particleExceededBounds =
        (!inverted && particle.y > height) || (inverted && particle.y < 0)
      if (particleExceededBounds) {
        // If we're not stopping, the particle should be reset to the top of the screen.
        if (!shouldStop) {
          particle.reset()
          // hasRemainingUpdates = true
        }

        // Otherwise, simply draw the particle as usual and continue updating.
      } else {
        hasRemainingUpdates = true
        if (inverted) {
          particle.y -= particle.travelY
        } else {
          particle.y += particle.travelY
        }
        particle.x += particle.travelX

        drawParticle(context, particle)
      }
    }

    if (hasRemainingUpdates) {
      shimmedRequestAnimationFrame(update)
    } else if (onComplete) {
      onComplete()
    }
  }

  function onResize() {
    width = containerElem.clientWidth
    height = containerElem.clientHeight
    canvas.width = width
    canvas.height = height

    shimmedRequestAnimationFrame(update)
  }

  onResize()

  window.addEventListener('resize', onResize, false)
  containerElem.appendChild(canvas)
}
const stopFalling = () => {
  const canvases = document.querySelectorAll(
    '.falling-particles-easter-egg-container canvas'
  )
  for (const canvas of canvases) {
    // Fade out particles.
    canvas.style.transition = '0.8s'
    canvas.style.opacity = 0
    setTimeout(() => canvas.remove(), 800)
  }
}
export { startFalling, stopFalling }
