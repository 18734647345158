import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import { trackClick, trackEvent, trackSignupButtonClick } from 'common/metrics'

import { logoutUser } from 'auth/actions'
import { showModal } from 'modalApp/actions'
import { showMyHub } from 'myHubApp/operations'
import ProfileBar from 'rootApp/components/ProfileBar'

import './style.scss'

export class UserPanel extends Component {
  loginHandler = _e => {
    trackClick('account_sub_menu_log_in')
    this.props.showModal('USER_LOGIN')
  }

  logoutHandler = e => {
    e.preventDefault()
    trackClick('account_sub_menu_log_out')
    this.props.logoutUser()
  }

  signupHandler = e => {
    e.preventDefault()
    trackClick('account_sub_menu_sign_up')
    trackSignupButtonClick('nav_menu')
    this.props.push('/signup')
  }

  hubClickHandler = () => {
    this.props.showMyHub()
    trackEvent('My Hub Opened', { source: 'profile_menu' })
  }

  render() {
    const { user } = this.props
    return (
      <Fragment>
        <ProfileBar
          loginHandler={this.loginHandler}
          logoutHandler={this.logoutHandler}
          showMyHub={this.hubClickHandler}
          signupHandler={this.signupHandler}
          user={user}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, user } = state.authReducer
  return { isAuthenticated, user }
}

function mapDispatchToProps(dispatch, _ownProps) {
  return {
    showModal: (...args) => dispatch(showModal(...args)),
    showMyHub: (...args) => dispatch(showMyHub(...args)),
    logoutUser: (...args) => dispatch(logoutUser(...args)),
    push: (...args) => dispatch(push(...args)),
  }
}

// Use default export for the connected component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPanel)
