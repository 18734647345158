import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import history from 'history-singleton'
import sagas from 'sagas'

import reducer from '../reducers'
import { configureMiddleware } from './middleware'

const sagaMiddleware = createSagaMiddleware()

export const createStore = () => {
  const middleware = configureMiddleware({ history })
  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
      sagaMiddleware,
      ...middleware,
    ],
    devTools:
      process.env.NODE_ENV !== 'production' &&
      process.env.NODE_ENV !== 'staging',
  })

  return store
}

const store = createStore()
sagaMiddleware.run(sagas)

export default store
