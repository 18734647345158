import rtApi from '@roosterteethproductions/svod-api'

import {
  getQueryParams,
  HTTP_RT_INVALID_UPSTREAM_API_TOKEN,
} from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  GET_VERIFY_PASSWORD_TOKEN_FAILURE,
  GET_VERIFY_PASSWORD_TOKEN_REQUEST,
  GET_VERIFY_PASSWORD_TOKEN_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  POST_FORGOT_PASSWORD_REQUEST,
  POST_FORGOT_PASSWORD_SUCCESS,
  PUT_PASSWORD_FAILURE,
  PUT_PASSWORD_REQUEST,
  PUT_PASSWORD_SUCCESS,
  PUT_RESET_PASSWORD_FAILURE,
  PUT_RESET_PASSWORD_REQUEST,
  PUT_RESET_PASSWORD_SUCCESS,
} from './types'

const getVerifyPasswordTokenRequest = () => ({
  type: GET_VERIFY_PASSWORD_TOKEN_REQUEST,
})

const getVerifyPasswordTokenSuccess = () => ({
  type: GET_VERIFY_PASSWORD_TOKEN_SUCCESS,
})

const getVerifyPasswordTokenFailure = ({ message }) => ({
  type: GET_VERIFY_PASSWORD_TOKEN_FAILURE,
  message,
})

const postForgotPasswordRequest = () => ({
  type: POST_FORGOT_PASSWORD_REQUEST,
})

const postForgotPasswordSuccess = email => ({
  type: POST_FORGOT_PASSWORD_SUCCESS,
  email,
})

const postForgotPasswordFailure = ({ message }) => ({
  type: POST_FORGOT_PASSWORD_FAILURE,
  message,
})

const putPasswordRequest = () => ({
  type: PUT_PASSWORD_REQUEST,
})

const putPasswordSuccess = () => ({
  type: PUT_PASSWORD_SUCCESS,
})

const putPasswordFailure = ({ message }) => ({
  type: PUT_PASSWORD_FAILURE,
  message,
})

const putResetPasswordRequest = () => ({
  type: PUT_RESET_PASSWORD_REQUEST,
})

const putResetPasswordSuccess = () => ({
  type: PUT_RESET_PASSWORD_SUCCESS,
})

const putResetPasswordFailure = ({ message }) => ({
  type: PUT_RESET_PASSWORD_FAILURE,
  message,
})

export const getVerifyPasswordToken = () => (dispatch, _getState) => {
  const { reset_password_token } = getQueryParams(window.location.search)

  dispatch(getVerifyPasswordTokenRequest())
  return rtApi.auth
    .verifyPasswordToken({ token: reset_password_token })
    .then(_json => Promise.resolve(dispatch(getVerifyPasswordTokenSuccess())))
    .catch(ex => Promise.reject(dispatch(getVerifyPasswordTokenFailure(ex))))
}

export const postForgotPassword = email => dispatch => {
  dispatch(postForgotPasswordRequest())
  return rtApi.auth
    .forgotPassword({ email })
    .then(_response =>
      Promise.resolve(dispatch(postForgotPasswordSuccess(email)))
    )
    .catch(ex => Promise.reject(dispatch(postForgotPasswordFailure(ex))))
}

export const putPassword = (
  { current_password, password, password_confirmation },
  errorCallback
) => (dispatch, getState) => {
  const state = getState()
  const { id } = state.authReducer.user

  dispatch(putPasswordRequest())
  return rtApi.auth
    .updatePassword({
      currentPassword: current_password,
      id,
      password,
      passwordConfirmation: password_confirmation,
      errorCallback,
    })
    .then(json => Promise.resolve(dispatch(putPasswordSuccess(json))))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(putPasswordFailure(ex)))
    })
}

export const putResetPassword = data => (dispatch, _getState) => {
  const { reset_password_token } = getQueryParams(window.location.search)
  const { password, password_confirmation } = data
  dispatch(putResetPasswordRequest())
  return rtApi.auth
    .resetPassword({ password, password_confirmation, reset_password_token })
    .then(json => Promise.resolve(dispatch(putResetPasswordSuccess(json))))
    .catch(ex => Promise.reject(dispatch(putResetPasswordFailure(ex))))
}
