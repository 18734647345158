import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getQueryString } from 'common/helpers'

import ShareItem from './ShareItem'

class TwitterShare extends Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    openShareWindow: PropTypes.func.isRequired,
    text: PropTypes.string,
  }

  static defaultProps = {
    text: '',
  }

  getTwitterHref = () => {
    const { href, text } = this.props
    const query = getQueryString({
      text,
      url: href,
      via: 'RoosterTeeth',
    })

    return `http://twitter.com/intent/tweet?${query}`
  }

  handleClick = () => {
    const { openShareWindow } = this.props

    openShareWindow(this.getTwitterHref())
  }

  render() {
    return (
      <ShareItem
        hoveredMessage='Share on Twitter'
        href={this.getTwitterHref()}
        icon='twitter'
        onClick={this.handleClick}
        target='_blank'
      />
    )
  }
}

export default TwitterShare
