export * from './avatarActions'
export * from './carouselActions'
export * from './feedActions'
export * from './genreActions'
export * from './myStuffActions'
export * from './livestreamActions'
export * from './merchActions'
export * from './scrollableActions'
export * from './seasonActions'
export * from './commentsActions'
export * from './videoActions'
export * from './ratingActions'

export function hideError() {
  return {
    type: 'modalApp/HIDE_ERROR',
  }
}

export function showError(message, reducer) {
  return {
    type: 'modalApp/SHOW_ERROR',
    message,
    reducer,
  }
}
