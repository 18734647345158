import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class PaymentIcon extends Component {
  static propTypes = {
    type: PropTypes.string,
  }

  static defaultProps = {
    type: 'default',
  }

  constructor(props) {
    super(props)

    this.paymentIconClass = this.paymentIconClass.bind(this)
  }

  paymentIconClass() {
    const { type } = this.props

    switch (type.toLowerCase()) {
      case 'amex':
      case 'american-express':
        return 'icon-cc-amex'
      case 'discover':
        return 'icon-cc-discover'
      case 'mastercard':
      case 'master-card':
        return 'icon-cc-mastercard'
      case 'paypal':
        return 'icon-cc-paypal'
      case 'visa':
        return 'icon-cc-visa'
      default:
        return 'icon-credit-card'
    }
  }

  render() {
    const iconClass = classNames(this.paymentIconClass(), 'qa-payment-icon')

    return <i className={iconClass} />
  }
}

export default PaymentIcon
