import rtApi from '@roosterteethproductions/svod-api'

import {
  GET_GENRE_FAILURE,
  GET_GENRE_REQUEST,
  GET_GENRE_SUCCESS,
} from './types'

/* GENRE FUNCTIONS */
export const requestGenres = () => ({
  type: GET_GENRE_REQUEST,
})

export const receiveGenres = item => ({
  type: GET_GENRE_SUCCESS,
  genres: item.data,
  receivedAt: Date.now(),
})

export const fetchGenresFailure = ex => ({
  type: GET_GENRE_FAILURE,
  error: ex.message,
})

export const fetchGenres = () => dispatch => {
  dispatch(requestGenres())
  rtApi.genres
    .fetchAll()
    .then(json => dispatch(receiveGenres(json)))
    .catch(ex => dispatch(fetchGenresFailure(ex)))
}
