const browserSearchMaybeOpenClass = 'browser-search-maybe-open'
const isCtrlF = e => e.code === 'KeyF' && (e.ctrlKey || e.metaKey)
const isEscape = e => e.code === 'Escape'

const addSearchOpenClass = () =>
  document.body.classList.add(browserSearchMaybeOpenClass)

const removeSearchOpenClass = () =>
  document.body.classList.remove(browserSearchMaybeOpenClass)

// Whenever the user uses the "Ctrl F" or "Cmd F" keystrokes, assume they opened the browser search and assign a class to the body. This class can be used to display spans that would otherwise only be visible on hover, aiding in search.
// Whenever the user clicks anything, remove the body class.
export const bindBrowserSearchHandlers = () => {
  window.addEventListener('keydown', e => {
    if (isCtrlF(e)) return addSearchOpenClass()
    if (isEscape(e)) return removeSearchOpenClass()
  })
  window.addEventListener('click', removeSearchOpenClass)
}
