/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import {
  fetchSeasonEpisodes,
  fetchSeasonEpisodesPercentage,
  fetchSeasons,
} from 'common/actions'
import { Carousel, EpisodeCard, Preloader } from 'common/components'
import RssButton from 'common/components/Buttons/RssButton'
import RTDropdownList from 'common/components/RTDropdownList'
import RssButtonWrapper from 'common/containers/RssButtonWrapper'
import { getQueryParams } from 'common/helpers'
import { trackClick } from 'common/metrics'

import { getUserTier } from 'auth/selectors'
import { hideModal, showModal } from 'modalApp/actions'

import './styles.scss'

export class SeasonEpisodes extends Component {
  static propTypes = {
    location: PropTypes.object,
    onChange: PropTypes.func,
    showSlug: PropTypes.string.isRequired,
    showLink: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    updateUrl: PropTypes.bool,
  }

  static defaultProps = {
    location: null,
    onChange() {},
    title: 'Episodes',
    type: 'carousel',
    updateUrl: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      limit: 24,
      selectedSeason: {
        seasonSlug: '',
        seasonLink: '',
        seasonTitle: '',
      },
      seasonData: [],
      totalItems: 24,
    }
  }

  updateSelectedSeason = () => {
    const { mySeasons } = this.props
    const queryParams = getQueryParams(this.props.location?.search)

    if (mySeasons && queryParams.season) {
      const seasonLinks = this.getLinksForSeason(mySeasons.items)
      const seasonTitle = this.getSeasonTitle(mySeasons.items)
      this.setState({ selectedSeason: { ...seasonLinks, seasonTitle } })
      this.props.onChange({
        ...seasonLinks,
        seasonTitle,
      })
    }
  }

  componentDidMount() {
    const { allEpisodes, dispatch, mySeasons, showSlug, showLink } = this.props
    dispatch(fetchSeasons(showSlug, showLink))
    this.updateSelectedSeason()
    // if we mounted and have some seasons fetched for this show but no selected slug, grab first season
    if (
      mySeasons !== undefined &&
      this.state.selectedSeason.seasonSlug === ''
    ) {
      const seasonLinks = this.getLinksForSeason(mySeasons.items)
      const seasonTitle = this.getSeasonTitle(mySeasons.items)
      this.setState({
        selectedSeason: { ...seasonLinks, seasonTitle },
      })
      const seasonEps = allEpisodes.find(
        s => s.seasonId === seasonLinks.seasonSlug
      )
      if (seasonEps !== undefined) {
        this.setState({
          seasonData: seasonEps.items,
          totalItems: seasonEps.total,
        })
      } else {
        dispatch(fetchSeasonEpisodes(seasonLinks, this.state.limit))
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, user } = this.props
    const { seasonData } = this.state
    if (user === null && nextProps.user !== null) {
      dispatch(fetchSeasonEpisodesPercentage({ items: seasonData }))
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    const { allEpisodes, dispatch, location, mySeasons, updateUrl } = this.props
    // if we for the first time receiving props, request first season of data
    if (prevProps.mySeasons === undefined && mySeasons !== undefined) {
      const seasonLinks = this.getLinksForSeason(mySeasons.items)
      const seasonTitle = this.getSeasonTitle(mySeasons.items)
      this.setState({ selectedSeason: { ...seasonLinks, seasonTitle } })
      this.updateSelectedSeason()
      dispatch(fetchSeasonEpisodes(seasonLinks, this.state.limit))
    }
    // if the episodes are changing, make sure our current season data matches the selected season
    if (prevProps.allEpisodes !== allEpisodes) {
      const seasonEps = allEpisodes.find(
        s => s.seasonId === this.state.selectedSeason.seasonSlug
      )
      if (seasonEps && seasonEps.items) {
        this.setState({
          seasonData: seasonEps.items,
          totalItems: seasonEps.total,
        })
      }
    }
    // account for season url changing
    if (
      updateUrl &&
      prevProps.location?.search !== location.search &&
      mySeasons &&
      allEpisodes.length > 0
    ) {
      const seasonLinks = this.getLinksForSeason(mySeasons.items)
      const seasonEps = allEpisodes.find(
        e => e.seasonId === seasonLinks.seasonSlug
      )
      if (seasonEps && seasonEps.items) {
        this.setState({
          seasonData: seasonEps.items.slice(0, 24),
          totalItems: seasonEps.total,
        })
      } else {
        dispatch(fetchSeasonEpisodes(seasonLinks, 24))
      }

      // account for user clicking browser navigation buttons and resetting 'show more' state
      if (location.search !== prevProps.location.search) {
        this.setState({
          limit: 24,
        })
      }
    }
  }

  getLinksForSeason = items => {
    // get slug for season based on number if in url, otherwise just grab first one
    let number = null
    if (this.props.updateUrl) {
      const query = getQueryParams(this.props.location.search)
      if (query.season) {
        number = parseInt(query.season, 10)
      }
    }
    const match = items.find(s => s.attributes.number === number)
    if (!match) {
      return {
        seasonSlug: items[0].attributes.slug,
        seasonLink: items[0].links.episodes,
      }
    }
    return {
      seasonSlug: match.attributes.slug,
      seasonLink: match.links.episodes,
    }
  }

  getSeasonTitle = items => {
    let seasonNumber = null
    let seasonTitle = ''

    if (this.props.updateUrl && this.props.location?.search) {
      // Assuming `getQueryParams` is a function that parses the query string and returns an object of query parameters
      const queryParams = getQueryParams(this.props.location?.search)
      if (queryParams.season) {
        seasonNumber = parseInt(queryParams.season, 10)
      }
    }

    const seasonMatch = items.find(
      season => season.attributes.number === seasonNumber
    )

    if (seasonMatch) {
      seasonTitle = seasonMatch.attributes.title // Assuming `title` is the property name for the season's title
    } else if (items.length > 0) {
      seasonTitle = items[0].attributes.title // Default to the first season's title if no match is found
    }

    return seasonTitle
  }

  handleChange = seasonSlug => {
    const { mySeasons } = this.props

    const season = mySeasons.items.find(s => s.attributes.slug === seasonSlug)
    this.selectSeason(seasonSlug)
    this.props.onChange({ seasonSlug, seasonTitle: season.attributes.title })
  }

  selectSeason = seasonSlug => {
    const { mySeasons } = this.props
    const season = mySeasons.items.find(s => s.attributes.slug === seasonSlug)
    const { number, slug } = season.attributes
    const link = season.links.episodes

    const { dispatch, allEpisodes, updateUrl } = this.props
    const newSeason = {
      seasonSlug: slug,
      seasonLink: link,
      seasonTitle: season.attributes.title,
    }

    this.setState({
      selectedSeason: newSeason,
      limit: 24,
    })
    // if we are updating the url, just do that and let the lifecycle methods take care of it
    if (updateUrl) {
      dispatch(push({ search: `?season=${number}` }))
    } else {
      const seasonEps = allEpisodes.find(
        e => e.seasonId === newSeason.seasonSlug
      )
      if (seasonEps && seasonEps.items) {
        this.setState({
          seasonData: seasonEps.items.slice(0, 24),
          totalItems: seasonEps.total,
        })
      } else {
        dispatch(fetchSeasonEpisodes(newSeason, 24))
      }
    }
  }

  showMore = () => {
    const { dispatch } = this.props
    const { selectedSeason } = this.state
    this.setState({ limit: 1000 })
    // async setState so call with number
    dispatch(fetchSeasonEpisodes(selectedSeason, 1000))
  }

  getEpisodeGrid = () => {
    const { seasonData } = this.state
    const jsx = []
    // eslint-disable-next-line no-unused-vars
    for (const [_i, item] of seasonData.entries()) {
      jsx.push(
        <div className='col s12 m4 l3' key={`episode-grid-${item.id}`}>
          <EpisodeCard
            item={item}
            onThumbnailClick={() =>
              trackClick('grid_item_image', { context: item.uuid })
            }
            onTitleClick={() =>
              trackClick('grid_item_text', { context: item.uuid })
            }
            isSeasonEpisode
          />
        </div>
      )
    }
    return jsx
  }

  render() {
    const {
      dispatch,
      isFetching,
      mySeasons,
      showSlug,
      title,
      type,
    } = this.props
    const { limit, seasonData, selectedSeason, totalItems } = this.state

    if (mySeasons === undefined || mySeasons.items === undefined) return null
    const selectedItem = mySeasons.items.find(
      ({ attributes: { slug } }) => selectedSeason.seasonSlug === slug
    )

    return (
      <section
        className='carousel-container'
        ref={section => (this.container = section)}
      >
        <div className='carousel-title'>{title}</div>
        <div className='carousel-actions'>
          <RTDropdownList
            ariaLabel='Select Season'
            onClick={this.handleChange}
            position='top-left-inner'
            options={mySeasons.items.map(season => ({
              value: season.attributes.slug,
              display: season.attributes.title,
            }))}
            selected={selectedItem?.attributes?.slug}
          >
            {selectedItem?.attributes?.title}
          </RTDropdownList>
          <RssButtonWrapper isSeriesPage>
            <RssButton />
          </RssButtonWrapper>
        </div>
        {seasonData && seasonData.length !== 0 && type === 'carousel' && (
          <Carousel
            data={seasonData}
            type='episodes'
            dispatch={dispatch}
            filter={{ type: 'season', name: showSlug }}
          />
        )}
        {seasonData && seasonData.length !== 0 && type === 'grid' && (
          <div className='episode-grid-container row'>
            {this.getEpisodeGrid()}
            {(totalItems > limit || seasonData.length !== totalItems) && (
              <Preloader condition={!isFetching}>
                <div
                  className='col s12 show-more'
                  role='presentation'
                  onClick={() => this.showMore()}
                >
                  show more ({totalItems - limit})
                  <i className='icon-keyboard_arrow_down' />
                </div>
              </Preloader>
            )}
          </div>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { allEpisodes, allSeasons, isFetching } = state.commonReducer.seasons
  const { user } = state.authReducer
  const { location } = state.router
  const userTier = getUserTier(state)

  const mySeasons = allSeasons.find(s => s.showId === ownProps.showSlug)

  return {
    allEpisodes,
    isFetching,
    location,
    mySeasons,
    user,
    userTier,
  }
}

const mapDispatchToProps = dispatch => ({
  showRssModal: modalProps => dispatch(showModal('FIRST_ONLY_RSS', modalProps)),
  hideRssModal: () => dispatch(hideModal('FIRST_ONLY_RSS')),
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonEpisodes)
