import React from 'react'

import downloadButtonAppStore from 'common/assets/download-button-app-store.svg'
import downloadButtonGooglePlay from 'common/assets/download-button-google-play.png'

import './styles.scss'

const AppStoreButtons = () => (
  <div className='app-store-buttons'>
    <div className='app-store-buttons__button'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://apps.apple.com/us/app/rooster-teeth/id1012562632'
      >
        <img src={downloadButtonAppStore} alt='Download on the App Store' />{' '}
      </a>
    </div>
    {/* The Google Play icon and App Store icon are different aspect ratios, so we can't use a column-gap, because you can't use `fr` in column-gap. So we have to introduce a middle element to the grid. */}
    <div />
    <div className='app-store-buttons__button'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://play.google.com/store/apps/details?id=com.roosterteeth.roosterteeth'
      >
        <img src={downloadButtonGooglePlay} alt='Download on Google Play' />{' '}
      </a>
    </div>
  </div>
)

export default AppStoreButtons
