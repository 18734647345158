/** *****************
 *
 *  Request Headers
 *
 ******************* */

import { config } from 'common/config'
import { getAccessToken, getClientId } from 'common/helpers'

export const clientDebugHeaders = {
  'Client-Type': 'web',
  'Client-Id': getClientId(),
}

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const getAuthHeaders = () => ({
  ...jsonHeaders,
  Authorization: `Bearer ${getAccessToken()}`,
})

export const airtableHeaders = {
  Authorization: `Bearer ${config.airtable.publicKey}`,
}

export const noCache = { 'Cache-Control': 'no-cache' }
