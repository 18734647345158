import React from 'react'
import PropTypes from 'prop-types'

import SecondaryBtn from 'common/components/Buttons/Secondary'
import TertiaryBtn from 'common/components/Buttons/Tertiary'

import './styles.scss'

const SideNavBtns = ({ onLoginClick, onSignupClick }) => (
  <div className='side-nav-btns'>
    <SecondaryBtn
      bg='dark'
      className='side-nav-btns__btn qa-side-nav-btns-btn qa-side-nav-btns-signup'
      linkTo='/signup'
      onClick={onSignupClick}
    >
      Sign Up
    </SecondaryBtn>
    <TertiaryBtn
      bg='dark'
      className='side-nav-btns__btn qa-side-nav-btns-btn qa-side-nav-btns-login'
      linkTo='/login'
      onClick={onLoginClick}
    >
      Log In
    </TertiaryBtn>
  </div>
)

SideNavBtns.propTypes = {
  onLoginClick: PropTypes.func,
  onSignupClick: PropTypes.func,
}

SideNavBtns.defaultProps = {
  onLoginClick: () => null,
  onSignupClick: () => null,
}

export default SideNavBtns
