export const SELECT_CHANNEL = 'rootApp/SELECT_CHANNEL'

export const GET_CHANNELS_REQUEST = 'rootApp/GET_CHANNELS_REQUEST'
export const GET_CHANNELS_SUCCESS = 'rootApp/GET_CHANNELS_SUCCESS'
export const GET_CHANNELS_FAILURE = 'rootApp/GET_CHANNELS_FAILURE'

export const GET_BADGES_REQUEST = 'rootApp/GET_BADGES_REQUEST'
export const GET_BADGES_SUCCESS = 'rootApp/GET_BADGES_SUCCESS'
export const GET_BADGES_FAILURE = 'rootApp/GET_BADGES_FAILURE'

export const SET_VIDEO_PLAYER_STATE = 'rootApp/SET_VIDEO_PLAYER_STATE'

export const OPEN_SEARCH_V2 = 'rootApp/OPEN_SEARCH_V2'
export const CLOSE_SEARCH_V2 = 'rootApp/CLOSE_SEARCH_V2'

export const OPEN_MOBILE_NAV = 'rootApp/OPEN_MOBILE_NAV'
export const CLOSE_MOBILE_NAV = 'rootApp/CLOSE_MOBILE_NAV'
