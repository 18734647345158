import React, { useLayoutEffect, useState } from 'react'
import Helmet from 'react-helmet'

import { checkScrollbars } from 'common/helpers'

function DetectScrollbars() {
  const [scrollbars, setScrollbars] = useState(null)

  useLayoutEffect(() => {
    setScrollbars(checkScrollbars())
  }, [])

  return <Helmet htmlAttributes={{ 'data-scrollbars': scrollbars }} />
}

export default DetectScrollbars
