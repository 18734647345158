import uniqBy from 'lodash.uniqby'

import {
  V2_GET_SHOWS_FAILURE,
  V2_GET_SHOWS_REQUEST,
  V2_GET_SHOWS_SUCCESS,
  V2_RESET_SHOWS,
} from 'showsApp/actions/types'

const initialState = {
  page: 1,
  totalPages: 1,
  shows: [],
  isFetching: false,
  // filterChannels
  // filterGenres
}

const browseShowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case V2_GET_SHOWS_FAILURE:
      return state

    case V2_GET_SHOWS_REQUEST:
      return { ...state, isFetching: true }

    case V2_GET_SHOWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        page: action.page ?? state.page,
        totalPages: action.totalPages ?? state.totalPages,
        shows: uniqBy([...state.shows, ...action.shows], 'uuid'),
      }

    case V2_RESET_SHOWS:
      return initialState
  }

  return state
}

export default browseShowsReducer
