import { priceInDollars } from 'common/helpers'

export function getDiscount() {
  const { discount_in_cents } = this.props.preview.invoice

  return priceInDollars(discount_in_cents)
}

export function getTotal() {
  const { total_in_cents } = this.props.preview.invoice

  return priceInDollars(total_in_cents)
}

export function isDiscountApplied() {
  const { discount_in_cents } = this.props.preview.invoice

  return discount_in_cents > 0
}
