import React from 'react'
import { Link } from 'react-router-dom'

import Avatar from 'common/components/Avatar'

import './styles.scss'

const CommunityGroup = ({
  backgroundImage,
  logo,
  membersCount,
  name,
  onClick = () => {},
  slug,
}) => (
  <Link
    className='community-group__outer'
    to={`/g/${slug?.toLowerCase()}`}
    onClick={onClick}
  >
    <div className='community-group__main'>
      <div className='community-group__curtain' />
      <div
        className='community-group__teaser'
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
      <Avatar
        alt={`Group image for ${name}`}
        border={3}
        className='community-group__avatar'
        size={84}
        src={logo}
      />
      <div className='community-group__name'>{name}</div>
      {membersCount !== undefined && (
        <div className='community-group__member-count'>
          {membersCount} Member{membersCount === 1 ? '' : 's'}
        </div>
      )}
    </div>
  </Link>
)

export default CommunityGroup
