import React from 'react'

import Avatar from 'common/components/Avatar'
import { a11yButtonProps } from 'common/helpers/accessibility'

import { TAB_USERS } from '../../helpers/constants'
import useGoToUserPage from '../../helpers/useGoToUserPage'
import EmptySearch from '../shared/EmptySearch'

import './styles.scss'

const User = ({ closeSearchV2, goToUserPage, searchTerm, user }) => {
  const { profile_picture, name } = user

  const goToThisUserPage = useGoToUserPage({
    closeSearchV2,
    name,
    goToUserPage,
    searchTerm,
    tab: TAB_USERS,
    user,
  })

  return (
    <div
      {...a11yButtonProps(goToThisUserPage)}
      className='search-v2-users__user'
    >
      <Avatar border={2} size={110} src={profile_picture} />
      <div className='search-v2-users__user-username'>{name}</div>
    </div>
  )
}

const TabUsers = ({ closeSearchV2, goToUserPage, users, searchTerm }) =>
  users.data.length === 0 ? (
    <EmptySearch />
  ) : (
    <div className='search-v2-users'>
      {users.data.map(user => (
        <User
          key={user.uuid}
          closeSearchV2={closeSearchV2}
          goToUserPage={goToUserPage}
          user={user}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  )

export default TabUsers
