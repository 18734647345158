import { userProfilePath } from 'common/helpers'
import {
  FETCH_COMMUNITY_USER,
  FETCH_COMMUNITY_USER_SUCCESSFUL,
  FETCH_COMMUNITY_USER_FAILED,
  FETCH_COMMUNITY_USER_FOLLOWING,
  FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL,
  FETCH_COMMUNITY_USER_FOLLOWING_FAILED,
  FETCH_COMMUNITY_USER_FOLLOWERS,
  FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL,
  FETCH_COMMUNITY_USER_FOLLOWERS_FAILED,
  FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER,
  FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL,
  FETCH_COMMUNITY_USER_MEMBERSHIPS_FAILED,
  FETCH_MY_MEMBERSHIPS,
  FETCH_MY_MEMBERSHIPS_SUCCESSFUL,
  FETCH_MY_MEMBERSHIPS_FAILED,
  MUTE_COMMUNITY_USER,
  MUTE_COMMUNITY_USER_SUCCESSFUL,
  MUTE_COMMUNITY_USER_FAILED,
  UNMUTE_COMMUNITY_USER,
  UNMUTE_COMMUNITY_USER_SUCCESSFUL,
  UNMUTE_COMMUNITY_USER_FAILED,
  FOLLOW_COMMUNITY_USER,
  FOLLOW_COMMUNITY_USER_SUCCESSFUL,
  FOLLOW_COMMUNITY_USER_FAILED,
  UNFOLLOW_COMMUNITY_USER,
  UNFOLLOW_COMMUNITY_USER_SUCCESSFUL,
  UNFOLLOW_COMMUNITY_USER_FAILED,
  FOLLOW_USER_IF_ALLOWED,
  UNFOLLOW_USER_IF_ALLOWED,
  FETCH_HOME_FEED,
  FETCH_HOME_FEED_SUCCESSFUL,
  FETCH_HOME_FEED_FAILED,
  FETCH_SUB_COMMUNITY_FEED,
  FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL,
  FETCH_SUB_COMMUNITY_FEED_FAILED,
  FETCH_SUB_COMMUNITY,
  FETCH_SUB_COMMUNITY_FAILED,
  FETCH_SUB_COMMUNITY_SUCCESSFUL,
  FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY,
  LEAVE_SUB_COMMUNITY,
  LEAVE_SUB_COMMUNITY_SUCCESSFUL,
  LEAVE_SUB_COMMUNITY_FAILED,
  JOIN_SUB_COMMUNITY,
  JOIN_SUB_COMMUNITY_SUCCESSFUL,
  JOIN_SUB_COMMUNITY_FAILED,
  LIKE_POST,
  UNLIKE_POST,
  LIKE_OR_UNLIKE_POST_SUCCESSFUL,
  FLAG_POST,
  PIN_POST,
  FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS,
  FETCH_EXPLORE_POSTS_AND_COMMUNITIES,
  FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL,
  FETCH_EXPLORE_POSTS_AND_COMMUNITIES_FAILED,
  FETCH_MEMBERS_FOR_SUB_COMMUNITY,
  FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL,
  FETCH_USER_ACTIVITY_FEED,
  FETCH_USER_ACTIVITY_FEED_FAILED,
  FETCH_USER_ACTIVITY_FEED_SUCCESSFUL,
  SUBMIT_USER_UPDATE_FORM,
  SUBMIT_USER_UPDATE_FORM_SUCCESSFUL,
  SUBMIT_USER_UPDATE_FORM_FAILED,
  SET_IS_EDITING_USER,
  UPDATE_USER_FORM,
  UNPIN_POST,
} from 'communityApp/actions/types'
import { DeletePrompt } from 'communityApp/components'
import { getUserInfoFromStateAndUrl } from 'communityApp/selectors'
import { showModal } from 'modalApp/actions'
import { push } from 'redux-first-history'

// Get user
export const fetchCommunityUser = username => ({
  username,
  type: FETCH_COMMUNITY_USER,
})

export const fetchCommunityUserSuccesful = user => ({
  user,
  type: FETCH_COMMUNITY_USER_SUCCESSFUL,
})

export const fetchCommunityUserFailed = ({ username, reason }) => ({
  reason,
  username,
  type: FETCH_COMMUNITY_USER_FAILED,
})

// See who a user is following
export const fetchCommunityUserFollowing = () => (dispatch, getState) =>
  dispatch({
    userId: getUserInfoFromStateAndUrl(getState()).profileUser.id,
    type: FETCH_COMMUNITY_USER_FOLLOWING,
  })

export const fetchCommunityUserFollowingSuccesful = ({
  userId,
  users,
  pagination,
}) => ({
  userId,
  users,
  pagination,
  type: FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL,
})

export const fetchCommunityUserFollowingFailed = userId => ({
  userId,
  type: FETCH_COMMUNITY_USER_FOLLOWING_FAILED,
})

// See groups that the profile user is a member of
export const fetchMembershipsForCurrentProfileUser = () => ({
  type: FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER,
})

// See groups in which logged in user is a member (sometimes this is the same as the profile user)
export const fetchMyMemberships = () => ({
  type: FETCH_MY_MEMBERSHIPS,
})

export const fetchCommunityUserMembershipsSuccesful = ({
  userId,
  memberships,
}) => ({
  userId,
  memberships,

  type: FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL,
})

export const fetchMembershipsForCurrentProfileUserFailed = userId => ({
  userId,
  type: FETCH_COMMUNITY_USER_MEMBERSHIPS_FAILED,
})

// See who follows a user
export const fetchCommunityUserFollowers = () => (dispatch, getState) =>
  dispatch({
    userId: getUserInfoFromStateAndUrl(getState()).profileUser.id,
    type: FETCH_COMMUNITY_USER_FOLLOWERS,
  })

export const fetchCommunityUserFollowersSuccesful = ({
  userId,
  users,
  pagination,
}) => ({
  userId,
  users,
  pagination,
  type: FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL,
})

export const fetchCommunityUserFollowersFailed = userId => ({
  userId,
  type: FETCH_COMMUNITY_USER_FOLLOWERS_FAILED,
})

// Mute user
export const muteUser = userId => ({ userId, type: MUTE_COMMUNITY_USER })
export const muteUserSuccessful = userId => ({
  userId,
  type: MUTE_COMMUNITY_USER_SUCCESSFUL,
})
export const muteUserFailed = userId => ({
  userId,
  type: MUTE_COMMUNITY_USER_FAILED,
})

// Unmute user
export const unmuteUser = userId => ({ userId, type: UNMUTE_COMMUNITY_USER })
export const unmuteUserSuccessful = userId => ({
  userId,
  type: UNMUTE_COMMUNITY_USER_SUCCESSFUL,
})
export const unmuteUserFailed = userId => ({
  userId,
  type: UNMUTE_COMMUNITY_USER_FAILED,
})

// Follow user
export const followUser = userId => ({ userId, type: FOLLOW_COMMUNITY_USER })
export const followUserSuccessful = userId => ({
  userId,
  type: FOLLOW_COMMUNITY_USER_SUCCESSFUL,
})
export const followUserFailed = userId => ({
  userId,
  type: FOLLOW_COMMUNITY_USER_FAILED,
})

// Unfollow user
export const unfollowUser = userId => ({
  userId,
  type: UNFOLLOW_COMMUNITY_USER,
})
export const unfollowUserSuccessful = ({ userId, loggedInUserId }) => ({
  userId,
  loggedInUserId,
  type: UNFOLLOW_COMMUNITY_USER_SUCCESSFUL,
})
export const unfollowUserFailed = userId => ({
  userId,
  type: UNFOLLOW_COMMUNITY_USER_FAILED,
})

// Conveniences for follow / unfollow
export const followUserIfAllowed = userId => ({
  type: FOLLOW_USER_IF_ALLOWED,
  userId,
})
export const unfollowUserIfAllowed = userId => ({
  type: UNFOLLOW_USER_IF_ALLOWED,
  userId,
})

// Fetch home feed
export const fetchHomeFeed = (opts = {}) => ({
  type: FETCH_HOME_FEED,
  ...opts,
})
export const fetchHomeFeedSuccessful = ({
  posts,
  pagination,
  resetPagination,
}) => ({
  type: FETCH_HOME_FEED_SUCCESSFUL,
  posts,
  pagination,
  resetPagination,
})
export const fetchHomeFeedFailed = () => ({
  type: FETCH_HOME_FEED_FAILED,
})

// Fetch a user's activity
export const fetchUserActivityFeed = (userId, opts = {}) => ({
  type: FETCH_USER_ACTIVITY_FEED,
  userId,
  ...opts,
})
export const fetchUserActivityFeedSuccessful = ({
  posts,
  pagination,
  userId,
  resetPagination,
}) => ({
  type: FETCH_USER_ACTIVITY_FEED_SUCCESSFUL,
  posts,
  pagination,
  userId,
  resetPagination,
})
export const fetchUserActivityFeedFailed = userId => ({
  type: FETCH_USER_ACTIVITY_FEED_FAILED,
  userId,
})

// Fetch sub-community feed
export const fetchSubCommunityFeed = (subCommunityId, opts = {}) => ({
  type: FETCH_SUB_COMMUNITY_FEED,
  subCommunityId,
  ...opts,
})
export const fetchSubCommunityFeedSuccessful = ({
  subCommunityId,
  posts,
  pagination,
  resetPagination,
  stickiedPost,
}) => ({
  type: FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL,
  subCommunityId,
  posts,
  pagination,
  resetPagination,
  stickiedPost,
})
export const fetchSubCommunityFeedFailed = subCommunityId => ({
  type: FETCH_SUB_COMMUNITY_FEED_FAILED,
  subCommunityId,
})

// Fetch a sub-community
export const fetchSubCommunity = subCommunitySlug => ({
  subCommunitySlug,
  type: FETCH_SUB_COMMUNITY,
})
export const fetchSubCommunitySuccesful = subCommunity => ({
  subCommunity,
  type: FETCH_SUB_COMMUNITY_SUCCESSFUL,
})
export const fetchSubCommunityFailed = ({ subCommunitySlug, reason }) => ({
  reason,
  subCommunitySlug,
  type: FETCH_SUB_COMMUNITY_FAILED,
})

// See members of a sub-community
export const fetchMembersForCurrentSubCommunity = () => ({
  type: FETCH_MEMBERS_FOR_CURRENT_SUB_COMMUNITY,
})
export const fetchMembersForSubCommunity = subCommunityId => ({
  type: FETCH_MEMBERS_FOR_SUB_COMMUNITY,
  subCommunityId,
})
export const fetchMembersForSubCommunitySuccessful = ({
  subCommunityId,
  users,
  pagination,
  totalMembersCount,
}) => ({
  type: FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL,
  subCommunityId,
  users,
  pagination,
  totalMembersCount,
})

// Leave a sub-community
export const leaveSubCommunity = subCommunityId => ({
  type: LEAVE_SUB_COMMUNITY,
  subCommunityId,
})
export const leaveSubCommunitySuccessful = subCommunityId => ({
  type: LEAVE_SUB_COMMUNITY_SUCCESSFUL,
  subCommunityId,
})
export const leaveSubCommunityFailed = subCommunityId => ({
  type: LEAVE_SUB_COMMUNITY_FAILED,
  subCommunityId,
})

// Join a sub-community
export const joinSubCommunity = subCommunityId => ({
  type: JOIN_SUB_COMMUNITY,
  subCommunityId,
})
export const joinSubCommunitySuccessful = subCommunityId => ({
  type: JOIN_SUB_COMMUNITY_SUCCESSFUL,
  subCommunityId,
})
export const joinSubCommunityFailed = subCommunityId => ({
  type: JOIN_SUB_COMMUNITY_FAILED,
  subCommunityId,
})

// Like and unlikes
export const likePost = postId => ({
  type: LIKE_POST,
  postId,
})
export const unlikePost = postId => ({
  type: UNLIKE_POST,
  postId,
})
export const likeOrUnlikePostSuccessful = ({ postId, likes }) => ({
  type: LIKE_OR_UNLIKE_POST_SUCCESSFUL,
  likes,
  postId,
})

// Flag post
// NOTE: There are no "successful" or "failed" actions for flags. Instead, the saga just triggers a notice.
export const flagPostAsAbuse = postId => ({
  type: FLAG_POST,
  postId,
  flagType: 'abuse',
})
export const flagPostAsSpam = postId => ({
  type: FLAG_POST,
  postId,
  flagType: 'spam',
})

// Pin post to community
export const pinPostToCommunity = post => ({
  type: PIN_POST,
  post,
})
export const unpinPostFromCommunity = post => ({
  type: UNPIN_POST,
  post,
})

// Go to a user's page
export const goToUserPage = username => {
  const url = userProfilePath(username)

  return push(url)
}

// Delete a post (by opening modal to confirm)
export const openDeletePostModal = post => {
  const DELETE_POST_MODAL_CLASSNAME =
    'modal-overlay modal-prompt modal-mobile-page'

  return showModal('COMPONENT_MODAL', {
    component: DeletePrompt,
    post,
    className: DELETE_POST_MODAL_CLASSNAME,
  })
}

export const openEditModal = post => {
  const { owner } = post
  let topicId = owner.type.toLowerCase() === 'community' ? owner.data.id : ''
  return showModal('CREATE_POST', {
    topicId,
    submitCopy: 'Update',
    editing: true,
    post,
  })
}

// Get own user and memberships, which are treated as one piece of information
export const fetchMyCommunityUserAndMemberships = () => ({
  type: FETCH_MY_COMMUNITY_USER_AND_MEMBERSHIPS,
})

// Get example posts and communities for anonymous and logged in users
export const fetchExplorePostsAndCommunities = () => ({
  type: FETCH_EXPLORE_POSTS_AND_COMMUNITIES,
})
export const fetchExplorePostsAndCommunitiesSuccessful = ({
  examplePosts,
  exampleCommunities,
}) => ({
  type: FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL,
  examplePosts,
  exampleCommunities,
})
export const fetchExplorePostsAndCommunitiesFailed = () => ({
  type: FETCH_EXPLORE_POSTS_AND_COMMUNITIES_FAILED,
})

// Update user form
export const setIsEditingUser = isEditingUser => ({
  type: SET_IS_EDITING_USER,
  isEditingUser,
})
export const updateUserForm = fields => ({
  type: UPDATE_USER_FORM,
  fields,
})

// Update user using form fields in state
export const submitUserUpdateForm = () => ({
  type: SUBMIT_USER_UPDATE_FORM,
})
export const submitUserUpdateFormSuccessful = userFromBusinessService => ({
  type: SUBMIT_USER_UPDATE_FORM_SUCCESSFUL,
  userFromBusinessService,
})
export const submitUserUpdateFormFailed = errors => ({
  type: SUBMIT_USER_UPDATE_FORM_FAILED,
  errors,
})
