import { useCallback } from 'react'
import { connect } from 'react-redux'

import { config } from 'common/config'
import { getAccessToken } from 'common/helpers'
import { getAnalyticsMemberTier, trackEvent } from 'common/metrics'

const DiscordLink = ({ user, accessToken, children: render }) => {
  let discordHref
  if (accessToken) {
    const params = new URLSearchParams({
      client_id: config.discord.clientId,
      redirect_uri: config.discord.redirectUri,
      response_type: 'code',
      scope: 'guilds.join identify',
      state: accessToken,
    }).toString()

    discordHref = `https://discord.com/api/oauth2/authorize?${params}`
  } else {
    discordHref = config.discord.anonInviteLink
  }

  const memberTier = getAnalyticsMemberTier(user)

  const trackDiscordClick = useCallback(
    () => trackEvent('Discord Link Clicked', { member_tier: memberTier }),
    [memberTier]
  )

  return render({ discordHref, trackDiscordClick })
}

const mapStateToProps = (state, _ownProps) => {
  const { user } = state.authReducer
  const accessToken = getAccessToken()

  return { user, accessToken }
}

export default connect(mapStateToProps)(DiscordLink)
