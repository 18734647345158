import React from 'react'
import PropTypes from 'prop-types'

import { SearchBtnBase } from './styled'

function SearchBtn({ className, onClick }) {
  return (
    <SearchBtnBase
      aria-label='Search'
      className={className}
      id='btn-search'
      onClick={onClick}
      title='Search (Alt + F)'
    >
      <svg viewBox='0 0 21 21'>
        <path d='M17.555,15.44l-3.483-3.483a7.691,7.691,0,1,0-2.121,2.122l3.483,3.483a1.519,1.519,0,0,0,2.121,0,1.479,1.479,0,0,0,.05-2.1A.046.046,0,0,0,17.555,15.44ZM7.683,2.252A5.437,5.437,0,1,1,2.246,7.689,5.437,5.437,0,0,1,7.683,2.252Z' />
      </svg>
    </SearchBtnBase>
  )
}

SearchBtn.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

SearchBtn.defaultProps = {
  className: null,
}

export default SearchBtn
