export const GET_EPISODE_REQUEST = 'episodeApp/GET_EPISODE_REQUEST'
export const GET_EPISODE_SUCCESS = 'episodeApp/GET_EPISODE_SUCCESS'
export const GET_EPISODE_FAILURE = 'episodeApp/GET_EPISODE_FAILURE'
export const GET_EPISODES_REQUEST = 'episodeApp/GET_EPISODES_REQUEST'
export const GET_EPISODES_SUCCESS = 'episodeApp/GET_EPISODES_SUCCESS'
export const GET_EPISODES_FAILURE = 'episodeApp/GET_EPISODES_FAILURE'
export const GET_NEXT_PAGE_EPISODES_REQUEST =
  'episodeApp/GET_NEXT_PAGE_EPISODES_REQUEST'
export const GET_NEXT_PAGE_EPISODES_SUCCESS =
  'episodeApp/GET_NEXT_PAGE_EPISODES_SUCCESS'
export const GET_NEXT_PAGE_EPISODES_FAILURE =
  'episodeApp/GET_NEXT_PAGE_EPISODES_FAILURE'

export const GET_ALL_EPS_PERCENT_REQUEST =
  'episodeApp/GET_ALL_EPS_PERCENT_REQUEST'
export const GET_ALL_EPS_PERCENT_SUCCESS =
  'episodeApp/GET_ALL_EPS_PERCENT_SUCCESS'
export const GET_ALL_EPS_PERCENT_FAILURE =
  'episodeApp/GET_ALL_EPS_PERCENT_FAILURE'

export const GET_NEXT_REQUEST = 'episodeApp/GET_NEXT_REQUEST'
export const GET_NEXT_SUCCESS = 'episodeApp/GET_NEXT_SUCCESS'
export const GET_NEXT_FAILURE = 'episodeApp/GET_NEXT_FAILURE'

export const GET_NEXT_EPS_PERCENT_REQUEST =
  'episodeApp/GET_NEXT_EPS_PERCENT_REQUEST'
export const GET_NEXT_EPS_PERCENT_SUCCESS =
  'episodeApp/GET_NEXT_EPS_PERCENT_SUCCESS'
export const GET_NEXT_EPS_PERCENT_FAILURE =
  'episodeApp/GET_NEXT_EPS_PERCENT_FAILURE'

export const GET_MERCH_REQUEST = 'episodeApp/GET_MERCH_REQUEST'
export const GET_MERCH_SUCCESS = 'episodeApp/GET_MERCH_SUCCESS'
export const GET_MERCH_FAILURE = 'episodeApp/GET_MERCH_FAILURE'
