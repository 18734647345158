/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { restrictFiles } from 'common/helpers'

export class FileUpload extends Component {
  static propTypes = {
    id: PropTypes.string,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    id: 'img-upload',
    accept: 'image/*',
    className: 'hide',
    multiple: false,
  }

  // necessary because imgInput is not defined if you try to use a fat arrow function
  // a ref is necessary because a label may be used to proxy the event
  onImageUploadSelected = _event => {
    if (!this.imgInput.files) {
      return
    }
    const updatedFiles = restrictFiles(this.imgInput.files)

    // clearing the file value so we get a change event if the user selects
    // the same image a second time
    this.imgInput.value = ''
    if (!updatedFiles.length) {
      return
    }
    this.props.onChange(updatedFiles)
  }

  render() {
    const { accept, className, id, multiple, ...rest } = this.props

    return (
      <input
        className={className}
        id={id}
        type='file'
        accept={accept}
        ref={fu => (this.imgInput = fu)}
        name='image-upload'
        multiple={multiple}
        {...rest}
        onChange={this.onImageUploadSelected}
      />
    )
  }
}
