import { simpleGET } from 'common/helpers/saga'
import { takeEvery } from 'redux-saga/effects'
import { endpoints } from 'common/config'

export function* fetchUserSearch({ term }) {
  yield simpleGET({
    authenticated: false,
    url: `${endpoints.communityBase}/v1/users/search?q=${term}&limit=100`,
    type: 'c/user_search',
  })
}

export function* userSearch() {
  yield takeEvery('c/user_search', fetchUserSearch)
}
