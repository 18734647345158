import { useEffect, useState } from 'react'

/**
 * Increments through integers that are less than `count`, and resets to `0` every time it gets to the end of the cycle. Increment happens every `interval` milliseconds.
 *
 * @param {object} options
 * @param {string} options.count The number of integers to cycle through. Should usually be `someArray.length`.
 * @param {string} options.interval Frequency in milliseconds that the integer should be incremented.
 * @return {number} The current integer.
 * @example

    const fruits = ['apple', 'banana', 'orange', 'grape']
    const FruitOfTheMinute = () => {
      const fruitIndex = useIncrementInterval({ count: fruits.length, interval: 60000 })
      // Will change every minute. Once it reaches "grape", it will start back over at "apple".
      return fruits[fruitIndex]
    }
 */
const useIncrementInterval = ({ count, interval }) => {
  const [currentIndex, setCurrentMessageIndex] = useState(0)

  useEffect(
    () => {
      const intervalId = setInterval(
        () => setCurrentMessageIndex(index => (index + 1) % count),
        interval
      )

      return () => clearInterval(intervalId)
    },
    [count, interval]
  )

  return currentIndex
}

export default useIncrementInterval
