import { getDomain } from 'common/helpers'

const getStoreHref = isAuthenticated => {
  const storeHref = isAuthenticated
    ? '/login/store'
    : `https://store.${getDomain(window.location.href, true)}`

  return storeHref
}

export default getStoreHref
