import { createSelector } from '@reduxjs/toolkit'
import { assoc, pipe, prop, propOr, test } from 'ramda'

const errorMessageRegex = /\[?(.*?)\]?:\s(.*)/

export const getSubscriptions = propOr({}, 'subscriptions')

export const getSubscriptionPreview = createSelector(
  getSubscriptions,
  prop('preview')
)

export const getSubscriptionInvoice = createSelector(
  getSubscriptionPreview,
  prop('invoice')
)

export const getObjectFromErrorMessage = errorMessage => {
  if (!errorMessage) {
    return null
  }

  if (!test(errorMessageRegex, errorMessage)) {
    return errorMessage
  }

  const [, key, value] = errorMessage.match(errorMessageRegex)
  return assoc(key, value, {})
}

export const getSubscriptionError = createSelector(
  [getSubscriptions],
  pipe(
    propOr(null, 'errorMessage'),
    getObjectFromErrorMessage
  )
)

export const getCouponError = createSelector(
  getSubscriptionError,
  propOr(null, 'coupon_code')
)

export const getSubscriptionInvoicePrice = createSelector(
  getSubscriptionInvoice,
  propOr(0, 'total_in_cents')
)
