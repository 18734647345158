import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const ErrorCard = ({ dismissable, hide, message }) => {
  if (!message) {
    return null
  }

  return (
    <div className='row error-row' role='alert'>
      <div className='col s12'>
        <div className='card red lighten-5'>
          <div className='card-content red-text'>
            <p>
              <i className='icon-error red-text' />
              <span className='message'>{message}</span>
            </p>
            {dismissable && (
              <button
                type='button'
                className='close'
                data-dismiss='alert'
                aria-label='Close'
                onClick={() => hide()}
              >
                <span aria-hidden='true'>×</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ErrorCard.propTypes = {
  dismissable: PropTypes.bool,
  hide: PropTypes.func,
  message: PropTypes.node,
}

ErrorCard.defaultProps = {
  dismissable: true,
  hide: () => null,
  message: '',
}

export default ErrorCard
