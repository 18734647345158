import { takeEvery } from 'redux-saga/effects'
import { simpleGET } from 'common/helpers/saga'
import { endpoints } from 'common/config'

export function* getIndex({
  authenticated = true,
  reset,
  urlParams = { limit: 12 },
}) {
  let url = new URL(`${endpoints.communityBase}/v1/communities`)
  let key
  for (key in urlParams) {
    url.searchParams.set(key, urlParams[key])
  }

  yield simpleGET({
    // we need this request authed, as sometimes admins will be using it to
    // fetch _all_ groups, including hidden ones
    authenticated,
    url,
    type: 'communityApp/BROWSE',
    reset,
  })
}

export function* getExamplePosts() {
  yield simpleGET({
    url: endpoints.communityBase + '/v1/communities/explore_feed',
    type: 'communityApp/EXAMPLE_POSTS',
    id: 'explore_feed',
  })
}

export function* browse() {
  yield takeEvery('communityApp/BROWSE', getIndex)
  yield takeEvery('communityApp/EXAMPLE_POSTS', getExamplePosts)
}
