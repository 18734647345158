import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { Post, Preloader } from 'common/components'
import { isInViewport } from 'common/helpers'

import './styles.scss'
import userFeed from 'common/reducers/user-feed'

class FeedContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    feed: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.string),
      pagination: PropTypes.shape({
        next: PropTypes.string,
        previous: PropTypes.string,
      }),
      count: PropTypes.number,
      stickiedPostId: PropTypes.string,
      isFetching: PropTypes.bool,
    }).isRequired,
    moderators: PropTypes.array,
    posts: PropTypes.object,
    page: PropTypes.number,
    // get posts is externalized so we can display feeds from different
    // sources... expects a boolean property to trigger a reset
    getPosts: PropTypes.func.isRequired,
    getNextPosts: PropTypes.func,
    userList: PropTypes.array,
    // additional content to display interspersed with feed items
    injectedContent: PropTypes.array,
  }

  static defaultProps = {
    canPage: false,
    moderators: [],
    userList: [],
  }

  state = {
    loadingMore: false,
    page: this.props.page || 1,
  }

  showMoreRef = React.createRef()

  onScroll = debounce(e => {
    const buttonVisible =
      this.showMoreRef.current && isInViewport(this.showMoreRef.current)
    if (buttonVisible) {
      this.setState({ loadingMore: true })
      this.props.getNextPosts()
    }
  }, 100)

  // ---------
  // Lifecycle
  // ---------
  /**
   * Sometimes we have a uuid when we mount the component, in this case,
   * fetch some posts!
   */
  componentDidMount() {
    const { getPosts } = this.props
    getPosts()
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentDidUpdate(prevProps, prevState) {
    const { feed, getPosts, id, user } = this.props
    if ((!prevProps.user && user) || id !== prevProps.id) {
      getPosts()
    }

    if (feed.list.length > prevProps.feed.list.length) {
      this.setState({ loadingMore: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  getModerator = post => {
    const { moderators } = this.props
    const match = moderators.find(mod => mod.id === post.author.id)
    if (match) {
      return true
    }
    return false
  }

  // ---------
  // Methods
  // ---------
  renderPosts() {
    let {
      dispatch,
      featureFlags,
      feed,
      injectedContent,
      isCommenting,
      posts,
      user,
      userList,
    } = this.props
    return feed.list.map((id, i) => {
      const normalizedIdx = i + 1
      // inject content every 3rd item
      // determine if we're the third post, then fetch the corresponding injectedContentItem
      let injectedContentItem =
        injectedContent && normalizedIdx % 3 === 0
          ? injectedContent[normalizedIdx / 3 - 1]
          : false
      const post = posts[id]
      return (
        <Fragment key={`post-${id}`}>
          <Post
            dispatch={dispatch}
            featureFlags={featureFlags}
            id={id}
            isCommenting={isCommenting}
            isModerator={this.getModerator(post)}
            post={post}
            user={user}
            userList={userList}
          />
          {injectedContentItem && (
            <div
              className='feed-item injected-content'
              key={'injected-content' + normalizedIdx}
            >
              {injectedContentItem}
            </div>
          )}
        </Fragment>
      )
    })
  }

  // ---------
  // Render
  // ---------
  render() {
    let {
      feed,
      getNextPosts,
      className,
      dispatch,
      user,
      featureFlags,
      isCommenting,
      userList,
      posts,
    } = this.props

    const stickiedPost = posts[feed.stickiedPostId]
    return (
      <div className={`feed-container ${className || ''}`}>
        {feed.stickiedPostId && (
          <Post
            id={feed.stickiedPostId}
            post={stickiedPost}
            user={user}
            dispatch={dispatch}
            featureFlags={featureFlags}
            isCommenting={isCommenting}
            userList={userList}
            sticky
          />
        )}
        {!!feed.list && !!feed.list.length && this.renderPosts()}

        {feed.pagination.next && (
          <div
            className='comment-container-show-more center-align'
            key='show-more'
          >
            <Preloader condition={!this.state.loadingMore && !feed.isFetching}>
              <a
                className='feed-showmore-link'
                onClick={getNextPosts}
                ref={this.showMoreRef}
              >
                Show More
              </a>
            </Preloader>
          </div>
        )}
      </div>
    )
  }
}

// ---------
// Redux Bindings
// ---------
// TODO: decide if we should just pass user into this container
const mapStateToProps = (state, _ownProps) => {
  const { user } = state.authReducer
  const { featureFlags, posts } = state.commonReducer
  const { isCommenting } = state.commonReducer.comments
  return {
    posts,
    user,
    featureFlags,
    isCommenting,
  }
}

export default connect(mapStateToProps)(FeedContainer)
