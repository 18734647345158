const initialState = {
  list: [],
  pagination: {},
  feed: null,
  feedIsFetching: false,
  canPage: true,
}

export default (
  state = initialState,
  { type, pagination, req, data: list }
) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
      return initialState
    case 'auth/POST_LOGOUT_SUCCESS':
      return { ...state, feed: null }
    case 'communityApp/BROWSE_RESP':
      return {
        ...state,
        canPage: !!pagination.next,
        pagination,
        list: req.reset ? [...list] : [...(state.list || []), ...list],
      }
    default:
      return state
  }
}
