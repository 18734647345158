/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { userProfilePath } from 'common/helpers'

// import { Link } from 'react-router-dom'
import './styles.scss'

export default class UserDialog extends Component {
  static propTypes = {
    featureFlags: PropTypes.object,
    friendUser: PropTypes.func,
    ignoreUser: PropTypes.func,
    inviteUser: PropTypes.func,
    myUser: PropTypes.object,
    selectedUser: PropTypes.object,
  }

  static defaultProps = {
    featureFlags: {},
    friendUser: () => null,
    ignoreUser: () => null,
    inviteUser: () => null,
    myUser: {},
    selectedUser: {},
  }

  getUserColor() {
    const { color } = this.props.selectedUser
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
  }

  render() {
    const {
      featureFlags,
      friendUser,
      inviteUser,
      ignoreUser,
      myUser,
      selectedUser,
    } = this.props
    const userLink = userProfilePath(selectedUser.nickName)
    return (
      <div className='mention-dialog' ref={div => (this.mention = div)}>
        <div
          className='color-header'
          style={{ backgroundColor: this.getUserColor() }}
        />
        <div className='mention-content'>
          <i
            className='icon-close2 close'
            onClick={this.props.resetSelectedMention}
            role='presentation'
          />
          <div className='user-info'>
            <img
              src={
                selectedUser.avatar ||
                `${process.env.PUBLIC_URL}/img/Rooster_avatar.png`
              }
              alt=''
              className='circle'
              style={{ border: `2px solid ${this.getUserColor()}` }}
            />
            <a href={userLink} rel='noopener noreferrer' target='_blank'>
              <p>{selectedUser.nickName}</p>
            </a>
          </div>
          {(myUser === null ||
            selectedUser.nickName !== myUser.attributes.username) &&
            featureFlags.directChat && (
              <div className='user-actions'>
                <div
                  className='action'
                  title='Add Friend'
                  onClick={() => friendUser(selectedUser)}
                  role='presentation'
                >
                  <i className='icon-person_add' />
                  <span>Add Friend</span>
                </div>
                {/*
            <div className="action"
              title="Message User"
              onClick={this.props.messageUser}
            >
              <i className="icon-message"></i>
              <span>Message</span>
            </div>
            */}
                <div
                  className='action'
                  onClick={() => inviteUser(selectedUser)}
                  role='presentation'
                  title='Invite User'
                >
                  <i className='icon-playlist_add' />
                  <span>Invite to Chat</span>
                </div>
                <div
                  className='action'
                  onClick={() => ignoreUser(selectedUser)}
                  role='presentation'
                  title='Ignore User'
                >
                  <i className='icon-visibility_off' />
                  <span>Ignore</span>
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }
}
