import React, { useEffect, useState } from 'react'
import reactReplace from 'string-replace-to-array'

import { isUndef } from 'common/helpers'

let EmojiMart = null
let emojiData = null
let emojiSheet = null

const getLibs = async () =>
  Promise.all([
    import('emoji-datasource'),
    import('emoji-mart'),
    import('common/assets/sheet_apple_64.png'),
  ]).then(([eD, { Emoji }, eS]) => {
    emojiData = eD.default
    emojiSheet = eS
    EmojiMart = Emoji
    return Promise.resolve()
  })

export const handleEmojis = (text, emojiSize = 12) => {
  // this regex finds us any words between two colons (:word:), and any valid skin-tones attached
  const matches = text.match(/(:[a-zA-Z0-9-_+]+:)(:skin-tone-[2-6]:)?/g)
  const emojis = []

  // Emoji code not lazy loaded yet
  if (!EmojiMart || !emojiData || !emojiSheet) {
    getLibs()
    return text
  }

  if (matches !== null) {
    for (let i = 0; i < matches.length; i += 1) {
      parseEmojiMatch(matches[i], emojiSize, emojis, i)
    }
    for (let index = 0; index < matches.length; index += 1) {
      // loop through the two arrays [matches] and [emojies] and replace them within the message string.
      text = reactReplace(text, matches[index], emojis[index])
    }
  }

  return text
}

const parseEmojiMatch = (match, emojiSize, emojis, i) => {
  // have to split the skin tone from the emoji for Filtering to verify emoji
  const array = match.split(/(::)/g)
  let skinTone = array[2]
  match = array[0]

  if (!isUndef(skinTone)) {
    // split removes the beginning colon, have to re-add it if a skin-tone was submitted
    skinTone = `:${skinTone}`
  }

  // remove colons because emojiData does not have colons in their short_name
  const filterMatch = match.replace(/:/g, '')

  // Loop through emojiData to verify if the submitted emoji is valid, also searches for alternative short_names
  let verifiedEmoji = emojiData.filter(
    obj =>
      obj.short_name === filterMatch || obj.short_names.includes(filterMatch)
  )

  // if Emoji is valid
  if (verifiedEmoji.length !== 0) {
    // Emoji has been verified, have to re-add colons to be put into Component
    verifiedEmoji = `:${verifiedEmoji[0].short_name}:`

    if (!isUndef(skinTone)) {
      // Attach the skin tone
      verifiedEmoji += skinTone
    }

    // Send back the <Emoji /> Component with our valid emoji
    return emojis.push(
      <EmojiMart
        emoji={verifiedEmoji}
        backgroundImageFn={_apple => emojiSheet}
        size={emojiSize}
        key={i}
        native
      />
    )
  }
  // Emoji was invalid
  if (!isUndef(skinTone)) {
    // if there was a skin-tone submitted then re-attach what they sent
    match += skinTone
  }
  // Send back the invalid emoji
  return emojis.push(match)
}

const MyEmoji = ({ symbol, emojiSize = 12 }) => {
  const [libsLoaded, setLibsLoaded] = useState(false)

  useEffect(() => {
    // use this neat trick to make sure we don't try and save state for
    // an unomuted component
    let isSubscribed = true
    getLibs().then(() => {
      if (isSubscribed) {
        setLibsLoaded(true)
      }
    })
    return () => (isSubscribed = false)
  }, [])

  if (!libsLoaded) {
    return <span />
  }

  return (
    <EmojiMart
      emoji={symbol}
      backgroundImageFn={_apple => emojiSheet}
      size={emojiSize}
      native
    />
  )
}

export default MyEmoji
