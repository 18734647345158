import { combineReducers } from 'redux'

import avatarReducer from './avatarReducer'
import carouselReducer from './carouselReducer'
import commentsReducer from './commentsReducer'
import featureFlags from './featureFlagReducer'
import feedReducer from './feedsReducer'
import follows from './followsReducer'
import genreReducer from './genreReducer'
import livestreamReducer from './livestreamReducer'
import myStuffReducer from './myStuffReducer'
import posts from './posts'
import ratingReducer from './ratingReducer'
import richMediaReducer from './richMediaReducer'
import scrollableReducer from './scrollableReducer'
import seasonReducer from './seasonReducer'
import uploadReducer from './uploadReducer'
import userFeedReducer from './user-feed'
import users from './usersReducer' // c-c-combo breaker
import videoReducer from './videoReducer'
import youtubeAuthReducer from './youtubeAuthReducer'

export default combineReducers({
  avatars: avatarReducer,
  carousel: carouselReducer,
  comments: commentsReducer,
  featureFlags,
  feedReducer,
  follows,
  genres: genreReducer,
  myStuff: myStuffReducer,
  livestreams: livestreamReducer,
  posts,
  rating: ratingReducer,
  richMedia: richMediaReducer,
  scrollable: scrollableReducer,
  seasons: seasonReducer,
  uploadBucket: uploadReducer,
  userFeed: userFeedReducer,
  users,
  video: videoReducer,
  youtubeAuth: youtubeAuthReducer,
})
