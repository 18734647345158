import React from 'react'
import PropTypes from 'prop-types'

import { MaterializeSelect } from 'common/components'

const ShirtSelect = ({ defaultValue, name, onChange, sizes }) => (
  <MaterializeSelect
    className='qa-shirt-select'
    name={name}
    defaultValue={defaultValue}
    required
    onChange={onChange}
  >
    {sizes.map(size => (
      <option key={size} value={size}>
        {size}
      </option>
    ))}
  </MaterializeSelect>
)

ShirtSelect.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sizes: PropTypes.array,
}

ShirtSelect.defaultProps = {
  defaultValue: '',
  sizes: [],
}

export default ShirtSelect
