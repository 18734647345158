import React from 'react'
import PropTypes from 'prop-types'

import Avatar from 'common/components/Avatar'
import { userProfilePath } from 'common/helpers'

import * as Styled from './styled'

function AvatarLink({ className, name, onClick, size, src }) {
  return (
    <AvatarLink.Base
      className={className}
      onClick={onClick}
      to={userProfilePath(name)}
    >
      <Avatar src={src} size={size} />
    </AvatarLink.Base>
  )
}

AvatarLink.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([30]),
  src: PropTypes.string,
}

AvatarLink.defaultProps = {
  onClick: () => null,
  size: 30,
}

AvatarLink.Base = Styled.AvatarLinkBase

export default AvatarLink
