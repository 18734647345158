import { takeEvery } from 'redux-saga/effects'
import { simpleGET } from 'common/helpers/saga'
import { endpoints } from 'common/config'
import { trackEvent } from 'common/metrics'

export function* getAdminPosts({ url, merge, sort }) {
  yield simpleGET({
    authenticated: true,
    url: url || `${endpoints.communityBase}/v1/admin_posts?sort=${sort}`,
    type: 'communityApp/ADMIN_POSTS',
    merge,
  })
}

export function* getAdminComments({ url, merge, sort }) {
  yield simpleGET({
    authenticated: true,
    url: url || `${endpoints.comments}/api/v1/admin_comments?sort=${sort}`,
    type: 'communityApp/ADMIN_COMMENTS',
    merge,
  })
}

export function* removePostFlags({ id }) {
  yield simpleGET({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/posts/${id}/flags`,
    type: 'common/DELETE_POST_FLAGS',
    method: 'DELETE',
    id,
  })
}

export function* removeCommentFlags({ id }) {
  yield simpleGET({
    authenticated: true,
    url: `${endpoints.comments}/api/v2/comments/${id}/dismiss_flags`,
    type: 'communityApp/DELETE_COMMENT_FLAGS',
    method: 'DELETE',
    id,
  })
}

export function* admin() {
  yield takeEvery('communityApp/ADMIN_COMMENTS', getAdminComments)
  yield takeEvery('communityApp/ADMIN_POSTS', getAdminPosts)
  yield takeEvery('communityApp/DELETE_POST_FLAGS', removePostFlags)
  yield takeEvery('communityApp/DELETE_COMMENT_FLAGS', removeCommentFlags)
}
