// Wrapper for providing routing to class components since React Router 6+ uses hooks exclusively. Copied straight from their docs:
// https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it

import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const memoizedMatchStub = useMemo(() => ({ params }), [params])
    const memoizedRouter = useMemo(() => ({ location, navigate, params }), [
      location,
      navigate,
      params,
    ])
    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // As a convenience, provide support for previous shape of "match" object (match.params)
        match={memoizedMatchStub}
        router={memoizedRouter}
      />
    )
  }

  ComponentWithRouterProp.displayName =
    Component?.displayName || 'ComponentWithRouterProp'

  return React.memo(ComponentWithRouterProp)
}

export default withRouter
