import { combineReducers } from 'redux'
import admin from './admin'
import autocomplete from './autocomplete'
import communities from './communities'
import communityUsers from './community-user-reducer'
import creation from './creation'
import feeds from './feeds'
import invites from './invites'
import memberships from './memberships'
import mutes from './mute'
import settings from './settings'
import subCommunity from '../sub-community/reducers'
import taggedPosts from './taggedPosts'
import userSearchResults from './user-search-results'

export default combineReducers({
  admin,
  autocomplete,
  communities,
  communityUsers,
  creation,
  feeds,
  invites,
  memberships,
  mutes,
  settings,
  subCommunity,
  taggedPosts,
  userSearchResults,
})
