import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const PercentBar = ({ percent }) => (
  <div className='percent-bar'>
    <div className='progress'>
      <div className='determinate' style={{ width: `${percent}%` }} />
    </div>
  </div>
)

PercentBar.propTypes = {
  percent: PropTypes.number,
}
PercentBar.defaultProps = {
  percent: null,
}

export default PercentBar
