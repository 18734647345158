import Cache, { MapStorageInterface } from 'common/lru-cache'

export const cache = new Cache({
  TTL: 8.64e7, // 1 day
  TTL_GRACE: 1000,
  store: new MapStorageInterface(),
})

if (typeof window !== 'undefined' && Array.isArray(window.bootstrapData)) {
  for (const [k, v] of window.bootstrapData) {
    cache.set(k, v)
  }
}
