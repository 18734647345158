/** *****************
 *
 *  Cookie Functions
 *
 ******************* */

import { accessTokenKey, refreshTokenKey } from 'common/config'

const ONE_MONTH_IN_HRS = 720
// Browsers let cookies live for 400 days. We use 399 here.
const COOKIE_MAX_AGE_IN_HRS = 9576

export const setCookie = (name, value, expHours) => {
  const time = new Date()
  time.setTime(time.getTime() + expHours * 60 * 60 * 1000)
  let domain = '.roosterteeth.com'
  if (window.location.hostname === 'localhost') {
    domain = 'localhost'
  }
  document.cookie = `${name}=${value};expires=${time.toUTCString()};domain=${domain};path=/`
}

export const setAccessTokenCookie = token =>
  setCookie(accessTokenKey, token, ONE_MONTH_IN_HRS)

export const setRefreshTokenCookie = token =>
  setCookie(refreshTokenKey, token, COOKIE_MAX_AGE_IN_HRS)

export const getCookie = cookieName => {
  const name = `${cookieName}=`
  const ca = document.cookie.split(';').map(elem => elem.trim())
  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i]
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const deleteCookie = name => {
  setCookie(name, '', -1)
}

/** *****************
 *
 *  Local Storage Functions
 *
 ******************* */
// test if local storage exists and stub out function to prevent quota exceeded errors
if (typeof localStorage === 'object') {
  try {
    localStorage.setItem('localStorage', 1)
    localStorage.removeItem('localStorage')
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem
    Storage.prototype.setItem = function setItem() {}
    // eslint-disable-next-line no-console
    console.warn(
      `Your web browser does not support storing settings locally. 
      In Safari, the most common cause of this is using "Private Browsing Mode". 
      Some settings may not save or some features may not work properly for you.`
    )
  }
}

export function getClientId() {
  return window.localStorage.getItem('client-id')
}

// get a draft of a post/comment for a specific id
export const getDraft = (type, id) => {
  const key = `${type}-drafts`
  const currentValue = JSON.parse(window.localStorage.getItem(key))
  for (const key in currentValue) {
    if (Object.prototype.hasOwnProperty.call(currentValue, key)) {
      if (key === id) {
        return currentValue[key]
      }
    }
  }
}

// save a draft of a post/comment for a specific id
export const saveDraft = (type, id, value) => {
  const key = `${type}-drafts`
  const currentValue = JSON.parse(window.localStorage.getItem(key))
  const newValue = Object.assign({}, currentValue, { [id]: value })
  window.localStorage.setItem(key, JSON.stringify(newValue))
}

// remove a draft of a post/comment for a specific id
export const removeDraft = (type, id) => {
  const key = `${type}-drafts`

  // Attempt to get post-drafts from localStorage, but default to {}
  let currentValue = {}
  try {
    currentValue = JSON.parse(window.localStorage.getItem(key))
    delete currentValue[id]
  } catch (_e) {
    currentValue = {}
  }

  window.localStorage.setItem(key, JSON.stringify(currentValue))
}
