import React, { useCallback, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { Spinner } from 'common/components'
import { RTOptionCarousel } from 'common/components/RTOptionCarousel'
import { a11yButtonProps } from 'common/helpers/accessibility'

import { getUserTier } from 'auth/selectors'
import { goToUserPage } from 'communityApp/actions/actions'

import { closeSearchV2 } from '../../actions'
import { TAB_ALL_RESULTS } from './helpers/constants'
import useFetchRecommendations from './helpers/useFetchRecommendations'
import useHandleSearchTermChange from './helpers/useHandleSearchTermChange'
import useInitialMount from './helpers/useInitialMount'
import useTabs from './helpers/useTabs'
import Recommendations from './Recommendations'
import TabHeader from './TabHeader'

import './styles.scss'

const initialState = {
  loading: false,
  selectedTab: TAB_ALL_RESULTS,
  searchPerformed: false,

  bonusFeatures: { data: [], total_results: 0 },
  episodes: { data: [], total_results: 0 },
  groups: { data: [], total_results: 0 },
  series: { data: [], total_results: 0 },
  users: { data: [], total_results: 0 },

  recommendedEpisodes: [],
  recommendedSeries: [],
}

const SearchV2 = ({
  closeSearchV2,
  goToUserPage,
  transitionState,
  userTier,
}) => {
  const searchRef = useRef()

  const [searchTerm, setSearchTerm] = useState('')
  const updateSearchTerm = useCallback(
    event => setSearchTerm(event.target.value),
    []
  )

  const [
    {
      bonusFeatures,
      episodes,
      groups,
      loading,
      recommendedEpisodes,
      recommendedSeries,
      searchPerformed,
      selectedTab,
      series,
      users,
    },
    setSearchResults,
  ] = useState(initialState)

  useFetchRecommendations({
    recommendedEpisodes,
    searchTerm,
    setSearchResults,
    userTier,
  })

  useInitialMount({
    closeSearchV2,
    searchRef,
    searchTerm,
  })

  useHandleSearchTermChange({
    searchTerm,
    setSearchResults,
  })

  const {
    setTabSeries,
    setTabEpisodes,
    setTabBonusFeatures,
    setTabGroups,
    setTabUsers,

    tabConfigs,
  } = useTabs({
    setSearchResults,
    bonusFeatures,
    episodes,
    groups,
    series,
    users,
  })

  // Comes from react-transition-group. May be "exited", "entering", "entered", or "exiting"
  const transitionModifierClass = `search-v2--${transitionState}`

  return (
    <div className={`search-v2 ${transitionModifierClass}`}>
      <i className='search-v2__search-icon icon-search' />
      <input
        ref={searchRef}
        autoComplete='off'
        autoCorrect='off'
        autoCapitalize='off'
        placeholder='Search'
        spellCheck='false'
        // Use "browser-default" class to clear Material styles
        className='browser-default search-v2__input'
        onChange={updateSearchTerm}
        value={searchTerm}
      />
      <i
        {...a11yButtonProps(closeSearchV2)}
        className='search-v2__close-icon icon-close2'
      />
      <div className='search-v2__tab-headers'>
        <RTOptionCarousel>
          {/* Render tab headers using config */}
          {searchPerformed
            ? tabConfigs.map(({ tab, text, selectTab, totalItems }) => (
                <TabHeader
                  key={tab}
                  searchPerformed={searchPerformed}
                  selectedTab={selectedTab}
                  selectTab={selectTab}
                  tab={tab}
                  text={text}
                  totalItems={totalItems}
                />
              ))
            : null}
        </RTOptionCarousel>
      </div>
      {/* Render corresponding tab components using config */}
      <div className='search-v2__tab'>
        {loading ? (
          <div className='search-v2__spinner-container'>
            <Spinner />
          </div>
        ) : searchPerformed ? (
          tabConfigs.map(({ tab, TabComponent }) =>
            selectedTab === tab ? (
              <TabComponent
                key={tab}
                bonusFeatures={bonusFeatures}
                closeSearchV2={closeSearchV2}
                episodes={episodes}
                groups={groups}
                series={series}
                users={users}
                setTabSeries={setTabSeries}
                setTabEpisodes={setTabEpisodes}
                setTabBonusFeatures={setTabBonusFeatures}
                setTabGroups={setTabGroups}
                setTabUsers={setTabUsers}
                searchTerm={searchTerm}
                goToUserPage={goToUserPage}
              />
            ) : null
          )
        ) : (
          <Recommendations
            closeSearchV2={closeSearchV2}
            recommendedEpisodes={recommendedEpisodes}
            recommendedSeries={recommendedSeries}
          />
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state, _ownProps) {
  return {
    featureFlags: state.commonReducer.featureFlags,
    location: state.router.location,
    userResults: state.communityReducer.userSearchResults,
    userTier: getUserTier(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeSearchV2: method => dispatch(closeSearchV2(method)),
    goToUserPage: username => dispatch(goToUserPage(username)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchV2)
