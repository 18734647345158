// NOTE: This accordion stuff only applies to the Mobile nav right now, but could somewhat easily be abstracted into a component used elsewhere.

import { useCallback } from 'react'
import Honeybadger from 'honeybadger-js'

import { trackClick } from 'common/metrics'

const OPEN_ACCORDION_ATTRIBUTE = 'data-open'
const TRANSITION_DURATION = 200

export const closeAllAccordions = () => {
  for (const element of document.querySelectorAll(
    `[${OPEN_ACCORDION_ATTRIBUTE}]`
  )) {
    element.removeAttribute(OPEN_ACCORDION_ATTRIBUTE)
  }

  for (const accordion of document.querySelectorAll('[data-nav-accordion]')) {
    const contents = accordion.querySelector(
      '.mobile-overlay-nav__accordion-contents'
    )

    contents.style.height = '0px'
    contents.style.opacity = 0
    contents.style.pointerEvents = 'none'
    contents.style.position = 'absolute'
  }
}

export const useHandleAccordionClick = () => {
  const handleAccordionHeaderClick = useCallback(
    (e, label, dataEventOptions) => {
      trackClick(label, dataEventOptions)
      try {
        const accordion = e.target.closest('[data-nav-accordion]')
        const contents = accordion.querySelector(
          '.mobile-overlay-nav__accordion-contents'
        )
        const isOpen = accordion.getAttribute(OPEN_ACCORDION_ATTRIBUTE)

        // If we're closing the accordion, just close all accordions. If we're opening the accordion, close all accordions and then open this one, and also set the attribute on the parent so that we can "dim" the other siblings.
        closeAllAccordions()

        // The following code is used to animate the opening and closing of accordions. It is impossible for CSS to animated between a height of 0 and a height of `auto`, so we have to use some Javascript.
        // When opening an accordion: First, we reset the height of the contents invisible contents so that the height is automatically determined by the element. We make the element absolute so that it doesn't take up any space. Then, we get the `desiredHeight` of the invisible contents. Once we have this, we set the position to `relative` and the height to `0px`. Then, in a timeout(0) (in order to make sure the change happens in a separate paint), we animate the height to the desired height and set the opacity to 1. This causes all siblings of the accordion to be pushed down gradually rather than in one paint.
        // When closing an accordion, we set the height to `0px` to animate out the element. Then reset its position to `absolute`.
        if (!isOpen) {
          document
            .querySelector('[data-nav-accordions-parent]')
            ?.setAttribute(OPEN_ACCORDION_ATTRIBUTE, true)
          contents.style.height = null
          contents.style.position = 'absolute'
          const desiredHeight = contents.getBoundingClientRect().height
          accordion.setAttribute(OPEN_ACCORDION_ATTRIBUTE, true)
          contents.style.position = 'relative'
          contents.style.height = '0px'
          contents.style.pointerEvents = 'all'
          setTimeout(() => {
            contents.style.opacity = 1
            contents.style.height = `${desiredHeight}px`
          }, 0)
        } else {
          contents.style.height = '0px'
          contents.style.opacity = 0
          contents.style.pointerEvents = 'none'
          setTimeout(() => {
            contents.style.position = 'absolute'
          }, TRANSITION_DURATION)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
        Honeybadger.notify(
          'An error occured while trying to open a mobile nav dropdown.',
          { context: { original_error: error } }
        )
      }
    },
    []
  )

  return handleAccordionHeaderClick
}
