import React from 'react'

import RandomVideoBox from 'common/containers/RandomVideoBox'
import { trackEvent } from 'common/metrics'

const RandomVideo = ({ user }) => (
  <RandomVideoBox
    onVideoSelected={randomVideo => {
      trackEvent('Random Episode Viewed', {
        item_id: randomVideo.id,
        user_id: user ? user.id : null,
      })
    }}
  />
)

export default RandomVideo
