import React from 'react'
import PropTypes from 'prop-types'

import ExternalLink from 'common/components/ExternalLink'
import getStoreHref from 'common/helpers/getStoreHref'

const SideNavStore = ({ isAuthenticated, onClick }) => {
  const storeHref = getStoreHref(isAuthenticated)

  return (
    <li className='side-nav-menu__item qa-side-nav-store'>
      <ExternalLink
        className='side-nav-menu__link qa-side-nav-store-link waves-effect waves-brand'
        href={storeHref}
        onClick={onClick}
      >
        Store
      </ExternalLink>
    </li>
  )
}

SideNavStore.propTypes = {
  isAuthenticated: PropTypes.bool,
  onClick: PropTypes.func,
}

SideNavStore.defaultProps = {
  isAuthenticated: false,
  onClick: () => null,
}

export default SideNavStore
