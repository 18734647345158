import React, { Component } from 'react'
import { Modal } from 'materialize-css'
import PropTypes from 'prop-types'

import { PrimaryBtn, TertiaryBtn } from 'common/components'

import './styles.scss'

export class ReasonDialog extends Component {
  static propTypes = {
    onCloseEnd: PropTypes.func,
    onSubmit: PropTypes.func,
    type: PropTypes.oneOf(['delete', 'flag']).isRequired,
  }

  static defaultProps = {
    onCloseEnd: () => null,
    onSubmit: () => null,
  }

  state = {
    reason: null,
  }

  modalInstance = null

  modalRef = React.createRef()

  componentDidMount() {
    const { onCloseEnd } = this.props

    this.modalInstance = Modal.init(this.modalRef.current, {
      dismissible: true,
      inDuration: 300,
      onCloseEnd,
    })

    this.modalInstance.open()
  }

  componentWillUnmount() {
    if (this.modalInstance) {
      this.modalInstance.destroy()
    }
  }

  handleSubmit = e => {
    const { onSubmit } = this.props
    const { reason } = this.state

    e.preventDefault()

    onSubmit(reason)
  }

  isDeleting = () => this.props.type === 'delete'

  isFlagging = () => this.props.type === 'flag'

  render() {
    const reasons = [['abuse', 'Abuse'], ['spam', 'Spam']]

    if (this.isDeleting()) {
      reasons.push(['other', 'Other'])
    }

    return (
      <div className='reason-dialog modal' ref={this.modalRef}>
        <form onSubmit={this.handleSubmit}>
          <div className='modal-content'>
            <h4 className='reason-dialog__heading'>
              {this.isFlagging() ? 'Flag' : 'Delete'} Comment
            </h4>

            {reasons.map(([type, label]) => (
              <p key={`${this.isFlagging() ? 'flag' : 'delete'}-${type}`}>
                <label>
                  <input
                    name='reason'
                    onClick={() => this.setState({ reason: type })}
                    type='radio'
                  />
                  <span>{label}</span>
                </label>
              </p>
            ))}

            <div className='modal-footer'>
              <TertiaryBtn
                className='reason-dialog__cancel modal-action modal-close'
                type='button'
              >
                Cancel
              </TertiaryBtn>
              <PrimaryBtn
                className='modal-action modal-close'
                disabled={!this.state.reason}
                type='submit'
              >
                {this.isFlagging() ? 'Report' : 'Delete'}
              </PrimaryBtn>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default ReasonDialog
