import Honeybadger from 'honeybadger-js'

import { config } from 'common/config'

const getEnterpriseReCAPTCHAToken = usage =>
  new Promise(resolve => {
    const siteKey = config.auth.googleSiteKey
    const enterpriseReCAPTCHA = window.grecaptcha?.enterprise
    if (!enterpriseReCAPTCHA) {
      const errorMessage = `Enterprise reCAPTCHA is not installed correctly.\nSite key: ${siteKey}\nUsage: ${usage}`

      Honeybadger.notify(errorMessage, { context: { usage } })
      // eslint-disable-next-line no-console
      console.error(errorMessage)
      resolve(false)
    } else {
      enterpriseReCAPTCHA.ready(async () => {
        const token = await enterpriseReCAPTCHA.execute(siteKey)
        resolve(token)
      })
    }
  })

export default getEnterpriseReCAPTCHAToken
