export const CLEAR_CANCEL_OFFERS = 'subscriptions/CLEAR_CANCEL_OFFERS'
export const CLEAR_GIFT_CARD = 'subscriptions/CLEAR_GIFT_CARD'
export const CLEAR_PREVIEW = 'subscriptions/CLEAR_PREVIEW'
export const CLEAR_SELECTED_PLAN = 'subscriptions/CLEAR_SELECTED_PLAN'
export const DELETE_SUBSCRIPTION_FAILURE =
  'subscriptions/DELETE_SUBSCRIPTION_FAILURE'
export const DELETE_SUBSCRIPTION_REQUEST =
  'subscriptions/DELETE_SUBSCRIPTION_REQUEST'
export const DELETE_SUBSCRIPTION_SUCCESS =
  'subscriptions/DELETE_SUBSCRIPTION_SUCCESS'
export const GET_ACCOUNT_FAILURE = 'subscriptions/GET_ACCOUNT_FAILURE'
export const GET_ACCOUNT_REQUEST = 'subscriptions/GET_ACCOUNT_REQUEST'
export const GET_ACCOUNT_SUCCESS = 'subscriptions/GET_ACCOUNT_SUCCESS'
export const GET_AVAILABLE_PLANS_FAILURE =
  'subscriptions/GET_AVAILABLE_PLANS_FAILURE'
export const GET_AVAILABLE_PLANS_REQUEST =
  'subscriptions/GET_AVAILABLE_PLANS_REQUEST'
export const GET_AVAILABLE_PLANS_SUCCESS =
  'subscriptions/GET_AVAILABLE_PLANS_SUCCESS'
export const GET_CANCEL_OFFERS_FAILURE =
  'subscriptions/GET_CANCEL_OFFERS_FAILURE'
export const GET_CANCEL_OFFERS_REQUEST =
  'subscriptions/GET_CANCEL_OFFERS_REQUEST'
export const GET_CANCEL_OFFERS_SUCCESS =
  'subscriptions/GET_CANCEL_OFFERS_SUCCESS'
export const GET_PLANS_FAILURE = 'subscriptions/GET_PLANS_FAILURE'
export const GET_PLANS_REQUEST = 'subscriptions/GET_PLANS_REQUEST'
export const GET_PLANS_SUCCESS = 'subscriptions/GET_PLANS_SUCCESS'
export const GET_REDEEMED_GIFT_CARDS_FAILURE =
  'subscriptions/GET_REDEEMED_GIFT_CARDS_FAILURE'
export const GET_REDEEMED_GIFT_CARDS_REQUEST =
  'subscriptions/GET_REDEEMED_GIFT_CARDS_REQUEST'
export const GET_REDEEMED_GIFT_CARDS_SUCCESS =
  'subscriptions/GET_REDEEMED_GIFT_CARDS_SUCCESS'
export const GET_SUBSCRIPTIONS_FAILURE =
  'subscriptions/GET_SUBSCRIPTIONS_FAILURE'
export const GET_SUBSCRIPTIONS_REQUEST =
  'subscriptions/GET_SUBSCRIPTIONS_REQUEST'
export const GET_SUBSCRIPTIONS_SUCCESS =
  'subscriptions/GET_SUBSCRIPTIONS_SUCCESS'
export const GET_TRANSACTIONS_FAILURE = 'subscriptions/GET_TRANSACTIONS_FAILURE'
export const GET_TRANSACTIONS_REQUEST = 'subscriptions/GET_TRANSACTIONS_REQUEST'
export const GET_TRANSACTIONS_SUCCESS = 'subscriptions/GET_TRANSACTIONS_SUCCESS'
export const POST_ACCEPT_OFFER_FAILURE =
  'subscriptions/POST_ACCEPT_OFFER_FAILURE'
export const POST_ACCEPT_OFFER_REQUEST =
  'subscriptions/POST_ACCEPT_OFFER_REQUEST'
export const POST_ACCEPT_OFFER_SUCCESS =
  'subscriptions/POST_ACCEPT_OFFER_SUCCESS'
export const POST_CANCELLATION_SURVEY_FAILURE =
  'subscriptions/POST_CANCELLATION_SURVEY_FAILURE'
export const POST_CANCELLATION_SURVEY_REQUEST =
  'subscriptions/POST_CANCELLATION_SURVEY_REQUEST'
export const POST_CANCELLATION_SURVEY_SUCCESS =
  'subscriptions/POST_CANCELLATION_SURVEY_SUCCESS'
export const POST_PAYMENT_TOKEN_FAILURE =
  'subscriptions/POST_PAYMENT_TOKEN_FAILURE'
export const POST_PAYMENT_TOKEN_REQUEST =
  'subscriptions/POST_PAYMENT_TOKEN_REQUEST'
export const POST_PAYMENT_TOKEN_SUCCESS =
  'subscriptions/POST_PAYMENT_TOKEN_SUCCESS'
export const POST_PREVIEW_FAILURE = 'subscriptions/POST_PREVIEW_FAILURE'
export const POST_PREVIEW_REQUEST = 'subscriptions/POST_PREVIEW_REQUEST'
export const POST_PREVIEW_SUCCESS = 'subscriptions/POST_PREVIEW_SUCCESS'
export const POST_SUBSCRIPTION_FAILURE =
  'subscriptions/POST_SUBSCRIPTION_FAILURE'
export const POST_SUBSCRIPTION_REQUEST =
  'subscriptions/POST_SUBSCRIPTION_REQUEST'
export const POST_SUBSCRIPTION_SUCCESS =
  'subscriptions/POST_SUBSCRIPTION_SUCCESS'
export const PUT_CANCEL_PENDING_FAILURE =
  'subscriptions/PUT_CANCEL_PENDING_FAILURE'
export const PUT_CANCEL_PENDING_REQUEST =
  'subscriptions/PUT_CANCEL_PENDING_REQUEST'
export const PUT_CANCEL_PENDING_SUCCESS =
  'subscriptions/PUT_CANCEL_PENDING_SUCCESS'
export const PUT_PAUSE_SUBSCRIPTION_FAILURE =
  'subscriptions/PUT_PAUSE_SUBSCRIPTION_FAILURE'
export const PUT_PAUSE_SUBSCRIPTION_REQUEST =
  'subscriptions/PUT_PAUSE_SUBSCRIPTION_REQUEST'
export const PUT_PAUSE_SUBSCRIPTION_SUCCESS =
  'subscriptions/PUT_PAUSE_SUBSCRIPTION_SUCCESS'
export const PUT_UNPAUSE_SUBSCRIPTION_FAILURE =
  'subscriptions/PUT_UNPAUSE_SUBSCRIPTION_FAILURE'
export const PUT_UNPAUSE_SUBSCRIPTION_REQUEST =
  'subscriptions/PUT_UNPAUSE_SUBSCRIPTION_REQUEST'
export const PUT_UNPAUSE_SUBSCRIPTION_SUCCESS =
  'subscriptions/PUT_UNPAUSE_SUBSCRIPTION_SUCCESS'
export const PUT_REACTIVATE_FAILURE = 'subscriptions/PUT_REACTIVATE_FAILURE'
export const PUT_REACTIVATE_REQUEST = 'subscriptions/PUT_REACTIVATE_REQUEST'
export const PUT_REACTIVATE_SUCCESS = 'subscriptions/PUT_REACTIVATE_SUCCESS'
export const PUT_REDEEM_GIFT_CARD_FAILURE =
  'subscriptions/PUT_REDEEM_GIFT_CARD_FAILURE'
export const PUT_REDEEM_GIFT_CARD_REQUEST =
  'subscriptions/PUT_REDEEM_GIFT_CARD_REQUEST'
export const PUT_REDEEM_GIFT_CARD_SUCCESS =
  'subscriptions/PUT_REDEEM_GIFT_CARD_SUCCESS'
export const PUT_SUBSCRIPTION_FAILURE = 'subscriptions/PUT_SUBSCRIPTION_FAILURE'
export const PUT_SUBSCRIPTION_REQUEST = 'subscriptions/PUT_SUBSCRIPTION_REQUEST'
export const PUT_SUBSCRIPTION_SUCCESS = 'subscriptions/PUT_SUBSCRIPTION_SUCCESS'
export const SELECT_PLAN_CHANGE = 'subscriptions/SELECT_PLAN_CHANGE'
export const RESET_POST_PREVIEW_FAILURE =
  'subscriptions/RESET_POST_PREVIEW_FAILURE'
