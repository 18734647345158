import React from 'react'

import { EpisodeCard } from 'common/components'
import { useToggle } from 'common/hooks'
import useGenericContentBlockItemTracking from 'common/hooks/useGenericContentBlockItemTracking'
import useRefScrolledIntoView from 'common/hooks/useRefScrolledIntoView'

import ContentBlockBase, { ContentBlockHeaderLink } from '../ContentBlockBase'
import useGradient from './useGradient'

function Placeholder() {
  return (
    <EpisodeCard
      item={{
        attributes: { display_title: 'Placeholder title' },
        links: { canonical_self: '/' },
      }}
    />
  )
}

const ContentBlockFeaturedEpisode = ({
  body,
  brandColor,
  contentBlock,
  headerUrl,
  item,
  listId,
  location,
  title1,
  title2,
}) => {
  const [inView, setInView] = useToggle()
  const contentBlockRef = useRefScrolledIntoView(setInView, {
    topOffset: 500,
    bottomOffset: 500,
    mode: 'any-visible',
  })

  const handleClick = useGenericContentBlockItemTracking({
    contentBlock,
    item,
    location,
  })

  const gradientStyle = useGradient(brandColor)

  return (
    <ContentBlockBase ref={contentBlockRef}>
      <div className='content-block__featured-episode-container'>
        <div
          className={`content-block__featured-episode-bg-gradient ${
            brandColor
              ? ''
              : 'content-block__featured-episode-bg-gradient--hue-drift'
          }`}
          style={gradientStyle}
        />
        <div className='content-block__featured-episode-body'>
          <ContentBlockHeaderLink
            contentBlock={contentBlock}
            header1={title1}
            header2={title2}
            location={location}
            url={headerUrl}
          />
          <div className='content-block__featured-episode-body-detail'>
            {body}
          </div>
        </div>
        <div className='content-block__featured-episode-preview'>
          {inView ? (
            <EpisodeCard
              item={item}
              listId={listId}
              onThumbnailClick={handleClick}
              onTitleClick={handleClick}
              width='1000'
            />
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockFeaturedEpisode
