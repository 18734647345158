import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { swapCommunityAPIForCDN } from 'common/helpers/paths'

import './styles.scss'

export class PostGallery extends Component {
  el = React.createRef()
  static propTypes = {
    images: PropTypes.array.isRequired,
    currentImage: PropTypes.number,
  }

  static defaultProps = {
    currentImage: 0,
  }

  componentDidMount() {
    this.el.current.querySelector('input:checked').focus()
  }

  render() {
    const { images, currentImage } = this.props
    return (
      <div className='post-gallery' ref={this.el}>
        {images.map((img, idx) => {
          let prev = (images.length + idx - 1) % images.length
          let next = (idx + 1) % images.length
          return (
            <div key={'gallery-img-' + idx} className='gallery-slide'>
              <input
                type='radio'
                id={'img-input-' + idx}
                defaultChecked={idx === currentImage}
                name='slide'
              />
              <div className='slide'>
                {images.length > 1 && (
                  <label htmlFor={'img-input-' + prev} className='prev'>
                    <i className='icon-chevron-left' />
                  </label>
                )}
                <img
                  key={'img-' + idx}
                  src={swapCommunityAPIForCDN(
                    img,
                    'h_1200,fl_lossy,c_limit,q_auto:best'
                  )}
                  alt='post'
                  className='post-image'
                />
                <div className='gallery-slide__counter link-light'>
                  {idx + 1} / {images.length}
                </div>
                {images.length > 1 && (
                  <label htmlFor={'img-input-' + next} className='next'>
                    <i className='icon-chevron-right' />
                  </label>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
