import rtApi from '@roosterteethproductions/svod-api'

import { config } from 'common/config'
import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

export const ADD_ROOM = 'chatApp/ADD_ROOM'
export const CHANGE_ROOM = 'chatApp/CHANGE_ROOM'
export const REMOVE_ROOM = 'chatApp/REMOVE_ROOM'
export const ADD_MESSAGE = 'chatApp/ADD_MESSAGE'
export const RECEIVE_MESSAGE = 'chatApp/RECEIVE_MESSAGE'
export const LOAD_ROOMS = 'chatApp/LOAD_ROOMS'
export const LOAD_ROOMS_SUCCESS = 'chatApp/LOAD_ROOMS_SUCCESS'
export const LOAD_ROOMS_FAIL = 'chatApp/LOAD_ROOMS_FAIL'
export const LOAD_MESSAGES = 'chatApp/LOAD_MESSAGES'
export const LOAD_MESSAGES_SUCCESS = 'chatApp/LOAD_MESSAGES_SUCCESS'
export const LOAD_MESSAGES_FAIL = 'chatApp/LOAD_MESSAGES_FAIL'

// V2 Stuff

export const MUTED_USERS_REQUEST = 'chatApp/MUTED_USERS_REQUEST'
export const MUTED_USERS_FAILURE = 'chatApp/MUTED_USERS_FAILURE'
export const MUTED_USERS_SUCCESS = 'chatApp/MUTED_USERS_SUCCESS'
export const MUTED_USER_ADD_REQUEST = 'chatApp/MUTED_USER_ADD_REQUEST'
export const MUTED_USER_ADD_FAILURE = 'chatApp/MUTED_USER_ADD_FAILURE'
export const MUTED_USER_ADD_SUCCESS = 'chatApp/MUTED_USER_ADD_SUCCESS'
export const MUTED_USER_REMOVE_REQUEST = 'chatApp/MUTED_USER_REMOVE_REQUEST'
export const MUTED_USER_REMOVE_FAILURE = 'chatApp/MUTED_USER_REMOVE_FAILURE'
export const MUTED_USER_REMOVE_SUCCESS = 'chatApp/MUTED_USER_REMOVE_SUCCESS'

export const BLOCKED_USERS_REQUEST = 'chatApp/BLOCKED_USERS_REQUEST'
export const BLOCKED_USERS_FAILURE = 'chatApp/BLOCKED_USERS_FAILURE'
export const BLOCKED_USERS_SUCCESS = 'chatApp/BLOCKED_USERS_SUCCESS'
export const BLOCKED_USER_ADD_REQUEST = 'chatApp/BLOCKED_USER_ADD_REQUEST'
export const BLOCKED_USER_ADD_FAILURE = 'chatApp/BLOCKED_USER_ADD_FAILURE'
export const BLOCKED_USER_ADD_SUCCESS = 'chatApp/BLOCKED_USER_ADD_SUCCESS'
export const BLOCKED_USER_REMOVE_REQUEST = 'chatApp/BLOCKED_USER_REMOVE_REQUEST'
export const BLOCKED_USER_REMOVE_FAILURE = 'chatApp/BLOCKED_USER_REMOVE_FAILURE'
export const BLOCKED_USER_REMOVE_SUCCESS = 'chatApp/BLOCKED_USER_REMOVE_SUCCESS'

// MUTED

// get muted users
const requestMuted = () => ({
  type: MUTED_USERS_REQUEST,
})

const receiveMuted = ({ userIds }) => ({
  type: MUTED_USERS_SUCCESS,
  userIds,
})

const fetchMutedFail = message => ({
  type: MUTED_USERS_FAILURE,
  message,
})

export const fetchMutedUsers = () => dispatch => {
  dispatch(requestMuted())
  rtApi.chat
    .fetchMutedUsers()
    .then(json => dispatch(receiveMuted(json)))
    .catch(ex => {
      dispatch(fetchMutedFail(ex))
      return Promise.reject(new Error())
    })
}

// add muted user
const addToMutedRequest = () => ({
  type: MUTED_USER_ADD_REQUEST,
})

export const addToMutedSuccess = userId => ({
  type: MUTED_USER_ADD_SUCCESS,
  userId,
})

const addToMutedFail = () => ({
  type: MUTED_USER_ADD_FAILURE,
})

export const addMutedUser = muteId => dispatch => {
  dispatch(addToMutedRequest())
  rtApi.chat
    .muteUser({ muteId })
    .then(() => dispatch(addToMutedSuccess(muteId)))
    .catch(ex => {
      dispatch(addToMutedFail(ex))
      return Promise.reject(new Error())
    })
}

// remove muted user
const removeFromMutedRequest = () => ({
  type: MUTED_USER_REMOVE_REQUEST,
})

export const removeFromMutedSuccess = userId => ({
  type: MUTED_USER_REMOVE_SUCCESS,
  userId,
})

const removeFromMutedFail = () => ({
  type: MUTED_USER_REMOVE_FAILURE,
})

export const removeMutedUser = unmuteId => dispatch => {
  dispatch(removeFromMutedRequest())
  return rtApi.chat
    .unmuteUser({ unmuteId })
    .then(() => dispatch(removeFromMutedSuccess(unmuteId)))
    .catch(ex => {
      dispatch(removeFromMutedFail(ex))
      return Promise.reject(new Error())
    })
}

// BLOCKED

// get blocked users
const requestBlocked = () => ({
  type: BLOCKED_USERS_REQUEST,
})

const receiveBlocked = ({ userIds }) => ({
  type: BLOCKED_USERS_SUCCESS,
  userIds,
})

const fetchBlockedFail = message => ({
  type: BLOCKED_USERS_FAILURE,
  message,
})

export const fetchBlockedUsers = () => dispatch => {
  dispatch(requestBlocked())
  rtApi.chat
    .fetchBlockedUsers()
    .then(json => dispatch(receiveBlocked(json)))
    .catch(ex => {
      dispatch(fetchBlockedFail(ex))
      return Promise.reject(new Error())
    })
}

// add blocked user
const addToBlockedRequest = () => ({
  type: BLOCKED_USER_ADD_REQUEST,
})

export const addToBlockedSuccess = userId => ({
  type: BLOCKED_USER_ADD_SUCCESS,
  userId,
})

const addToBlockedFail = () => ({
  type: BLOCKED_USER_ADD_FAILURE,
})

export const addBlockedUser = blockId => dispatch => {
  dispatch(addToBlockedRequest())
  rtApi.chat
    .blockUser({ blockId })
    .then(() => dispatch(addToBlockedSuccess(blockId)))
    .catch(ex => {
      dispatch(addToBlockedFail(ex))
      return Promise.reject(new Error())
    })
}

// remove blocked user
const removeFromBlockedRequest = () => ({
  type: BLOCKED_USER_REMOVE_REQUEST,
})

export const removeFromBlockedSuccess = userId => ({
  type: BLOCKED_USER_REMOVE_SUCCESS,
  userId,
})

const removeFromBlockedFail = () => ({
  type: BLOCKED_USER_REMOVE_FAILURE,
})

export const removeBlockedUser = unblockId => dispatch => {
  dispatch(removeFromBlockedRequest())
  return rtApi.chat
    .unblockUser({ unblockId })
    .then(() => dispatch(removeFromBlockedSuccess(unblockId)))
    .catch(ex => {
      dispatch(removeFromBlockedFail(ex))
      return Promise.reject(new Error())
    })
}

// Get the last X messages from the DB to show in chat
export const fetchMessages = (roomId, numMessages) =>
  rtApi.chat
    .fetchMessageHistory({
      roomId,
      numMessages,
      extraHeaders: { 'x-api-token': config.chat.apiKey },
    })
    // .then(resp => resp.json())
    .then(resp => resp)
    .catch(error => {
      throw error
    })

export const fetchRoom = (roomId, title) =>
  rtApi.chat
    .fetchRoom({
      roomId,
      title,
      extraHeaders: { 'x-api-token': config.chat.apiKey },
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .catch(_err => {
      // console.log('error: ', err)
    })

export const fetchUsernamesFromIds = ids =>
  rtApi.users
    .fetchBulk({ ids })
    .then(json => json.data)
    .catch(ex => Promise.reject(ex))

// V1 Stuff

const requestRooms = () => ({
  type: LOAD_ROOMS,
})

const receiveRooms = json => ({
  type: LOAD_ROOMS_SUCCESS,
  json,
})

// Fetch the current rooms for this user
export const fetchRooms = user => dispatch => {
  dispatch(requestRooms())
  rtApi.chat
    .fetchRooms(user)
    .then(json => dispatch(receiveRooms(json)))
    .catch(error => {
      throw error
    })
}

// const requestMessages = () => ({
//   type: LOAD_MESSAGES,
// })

// const receiveMessages = (json, room) => {
//   const date = moment().format('lll')
//   return {
//     type: LOAD_MESSAGES_SUCCESS,
//     json,
//     room,
//     date,
//   }
// }

// // Get the last X messages from the DB to show in chat
// export const fetchMessages = room => dispatch => {
//   dispatch(requestMessages())
//   rtApi.chat
//     .fetchMessages(room)
//     .then(json => dispatch(receiveMessages(json, room)))
//     .catch(error => {
//       throw error
//     })
// }

export const fetchUserUuid = username => (dispatch, _getState) => {
  // making sure we don't make case-sensative requests that break cache
  const name = username.toLowerCase()
  return rtApi.users.fetchUsernames({ name }).catch(ex => {
    if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
      dispatch(logoutSuccess())
    }
    throw ex
  })
}

const addMessage = message => ({
  type: ADD_MESSAGE,
  message,
})

export const receiveMessage = message => ({
  type: RECEIVE_MESSAGE,
  message,
})

export const changeRoom = room => ({
  type: CHANGE_ROOM,
  room,
})

const addRoom = room => ({
  type: ADD_ROOM,
  room,
})

const removeRoom = room => ({
  type: REMOVE_ROOM,
  room,
})

// create or join room, add it to users list
export const createRoom = room => dispatch => {
  dispatch(addRoom(room))
  rtApi.chat.createRoom(room).catch(error => {
    throw error
  })
}

// remove this user from a room list
export const leaveRoom = room => dispatch => {
  dispatch(removeRoom(room))
  rtApi.chats.leaveRoom(room).catch(error => {
    throw error
  })
}

// This const is to save the message to the DB
export const createMessage = message => dispatch => {
  dispatch(addMessage(message))
  rtApi.chat.newMessage(message).catch(error => {
    throw error
  })
}
