import React, { Component, createContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import moment from 'moment'
import { PostGallery } from 'common/components'
import { endpoints } from 'common/config'
import {
  copyToClipboard,
  getUrls,
  isTimeInFuture,
  notice,
  userProfilePath,
} from 'common/helpers'
import {
  getPostDisplayType,
  getPostType,
  getSubjectPostId,
} from 'common/helpers/post'
import { showModal, hideModal } from 'modalApp/actions'
import PostBody from './post-body'
import PostHeader from './post-header'
import PostFooter from './post-footer'

import './styles.scss'
import withRouter from 'common/helpers/withRouter'

const PostDefaultProps = {
  enablePostDetails: true,
  post: {},
  showGallery: false,
  isRepost: false,
  userList: [],
  showFollowBtn: false,
  focusCommentBox: false,
  liked: false,
}
export const PostContext = createContext(PostDefaultProps)

/**
 * Posts are similar but different to comments. They have a truncated state
 * that has specific business rules around its display as well. They also have
 * different layouts depending on their type
 */
class PostBase extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isReply: PropTypes.bool,
    // current user from user
    user: PropTypes.object,
    enablePostDetails: PropTypes.bool,
    showGallery: PropTypes.bool,
    userList: PropTypes.array,
    showReplyForm: PropTypes.bool,
    showFollowBtn: PropTypes.bool,
    focusCommentBox: PropTypes.bool,

    isRepost: PropTypes.bool,
    post: PropTypes.object,
    posts: PropTypes.object.isRequired,

    subjectPost: PropTypes.object.isRequired,
    enclosedPost: PropTypes.object,
  }

  static defaultProps = PostDefaultProps

  constructor(props) {
    super(props)

    const { post, enablePostDetails } = this.props
    this.state = {
      shareDropDownIsOpen: false,
      showReplyInput: false,
      showSpoiler: !(enablePostDetails && post && post.spoiler),
      showMuted: post.author.muted,
      showReplyForm: false,
    }
  }

  // --------
  // Lifecycle
  // --------

  componentDidMount() {
    if (this.props.showReplyForm) {
      this.toggleReplyForm()
    }
  }

  componentDidUpdate(prevProps) {
    // force closing all reply forms if we just finished adding a comment
    if (this.props.isCommenting && !prevProps.isCommenting) {
      this.toggleReplyForm('e', false)
    }
    if (prevProps.post.spoiler !== this.props.post.spoiler) {
      this.setState({
        showSpoiler: !(
          this.props.enablePostDetails &&
          this.props.post &&
          this.props.post.spoiler
        ),
      })
    }
  }

  // -------
  // Methods
  // -------
  /**
   * Convenience method for fetching comment count
   * @returns Number
   **/
  commentCount = () => {
    const metadata = this.props.subjectPost.metadata
    if (!metadata || !metadata.comments || !metadata.comments.count) {
      return 0
    }

    return metadata.comments.count
  }

  shareCount = () => {
    const metadata = this.props.subjectPost.metadata
    if (!metadata || !metadata.shares || !metadata.shares.count) {
      return 0
    }

    return metadata.shares.count
  }

  likeCount = () => {
    const metadata = this.props.subjectPost.metadata
    if (!metadata || !metadata.likes || !metadata.likes.count) {
      return 0
    }

    return metadata.likes.count
  }

  getImages = () => {
    let images = []
    const { post } = this.props
    if (post && post.media && post.media.images) {
      images = post.media.images
    }
    return images
  }

  /**
   * Generates `to` route object for this comment
   * @return {Object}
   */
  getPermalink = (full = false) => {
    const subjectPost = this.props.subjectPost

    if (getPostType({ post: subjectPost }) === 'merch') {
      const { body: message } = subjectPost
      const links = getUrls(message)
      if (links[0]) {
        return links[0]
      }
    }
    let path = '/g'
    if (subjectPost) {
      path = path + `/post/${subjectPost?.id}`
    }
    if (full) {
      return window.location.protocol + '//' + window.location.host + path
    }
    return path
  }

  onClickUser = (e, user) => {
    e.stopPropagation()
    this.props.history.push(userProfilePath(user))
  }

  onShareButtonClicked = e => {
    this.setState(prevState => ({ shareDropDownIsOpen: true }))
  }

  onInternalShare = e => {
    const { user, post } = this.props

    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    if (!user) {
      this.props.dispatch(showModal('USER_LOGIN'))
      return
    }
    if (user && !user.attributes?.email_verified) {
      this.props.dispatch(showModal('VERIFY_EMAIL'))
      return
    }
    // check if user is banned
    if (isTimeInFuture(user?.attributes?.banned_until)) {
      this.showBannedNotice()
      return
    }
    let topicId =
      post.owner.type.toLowerCase() === 'community' ? post.owner.data.id : ''
    this.props.dispatch(
      showModal('CREATE_POST', {
        topicId,
        post: {
          repostId: getSubjectPostId(this.props.post),
        },
      })
    )
    this.setState(prevState => ({ shareDropDownIsOpen: false }))
  }

  showBannedNotice = () => {
    // turning the banned_until user readable
    const bannedUntil = moment(
      this.props.user?.attributes?.banned_until
    ).format('MM-DD-YYYY')

    notice({
      icon: 'icon-close2',
      iconColor: 'red',
      header: 'Error',
      body: `you are banned until: ${bannedUntil}`,
    })
  }

  onCopyShare = e => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    // check if user is banned
    if (isTimeInFuture(this.props.user?.attributes?.banned_until)) {
      this.showBannedNotice()
      return
    }

    copyToClipboard(this.getPermalink(true), this.el)
    this.setState(prevState => ({ shareDropDownIsOpen: false }))
    // open notifier
    notice({
      iconColor: 'blue',
      header: '',
      body: 'link copied to clipboard',
    })
  }

  /**
   * Genereic method for stopping event bubbling, since an entire post is
   * clickable
   **/
  preventFullPost = (e, closeModal = true) => {
    e.stopPropagation()
    // a route level listener that closes modals on route change may be more appropriate
    if (closeModal) {
      this.props.dispatch(hideModal())
    }
  }

  handlePostClick = e => {
    const { enablePostDetails, isRepost } = this.props
    if (!enablePostDetails || e.target.tagName === 'A') {
      return
    }
    if (isRepost && e) {
      e.stopPropagation()
    }

    this.showFullPost()
  }

  showFullPost = (options = {}) => {
    const { dispatch, post } = this.props
    // TODO: rethink if we need to use native link behavior for merch
    const postType = getPostType({ post })
    if (postType === 'merch') {
      const { body: message } = post
      const links = getUrls(message)
      if (links[0]) {
        window.location = links[0]
        return
      }
    }
    const hasImages =
      post && post.media && post.media.images && post.media.images.length > 0
    const className = hasImages ? 'modal-post-details-images' : ''
    const modalOptions = Object.assign(options, { id: post.id, className })
    dispatch(showModal('POST_DETAIL', modalOptions))
  }

  toggleMute = e => {
    this.preventFullPost(e)
    this.setState({ showMuted: !this.state.showMuted })
  }

  toggleSpoiler = e => {
    this.preventFullPost(e)
    this.setState({ showSpoiler: !this.state.showSpoiler })
  }

  toggleLiked = (e, liked) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    const { user, dispatch, post } = this.props
    const postId = getSubjectPostId(post)
    if (!user) {
      dispatch(showModal('USER_LOGIN'))
      return
    }

    let type = 'communityApp/g/post/like'
    if (liked) {
      type = 'communityApp/g/post/unlike'
    }

    dispatch({
      type,
      postId,
    })
  }

  /**
   * Handles reply clicks; should open modal with comment open on preview;
   * should toggle comment if detail view
   */
  handleReplyClick = e => {
    const { enablePostDetails } = this.props
    if (enablePostDetails) {
      e.stopPropagation()
      return this.showFullPost({ showReplyForm: true, focusCommentBox: true })
    }
    this.toggleReplyForm()
  }

  toggleReplyForm = (e, showReplyForm = !this.state.showReplyForm) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    this.setState({ showReplyForm })
  }

  // ---------
  // Render
  // ---------
  render() {
    const { children, isModerator, isRepost, post, showGallery } = this.props

    const { showMuted } = this.state

    const contextProps = {
      ...this.props,
      ...this.state,
      commentCount: this.commentCount,
      getImages: this.getImages,
      getPermalink: this.getPermalink,
      handleReplyClick: this.handleReplyClick,
      likeCount: this.likeCount,
      onClickUser: this.onClickUser,
      onCopyShare: this.onCopyShare,
      onInternalShare: this.onInternalShare,
      onShareButtonClicked: this.onShareButtonClicked,
      preventFullPost: this.preventFullPost,
      shareCount: this.shareCount,
      toggleLiked: this.toggleLiked,
      toggleMute: this.toggleMute,
      toggleReplyForm: this.toggleReplyForm,
      toggleSpoiler: this.toggleSpoiler,
    }

    let images = this.getImages()
    const postType = getPostDisplayType({ post })
    return (
      <div
        className={classnames(`post post-type-${postType}`, {
          repost: isRepost,
        })}
        onClick={this.handlePostClick}
        ref={ref => {
          this.el = ref
        }}
      >
        <PostContext.Provider value={contextProps}>
          <PostHeader isModerator={isModerator} isRepost={isRepost} />
          {showGallery && images.length > 0 && !showMuted && (
            <PostGallery images={images} />
          )}
          {!showMuted && (
            <React.Fragment>
              <PostBody />
              {!isRepost && <PostFooter />}
            </React.Fragment>
          )}
          {children}
        </PostContext.Provider>
      </div>
    )
  }
}

const Post = withRouter(PostBase)

const mapStateToProps = (state, ownProps) => {
  const { userBadges } = state.rootReducer
  const { posts } = state.commonReducer
  const post = ownProps.post ? ownProps.post : posts[ownProps.id]
  const subjectPost = posts[getSubjectPostId(post)]
  const enclosedPost = post?.repostId ? posts[post.repostId] : post.repost

  return {
    enclosedPost,
    post,
    posts,
    subjectPost,
    userBadges,
  }
}

export default connect(mapStateToProps)(Post)
