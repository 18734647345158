import rtApi from '@roosterteethproductions/svod-api'
import { push } from 'redux-first-history'
import { call, put, select } from 'redux-saga/effects'

import { functionalPushOrRedirect } from 'common/helpers'
import { getAnalyticsMemberTier, trackEvent } from 'common/metrics'

import {
  putUserFailure,
  putUserRequest,
  putUserSuccess,
  shopifySession,
} from 'auth/actions'
import { showMyHub } from 'myHubApp/operations'
import { setUsernameError, softResetSignupFlowForms } from 'signupApp/actions'
import { VIEW_CUSTOMIZE_ACCOUNT } from 'signupApp/constants'
import { trackSignupFlowEvent } from 'signupApp/helpers'
import { getCampaign, getParams } from 'signupApp/selectors'

const delay = ms => new Promise(res => setTimeout(res, ms))

// FIXME: functionalPushOrRedirect calls push and shopifySession, expecting them to be bound to dispatch. But there's no intuitive way to provide dispatch to callbacks inside sagas, we have this big ugly mess.
const pushOrRedirectThunk = args => dispatch =>
  functionalPushOrRedirect({
    ...args,
    push: url => dispatch(push(url)),
    shopifySession: storeReturnTo => dispatch(shopifySession(storeReturnTo)),
  })

const updateUsernameAndPreserveResponse = ({ id, username }) =>
  new Promise(resolve =>
    rtApi.users
      .update({ id, user: { username }, errorCallback: resolve })
      .then(json => resolve(json))
      .catch(ex => resolve(ex))
  )

function* submitCustomizeFormSaga() {
  const { username } = yield select(s => s.signupReducer)
  const { id } = yield select(s => s.authReducer.user)

  const { redirect_url: redirectUrl } = yield select(getParams)

  // authReducer
  yield put(putUserRequest())

  const result = yield call(updateUsernameAndPreserveResponse, { id, username })

  if (result.type === 'user') {
    yield call(trackSignupCompleteEvents)

    // authReducer
    yield put(putUserSuccess(result))

    yield put(pushOrRedirectThunk({ href: '/', redirectUrl }))

    // If the user has finished signup flow, make sure to clear the signup flow forms from Redux (includes email and password, etc).
    yield put(softResetSignupFlowForms())

    // Once the user has been redirected after completing signup, wait a few seconds and then open the cool new onboarding hub.
    yield call(delay, 1000)
    yield put(showMyHub({ showLoadingScreen: true }))
    trackEvent('My Hub Opened', { source: 'automatic' })
  } else {
    let error = "That didn't work. Try another username."
    if (result.message) error = result.message

    // authReducer
    yield put(putUserFailure({ message: error }))

    yield put(setUsernameError(error))
  }
}

function* trackSignupCompleteEvents() {
  const user = yield select(s => s.authReducer.user)

  const {
    chat_livestream_title: chatLivestreamTitle,
    episode_uuid: episodeUuid,
  } = yield select(getParams)

  const campaign = yield select(getCampaign)

  trackSignupFlowEvent(VIEW_CUSTOMIZE_ACCOUNT, {
    campaign,
    option_selected: null,
    state: 'exited',
    // Should always be "free" or "first"
    user_tier: getAnalyticsMemberTier(user),
  })
  if (chatLivestreamTitle) {
    trackEvent('Chat Signup Complete', {
      platform: 'web',
      stream_title: chatLivestreamTitle,
      option_selected: 'email',
      user_uuid: user?.id,
    })
  }
  if (episodeUuid) {
    trackEvent('Gate Signup Complete', {
      platform: 'web',
      episode_uuid: episodeUuid,
      option_selected: 'email',
      user_uuid: user?.id,
    })
  }
}

export default submitCustomizeFormSaga
