import React from 'react'

import CanonicalLinks from './CanonicalLinks'
import DisallowCrawlers from './DisallowCrawlers'

const SEOTags = () => (
  <React.Fragment>
    <DisallowCrawlers />
    <CanonicalLinks />
  </React.Fragment>
)

export default SEOTags
