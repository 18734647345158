import { Component } from 'react'

import withRouter from 'common/helpers/withRouter'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children || null
  }
}

export default withRouter(ScrollToTop)
