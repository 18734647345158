/** **********************
 *
 *  Conversion Helper Functions
 *
 ************************ */

export const padNumber = (number, digits) =>
  Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number

// get float number with precision decimal places
export const roundFix = (number, precision) => {
  const multi = 10 ** precision
  return Math.round((number * multi).toFixed(precision + 1)) / multi
}
