import React, { Component, Suspense } from 'react'
import Favico from 'favico.js'
import {
  addNotification,
  getNotifications,
  getUnseenNotificationsCount,
  putAllNotificationsSeen,
} from 'notifications/actions'
import { Socket } from 'phoenix-socket'
import { connect } from 'react-redux'

import { endpoints } from 'common/config'
import { getAccessToken } from 'common/helpers'
import * as metrics from 'common/metrics'

import ToastWrapper from '../ToastWrapper'

const NotificationBar = React.lazy(() =>
  import('../../components/NotificationBar')
)

class Notifications extends Component {
  dropdownRef = React.createRef()

  constructor(props) {
    super(props)

    this.favico = new Favico({ animation: 'none', bgColor: '#4c67dc' })
    this.socket = null
    this.toastTimeout = 5000
    this.userChannel = null
  }

  componentDidMount() {
    const { unseen } = this.props

    if (unseen !== 0) {
      this.favico.badge(unseen)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user } = this.props
    const { unseen } = this.props
    if (user && !nextProps.user) {
      // user logged out, remove favico
      this.favico.reset()
    }
    if (unseen !== 0 && nextProps.unseen === 0) {
      this.favico.reset()
    }
    if (nextProps.unseen !== 0) {
      this.favico.badge(nextProps.unseen)
    }
    if (!this.socket && nextProps.isAuthenticated) {
      // if we're authenticated, connect to our socket for real time notifications
      this.connectToNotifications()
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    const { user, dispatch } = this.props
    if (!prevProps.user && user) {
      // user logged in, get nofifications
      dispatch(getUnseenNotificationsCount())
      dispatch(getNotifications())
      // connect to users notifications channel
      this.connectToNotifications()
    }
  }

  connectToNotifications = () => {
    const { user, dispatch } = this.props
    if (!this.socket) {
      this.socket = new Socket(`${endpoints.socket}/api/notification_stream`, {
        params: { token: getAccessToken() },
      })
      this.socket.connect()
    }
    if (user) {
      this.userChannel = this.socket.channel(`user:${user.id}`, {})
      this.userChannel
        .join()
        .receive('ok', _resp => {
          // console.log('Joined successfully', resp)
        })
        .receive('error', _resp => {
          // console.log('Unable to join', resp)
        })
      this.userChannel.on('new_notification', notice => {
        ToastWrapper(notice)
        dispatch(addNotification(notice))
      })
    }
  }

  inboxOpened = e => {
    const { dispatch, user } = this.props

    e.preventDefault()
    metrics.trackClick('header_notification_bell')
    window.localStorage.setItem('notif-seen', true)

    if (user) {
      setTimeout(() => {
        dispatch(putAllNotificationsSeen()).then(() => {
          dispatch(getUnseenNotificationsCount())
        })
      }, 3000)
    }
  }

  render() {
    const {
      canPage,
      dispatch,
      isAuthenticated,
      isFetching,
      notifications,
      unseen,
      user,
    } = this.props
    // const hasNotifications = notifications.length !== 0

    return (
      <span className='notifications-bar'>
        <a
          aria-label='Notifications menu'
          className='notifications-bar__btn dropdown-trigger'
          data-target='notifications-dropdown'
          href='#!'
          onClick={e => this.inboxOpened(e)}
          ref={this.dropdownRef}
        >
          <i className='icon-notifications notifications-bar__icon' />
          {unseen !== 0 && (
            <small className='notification-bar__badge'>{unseen}</small>
          )}
        </a>
        <Suspense fallback={<div className='notification-bar__placeholder' />}>
          <NotificationBar
            dropdownRef={this.dropdownRef}
            canPage={canPage}
            dispatch={dispatch}
            isAuthenticated={isAuthenticated}
            isFetching={isFetching}
            notifications={notifications}
            unseen={unseen}
            user={user}
          />
        </Suspense>
      </span>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, user } = state.authReducer
  const {
    notifications,
    unseen,
    isFetching,
    canPage,
  } = state.notificationsReducer
  return {
    isAuthenticated,
    notifications,
    unseen,
    user,
    isFetching,
    canPage,
  }
}

export default connect(mapStateToProps)(Notifications)
