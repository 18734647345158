import rtApi from '@roosterteethproductions/svod-api'
import { call, put } from 'redux-saga/effects'

import { selectRandomAvatar } from 'common/actions'

import { updateUsername } from 'signupApp/actions'

let usernames = []

function* updateUserNameToRandomSaga() {
  // If usernames is an empty list, replenish the list from the API.
  if (usernames.length === 0) {
    yield call(() =>
      rtApi.users
        .generateUsernames()
        .then(({ usernames: usernamesFromApi }) => {
          usernames = usernamesFromApi
        })
    )
  }

  yield put(updateUsername(usernames.pop()))
}

function* updateUserNameAndAvatarToRandomSaga() {
  // Run thunk to select a random avatar (updates authReducer)
  yield put(selectRandomAvatar())
  // Run saga to get random username (just updates store)
  yield call(updateUserNameToRandomSaga)
}

export default updateUserNameAndAvatarToRandomSaga
