/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

/**
 * Attempts to render the first oembedabble link from the input text
 */
export default class RichMedia extends Component {
  static propTypes = {
    url: PropTypes.string,
  }

  static defaultProps = {
    url: null,
  }

  // --------
  // Lifecycle
  // --------
  componentDidMount() {
    this.renderCards()
  }

  componentWillUnmount() {
    const card = document.querySelector('.iframely-card')
    if (card) card.remove()
  }

  // --------
  // Methods
  // --------
  renderCards() {
    if (!this.link || !window.iframely) {
      return
    }
    window.iframely.load(this.link)
  }

  // --------
  // Render
  // --------
  render() {
    const { url } = this.props
    return (
      <div className='rich-media' ref={ref => (this.node = ref)}>
        {url && (
          <a href={url} rel='nofollow' ref={link => (this.link = link)}>
            {url}
          </a>
        )}
      </div>
    )
  }
}
