import React from 'react'
import { Transition } from 'react-transition-group'

import useIncrementInterval from 'common/hooks/useIncrementInterval'

const LoadingMessages = ({ loaded, messages, messageDurationMs }) => {
  const currentMessageIndex = useIncrementInterval({
    count: messages.length,
    interval: messageDurationMs,
  })

  return (
    <Transition appear in={!loaded} timeout={700} unmountOnExit>
      {state => (
        <div
          className={`my-hub__loading-messages my-hub__loading-messages--${state}`}
        >
          <div className='my-hub__loading-messages-inner'>
            {messages.map((message, messageIndex) => (
              <LoadingMessage
                isCurrentMessage={currentMessageIndex === messageIndex}
                key={message}
                message={message}
              />
            ))}
          </div>
        </div>
      )}
    </Transition>
  )
}

const LOADING_MESSAGE_ANIMATION_DURATION = 300

const LoadingMessage = ({ message, isCurrentMessage }) => (
  <Transition
    in={isCurrentMessage}
    timeout={LOADING_MESSAGE_ANIMATION_DURATION}
  >
    {state => (
      <div
        className={`my-hub__loading-message my-hub__loading-message--${state}`}
        key={message}
      >
        {message}
      </div>
    )}
  </Transition>
)

export default LoadingMessages
