/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'

function useInfinitePager({
  active = true,
  fetchMore,
  maxPages,
  initPage = 1,
  scrollElement = window,
}) {
  const [page, setPage] = useState(initPage)
  const currentPage = useRef(page)
  useEffect(
    () => {
      if (currentPage.current !== page && page <= maxPages && active) {
        currentPage.current = page
        fetchMore({ page })
      }
    },
    [active, fetchMore, maxPages, page]
  )

  const [scroll, setScroll] = useState(
    scrollElement.scrollTop || scrollElement.scrollY
  )
  useEffect(() => {
    if (active) {
      scrollElement.addEventListener('scroll', handleScroll)
    }
    return () =>
      active ? scrollElement.removeEventListener('scroll', handleScroll) : null
  })
  const handleScroll = debounce(() => {
    const atBottom =
      scrollElement === window
        ? window.scrollY > document.body.clientHeight - window.innerHeight - 150
        : scrollElement.scrollTop >=
          scrollElement.scrollHeight - scrollElement.clientHeight
    if (atBottom && page + 1 <= maxPages) {
      setPage(page + 1)
    }
  }, 200)

  return setPage
}

useInfinitePager.propTypes = {
  active: PropTypes.bool,
  fetchMore: PropTypes.func.isRequired,
  maxPages: PropTypes.number.isRequired,
  initPage: PropTypes.number,
}

useInfinitePager.defaultProps = {
  active: false,
  initPage: 1,
}

export default useInfinitePager
