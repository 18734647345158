// eslint-disable-next-line simple-import-sort/imports
import { combineReducers } from 'redux'
import { routerReducer } from 'store/reduxFirstHistory'

import urlReducer from 'common/reducers/urlReducer'

import authReducer from './auth/reducers'
import subscriptions from './subscriptions/reducers'

import chatReducer from './chatApp/reducers'
import commonReducer from './common/reducers'
import communityReducer from './communityApp/reducers'
import communityV2Reducer from './communityApp/reducerV2'
import episodeReducer from './episodeApp/reducers'
import homeReducer from './homeApp/reducers'
import marketingReducer from './marketingApp/reducers'
import modalReducer from './modalApp/reducers'
import myHubReducer from './myHubApp/reducer'
import notificationsReducer from './notifications/reducers'
import profileReducer from './profileApp/reducers'
import rootReducer from './rootApp/reducers'
import scheduleReducer from './scheduleApp/reducers'
import showsReducer from './showsApp/reducers'
import signupReducer from './signupApp/reducers'

const reducers = combineReducers({
  authReducer,
  chatReducer,
  commonReducer,
  communityReducer,
  communityV2Reducer,
  episodeReducer,
  homeReducer,
  marketingReducer,
  modalReducer,
  myHubReducer,
  notificationsReducer,
  profileReducer,
  rootReducer,
  router: routerReducer,
  scheduleReducer,
  showsReducer,
  signupReducer,
  subscriptions,
  urlReducer,
})

export default reducers
