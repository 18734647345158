import React from 'react'

const ReCAPTCHALegalBlurb = () => (
  <>
    This site is protected by reCAPTCHA and the{' '}
    <a
      href='https://policies.google.com/privacy?hl=en'
      rel='noopener noreferrer'
      target='_blank'
    >
      Google Privacy Policy
    </a>{' '}
    and{' '}
    <a
      href='https://policies.google.com/terms?hl=en'
      rel='noopener noreferrer'
      target='_blank'
    >
      Terms of Service
    </a>{' '}
    apply.
  </>
)

export default ReCAPTCHALegalBlurb
