/* eslint-disable camelcase */
import { takeEvery, put, select } from 'redux-saga/effects'
import { simpleGET, simpleCall } from 'common/helpers/saga'
import { endpoints } from 'common/config'
import { trackEvent } from 'common/metrics'

export function* getAbout({ slug }) {
  yield put.resolve({ type: 'communityApp/subCommunity', slug })
  yield put({
    type: 'communityApp/subCommunity/social',
    id: yield select(state => state.communityReducer.subCommunity.id),
  })
}

export function* getSocial({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/communities/${id}/social_media`,
    type: 'communityApp/subCommunity/social',
  })
}

export function* getAchievements({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/communities/${id}/achievements`,
    type: 'communityApp/c/achievements',
  })
}

export function* getCommunity({ slug }) {
  yield simpleGET({
    // authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/slug/${slug}`,
    type: 'communityApp/subCommunity',
  })
}

export function* updateCommunity({ id, community }) {
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}`,
    type: 'communityApp/subCommunity/updateCommunity',
    method: 'PATCH',
    body: { community },
  })
}

export function* updateCommunityAvatar({ id, logo }) {
  if (!id) {
    // todo change data flow
    console.error(
      'this shouldnt be called on login when the community is temp cleared'
    )
    return
  }
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}`,
    type: 'communityApp/subCommunity/updateCommunityAvatar',
    method: 'PATCH',
    body: { logo },
  })
}

export function* updateCommunityBG({ id, header }) {
  if (!id) {
    // todo change data flow
    console.error(
      'this shouldnt be called on login when the community is temp cleared'
    )
    return
  }
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}`,
    type: 'communityApp/subCommunity/updateCommunityBG',
    method: 'PATCH',
    body: { header },
  })
}

export function* updateCommunitySocial({ id, social_medium }) {
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}/social_media`,
    type: 'communityApp/subCommunity/updateCommunitySocial',
    method: 'PATCH',
    body: { social_medium },
  })
}

export function* pinPost({ communityId, postToPinId }) {
  yield updateCommunity({
    id: communityId,
    community: {
      stickied_post_id: postToPinId,
    },
  })

  // synthesizing a response event so we can add a separate reducer for it
  yield put({
    type: 'communityApp/subCommunity/pin_RESP',
    communityId,
    postToPinId,
  })
}

export function* unpinPost({ communityId, postToUnpinId }) {
  const currentPinnedPostId = yield select(state => {
    return state.communityReducer.subCommunity.community.stickied_post_id
  })

  if (currentPinnedPostId === postToUnpinId) {
    yield updateCommunity({
      id: communityId,
      community: {
        stickied_post_id: null,
      },
    })
  }
}

export function* getCommunityChat({ communityId }) {
  yield simpleGET({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${communityId}/chat`,
    type: 'communityApp/subCommunity/chat',
  })
}

export function* createPost({ communityId, pinPost, post, userId }) {
  const postResp = yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${communityId}/posts`,
    type: 'communityApp/c/createPost',
    method: 'POST',
    body: post,
  })

  if (pinPost && postResp && postResp.data && postResp.data.id) {
    yield put({
      type: 'communityApp/subCommunity/pin',
      communityId,
      postToPinId: postResp.data.id,
    })
  }

  if (postResp && postResp.data && postResp.data.id) {
    let eventName = 'post_created'
    let postId = postResp.data.id
    if (post.repost_post_id) {
      eventName = 'post_reposted'
      postId = post.repost_post_id
    }
    trackEvent('SocialEvent', {
      label: eventName,
      platform: 'web',
      target_id: postId,
      target_type: 'post',
      user_uuid: userId,
    })
  }
}

/**
 * Get a publicly viewable feed for a user
 * @param {String} id - the id of the user to fetch the feed for
 */
export function* getFeed({ id, nextPage }) {
  const url = new URL(
    `${endpoints.communityBase}/v1/communities/${id}/home_feed`
  )
  if (nextPage) {
    url.searchParams.set('cursor', nextPage)
  }

  yield simpleCall({
    // authenticated: true,
    url,
    type: 'communityApp/c/getFeed',
    method: 'GET',
    id,
  })
}

/**
 * Get a subcription feed for a user
 * @param {String} id - id for users subscription feed to fetch
 */
export function* getMyFeed({ id, nextPage, reset = false }) {
  const url = new URL(`${endpoints.communityBase}/v1/users/${id}/feed`)
  if (nextPage) {
    url.searchParams.set('cursor', nextPage)
  }
  yield simpleCall({
    authenticated: true,
    url,
    type: 'communityApp/c/getMyFeed',
    method: 'GET',
    reset,
    id,
  })
}

// no body needed
export function* join({ id, userId }) {
  let res = yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}/join`,
    method: 'POST',
    type: 'communityApp/subCommunity/join',
  })

  if (res.data) {
    trackGroupEvent({
      communityUuid: id,
      eventName: 'community_joined',
      userId,
    })
  }
}

export function* leave({ id, userId }) {
  let res = yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/communities/${id}/leave`,
    method: 'POST',
    type: 'communityApp/subCommunity/leave',
    id,
  })

  if (res.data) {
    trackGroupEvent({
      communityUuid: id,
      eventName: 'community_left',
      userId,
    })
  }
}

function trackGroupEvent({ communityUuid, eventName, userId }) {
  trackEvent('SocialEvent', {
    label: eventName,
    platform: 'web',
    target_id: communityUuid,
    target_type: 'community',
    user_id: userId,
  })
}

/**
 * Fetch members that belong to the target community
 * @param {String} id - uuid of the community
 * @param {Boolean} filter - filter by members or not
 * TODO: make the filter more robust when api supports it
 **/
export function* getMembers({
  id,
  filter = true,
  reset = false,
  searchParams = { limit: 18 },
}) {
  let url = new URL(`${endpoints.communityBase}/v1/communities/${id}/members`)

  for (let key in searchParams) {
    url.searchParams.append(key, searchParams[key])
  }
  url.searchParams.append('filters[member]', filter)

  if (reset) {
    url.searchParams.delete('cursor')
  }

  yield simpleCall({
    // authenticated: true,
    url,
    method: 'GET',
    type: 'communityApp/c/getMembers',
    filter,
    reset,
  })
}

/**
 * Invite user to community
 * @param {String} id - uuid of the community
 **/
export function* inviteUser({ communityId, userId }) {
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/invitations`,
    method: 'POST',
    type: 'community/c/invite',
    body: {
      invitation: {
        community_id: communityId,
        user_id: userId,
        moderator: false,
      },
    },
  })
}

/**
 * accept an invite for a community
 * @param {String} id - uuid of the invite
 **/
export function* acceptInvite({ id }) {
  yield simpleCall({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/invitations/${id}/accept`,
    method: 'POST',
    type: 'communityApp/c/acceptInvite',
  })
}

/**
 * Bans by user
 * @param {String} userId - uuid of the user to ban
 * @param {Date} bannedEndDate - end date of ban
 * TODO: pass banned_end_date to api when we implement temporary banning
 * @note a "kick" is just ban with no end date
 */
export function* kickUser({ userId, communityUuid, bannedEndDate }) {
  yield simpleGET({
    authenticated: true,
    url: endpoints.communityBase + `/v1/communities/${communityUuid}/ban`,
    method: 'PATCH',
    body: {
      user_id: userId,
    },
    type: 'community/c/ban',
  })

  yield getMembers({ id: communityUuid, reset: true })
}

export function* unKickUser({ userId, communityUuid }) {
  yield simpleGET({
    authenticated: true,
    url: endpoints.communityBase + `/v1/communities/${communityUuid}/unban`,
    body: {
      user_id: userId,
    },
    method: 'PATCH',
    type: 'community/c/unban',
  })
  yield getMembers({ id: communityUuid, reset: true })
}

export function* subCommunity() {
  yield takeEvery('communityApp/subCommunity', getCommunity)
  yield takeEvery('communityApp/subCommunity/updateCommunity', updateCommunity)
  yield takeEvery('communityApp/subCommunity/chat', getCommunityChat)
  yield takeEvery('communityApp/subCommunity/pin', pinPost)
  yield takeEvery('communityApp/subCommunity/unpin', unpinPost)
  yield takeEvery(
    'communityApp/subCommunity/updateCommunitySocial',
    updateCommunitySocial
  )
  yield takeEvery('communityApp/subCommunity/join', join)
  yield takeEvery('communityApp/subCommunity/leave', leave)
  yield takeEvery('communityApp/subCommunity/social', getSocial)
  yield takeEvery('communityApp/subCommunity/about', getAbout)
  yield takeEvery('communityApp/c/acceptInvite', acceptInvite)
  yield takeEvery('communityApp/c/achievements', getAchievements)
  yield takeEvery('communityApp/c/createPost', createPost)
  yield takeEvery('communityApp/c/getFeed', getFeed)
  yield takeEvery('communityApp/c/getMyFeed', getMyFeed)
  yield takeEvery('communityApp/c/getMembers', getMembers)
  yield takeEvery('community/c/invite', inviteUser)
  yield takeEvery('community/c/ban', kickUser)
  yield takeEvery('community/c/unban', unKickUser)
  yield takeEvery(
    'communityApp/subCommunity/updateCommunityAvatar',
    updateCommunityAvatar
  )
  yield takeEvery(
    'communityApp/subCommunity/updateCommunityBG',
    updateCommunityBG
  )
}
