// forked from
// https://github.com/rsms/js-lru/blob/master/lru.js
const NEWER = Symbol('newer')
const OLDER = Symbol('older')

// does not strictly adhere to storage interface in that it does not coerce vals
// to strings
// basically this is a memory store
export class MapStorageInterface {
  constructor(...args) {
    this.store = new Map(...args)
  }

  getItem(key) {
    if (!this.store.has(key)) {
      return null
    }
    return this.store.get(key)
  }

  setItem(key, val) {
    return this.store.set(key, val)
  }

  removeItem(key) {
    this.store.delete(key)
  }
}

// 15m in ms
const DEFAULT_TTL = 300000
const DEFAULT_GRACE = 150
export class CacheEntry {
  constructor(k, v, TTL, grace = 0) {
    this.awaitingValue = false
    this.key = k
    this.value = v
    this[NEWER] = undefined
    this[OLDER] = undefined
    this.TTL = TTL
    this.grace = grace
    this.refresh()
  }

  refresh() {
    this.expires = Date.now() + this.TTL
    this.graceExpires = this.expires + this.grace
    this.backgroundRefreshInProgress = false
  }

  isExpired() {
    return this.expires < Date.now()
  }

  isGraceExpired() {
    return this.graceExpires < Date.now()
  }
}

export default class Cache {
  constructor({
    sizeLimit = 1000,
    TTL = DEFAULT_TTL,
    TTL_GRACE = DEFAULT_GRACE,
    logger = console,
    store = new MapStorageInterface(),
    serveStaleUntilSuccess = false,
  } = {}) {
    this.size = 0
    this.sizeLimit = sizeLimit
    this.store = store
    this.oldest = undefined
    this.newest = undefined
    this.TTL = TTL
    this.TTL_GRACE = TTL_GRACE
    this.logger = logger
    this.serveStaleUntilSuccess = serveStaleUntilSuccess
  }

  // Get the cached value or do the action passed as callback if the cache entry
  // does not exist
  // returns a promise that resolves to the entry
  // will pool requests to cb while cb is waiting to finish
  getDo(
    key,
    cb,
    {
      TTL = this.TTL,
      serveStaleUntilSuccess = this.serveStaleUntilSuccess,
    } = {}
  ) {
    let entry = this._get(key)

    if (entry && (serveStaleUntilSuccess || !entry.isGraceExpired())) {
      // we are currently fetching the resource return the promise for it
      if (entry.awaitingValue) {
        this.logger.info('pooling simultaneous request for same resource', {
          key,
        })
        // here the value is the promise rather than the resolved value
        return entry.value
      }
      // entry is expired, grace period has not passed or stale entries are
      // allowed to be returned, there are no background refreshes currently
      // in progress
      // Here we fire off the action in the background but return the cached entry
      if (entry.isExpired() && !entry.backgroundRefreshInProgress) {
        this.logger.info('refreshing cache', { key })
        entry.backgroundRefreshInProgress = true
        // purposefully not returned to perform action in background
        cb()
          .then(val => {
            this.logger.info(`cache background refresh successful`, { key })
            entry.backgroundRefreshInProgress = false
            this.set(key, val, TTL)
            return val
          })
          .catch(e => {
            entry.backgroundRefreshInProgress = false
            this.logger.warn('refresh attempt of cache failed', { key, e })
            // background refreshes should be ignored when they fail
          })
      }
      return Promise.resolve(entry.value)
    }
    // cache entry does not exist, populate it
    // create a cache entry with the promise as the value
    this.set(
      key,
      cb()
        .then(val => {
          const entry = this._get(key)
          if (!entry) {
            this.logger.error(
              'key was not found in the cache, should have been a promise',
              { key }
            )
            if (!val) {
              throw new Error('failed cache entry')
            }
          } else {
            entry.awaitingValue = false
          }
          this.set(key, val, TTL)
          return val
        })
        .catch(e => {
          const entry = this._get(key)
          this.logger.error('failed cache callback', { key, e, cb })
          if (entry) {
            this.store.removeItem(entry.key)
            this.removeEntryFromLRU(entry)
          }
          return Promise.reject(e)
        })
    )
    entry = this._get(key)

    entry.awaitingValue = true

    return entry.value
  }

  removeEntryFromLRU(entry) {
    if (entry[NEWER]) {
      if (entry === this.oldest) {
        this.oldest = entry[NEWER]
      }
      entry[NEWER][OLDER] = entry[OLDER]
    }

    if (entry[OLDER]) {
      if (entry === this.newest) {
        this.newest = entry[OLDER]
      }
      entry[OLDER][NEWER] = entry[NEWER]
    }
  }

  // pushes entry to top of most recently used list (or back of least recent)
  markAsUsed(entry) {
    if (entry === this.newest) {
      return
    }

    this.removeEntryFromLRU(entry)

    entry[NEWER] = undefined
    entry[OLDER] = this.newest
    if (this.newest) {
      this.newest[NEWER] = entry
    }

    this.newest = entry
  }

  set(key, value, TTL = this.TTL) {
    if (this.store.getItem(key) !== null) {
      const entry = this.store.getItem(key)
      entry.value = value
      entry.TTL = TTL
      entry.refresh()
      this.markAsUsed(entry)
      return this
    }
    this.size += 1

    const entry = new CacheEntry(key, value, TTL, this.TTL_GRACE)

    // make this entry the newest
    if (this.newest) {
      this.newest[NEWER] = entry
      entry[OLDER] = this.newest
    } else {
      this.oldest = entry
    }

    this.newest = entry

    if (this.size > this.sizeLimit) {
      this.logger.info('lru limit reached evicting')
      const origOldest = this.oldest
      if (this.oldest[NEWER]) {
        this.oldest = this.oldest[NEWER]
        this.oldest[OLDER] = undefined
      } else {
        this.oldest = undefined
        this.newest = undefined
      }
      origOldest[OLDER] = undefined
      origOldest[NEWER] = undefined
      this.store.removeItem(origOldest.key)
      this.size -= 1
    }

    this.store.setItem(key, entry)
  }

  peek(key) {
    const entry = this.store.getItem(key)
    if (!entry) {
      return undefined
    }
    return entry
  }

  _get(key) {
    const entry = this.store.getItem(key)
    if (!entry) {
      return undefined
    }

    this.markAsUsed(entry)
    return entry
  }

  get(key) {
    const entry = this._get(key)
    if (entry && !entry.isExpired()) {
      return entry.value
    }
  }
}
