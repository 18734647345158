import { simpleGET } from 'common/helpers/saga'
import { put, takeEvery } from 'redux-saga/effects'
import { endpoints } from 'common/config'

// @note currently this endpoint defaults to 10 itmes per request, forcing
// to 1000, in the future we should figure out a better way of fetching
// context-specific membership data
export function* getMemberships({ cache = { TTL: 10000 } }) {
  yield simpleGET({
    authenticated: true,
    url: endpoints.communityBase + '/v1/memberships/mine?limit=1000',
    type: 'communityApp/MEMBERSHIPS',
    cache,
  })
}

export function* getMembershipsForUser({
  authenticated = true,
  nextPage,
  userId,
}) {
  const url = new URL(
    endpoints.communityBase + `/v1/memberships/user/${userId}`
  )
  if (nextPage) {
    url.searchParams.set('cursor', nextPage)
  }
  yield simpleGET({
    authenticated,
    url,
    type: 'communityApp/MEMBERSHIPS_USER',
    id: userId,
    nextPage,
  })
}

export function* getInvites() {
  yield simpleGET({
    authenticated: true,
    url: endpoints.communityBase + '/v1/invitations/pending',
    type: 'communityApp/INVITES',
  })
}

export function* memberships() {
  yield takeEvery(
    [
      'communityApp/c/acceptInvite_RESP',
      'communityApp/CREATE_RESP', // <---- this can be done in the reducer
      'communityApp/MEMBERSHIPS',
    ],
    getMemberships
  )
  yield takeEvery('communityApp/INVITES', getInvites)
  yield takeEvery('communityApp/MEMBERSHIPS_USER', getMembershipsForUser)
}
