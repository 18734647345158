import React from 'react'

const CloseButton = ({ onClick }) => (
  <div
    aria-label='Close My Hub'
    className='my-hub__close'
    onClick={e => {
      e.stopPropagation()
      onClick()
    }}
    onKeyPress={onClick}
    role='button'
    tabIndex={0}
  />
)

export default CloseButton
