const initialState = {}

// This reducer holds all community-user objects
// community-user objects have a limited subset of data from business service
export default (
  state = initialState,
  { type, resp, pagination, data, req }
) => {
  let newState
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/get_community_user_RESP':
      return {
        ...state,
        [data.id]: data,
      }
    case 'common/GET_USER_FOLLOWERS_RESP':
    case 'common/GET_USER_FOLLOWING_RESP':
    case 'common/GET_MY_FOLLOWING_RESP':
      newState = { ...state }
      data.forEach(user => {
        newState[user.id] = user
      })
      return newState
    case 'communityApp/c/getMembers_RESP':
      newState = { ...state }
      data.forEach(communityMember => {
        if (communityMember.user) {
          newState[communityMember.user.id] = communityMember.user
        }
      })
      return newState
    case 'common/DELETE_USER_FOLLOW_RESP':
    case 'common/POST_USER_FOLLOW_RESP':
      let followed = type === 'common/POST_USER_FOLLOW_RESP'
      return {
        ...state,
        [req.id]: {
          ...state[req.id],
          metadata: {
            ...state[req.id].metadata,
            followed,
          },
        },
      }
    default:
      return state
  }
}
