import React from 'react'
import { Transition } from 'react-transition-group'

import ContentBlocksFeed from 'common/components/ContentBlocks/ContentBlocksFeed'

const MyHubContentArea = ({ feed, loaded }) => (
  <Transition appear in={loaded} timeout={0} unmountOnExit>
    <div className='my-hub__content-area'>
      <ContentBlocksFeed feed={feed} location='My Hub' />
    </div>
  </Transition>
)

export default MyHubContentArea
