import React, { useMemo } from 'react'

import useContentBlockCarouselItemTracking from 'common/hooks/useContentBlockCarouselItemTracking'

import CarouselV2 from '../CarouselV2'
import EpisodeCard from '../EpisodeCard'
import ContentBlockBase, { ContentBlockHeaderLink } from './ContentBlockBase'

// The list_items_by_bulk endpoint uses item.uuid. Other endpoints use item.attributes.uuid
const getItemKey = item => item.uuid || item.attributes.uuid

// Episode cards have text beneath the card elements, extending the overall height of the elements, so the page buttons cannot use default centering.
const episodePageButtonStyle = {
  top: '40%',
  transform: `translateY(-50%)`,
}

const Placeholder = () => (
  <EpisodeCard
    item={{
      attributes: {
        display_title: 'Placeholder title',
      },
      links: { canonical_self: '/' },
    }}
  />
)

let ItemInner = ({ contentBlock, index, item, listId, location, perPage }) => {
  const trackClick = useContentBlockCarouselItemTracking({
    contentBlock,
    item,
    itemIndex: index,
    location,
    perPage,
  })

  return (
    <EpisodeCard
      listId={listId}
      item={item}
      onThumbnailClick={trackClick}
      onTitleClick={trackClick}
      width='500'
    />
  )
}

ItemInner = React.memo(ItemInner)

const ContentBlockEpisodeCarousel = ({
  contentBlock,
  headerUrl,
  items,
  listId,
  location,
  perPage,
  scrollModeMargins,
  title1,
  title2,
}) => {
  const Item = useMemo(
    () => ({ index, item, _selected }) => (
      <ItemInner
        contentBlock={contentBlock}
        index={index}
        item={item}
        listId={listId}
        location={location}
        perPage={perPage}
      />
    ),
    [contentBlock, listId, location, perPage]
  )

  return (
    <ContentBlockBase>
      <div className='content-block__episode-carousel'>
        <ContentBlockHeaderLink
          contentBlock={contentBlock}
          header1={title1}
          header2={title2}
          location={location}
          url={headerUrl}
        />
        <div className='content-block__episode-carousel-wrapper'>
          <CarouselV2
            allowOverflow
            getItemKey={getItemKey}
            items={items}
            pageButtonStyle={episodePageButtonStyle}
            perPage={perPage}
            placeholder={Placeholder}
            renderItem={Item}
            scrollModeMargins={scrollModeMargins}
            spacing={10}
            teasedItemCount={perPage}
            transitionDuration={500}
          />
        </div>
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockEpisodeCarousel
