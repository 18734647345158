import {
  FETCH_CONTENT_BLOCKS_FAILED,
  FETCH_CONTENT_BLOCKS_SUCCESSFUL,
} from 'homeApp/actions/types'

const initialState = { feeds: {} }

const contentBlocksReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_BLOCKS_SUCCESSFUL: {
      const { feed, feedType } = action
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [feedType]: feed,
        },
      }
    }
    case FETCH_CONTENT_BLOCKS_FAILED: {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default contentBlocksReducer
