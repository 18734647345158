import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { ExternalLink } from 'common/components'
import DiscordLink from 'common/containers/DiscordLink'
import {
  hasRole,
  ROLE_COMMUNITY_ADMIN,
  ROLE_GLOBAL_COMMUNITY_ADMIN,
  ROLE_GLOBAL_MODERATOR,
} from 'common/helpers'

const DiscordNavLink = () => (
  <DiscordLink>
    {({ discordHref, trackDiscordClick }) => (
      <li className='side-nav-menu__item'>
        <ExternalLink
          className='side-nav-menu__link waves-effect waves-brand'
          href={discordHref}
          onClick={trackDiscordClick}
        >
          Discord
        </ExternalLink>
      </li>
    )}
  </DiscordLink>
)

const SideNav = ({ className, user, featureFlags }) => {
  const classes = {
    base: classnames('side-nav-menu__subnav', className),
  }

  const showAdminLink = hasRole(ROLE_GLOBAL_MODERATOR, user)
  const showCreateNewLink = hasRole(ROLE_GLOBAL_COMMUNITY_ADMIN, user)

  const { newCommunity: useCommunityV2 } = featureFlags

  const navItems = []

  navItems.push(<DiscordNavLink />)

  if (useCommunityV2) {
    if (showAdminLink || showCreateNewLink) {
      navItems.push(
        <li className='side-nav-menu__item'>
          <NavLink
            className='side-nav-menu__link waves-effect waves-brand'
            exact
            to='/g'
          >
            Feed
          </NavLink>
        </li>
      )
      if (hasRole(ROLE_GLOBAL_MODERATOR, user)) {
        navItems.push(
          <li className='side-nav-menu__item'>
            <NavLink
              className='side-nav-menu__link waves-effect waves-brand'
              to='/g/admin'
            >
              Admin
            </NavLink>
          </li>
        )
      }
      if (hasRole(ROLE_COMMUNITY_ADMIN, user)) {
        navItems.push(
          <li className='side-nav-menu__item'>
            <NavLink
              className='side-nav-menu__link waves-effect waves-brand'
              to='/g/create'
            >
              Create New
            </NavLink>
          </li>
        )
      }
    }
  } else {
    navItems.push(
      <li className='side-nav-menu__item'>
        <NavLink
          className='side-nav-menu__link waves-effect waves-brand'
          exact
          to='/g'
        >
          Feed
        </NavLink>
      </li>
    )

    navItems.push(
      <li className='side-nav-menu__item'>
        <NavLink
          className='side-nav-menu__link waves-effect waves-brand'
          exact
          to='/g/explore'
        >
          Explore
        </NavLink>
      </li>
    )

    if (hasRole(ROLE_COMMUNITY_ADMIN, user)) {
      navItems.push(
        <li className='side-nav-menu__item'>
          <NavLink
            className='side-nav-menu__link waves-effect waves-brand'
            to='/g/create'
          >
            Create New
          </NavLink>
        </li>
      )
    }
  }

  if (navItems.length === 0) return null

  return <ul className={classes.base}>{navItems}</ul>
}

SideNav.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
}

export default SideNav
