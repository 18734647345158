// import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import reduxCatch from 'redux-catch'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'store/reduxFirstHistory'

import reduxErrorHandler from './reduxErrorHandler'

export function configureMiddleware({ history: _history }) {
  const sagaMiddleware = createSagaMiddleware()

  const middleware = [sagaMiddleware, routerMiddleware]
  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'staging'
  ) {
    middleware.push(reduxCatch(reduxErrorHandler))
  }
  return middleware
}
