import { put, takeEvery } from 'redux-saga/effects'

import { endpoints } from 'common/config'
import { simpleGET } from 'common/helpers/saga'
import { trackEvent, trackLikeEvent } from 'common/metrics'

/**
 * Create a post to a user's followers
 * @param {Object} action
 * @param {String} action.userId - the id of the user
 * @param {String} action.post - the message to post
 */
export function* userPost({ post, userId }) {
  const postResp = yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${userId}/posts`,
    type: 'common/USER_POST',
    method: 'POST',
    authenticated: true,
    body: post,
  })

  if (postResp && postResp.data && postResp.data.id) {
    let eventName = 'post_created'
    let postId = postResp.data.id
    if (post.repost_post_id) {
      eventName = 'post_reposted'
      postId = post.repost_post_id
    }
    trackEvent('SocialEvent', {
      label: eventName,
      platform: 'web',
      target_id: postId,
      target_type: 'post',
      user_uuid: userId,
    })
  }
}

/**
 * Fetches public-facing posts for a user
 * @param {String} id - uuid for the user we want posts for
 */
export function* getUsersPosts({ id, reset, next, authenticated = true }) {
  const url = new URL(`${endpoints.communityBase}/v1/users/${id}/home_feed`)

  // we should only page if we're not resetting
  if (next && !reset) {
    url.searchParams.append('cursor', next)
  }

  yield simpleGET({
    authenticated,
    url,
    type: 'common/GET_USER_FEED',
    method: 'GET',
    reset,
    id,
  })
}

export function* getPost({ authenticated = true, id }) {
  yield simpleGET({
    authenticated,
    url: `${endpoints.communityBase}/v1/posts/${id}`,
    type: 'common/GET_POST',
    method: 'GET',
  })
}

export function* updatePost({ id, post, pinPost, communityId }) {
  const postResp = yield simpleGET({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/posts/${id}`,
    type: 'common/PATCH_POST',
    method: 'PATCH',
    body: post,
    id,
  })

  if (
    typeof pinPost === 'boolean' &&
    postResp &&
    postResp.data &&
    postResp.data.id
  ) {
    if (pinPost) {
      yield put({
        type: 'communityApp/subCommunity/pin',
        communityId,
        postToPinId: id,
      })
    } else {
      // don't worry this function makes sure its only unpinning if the thing you are unpinning was pinned
      yield put({
        type: 'communityApp/subCommunity/unpin',
        communityId,
        postToUnpinId: id,
      })
    }
  }
}

export function* flagPost({ label, postId }) {
  yield simpleGET({
    authenticated: true,
    body: { type: label },
    method: 'POST',
    type: 'communityApp/g/post/flag',
    url: `${endpoints.communityBase}/v1/posts/${postId}/flag`,
  })
}

export function* likePost({ postId, associatedPostId }) {
  const resp = yield simpleGET({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/posts/${postId}/like`,
    type: 'communityApp/g/post/like',
    method: 'POST',
    postId,
    associatedPostId,
  })

  if (resp.likes) trackLikeEvent(postId)
}

export function* unLikePost({ postId, associatedPostId }) {
  yield simpleGET({
    authenticated: true,
    url: `${endpoints.communityBase}/v1/posts/${postId}/like`,
    type: 'communityApp/g/post/unlike',
    method: 'DELETE',
    postId,
    associatedPostId,
  })
}

export function* removePost({ id, reason }) {
  yield simpleGET({
    authenticated: true,
    body: { reason },
    id,
    method: 'DELETE',
    type: 'common/DELETE_POST',
    url: `${endpoints.communityBase}/v1/posts/${id}`,
  })
}

export function* posts() {
  yield takeEvery('common/USER_POST', userPost)
  yield takeEvery('common/GET_USER_FEED', getUsersPosts)
  yield takeEvery('common/GET_POST', getPost)
  yield takeEvery('common/PATCH_POST', updatePost)
  yield takeEvery('common/DELETE_POST', removePost)
  yield takeEvery('communityApp/g/post/flag', flagPost)
  yield takeEvery('communityApp/g/post/like', likePost)
  yield takeEvery('communityApp/g/post/unlike', unLikePost)
}
