import React, { Component } from 'react'
import { Dropdown } from 'materialize-css'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Avatar from 'common/components/Avatar'
import { RTDropdown } from 'common/components/RTDropdown'
import { userProfilePath } from 'common/helpers'
import { a11yButtonProps } from 'common/helpers/accessibility'
import * as metrics from 'common/metrics'

import './styles.scss'

class ProfileBar extends Component {
  static propTypes = {
    user: PropTypes.object,
  }

  dropdownRef = React.createRef()

  componentDidMount() {
    Dropdown.init(this.dropdownRef.current, {
      alignment: 'right',
      constrainWidth: false,
      coverTrigger: true,
    })
  }

  render() {
    const {
      loginHandler,
      logoutHandler,
      signupHandler,
      showMyHub,
      user,
    } = this.props

    return (
      <div className='nav-profile-menu'>
        <RTDropdown
          className='nav-profile-menu__rt-dropdown'
          triggerElement={({ open, ref }) => (
            <div
              aria-label='Account menu'
              className='nav-profile-menu__trigger'
              onClick={open}
              onKeyPress={open}
              ref={ref}
              role='button'
              tabIndex={0}
            >
              {user ? (
                <Avatar
                  className='nav-profile-menu__avatar'
                  size={30}
                  src={user?.attributes?.pictures?.tb?.profile}
                />
              ) : (
                <i className='nav-profile-menu__avatar-icon icon-account_circle qa-avatar-logged-out' />
              )}
            </div>
          )}
          minWidth={175}
          position='bottom-left'
          dropdownContents={
            user === null ? (
              <ul className='nav-profile-menu__list'>
                <li>
                  <div {...a11yButtonProps(loginHandler)}>
                    <span className='trk-profile-dropdown-login'>Log In</span>
                  </div>
                </li>
                <li>
                  <div {...a11yButtonProps(signupHandler)}>
                    <span className='trk-profile-dropdown-signup'>Sign Up</span>
                  </div>
                </li>
              </ul>
            ) : (
              <ul className='nav-profile-menu__list'>
                <li className='nav-profile-menu__list-username'>
                  <div>{user.attributes.username}</div>
                </li>
                <li>
                  <div {...a11yButtonProps(showMyHub)}>My Hub</div>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      metrics.trackClick('account_sub_menu_profile_link')
                    }
                    to={userProfilePath(user.attributes.username)}
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      metrics.trackClick('account_sub_menu_settings')
                    }
                    to='/settings'
                  >
                    Settings
                  </Link>
                </li>
                <li className='nav-profile-menu__list-divider' />
                <li>
                  <a href='#logout' onClick={logoutHandler}>
                    Log Out
                  </a>
                </li>
              </ul>
            )
          }
        />
      </div>
    )
  }
}

export default ProfileBar
