export default (
  state = { list: [], lookingForSuggestions: false },
  { type, resp: list }
) => {
  switch (type) {
    case 'common/GET_USERS_REQ':
      return {
        ...state,
        lookingForSuggestions: true,
      }
    case 'common/GET_USERS_RESP':
      return {
        ...state,
        list,
        lookingForSuggestions: false,
      }
    default:
      return state
  }
}
