import Honeybadger from 'honeybadger-js'

import { redactState } from 'common/helpers'

function reduxErrorHandler(error, getState, lastAction, _dispatch) {
  if (!error) {
    return
  }

  const {
    auth,
    commonReducer,
    episodeReducer,
    router,
    subscriptions,
  } = getState()
  Honeybadger.notify(error, {
    context: {
      lastAction,
      state: redactState({
        auth,
        featureFlags: commonReducer.featureFlags,
        episodeReducer,
        router,
        subscriptions,
      }),
    },
  })
}

export default reduxErrorHandler
