import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userOwnsPost } from 'common/helpers'
import MaterializeSelect from 'common/components/MaterializeSelect'
import PrimaryBtn from 'common/components/Buttons/Primary'
import TertiaryBtn from 'common/components/Buttons/Tertiary'

import './styles.scss'

export class DeletePrompt extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onComplete: PropTypes.func,
    post: PropTypes.object.isRequired,
    text: PropTypes.string,
    user: PropTypes.object.isRequired,
  }

  static defaultProps = {
    onComplete: () => null,
    text: 'Are you sure you want to delete this post?',
  }

  deletePost = reason => {
    const { dispatch, onComplete, post } = this.props

    dispatch({
      type: 'common/DELETE_POST',
      id: post.id,
      reason,
    })

    onComplete()
  }

  handleOwnDelete = () => {
    this.deletePost()
  }

  handleSelect = ({ target: { value } }) => {
    this.deletePost(value)
  }

  render() {
    const { onComplete, post, text, user } = this.props

    return (
      <div className='delete-prompt sticky-prompt'>
        <h3 className='delete-prompt__heading'>{text}</h3>

        <div className='delete-prompt__actions'>
          <div className='delete-prompt__cancel'>
            <TertiaryBtn bg='dark' onClick={onComplete}>
              Cancel
            </TertiaryBtn>
          </div>

          {userOwnsPost({ post, user }) ? (
            <PrimaryBtn bg='dark' onClick={this.handleOwnDelete}>
              Delete
            </PrimaryBtn>
          ) : (
            <MaterializeSelect defaultValue='null' onChange={this.handleSelect}>
              <option disabled value='null'>
                Delete for …
              </option>
              <option value='spam'>Spam</option>
              <option value='abuse'>Abuse</option>
              <option value='other'>Other</option>
            </MaterializeSelect>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { user } = state.authReducer

  return {
    user,
  }
}

export default connect(mapStateToProps)(DeletePrompt)
