import React, { Component } from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import URLSearchParams from 'url-search-params'

import { fetchAllReplies } from 'common/actions'
import { AddComment, Spinner, Tooltip } from 'common/components'
import Avatar from 'common/components/Avatar'
import FormattedText from 'common/components/FormattedText'
import InlineLike from 'common/components/InlineLike'
import { Badges, CommentAdminControls } from 'common/containers'
import {
  hasRole,
  ROLE_ADMIN,
  ROLE_GLOBAL_MODERATOR,
  sortByDateAsc,
  userProfilePath,
} from 'common/helpers'
import withRouter from 'common/helpers/withRouter'

import './styles.scss'

const SPOILER_CONTENT = '01001001 01010010 01110011 01010000 01101000 01100101'

export class CommentBase extends Component {
  static defaultProps = {
    activeReplyForm: '0',
    avatarSize: 30,
    bg: 'light',
    child_comments_total_count: 0,
    created_at: '',
    handleHashTags: false,
    isHighlighted: null,
    isReply: null,
    message: '',
    onOptionsActionClick() {},
    onReplyClick: () => null,
    onShowReplies: () => null,
    owner_email: '',
    owner_image: '',
    owner_name: '',
    replies: [],
    showReplies: null,
    staff_member_has_replied: false,
    toggleReplyForm: () => null,
    topic_uuid: '',
    user: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      showReplies: this.props.showReplies || false,
      editing: false,
      showSpoiler: !this.props.spoiler,
    }
  }

  // --------
  // Lifecycle
  // --------
  UNSAFE_componentWillReceiveProps(nextProps) {
    const replies = this.props.replies
    if (
      replies &&
      replies.length >= 0 &&
      replies.length !== nextProps.replies.length
    ) {
      if (!this.state.showReplies) {
        this.toggleReplies('e', true)
      }
      this.props.toggleReplyForm('0')
    } else if (this.props.message !== nextProps.message) {
      this.toggleEdit(false)
    }

    // force closing all reply forms if we just finished adding a comment
    if (nextProps.isCommenting && !this.props.isCommenting) {
      this.props.toggleReplyForm('0')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.activeReplyForm !== this.props.activeReplyForm ||
      nextProps.child_comments_total_count !==
        this.props.child_comments_total_count ||
      nextProps.isHighlighted !== this.props.isHighlighted ||
      nextProps.isReply !== this.props.isReply ||
      nextProps.liked_status !== this.props.liked_status ||
      nextProps.likes_count !== this.props.likes_count ||
      nextProps.message !== this.props.message ||
      nextProps.onReplyClick !== this.props.onReplyClick ||
      nextProps.onShowReplies !== this.props.onShowReplies ||
      nextProps.onReplyClick !== this.props.onReplyClick ||
      nextProps.replies !== this.props.replies ||
      nextProps.showReplies !== this.props.showReplies ||
      nextProps.toggleReplyForm !== this.props.toggleReplyForm ||
      nextState.showReplies !== this.state.showReplies ||
      nextState.showSpoiler !== this.state.showSpoiler ||
      nextState.editing !== this.state.editing
    )
  }

  handleLikeClick = e => {
    const {
      likeComment,
      liked_status: isLiked,
      removeCommentLike,
      uuid,
    } = this.props

    e.preventDefault()

    if (isLiked) {
      removeCommentLike(uuid)
    } else {
      likeComment(uuid)
    }
  }

  handleReplyClick = (e, replyId, uuid) => {
    e.preventDefault()
    this.props.onReplyClick()
    this.props.toggleReplyForm(replyId || uuid)
  }

  isCommunity = () => {
    const { pathname } = this.props.router.location

    return pathname.startsWith('/g/') || pathname === '/g'
  }

  /**
   * Generates `to` route object for this comment
   * @return {Object}
   */
  getPermalink = () => {
    const routeParams = { ...this.props.router.location }
    let query
    if (!routeParams || !routeParams.query) {
      query = new URLSearchParams()
    } else {
      query = new URLSearchParams(routeParams.query)
    }
    query.set('comment', this.props.uuid)
    return `${this.props.router.location.pathname}?${query.toString()}`
  }

  onClickUser = (e, user) => {
    this.props.history.push(userProfilePath(user))
  }

  onEdit = _e => {
    this.toggleEdit(true)
  }

  toggleEdit = (editing = !this.props.editing) => {
    this.setState({ editing })
  }

  toggleReplies = (e, showReplies = !this.state.showReplies) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }
    this.setState({ showReplies })
    if (showReplies && this.props.child_comments_total_count) {
      // avoid race condition, if this is first reply don't fetch because BE will send empty array
      // since the post hasn't finished being written
      this.props.dispatch(fetchAllReplies(this.props.uuid))
    }
  }

  toggleSpoiler = () => {
    this.setState(state => ({ showSpoiler: !state.showSpoiler }))
  }

  renderReplies = () => {
    const {
      activeReplyForm,
      bg,
      error,
      featureFlags,
      handleHashTags,
      highlightedCommentUuid,
      isCommenting,
      isLikable,
      likeComment,
      removeCommentLike,
      replies,
      user,
    } = this.props
    // sort by ascending created_at date
    const sortedReplies = replies.sort((a, b) =>
      sortByDateAsc(a.created_at, b.created_at)
    )
    return sortedReplies.map((reply, _i) => (
      <Comment
        activeReplyForm={activeReplyForm}
        avatarSize={30}
        bg={bg}
        className='reply'
        dispatch={this.props.dispatch}
        error={error}
        featureFlags={featureFlags}
        handleHashTags={handleHashTags}
        highlightedCommentUuid={highlightedCommentUuid}
        isCommenting={isCommenting}
        isLikable={isLikable}
        isReply
        key={`comment-${reply.uuid}`}
        likeComment={likeComment}
        removeCommentLike={removeCommentLike}
        toggleReplyForm={() => this.props.toggleReplyForm(reply.uuid)}
        trackSubmission={this.isCommunity()}
        user={user}
        {...reply}
      />
    ))
  }

  render() {
    const {
      activeReplyForm,
      avatarSize,
      bg,
      child_comments_total_count: replyCount,
      className,
      created_at: createdAt,
      deleted_at: deletedAt,
      dispatch,
      edited_at: editedAt,
      error,
      handleHashTags,
      highlightedCommentUuid,
      isCommenting,
      isFetching,
      isReply,
      liked_status: isLiked,
      likes_count: likesCount,
      message,
      owner_badges: ownerBadges,
      owner_image: ownerImage,
      owner_name: ownerName,
      owner_roles: ownerRoles,
      owner_status: ownerTier,
      parent_uuid: parentUuid,
      shadowbanned,
      spoiler,
      staff_member_has_replied: isStaffReplied,
      topic_type: topicType,
      topic_uuid: topicUuid,
      user,
      userList,
      uuid,
    } = this.props
    const { editing, showSpoiler } = this.state
    const isEmpty = replyCount === 0
    // we only nest one level deep for comments, so if we're a reply, we should
    // point at the original comment
    const parentId = isReply ? parentUuid : uuid
    const replyId = isReply && uuid
    // when replying, prepopulate with this comment's owner as an @mention
    const userMention = ownerName ? `@${ownerName} ` : ''
    const userLink = userProfilePath(ownerName)

    return (
      <article
        className={`comment row ${className || ''}`}
        id={`comment-${isReply ? replyId : parentId}`}
      >
        <div className='comment-avatar'>
          <a href={userLink} rel='noopener noreferrer nofollow' target='_blank'>
            <Avatar src={ownerImage} size={avatarSize} />
          </a>
        </div>
        <div className='comment-body'>
          <div className='comment-metadata'>
            <h6 className='comment-name'>
              <a
                href={userLink}
                rel='noopener noreferrer nofollow'
                target='_blank'
              >
                {ownerName}
              </a>
              <Badges
                user={{
                  badges: ownerBadges,
                  roles: ownerRoles,
                  tier: ownerTier,
                }}
                badgeClasses=''
              />
              {highlightedCommentUuid === uuid && (
                <span className='badge grey darken-3 comment--highlighted-text'>
                  <span className='blue-text indicator'>&#9679;</span>
                  <span className='grey-text'>
                    Highlighted {isReply ? 'Reply' : 'Comment'}
                  </span>
                </span>
              )}
              <Link
                to={this.getPermalink()}
                className='comment__timeago grey-text text-darken-1'
              >
                <small>{moment(createdAt).fromNow()}</small>
              </Link>
            </h6>
            <div className='comment-timeago'>
              {editedAt !== null ? (
                <span
                  className='comment-edited'
                  title={`last edited ${moment(editedAt).fromNow()}`}
                >
                  *
                </span>
              ) : (
                ''
              )}
              {(hasRole(ROLE_ADMIN, user) ||
                hasRole(ROLE_GLOBAL_MODERATOR, user)) &&
                deletedAt && <span className='deleted-badge'>deleted</span>}
              {(hasRole(ROLE_ADMIN, user) ||
                hasRole(ROLE_GLOBAL_MODERATOR, user)) &&
                shadowbanned && (
                  <span className='shadowbanned-badge'>shadowbanned</span>
                )}
            </div>
          </div>
          <div
            className={`comment-message ${
              !showSpoiler && !deletedAt ? 'spoiler' : ''
            }`}
          >
            {spoiler && !showSpoiler && !editing && !deletedAt && (
              <div className='comment-spoiler-overlay'>
                <button
                  type='button'
                  className='comment-toggle-spoiler'
                  onClick={this.toggleSpoiler}
                >
                  [Show Spoilers]
                </button>
              </div>
            )}
            {!editing ? (
              <FormattedText
                emojiSize={15}
                text={
                  !showSpoiler && !editing && !deletedAt
                    ? SPOILER_CONTENT
                    : message
                }
                handleClickOnUser={this.onClickUser}
                richMedia
                disablePosts
                handleHashTags={handleHashTags}
              />
            ) : (
              <AddComment
                bg={bg}
                className='comment-reply-form'
                commentId={uuid}
                dispatch={this.props.dispatch}
                focusIn
                isCommenting={isCommenting}
                onCancel={() => this.toggleEdit(false)}
                placeholder='Edit your comment'
                submitCopy='Save Changes'
                topicType={topicType}
                trackSubmission={this.isCommunity()}
                user={user}
                userList={userList}
                value={
                  !showSpoiler && !editing && !deletedAt
                    ? SPOILER_CONTENT
                    : message
                }
              />
            )}
            {!!user && (
              <CommentAdminControls
                {...this.props}
                dispatch={this.props.dispatch}
                onActionClick={this.props.onOptionsActionClick}
                onEdit={this.onEdit}
              />
            )}
          </div>

          <div className='comment-actions'>
            <button
              className='comment-reply-btn'
              onClick={e => this.handleReplyClick(e, replyId, uuid)}
              type='button'
            >
              <i className='icon icon-reply' />
              Reply
            </button>

            <InlineLike
              count={likesCount}
              isLiked={Boolean(isLiked)} // Cast undefined as false
              onClick={this.handleLikeClick}
            />
          </div>

          <div className='comment-reply'>
            {activeReplyForm === (replyId || uuid) && (
              <AddComment
                bg={bg}
                className='comment-reply-form'
                dispatch={dispatch}
                error={error}
                focusIn
                isCommenting={isCommenting}
                lockedValue={userMention}
                onCancel={() => this.props.toggleReplyForm('0')}
                parentId={parentId}
                placeholder='Add a reply'
                replyId={replyId}
                replyTimestamp={createdAt}
                topicId={topicUuid}
                topicType={topicType}
                trackSubmission={this.isCommunity()}
                user={user}
                userList={userList}
                value=' '
              />
            )}
          </div>
          {!isEmpty && (
            <div className='comment-show-replies'>
              <button
                className='comment-replies-toggle'
                onClick={this.toggleReplies}
                type='button'
              >
                {this.state.showReplies ? (
                  <>
                    Hide {pluralize('reply', replyCount, true)}
                    <span className='icon icon-chevron-up' />
                  </>
                ) : (
                  <>
                    Show {pluralize('reply', replyCount, true)}
                    <span className='icon icon-chevron-down' />
                  </>
                )}
              </button>

              {isStaffReplied && (
                <Tooltip text='RT Staff Member Replied'>
                  <span className='comment-staff-replied'>
                    <i className='icon icon-rooster' />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {!isEmpty && this.state.showReplies && (
            <div className='comment-replies'>
              {isFetching ? <Spinner /> : this.renderReplies()}
            </div>
          )}
        </div>
      </article>
    )
  }
}

const Comment = withRouter(CommentBase)

export default Comment
