import React, { Component } from 'react'
import { Tooltip } from 'materialize-css'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  hasRole,
  ROLE_CHAT_ADMIN,
  ROLE_GLOBAL_CHAT_ADMIN,
  ROLE_GLOBAL_CHAT_MODERATOR,
} from 'common/helpers'

import './styles.scss'

// badge render mappings
// -------
const TOOLTIPS = {
  chat_admin: 'chat admin',
  chat_bot: 'chat bot',
  chat_moderator: 'chat moderator',
  staff: 'rooster teeth staff',
  first: 'first member',
  first_plus: 'first plus member',
}
const BADGE_ICONS = {
  chat_admin: 'chat-admin icon-teeth',
  chat_bot: 'chat-bot icon-robot',
  chat_moderator: 'chat-moderator icon-teeth',
  staff: 'staff icon-rooster',
  first: 'first icon-star2',
  first_plus: 'first icon-FIRST_Badge',
}

class Badges extends Component {
  static propTypes = {
    // expects a normalized user object
    user: PropTypes.object,
    // accepts 'default', 'community', 'chat'
    context: PropTypes.string,
    // modifier classes you would like to appear on your badges
    badgeClasses: PropTypes.string,
    // Override badge logic
    badges: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    context: 'default',
    badgeClasses: '',
  }

  // ------
  // Lifecycle
  // ------
  componentWillUnmount() {
    this.removeTooltips()
  }

  // ------
  // Methods
  // ------
  renderBadges = () => {
    const jsx = []
    const tierBadge = this.getTierBadge()
    jsx.push(tierBadge)

    if (this.props.context === 'chat' || this.props.context === 'newChat') {
      jsx.push(this.getChatBadges())
    } else {
      const badgeRoles = this.getBadgeRoles()
      for (const badge of badgeRoles) {
        jsx.push(this.renderBadge(badge))
      }
    }
    return jsx
  }

  getBadge = (array, label) => array.find(badge => badge.label === label)

  /**
   * Gets badge roles to render
   */
  getBadgeRoles = () => {
    const { badges, isModerator, user } = this.props
    let applicableBadges = []
    const userBadges = user?.badges || user?.attributes?.badges
    // if user doesn't have any badges don't bother
    if (!userBadges || userBadges.length < 1 || !badges) {
      return applicableBadges
    }
    // if local moderator, and not global moderator add local badge
    // isModerator flag is only set on a group page so you should only see there
    const localModBadge = this.getBadge(badges, 'Local Moderator')
    const globalModBadge = this.getBadge(badges, 'Global Moderator')
    if (isModerator && localModBadge && !globalModBadge) {
      applicableBadges.push(localModBadge)
    }

    // add badges that this user has
    applicableBadges.push(
      ...badges.filter(badge => userBadges.indexOf(badge.label) !== -1)
    )

    // site admin should replace global mod if a user has both
    if (
      this.getBadge(applicableBadges, 'Global Moderator') &&
      this.getBadge(applicableBadges, 'Site Admin')
    ) {
      applicableBadges = applicableBadges.filter(
        badge => badge.label !== 'Global Moderator'
      )
    }

    // if First+, make that first icon that shows
    const firstPlusIndex = applicableBadges.findIndex(
      badge => badge.label === 'First+'
    )
    if (firstPlusIndex) {
      const firstBadge = applicableBadges.splice(firstPlusIndex, 1)
      applicableBadges.unshift(firstBadge[0])
    }

    return applicableBadges
  }

  getTierBadge = () => {
    const { context, user } = this.props
    const userBadges = user?.badges || user?.attributes?.badges

    if (userBadges?.indexOf('First+') !== -1) {
      // if chat, first plus icon is ok
      if (context === 'chat' || context === 'newChat') {
        return this.renderIcon('first_plus')
      }
      // else don't add first star since first+ will be in badges
      return null
    }

    const firstTier =
      user?.member_tier || user?.attributes?.member_tier || user?.tier
    if (firstTier === 'first') {
      return this.renderIcon('first')
    }

    return null
  }

  getChatBadges = () => {
    const { context, user } = this.props
    const jsx = []
    if (context === 'newChat' && hasRole(ROLE_GLOBAL_CHAT_ADMIN, user)) {
      jsx.push(this.renderIcon('chat_admin'))
    } else if (context === 'chat' && hasRole(ROLE_CHAT_ADMIN, user)) {
      jsx.push(this.renderIcon('chat_admin'))
    } else if (
      context === 'newChat' &&
      hasRole(ROLE_GLOBAL_CHAT_MODERATOR, user)
    ) {
      jsx.push(this.renderIcon('chat_moderator'))
    }
    if (user?.badges?.indexOf('bot') > -1) {
      jsx.push(this.renderIcon('chat_bot'))
    }
    if (user?.badges?.indexOf('Staff') > -1) {
      jsx.push(this.renderIcon('staff'))
    }

    return jsx
  }

  renderBadge = badgeType => {
    const { badgeClasses } = this.props
    const tooltipText = typeof badgeType === 'object' ? badgeType.label : 'hi'
    // if for some reason the badge is not defined or has no image don't render
    if (!badgeType?.cdn_url) {
      return null
    }
    return (
      <img
        key={badgeType.id}
        className={`badge tt ${badgeClasses}`}
        onMouseEnter={e => this.createTooltip(e, tooltipText)}
        onMouseLeave={this.removeTooltips}
        data-position='top'
        src={badgeType.cdn_url}
        alt={`${badgeType.label} badge`}
      />
    )
  }

  renderIcon = badgeType => {
    const { badgeClasses } = this.props
    const tooltipText = TOOLTIPS[badgeType]
    const icon = BADGE_ICONS[badgeType]
    return (
      <i
        key={badgeType}
        className={`badge tt ${badgeClasses} ${icon}`}
        onMouseEnter={e => this.createTooltip(e, tooltipText)}
        onMouseLeave={this.removeTooltips}
        data-position='top'
      />
    )
  }

  createTooltip = (e, tooltipText) => {
    Tooltip.init(e.target, {
      enterDelay: 50,
      html: `<span class="badge-tt">${tooltipText}</span>`,
    })
  }

  removeTooltips = () => {
    // make sure to account for a tt getting orphaned on a render
    const tts = document.getElementsByClassName('badge-tt')
    while (tts.length > 0) {
      tts[0].closest('.material-tooltip').remove()
    }
  }

  render() {
    return <span className='badges'>{this.renderBadges()}</span>
  }
}

const mapStateToProps = (state, _ownProps) => {
  const { userBadges: badges } = state.rootReducer
  return {
    badges,
  }
}

export default connect(mapStateToProps)(Badges)
