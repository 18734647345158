import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './styles.scss'

const CarouselHeader = ({ link, onClick, title }) => {
  if (link === null) return <h1 className='carousel-title'>{title}</h1>
  return (
    <h1 className='carousel-title link'>
      <Link onClick={onClick} title={title} to={link}>
        {title} <i className='show-more icon-keyboard_arrow_right' />
      </Link>
    </h1>
  )
}

CarouselHeader.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
}

CarouselHeader.defaultProps = {
  link: '',
  onClick: () => null,
  title: '',
}

export default CarouselHeader
