import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import io from 'socket.io-client'

const socketContext = createContext()

// Provider component that wraps your app and makes socket object ...
// ... available to any child component that calls useSocket().
export function ProvideSocket({ children }) {
  const sock = useProvideSocket()
  return (
    <socketContext.Provider value={sock}>{children}</socketContext.Provider>
  )
}

// Hook for child components to get the socket object ...
// ... and re-render when it changes.
export const useSocket = () => useContext(socketContext)

const useProvideSocket = () => {
  const socket = useRef(null)
  const setSocket = useCallback(node => {
    if (node !== null) {
      node.on('connect', () => {
        // console.log('set connect true')
        setConnected(true)
      })
      node.on('disconnect', _reason => {
        // console.log('disconnect to true')
        setConnected(false)
      })
      node.on('unauthorized', _error => {
        // token was not good on connection, clear it
        // console.error('unauthorized error', error)
      })
      node.on('connect_error', () => {
        // console.log('Connection Failed')
      })
    }
    socket.current = node
  }, [])
  const [connected, setConnected] = useState(false)

  const initSocket = (apiUrl, ...args) => {
    // console.log('initsocket', args, apiUrl, socket)
    if (socket.current) {
      socket.current.disconnect()
    }
    // socket.current = io(apiUrl, ...args)
    setSocket(io(apiUrl, ...args))
  }

  const connectSocket = () => {
    // console.log('connect', socket.current)
    socket.current.connect()
  }

  const disconnectSocket = () => {
    // console.log('disconnect sock', socket.current)
    socket.current.disconnect()
  }

  return {
    connectSocket,
    connected,
    disconnectSocket,
    initSocket,
    socket: (socket && socket.current) || null,
  }
}
