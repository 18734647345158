import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Post } from 'common/components'
import { getSubjectPostId } from 'common/helpers/post'

class PostContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
  }

  // ------
  // Lifecycle
  // ------
  componentDidMount() {
    const { dispatch, id, post, user } = this.props
    if (!post) {
      dispatch({
        authenticated: !!user,
        type: 'common/GET_POST',
        id,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, user } = this.props
    if (nextProps.id !== this.props.id) {
      dispatch({
        authenticated: !!user,
        type: 'common/GET_POST',
        id: nextProps.id,
      })
    }
  }

  // ------
  // Render
  // ------
  render() {
    const {
      allowReply,
      dispatch,
      featureFlags,
      isRepost,
      post,
      subjectPost,
      user,
    } = this.props

    if (post) {
      return (
        <Post
          allowReply={allowReply}
          post={post}
          dispatch={dispatch}
          featureFlags={featureFlags}
          enablePostDetails
          isRepost={isRepost}
          user={user}
          subjectPost={subjectPost}
        />
      )
    }

    return null
  }
}

const mapStateToProps = (state, ownProps) => {
  const { featureFlags, posts } = state.commonReducer
  const post = ownProps.post ? ownProps.post : posts[ownProps.id]
  const subjectPost = posts[getSubjectPostId(post)]
  const { user } = state.authReducer

  return {
    subjectPost,
    user,
    post,
    featureFlags,
  }
}

export default connect(mapStateToProps)(PostContainer)
