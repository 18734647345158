const processOAuthErrorResponse = errorResponse => {
  if (errorResponse.type === 'popup_closed') {
    return 'Uh oh, it looks like the sign-in popup window was closed.'
  }
  if (errorResponse.type === 'popup_failed_to_open') {
    return 'You might need to enable popup windows to sign in.'
  }
  if (
    errorResponse.details &&
    errorResponse.details.includes('Cookies are not enabled')
  ) {
    return "Cookies must be enabled to log in. If you're in Private or Incognito browsing, you might not be able to log in."
  }
  if (
    errorResponse.message &&
    errorResponse.message.includes('The desired condition was never met')
  ) {
    return "If you're in Private or Incognito browsing, you might not be able to log in."
  }
  return 'An error occured while trying to sign in. Please refresh and try again.'
}

export default processOAuthErrorResponse
