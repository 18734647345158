import React, { Component } from 'react'
import { Tooltip } from 'materialize-css'
import { connect } from 'react-redux'

import { addToLikes, fetchLikesShowData, rmFromLikes } from 'common/actions'
import { Preloader } from 'common/components'

import { showModal } from '../../../modalApp/actions'

import './styles.scss'

class LikeBtn extends Component {
  tooltipInstance = null

  tooltipRef = React.createRef()

  static defaultProps = {
    className: '',
    uuid: 0,
    onAddClick() {},
    onRemoveClick() {},
  }

  constructor(props) {
    super(props)

    this.state = {
      isLiked: props.itemUuids.indexOf(props.uuid) !== -1,
      submitting: false,
    }
  }

  componentDidMount() {
    const { uuid, dispatch, isAuthenticated } = this.props
    if (isAuthenticated) {
      dispatch(fetchLikesShowData(uuid))
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isFetching, itemUuids, uuid } = this.props
    if (itemUuids !== nextProps.itemUuids) {
      // our list of liked items has updated
      this.setState({ isLiked: nextProps.itemUuids.indexOf(uuid) !== -1 })
    }
    if (isFetching && this.state.submitting && !nextProps.isFetching) {
      this.setState({ submitting: false })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLiked !== this.state.isLiked) {
      // update our tooltip
      if (this.tooltipInstance) {
        this.tooltipInstance.destroy()
      }
      this.tooltipInstance = Tooltip.init(this.tooltipRef.current, {
        enterDelay: 50,
      })
    }
  }

  componentWillUnmount() {
    this.destroyTooltip()
  }

  initTooltip = () => {
    this.tooltipInstance = Tooltip.init(this.tooltipRef.current, {
      enterDelay: 50,
    })
  }

  destroyTooltip = () => {
    this.tooltipInstance?.destroy()
  }

  addItem = () => {
    const { uuid, dispatch, isAuthenticated } = this.props
    if (!isAuthenticated) {
      dispatch(showModal('USER_LOGIN', { user: '' }))
      return
    }
    this.setState({ isLiked: true, submitting: true })
    dispatch(addToLikes(uuid, 'episode'))
  }

  handleAddClick = e => {
    e.stopPropagation()
    this.addItem()
    this.props.onAddClick()
  }

  handleRemoveClick = e => {
    e.stopPropagation()
    this.removeItem()
    this.props.onRemoveClick()
  }

  removeItem = () => {
    const { uuid, dispatch, isAuthenticated } = this.props
    if (!isAuthenticated) {
      dispatch(showModal('USER_LOGIN', { user: '' }))
      return
    }
    this.setState({ isLiked: false, submitting: true })
    dispatch(rmFromLikes(uuid))
  }

  render() {
    const { className } = this.props
    const { isLiked, submitting } = this.state
    return isLiked ? (
      <div
        className={className}
        onClick={this.handleRemoveClick}
        onMouseEnter={this.initTooltip}
        onMouseLeave={this.destroyTooltip}
        role='presentation'
      >
        <div className='like'>
          <Preloader size='small' condition={!submitting} color='#f7f7f8'>
            <input id='toggle-heart' type='checkbox' defaultChecked />
            <label
              htmlFor='toggle-heart'
              aria-label='like'
              className='tooltipped'
              ref={this.tooltipRef}
              data-position='bottom'
              data-tooltip='I Like It!'
            >
              <i className='icon icon-heart_filled' />
            </label>
          </Preloader>
        </div>
      </div>
    ) : (
      <div
        className={className}
        onClick={this.handleAddClick}
        onMouseEnter={this.initTooltip}
        role='presentation'
      >
        <div className='like'>
          <Preloader size='small' condition={!submitting} color='#f7f7f8'>
            <input id='toggle-heart' type='checkbox' defaultChecked={false} />
            <label
              htmlFor='toggle-heart'
              aria-label='like'
              className='tooltipped'
              ref={this.tooltipRef}
              data-position='bottom'
              data-tooltip='Like Video'
            >
              <i className='icon icon-heart_empty' />
            </label>
          </Preloader>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.authReducer
  const { isFetching, itemUuids } = state.commonReducer.rating
  return {
    isAuthenticated,
    isFetching,
    itemUuids,
  }
}

export default connect(mapStateToProps)(LikeBtn)
