import uniqBy from 'lodash.uniqby'

import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION_CATEGORIES_SUCCESS,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_UNSEEN_SUCCESS,
  PUT_ALL_NOTIFICATIONS_READ_FAILURE,
  PUT_ALL_NOTIFICATIONS_READ_SUCCESS,
  PUT_ALL_NOTIFICATIONS_SEEN_FAILURE,
  PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS,
  PUT_NOTIFICATION_READ,
  PUT_NOTIFICATION_READ_FAILURE,
  PUT_NOTIFICATION_SEEN,
  PUT_NOTIFICATION_SEEN_FAILURE,
  PUT_NOTIFICATION_SETTINGS_SUCCESS,
} from '../actions/types'

const initialState = {
  canPage: false,
  categories: null,
  error: null,
  isFetching: false,
  nextLink: '',
  notifications: [],
  page: null,
  publicCategories: null,
  isReminded: null,
  settings: null,
  types: null,
  unseen: 0,
}

export default (state = initialState, action) => {
  let notifications = null
  const now = new Date()
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    case GET_NOTIFICATIONS_SUCCESS:
      notifications = [...action.notifications]
      if (action.merge) {
        notifications = [...state.notifications, ...notifications]
      }
      return {
        ...state,
        isFetching: false,
        notifications: uniqBy(notifications, n => n.id),
        nextLink: action.links?.next,
        canPage: !!action.links?.next,
      }
    case GET_NOTIFICATION_CATEGORIES_SUCCESS:
      return {
        ...state,
        publicCategories: action.categories,
      }
    case GET_NOTIFICATIONS_UNSEEN_SUCCESS:
      return {
        ...state,
        unseen: action.unseen.length,
      }
    case GET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        settings: action.settings,
      }
    case PUT_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        settings: action.settings,
      }
    case PUT_NOTIFICATION_SEEN:
      // TODO: update seen count based on the number of true responses
      notifications = [...state.notifications]
      const seenCount =
        action.seen && action.seen.length ? action.seen.length : 0
      let updatedUnseen = state.unseen - seenCount
      if (updatedUnseen < 0) {
        updatedUnseen = 0
      }
      if (action.seen) {
        for (const uuid of action.seen) {
          const notice = notifications.find(notice => notice.id === uuid)
          if (notice !== undefined) {
            notice.seen = true
            notice.attributes.seen_at = now
          }
        }
      }

      return {
        ...state,
        notifications,
        unseen: updatedUnseen,
      }
    case PUT_NOTIFICATION_SEEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    case PUT_NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map(n => {
          if (n.id === action.id) {
            n.read = true
            n.attributes.read_at = now.toISOString()
          }
          return n
        }),
      }
    case PUT_NOTIFICATION_READ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    case PUT_ALL_NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(n => {
          n.read = true
          n.attributes.read_at = now
          return n
        }),
      }
    case PUT_ALL_NOTIFICATIONS_READ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    case PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS:
      return {
        ...state,
        unseen: 0,
      }
    case PUT_ALL_NOTIFICATIONS_SEEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    case ADD_NOTIFICATION:
      notifications = uniqBy(
        [action.notification, ...state.notifications],
        n => n.id
      )
      return {
        ...state,
        notifications,
        unseen: state.unseen + 1,
      }
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    default:
      return state
  }
}
