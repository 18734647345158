import {
  GET_VIDEO_FAILURE,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
} from '../actions/types'

const initialState = {
  isFetching: false,
  videos: [],
}

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEO_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_VIDEO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_VIDEO_SUCCESS: {
      // If the current video is in the state, remove it before pushing the video. We want the latest version of the video because some things, such as ad_config, can change when the logged-in-user changes.
      // console.log('action.video (from fetchVideo)', action.video)
      const videos = state.videos.filter(
        stateVideo => stateVideo.id !== action.video.id
      )
      videos.push(action.video)
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        videos,
      }
    }
    default:
      return state
  }
}

export default videoReducer
