const initialState = { list: [], pagination: {}, creating: false }

export default (state = initialState, { type, pagination, data: list }) => {
  switch (type) {
    case 'communityApp/subCommunity/related_RESP':
      return {
        ...state,
        pagination,
        list,
      }
    case 'communityApp/subCommunity/createRelation_REQ':
      return {
        ...state,
        creating: true,
      }
    // TODO also handle errors
    case 'communityApp/subCommunity/createRelation_RESP':
      return {
        ...state,
        creating: false,
        // list is actually a single item here, the relation just created
        list: [...state.list, list],
      }
    default:
      return state
  }
}
