import React from 'react'

import appleIcon from 'common/assets/icon-apple-app-store.png'
import googleIcon from 'common/assets/icon-google-play.png'
import rssIcon from 'common/components/Buttons/RssButton/assets/rss-icon@2x.png'
import ExternalLink from 'common/components/ExternalLink'
import RssButtonWrapper from 'common/containers/RssButtonWrapper'

import './styles.scss'

const MobileAppIcons = () => (
  <>
    <ExternalLink
      aria-label='Google Store app'
      className='mobile-apps__link qa-mobile-apps-android'
      href='https://play.google.com/store/apps/details?id=com.roosterteeth.roosterteeth'
    >
      <img
        alt='Google Store icon'
        className='mobile-apps__image'
        src={googleIcon}
      />
    </ExternalLink>
    <ExternalLink
      aria-label='Apple Store app'
      className='mobile-apps__link qa-mobile-apps-apple'
      href='https://itunes.apple.com/us/app/rooster-teeth/id1012562632'
    >
      <img
        alt='Apple Store icon'
        className='mobile-apps__image'
        src={appleIcon}
      />
    </ExternalLink>
    <RssButtonWrapper classname='mobile-apps__link'>
      <img alt='RSS icon' className='mobile-apps__image' src={rssIcon} />
    </RssButtonWrapper>
  </>
)

export default MobileAppIcons
