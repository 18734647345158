import React from 'react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

import { swapCommunityAPIForCDN } from 'common/helpers'

import * as Styled from './styled'

const CommunityCard = ({ className, community }) => {
  let backgroundImage

  if (community.header) {
    backgroundImage = swapCommunityAPIForCDN(
      community.header,
      'c_fill,w_256,h_128,f_auto,q_auto:best'
    )
  }

  return (
    <CommunityCard.Base
      className={className}
      to={`/g/${community.slug.toLowerCase()}`}
    >
      <CommunityCard.Banner src={backgroundImage} />
      <CommunityCard.Avatar border={2} size={80} src={community.logo} />

      <CommunityCard.Content>
        <CommunityCard.Name>{community.name}</CommunityCard.Name>

        {community.metadata?.memberships?.count > 0 && (
          <CommunityCard.Members>
            {pluralize('Member', community.metadata.memberships.count, true)}
          </CommunityCard.Members>
        )}
      </CommunityCard.Content>
    </CommunityCard.Base>
  )
}

CommunityCard.propTypes = {
  className: PropTypes.string,
  community: PropTypes.object.isRequired,
}

CommunityCard.defaultProps = {
  className: '',
}

CommunityCard.Avatar = Styled.CommunityCardAvatar
CommunityCard.Banner = Styled.CommunityCardBanner
CommunityCard.Base = Styled.CommunityCardBase
CommunityCard.Content = Styled.CommunityCardContent
CommunityCard.Members = Styled.CommunityCardMembers
CommunityCard.Name = Styled.CommunityCardName

export default CommunityCard
