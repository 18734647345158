import React from 'react'
import PropTypes from 'prop-types'

import { PrimaryBtn } from 'common/components'

import './styles.scss'

const EmptyMan = ({
  btnLink = '',
  btnText = '',
  heading = '',
  imgSrc = '',
  showBtn = true,
  text = '',
}) => (
  <div className='empty-man'>
    {imgSrc ? (
      <img alt='empty' src={imgSrc} />
    ) : (
      <div className='empty-man__shrug'>¯\_(ツ)_/¯</div>
    )}
    <div className='empty-man__heading'>
      {heading || 'Nothing to see Here...'}
    </div>
    <div className='empty-man__text'>
      {text || `Sorry! We couldn't find what you were looking for.`}
    </div>
    {showBtn && (
      <div className='empty-man__button'>
        <PrimaryBtn linkTo={btnLink || '/'}>{btnText || 'Go Home'}</PrimaryBtn>
      </div>
    )}
  </div>
)

EmptyMan.propTypes = {
  btnLink: PropTypes.string,
  btnText: PropTypes.string,
  heading: PropTypes.string,
  imgSrc: PropTypes.string,
  showBtn: PropTypes.bool,
  text: PropTypes.string,
}

export default EmptyMan
