import styled, { css } from 'styled-components'

import { btnReset, tabFocus } from 'common/styles/styled-mixins'
import scssVariables from 'common/styles/variables.scss'

export const InlineLikeIcons = styled.span`
  margin-right: 5px;
  opacity: 0.8;
  position: relative;
  transition-duration: 0.2s;
  transition-property: color, opacity;
  transition-timing-function: ease-in-out;

  ${({ isLiked }) => {
    if (isLiked) {
      return css`
        color: ${scssVariables.red};
        opacity: 1;
      `
    }
  }}
`

export const InlineLikeIconEmpty = styled.i.attrs({
  className: 'icon icon-heart_empty',
})`
  left: 0;
  position: absolute;
  top: 0;
`

export const InlineLikeIconFilled = styled.i.attrs({
  className: 'icon icon-heart_filled',
})`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out;

  ${({ isLiked }) => {
    if (isLiked) {
      return css`
        opacity: 1;
      `
    }
  }}
`

export const InlineLikeText = styled.span`
  padding-left: 26px;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
`

export const InlineLikeBase = styled.button.attrs({
  type: 'button',
})`
  ${btnReset}
  ${tabFocus}

  &:hover {
    ${InlineLikeIconFilled},
    ${InlineLikeIcons},
    ${InlineLikeText} {
      opacity: 1;
    }
  }
`
