const initialState = {
  mutedStatus: null,
  muted: [],
}

let muted
export default (state = initialState, { type, resp }) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/MUTE_REQ':
      return {
        ...state,
        mutedStatus: null,
      }
    case 'communityApp/MUTE_RESP':
      const mutes = resp.muted
      muted = { ...state.muted }
      mutes.forEach(muteAction => {
        muted[muteAction['destination_id']] = true
      })
      return {
        ...state,
        muted,
        mutedStatus: true,
      }
    case 'communityApp/UNMUTE_REQ':
      return {
        ...state,
        mutedStatus: null,
      }
    case 'communityApp/UNMUTE_RESP':
      const unmutes = resp.unmuted
      muted = { ...state.muted }
      unmutes.forEach(unmute => {
        muted[unmute['destination_id']] = false
      })
      return {
        ...state,
        muted,
        mutedStatus: true,
      }
    case 'communityApp/MY_MUTED_RESP':
      return {
        ...state,
        muted: resp.muted,
      }
    default:
      return state
  }
}
