import authenticateAppleUser from 'common/helpers/authenticate-apple-user'
import authenticateFacebookUser from 'common/helpers/authenticate-facebook-user'
import authenticateGoogleUser from 'common/helpers/authenticate-google-user'

const getAuthenticateFuncFromIdp = serviceId => {
  switch (serviceId) {
    case 'google':
      return () => authenticateGoogleUser({ scope: 'profile email' })
    case 'apple':
      return authenticateAppleUser
    case 'facebook':
      return authenticateFacebookUser
  }
}

export default getAuthenticateFuncFromIdp
