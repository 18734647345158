/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React from 'react'
import classnames from 'classnames'

import './styles.scss'

const BtnBase = React.forwardRef(
  ({ children, className, linkTo, pill, ...props }, ref) => (
    <button
      className={classnames('btn waves-effect waves-light', className, {
        'btn--pill': pill,
      })}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  )
)

export default BtnBase
