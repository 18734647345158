import React, { Component } from 'react'
import getCaret from 'caret-position2/get'
import setCaret from 'caret-position2/set'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { ChatMentionList } from 'common/components'
import { isUndef } from 'common/helpers'

import './styles.scss'

// https://github.com/facebook/react/issues/10135#issuecomment-314441175
// react breaks native dispatch event
function setNativeValue(element, value) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
  const prototype = Object.getPrototypeOf(element)
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value'
  ).set

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value)
  } else {
    valueSetter.call(element, value)
  }
}

export default class ChatInput extends Component {
  static propTypes = {
    // string of copy to show before content, but leave uneditable
    allowEmpty: PropTypes.bool,
    enableMentions: PropTypes.bool,
    focusIn: PropTypes.bool,
    focusOnSend: PropTypes.bool,
    // prop indicates whether an action is currently pending
    // disables input and enables indicator
    inProgress: PropTypes.bool,
    lockedValue: PropTypes.string,
    maxLength: PropTypes.number,
    mentionList: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    sendMsg: PropTypes.func.isRequired,
    showSpoilers: PropTypes.bool,
    showLogin: PropTypes.func.isRequired,
    showVerify: PropTypes.func,
    submitOnEnter: PropTypes.bool,
    user: PropTypes.object,
    value: PropTypes.string,
  }

  static defaultProps = {
    allowEmpty: false,
    enableMentions: false,
    focusIn: false,
    focusOnSend: true,
    inProgress: false,
    lockedValue: '',
    maxLength: 5000,
    mentionList: [],
    onBlur: () => null,
    onChange: () => null,
    onEnter: null,
    onFocus: () => null,
    showSpoilers: null,
    showVerify: () => null,
    submitOnEnter: true,
    user: {},
    value: '',
  }

  lockedValueRef = React.createRef()

  // ----------
  // Lifecycle
  // ----------

  constructor(props) {
    super(props)

    this.state = {
      emojiSheet: null,
      Picker: false,
      selectedMention: '',
      selectedMentionIndex: 0,
      showEmojiPicker: false,
      showMentions: false,
      value: this.props.value || '',
    }
    this.chatInput = React.createRef()
    this.emojiRef = React.createRef()
    this.emojiIconRef = React.createRef()
    this.mentionsRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, {
      capture: true,
    })
    import('common/assets/sheet_apple_64.png').then(eS =>
      this.setState({ emojiSheet: eS })
    )
    if (this.props.focusIn) {
      setTimeout(() => {
        this.chatInput.current.focus()
      }, 300)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { mentionList } = this.props
    if (nextProps.enableMentions && mentionList !== nextProps.mentionList) {
      const selectedMention = nextProps.mentionList
        ? nextProps.mentionList[0]
        : ''
      this.setState({
        selectedMention,
        selectedMentionIndex: 0,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.enableMentions &&
      this.state.value !== prevState.value &&
      this.state.showMentions
    ) {
      this.handleMention()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  // ----------
  // Methods
  // ----------
  handleChange = event => {
    // Have to capture the value in state so we can add emojis and mentions to the textarea
    const isTypingMention =
      this.isTypingMention(event.target.value) && this.props.enableMentions
    if (event.target.value === '' && this.props.enableMentions) {
      this.resetMentions()
    }
    this.setState({ value: event.target.value, showMentions: isTypingMention })
    const { onChange } = this.props
    if (onChange) {
      onChange(event)
    }

    // Force rerender in iOS https://stackoverflow.com/a/3485654/2617437
    /* eslint-disable */
    this.chatInput.current.style.display = 'none'
    this.chatInput.current.offsetHeight
    this.chatInput.current.style.display = ''
    /* eslint-enable */
  }

  handleKeyDown = event => {
    if (
      this.props.submitOnEnter &&
      event.key === 'Enter' &&
      !this.state.showMentions
    ) {
      event.preventDefault()
      if (this.props.onEnter) {
        this.props.onEnter()
      } else {
        this.handleSubmit(event)
      }
    } else if (this.state.showMentions) {
      this.handleMentionKey(event)
    }
  }

  handleSubmit = (event = null) => {
    const { user, focusOnSend, allowEmpty } = this.props
    const text = this.buildValue()
    // if they press shift + enter then show newline, only on input box.
    if (event && event.shiftKey) {
      const selection = getCaret(this.chatInput.current)
      const { value } = this.state
      this.setState(
        {
          value: `${value.slice(0, selection.caret)}\n${value.slice(
            selection.caret,
            value.length
          )}`,
        },
        () => {
          // react resets the position of the caret after we set the new value,
          // manually resetting the caret position after the update
          this.setCaretPosition(selection.caret + 1)
        }
      )
      return
    }

    // if we don't currently have a user then we should prompt them to login
    if (user === null) {
      this.props.showLogin()
      return
    }
    if (user && !user.attributes?.email_verified) {
      this.props.showVerify()
      return
    }
    if (text === '' && !allowEmpty) {
      return
    }
    this.props.sendMsg(text)
    // if its a reply, the component will unmount, don't run these
    if (!this.props.isReply) {
      this.setState({ value: '', showEmojiPicker: false, showMentions: false })
      if (focusOnSend) {
        this.chatInput.current.focus()
      }
    }
  }

  // Emoji functions

  toggleEmojiPicker = event => {
    event.stopPropagation()
    event.preventDefault()
    if (this.state.showEmojiPicker) {
      this.setState({ showEmojiPicker: false })
    } else {
      import('emoji-mart').then(({ Picker }) => {
        this.setState({ Picker })
      })
      this.setState({ showEmojiPicker: true })
    }
  }

  setEmoji = emoji => {
    const caret = getCaret(this.chatInput.current).caret
    const { value } = this.state
    const newValue = value.slice(0, caret) + emoji.native + value.slice(caret)

    // Capture what is currently in the input and attach the clicked emoji to it
    setNativeValue(this.chatInput.current, newValue)
    this.chatInput.current.dispatchEvent(new Event('input', { bubbles: true }))
    this.chatInput.current.focus()
    // some emojis count as having more than one character :P
    this.setCaretPosition(caret + emoji.native.length)
  }

  // Mention Functions

  fillMention = name => {
    name = name || this.state.selectedMention
    if (isUndef(name)) {
      this.setState({ showMentions: false })
      return
    }
    const mentionStart = this.getMentionStartIndex()
    const beforeMention = this.state.value.substring(0, mentionStart)
    let afterMention = this.state.value.substring(
      getCaret(this.chatInput.current).caret
    )
    // if we are at the end of the input, add a space
    if (afterMention.length === 0) {
      afterMention = ' '
    }
    const newString = `${beforeMention}@${name}${afterMention}`
    this.setState({ value: newString }, () => {
      if (afterMention === ' ') {
        this.chatInput.current.focus()
      } else {
        this.setCaretPosition(`${beforeMention}@${name}`.length)
      }
      // synthetically triggering onChange event, as React 16+ doesn't trigger
      // onChange events when update state
      this.handleChange({ target: { value: newString } })
    })
    this.resetMentions()
  }

  getMentionStartIndex = () => {
    let index = this.state.value.lastIndexOf(' @') + 1
    const caretPos = getCaret(this.chatInput.current).caret
    if (caretPos !== this.state.value.length) {
      index = this.state.value.slice(0, caretPos).lastIndexOf(' @') + 1
    }
    return index
  }

  handleMention = () => {
    const currentWord = this.state.value.substring(
      this.getMentionStartIndex(),
      getCaret(this.chatInput.current).caret
    )
    this.matchMention(currentWord.substr(1))
  }

  handleMentionKey = event => {
    // special keys have the following affect on mentions:
    // Tab/Enter: fill textarea with currently selected username
    // ArrowUp/ArrowDown: select previous/next username in the currently matched list
    // Escape: close mentions dialog
    // Space: close mentions dialog and reset mentions state
    let currentList = []
    let newIndex = 0
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        event.preventDefault()
        this.fillMention()
        break
      case 'ArrowUp':
        event.preventDefault()
        currentList = this.props.mentionList
        newIndex = this.state.selectedMentionIndex - 1
        if (newIndex < 0) {
          newIndex = currentList.length - 1
        }
        this.setState({
          selectedMention: currentList[newIndex],
          selectedMentionIndex: newIndex,
        })
        break
      case 'ArrowDown':
        event.preventDefault()
        currentList = this.props.mentionList
        newIndex = this.state.selectedMentionIndex + 1
        if (newIndex === currentList.length) {
          newIndex = 0
        }
        this.setState({
          selectedMention: currentList[newIndex],
          selectedMentionIndex: newIndex,
        })
        break
      case ' ':
        this.resetMentions()
        break
      case 'Escape':
        event.preventDefault()
        event.stopPropagation()
        this.setState({ showMentions: false })
        break
      default:
        break
    }
  }

  isTypingMention = value => {
    let words = value.split(' ')
    // check if they clicked back on a mention not the last word
    if (getCaret(this.chatInput.current).caret !== value.length) {
      words = value.slice(0, getCaret(this.chatInput.current).caret).split(' ')
    }
    // return if last word is a mention
    return words[words.length - 1][0] === '@'
  }

  matchMention = name => {
    if (!/^[\w-.]+$/.test(name)) {
      this.resetMentions()
      return false
    }
    this.props.getMentionList(name)
  }

  resetMentions = () => {
    if (!this.props.enableMentions) {
      return
    }
    this.props.getMentionList()
    this.setState({
      selectedMention: '',
      showMentions: false,
    })
  }

  handleClickOutside = event => {
    let domNode = null

    if (this.state.showMentions) {
      domNode = this.mentionsRef.current
      if (!domNode || !domNode.contains(event.target)) {
        this.setState({ showMentions: false })
        this.resetMentions()
      }
    }

    if (this.state.showEmojiPicker) {
      domNode = this.emojiRef.current
      if (
        !domNode ||
        (!domNode.contains(event.target) &&
          !this.emojiIconRef?.current.contains(event.target))
      ) {
        this.setState({ showEmojiPicker: false })
      }
    }
  }

  /**
   * Sets position of user caret in input area
   * @param {Number} position
   */
  setCaretPosition = (position = this.chatInput.current.value.length) => {
    setCaret(this.chatInput.current, position)
  }

  buildValue = () => {
    const lockedValue = this.props.lockedValue || ''
    const text = this.state.value.substring(0, this.props.maxLength).trim()
    return lockedValue + text
  }

  /**
   * Calculates offset based on our prop.lockedValue
   * @return {Object}
   */
  calcOffset = () => {
    const { lockedValue } = this.props
    const styles = {}

    if (lockedValue && this.lockedValueRef.current) {
      const width = this.lockedValueRef.current.offsetWidth

      styles.textIndent = `calc(${width}px + 5px)`
    }

    return styles
  }

  reset = () => {
    this.setState({ value: '' })
  }

  render() {
    const {
      enableMentions,
      focusIn,
      inProgress,
      lockedValue,
      maxLength,
      mentionList,
      onBlur,
      onFocus,
      placeholder,
      showSpoilers,
      textareaClassName,
    } = this.props

    const {
      emojiSheet,
      Picker,
      selectedMention,
      showEmojiPicker,
      showMentions,
      value,
    } = this.state

    const textareaClasses = textareaClassName || 'materialize-textarea'

    return (
      <div>
        {showEmojiPicker && (
          <div className='chat-emoji-wrapper' ref={this.emojiRef}>
            {Picker && (
              <Picker
                perLine={9}
                color='#06add0'
                emojiSize={18}
                native
                backgroundImageFn={_apple => emojiSheet}
                title='RT-Moji'
                emoji=':fire:'
                onClick={this.setEmoji}
              />
            )}
          </div>
        )}
        {/* Materialize textaarea repaints whole screen and needs to be fixed.
            Issue report here: https://github.com/Dogfalo/materialize/issues/4091 */}
        <div className='chat-area'>
          {lockedValue && (
            <div className='chat-input__locked-value' ref={this.lockedValueRef}>
              {lockedValue}
            </div>
          )}
          {enableMentions && showMentions && mentionList && (
            <div ref={this.mentionsRef}>
              <ChatMentionList
                fillMention={() => this.fillMention()}
                mentionList={mentionList}
                selectedMention={selectedMention}
                setMention={obj => this.setState(obj)}
              />
            </div>
          )}
          <textarea
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={focusIn}
            className={classnames('qa-chatinput-textarea', textareaClasses)}
            disabled={!showSpoilers || inProgress}
            id='chat-text'
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={this.handleChange}
            onFocus={onFocus}
            onKeyDown={this.handleKeyDown}
            placeholder={placeholder}
            ref={this.chatInput}
            rows='5'
            style={this.calcOffset()}
            value={value}
          />
          <a
            aria-pressed={showEmojiPicker}
            className={`chat-emoji-icon ${!showSpoilers ? 'disabled' : ''}`}
            href='#emoji-icons'
            onClick={this.toggleEmojiPicker}
            ref={this.emojiIconRef}
            role='button'
          >
            <i className='icon-sentiment_very_satisfied' />
            <span className='visuallyhidden'>Emoji menu</span>
          </a>
          {inProgress ? (
            <div className='progress'>
              <div className='indeterminate' />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}
