import React, { Component } from 'react'
import classnames from 'classnames'
import { Collapsible } from 'materialize-css'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'

import ExternalLink from 'common/components/ExternalLink'
import { trackClick } from 'common/metrics'

import CommunitySideNav from 'communityApp/containers/side-nav'
import SnowflakesEasterEggButton from 'rootApp/components/SnowflakesEasterEgg'

import SideNavChannels from './SideNavChannels'
import SideNavMyStuff from './SideNavMyStuff'
import SideNavStore from './SideNavStore'

import './styles.scss'

class SideNavMenu extends Component {
  static propTypes = {
    channels: PropTypes.array,
    isAuthenticated: PropTypes.bool.isRequired,
    isLiveEventOn: PropTypes.bool,
    path: PropTypes.string.isRequired,
  }

  channelsRef = React.createRef()

  myStuffRef = React.createRef()

  channelsSubnav = null

  myStuffSubnav = null

  componentDidMount() {
    this.channelsSubnav = Collapsible.init(this.channelsRef.current)
    if (this.props.isAuthenticated) {
      this.myStuffSubnav = Collapsible.init(this.myStuffRef.current)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.myStuffSubnav = Collapsible.init(this.myStuffRef.current)
    }
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.myStuffSubnav.destroy()
    }
  }

  collapseChannels = () => {
    this.channelsSubnav.close()
  }

  collapseMyStuff = () => {
    this.myStuffSubnav && this.myStuffSubnav.close()
  }

  handleDropdownClick = ({ label, options }) => {
    this.trackAndCollapse(label, options)
  }

  trackAndCollapse = (label, options) => {
    // Prevent :focus from staying on an <a> when clicked, for shared sections (episodes and first-exclusives) and external links (e.g., RTX)
    this.clearNavFocus()

    trackClick(label, options)
    this.collapseChannels()
    this.collapseMyStuff()
  }

  clearNavFocus = () => document?.activeElement?.blur()

  setFocusSeries = (path, searchPathToExclude) =>
    path === '/series' &&
    !searchPathToExclude.includes('?genres=Podcasts&order=desc')

  render() {
    const {
      channels,
      featureFlags,
      isAuthenticated,
      isLiveEventOn,
      path,
      searchPath,
    } = this.props

    const waveClass = `waves-effect waves-brand`

    return (
      <ul className='side-nav-menu qa-side-nav-menu'>
        <li className='side-nav-menu__item'>
          <ul
            className='side-nav-menu__collapsible collapsible collapsible-accordion'
            ref={this.channelsRef}
          >
            <li className='side-nav-menu__item'>
              <Link
                className={classnames(
                  'side-nav-menu__link side-nav-menu__collapsible-toggle collapsible-header',
                  waveClass
                )}
                onClick={e => {
                  e.preventDefault()
                  trackClick('nav_channels_dropdown')
                }}
                to='#channels'
              >
                Channels
              </Link>

              <div className='side-nav-menu__subnav collapsible-body'>
                <SideNavChannels
                  channels={channels}
                  onChannelClick={this.handleDropdownClick}
                />
              </div>
            </li>
          </ul>
        </li>

        <li className='side-nav-menu__item'>
          <NavLink
            className={classnames('side-nav-menu__link', waveClass)}
            isActive={() => this.setFocusSeries(path, searchPath)}
            onClick={() => this.trackAndCollapse('nav_series')}
            to={{ pathname: '/series', search: '?order=desc' }}
          >
            {featureFlags.showsV2 ? 'Shows' : 'Series'}
          </NavLink>
        </li>

        <li className='side-nav-menu__item'>
          <NavLink
            className={classnames('side-nav-menu__link', waveClass)}
            onClick={() => this.trackAndCollapse('nav_episodes')}
            to='/episodes'
          >
            Episodes
          </NavLink>
        </li>

        <li className='side-nav-menu__item'>
          <NavLink
            className={classnames('side-nav-menu__link', waveClass)}
            onClick={() => this.trackAndCollapse('nav_live')}
            to='/live/rt-tv'
          >
            {isLiveEventOn ? (
              <span className='side-nav-menu__live qa-side-nav-live'>
                Live <i className='icon-live_now' />
              </span>
            ) : (
              'Live'
            )}
          </NavLink>
        </li>

        <li className='side-nav-menu__item'>
          <NavLink
            className={classnames('side-nav-menu__link', waveClass)}
            onClick={() => this.trackAndCollapse('nav_schedule')}
            to='/schedule'
          >
            Schedule
          </NavLink>
        </li>

        {isAuthenticated && (
          <li className='side-nav-menu__item'>
            <ul
              className='side-nav-menu__collapsible collapsible collapsible-accordion'
              ref={this.myStuffRef}
            >
              <li className='side-nav-menu__item'>
                <Link
                  className={classnames(
                    'side-nav-menu__link side-nav-menu__collapsible-toggle collapsible-header',
                    'qa-side-nav-menu-my-stuff',
                    waveClass
                  )}
                  onClick={e => {
                    e.preventDefault()
                    trackClick('nav_myStuff_dropdown')
                  }}
                  to='/mystuff'
                >
                  My Stuff
                </Link>

                <div className='side-nav-menu__subnav collapsible-body'>
                  <SideNavMyStuff onClick={this.handleDropdownClick} />
                </div>
              </li>
            </ul>
          </li>
        )}

        <li className='side-nav-menu__item'>
          <NavLink
            className={classnames(
              'side-nav-menu__link',
              'qa-side-nav-menu-new',
              waveClass
            )}
            onClick={() => this.trackAndCollapse('nav_community_new')}
            to='/g'
          >
            Community
          </NavLink>

          {(path.startsWith('/g/') || path === '/g') && (
            <CommunitySideNav className='qa-side-nav-menu-community-nav' />
          )}
        </li>

        <SideNavStore
          isAuthenticated={isAuthenticated}
          onClick={() => this.trackAndCollapse('nav_store')}
        />

        <li className='side-nav-menu__item'>
          <ExternalLink
            className={classnames('side-nav-menu__link', waveClass)}
            href='https://devices.roosterteeth.com/'
            onClick={() => this.trackAndCollapse('nav_devices')}
          >
            Devices
          </ExternalLink>
        </li>

        {!!this.props?.featureFlags?.holidayEasterEgg && (
          <SnowflakesEasterEggButton
            element={({ falling, toggleFalling }) => (
              <li className='side-nav-menu__item'>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={classnames('side-nav-menu__link', waveClass)}
                  onClick={toggleFalling}
                  onKeyPress={toggleFalling}
                  tabIndex={0}
                  role='button'
                >
                  {falling ? 'Stop snowing' : 'Let it snow'}{' '}
                  <span role='img' aria-label='Snowflake'>
                    ❄️
                  </span>
                </a>
              </li>
            )}
          />
        )}
      </ul>
    )
  }
}

export default SideNavMenu
