import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'

import { fetchEpisode } from 'episodeApp/actions'

import './App.scss'

const VideoContainer = React.lazy(() =>
  import(// eslint-disable-next-line import/extensions
  '/common/containers/VideoContainer')
)

class EmbeddableVideo extends Component {
  componentDidMount() {
    const { dispatch, slug } = this.props
    dispatch(fetchEpisode(slug))
    document.body.id = 'episode-page'
  }

  render() {
    const { dispatch, episode, slug, url, user } = this.props
    const autoplay =
      new URL(window.location.href).searchParams.get('autoplay') === '1'

    const isEmpty = episode === undefined
    return isEmpty ? (
      ''
    ) : (
      <Suspense fallback={<div />}>
        <VideoContainer
          episode={episode}
          dispatch={dispatch}
          user={user}
          slug={slug}
          url={url}
          autoPlay={autoplay}
          allowChromecast={false}
          advanceToNextVideo={false}
          openLinksInNewTab
        />
      </Suspense>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params, url } = ownProps.match
  const { slug } = params
  const { user } = state.authReducer

  const episode = state.episodeReducer.episode.episodes.find(episode => {
    if (Object.keys(episode).length !== 0) {
      return episode.attributes.slug === slug
    }
    return false
  })

  return {
    episode,
    slug,
    url,
    user,
  }
}

export default connect(mapStateToProps)(EmbeddableVideo)
