import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import logoSvg from 'common/assets/logo-rooster-teeth-white.svg'
import closeIcon from 'common/assets/nav-close.svg'
import {
  ExternalLink,
  MobileAppIcons,
  SecondaryBtn,
  TertiaryBtn,
} from 'common/components'
import DiscordLink from 'common/containers/DiscordLink'
import { a11yButtonProps } from 'common/helpers/accessibility'
import { trackClick, trackSignupButtonClick } from 'common/metrics'

import { closeAllAccordions, useHandleAccordionClick } from './accordions'

import './styles.scss'

const DiscordNavLink = () => (
  <DiscordLink>
    {({ discordHref, trackDiscordClick }) => (
      <li>
        <ExternalLink href={discordHref} onClick={trackDiscordClick}>
          Discord
        </ExternalLink>
      </li>
    )}
  </DiscordLink>
)

const MobileOverlayNav = ({
  channels,
  closeNav,
  featureFlags,
  isAuthenticated,
  isLiveEventOn,
  isOpen,
}) => {
  // On mount, add the left-closed class to the root so that the marketing banner behaves properly.
  useEffect(() => {
    document.getElementById('root')?.classList.add('left-closed')
  }, [])

  const collapseAccordionsAndClose = useCallback(
    () => {
      closeAllAccordions()
      closeNav()
    },
    [closeNav]
  )

  const trackAndClose = useCallback(
    (label, dataEventOptions) => {
      trackClick(label, dataEventOptions)
      collapseAccordionsAndClose()
    },
    [collapseAccordionsAndClose]
  )

  const handleLoginClick = useCallback(
    () => {
      trackClick('mobile_nav_log_in')
      closeNav()
    },
    [closeNav]
  )

  const handleSignupClick = useCallback(() => {
    trackClick('nav_sign_up')
    trackSignupButtonClick('mobile_nav')
  }, [])

  const handleAccordionHeaderClick = useHandleAccordionClick()

  return (
    <>
      <div
        {...a11yButtonProps(collapseAccordionsAndClose)}
        className={`mobile-overlay-nav__close-icon-outer ${
          isOpen ? 'mobile-overlay-nav__close-icon-outer--open' : ''
        }`}
      >
        <img src={closeIcon} alt='Close icon' />
      </div>
      <div
        className={`mobile-overlay-nav ${
          isOpen ? 'mobile-overlay-nav--open' : ''
        }`}
      >
        <div className='mobile-overlay-nav__logo-container'>
          <Link
            aria-label='Home'
            onClick={() => trackAndClose('mobile_nav_home_button')}
            role='button'
            to='/'
          >
            <img src={logoSvg} alt='Rooster Teeth Logo' />
          </Link>
        </div>
        <div className='mobile-overlay-nav__menu'>
          <ul className='mobile-overlay-nav__items' data-nav-accordions-parent>
            <li className='mobile-overlay-nav__accordion' data-nav-accordion>
              <span
                {...a11yButtonProps(e =>
                  handleAccordionHeaderClick(e, 'nav_channels_dropdown')
                )}
                className='mobile-overlay-nav__accordion-header'
              >
                Channels
              </span>
              <ul className='mobile-overlay-nav__accordion-contents'>
                {channels?.map(channel => (
                  <MobileOverlayNavChannel
                    channel={channel}
                    trackAndClose={trackAndClose}
                  />
                ))}
              </ul>
            </li>
            <li>
              <Link
                onClick={() => trackAndClose('nav_series')}
                to={{ pathname: '/series', search: '?order=desc' }}
              >
                {featureFlags.showsV2 ? 'Shows' : 'Series'}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => trackAndClose('nav_episodes')}
                to='/episodes'
              >
                Episodes
              </Link>
            </li>
            <li>
              <Link onClick={() => trackAndClose('nav_live')} to='/live/rt-tv'>
                {isLiveEventOn ? (
                  <span className='mobile-overlay-nav__live-span'>
                    Live <i className='icon-live_now' />
                  </span>
                ) : (
                  'Live'
                )}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => trackAndClose('nav_schedule')}
                to='/schedule'
              >
                Schedule
              </Link>
            </li>
            {isAuthenticated ? (
              <li className='mobile-overlay-nav__accordion' data-nav-accordion>
                <span
                  {...a11yButtonProps(e =>
                    handleAccordionHeaderClick(e, 'nav_myStuff_dropdown')
                  )}
                  className='mobile-overlay-nav__accordion-header'
                >
                  My Stuff
                </span>
                <ul className='mobile-overlay-nav__accordion-contents'>
                  <li>
                    <Link
                      onClick={() => {
                        trackAndClose('nav-my-stuff', {
                          context: 'my-playlists',
                        })
                      }}
                      to='/mystuff#my-playlists'
                    >
                      My Playlists
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        trackAndClose('nav-my-stuff', { context: 'discover' })
                      }}
                      to='/mystuff#discover'
                    >
                      Discover
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        trackAndClose('nav-my-stuff', {
                          context: 'followed-playlists',
                        })
                      }}
                      to='/mystuff#followed-playlists'
                    >
                      Followed Playlists
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        trackAndClose('nav-my-stuff', {
                          context: 'followed-series',
                        })
                      }}
                      to='/mystuff#followed-series'
                    >
                      Followed Series
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            <li className='mobile-overlay-nav__accordion' data-nav-accordion>
              <span
                {...a11yButtonProps(e =>
                  handleAccordionHeaderClick(e, 'nav_community_new')
                )}
                className='mobile-overlay-nav__accordion-header'
              >
                Community
              </span>
              <ul className='mobile-overlay-nav__accordion-contents'>
                <li>
                  <Link
                    onClick={() => {
                      trackAndClose('nav_community_new')
                    }}
                    exact
                    to='/g'
                  >
                    Feed
                  </Link>
                </li>
                <DiscordNavLink />
              </ul>
            </li>
            <li>
              <ExternalLink
                href='https://devices.roosterteeth.com/'
                onClick={() => trackAndClose('nav_devices')}
              >
                Devices
              </ExternalLink>
            </li>
          </ul>
          {!isAuthenticated && (
            <>
              <div className='mobile-overlay-nav__signup-button-container'>
                <SecondaryBtn
                  bg='dark'
                  linkTo='/signup'
                  onClick={handleSignupClick}
                >
                  Sign Up
                </SecondaryBtn>
              </div>
              <div className='mobile-overlay-nav__signup-button-container'>
                <TertiaryBtn
                  bg='dark'
                  linkTo='/login'
                  onClick={handleLoginClick}
                >
                  Log In
                </TertiaryBtn>
              </div>
            </>
          )}
          <div className='mobile-overlay-nav__mobile-app-icons-container'>
            <MobileAppIcons />
          </div>
        </div>
      </div>
    </>
  )
}

const MobileOverlayNavChannel = ({ channel, trackAndClose }) => {
  const {
    attributes: { name, slug },
    id,
  } = channel

  const channelPath = `/channel/${slug}`

  return (
    <li key={id}>
      <Link
        onClick={() => {
          trackAndClose('nav_channel', {
            context: channel.attributes.name,
          })
        }}
        to={channelPath}
      >
        {name}
      </Link>
    </li>
  )
}

export default MobileOverlayNav
