import {
  DELETE_FROM_LIKES_FAILURE,
  DELETE_FROM_LIKES_REQUEST,
  DELETE_FROM_LIKES_SUCCESS,
  GET_LIKES_EPISODE_DATA_FAILURE,
  GET_LIKES_EPISODE_DATA_REQUEST,
  GET_LIKES_EPISODE_DATA_SUCCESS,
  PUT_ADD_TO_LIKES_FAILURE,
  PUT_ADD_TO_LIKES_REQUEST,
  PUT_ADD_TO_LIKES_SUCCESS,
} from '../actions/types'

const initialState = {
  itemUuids: [],
  isFetching: false,
  lastUpdated: null,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIKES_EPISODE_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_LIKES_EPISODE_DATA_SUCCESS:
      return parseInt(action.rating, 10) === 1
        ? {
            ...state,
            itemUuids: [action.uuid],
            lastUpdated: Date.now(),
            isFetching: false,
          }
        : {
            ...state,
            lastUpdated: Date.now(),
            isFetching: false,
          }
    case GET_LIKES_EPISODE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case PUT_ADD_TO_LIKES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_ADD_TO_LIKES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        itemUuids: [action.uuid],
      }
    case PUT_ADD_TO_LIKES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case DELETE_FROM_LIKES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case DELETE_FROM_LIKES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        itemUuids: state.itemUuids.filter(i => i !== action.removedUuid),
      }
    case DELETE_FROM_LIKES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    default:
      return state
  }
}
