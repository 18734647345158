import React, { useCallback, useMemo, useState } from 'react'

import RTXLogo from 'common/assets/rtx23_logo_light.png'
import LightLogo from 'common/assets/rtx23_logo_light-square.png'
import OrangeLogo from 'common/assets/rtx23_logo_orange-square.png'
import TravisImage from 'common/assets/rtx23_travis.png'
import WillieImage from 'common/assets/rtx23_willie.png'
import { Tooltip } from 'common/components'
import { trackEvent } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const useHelpers = () =>
  useMemo(() => {
    const trackClick = () => trackEvent('RTX23 Easter Egg Clicked')

    const willieImageElem = new Image(509, 566)
    willieImageElem.src = WillieImage

    const travisImageElem = new Image(506, 603)
    travisImageElem.src = TravisImage

    const lightLogoImageElem = new Image(192, 192)
    lightLogoImageElem.src = LightLogo

    const orangeLogoImageElem = new Image(192, 192)
    orangeLogoImageElem.src = OrangeLogo

    function resetParticle(particle) {
      particle.size = 60
      particle.alpha = Math.random()
      const dice = Math.random()
      if (dice < 0.25) {
        particle.image = travisImageElem
      } else if (dice < 0.5) {
        particle.image = willieImageElem
      } else if (dice < 0.75) {
        particle.image = lightLogoImageElem
      } else {
        particle.image = orangeLogoImageElem
      }
    }

    function drawParticle(context, particle) {
      context.drawImage(
        particle.image,
        particle.x,
        particle.y,
        particle.size,
        particle.size
      )
    }

    return { trackClick, resetParticle, drawParticle }
  }, [])

const RTX2023EasterEgg = () => {
  const { trackClick, resetParticle, drawParticle } = useHelpers()
  const [showCharacters, setShowCharacters] = useState(false)
  const triggerCharacterAnimation = useCallback(() => {
    setShowCharacters(true)
    const timeoutId = setTimeout(() => setShowCharacters(false), 6000)
    return () => {
      setShowCharacters(false)
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      <img
        alt='An illustration of a handsome armadillo'
        className={`rtx-easter-egg-character rtx-easter-egg-character--left ${
          showCharacters ? 'rtx-easter-egg-character--visible' : ''
        }`}
        src={WillieImage}
      />
      <img
        alt='An illustration of a cute bat'
        className={`rtx-easter-egg-character rtx-easter-egg-character--right ${
          showCharacters ? 'rtx-easter-egg-character--visible' : ''
        }`}
        src={TravisImage}
      />
      <FallingParticlesEasterEggButton
        compression={1}
        drawParticle={drawParticle}
        particleCount={30}
        resetParticle={resetParticle}
        speed={1.2}
        startYOffset={150}
        stopAfter={100}
        element={({ toggleFalling, falling }) => (
          <Tooltip text='Get hyped for RTX'>
            <div
              className={`rtx-easter-egg-nav-button ${falling ? 'active' : ''}`}
              role='button'
              onClick={() => {
                trackClick()
                toggleFalling()
                if (!falling) triggerCharacterAnimation()
              }}
              onKeyPress={toggleFalling}
              tabIndex={0}
            >
              <img src={RTXLogo} alt='RTX 2023 Logo' />
            </div>
          </Tooltip>
        )}
      />
    </>
  )
}

export default RTX2023EasterEgg
