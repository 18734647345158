import React, { useMemo } from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'
import { useToggle } from 'common/hooks'

import './styles.scss'

const SiteVersionIndicator = () => {
  const [hidden, hide] = useToggle()
  const { isProduction, hex, titleText, gitRef } = useMemo(() => {
    const host = window.location.host
    const gitRef = window.rtSVODRevision
    let isProduction = true
    let hex
    let titleText

    if (host.match(/localhost/i)) {
      isProduction = false
      hex = '#c9373f'
      titleText = 'Local'
    } else if (host.match(/staging\./i)) {
      isProduction = false
      hex = '#34b22f'
      titleText = 'Staging'
    } else {
      const peMatches = host.match(/([a-zA-Z_-]+)\.pe\./i)
      if (peMatches) {
        const [, peName] = peMatches
        isProduction = false
        hex = '#4c67dc'
        titleText = `Personal Environment — ${peName}`
      }
    }

    return { isProduction, hex, titleText, gitRef }
  }, [])
  const style = useMemo(() => ({ backgroundColor: hex }), [hex])

  if (isProduction) return null
  if (hidden) return null

  return (
    <div style={style} className='rt-site-version-indicator'>
      <i
        className='rt-site-version-indicator__close icon-close2'
        {...a11yButtonProps(hide)}
      />
      &nbsp;
      <span className='rt-site-version-indicator__name'>{titleText}</span>
      {gitRef === 'undefined' ? null : (
        <span className='rt-site-version-indicator__git-ref'> — {gitRef}</span>
      )}
    </div>
  )
}

export default SiteVersionIndicator
