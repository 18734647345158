import { refreshTokenKey } from 'common/config'
import { getAuthHeaders } from 'common/headers'
import {
  getAccessToken,
  HTTP_RT_INVALID_UPSTREAM_API_TOKEN,
} from 'common/helpers'
import { initialState } from 'common/reducers/featureFlagReducer'

import { refreshTokenFetch } from 'auth/actions/clientActions'

import { getCookie } from './storage'

const initDefaults = {
  credentials: 'include',
  mode: 'cors',
}
// call this rather than fetch to have your fetch call automatically attempt to
// grab a new token in the event of a 401
export function authenticatedFetch(url, fetchOpts) {
  // Opt in to attempt to use auth headers by default (if a token is available)
  if (fetchOpts.defaultAuth && getAccessToken()) {
    fetchOpts.headers = { ...fetchOpts.headers, ...getAuthHeaders() }
  }
  // allow disabling of old auth for testing. Add ?ff_noLegacyAuth&sticky then
  // refresh the page
  if (initialState.noLegacyAuth) {
    fetchOpts = { ...initDefaults, ...fetchOpts }
    // console.log('fetchOpts.headers', fetchOpts.headers)
    // console.warn(
    //   'ff_noLegacyAuth is set, ?ff_noLegacyAuth=false&sticky to disable'
    // )
    if (fetchOpts.headers && fetchOpts.headers.Authorization) {
      delete fetchOpts.headers.Authorization
    }
  }

  if (getAccessToken() === null) {
    if (getCookie(refreshTokenKey)) {
      return refreshTokenFetch().then(r => {
        if (!r.ok) {
          return r
        }

        if (!initialState.noLegacyAuth) {
          Object.assign(fetchOpts.headers, getAuthHeaders())
        }

        return fetch(url, fetchOpts)
      })
    }

    // When using the new "doNotForceAuth" option, allow some requests to be made without authentication (get user profile, etc)
    if (!fetchOpts.doNotForceAuth) {
      // If we don't have either token, this error should trigger
      // a logout success (no need to hit BE)
      const err = new Error('Access Token does not exist.')
      err.status = HTTP_RT_INVALID_UPSTREAM_API_TOKEN
      return Promise.reject(err)
    }
  }

  return fetch(url, fetchOpts).then(r => {
    // some apis incorrectly return 403 rather than 401
    if (r.status === 401) {
      return refreshTokenFetch().then(r => {
        if (!r.ok) {
          return r
        }

        if (!initialState.noLegacyAuth) {
          Object.assign(fetchOpts.headers, getAuthHeaders())
        }

        return fetch(url, fetchOpts)
      })
    }
    return r
  })
}
