import Honeybadger from 'honeybadger-js'
import { call, put, select } from 'redux-saga/effects'

import getAuthenticateFuncFromIdp from 'common/helpers/getAuthenticateFuncFromIdp'
import getRtApiLoginFuncFromIdp from 'common/helpers/getRtApiLoginFuncFromIdp'
import processOAuthErrorResponse from 'common/helpers/processOAuthErrorResponse'

import {
  loginError3Party,
  loginRequest3Party,
  loginSuccess3Party,
} from 'auth/actions'
import trackRssSignupConversion from 'showsApp/sagas/trackRssSignupConversion'
import { set3PartySigninError } from 'signupApp/actions'
import {
  genericCreateAccountError,
  VIEW_CREATE_ACCOUNT,
} from 'signupApp/constants'
import { trackSignupFlowEvent } from 'signupApp/helpers'
import getUserObjectSaga from 'signupApp/sagas/getUserObjectSaga'
import { getCampaign } from 'signupApp/selectors'

function* attemptSignInWith3PartySaga(action) {
  const { serviceId } = action
  const authenticateFunc = getAuthenticateFuncFromIdp(serviceId)
  const authenticateAttemptResult = yield call(() =>
    authenticateFunc().catch(rawError => ({
      error: processOAuthErrorResponse(rawError),
    }))
  )

  if (authenticateAttemptResult?.token) {
    const loggedIn = yield call(loginUser3PartySaga, {
      serviceId,
      token: authenticateAttemptResult.token,
    })

    // Get the user object and allow the components to perform the redirect based on the current view and the logged in user.
    if (loggedIn) {
      const campaign = yield select(getCampaign)
      trackSignupFlowEvent(VIEW_CREATE_ACCOUNT, {
        campaign,
        option_selected: serviceId,
        state: 'exited',
        user_tier: 'anon',
      })

      yield call(trackRssSignupConversion)

      yield call(getUserObjectSaga)
    }

    return
  }

  // authenticateAttemptResult.error should be defined in this case (for example, the user closed the 3rd party login window), but have a fallback just in case.
  const error = authenticateAttemptResult?.error || genericCreateAccountError

  yield put(set3PartySigninError(error))
}

// Returns true if the user was logged in, returns false if the user was not logged in.
function* loginUser3PartySaga({ serviceId, token }) {
  yield put(loginRequest3Party())
  const rtApiLogin = getRtApiLoginFuncFromIdp(serviceId)

  const rtAuthResponse = yield call(() =>
    rtApiLogin(token).catch(rtAuthErrorResponse => ({
      error: rtAuthErrorResponse.message,
    }))
  )
  if (rtAuthResponse.user) {
    yield put(loginSuccess3Party(rtAuthResponse.user))

    return true
  }

  // If we got a token successfully from the IDP, then this login attempt shouldn't ever fail. But in case it does, provide the error message to the authReducer and signup v2 reducer.
  const error = rtAuthResponse.error || genericCreateAccountError
  Honeybadger.notify(
    'An error occured while trying to log a user in with a valid token.',
    { context: { identityProvider: serviceId } }
  )

  yield put(loginError3Party(error))
  yield put(set3PartySigninError(error))

  return false
}

export default attemptSignInWith3PartySaga
