import React, { Component } from 'react'
import Honeybadger from 'honeybadger-js'
import PropTypes from 'prop-types'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
    environment: PropTypes.string.isRequired,
    silent: PropTypes.bool,
  }

  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    environment: process.env.NODE_ENV,
    silent: false,
  }

  state = {
    error: null,
    hasError: false,
  }

  componentDidCatch(error, info) {
    const { environment } = this.props

    if (!(error instanceof Error)) {
      error = new Error(error)
      error.stack = info.componentStack
    }

    this.setState({ hasError: true, error })

    if (environment === 'production' || environment === 'staging') {
      Honeybadger.notify(error)
    }
  }

  render() {
    const { children, environment, silent } = this.props
    const { error, hasError } = this.state

    if (hasError) {
      if (silent || environment === 'production') {
        return null
      }

      return (
        <div>
          <h2>{error.message}</h2>
          <pre>{error.stack}</pre>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary
