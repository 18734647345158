import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ExternalLink from 'common/components/ExternalLink'

const LinkBase = React.forwardRef(
  ({ className, external, linkTo, ...props }, ref) => {
    const classes = classnames('btn waves-effect waves-light', className)

    return external ? (
      <ExternalLink className={classes} href={linkTo} ref={ref} {...props} />
    ) : (
      /* Forward ref not supported by React Router <Link> */
      <Link className={classes} to={linkTo} {...props} />
    )
  }
)

LinkBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  external: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
}

LinkBase.defaultProps = {
  className: '',
  external: false,
}

export default LinkBase
