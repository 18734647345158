import { takeEvery } from 'redux-saga/effects'

import { endpoints } from 'common/config'
import { simpleGET } from 'common/helpers/saga'

/**
 * Get a list of users that are following the target user
 * @param {Object} action
 * @param {String} action.id - the id of the user
 * @param {String} [action.nextPage] - the cursor for pagination
 */
export function* getUsersFollowers({ id, nextPage, authenticated = true }) {
  const url = new URL(`${endpoints.communityBase}/v1/users/${id}/followers`)
  if (nextPage) {
    url.searchParams.set('cursor', nextPage)
  }

  yield simpleGET({
    url,
    type: 'common/GET_USER_FOLLOWERS',
    method: 'GET',
    authenticated,
  })
}

/**
 * Get a list of users that the target user is following
 * @param {Object} action
 * @param {Boolean} action.authenticated wether to include auth headers
 * @param {String} action.id id of user you'd like to know the followers for
 * @param {String} [action.nextPage] pagination cursor
 * @param {String} [action.query] search query to refine
 */
export function* getUsersFollowing({
  authenticated = true,
  id,
  nextPage,
  query,
}) {
  const url = new URL(`${endpoints.communityBase}/v1/users/${id}/following`)

  if (query) {
    url.searchParams.set('query', query)
  }

  if (nextPage) {
    url.searchParams.set('cursor', nextPage)
  }

  yield simpleGET({
    url,
    type: 'common/GET_USER_FOLLOWING',
    method: 'GET',
    authenticated,
  })
}

/**
 * This is the same endpoint as `getUserFollowing`, but we need to store
 * this in a different reducer for the target user so it doesn't get
 * overwritten by requests for other users
 * TODO: make this call more efficient in code-use
 * @param {Object} action
 * @param {Object} action.id auth'd user's id
 * */
export function* getMyFollowing({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${id}/following`,
    type: 'common/GET_MY_FOLLOWING',
    method: 'GET',
    authenticated: true,
  })
}
/*
 * @param {Object} action
 * @param {Object} action.id user to follow
 */
export function* postFollowUser({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${id}/follow`,
    type: 'common/POST_USER_FOLLOW',
    method: 'POST',
    id,
    authenticated: true,
  })
}

/*
 * @param {Object} action
 * @param {Object} action.id user to unfollow
 */
export function* unfollowUser({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${id}/follow`,
    type: 'common/DELETE_USER_FOLLOW',
    method: 'DELETE',
    id,
    authenticated: true,
  })
}

/*
 * @param {Object} action
 * @param {Object} action.id user to unfollow
 */
export function* deleteFollower({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${id}/remove_follower`,
    type: 'common/DELETE_USER_FOLLOWER',
    method: 'DELETE',
    id,
    authenticated: true,
  })
}

export function* follows() {
  yield takeEvery('common/GET_USER_FOLLOWERS', getUsersFollowers)
  yield takeEvery('common/GET_USER_FOLLOWING', getUsersFollowing)
  yield takeEvery('common/GET_MY_FOLLOWING', getMyFollowing)
  yield takeEvery('common/POST_USER_FOLLOW', postFollowUser)
  yield takeEvery('common/DELETE_USER_FOLLOW', unfollowUser)
  yield takeEvery('common/DELETE_USER_FOLLOWER', deleteFollower)
}
