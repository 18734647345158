import { takeLatest, call } from 'redux-saga/effects'
import { simpleGET } from 'common/helpers/saga'
import { delay } from 'redux-saga'
import { endpoints } from 'common/config'

export function* autocompleteCall({ query }) {
  yield call(delay, 500)
  yield simpleGET({
    url: `${
      endpoints.communityBase
    }/v1/communities/autocomplete?query=${query}`,
    type: 'communityApp/autocomplete',
  })
}

export function* autocomplete() {
  yield takeLatest('communityApp/autocomplete', autocompleteCall)
}
