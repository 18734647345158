import React, { Component } from 'react'
import classnames from 'classnames'
import { FormSelect } from 'materialize-css'
import PropTypes from 'prop-types'

import './styles.scss'

class MaterializeSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    ignoreDynamicChildren: PropTypes.bool,
    label: PropTypes.string,
    onChange: () => null,
    options: PropTypes.object,
    valid: PropTypes.bool,
    wide: PropTypes.bool,
  }

  static defaultProps = {
    className: '',
    ignoreDynamicChildren: false,
    label: '',
    onChange: () => null,
    options: {},
    valid: false,
    wide: false,
  }

  select = React.createRef()

  componentDidMount() {
    FormSelect.init(this.select.current, this.props.options)
  }

  componentDidUpdate(prevProps, _prevState) {
    const { children, ignoreDynamicChildren } = this.props

    // For dynamic updates, we need to reinitialize the select dropdown
    // Bug: Children force update on parent update
    if (ignoreDynamicChildren && children !== prevProps.children) {
      return
    }

    FormSelect.init(this.select.current, this.props.options)
  }

  render() {
    const { className, label, valid, wide, ...remainingProps } = this.props

    const classes = {
      base: classnames(
        'materialize-select',
        'qa-materialize-select',
        className,
        {
          'materialize-select--valid': valid,
          'materialize-select--wide': wide,
        }
      ),
    }

    return (
      <div className={classes.base}>
        <select
          {...remainingProps}
          onChange={this.props.onChange}
          ref={this.select}
        />
        {label && <label>{label}</label>}
      </div>
    )
  }
}

export default MaterializeSelect
