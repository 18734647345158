import { useCallback } from 'react'

import { trackEvent } from 'common/metrics'

const useGenericContentBlockItemTracking = ({ contentBlock, item, location }) =>
  useCallback(
    () => {
      trackEvent('Content Block Item Clicked', {
        content_block_display_type: contentBlock.attributes.display_type,
        content_block_item_use_type: contentBlock.attributes.item_use_type,
        content_block_uuid: contentBlock.attributes.uuid,
        item_type: item.type,
        item_uuid: item.attributes.uuid,
        location,
      })
    },
    [contentBlock, item, location]
  )

export default useGenericContentBlockItemTracking
