import { createSelector } from '@reduxjs/toolkit'

import { getQueryParams } from 'common/helpers'

import { getHash } from 'routing/selectors'

export const getRootReducer = state => state.rootReducer

export const getChannels = createSelector(
  [getRootReducer],
  rootReducer => rootReducer.channels
)

export const getSelectedChannel = state => state.urlReducer.params?.channelSlug

export const getSelectedChannelObject = createSelector(
  [getSelectedChannel, getChannels],
  (selectedChannel, channels) => {
    if (!selectedChannel) return

    const channel = channels?.find(c => c.attributes.slug === selectedChannel)
    return channel
  }
)

export const getSelectedChannelAttributes = createSelector(
  [getSelectedChannelObject],
  channelObject => channelObject?.attributes
)

export const getSelectedChannelDescription = createSelector(
  [getSelectedChannelAttributes],
  channelAttributes => channelAttributes?.site_description
)

export const getSelectedChannelName = createSelector(
  [getSelectedChannelAttributes],
  channelAttributes => channelAttributes?.name
)

export const getSelectedChannelBrandColor = createSelector(
  [getSelectedChannelObject],
  channelObject => channelObject?.attributes?.brand_color
)

export const getSelectedChannelVerticalLogo = createSelector(
  [getSelectedChannelObject],
  channelObject => channelObject?.attributes?.vertical_logo_url
)

export const isViewingMissingChannel = createSelector(
  [getChannels, getSelectedChannel],
  (channels, selectedChannel) =>
    // Channels are loaded and a channel slug is specified, but the channel is not one that we recognize.
    channels?.length &&
    selectedChannel &&
    !channels.find(c => c.attributes.slug === selectedChannel)
)

export const getIsSearchV2Open = createSelector(
  [getRootReducer],
  root => root.searchV2Open
)

// FIXME: Get rid of this, query will strictly come from the search's uncontrolled component.
export const getSearchQuery = createSelector(
  [getHash, getIsSearchV2Open],
  (hash, searchIsOpen) => {
    if (!searchIsOpen) return false

    const query = getQueryParams(hash.split('?')[1])
    return query.term || false
  }
)

export const getIsMobileNavOpen = createSelector(
  [getRootReducer],
  root => root.mobileNavOpen
)
