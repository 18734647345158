import {
  APPLY_COUPON,
  ATTEMPT_SIGN_IN_WITH_3PARTY,
  CREATE_USER_SAGA,
  HIDE_PAYPAL_OVERLAY,
  SEND_TRACKING_EVENT,
  SET_3PARTY_SIGNIN_ERROR,
  SET_BILLING_ERROR,
  SET_BILLING_FORM_FIELD,
  SET_BILLING_FORM_SUBMITTING,
  SET_CREDIT_CARD_IS_VALID,
  SET_EMAIL_ERROR,
  SET_FIRST_NAME_ERROR,
  SET_LAST_NAME_ERROR,
  SET_PASSWORD_ERROR,
  SET_PAYMENT_METHOD,
  SET_PLAN_CONFIRMED,
  SET_POSTAL_CODE_ERROR,
  SET_PREFER_EXISTING_PAYMENT_METHOD,
  SET_TERMS_AGREED_CHECKED,
  SET_TERMS_AGREED_CHECKED_ERROR,
  SET_URL_PARAMS_TO_STATE,
  SET_USERNAME_ERROR,
  SHOW_PAYPAL_OVERLAY,
  SOFT_RESET_SIGNUP_FLOW_FORMS,
  SUBMIT_CUSTOMIZE_FORM,
  UPDATE_COUPON,
  UPDATE_EMAIL,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_PASSWORD,
  UPDATE_POSTAL_CODE,
  UPDATE_SELECTED_PLAN,
  UPDATE_USERNAME,
  UPDATE_USERNAME_AND_AVATAR_TO_RANDOM,
} from 'signupApp/types'

export const softResetSignupFlowForms = () => ({
  type: SOFT_RESET_SIGNUP_FLOW_FORMS,
})

export const updatePassword = newPassword => ({
  type: UPDATE_PASSWORD,
  newPassword,
})

export const updateEmail = newEmail => ({
  type: UPDATE_EMAIL,
  newEmail,
})

export const updateUsername = newUsername => ({
  type: UPDATE_USERNAME,
  newUsername,
})

export const updateUsernameAndAvatarToRandom = () => ({
  type: UPDATE_USERNAME_AND_AVATAR_TO_RANDOM,
})

export const setEmailError = error => ({
  type: SET_EMAIL_ERROR,
  error,
})

export const setPasswordError = error => ({
  type: SET_PASSWORD_ERROR,
  error,
})

export const setPreferExistingPaymentMethod = value => ({
  type: SET_PREFER_EXISTING_PAYMENT_METHOD,
  value,
})

export const setTermsAgreedChecked = termsAgreedChecked => ({
  type: SET_TERMS_AGREED_CHECKED,
  termsAgreedChecked,
})

export const setTermsAgreedCheckedError = error => ({
  type: SET_TERMS_AGREED_CHECKED_ERROR,
  error,
})

export const submitCustomizeForm = () => ({ type: SUBMIT_CUSTOMIZE_FORM })

export const createUser = token => ({
  type: CREATE_USER_SAGA,
  token,
})

export const attemptSignInWith3Party = serviceId => ({
  type: ATTEMPT_SIGN_IN_WITH_3PARTY,
  serviceId,
})

export const set3PartySigninError = error => ({
  type: SET_3PARTY_SIGNIN_ERROR,
  error,
})

export const setUsernameError = error => ({
  type: SET_USERNAME_ERROR,
  error,
})

export const updateSelectedPlan = selectedPlan => ({
  type: UPDATE_SELECTED_PLAN,
  selectedPlan,
})

export const setPlanConfirmed = () => ({
  type: SET_PLAN_CONFIRMED,
})

export const setBillingFormSubmitting = isSubmitting => ({
  type: SET_BILLING_FORM_SUBMITTING,
  isSubmitting,
})

export const showPaypalOverlay = () => ({ type: SHOW_PAYPAL_OVERLAY })

export const hidePaypalOverlay = () => ({
  type: HIDE_PAYPAL_OVERLAY,
})

export const setBillingFormField = (field, value) => ({
  type: SET_BILLING_FORM_FIELD,
  field,
  value,
})

export const setPaymentMethod = paymentMethod => ({
  type: SET_PAYMENT_METHOD,
  paymentMethod,
})

export const setBillingError = error => ({
  type: SET_BILLING_ERROR,
  error,
})

export const updateCoupon = couponCode => ({
  type: UPDATE_COUPON,
  couponCode,
})

export const applyCoupon = couponCode => ({
  type: APPLY_COUPON,
  couponCode,
})

export const sendTrackingEvent = (eventName, event = {}) => ({
  type: SEND_TRACKING_EVENT,
  eventName,
  event,
})

const paramStringToObject = string =>
  Object.fromEntries(new URLSearchParams(string).entries())

export const setUrlParamsToState = () => ({
  type: SET_URL_PARAMS_TO_STATE,
  params: paramStringToObject(window.location.search),
})

export const updateFirstName = newFirstName => ({
  type: UPDATE_FIRST_NAME,
  newFirstName,
})

export const updateLastName = newLastName => ({
  type: UPDATE_LAST_NAME,
  newLastName,
})

export const updatePostalCode = newPostalCode => ({
  type: UPDATE_POSTAL_CODE,
  newPostalCode,
})

export const setCreditCardIsValid = isValid => ({
  type: SET_CREDIT_CARD_IS_VALID,
  isValid,
})

export const setFirstNameError = error => ({
  type: SET_FIRST_NAME_ERROR,
  error,
})

export const setLastNameError = error => ({ type: SET_LAST_NAME_ERROR, error })

export const setPostalCodeError = error => ({
  type: SET_POSTAL_CODE_ERROR,
  error,
})
