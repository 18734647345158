import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { swapCommunityAPIForCDN } from 'common/helpers/paths'

import './styles.scss'

const MAX_IMAGES = 4

export default class PostImagePreview extends Component {
  static propTypes = {
    images: PropTypes.array,
  }

  static defaultProps = {
    images: [],
  }

  img = (img, idx, images) => {
    // we should only ever render the max images
    if (idx > MAX_IMAGES - 1) {
      return
    }
    const moreImagesCount = images.length - MAX_IMAGES
    const hasMoreImages = moreImagesCount > 0
    const isLastItem = idx === MAX_IMAGES - 1
    const preview = swapCommunityAPIForCDN(
      img,
      'w_708,h_500,fl_lossy,c_thumb,g_faces:center,q_auto:best'
    )

    return (
      <div className='post-image' key={`img-${idx}`}>
        <img src={preview} alt='post' />
        {isLastItem && hasMoreImages && (
          <div
            className='post-image-overlay'
            title={`${moreImagesCount} more images`}
          >
            <h3>+{moreImagesCount}</h3>
          </div>
        )}
      </div>
    )
  }

  render() {
    const { images } = this.props
    let imageLayout = 'post-images-zero'
    switch (images.length) {
      case 0:
        imageLayout = 'post-images-zero'
        break
      case 1:
        imageLayout = 'post-images-one'
        break
      case 2:
        imageLayout = 'post-images-two'
        break
      case 3:
        imageLayout = 'post-images-three'
        break
      case 4:
      default:
        imageLayout = 'post-images-four-plus'
    }

    const wrapperClassesObj = {
      'post-images-preview': true,
    }
    wrapperClassesObj[imageLayout] = true

    const wrapperClasses = classnames(wrapperClassesObj)

    return <div className={wrapperClasses}>{images.map(this.img)}</div>
  }
}
