import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  deleteCommentLike,
  fetchAllReplies,
  fetchComments,
  fetchSingleComment,
  postCommentLike,
  resetSingleComment,
  updatePage,
} from 'common/actions'
import { Comment } from 'common/components'

import { showModal } from 'modalApp/actions'

import './styles.scss'

class CommentsContainer extends Component {
  static propTypes = {
    bg: PropTypes.oneOf(['dark', 'light']),
    comments: PropTypes.array,
    handleHashTags: PropTypes.bool,
    highlightedComment: PropTypes.object,
    highlightedCommentUuid: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    onOptionsActionClick: PropTypes.func,
    onReplyClick: PropTypes.func,
    onShowReplies: PropTypes.func,
    page: PropTypes.number,
    sortBy: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    topicType: PropTypes.string,
    uuid: PropTypes.string.isRequired,
  }

  static defaultProps = {
    bg: 'light',
    handleHashTags: false,
    topicType: 'svod',
  }

  constructor(props) {
    super(props)
    this.state = {
      activeReplyForm: '0',
    }

    this.commentContainer = React.createRef()
  }

  // / ////////////
  // Lifecycle
  // / ////////////
  /**
   * Sometimes we have a uuid when we mount the component, in this case,
   * fetch some comments!
   */
  async componentDidMount() {
    const { dispatch, sortBy, uuid } = this.props
    if (!uuid) {
      return
    }
    await dispatch(fetchComments(uuid, 1, false, sortBy?.value))
    this.populatePermaComment(this.props)
  }

  /**
   * We need to fetch out comments, but... since component mounting sometimes
   * gets wiggy with this component, we just listen to prop changes intsead
   * @note this assumes that we're going to set the uuid at some point _after_
   * we mount the component
   * @note also, we're assuming we're not going to unmount this component if
   * we switch uuids
   */
  componentDidUpdate(prevProps, _prevState) {
    const { comments, dispatch, isFetching, sortBy, uuid } = this.props
    let { page } = this.props

    let merge = true

    // check if comments were wiped
    // comments can get wiped on logout/in to clear user specific attrs
    if (!comments.length && comments.length !== prevProps.comments.length) {
      prevProps.dispatch(fetchComments(uuid, page, merge, sortBy?.value))
    }

    if (
      isFetching ||
      (page === prevProps.page &&
        sortBy?.value === prevProps.sortBy?.value &&
        uuid === prevProps.uuid)
    ) {
      return
    }

    if (uuid !== prevProps.uuid) {
      page = 1
    }

    if (
      page === 1 ||
      sortBy?.value !== prevProps.sortBy?.value ||
      uuid !== prevProps.uuid
    ) {
      merge = false
    }

    dispatch(
      fetchComments(this.props.uuid, page, merge, this.props.sortBy?.value)
    )
  }

  componentWillUnmount() {
    this.props.dispatch(resetSingleComment())
  }

  // / ///////////
  // Helpers
  // / ///////////

  loadNextPage = e => {
    e.preventDefault()
    this.props.dispatch(updatePage(this.props.page + 1))
  }

  likeComment = id => {
    const { dispatch, isAuthenticated } = this.props

    if (isAuthenticated) {
      return dispatch(postCommentLike(id))
    }

    dispatch(showModal('USER_LOGIN'))
  }

  removeCommentLike = id => {
    const { dispatch, isAuthenticated } = this.props

    if (isAuthenticated) {
      return dispatch(deleteCommentLike(id))
    }

    dispatch(showModal('USER_LOGIN'))
  }

  toggleReplyForm = index => {
    this.setState((state, _props) => ({
      activeReplyForm: state.activeReplyForm === index ? '0' : index,
    }))
  }

  async populatePermaComment(props) {
    const {
      dispatch,
      highlightedCommentUuid,
      highlightedCommentFetching,
    } = props
    if (!highlightedCommentUuid) {
      return
    }
    if (highlightedCommentFetching) {
      return
    }
    if (
      highlightedCommentUuid === this.props.highlightedCommentUuid &&
      this.props.highlightedComment
    ) {
      return
    }

    await dispatch(fetchSingleComment(highlightedCommentUuid)).then(
      ({ comment }) => {
        window.scrollTo(0, this.commentContainer.current.offsetTop)
        dispatch(fetchAllReplies(comment.uuid))
      }
    )
  }

  renderComments() {
    const {
      bg,
      comments,
      dispatch,
      error,
      featureFlags,
      handleHashTags,
      isCommenting,
      onOptionsActionClick,
      onReplyClick,
      onShowReplies,
      topicType,
      user,
      userList,
    } = this.props
    return comments.map((comment, _i) => (
      <Comment
        activeReplyForm={this.state.activeReplyForm}
        bg={bg}
        dispatch={dispatch}
        error={error}
        featureFlags={featureFlags}
        handleHashTags={handleHashTags}
        isCommenting={isCommenting}
        key={`comment-${comment.uuid}`}
        likeComment={this.likeComment}
        onOptionsActionClick={onOptionsActionClick}
        onReplyClick={onReplyClick}
        onShowReplies={onShowReplies}
        removeCommentLike={this.removeCommentLike}
        toggleReplyForm={this.toggleReplyForm}
        topicType={topicType}
        user={user}
        userList={userList}
        {...comment}
      />
    ))
  }

  render() {
    const {
      bg,
      comments,
      dispatch,
      featureFlags,
      handleHashTags,
      highlightedComment,
      highlightedCommentUuid,
      isCommenting,
      topicType,
      user,
      userList,
    } = this.props

    const empty = !comments || !comments.length
    return (
      <div
        className={`comment-container ${empty ? 'empty' : ''}`}
        ref={this.commentContainer}
      >
        {!!highlightedComment && (
          <Comment
            activeReplyForm={this.state.activeReplyForm}
            bg={bg}
            dispatch={dispatch}
            featureFlags={featureFlags}
            handleHashTags={handleHashTags}
            highlightedCommentUuid={highlightedCommentUuid}
            isCommenting={isCommenting}
            key={`highlight-comment-${highlightedComment.uuid}`}
            likeComment={this.likeComment}
            removeCommentLike={this.removeCommentLike}
            showReplies
            toggleReplyForm={this.toggleReplyForm}
            topicType={topicType}
            user={user}
            userList={userList}
            {...highlightedComment}
          />
        )}

        {(!comments || !comments.length) && (
          <div className='comment-container__zero-state-message-container'>
            <div className='comment-container__zero-state-message'>
              Be the first to comment!
            </div>
          </div>
        )}

        {!!comments && !!comments.length && this.renderComments()}

        {this.props.canPage && (
          <div className='comment-container-show-more center-align'>
            {!this.props.isFetching && (
              <a href='#show-more' onClick={this.loadNextPage}>
                Show More
              </a>
            )}
            {this.props.isFetching && (
              <div className='preloader-wrapper active'>
                <div className='spinner-layer'>
                  <div className='circle-clipper left'>
                    <div className='circle' />
                  </div>
                  <div className='gap-patch'>
                    <div className='circle' />
                  </div>
                  <div className='circle-clipper right'>
                    <div className='circle' />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

// / ////////////
// Redux Bindings
// / ////////////
const mapStateToProps = (state, _ownProps) => {
  const { isAuthenticated, user } = state.authReducer
  const { error, isCommenting } = state.commonReducer.comments
  const { featureFlags } = state.commonReducer

  return {
    ...state.commonReducer.comments,
    error,
    featureFlags,
    isAuthenticated,
    isCommenting,
    user,
  }
}

export default connect(mapStateToProps)(CommentsContainer)
