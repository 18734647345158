import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { MemoCloudinaryImg } from 'common/components'
import { getImageSrc } from 'common/helpers'

import placeholder from './placeholder.png'

import './styles.scss'

const getCreator = attributes =>
  // Legacy APIs use "owner_name", content blocks API uses "owner_username"
  attributes?.owner_name || attributes?.owner_username || ''

const getIcon = (category, isFollowed) => {
  if (isFollowed) {
    return <i className='icon icon-rt-followed-playlist' />
  }
  switch (category) {
    case 'watch_later':
      return <i className='icon icon-watch_later' />
    case 'liked_videos':
      return <i className='icon icon-heart_filled' />
    default:
      return <i className='icon icon-rt-playlist' />
  }
}

const getPublic = privacy =>
  privacy === 'private_list' ? (
    <i className='icon icon-rt-lock' />
  ) : (
    <i className='icon icon-rt-public' />
  )

function PlaylistCard({
  isFollowed,
  item,
  onThumbnailClick,
  onTitleClick,
  width = 'auto',
}) {
  if (item) {
    const imgSrc =
      // For playlists in the content blocks API
      item?.attributes?.display_content_image_url ||
      // For playlists in legacy APIs
      getImageSrc(item?.data?.included?.images, 'small', 'profile')
    const {
      category,
      playlist_items_count: count,
      privacy_level: privacy,
    } = item.attributes
    const link =
      item?.attributes?.category === 'followed_series'
        ? '/library#followed-series'
        : `/playlist/${
            // For playlists in the content blocks API
            item?.attributes?.uuid ||
              // For playlists in legacy APIs
              item.id
          }`
    return (
      <div className='playlist-card'>
        <div className='card-content'>
          <div className='card-image-wrapper'>
            <Link onClick={onThumbnailClick} to={link}>
              <MemoCloudinaryImg
                src={imgSrc.split('f_auto')[1]}
                width={width}
              />
              <div className='card-overlay'>
                <div className='overlay-content'>
                  <div className='items-count'>{count}</div>
                  {getIcon(category, isFollowed)}
                </div>
              </div>
            </Link>
          </div>
          <div className='info-line'>
            <div className='info-left'>
              <Link to={link} className='playlist-title' onClick={onTitleClick}>
                {item.attributes.name}
              </Link>
              <div className='playlist-extra'>
                <Link to={link} className='playlist-extra__link'>
                  {getPublic(privacy)}
                </Link>{' '}
                | {getCreator(item.attributes)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='playlist-card'>
      <div className='card-content'>
        <div
          className='image'
          style={{ backgroundImage: `url('${placeholder}')` }}
        />
      </div>
    </div>
  )
}

PlaylistCard.propTypes = {
  item: PropTypes.object,
  onThumbnailClick: PropTypes.func,
  onTitleClick: PropTypes.func,
}

PlaylistCard.defaultProps = {
  item: {},
  onThumbnailClick: () => null,
  onTitleClick: () => null,
}

export default PlaylistCard
