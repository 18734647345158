import React, { useCallback } from 'react'

import { ShowCard } from 'common/components'
import { trackSearchSelection } from 'common/metrics'

import getAnalyticsTabName from '../../helpers/getAnalyticsTabName'

const WrappedShowCard = ({ closeSearchV2, series, searchTerm, tab }) => {
  const trackClickAndClose = useCallback(
    () => {
      closeSearchV2()
      trackSearchSelection({
        query: searchTerm,
        tab: getAnalyticsTabName(tab),
        type: 'series',
        uuid: series.attributes?.uuid || series.uuid || series.id,
      })
    },
    [
      closeSearchV2,
      searchTerm,
      series.id,
      series.uuid,
      series.attributes?.uuid,
      tab,
    ]
  )

  return (
    <ShowCard
      item={series}
      onThumbnailClick={trackClickAndClose}
      onTitleClick={trackClickAndClose}
      poster
      width='400'
    />
  )
}

export default WrappedShowCard
