import React from 'react'

import { TAB_BONUS_FEATURES, TAB_EPISODES } from '../../helpers/constants'
import EmptySearch from '../shared/EmptySearch'
import WrappedEpisodeCard from '../shared/WrappedEpisodeCard'

import './styles.scss'

const TabEpisodes = ({
  bonusFeatures,
  closeSearchV2,
  episodes,
  isBonusFeatures,
  searchTerm,
}) => {
  // This is kind of an ugly way to reuse this component but it's the only difference between the two tabs.
  const [items, tab] = isBonusFeatures
    ? [bonusFeatures, TAB_BONUS_FEATURES]
    : [episodes, TAB_EPISODES]

  if (items.data.length === 0) return <EmptySearch />

  return (
    <div className='search-v2-episodes'>
      {items.data?.map(episode => (
        <WrappedEpisodeCard
          key={episode.uuid}
          closeSearchV2={closeSearchV2}
          episode={episode}
          isBonusFeature={isBonusFeatures}
          searchTerm={searchTerm}
          tab={tab}
        />
      ))}
    </div>
  )
}

export default TabEpisodes
