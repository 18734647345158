import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import navCard from 'common/assets/uno-infinite/uno-infinite-card-ah.png'
import cardAlfredo from 'common/assets/uno-infinite/uno-infinite-character-alfredo.png'
import cardBk from 'common/assets/uno-infinite/uno-infinite-character-bk.png'
import cardGeoff from 'common/assets/uno-infinite/uno-infinite-character-geoff.png'
import cardJack from 'common/assets/uno-infinite/uno-infinite-character-jack.png'
import cardJeremy from 'common/assets/uno-infinite/uno-infinite-character-jeremy.png'
import cardJoe from 'common/assets/uno-infinite/uno-infinite-character-joe.png'
import cardKy from 'common/assets/uno-infinite/uno-infinite-character-ky.png'
import cardLindsay from 'common/assets/uno-infinite/uno-infinite-character-lindsay.png'
import cardMatt from 'common/assets/uno-infinite/uno-infinite-character-matt.png'
import cardMichael from 'common/assets/uno-infinite/uno-infinite-character-michael.png'
import cardTrevor from 'common/assets/uno-infinite/uno-infinite-character-trevor.png'
import { Tooltip } from 'common/components'
import getRandom from 'common/helpers/getRandom'
import { trackEvent } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const useUnoInfiniteHelpers = () =>
  useMemo(() => {
    const characterImages = [
      cardAlfredo,
      cardBk,
      cardGeoff,
      cardJack,
      cardJeremy,
      cardJoe,
      cardKy,
      cardLindsay,
      cardMatt,
      cardMichael,
      cardTrevor,
    ].map(src => {
      const cardImage = new Image()
      cardImage.src = src
      return cardImage
    })

    const cardWidth = 75
    const cardAspectRatio = 0.722
    const cardHeight = cardWidth / cardAspectRatio

    function resetParticle(particle) {
      particle.image = getRandom(characterImages)
    }

    function drawParticle(context, particle) {
      context.shadowOffsetX = 5
      context.shadowOffsetY = 5
      context.shadowColor = 'rgba(0, 0, 0, 0.2)'
      context.shadowBlur = 20

      context.drawImage(
        particle.image,
        particle.x,
        particle.y,
        cardWidth,
        cardHeight
      )
    }

    const trackClick = user =>
      trackEvent('Uno Infinite Icon Clicked', {
        user_tier: user?.attributes?.member_tier || 'anon',
      })

    return {
      drawParticle,
      resetParticle,
      trackClick,
    }
  }, [])

const UnoInfiniteNavButton = React.memo(({ user }) => {
  const { drawParticle, resetParticle, trackClick } = useUnoInfiniteHelpers()

  return (
    <FallingParticlesEasterEggButton
      compression={0.7}
      drawParticle={drawParticle}
      particleCount={50}
      resetParticle={resetParticle}
      speed={3}
      startYOffset={200}
      stopAfter={50}
      element={({ toggleFalling, falling }) => (
        <Tooltip text='Uno: Infinite'>
          <div
            className={`uno-infinite-nav-button ${falling ? 'active' : ''}`}
            role='button'
            onClick={() => {
              trackClick(user)
              toggleFalling()
            }}
            onKeyPress={toggleFalling}
            tabIndex={0}
          >
            <img src={navCard} alt='Achievement Hunter Uno Card' />
          </div>
        </Tooltip>
      )}
    />
  )
})
const mapStateToProps = state => ({ user: state.authReducer.user })

export default connect(mapStateToProps)(UnoInfiniteNavButton)
