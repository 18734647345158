const initialState = {
  isPosting: false,
  post: null,
  isFetchingPost: null,
}

export default (
  state = initialState,
  { type, _resp, _pagination, data, _req, ..._rest }
) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'common/GET_USER_FOLLOWERS_RESP':
      return {
        ...state,
        followers: data,
      }
    case 'common/USER_POST_REQ':
      return {
        ...state,
        isPosting: true,
      }
    case 'common/USER_POST_RESP':
    case 'communityApp/c/createPost_RESP':
    case 'common/PATCH_POST_RESP':
      return {
        ...state,
        isPosting: false,
        recentPost: data,
      }
    default:
      return state
  }
}
