import { delay } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'

import { endpoints } from 'common/config'
import { simpleGET } from 'common/helpers/saga'

/**
 * Get a list of users matching the provided name
 * @param {String} id - the id of the user
 */
export function* getUsers({ name }) {
  yield call(delay, 500)
  yield simpleGET({
    url: `${
      endpoints.business
    }/api/v1/users/autocomplete?query=${name.toLowerCase()}`,
    type: 'common/GET_USERS',
    method: 'GET',
  })
}

export function* users() {
  yield takeLatest('common/GET_USERS', getUsers)
}
