import moment from 'moment'

import { truncStringByChar } from 'common/helpers'

export const normalizeNotifications = payload => {
  const maxLength = 33
  const placeholderAvatar = `${process.env.PUBLIC_URL}/img/favicon-32x32.png`
  const normalized = {}
  switch (payload['notify.template_token']) {
    case 'group_pinned_post':
      normalized.imageUrl = payload['extra.post_author_profile_image_url']
      normalized.imageTag = payload['extra.post_author_username']
      normalized.title = `New pinned post in <b>${
        payload['extra.group_name']
      }</b>`
      normalized.body = `<span aria-label="📌, pushpin" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📌</span></span> <b>${truncStringByChar(
        payload['extra.post_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'livestream_start':
      const liveTime = moment(payload['extra.live_at'])
      normalized.title = ''
      normalized.body = `<span aria-label="⏰, alarm_clock" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">⏰</span></span> <b>${
        payload['extra.stream_name']
      }</b> ${
        liveTime.isBefore() ? 'livestream started' : 'livestream starts'
      } <b>${liveTime.fromNow()}</b>`
      normalized.imageUrl = payload['extra.stream_image_url']
      normalized.imageTag = payload['extra.stream_name']
      normalized.ctaUrl = payload['extra.url']
      break

    case 'my_follows_make_a_post':
      normalized.imageUrl = payload['extra.post_author_profile_image_url']
      normalized.imageTag = payload['extra.post_author_username']
      normalized.title = `<b>${
        payload['extra.post_author_username']
      }</b> made a post`
      normalized.body = `<span aria-label="🖊️, lower_left_ballpoint_pen" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">🖊️</span></span> <b>${truncStringByChar(
        payload['extra.post_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'new_episode':
      normalized.imageUrl = payload['extra.episode_image_url']
      normalized.imageTag = payload['extra.episode_name']
      normalized.title = `A new episode of <b>${
        payload['extra.show_name']
      }</b> is available!`
      normalized.body = `<span aria-label="📺, tv" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📺</span></span> Watch <b>${
        payload['extra.episode_name']
      }</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'new_post_in_group':
      normalized.imageUrl = payload['extra.post_author_profile_image_url']
      normalized.imageTag = payload['extra.post_author']
      normalized.title = `<b>${
        payload['extra.post_author']
      }</b> made a post in <b>${payload['extra.group_name']}</b>`
      normalized.body = `<span aria-label="🖊️, lower_left_ballpoint_pen" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">🖊️</span></span> <b>${truncStringByChar(
        payload['extra.post_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'new_site_wide_post':
      normalized.imageUrl = payload['extra.post_author_profile_image_url']
      normalized.imageTag = payload['extra.post_author']
      normalized.title = `<b>New Site-Wide Post</b>`
      normalized.body = payload['extra.post_body']
      normalized.ctaUrl = payload['extra.url']
      break

    case 'public_playlist_i_follow_is_updated':
      normalized.imageUrl = payload['extra.profile_url']
      normalized.imageTag = 'Playlist Update'
      normalized.title = `<b>${
        payload['extra.owner_username']
      }</b> updated a playlist you follow!`
      normalized.body = `<span aria-label="🔥, fire" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">🔥</span></span> Check out <b>${
        payload['extra.playlist_name']
      }</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'site_admin_message':
    case 'dynamic_notification':
      normalized.imageUrl = placeholderAvatar
      normalized.imageTag = 'System Message'
      normalized.title = `<b>${payload['extra.title']}</b>`
      normalized.body = payload['extra.body']
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_invites_me_to_group':
      normalized.imageUrl = payload['extra.inviter_profile_image_url']
      normalized.imageTag = payload['extra.inviter_username']
      normalized.title = ``
      normalized.body = `<span aria-label="🙌, raised_hands" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">🙌</span></span> <b>${
        payload['extra.inviter_username']
      }</b> invited you to join <b>${payload['extra.group_name']}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_likes_my_post':
      normalized.imageUrl = payload['extra.liker_profile_image_url']
      normalized.imageTag = payload['extra.liker_username']
      normalized.title = `<b>${
        payload['extra.liker_username']
      }</b> liked your post <span aria-label="😍, heart_eyes" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">😍</span></span>`
      normalized.body = `<b>${payload['extra.post_body'].substr(
        0,
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_mentions_me_in_a_community_comment':
      normalized.imageUrl = payload['extra.comment_author_profile_image_url']
      normalized.imageTag = payload['extra.comment_author_username']
      normalized.title = `<b>${
        payload['extra.comment_author_username']
      }</b> mentioned you in a comment:`
      normalized.body = `<span aria-label="📣, mega" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📣</span></span> <b>${truncStringByChar(
        payload['extra.comment_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_mentions_me_in_a_post':
      normalized.imageUrl = payload['extra.post_author_profile_image_url']
      normalized.imageTag = payload['extra.post_author_username']
      normalized.title = `<b>${
        payload['extra.post_author_username']
      }</b> mentioned you in a post:`
      normalized.body = `<span aria-label="📣, mega" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📣</span></span> <b>${truncStringByChar(
        payload['extra.post_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_mentions_me_in_an_svod_comment':
      normalized.imageUrl = payload['extra.comment_author_profile_image_url']
      normalized.imageTag = payload['extra.comment_author_username']
      normalized.title = `<b>${
        payload['extra.comment_author_username']
      }</b> mentioned you in a comment:`
      normalized.body = `<span aria-label="📣, mega" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📣</span></span> <b>${truncStringByChar(
        payload['extra.comment_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_replies_to_my_comment':
      normalized.imageUrl =
        payload['extra.new_comment_author_profile_image_url']
      normalized.imageTag = payload['extra.new_comment_author_username']
      normalized.title = `<b>${
        payload['extra.new_comment_author_username']
      }</b> replied to your comment:`
      normalized.body = `<span aria-label="📣, mega" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📣</span></span> <b>${truncStringByChar(
        payload['extra.new_comment_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_replies_to_my_post':
      normalized.imageUrl = payload['extra.comment_author_profile_image_url']
      normalized.imageTag = payload['extra.comment_author_username']
      normalized.title = `<b>${
        payload['extra.comment_author_username']
      }</b> replied to your post:`
      normalized.body = `<span aria-label="📣, mega" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">📣</span></span> <b>${truncStringByChar(
        payload['extra.comment_body'],
        maxLength
      )}</b>`
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_repost_my_post':
      normalized.imageUrl = payload['extra.repost_author_profile_image_url']
      normalized.imageTag = payload['extra.repost_author_username']
      normalized.title = `<b>${
        payload['extra.repost_author_username']
      }</b> reposted your post${
        payload['extra.repost_body'] && payload['extra.repost_body'] !== '??'
          ? ':'
          : ''
      }`
      normalized.body =
        payload['extra.repost_body'] && payload['extra.repost_body'] !== '??'
          ? `<span aria-label="👏, clap" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">👏</span></span> <b>${truncStringByChar(
              payload['extra.repost_body'],
              maxLength
            )}</b>`
          : ''
      normalized.ctaUrl = payload['extra.url']
      break

    case 'someone_start_follow_me':
      normalized.imageUrl = payload['extra.follower_profile_image_url']
      normalized.imageTag = payload['extra.follower_username']
      normalized.title = ``
      normalized.body = `<span aria-label="🎉, tada" class="emoji-mart-emoji emoji-mart-emoji-native"><span style="font-size: 12px;">🎉</span></span> <b>${
        payload['extra.follower_username']
      }</b> started following you!`
      normalized.ctaUrl = payload['extra.url']
      break

    default:
      normalized.imageUrl = placeholderAvatar
      normalized.imageTag = 'Default Message'
      normalized.title = payload['extra.title']
        ? `<b>${payload['extra.title']}</b>`
        : ''
      normalized.body = payload['extra.body']
      normalized.ctaUrl = payload['extra.url']
      break
  }

  return normalized
}
