import { COMMENTS_PER_PAGE } from 'common/actions'

import {
  COMMENTS_UPDATE_PAGE,
  COMMENTS_UPDATE_SORT_BY,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_LIKE_SUCCESS,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  GET_ALL_REPLIES_FAILURE,
  GET_ALL_REPLIES_REQUEST,
  GET_ALL_REPLIES_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_SINGLE_COMMENT_FAILURE,
  GET_SINGLE_COMMENT_REQUEST,
  GET_SINGLE_COMMENT_SUCCESS,
  GET_TOTAL_COMMENTS_FAILURE,
  GET_TOTAL_COMMENTS_REQUEST,
  GET_TOTAL_COMMENTS_SUCCESS,
  GET_USERNAMES_SUCCESS,
  POST_ADD_COMMENT_FAILURE,
  POST_ADD_COMMENT_REQUEST,
  POST_ADD_COMMENT_SUCCESS,
  POST_COMMENT_LIKE_SUCCESS,
  PUT_COMMENT_FAILURE,
  PUT_COMMENT_REQUEST,
  PUT_COMMENT_SUCCESS,
  RESTORE_COMMENT_FAILURE,
  RESTORE_COMMENT_REQUEST,
  RESTORE_COMMENT_SUCCESS,
  SET_SINGLE_COMMENT,
} from '../actions/types'

const initialState = {
  canPage: true,
  comments: [],
  deleteCommentFetching: null,
  error: null,
  highlightedComment: null,
  isCommenting: false,
  isFetching: false,
  page: 1,
  restoreCommentFetching: null,
  sortBy: { name: 'Newest', value: '-created_at' },
  total: 0,
  userList: null,
}

function updateComment(state, { comment }) {
  let newComments = state.comments
  if (comment) {
    newComments = state.comments.map(el => {
      if (el.uuid === comment.uuid) {
        // PUT does not return replies
        comment.replies = el.replies
        return comment
      }
      if (comment.parent_uuid && comment.parent_uuid === el.uuid) {
        return {
          ...el,
          replies: el.replies.map(reply =>
            reply.uuid === comment.uuid ? comment : reply
          ),
        }
      }
      return el
    })
  }
  return newComments
}

function addComment(state, { comment }) {
  let newComments = state.comments
  if (comment) {
    comment = { replies: [], ...comment }
    if (!comment.parent_uuid) {
      // push our new comment onto the stack
      newComments = [comment, ...state.comments]
    } else {
      const idx = state.comments.findIndex(
        el => el.uuid === comment.parent_uuid
      )
      if (idx !== -1 && state.comments) {
        newComments = state.comments.slice()
        newComments[idx].replies = newComments[idx]?.replies?.slice() || []
        newComments[idx].replies.unshift(comment)
        newComments[idx].child_comments_total_count =
          newComments[idx].child_comments_total_count + 1 || 1
      }
    }
  }

  return {
    ...state,
    comments: newComments,
    isFetching: false,
    isCommenting: false,
    error: initialState.error,
  }
}

function toggleLike({ isLiking, state, uuid }) {
  return state.comments.map(comment => {
    const { likes_count: commentLikes } = comment

    if (comment.uuid === uuid) {
      return {
        ...comment,
        liked_status: isLiking,
        likes_count: isLiking ? commentLikes + 1 : commentLikes - 1,
      }
    }

    if (comment.replies?.length > 0) {
      return {
        ...comment,
        replies: comment.replies.map(reply => {
          const { likes_count: replyLikes } = reply

          if (reply.uuid === uuid) {
            return {
              ...reply,
              liked_status: isLiking,
              likes_count: isLiking ? replyLikes + 1 : replyLikes - 1,
            }
          }

          return reply
        }),
      }
    }

    return comment
  })
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case COMMENTS_UPDATE_PAGE:
      return {
        ...state,
        page: action.page,
      }
    case COMMENTS_UPDATE_SORT_BY:
      return {
        ...state,
        page: 1,
        sortBy: action.sortBy,
      }
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_COMMENTS_SUCCESS:
      if (!action.comments) {
        return state
      }

      action.comments = action.comments.map(comment => {
        comment.replies = comment.child_comments
        delete comment.child_comments
        return comment
      })
      let comments = [...action.comments]
      if (action.merge) {
        comments = [...state.comments, ...comments]
      }
      return {
        ...state,
        comments,
        isFetching: false,
        page: action.page,
        canPage: !!(
          action.comments && action.comments.length === COMMENTS_PER_PAGE
        ),
      }
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case PUT_COMMENT_REQUEST:
    case POST_ADD_COMMENT_REQUEST:
      return { ...state, isCommenting: true }
    case POST_ADD_COMMENT_SUCCESS:
      return addComment(state, action)
    case POST_ADD_COMMENT_FAILURE:
    case PUT_COMMENT_FAILURE:
      return { ...state, error: action.error, isCommenting: false }
    case PUT_COMMENT_SUCCESS:
      return {
        ...state,
        comments: updateComment(state, action),
        isCommenting: false,
        error: initialState.error,
      }
    case GET_SINGLE_COMMENT_REQUEST:
      return { ...state, highlightedCommentFetching: true }
    case GET_SINGLE_COMMENT_SUCCESS:
      const comment = { ...action.comment }
      const allComments = [...state.comments]
      let original = allComments.find(el => el.uuid === action.comment.uuid)
      if (!original) {
        allComments.push(comment)
        original = allComments.find(el => el.uuid === action.comment.uuid)
      }
      comment.replies = original.replies || original.child_comments
      comment.child_comments_total_count = original.child_comments_total_count

      // handle highlighted display
      if (comment.uuid === action.uuid) {
        comment.isHighlighted = true
      }

      return {
        ...state,
        comments: allComments,
        highlightedComment: comment,
        highlightedCommentFetching: false,
      }
    case SET_SINGLE_COMMENT:
      return {
        ...state,
        highlightedComment: action.comment,
      }
    case GET_SINGLE_COMMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        highlightedCommentFetching: false,
      }
    case GET_ALL_REPLIES_REQUEST:
      return { ...state, isFetching: true, highlightedCommentFetching: true }
    case GET_ALL_REPLIES_SUCCESS:
      const selectedComment =
        state.comments.find(comment => comment.uuid === action.uuid) || {}
      selectedComment.replies = action.replies
      selectedComment.child_comments_total_count = action.replies.length

      return {
        ...state,
        comments: updateComment(state, selectedComment),
        highlightedComment:
          action.uuid === state.highlightedComment?.uuid
            ? { ...state.highlightedComment, ...selectedComment }
            : state.highlightedComment,
        isFetching: false,
        highlightedCommentFetching: false,
      }
    case GET_ALL_REPLIES_FAILURE:
      return {
        ...state,
        error: action.error,
        highlightedCommentFetching: false,
      }

    case DELETE_COMMENT_REQUEST:
      return {
        ...state,
        deleteCommentFetching: true,
      }
    case DELETE_COMMENT_SUCCESS:
      // we still get back the original message, instead we want to show it
      // as deleted
      action.comment.message = '[deleted]'
      return {
        ...state,
        comments: updateComment(state, action),
        deleteCommentFetching: false,
      }
    case DELETE_COMMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        deleteCommentFetching: false,
      }
    case RESTORE_COMMENT_REQUEST:
      return {
        ...state,
        restoreCommentFetching: true,
      }
    case RESTORE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: updateComment(state, action),
        restoreCommentFetching: false,
      }
    case RESTORE_COMMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        restoreCommentFetching: false,
      }
    case GET_USERNAMES_SUCCESS:
      const userList = action.users.map(user => user.username)
      return {
        ...state,
        userList,
      }
    case POST_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        comments: toggleLike({
          isLiking: true,
          state,
          uuid: action.uuid,
        }),
      }
    case DELETE_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        comments: toggleLike({
          isLiking: false,
          state,
          uuid: action.uuid,
        }),
      }
    case GET_TOTAL_COMMENTS_REQUEST:
    case GET_TOTAL_COMMENTS_FAILURE:
      return {
        ...state,
        total: undefined,
      }
    case GET_TOTAL_COMMENTS_SUCCESS:
      return {
        ...state,
        total: action.total,
      }
    default:
      return state
  }
}
