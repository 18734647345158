/* eslint-disable */
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { config } from 'common/config'
import { checkConsent, getCookie, isMobileUA, waitForGlobal } from 'common/helpers'
import { resetGrowthBookUserId } from 'common/metrics'

var analytics
window.analytics = analytics

class ExternalScripts extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }

  state = {
    isBrazeLoaded: false,
    isFunctionalOptIn: false,
    isPerformanceOptIn: false,
    isSocialOptIn: false,
    isTargetingOptIn: false,
  }

  braze = null

  componentDidMount() {
    this.initFacebook()
    this.initSegmentPreConsent()
    // We call this once to assign a random UUID to the user on page load. When Segment loads, we'll try to use the anonymous ID provided there.
    resetGrowthBookUserId()

    if(process.env.NODE_ENV !== 'development') {
      // Once OneTrust loads, handle consent and listen for consent changes (for example, GDPR user clicks "reject" or a user opens their Cookie preferences and disables some cookies).
      waitForGlobal('OneTrust').then(() => {
        console.log(
          "Calling handleConsent from waitForGlobal('OneTrust').\nOnetrustActiveGroups:",
          window?.OnetrustActiveGroups
        )
        this.handleConsent()

        window?.OneTrust?.OnConsentChanged(() => {
          console.log(
            'Calling handleConsent from OnConsentChanged.\nOnetrustActiveGroups:',
            window?.OnetrustActiveGroups
          )
          this.handleConsent()
        })
      })

      // If OnConsentChanged does not fire after initial consent has been automatically granted (for example, in USA, consent is automatically granted), deliberately run handleConsent when the active groups variable has been populated.
      waitForGlobal('OnetrustActiveGroups').then(() => {
        console.log(
          "Calling handleConsent from waitForGlobal('OnetrustActiveGroups').\nOnetrustActiveGroups:",
          window?.OnetrustActiveGroups
        )
        this.handleConsent()
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isBrazeLoaded } = this.state
    const { user } = this.props

    if (isBrazeLoaded && (
      (user && !prevProps.user)
      || (user && prevProps.user && user.id !== prevProps.user.id)
    )) {
      this.braze.changeUser(user.id)
    }
  }

  handleConsent = () => {
    const isFunctionalOptIn = checkConsent('bb')
    const isPerformanceOptIn = checkConsent('pf')
    const isSocialOptIn = checkConsent('sm')
    const isTargetingOptIn = checkConsent('ad')

    if (isPerformanceOptIn) {
      this.initSegmentPostConsent()
      this.initGoogleTagManager()
    }

    if (isTargetingOptIn) {
      this.initBraze()
    }

    this.setState({
      isFunctionalOptIn,
      isPerformanceOptIn,
      isSocialOptIn,
      isTargetingOptIn,
    })
  }

  initBraze = () => {
    const { user } = this.props

    import('@braze/web-sdk').then(
      (braze) => {
        this.braze = braze

        braze.initialize(
          config.metrics.brazeApiKey,
          {
            allowUserSuppliedJavascript: true,
            baseUrl: 'sdk.iad-03.braze.com',
          }
        )

        braze.automaticallyShowInAppMessages()

        if (user?.id) {
          braze.changeUser(user.id)
        }

        braze.openSession()
        this.setState({ isBrazeLoaded: true })
      }
    )
  }

  defineFacebookInit = () => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents : false,
        xfbml            : true,
        version          : 'v15.0'
      })
    }
  }

  loadFacebookSDK = () => {
    ; (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      const js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  initFacebook = () => {
    // NOTE: These steps must be called in this order. The init function `fbAsyncInit` MUST be defined before the SDK has loaded, or else it will not be called.
    // Step 1/2, define the init function.
    this.defineFacebookInit()
    // Step 2/2, load the SDK.
    this.loadFacebookSDK()
  }

  initGoogleTagManager = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', `${config.metrics.gtmAccount}`,
      { 'optimize_id': `${config.metrics.googleContainer}` }
    );
  }

  initSegmentPreConsent = () => {
    ! function () {
      analytics = window.analytics = window.analytics || [];
      if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
        else {
          analytics.invoked = !0;
          analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
          analytics.factory = function (e) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics
            }
          };
          for (var e = 0; e < analytics.methods.length; e++) {
            var key = analytics.methods[e];
            analytics[key] = analytics.factory(key)
          }
          analytics.load = function (link, e) {
            var t = document.createElement("script");
            t.type = "text/javascript";
            t.async = !0;
            t.src = link;
            var n = document.getElementsByTagName("script")[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e
          };
          // We have to wait for consent to do this part. See "initSegmentPostConsent".
          // window.analytics.SNIPPET_VERSION = "4.13.1";
          // window.analytics.load(config.metrics.segmentLink);
        }
    }()


    // We have to wait for consent to do this part. See "initSegmentPostConsent".
    // Once Segment has loaded, populate the GrowthBook anonymous ID from it.
    // window.analytics.ready(() => {
    //   resetGrowthBookUserId()
    // })
  }

  initSegmentPostConsent() {
    window.analytics.SNIPPET_VERSION = "4.13.1";
    window.analytics.load(config.metrics.segmentLink);
    window.analytics.ready(() => resetGrowthBookUserId())
  }

  render() {
    const { featureFlags } = this.props
    const {
      isFunctionalOptIn,
      isPerformanceOptIn,
      isTargetingOptIn,
    } = this.state

    return (
      <Helmet>
        {(isPerformanceOptIn) && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${
              config.metrics.gtmAccount
              }`}
          />
        )}

        {(isFunctionalOptIn) && (
          <script
            async
            defer
            importance='low'
            src={`//cdn.iframe.ly/embed.js?api_key=${
              process.env.REACT_APP_IFRAMELY_KEY
              }`}
          />
        )}

        {(isTargetingOptIn) && (
          <script async src='//static.criteo.net/js/ld/ld.js' />
        )}
      </Helmet>
    )
  }
}

export default ExternalScripts
