import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { MemoCloudinaryImg, PremiumBadge } from 'common/components'
import QueueToggleIcon from 'common/containers/QueueToggle/IconBtn'
import { getImageSrc } from 'common/helpers'
import showPosterImage from 'common/helpers/showPosterImage'

import './styles.scss'

class ShowCard extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onFollowSeriesClick: PropTypes.func,
    onThumbnailClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    onUnfollowSeriesClick: PropTypes.func,
    width: PropTypes.string,
  }

  static defaultProps = {
    onFollowSeriesClick: () => null,
    onThumbnailClick: () => null,
    onTitleClick: () => null,
    onUnfollowSeriesClick: () => null,
    width: 'auto',
  }

  state = {
    hovered: false,
  }

  getUuid() {
    const { item } = this.props
    return item.attributes.uuid || item.uuid
  }

  render() {
    const { poster, item, width, lazy } = this.props
    const { hovered } = this.state
    const imgSrc = poster
      ? showPosterImage(item)
      : getImageSrc(item.included.images, 'small', 'title_card')
    const cloudinaryPublicId = imgSrc?.split('f_auto')[1]
    const isPremium = item.attributes.is_sponsors_only
    const responsive = width === 'auto'
    return (
      <div
        className={`show-card ${
          poster ? 'show-card--has-poster-image' : 'show-card--no-poster-image'
        }`}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <div className='card-content'>
          <div className='card-image-wrapper'>
            <Link to={`/series/${item.attributes.slug}`}>
              {cloudinaryPublicId ? (
                <MemoCloudinaryImg
                  lazy={lazy}
                  src={cloudinaryPublicId}
                  responsive={responsive}
                  width={width}
                />
              ) : (
                <img
                  className='show-card__poster-image'
                  src={imgSrc}
                  alt='Series Poster Image'
                />
              )}
              {isPremium && <PremiumBadge />}
            </Link>
            <div className='btns'>
              <Link
                className='card-link'
                onClick={this.props.onThumbnailClick}
                to={`/series/${item.attributes.slug}`}
              />
              <div className='bottom'>
                <Link
                  className='title'
                  onClick={this.props.onTitleClick}
                  title={item.attributes.title}
                  to={`/series/${item.attributes.slug}`}
                >
                  {item.attributes.title}
                  <div className='meta'>
                    {item.attributes.season_count === 1 ? (
                      <span className='season-info'>
                        {item.attributes.season_count} season
                      </span>
                    ) : (
                      <span className='season-info'>
                        {item.attributes.season_count} seasons
                      </span>
                    )}
                  </div>
                </Link>
                {hovered && (
                  <QueueToggleIcon
                    className='icon-btn'
                    onAddClick={() => this.props.onFollowSeriesClick(item)}
                    onRemoveClick={() => this.props.onUnfollowSeriesClick(item)}
                    type={item.type}
                    uuid={this.getUuid()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ShowCard
