import React, { useEffect } from 'react'
import { Transition } from 'react-transition-group'

import BigSidewaysUsername from 'myHubApp/components/BigSidewaysUsername'
import CloseButton from 'myHubApp/components/CloseButton'
import LoadingMessages from 'myHubApp/components/LoadingMessages'
import MobileMainWelcome from 'myHubApp/components/MobileMainWelcome'
import MyHubContentArea from 'myHubApp/components/MyHubContentArea'
import ProfileImage from 'myHubApp/components/ProfileImage'
import WelcomeMessage from 'myHubApp/components/WelcomeMessage'
import {
  useHubBodyClass,
  useHubClickHandlers,
  useLoadingScreen,
} from 'myHubApp/hooks'

const loadingMessages = [
  'Building the RT Podcast Set...',
  'Assessing the latest Death Battle data...',
  "Applying Geoff's tattoos...",
]

const MyHubInner = ({
  closeMyHub,
  feed,
  feedType,
  fetchMyHubData,
  profileImage,
  showLoadingScreen,
  transitionState,
  username,
  waitForUserObject,
}) => {
  // Fetch hub data on load.
  useEffect(
    () => {
      if (waitForUserObject) return
      fetchMyHubData()
    },
    [feedType, fetchMyHubData, waitForUserObject]
  )

  const isLoading = useLoadingScreen({ duration: 3000, showLoadingScreen })
  const loaded = !isLoading

  const { handleCloseButtonClick } = useHubClickHandlers({
    closeMyHub,
    loaded,
  })

  useHubBodyClass()

  return (
    <div
      className={`my-hub my-hub--${transitionState} ${
        loaded ? 'my-hub--display-main' : ''
      }`}
    >
      <div className='my-hub__blurred-bg' />
      <CloseButton onClick={handleCloseButtonClick} />
      <ProfileImage profileImage={profileImage} />
      <WelcomeMessage loaded={loaded} username={username} />
      <LoadingMessages
        loaded={loaded}
        messageDurationMs={2000}
        messages={loadingMessages}
      />
      <BigSidewaysUsername loaded={loaded} username={username} />
      <MobileMainWelcome username={username} loaded={loaded} />
      <MyHubContentArea feed={feed} loaded={loaded} />
    </div>
  )
}

const INITIAL_HUB_ANIMATION_DURATION = 750

const MyHub = ({
  closeMyHub,
  feed,
  feedType,
  fetchMyHubData,
  profileImage,
  shouldDisplayHub,
  showLoadingScreen,
  username,
  waitForUserObject,
}) =>
  !waitForUserObject ? (
    <Transition
      in={shouldDisplayHub}
      timeout={{ enter: 0, exit: INITIAL_HUB_ANIMATION_DURATION }}
      unmountOnExit
    >
      {state => (
        <MyHubInner
          closeMyHub={closeMyHub}
          feed={feed}
          feedType={feedType}
          fetchMyHubData={fetchMyHubData}
          profileImage={profileImage}
          showLoadingScreen={showLoadingScreen}
          transitionState={state}
          username={username}
          waitForUserObject={waitForUserObject}
        />
      )}
    </Transition>
  ) : null

export default MyHub
