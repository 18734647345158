import React from 'react'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'

import { TabFocus } from 'common/components'

import {
  InlineLikeBase,
  InlineLikeIconEmpty,
  InlineLikeIconFilled,
  InlineLikeIcons,
  InlineLikeText,
} from './styled'

function InlineLike({ className, count, isLiked, onClick }) {
  return (
    <TabFocus>
      <InlineLikeBase
        aria-label={isLiked ? 'Remove like' : 'Like'}
        className={className}
        onClick={onClick}
      >
        <InlineLikeIcons isLiked={isLiked}>
          <InlineLikeIconEmpty />
          <InlineLikeIconFilled isLiked={isLiked} />
        </InlineLikeIcons>

        {count > 0 ? (
          <InlineLikeText aria-label={pluralize('like', count, true)}>
            {count}
          </InlineLikeText>
        ) : (
          <InlineLikeText>Like</InlineLikeText>
        )}
      </InlineLikeBase>
    </TabFocus>
  )
}

InlineLike.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  isLiked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

InlineLike.defaultProps = {
  className: '',
}

export default InlineLike
