import { getRssLoginUrl, getUserTier } from 'auth/selectors'
import { showModal } from 'modalApp/actions'
import { setPendingRssActions } from 'showsApp/actions/rssActions'
import {
  getAuthenticatedSeriesFeedUrl,
  getPendingRssActions,
} from 'showsApp/selectors'

export const handlePostLoginRssActions = () => (dispatch, getState) => {
  const state = getState()
  const userTier = getUserTier(state)
  const seriesFeedUrl = getAuthenticatedSeriesFeedUrl(state)
  const rssLoginUrl = getRssLoginUrl(state)
  const isPendingRssAction = getPendingRssActions(state)
  const supportingCastPage = seriesFeedUrl || rssLoginUrl
  if (isPendingRssAction && userTier !== 'anon') {
    if (userTier === 'first') {
      window.open(supportingCastPage, '_blank')
    } else {
      dispatch(showModal('FIRST_ONLY_RSS'))
    }
    dispatch(
      setPendingRssActions({
        isPending: false,
        seriesUuid: null,
      })
    )
  }
}
