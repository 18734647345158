import styled from 'styled-components'

export const AvatarBase = styled.img`
  border-radius: 999px;
  box-sizing: border-box;
  object-fit: cover;

  ${({ border }) => border && ` border: ${border}px solid #f7f7f8; `}
`

export const AvatarBaseNoSrc = styled.div`
  border-radius: 999px;
  box-sizing: border-box;
  object-fit: cover;

  ${({ border }) => border && ` border: ${border}px solid #f7f7f8; `}
`
