export const channelGenreKey = (channelSlug, genreName) =>
  `${channelSlug}$$${genreName}`

export const getChannelGenres = channels => {
  const channelGenres = new Set()
  for (const channel of channels) {
    const channelSlug = channel.attributes.slug
    for (const genre of channel?.included?.genres || []) {
      const genreName = genre?.attributes?.name
      if (genreName) {
        channelGenres.add(channelGenreKey(channelSlug, genreName))
      }
    }
  }
  return channelGenres
}
