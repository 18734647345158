export const getEpisode = state => state.episodeReducer.episode
export const getRelated = state => state.episodeReducer.related
export const getEpisodes = state => getEpisode(state).episodes
export const getNext = state => getRelated(state).next

export const getEpisodeBySlug = (state, slug) => {
  if (!slug) return
  return getEpisodes(state).find(episode => {
    if (Object.keys(episode).length !== 0) {
      return episode.attributes.slug === slug
    }
    return false
  })
}

export const getNextEpisodesBySlug = (state, slug) => {
  if (!slug) return
  return getNext(state).find(nextItem => {
    if (Object.keys(nextItem).length !== 0) {
      return nextItem.slug === slug
    }
    return false
  })
}
