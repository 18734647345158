import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { config } from 'common/config'
import handleHomeClick from 'common/helpers/handleHomeClick'
import handleNavStickiness from 'common/helpers/handleNavStickiness'
import { trackClick, trackSignupButtonClick } from 'common/metrics'

import RTX2023EasterEgg from 'rootApp/components/RTX2023EasterEgg'
import ValentinesHearts from 'rootApp/components/ValentinesHearts'
import StinkyDragonButton from 'rootApp/containers/StinkyDragonButton'
import SupportUkraineNavButton from 'rootApp/containers/SupportUkraineNavButton'
import UnoInfiniteNavButton from 'rootApp/containers/UnoInfiniteNavButton'

import CommunityNav from '../../containers/CommunityNav'
import Notifications from '../../containers/Notifications'
import UserPanel from '../../containers/UserPanel'
import PrideHearts from '../PrideHearts'
import RtxCountdown from '../RTXCountdown'
import WomensHistoryMonthEasterEgg from '../WomensHistoryMonthEasterEgg'
import SearchBtn from './SearchBtn'
import TryFirst from './TryFirst'

import './styles.scss'

class TopNav extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    pathname: PropTypes.string.isRequired,
    subscribed: PropTypes.bool.isRequired,
    user: PropTypes.object,
    featureFlags: PropTypes.object.isRequired,
  }

  static defaultProps = {
    user: {},
  }

  state = {
    isSticky: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledHandleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledHandleScroll)
  }

  handleHomeClick = e => {
    const { pathname } = this.props
    handleHomeClick(e, pathname, 'header_home_button')
  }

  handleSearchClick = () => {
    trackClick('nav_search')
    this.props.openSearchV2()
  }

  isCommunity = () => {
    const { pathname } = this.props

    return pathname.startsWith('/g')
  }

  isShowsV2 = () => {
    const { pathname, featureFlags } = this.props

    return pathname.startsWith('/series') && featureFlags.showsV2
  }

  showCommunityNav = () =>
    // The community nav was deprecated in community V2.
    this.isCommunity() && !this.props.featureFlags.newCommunity

  throttledHandleScroll = () => {
    window.requestAnimationFrame(this.toggleStickiness)
  }

  toggleStickiness = () => {
    this.setState({ isSticky: handleNavStickiness() })
  }

  render() {
    const { isAuthenticated, subscribed, user, featureFlags } = this.props
    const { isSticky } = this.state

    const rtxDate = config.dates.rtx

    const prideMonth = !!featureFlags.prideMonth
    const womensHistoryMonth = !!featureFlags.womensHistoryMonth

    const useStickyNav = isSticky || this.isCommunity()
    // For the shows grid page, use a sticky nav but don't make it opaque.
    const useOpaqueNav = useStickyNav && !this.isShowsV2()

    return (
      <header
        className={classnames('top-nav', {
          'top-nav--sticky': useStickyNav,
          'top-nav--opaque': useOpaqueNav,
          'top-nav--pride': prideMonth,
        })}
      >
        <div className='top-nav__site-section'>
          <a
            aria-label='Toggle side nav'
            className='top-nav__toggle sidenav-trigger'
            data-target='slide-out'
            href='#toggle-sidenav'
            onClick={() => trackClick('header_open_nav')}
          >
            <i className='icon-menu' />
          </a>

          <Link
            aria-label='Home'
            className='top-nav__logo qa-top-nav-logo'
            onClick={this.handleHomeClick}
            to='/'
          >
            <i className='top-nav__logo-icon icon-logomark' />
          </Link>
        </div>

        {this.showCommunityNav() && <CommunityNav user={user} />}

        {featureFlags.rtxCountdown && rtxDate ? (
          <RtxCountdown date={rtxDate} />
        ) : null}

        <div className='top-nav__account-section'>
          {!subscribed && (
            <TryFirst
              isAuthenticated={isAuthenticated}
              onClick={() => trackSignupButtonClick('nav_try_first')}
            />
          )}
          {featureFlags.unoInfiniteButton ? <UnoInfiniteNavButton /> : null}
          {featureFlags.stinkyDragonButton ? <StinkyDragonButton /> : null}
          {featureFlags.supportUkraineButton ? (
            <SupportUkraineNavButton />
          ) : null}
          {prideMonth ? <PrideHearts /> : null}
          {womensHistoryMonth ? <WomensHistoryMonthEasterEgg /> : null}
          {featureFlags.valentinesHearts ? <ValentinesHearts /> : null}
          {featureFlags.rtx23CharactersEasterEgg ? <RTX2023EasterEgg /> : null}
          <SearchBtn onClick={this.handleSearchClick} />
          {isAuthenticated ? <Notifications /> : null}
          <UserPanel />
        </div>
      </header>
    )
  }
}

export default TopNav
