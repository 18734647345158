import { useEffect } from 'react'

import { sendGA } from 'common/metrics'

const useInitialMount = ({ closeSearchV2, searchRef, searchTerm }) => {
  useEffect(
    () => {
      searchRef.current?.focus()

      document.documentElement.classList.add('disable-scroll')

      const eventListener = ev => {
        // escape key, close search
        if (ev.keyCode === 27) {
          ev.preventDefault()
          closeSearchV2('manual')

          sendGA({
            event: 'searchEvent',
            eventCategory: 'Search Action',
            eventAction: 'Closed',
            eventLabel: searchTerm,
          })
          document.documentElement.classList.remove('disable-scroll')
        }
      }
      document.addEventListener('keyup', eventListener, { capture: true })

      return () => {
        document.documentElement.classList.remove('disable-scroll')
        document.removeEventListener(eventListener)
      }
    },
    [closeSearchV2, searchRef, searchTerm]
  )
}

export default useInitialMount
