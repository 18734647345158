import React from 'react'

import ExternalLink from '../ExternalLink'

const discordHref = 'https://discord.com/invite/roosterteeth'
const facebookHref = 'https://www.facebook.com/roosterteeth'
const instagramHref = 'https://www.instagram.com/roosterteeth'
const tiktokHref = 'https://www.tiktok.com/@roosterteeth'
const twitterHref = 'https://twitter.com/RoosterTeeth'
const youtubeHref = 'https://www.youtube.com/roosterteeth'

const SocialLinks = ({ onClick = () => {} }) => (
  <>
    <ExternalLink
      aria-label='Twitter'
      href={twitterHref}
      onClick={() => onClick(twitterHref)}
    >
      <i className='icon-twitter' />
    </ExternalLink>
    <ExternalLink
      aria-label='Instagram'
      href={instagramHref}
      onClick={() => onClick(instagramHref)}
    >
      <i className='icon-instagram' />
    </ExternalLink>
    <ExternalLink
      aria-label='Facebook'
      href={facebookHref}
      onClick={() => onClick(facebookHref)}
    >
      <i className='icon-facebook' />
    </ExternalLink>
    <ExternalLink
      aria-label='YouTube'
      href={youtubeHref}
      onClick={() => onClick(youtubeHref)}
    >
      <i className='icon-youtube' />
    </ExternalLink>
    <ExternalLink
      aria-label='Discord'
      href={discordHref}
      onClick={() => onClick(discordHref)}
    >
      <i className='icon-discord-2' />
    </ExternalLink>
    <ExternalLink
      aria-label='TikTok'
      href={tiktokHref}
      onClick={() => onClick(tiktokHref)}
    >
      <i className='icon-tiktok' />
    </ExternalLink>
  </>
)

export default SocialLinks
