import React from 'react'
import PropTypes from 'prop-types'

function MailtoLink({ children, email, ...moreProps }) {
  return (
    <a href={`mailto:${email}`} {...moreProps}>
      {children || email}
    </a>
  )
}

MailtoLink.propTypes = {
  children: PropTypes.node,
  email: PropTypes.string.isRequired,
}

export default MailtoLink
