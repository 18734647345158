import rtApi from '@roosterteethproductions/svod-api'
import Honeybadger from 'honeybadger-js'
import { call, put } from 'redux-saga/effects'

import { getUserError, getUserRequest, getUserSuccess } from 'auth/actions'

function* getUserObjectSaga() {
  yield put(getUserRequest())
  const result = yield call(rtApi.users.fetchMyUser)

  if (result.type !== 'user') {
    // We don't expect errors here because they should've happened at account creation rather than the subsequent call to fetchMyUser.
    Honeybadger.notify(
      'An unexpected error occured while trying to fetch a user object.',
      { context: { fetchUserApiResponse: result } }
    )

    yield put(getUserError('An unknown error occured.'))
  }

  yield put(getUserSuccess(result))

  return result
}

export default getUserObjectSaga
