/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { RichMedia } from 'common/components'
import Post from 'common/containers/Post'
import { getUrls } from 'common/helpers'

function getPostId(urlString) {
  if (!urlString) {
    return false
  }

  try {
    const url = new URL(urlString)

    if (
      !url.hostname.endsWith('roosterteeth.com') &&
      url.hostname !== 'localhost'
    ) {
      return false
    }

    const matches = url.pathname.match(/^\/g\/post\/([^/?]+)$/)
    if (matches && matches.length > 1) {
      return matches[1]
    }
  } catch (e) {
    // console.error('could not match post url', e)
  }
}

/**
 * Attempts to render the first oembedabble link from the input text
 */
export class RichMediaContainer extends Component {
  static propTypes = {
    text: PropTypes.string,
    // rendering posts in comments crashes the app. joy. temporarily disabling
    // post rendering in comments
    disablePosts: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    disablePosts: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      links: [],
      linkIdx: 0,
      postId: null,
      ...this.parseText(this.props.text),
    }

    // TODO: debounce parse requests
    this.throttledParseText = debounce(this.parseText, 200)
  }

  // --------
  // Lifecycle
  // --------
  UNSAFE_componentWillReceiveProps(nextProps) {
    // if we're updating the text, skip trying to parse url validity
    if (this.props.text !== nextProps.text) {
      return this.setState(this.throttledParseText(this.props.text))
    }

    if (nextProps.isValid === true) {
      return this.setState({ validUrl: nextProps.checkUrl })
    }

    if (
      this.props.isChecking !== nextProps.isChecking &&
      nextProps.isValid === false
    ) {
      this.incrementLinkCheck()
    }
  }

  // --------
  // Methods
  // --------
  parseText = text => {
    if (!text) {
      return
    }
    const links = getUrls(text)

    const postId = getPostId(links[0])
    if (postId) {
      return { postId, validUrl: null }
    }
    return { validUrl: links[0] }

    // TODO: once we figure out api access or, if we even want it
    // update state, then check our links
    // this.setState({links: links, linkIdx: 0}, () => {
    //   console.log('this should trigger linkcheck')
    //   this.incrementLinkCheck()
    // })
  }

  incrementLinkCheck() {
    const { links, linkIdx } = this.state
    const { dispatch } = this.props
    const url = links[linkIdx]
    if (!url) {
      return
    }
    this.setState({ linkIdx: linkIdx + 1 })
    dispatch({ type: 'common/RICHMEDIA_CHECK_LINK', url })
  }

  // --------
  // Render
  // --------
  render() {
    // TODO: swap this back to a prop from the reducer
    // @note we need to supply a key prop to the RichMedia instance
    // to guarantee that it un-renders whenever the valid url changes
    const { validUrl, postId } = this.state
    const { disablePosts } = this.props

    if (!disablePosts && postId) {
      return <Post id={postId} repost />
    }
    return (
      <div>
        {validUrl && (
          <RichMedia url={validUrl} key={`rich-media-${validUrl}`} />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { isChecking, checkUrl, isValid } = state.commonReducer.richMedia

  return {
    isChecking,
    checkUrl,
    isValid,
  }
}

export default connect(mapStateToProps)(RichMediaContainer)
