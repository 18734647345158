/**
 * @param {string} text - what you want copied to the users clipboard
 * @param {HTMLElement} domParent - where to append a hidden text area to, sometimes document.body doesn't work when the element is in a modal - no idea why but providing an element closer to the text you are interacting with seems to fix this
 */
export const copyToClipboard = (text, domParent = document.body) => {
  const isIos = window.navigator.userAgent.match(/ipad|iphone/i)
  const textarea = document.createElement('textarea')
  let range
  let selection

  textarea.value = text
  textarea.readOnly = true

  // Hide textarea when appended (see .visuallyhidden)
  textarea.style.cssText =
    'border:0;clip:rect(0 0 0 0);clip-path:inset(50%);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;white-space:nowrap;width:1px'

  domParent.appendChild(textarea)

  if (isIos) {
    range = document.createRange()
    range.selectNodeContents(textarea)
    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    textarea.setSelectionRange(0, 999999)
  } else {
    textarea.select()
  }

  document.execCommand('copy')
  domParent.removeChild(textarea)
}

export const testClipboardSupport = () =>
  Boolean(document.queryCommandSupported('copy'))
