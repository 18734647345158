import { useCallback } from 'react'

import { trackEvent } from 'common/metrics'

const useContentBlockCarouselItemTracking = ({
  contentBlock,
  item,
  itemIndex,
  location,
  perPage,
}) =>
  useCallback(
    () => {
      trackEvent('Content Block Carousel Item Clicked', {
        // Use "1-indexed" item indices for Data.
        carousel_item_index: itemIndex + 1,
        carousel_per_page: perPage,
        // Use "1-indexed" page indices for Data. For example, if we're on offset 17 with a page size of 5, we're on the "fourth" page.
        carousel_item_page: Math.floor(itemIndex / perPage) + 1,
        content_block_display_type: contentBlock?.attributes?.display_type,
        content_block_uuid: contentBlock?.attributes?.uuid,
        item_type: item?.type,
        // Bonus Features have item.uuid but not item.attributes.uuid
        item_uuid: item?.attributes?.uuid || item?.uuid,
        location,
      })
    },
    [contentBlock, item, itemIndex, location, perPage]
  )

export default useContentBlockCarouselItemTracking
