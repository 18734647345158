import React from 'react'

import MerchCardV2 from 'common/components/MerchCardV2'
import { useToggle } from 'common/hooks'
import useGenericContentBlockItemTracking from 'common/hooks/useGenericContentBlockItemTracking'
import useRefScrolledIntoView from 'common/hooks/useRefScrolledIntoView'

import placeholder from '../MerchCardV2/placeholder.gif'
import ContentBlockBase, { ContentBlockHeaderLink } from './ContentBlockBase'

const PlaceholderElems = () => {
  const elems = []

  for (let index = 1; index <= 6; index += 1)
    elems.push(<MerchCardV2 key={index} imageSrc={placeholder} />)

  return elems
}

const MerchCollectionCardOuter = ({
  contentBlock,
  item,
  location,
  merchType,
}) => {
  const handleClick = useGenericContentBlockItemTracking({
    contentBlock,
    item,
    location,
  })

  let imageUrl
  // For store_carousel, each tile is a collection of products.
  if (merchType === 'store_carousel') {
    imageUrl = item.attributes.original_image

    // Otherwise we're using merch_carousel, in which each tile is an individual product.
  } else {
    imageUrl = item.attributes.image_url
  }

  return (
    <MerchCardV2
      cloudinaryPublicId={imageUrl}
      href={item.attributes.link}
      onClick={handleClick}
    />
  )
}

const ContentBlockMerchGroup = ({
  contentBlock,
  items,
  headerUrl,
  location,
  merchType,
  title1,
  title2,
}) => {
  const [inView, setInView] = useToggle()
  const contentBlockRef = useRefScrolledIntoView(setInView, {
    topOffset: 500,
    bottomOffset: 500,
    mode: 'any-visible',
  })
  const limitedItems = items.slice(0, 6)
  return (
    <ContentBlockBase ref={contentBlockRef}>
      <div className='content-block__merch-group-container'>
        <ContentBlockHeaderLink
          contentBlock={contentBlock}
          header1={title1}
          header2={title2}
          location={location}
          url={headerUrl}
        />
        <div className='content-block__merch-group-items'>
          {inView ? (
            limitedItems.map(item => (
              <MerchCollectionCardOuter
                contentBlock={contentBlock}
                item={item}
                key={item.attributes.uuid}
                location={location}
                merchType={merchType}
              />
            ))
          ) : (
            <PlaceholderElems />
          )}
        </div>
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockMerchGroup
