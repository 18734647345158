import {
  GET_FEATURED_EP_REQUEST,
  GET_FEATURED_EP_SUCCESS,
  GET_FEATURED_GEAR_FAILURE,
  GET_FEATURED_GEAR_REQUEST,
  GET_FEATURED_GEAR_SUCCESS,
  GET_FEATURED_ITEM_V3_REQUEST,
  GET_FEATURED_ITEM_V3_SUCCESS,
  GET_FEATURED_SHOW_SUCCESS,
} from '../actions/types'

const initialState = {
  isFetching: false,
  featuredGear: [],
  featuredItems: {},
  featuredItemsV3: {},
}

// we have a single page app per channel so no need to store more than one featured in our state
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURED_EP_REQUEST:
      return {
        ...state,
        isFetching: true,
        featuredItems: {
          ...state.featuredItems,
          [action.channel]: { episode: {}, show: {}, collection: {} },
        },
      }
    case GET_FEATURED_ITEM_V3_REQUEST: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case GET_FEATURED_EP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        featuredItems: {
          ...state.featuredItems,
          [action.channel]: {
            attributes: action.attributes,
            episode: action.item,
            collection: action.collection,
            show: {},
          },
        },
      }
    case GET_FEATURED_ITEM_V3_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        // Objects from the new Featured Item endpoint have a totally different (much flatter) shape, so let's keep them in a different object until the previous Featured Items endpoint is deprecated.
        featuredItemsV3: {
          ...state.featuredItems,
          [action.channel]: action.item,
        },
      }
    }
    case GET_FEATURED_SHOW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        featuredItems: {
          ...state.featuredItems,
          [action.channel]: {
            attributes: state.featuredItems[action.channel].attributes,
            episode: state.featuredItems[action.channel].episode,
            show: action.item,
            collection: action.collection,
          },
        },
      }
    case GET_FEATURED_GEAR_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      }
    case GET_FEATURED_GEAR_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
      }
    case GET_FEATURED_GEAR_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        lastUpdated: action.receivedAt,
        featuredGear: action.data,
      }
    default:
      return state
  }
}
