import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const QueueToggleBlankBtn = React.forwardRef(
  ({ className, isQueued, toggleQueue, ...props }, ref) => (
    <button
      aria-checked={isQueued}
      className={classnames('queue-toggle__blank-btn', className)}
      onClick={toggleQueue}
      ref={ref}
      role='switch'
      type='button'
      {...props}
    />
  )
)

QueueToggleBlankBtn.propTypes = {
  className: PropTypes.string,
}

QueueToggleBlankBtn.defaultProps = {
  className: '',
}

export default QueueToggleBlankBtn
