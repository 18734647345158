export const removeParamFromSearchString = (queryString, param) => {
  const searchParams = new URLSearchParams(queryString)
  searchParams.delete(param)
  return searchParams.toString()
}

export const setParamInSearchString = (queryString, param, value) => {
  const searchParams = new URLSearchParams(queryString)
  searchParams.set(param, value)
  return searchParams.toString()
}
