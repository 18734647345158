import { useMemo } from 'react'

const useGradient = brandColor =>
  useMemo(
    () => {
      if (!brandColor) {
        return {
          // Default gradient when brand color is not present.
          background: `radial-gradient(closest-side at center, rgba(76, 103, 220, 0.59) 0%, rgba(76, 103, 220, 0.43) 40.34%, rgba(24, 160, 251, 0) 100%)`,
        }
      }

      // When brand color is present, use it for the gradient.
      const transparent = `#${brandColor}00`
      const translucent = `#${brandColor}30`
      const opaque = `#${brandColor}60`
      return {
        background: `radial-gradient(closest-side at center, ${opaque} 0%, ${translucent} 30.34%, ${transparent} 100%)`,
      }
    },
    [brandColor]
  )

export default useGradient
