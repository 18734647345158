const CLOUDINARY_URL = 'https://res.cloudinary.com/rtv3/image/upload/static'

export const fallbackTitle = 'Rooster Teeth'
export const fallbackOgTitle = 'The funniest content on the Internet'
export const fallbackDescription = `Watch wild gameplay, creative animation, and hilarious podcasts on Rooster Teeth.`
const twitterImage = `${CLOUDINARY_URL}/rt_twitter_og_card.png`
const ogImage = `${CLOUDINARY_URL}/rt_preview_og_card.png`
const defaultSiteDescription =
  'Rooster Teeth is the home of premium dumb content. Watch comedy skits, podcasts, stunning animation, gameplay commentary, and more.'
export const defaultTmplParams = {
  settings: {
    'view options': {
      delimiter: '|',
      rmWhitespace: true,
      compileDebug: process.env.NODE_ENV !== 'production',
      cache: true,
    },
  },
  title: fallbackTitle,
  ogType: 'website',
  ogTitle: fallbackOgTitle,
  twitterTitle: fallbackOgTitle,
  ogDescription: fallbackDescription,
  crawlerDescription: defaultSiteDescription,
  twitterDescription: fallbackDescription,
  ogImage: ogImage,
  twitterImage: twitterImage,
  ogURL: 'https://roosterteeth.com',
  twitterPlayer: '',
  twitterCard: 'summary_large_image',
  twitterSite: '@RoosterTeeth',
  twitterHeight: 360,
  twitterWidth: 640,
  oembedURL: '',
  oembedTitle: '',
  iphoneAppId: 1012562632,
  googleplayId: 'com.roosterteeth.roosterteeth',
  iphoneUrl: 'rt://',
  googleplayUrl: 'rt://',
  bootstrapData: [],
}

for (let key in process.env) {
  if (key.startsWith('REACT_APP')) {
    defaultTmplParams[key] = process.env[key]
  }
}
