import React, { useCallback, useEffect, useMemo } from 'react'
import Honeybadger from 'honeybadger-js'
import { connect } from 'react-redux'

import ExternalLink from 'common/components/ExternalLink'
import { useDateBasedFeatureFlag } from 'common/hooks'
import { trackEvent } from 'common/metrics'

import { getLoggedInUserId, getRssLoginUrl, getUserTier } from 'auth/selectors'
import { hideModal, showModal } from 'modalApp/actions'
import { setPendingRssActions } from 'showsApp/actions/rssActions'
import { handlePostLoginRssActions } from 'showsApp/operations'
import {
  getAuthenticatedSeriesFeedUrl as getSeriesFeedUrl,
  getRssLoginToken,
  getSeriesUuid,
} from 'showsApp/selectors'

import { SERIES_RSS_BUTTON_CLICKED } from './constants'

import './styles.scss'

export const RssButtonWrapper = ({
  children,
  classname = '',
  handlePostLoginRssActions,
  isSeriesPage = false,
  location,
  rssLoginToken,
  rssLoginUrl,
  seriesFeedUrl,
  seriesUuid,
  setPendingRssActions,
  showRssModal,
  userId,
  userTier,
}) => {
  const rssUrl = useMemo(() => seriesFeedUrl || rssLoginUrl, [
    seriesFeedUrl,
    rssLoginUrl,
  ])

  const shouldHideButton = useDateBasedFeatureFlag('disableFirstUpgradesDate')

  useEffect(
    () => {
      !!rssLoginToken && handlePostLoginRssActions()
    },
    [rssLoginToken, handlePostLoginRssActions]
  )

  useEffect(
    () => {
      const checkMissingTokenTimeoutId = setTimeout(() => {
        if (userTier === 'first' && !rssLoginToken) {
          Honeybadger.notify(new Error('FIRST user has no RSS token'), {
            context: { userId },
          })
        }
      }, 1500)
      return () => clearTimeout(checkMissingTokenTimeoutId)
    },
    [rssLoginToken, userTier, userId]
  )

  const sendTrackingData = useCallback(
    () =>
      trackEvent(SERIES_RSS_BUTTON_CLICKED, {
        series_uuid: seriesUuid,
        user_tier: userTier,
      }),
    [seriesUuid, userTier]
  )

  const handleButtonClick = () => {
    sendTrackingData()
    setPendingRssActions({ isPending: true, seriesUuid })
    showRssModal({
      redirectUrl: location.pathname,
      seriesUuid,
      userTier,
    })
  }

  const isShowWithoutFeed = isSeriesPage && !seriesFeedUrl
  const isFirstWithoutToken = userTier === 'first' && !rssLoginToken

  if (isShowWithoutFeed || isFirstWithoutToken) return null

  if (userTier === 'first') {
    return (
      <ExternalLink
        aria-label='RSS Button'
        className={classname}
        href={rssUrl}
        onClick={sendTrackingData}
        data-testid='rss-wrapper-link'
      >
        {children}
      </ExternalLink>
    )
  }

  if (shouldHideButton) {
    return null
  }

  return (
    <button
      type='button'
      className='rss-button__button-wrapper'
      onClick={handleButtonClick}
      data-testid='rss-wrapper-button'
    >
      {children}
    </button>
  )
}

const mapStateToProps = state => {
  const { location } = state.router
  const userTier = getUserTier(state)
  const rssLoginUrl = getRssLoginUrl(state)
  const rssLoginToken = getRssLoginToken(state)
  const seriesFeedUrl = getSeriesFeedUrl(state)
  const seriesUuid = getSeriesUuid(state)
  const userId = getLoggedInUserId(state)

  return {
    location,
    rssLoginToken,
    rssLoginUrl,
    seriesFeedUrl,
    seriesUuid,
    userId,
    userTier,
  }
}

const mapDispatchToProps = dispatch => ({
  handlePostLoginRssActions: () => dispatch(handlePostLoginRssActions()),
  showRssModal: modalProps => dispatch(showModal('FIRST_ONLY_RSS', modalProps)),
  hideRssModal: () => dispatch(hideModal('FIRST_ONLY_RSS')),
  dispatch,
  setPendingRssActions: isPendingRssAction =>
    dispatch(setPendingRssActions(isPendingRssAction)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RssButtonWrapper)
