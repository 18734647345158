const feedInitialState = {
  list: [],
  pagination: {
    previous: null,
    next: null,
  },
  count: null,
  isFetching: false,
  stickiedPostId: null,
}
const initialState = { feedInitialState }

function getPostId(item) {
  if (item.post) {
    return item.post.id
  } else {
    return item.id
  }
}

/**
 * Because feeds have a common format and set of interactions we centeralize them
 * here. Try not to make your own. Just store the id/slug elsewhere
 */
export default (
  state = initialState,
  { type, data, pagination, req, images, resp, ...rest }
) => {
  switch (type) {
    // reset all our feed data when our session changes
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/c/createPost_RESP':
    case 'common/USER_POST_RESP':
      const feedId = data.owner.data.id
      if (!state[feedId]) {
        // no point in adding to a feed we dont have
        return state
      }
      return {
        ...state,
        [feedId]: {
          ...state[feedId],
          list: [data.id, ...state[feedId].list],
        },
      }

    case 'common/GET_USER_FEED_REQ':
    case 'communityApp/c/getMyFeed_REQ':
    case 'communityApp/c/getFeed_REQ':
    case 'communityApp/EXAMPLE_POSTS_REQ':
      const reqFeed = { ...state }
      if (!reqFeed[req.id]) {
        reqFeed[req.id] = {
          ...feedInitialState,
          isFetching: true,
        }
      } else {
        reqFeed[req.id].isFetching = true
      }
      return reqFeed

    case 'communityApp/c/getMyFeed_RESP':
    case 'communityApp/c/getFeed_RESP':
    case 'common/GET_USER_FEED_RESP':
    case 'communityApp/c/getTaggedPosts_RESP':
    case 'communityApp/EXAMPLE_POSTS_RESP': {
      let feedId
      if (type === 'communityApp/c/getMyFeed_RESP') {
        // If we are looking at our default feed (items from our friends and communities), use a special ID, `myFeed`.
        feedId = 'myFeed'
        // For any other feed of content, use a given UUID.
      } else {
        feedId = req.id
      }

      // example posts will just never have a full page view, so disabling
      // the show more behavior
      if (type === 'communityApp/EXAMPLE_POSTS_RESP') {
        pagination.next = null
      }
      // if tagged posts don't return any data, we are at the end of the stream
      // set the next to null so FE doesn't try to keep fetching
      if (type === 'communityApp/c/getTaggedPosts_RESP') {
        if (!data.length) {
          pagination.next = null
        }
      }

      // if there is no previous reset the feed, don't append
      if (!pagination.previous) {
        return {
          ...state,
          [feedId]: {
            pagination,
            isFetching: false,
            list: data.map(getPostId),
            stickiedPostId: resp.stickied_post
              ? resp.stickied_post.id
              : feedInitialState.stickiedPostId,
            count: resp.count,
          },
        }
      }

      return {
        ...state,
        [feedId]: {
          pagination,
          isFetching: false,
          list: [...state[feedId].list, ...data.map(getPostId)],
          stickiedPostId: resp.stickied_post
            ? resp.stickied_post.id
            : feedInitialState.stickiedPostId,
          count: resp.count,
        },
      }
    }
    case 'communityApp/subCommunity/pin_RESP':
      let feedState = { ...state }
      const { communityId, postToPinId } = rest
      let feed = { ...feedState[communityId] }
      feed.list = [
        ...feed.list.filter(id => {
          return id !== postToPinId
        }),
      ]
      feed.stickiedPostId = postToPinId
      feedState[communityId] = feed
      return feedState
    case 'common/DELETE_POST_RESP':
      const postId = req.id
      const filterDeletedPost = id => {
        return id !== postId
      }
      const newFeeds = { ...state }
      for (let feedId in state) {
        let feed = newFeeds[feedId]
        feed.list = feed.list.filter(filterDeletedPost)
        if (postId === feed.stickiedPostId) {
          feed.stickiedPostId = feedInitialState.stickiedPostId
        }
      }
      return newFeeds
    case 'communityApp/subCommunity/updateCommunity_RESP':
      return {
        ...state,
        [data.id]: {
          ...state[data.id],
          stickiedPostId: data.stickied_post_id,
        },
      }

    default:
      return state
  }
}
