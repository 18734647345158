import React, { Component } from 'react'
import { compose } from '@reduxjs/toolkit'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchItemInWatchLater } from 'common/actions'
import {
  MemoCloudinaryImg,
  PercentBar,
  PlayBtn,
  PremiumBadge,
  ShareMenu,
} from 'common/components'
import QueueToggleIconBtn from 'common/containers/QueueToggle/IconBtn'
import {
  canUserWatch,
  encodeUuid,
  getDomain,
  getImageSrc,
  getPercentDone,
  getTimeString,
  isTimeInFuture,
  withDateBasedFeatureFlag,
} from 'common/helpers'
import { trackEvent } from 'common/metrics'

import { showModal } from 'modalApp/actions'

import './styles.scss'

export class EpisodeCard extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    item: PropTypes.object,
    onThumbnailClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    user: PropTypes.object,
    width: PropTypes.string,
  }

  static defaultProps = {
    isAuthenticated: false,
    item: {},
    onThumbnailClick: () => null,
    onTitleClick: () => null,
    user: null,
    width: 'auto',
  }

  state = {
    hovered: false,
    imgSrcFromIncluded: null,
  }

  componentDidMount() {
    const { fetchItemInWatchLater, isAuthenticated, item } = this.props
    const uuid = this.getUuid()
    if (isAuthenticated) {
      fetchItemInWatchLater(uuid)
    }
    if (item && !this.state.imgSrcFromIncluded) {
      const imgSrcFromIncluded = getImageSrc(
        item.included ? item.included.images : null,
        'small',
        'profile'
      )
      this.setState({
        imgSrcFromIncluded,
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.item !== this.props.item ||
      nextProps.isAuthenticated !== this.props.isAuthenticated ||
      nextProps.percent !== this.props.percent ||
      nextProps.user !== this.props.user ||
      this.state.hovered !== nextState.hovered ||
      this.state.imgSrcFromIncluded !== nextState.imgSrcFromIncluded
    )
  }

  componentDidUpdate(prevProps, _prevState) {
    const { fetchItemInWatchLater, isAuthenticated, item } = this.props
    const uuid = this.getUuid()
    if (!prevProps.isAuthenticated && isAuthenticated) {
      fetchItemInWatchLater(uuid)
    }
    if (!prevProps.item && item) {
      const imgSrcFromIncluded = getImageSrc(
        item.included ? item.included.images : null,
        'small',
        'profile'
      )
      this.setState({ imgSrcFromIncluded })
    }
  }

  getImg() {
    return (
      // Prefer an explicitly provided thumbnail image
      this.props?.item?.attributes?.thumbnail_image ||
      // But also allow images to come from `included`, which are processed on mount and updates
      this.state.imgSrcFromIncluded
    )
  }

  getDateTime = () => {
    const { item, user } = this.props
    const {
      is_sponsors_only: firstOnly,
      member_golive_at: memberGoLive,
      sponsor_golive_at: sponsorGoLive,
    } = item.attributes
    // if its forever first or first user, always show sponsor date
    if (firstOnly || (user && user.attributes.member_tier_i === 1)) {
      return (
        <time dateTime={sponsorGoLive}>
          {moment(sponsorGoLive).format('MM/DD/YYYY')}
        </time>
      )
    }
    // otherwise if its not free for users yet, add free date
    if (isTimeInFuture(memberGoLive)) {
      return (
        <time dateTime={memberGoLive}>
          FREE {moment(memberGoLive).format('MM/DD/YYYY')}
        </time>
      )
    }
    // default to member go live date since first is already covered
    return (
      <time dateTime={memberGoLive}>
        {moment(memberGoLive).format('MM/DD/YYYY')}
      </time>
    )
  }

  // As we adopt the JSON API spec, we are getting rid of "canonical_links" in favor of a unified "links" object.
  getCanonicalSelf = () => {
    const { item } = this.props
    const oldVersion = item.canonical_links?.self
    const newVersion = item.links?.canonical_self
    return newVersion || oldVersion
  }

  getLink = () => {
    const { listId } = this.props
    const canonicalSelf = this.getCanonicalSelf()
    return this.props.listId
      ? `${canonicalSelf}?list=${encodeUuid(listId)}`
      : canonicalSelf
  }

  getPremium = () => {
    const { user } = this.props
    const {
      is_sponsors_only: firstOnly,
      member_golive_at: memberGoLive,
    } = this.props.item.attributes
    let userIsFirst = false
    if (user && user?.attributes?.member_tier_i === 1) {
      userIsFirst = true
    }
    if (firstOnly) {
      return <PremiumBadge userIsFirst={userIsFirst} />
    }
    if (isTimeInFuture(memberGoLive)) {
      return <PremiumBadge userIsFirst={userIsFirst} />
    }
    return null
  }

  getTitle = () => {
    const { item } = this.props
    return item.attributes.show_title || item.attributes.parent_content_title
  }

  getUuid = () => {
    const { item } = this.props
    return item?.uuid || item?.attributes?.uuid
  }

  onClicked = (e, type) => {
    const {
      featureEnabled: shouldHideFirstButton,
      item,
      onThumbnailClick,
      onTitleClick,
      showModal,
      user,
    } = this.props
    const { is_sponsors_only, member_golive_at } = item.attributes
    const uuid = this.getUuid()

    if (
      (is_sponsors_only || isTimeInFuture(member_golive_at)) &&
      (!user || !canUserWatch(user, item)) &&
      !shouldHideFirstButton
    ) {
      const modalImg = this.getImg()
      e.preventDefault()
      showModal('FIRST_ONLY', { thumbnail: modalImg })
      trackEvent('Trial Modal Shown', {
        item_id: uuid,
        item_type: item.type,
      })
    }

    if (type === 'title') {
      onTitleClick()
    }
    if (type === 'thumb') {
      onThumbnailClick()
    }
  }

  render() {
    const { isAuthenticated, item, percent, showModal, width } = this.props
    const { display_title, length, slug } = item.attributes
    const uuid = this.getUuid()
    const { hovered } = this.state
    const img = this.getImg()
    const premiumBadge = this.getPremium()
    const releaseDate = this.getDateTime()
    if (item && this.getCanonicalSelf()) {
      const { type } = item
      const titleId = `${uuid}-label`

      return (
        <div
          className='episode-card'
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
          data-qa-is-premium={!!premiumBadge}
        >
          <div className='card-content'>
            <div className='card-image-wrapper'>
              <Link
                aria-labelledby={titleId}
                to={this.getLink()}
                onClick={e => this.onClicked(e, 'thumb')}
              >
                {img ? (
                  <MemoCloudinaryImg
                    alt={`Episode thumbnail for episode "${display_title}"`}
                    src={img?.split('f_auto')[1]}
                    width={width}
                  />
                ) : null}
                <time className='timestamp' dateTime={`${length || 0}s`}>
                  {getTimeString(length || 0)}
                </time>
                {premiumBadge}
              </Link>
              {hovered && uuid && (
                <div className='btns'>
                  <PlayBtn
                    link={this.getLink()}
                    onClick={e => this.onClicked(e, 'thumb')}
                  />
                  <QueueToggleIconBtn
                    className='episode-card__queue action-btn icon-btn'
                    type={type}
                    uuid={uuid}
                  />
                  <ShareMenu
                    className='episode-card__share action-btn icon-btn'
                    href={`https://${getDomain(
                      window.location.href,
                      true
                    )}${this.getCanonicalSelf()}`}
                    slug={slug}
                    type={type}
                  />
                  <QueueToggleIconBtn
                    className='episode-card__playlist action-btn icon-btn'
                    onAddClick={() =>
                      isAuthenticated
                        ? showModal('PLAYLIST_CREATE', { uuid, type })
                        : showModal('USER_LOGIN')
                    }
                    type='playlist'
                    uuid={uuid}
                  />
                </div>
              )}
              {percent > 0 && <PercentBar percent={percent} />}
            </div>
            <div className='info-line'>
              <div className='info-left'>
                <Link
                  className='episode-title'
                  id={titleId}
                  onClick={e => this.onClicked(e, 'title')}
                  tabIndex='-1'
                  title={display_title}
                  to={this.getLink()}
                >
                  {display_title}
                </Link>
                <div className='episode-extra'>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    to={item.canonical_links?.show || '/'}
                    className='episode-extra__link'
                  >
                    {this.getTitle()}
                  </Link>{' '}
                  | {releaseDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='episode-card'>
        <div className='card-content'>
          <div className='image' style={{ opacity: 0 }} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { item: itemFromProps, isSeasonEpisode, isBonus }
) => {
  const { isAuthenticated, user } = state.authReducer

  // TODO: Season episodes and bonus features use different places in the store for percentages, even though they're just episode percentages. There's no advantage to this. It might be better for them to share the same percentages object.
  let percentages = state.commonReducer.carousel.itemPercentages
  if (isSeasonEpisode) percentages = state.commonReducer.seasons.itemPercentages
  if (isBonus) percentages = state.showsReducer.bonus.itemPercentages
  const { allPlaylists } = state.commonReducer.myStuff

  let percent
  if (percentages && itemFromProps) {
    percent = getPercentDone({ item: itemFromProps, percentages })
  }

  return { isAuthenticated, percent, user, allPlaylists }
}

export default compose(
  connect(
    mapStateToProps,
    { fetchItemInWatchLater, showModal }
  ),
  withDateBasedFeatureFlag('disableFirstUpgradesDate')
)(EpisodeCard)
