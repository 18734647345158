import { match } from 'path-to-regexp'
import { LOCATION_CHANGE } from 'redux-first-history'

const initialState = { params: {} }

// Before using this reducer, read this:
// https://docsprod.turner.com/display/RTE/React+Router+and+urlReducer
// Use this reducer as a "cheat" way to supply desired URL patterns to components without having to use Prop-drilling (which is required with React-Router)
const paramRoutes = [
  // Community
  // My Feed
  '/g',
  '/g/:tab(feed|explore|community_guidelines)',
  // User Profile
  '/g/user/:username',
  '/g/user/:username/:tab(activity|following|followers|groups)',
  // Subcommunity
  '/g/:subcommunity',
  '/g/:subcommunity/:tab(feed|members)',
  // Channels
  '/channel/:channelSlug',
  // Series
  '/series/:seriesSlug',
  // Episode
  '/watch/:episodeSlug',
  // Other
  '/(.*)',
]

const matchPath = match(paramRoutes, { decode: decodeURIComponent })

const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      // console.log('action', action)
      return matchPath(action.payload.location.pathname)

    default:
      return state
  }
}

export default urlReducer
