import React from 'react'
import { Helmet } from 'react-helmet'

import useIsNonIndexablePostPage from './useIsNonIndexablePostPage'

const isNotProduction = () => window.location.hostname !== 'roosterteeth.com'
const isUserProfile = () => window.location.pathname.includes('/g/user/')

const DisallowCrawlers = () => {
  const isNonIndexablePostPage = useIsNonIndexablePostPage()
  if (isNotProduction() || isUserProfile() || isNonIndexablePostPage) {
    return (
      <Helmet>
        <meta name='robots' content='noindex' />
      </Helmet>
    )
  }
  return null
}

export default DisallowCrawlers
