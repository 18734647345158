import React from 'react'

import { ShowCard } from 'common/components'
import useGenericContentBlockItemTracking from 'common/hooks/useGenericContentBlockItemTracking'
import { trackClick } from 'common/metrics'

import ContentBlockBase, { ContentBlockHeaderLink } from './ContentBlockBase'

const ShowCardOuter = ({ contentBlock, item, location }) => {
  const handleClick = useGenericContentBlockItemTracking({
    contentBlock,
    item,
    location,
  })
  return (
    <div className='content-block__series-group-list-item' key={item.id}>
      <ShowCard
        item={item}
        onFollowSeriesClick={() =>
          trackClick('grid_item_follow_series', {
            context: item.attributes.uuid,
          })
        }
        onThumbnailClick={handleClick}
        onTitleClick={handleClick}
        onUnfollowSeriesClick={() =>
          trackClick('grid_item_unfollow_series', {
            context: item.attributes.uuid,
          })
        }
        poster
      />
    </div>
  )
}

const ContentBlockSeriesGroup = ({
  contentBlock,
  headerUrl,
  items,
  location,
  title1,
  title2,
}) => (
  <ContentBlockBase>
    <div className='content-block__series-group-container'>
      <ContentBlockHeaderLink
        contentBlock={contentBlock}
        header1={title1}
        header2={title2}
        location={location}
        url={headerUrl}
      />
      <div className='content-block__series-group-list'>
        {items?.map(item => (
          <ShowCardOuter
            contentBlock={contentBlock}
            item={item}
            key={item.attributes.uuid}
            location={location}
          />
        ))}
      </div>
    </div>
  </ContentBlockBase>
)

export default ContentBlockSeriesGroup
