import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  EpisodeCard,
  LivestreamCard,
  MerchCard,
  PlaylistCard,
  ShowCard,
} from 'common/components'

import './styles.scss'

// Prevent Cloudinary from loading huge original sizes for thumbnail artwork. The `width-'auto'` value should tell Cloudinary to construct a URL based on exactly what size is needed. But that's not working right now, so for carousel item's, we set a sane limit of 600px.
const CARD_IMAGE_SRC_WIDTH = 600

class CarouselItem extends Component {
  static propTypes = {
    filter: PropTypes.object,
    item: PropTypes.object.isRequired,
    onFollowSeriesClick: PropTypes.func,
    onMerchItemClick: PropTypes.func,
    onThumbnailClick: PropTypes.func,
    onThumbnailTitleClick: PropTypes.func,
    onUnfollowSeriesClick: PropTypes.func,
    scaleItem: PropTypes.func,
    type: PropTypes.string.isRequired,
    unscaleItem: PropTypes.func,
  }

  static defaultProps = {
    filter: {},
    onFollowSeriesClick: () => {},
    onMerchItemClick() {},
    onThumbnailClick() {},
    onThumbnailTitleClick() {},
    onUnfollowSeriesClick: () => {},
    scaleItem: () => null,
    unscaleItem: () => null,
  }

  cardRef = React.createRef()

  shouldComponentUpdate(nextProps) {
    const { item, filter } = this.props
    return nextProps.item !== item || nextProps.filter !== filter
  }

  getCard(type) {
    const {
      item,
      listId,
      onFollowSeriesClick,
      onMerchItemClick,
      onThumbnailClick,
      onThumbnailTitleClick,
      onUnfollowSeriesClick,
    } = this.props

    switch (type) {
      case 'shows':
        return (
          <ShowCard
            item={item}
            onFollowSeriesClick={onFollowSeriesClick}
            onThumbnailClick={() => onThumbnailClick(item)}
            onUnfollowSeriesClick={onUnfollowSeriesClick}
            width={CARD_IMAGE_SRC_WIDTH}
          />
        )
      case 'episodes':
        return (
          <EpisodeCard
            item={item}
            onThumbnailClick={() => onThumbnailClick(item)}
            width={CARD_IMAGE_SRC_WIDTH}
          />
        )
      case 'merch':
        return <MerchCard item={item} onClick={() => onMerchItemClick(item)} />
      case 'livestreams':
        return (
          <LivestreamCard
            item={item}
            onThumbnailClick={() => onThumbnailClick(item)}
            onTitleClick={onThumbnailTitleClick}
          />
        )
      case 'playlist':
        return (
          <PlaylistCard
            item={item}
            key={item.id}
            onThumbnailClick={() => onThumbnailClick(item)}
            width={CARD_IMAGE_SRC_WIDTH}
          />
        )
      case 'watchlater':
        return (
          <EpisodeCard
            item={item}
            onThumbnailClick={() => onThumbnailClick(item)}
            listId={listId}
            width={CARD_IMAGE_SRC_WIDTH}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { type } = this.props
    return (
      <div
        className='card-container'
        onMouseEnter={() => this.props.scaleItem(this)}
        ref={this.cardRef}
        onMouseLeave={() => this.props.unscaleItem(this)}
      >
        {this.getCard(type)}
      </div>
    )
  }
}

export default CarouselItem
