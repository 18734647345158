import React, { useCallback } from 'react'

import imageContentBlockDevices from 'common/assets/onboarding-content-block-devices.png'
import AppStoreButtons from 'common/components/AppStoreButtons'
import DevicesLinks from 'common/components/DevicesLinks'
import { useToggle } from 'common/hooks'
import useRefScrolledIntoView from 'common/hooks/useRefScrolledIntoView'
import { trackEvent } from 'common/metrics'

import ContentBlockBase, { Header1, Header2 } from './ContentBlockBase'

const DevicesAnchorTag = ({ children }) => (
  <a
    href='https://devices.roosterteeth.com/'
    rel='noopener noreferrer'
    target='_blank'
  >
    {children}
  </a>
)

const ContentBlockDevices = ({ contentBlock, location }) => {
  const [inView, setInView] = useToggle()
  const contentBlockRef = useRefScrolledIntoView(setInView, {
    topOffset: 500,
    bottomOffset: 500,
    mode: 'any-visible',
  })

  // Track any click inside Devices that was on an <a> tag (there are a lot).
  const handleClick = useCallback(
    e => {
      const anchor = e.target.closest('a')
      if (!anchor) return

      trackEvent('Content Block Link Clicked', {
        content_block_display_type: contentBlock.attributes.display_type,
        content_block_item_use_type: contentBlock.attributes.item_use_type,
        content_block_uuid: contentBlock.attributes.uuid,
        location,
        target_url: anchor.href,
      })
    },
    [contentBlock, location]
  )

  return (
    <ContentBlockBase
      className='content-block--devices-outer-qa'
      onClick={handleClick}
      onKeyPress={handleClick}
      ref={contentBlockRef}
    >
      {inView ? (
        <>
          <div className='content-block__devices-desktop-container'>
            <div className='content-block__devices-desktop-bg-gradient' />
            <div className='content-block__devices-desktop-body-outer'>
              <div className='content-block__devices-desktop-body-left'>
                <Header1>
                  <DevicesAnchorTag>Watch on the go</DevicesAnchorTag>
                </Header1>
                <Header2>
                  <DevicesAnchorTag>Download the app</DevicesAnchorTag>
                </Header2>
                <div className='content-block__devices-desktop-app-links-outer'>
                  <AppStoreButtons />
                </div>
                <div className='content-block__devices-desktop-links-outer-big'>
                  <DevicesLinks scale='s' />
                </div>
              </div>
              <div className='content-block__devices-desktop-body-right'>
                <DevicesAnchorTag>
                  <img
                    className='content-block__devices-desktop-image'
                    src={imageContentBlockDevices}
                    alt='Rooster Teeth App on various devices'
                  />
                </DevicesAnchorTag>
              </div>
            </div>
          </div>
          <div className='content-block__devices-mobile-container'>
            <div className='content-block__devices-mobile-bg-gradient' />
            <div className='content-block__devices-mobile-body'>
              <Header1>
                <DevicesAnchorTag>Watch on the go</DevicesAnchorTag>
              </Header1>
              <Header2>
                <DevicesAnchorTag>Download the app</DevicesAnchorTag>
              </Header2>
              <a
                href='https://devices.roosterteeth.com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                <img
                  className='content-block__devices-mobile-image'
                  src={imageContentBlockDevices}
                  alt='Rooster Teeth App on various devices'
                />
              </a>
              <div className='content-block__devices-mobile-links-outer-small'>
                <DevicesLinks scale='xs' />
              </div>
              <div className='content-block__devices-mobile-app-links-outer'>
                <AppStoreButtons />
              </div>
              <div className='content-block__devices-mobile-links-outer-big'>
                <DevicesLinks scale='s' />
              </div>
            </div>
          </div>
        </>
      ) : (
        // NOTE: Unlike the other content block placeholders, this placeholder will not match the exact dimensions of the rendered Devices block. Its height is just an approximation.
        <div className='content-block__devices-placeholder' />
      )}
    </ContentBlockBase>
  )
}

export default ContentBlockDevices
