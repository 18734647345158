/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useCallback, useEffect, useState } from 'react'

import handsomenessImg from '../../assets/emoji/revels.png'

const useShiftKeyHeld = () => {
  const [shiftKeyHeld, setShiftKeyHeld] = useState(false)
  useEffect(() => {
    function turnOn(e) {
      if (e.key === 'Shift') {
        setShiftKeyHeld(true)
      }
    }
    function turnOff(e) {
      if (e.key === 'Shift') {
        setShiftKeyHeld(false)
      }
    }
    document.addEventListener('keydown', turnOn)
    document.addEventListener('keyup', turnOff)

    return () => {
      document.removeEventListener('keydown', turnOn)
      document.removeEventListener('keyup', turnOff)
    }
  }, [])
  return shiftKeyHeld
}

let intervalSet = false

const IncrediblyHandsomeEasterEgg = () => {
  const shiftKeyHeld = useShiftKeyHeld()
  const onClick = useCallback(() => {
    if (intervalSet) return
    intervalSet = true
    setInterval(() => {
      const images = document.querySelectorAll('img[src]')
      for (const image of images) {
        image.setAttribute('src', handsomenessImg)
        image.setAttribute('srcset', handsomenessImg)
      }
    }, 500)
  }, [])

  return (
    <>
      <a
        style={{ display: shiftKeyHeld ? 'inline' : 'none', cursor: 'pointer' }}
        onClick={e => {
          e.preventDefault()
          onClick()
        }}
      >
        Muh Face!
      </a>
    </>
  )
}

export default IncrediblyHandsomeEasterEgg
