import {
  SCROLLABLE_DISABLE,
  SCROLLABLE_ENABLE,
  SCROLLABLE_TOGGLE,
} from '../actions/types'

const initialState = true

export default (state = initialState, action) => {
  switch (action.type) {
    case SCROLLABLE_ENABLE:
    case SCROLLABLE_DISABLE:
      return !!action.scrollable
    case SCROLLABLE_TOGGLE:
      return action.scrollable !== undefined
        ? action.scrollable
        : !state.scrollable
    default:
      return state
  }
}
