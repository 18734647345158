import {
  PUT_UPDATE_PROFILE,
  PUT_UPDATE_PROFILE_ERR,
  PUT_UPDATE_PROFILE_IMAGE_ERR,
  PUT_UPDATE_PROFILE_IMAGE_RESP,
  RECEIVE_PROFILE,
  REQUEST_PROFILE,
  SAVE_PROFILE,
  TOGGLE_EDITING,
  TOGGLE_FOLLOW,
  TOGGLE_FRIEND,
  TOGGLE_LIKE,
  TOGGLE_SAVING,
  TRIGGER_MESSAGE,
  TRIGGER_UPDATE_COVER,
  TRIGGER_UPDATE_PHOTO,
} from '../actions/types'

const initialState = {
  isFetching: false,
  isEditing: false,
  isSaving: false,
  user: null,
  imageError: null,
  profileError: null,
  404: false,
}

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case 'PROFILE_GET_ERR':
      return {
        ...state,
        profileError: 'There was an error fetching the requested profile',
      }
    case REQUEST_PROFILE:
      return {
        ...state,
        isFetching: true,
        404: false,
      }
    case RECEIVE_PROFILE:
      if (action.user.error && action.user.error === 'ResourceNotFound') {
        return {
          ...state,
          isFetching: false,
          404: true,
        }
      }

      return {
        ...state,
        isFetching: false,
        user: action.user,
      }
    case SAVE_PROFILE:
      return {
        ...state,
        isSaving: false,
        isEditing: false,
      }
    case TOGGLE_FOLLOW:
      return state
    case TOGGLE_FRIEND:
      return state
    case TOGGLE_LIKE:
      return state
    case TOGGLE_SAVING:
      return {
        ...state,
        isSaving: !state.isSaving,
      }
    case TOGGLE_EDITING:
      return {
        ...state,
        isEditing:
          action.isEditing === undefined ? !state.isEditing : action.isEditing,
      }
    case TRIGGER_MESSAGE:
      return state
    case TRIGGER_UPDATE_COVER:
      return state
    case TRIGGER_UPDATE_PHOTO:
      return state
    case PUT_UPDATE_PROFILE:
      return {
        ...state,
        user: action.data,
        isSaving: false,
        isEditing: false,
        profileError: null,
      }
    case PUT_UPDATE_PROFILE_ERR:
      return {
        ...state,
        profileError: action.error,
      }
    // manage common/IMAGES_UPLOAD response
    case 'profile/UPLOAD_IMAGES':
      const { images } = action
      return {
        ...state,
        awsBucket: images[0].awsBucket,
        profileImage: images[0].url,
        profileImagePath: images[0].path,
      }
    case PUT_UPDATE_PROFILE_IMAGE_RESP:
      return {
        ...state,
        updatingImage: false,
      }
    case PUT_UPDATE_PROFILE_IMAGE_ERR:
      return {
        ...state,
        imageError: action.data.error,
      }
    default:
      return state
  }
}

export default profileReducer
