import { createSelector } from '@reduxjs/toolkit'
import { path, pathOr, prop, propOr } from 'ramda'

import { getURLSearchObj } from 'common/selectors'

import { getPathname } from 'routing/selectors'

import { POSSIBLE_SORT_KEYS } from './constants'

export const getShows = createSelector(
  state => state.showsReducer.shows,
  propOr([], 'shows')
)

export const getRssReducer = state => state.showsReducer.rss

export const getSlug = createSelector(
  getPathname,
  pathname => pathname?.split('/')?.pop()
)

export const getSeries = createSelector(
  getShows,
  getSlug,
  (shows, slug) => shows?.find(show => show.attributes.slug === slug)
)

export const getSeriesUuid = createSelector(
  getSeries,
  prop('uuid')
)

export const getShowAttributes = createSelector(
  getSeries,
  prop('attributes')
)

export const getSeriesFeedUrl = createSelector(
  getShowAttributes,
  prop('supporting_cast_url')
)

export const getRssLoginToken = createSelector(
  getRssReducer,
  prop('rssLoginToken')
)

export const getAuthenticatedSeriesFeedUrl = createSelector(
  getSeriesFeedUrl,
  getRssLoginToken,
  (feedUrl, token) => {
    if (!feedUrl) return null
    const url = new URL(feedUrl)
    url.searchParams.set('t', token)
    return url.toString()
  }
)

export const getSeriesImages = createSelector(
  getSeries,
  pathOr([], ['included', 'images'])
)

export const getSeriesTitleCard = createSelector(
  getSeriesImages,
  images => {
    if (!images.length) return null
    const titleCard = images.find(
      ({ attributes }) => attributes?.image_type === 'title_card'
    )
    return titleCard?.attributes
  }
)

export const getPendingRssActions = createSelector(
  getRssReducer,
  path(['pendingRssActions', 'isPending'])
)

export const getPendingSeriesUuid = createSelector(
  getRssReducer,
  path(['pendingRssActions', 'seriesUuid'])
)

export const getSort = createSelector(
  getURLSearchObj,
  params => {
    for (const key of POSSIBLE_SORT_KEYS) {
      const value = params.get(key)
      if (value) return [key, value]
    }

    // If no query parameter is provided, default to newest-to-oldest.
    return ['order', 'desc']
  }
)

export const getGenre = createSelector(
  getURLSearchObj,
  params => {
    const genre = params.get('genres')
    if (genre) return decodeURIComponent(genre)
  }
)

export const getChannelSlug = createSelector(
  getURLSearchObj,
  params => {
    const channelSlug = params.get('channel_id')
    if (channelSlug) return decodeURIComponent(channelSlug)
  }
)
