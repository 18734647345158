import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Tooltip } from 'common/components'
import { pageTitle, testClipboardSupport } from 'common/helpers'

import EmbedShare from './EmbedShare'
import LinkShare from './LinkShare'
import TwitterShare from './TwitterShare'

import './styles.scss'

const popupOptions = {
  height: 300,
  left: Math.ceil(window.innerWidth / 2 - 300),
  top: Math.ceil(window.innerHeight / 2 - 127),
  width: 600,
}

class ShareMenu extends Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    slug: PropTypes.string,
    type: PropTypes.oneOf(['bonus_feature', 'episode', 'post']),
  }

  static defaultProps = {
    slug: '',
    type: 'episode',
  }

  state = {
    isOpen: false,
  }

  baseRef = React.createRef()

  clipboardSupported = testClipboardSupport()

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state

    if (isOpen !== prevState.isOpen) {
      if (isOpen) {
        document.addEventListener('click', this.handleClickOutside, {
          capture: true,
        })
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (!this.baseRef.current.contains(e.target)) {
      this.setState({ isOpen: false })
    }
  }

  openShareWindow = href => {
    const windowFeatures = Object.keys(popupOptions)
      .map(key => `${key}=${popupOptions[key]}`)
      .join(',')

    window.open(href, pageTitle('Share'), windowFeatures)
  }

  toggleMenu = () => {
    this.setState((state, _props) => ({ isOpen: !state.isOpen }))
  }

  render() {
    const { className, href, slug, type } = this.props
    const { isOpen } = this.state
    const baseClass = classnames('share-menu', className)
    const buttonClass = classnames('share-menu__btn', {
      'share-menu__btn--active': isOpen,
    })

    return (
      <div className={baseClass} ref={this.baseRef}>
        <Tooltip text='Share'>
          <button
            className={buttonClass}
            onClick={this.toggleMenu}
            type='button'
          >
            <i className='icon-share' />
            <span className='visuallyhidden'>Share</span>
          </button>
        </Tooltip>

        {isOpen && (
          <ul className='share-menu__list'>
            {/*
              Temporarily disabling Facebook sharing because of the issue described here:
              https://jiraprod.turner.com/browse/RTFE-890
            */}
            {/* <FacebookShare href={href} openShareWindow={this.openShareWindow} /> */}
            <TwitterShare href={href} openShareWindow={this.openShareWindow} />
            {this.clipboardSupported && <LinkShare href={href} />}
            {this.clipboardSupported && slug && type && (
              <EmbedShare href={href} slug={slug} type={type} />
            )}
          </ul>
        )}
      </div>
    )
  }
}

export default ShareMenu
