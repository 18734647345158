import React from 'react'

import firstLogo from './assets/first-logo.svg'
import followTheRssFeed from './assets/follow-the-rss-feed.png'
import rssIcon from './assets/rss-icon@2x.png'

import './styles.scss'

const RssButton = () => (
  <div className='rss-button__row-container' data-testid='rss-button'>
    <div className='rss-button__content-area'>
      <img alt='rss-icon' src={rssIcon} className='rss-button__rss-icon' />
      <div className='rss-button__text-container'>
        <img
          alt='first-logo'
          src={firstLogo}
          className='rss-button__first-logo'
        />
        <div className='rss-button__button-copy'>
          <img src={followTheRssFeed} alt='follow the rss feed' />
        </div>
      </div>
    </div>
  </div>
)

export default RssButton
