/** **********************
 *
 *  Pricing
 *
 ************************ */

export const priceInDollars = (cents, units = 1, decimals = 2) =>
  (Math.floor(cents / units) / 100).toFixed(decimals)

export const shortPriceInDollars = amount => {
  const isCentsIncluded = String(amount).substr(-2) !== '00'

  return isCentsIncluded ? priceInDollars(amount) : priceInDollars(amount, 1, 0)
}
