import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { deleteComment, flagComment, restoreComment } from 'common/actions'
import IconDropdown from 'common/components/IconDropdown'
import IconDropdownLink from 'common/components/IconDropdown/IconDropdownLink'
import { hasRole, ROLE_ADMIN, ROLE_GLOBAL_MODERATOR } from 'common/helpers'

import { deleteAction, editAction, flagAction, restoreAction } from './actions'
import ReasonDialog from './ReasonDialog'

export class CommentAdminControls extends Component {
  static propTypes = {
    deleteComment: PropTypes.func.isRequired,
    flagComment: PropTypes.func.isRequired,
    onActionClick: PropTypes.func,
    onEdit: PropTypes.func,
    owner_uuid: PropTypes.string.isRequired,
    restoreComment: PropTypes.func.isRequired,
    user: PropTypes.object,
    uuid: PropTypes.string.isRequired,
  }

  static defaultProps = {
    onActionClick() {},
    onEdit: () => null,
    user: {},
  }

  state = {
    modalType: null,
  }

  deleteComment = reason => {
    const { deleteComment, uuid } = this.props

    deleteComment({ id: uuid, reason })
  }

  editComment = () => {
    const { onEdit } = this.props

    if (!onEdit) {
      return
    }

    onEdit()
  }

  flagComment = reason => {
    const { flagComment, uuid } = this.props

    flagComment(uuid, reason)
  }

  getActions = () => {
    const { user, deleted_at: deletedAt } = this.props
    const actions = []

    if (this.isOwnComment() && !deletedAt) {
      actions.push(editAction)
      actions.push(deleteAction)
    }

    if (!this.isOwnComment() && !deletedAt) {
      actions.push(flagAction)

      if (hasRole(ROLE_ADMIN, user) || hasRole(ROLE_GLOBAL_MODERATOR, user)) {
        actions.push(deleteAction)
      }
    }

    if (
      (hasRole(ROLE_ADMIN, user) || hasRole(ROLE_GLOBAL_MODERATOR, user)) &&
      deletedAt
    ) {
      actions.push(restoreAction)
    }

    return actions
  }

  handleActionClick = ({ action, e }) => {
    const { deleteComment, onActionClick, uuid } = this.props

    e.preventDefault()

    if (action === 'flagComment') {
      this.setState({ modalType: 'flag' })
    } else if (action === 'deleteComment') {
      if (this.isOwnComment()) {
        deleteComment({ id: uuid })
      } else {
        this.setState({ modalType: 'delete' })
      }
    } else {
      this[action]()
    }

    onActionClick(action)
  }

  handleDialogCloseEnd = () => {
    this.setState({ modalType: null })
  }

  handleDialogSubmit = reason => {
    if (this.isFlagging()) {
      this.flagComment(reason)
    } else if (this.isDeleting()) {
      this.deleteComment(reason)
    }
  }

  isDeleting = () => this.state.modalType === 'delete'

  isFlagging = () => this.state.modalType === 'flag'

  isOwnComment = () => this.props.user?.id === this.props.owner_uuid

  restoreComment = () => {
    const { restoreComment, uuid } = this.props

    restoreComment(uuid)
  }

  render() {
    const { modalType } = this.state
    const actions = this.getActions()
    const reasons = [['abuse', 'Abuse'], ['spam', 'Spam']]

    if (this.isDeleting()) {
      reasons.push(['other', 'Other'])
    }

    return (
      <div className='comment-admin-controls'>
        <IconDropdown>
          {actions.map(({ action, text }) => (
            <IconDropdownLink
              href={`#${action}`}
              key={action}
              onClick={e => this.handleActionClick({ e, action })}
            >
              {text}
            </IconDropdownLink>
          ))}
        </IconDropdown>

        {modalType && (
          <ReasonDialog
            onCloseEnd={this.handleDialogCloseEnd}
            onSubmit={this.handleDialogSubmit}
            type={modalType}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { user } = state.authReducer

  return {
    user,
  }
}

const mapDispatchToProps = {
  deleteComment,
  flagComment,
  restoreComment,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentAdminControls)
