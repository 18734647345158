import rtApi from '@roosterteethproductions/svod-api'

import {
  fetchEpisodesPercentage,
  fetchPlaylists,
  receiveEpisodes,
} from 'common/actions'

import {
  GET_FEED_CAROUSEL_FAILURE,
  GET_FEED_CAROUSEL_REQUEST,
  GET_FEED_CAROUSEL_SUCCESS,
  GET_FEEDS_FAILURE,
  GET_FEEDS_REQUEST,
  GET_FEEDS_SUCCESS,
} from './types'

// Feeds Actions

const getFeedsRequest = () => ({
  type: GET_FEEDS_REQUEST,
})

const getFeedsSuccess = ({ feed, slug }) => ({
  type: GET_FEEDS_SUCCESS,
  feed,
  slug,
})

const getFeedsError = message => ({
  type: GET_FEEDS_FAILURE,
  message: String(message),
})

export const getFeeds = ({ slug }) => (dispatch, getState) => {
  const state = getState()
  const { isAuthenticated } = state.authReducer

  dispatch(getFeedsRequest())

  return rtApi.feeds
    .fetchType({ authenticated: isAuthenticated, type: slug })
    .then(json =>
      Promise.resolve(dispatch(getFeedsSuccess({ slug, feed: json })))
    )
    .catch(ex => Promise.reject(dispatch(getFeedsError(ex))))
}

const getFeedCarouselRequest = ({ type, id }) => ({
  type: GET_FEED_CAROUSEL_REQUEST,
  carouselType: type,
  id,
})

const getFeedCarouselSuccess = ({ id, items }) => ({
  type: GET_FEED_CAROUSEL_SUCCESS,
  id,
  items: items.filter(Boolean), // Remove null items on staging
})

const getFeedCarouselError = message => ({
  type: GET_FEED_CAROUSEL_FAILURE,
  message: String(message),
})

export const getFeedCarousel = ({ filter, source, type }) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { isAuthenticated } = state.authReducer

  dispatch(getFeedCarouselRequest({ type, id: filter.name }))

  return rtApi.feeds
    .fetchEntry({
      authenticated: isAuthenticated,
      url: source,
    })
    .then(json => {
      const items =
        type === 'merch' ? json.data[0].included.collections : json.data

      return Promise.resolve(
        dispatch(getFeedCarouselSuccess({ id: filter.name, items }))
      )
    })
    .then(({ items }) => {
      if (items.length) {
        if (type === 'episodes') {
          return dispatch(receiveEpisodes({ data: items }, filter))
        }

        if (type === 'playlist') {
          return dispatch(
            fetchPlaylists(
              items.map(item => item.attributes.content_uuid),
              filter
            )
          )
        }
      }
    })
    .then(json => {
      if (type === 'episodes') {
        return dispatch(fetchEpisodesPercentage(json))
      }
      return Promise.resolve(json)
    })
    .catch(ex => Promise.reject(dispatch(getFeedCarouselError(ex))))
}
