import rtApi from '@roosterteethproductions/svod-api'

import { getEpisodePercentages } from 'common/helpers'

import {
  GET_EPISODES_FAILURE,
  GET_EPISODES_REQUEST,
  GET_EPISODES_SUCCESS,
  GET_EPS_PERCENT_FAILURE,
  GET_EPS_PERCENT_REQUEST,
  GET_EPS_PERCENT_SUCCESS,
  GET_SHOWS_FAILURE,
  GET_SHOWS_REQUEST,
  GET_SHOWS_SUCCESS,
} from './types'

const carouselLength = 30
// Episode functions

export const requestEpisodes = filter => ({
  type: GET_EPISODES_REQUEST,
  filter,
})

export const receiveEpisodes = (items, filter) => ({
  type: GET_EPISODES_SUCCESS,
  filter,
  items: items.data,
  lastUpdated: Date.now(),
})

export const fetchEpisodesFailure = ex => ({
  type: GET_EPISODES_FAILURE,
  error: ex.message,
})

export const fetchEpisodes = (myFilter, sort) => dispatch => {
  dispatch(requestEpisodes(myFilter))
  let filter = myFilter
  let includeBonusFeatures = false
  // date filter is used locally to filter the carousel data
  // on BE date is the default so it doesn't have to be specified
  // if its date filter (recent episodes) include bonus with it
  if (filter.name === 'date') {
    filter = undefined
    includeBonusFeatures = true
  }
  rtApi.episodes
    .fetchAll({
      filter,
      sort,
      includeBonusFeatures,
      perPage: carouselLength,
    })
    .then(json => dispatch(receiveEpisodes(json, myFilter)))
    .then(json => dispatch(fetchEpisodesPercentage(json)))
    .catch(ex => dispatch(fetchEpisodesFailure(ex)))
}

// Get Episodes Watched percentage
const requestEpisodesPercent = () => ({
  type: GET_EPS_PERCENT_REQUEST,
})

const receiveEpisodesPercent = items => ({
  type: GET_EPS_PERCENT_SUCCESS,
  items,
})

const fetchEpisodesPercentFailure = ex => ({
  type: GET_EPS_PERCENT_FAILURE,
  error: ex.message,
})

export const fetchEpisodesPercentage = episodeData =>
  getEpisodePercentages({
    failureAction: fetchEpisodesPercentFailure,
    items: episodeData.items,
    requestAction: requestEpisodesPercent,
    successAction: receiveEpisodesPercent,
  })

// Shows functions

export const requestShows = filter => ({
  type: GET_SHOWS_REQUEST,
  filter,
})

const receiveShows = (filter, items) => ({
  type: GET_SHOWS_SUCCESS,
  filter,
  items: items.data,
  lastUpdated: Date.now(),
})

const fetchShowsFailure = ex => ({
  type: GET_SHOWS_FAILURE,
  error: ex.message,
})

export const fetchShows = filter => (dispatch, _getState) => {
  dispatch(requestShows(filter))
  let myFilter = filter
  // date filter is used locally to filter the carousel data
  // on BE date is the default so it doesn't have to be specified
  if (filter.name === 'date') {
    myFilter = undefined
  }
  const getRequest = () => {
    if (filter?.name === 'channels') {
      return rtApi.channels.fetchShows({
        channel: filter.value,
        perPage: carouselLength,
      })
    }
    if (filter?.type === 'related') {
      return rtApi.shows.fetchRelated({
        id: filter.name,
        perPage: carouselLength,
      })
    }
    return rtApi.shows.fetchAll({ filter: myFilter, perPage: carouselLength })
  }
  getRequest()
    .then(json => dispatch(receiveShows(filter, json)))
    .catch(ex => dispatch(fetchShowsFailure(ex)))
}
