import React from 'react'

import './styles.scss'

const ChatHideBtn = ({
  toggleChat = () => {},
  isChatOpen = false,
  href = '#toggle-chat',
}) => (
  <a
    className={`chat-hide hide-on-med-and-down chat-hide--${
      isChatOpen ? 'open' : 'closed'
    }`}
    href={href}
    onClick={toggleChat}
  >
    {isChatOpen ? <i className='icon-close2' /> : <i className='icon-chat' />}
    <span>CHAT</span>
  </a>
)

export default ChatHideBtn
