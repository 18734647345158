/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = React.forwardRef((props, ref) => (
  <a ref={ref} rel='noopener noreferrer' target='_blank' {...props} />
))

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export default ExternalLink
