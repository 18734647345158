import { css } from 'styled-components'

export const btnReset = () => css`
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  padding: 0;

  &:focus {
    background: transparent; // Materialize
  }
`

export const defaultOutline = css`
  outline: 1px dotted #212121 !important; // Firefox
  outline: 5px auto -webkit-focus-ring-color !important; // Webkit
`

export const tabFocus = ({ tabFocused }) => {
  if (tabFocused) {
    return defaultOutline
  }
}
