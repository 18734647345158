import React, { useMemo } from 'react'

import useContentBlockCarouselItemTracking from 'common/hooks/useContentBlockCarouselItemTracking'

import CarouselV2 from '../CarouselV2'
import ShowCard from '../ShowCard'
import placeholder from '../ShowCard/placeholder.gif'
import ContentBlockBase, { ContentBlockHeaderLink } from './ContentBlockBase'

const Placeholder = () => (
  <ShowCard item={{ attributes: { poster_image: placeholder } }} poster />
)

let ItemInner = ({ contentBlock, index, item, location, perPage }) => {
  const trackClick = useContentBlockCarouselItemTracking({
    contentBlock,
    item,
    itemIndex: index,
    location,
    perPage,
  })

  return (
    <ShowCard item={item} onThumbnailClick={trackClick} poster width='600' />
  )
}

ItemInner = React.memo(ItemInner)

const getItemKey = item => item.attributes.uuid

const ContentBlockSeriesCarousel = ({
  contentBlock,
  headerUrl,
  items,
  location,
  perPage,
  scrollModeMargins,
  title1,
  title2,
}) => {
  const Item = useMemo(
    () => ({ index, item, _selected }) => (
      <ItemInner
        contentBlock={contentBlock}
        index={index}
        item={item}
        location={location}
        perPage={perPage}
      />
    ),
    [contentBlock, location, perPage]
  )

  return (
    <ContentBlockBase>
      <div className='content-block__series-carousel'>
        <ContentBlockHeaderLink
          contentBlock={contentBlock}
          header1={title1}
          header2={title2}
          location={location}
          url={headerUrl}
        />
        <div className='content-block__series-carousel-wrapper'>
          <CarouselV2
            allowOverflow
            getItemKey={getItemKey}
            items={items}
            perPage={perPage}
            placeholder={Placeholder}
            renderItem={Item}
            scrollModeMargins={scrollModeMargins}
            spacing={10}
            teasedItemCount={perPage}
            transitionDuration={500}
          />
        </div>
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockSeriesCarousel
