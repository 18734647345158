const initialState = {
  isFetching: false,
  images: [],
}
export default (state = initialState, { type, images, image }) => {
  switch (type) {
    case 'common/UPLOAD_IMAGE_REQ':
    case 'common/UPLOAD_IMAGES_REQ':
      return {
        ...state,
        isFetching: true,
      }
    case 'common/UPLOAD_IMAGE_RESP':
      return {
        ...state,
        images: [...state.images, image],
        isFetching: false,
      }
    case 'common/UPLOAD_IMAGES_RESP':
      return {
        ...state,
        images: [
          ...state.images.filter(
            image => !images.find(img => image.url === img.url)
          ),
          ...images,
        ],
        isFetching: false,
      }
    case 'common/REMOVE_UPLOADED_IMG':
      return {
        ...state,
        images: state.images.filter(img => image !== img),
      }
    case 'common/UPLOAD_IMAGE_RESET':
    case 'common/USER_POST_RESP':
    case 'communityApp/c/createPost_RESP':
    case 'common/PATCH_POST_RESP':
      return initialState
    default:
      return state
  }
}
