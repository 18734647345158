import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { FollowBtn } from 'common/components'
import Avatar from 'common/components/Avatar'
import { Badges } from 'common/containers'
import { userProfilePath } from 'common/helpers'

import './styles.scss'

const UserCard = ({ children, className, user }) => (
  <Link
    className={classnames('user-card', className)}
    to={userProfilePath(user.name)}
  >
    {children}
    <Avatar size={80} src={user.profile_picture} />
    <p className='user-card__name'>@{user.name}</p>

    <div className='user-card__badges'>
      <Badges user={user} />
    </div>

    <FollowBtn bg='dark' className='qa-follow-btn' userUuid={String(user.id)} />
  </Link>
)

UserCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
}

export default UserCard
