/**
 * Determines if a membership should have moderator access to a community
 * @param {Object} membership - a user's membership for a community
 * @return {Boolean}
 */
export function isCommunityModerator(membership) {
  if (!membership) {
    return false
  }
  return membership.moderator || membership.admin || membership.creator || false
}
