import { checkIfLive } from 'common/helpers'

import {
  GET_MARKETINGBANNERS_FAILURE,
  GET_MARKETINGBANNERS_REQUEST,
  GET_MARKETINGBANNERS_SUCCESS,
} from '../actions/types'

const findDisplayType = (arr, type) => {
  if (arr?.length > 0) {
    return arr.find(i => i.attributes.display_type === type)
  }
  return null
}

const findIfLive = banner => {
  if (banner) {
    return checkIfLive(
      banner.attributes.golive_at,
      banner.attributes.expires_at
    )
  }
  return null
}

const marketing = (
  state = {
    isFetching: false,
    lastUpdated: null,
    popupMarketingBanner: null,
    topMarketingBanner: null,
  },
  action
) => {
  switch (action.type) {
    case GET_MARKETINGBANNERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_MARKETINGBANNERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_MARKETINGBANNERS_SUCCESS:
      const popupMarketingBanner = findDisplayType(
        action.marketingBanners,
        'popup'
      )
      const topMarketingBanner = findDisplayType(
        action.marketingBanners,
        'top_image'
      )
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        popupMarketingBanner: Object.assign({}, popupMarketingBanner, {
          isLive: findIfLive(popupMarketingBanner),
        }),
        topMarketingBanner: Object.assign({}, topMarketingBanner, {
          isLive: findIfLive(topMarketingBanner),
        }),
      }
    default:
      return state
  }
}

export default marketing
