import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { a11yButtonProps } from 'common/helpers/accessibility'
import handleHomeClick from 'common/helpers/handleHomeClick'
import { trackSignupButtonClick } from 'common/metrics'

import Notifications from 'rootApp/containers/Notifications'
import StinkyDragonButton from 'rootApp/containers/StinkyDragonButton'
import UserPanel from 'rootApp/containers/UserPanel'

import TryFirst from '../TopNav/TryFirst'
import WomensHistoryMonthEasterEgg from '../WomensHistoryMonthEasterEgg'
import useMarketingBannerAwareStickiness from './useMarketingBannerAwareStickiness'

import './styles.scss'

const MobileTopNav = ({ featureFlags, isAuthenticated, isFirst, pathname }) => {
  const handleHomeButtonClick = useCallback(
    e => handleHomeClick(e, pathname, 'mobile_home_top_logo'),
    [pathname]
  )

  const sticky = useMarketingBannerAwareStickiness()

  return (
    <div className={`mobile-top-nav ${sticky ? 'mobile-top-nav--sticky' : ''}`}>
      <div className='mobile-top-nav__left'>
        <Link
          className='mobile-top-nav__logo-container'
          to='/'
          {...a11yButtonProps(handleHomeButtonClick)}
        >
          <i className='icon-logomark' />
        </Link>
      </div>
      <div className='mobile-top-nav__right'>
        <div className='mobile-top-nav__first-button-container'>
          {!isFirst && (
            <TryFirst
              isAuthenticated={isAuthenticated}
              onClick={() => trackSignupButtonClick('nav_try_first')}
            />
          )}
        </div>
        {featureFlags.stinkyDragonButton ? <StinkyDragonButton /> : null}
        {featureFlags.womensHistoryMonth ? (
          <WomensHistoryMonthEasterEgg />
        ) : null}
        <div className='mobile-top-nav__notifications-container'>
          {isAuthenticated ? <Notifications /> : null}
        </div>
        <div className='mobile-top-nav__profile-button-container'>
          <UserPanel />
        </div>
      </div>
    </div>
  )
}

export default MobileTopNav
