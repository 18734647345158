import { uriInUnQuotedAttr } from 'xss-filters'

import { endpoints } from 'common/config'

export const checkIfExternalUri = uri => /^http/.test(uri)

export const checkIfStoreUri = uri =>
  uri &&
  (uri.includes('store.roosterteeth.com') ||
    uri.includes('store.staging.roosterteeth.com') ||
    uri.includes('store.roosterteeth.co.uk'))

export const checkIfRTUri = uri => {
  if (checkIfStoreUri(uri)) {
    return true
  }

  const substrings = [
    '.pe.roosterteeth.com',
    '//auth.roosterteeth.com',
    '//auth.staging.roosterteeth.com',
    '//blog.roosterteeth.com',
    '//games.roosterteeth.com',
    '//lcsecure.net/rooster-blog',
    '//local.roosterteeth.com',
    '//localhost',
    '//qa.roosterteeth.com',
    '//roosterteeth.com',
    '//rtxaustin.com',
    '//rtxevent.com',
    '//staging.roosterteeth.com',
    '//www.roosterteeth.com',
    '//m.staging.roosterteeth.com',
    '//m.roosterteeth.com',
  ]

  return new RegExp(substrings.join('|')).test(uri)
}

export function classPushOrRedirect({ href, onStoreRedirect = () => null }) {
  const { push, redirectUrl, shopifySession } = this.props

  if (redirectUrl) {
    const isExternalLink = checkIfExternalUri(redirectUrl)
    const isRTLink = checkIfRTUri(redirectUrl)
    const isStoreLink = checkIfStoreUri(redirectUrl)

    if (isExternalLink) {
      if (isStoreLink) {
        onStoreRedirect()
        shopifySession(redirectUrl).then(url => {
          window.location.href = url
        })
      } else if (isRTLink) {
        window.location.href = uriInUnQuotedAttr(redirectUrl)
      } else {
        push(href)
      }
    } else {
      push(redirectUrl)
    }
  } else {
    push(href)
  }
}

// Allow classPushOrRedirect to be used with using "this".
export function functionalPushOrRedirect({
  href,
  onStoreRedirect,
  push,
  redirectUrl,
  shopifySession,
}) {
  const componentStub = {
    props: { push, redirectUrl, shopifySession },
  }
  const boundToStub = classPushOrRedirect.bind(componentStub)
  return boundToStub({ href, onStoreRedirect })
}

export const communityHomePath = () => `/g`
export const userProfilePath = username => `/g/user/${username}`

// For docs on CDN opts
// https://cloudinary.com/documentation/image_transformations#resizing_and_cropping_images
const prod = 'https://s3.amazonaws.com/community-prod/user/'
const liveParams = [
  endpoints.communityImageAPI,
  endpoints.communityImageCDNBucket,
]
const cdnparams =
  process.env.NODE_ENV === 'development'
    ? [[prod, 'comm'], liveParams]
    : [liveParams]
const lazySafariCheck =
  navigator.userAgent.includes('Safari') &&
  !navigator.userAgent.includes('Chrome')
export function swapCommunityAPIForCDN(APIURL, CDNOpts) {
  let img = APIURL
  // f_auto is almost always a good idea, except gifs in safari
  if (!lazySafariCheck && !CDNOpts.includes('f_auto')) {
    CDNOpts += ',f_auto'
  }
  for (const [originalBase, bucket] of cdnparams) {
    if (APIURL && APIURL.startsWith(originalBase)) {
      img = `${
        endpoints.communityImageCDN
      }${CDNOpts}/${bucket}/${APIURL.replace(originalBase, '')}`
    }
  }
  return img
}

export const getCurrentLivestreamSlug = () => {
  if (window.location.pathname.indexOf('/live/') !== -1) {
    return window.location.pathname.split('/')[2]
  }
  return false
}

export const getCurrentWatchSlug = () => {
  if (window.location.pathname.indexOf('/watch/') !== -1) {
    return window.location.pathname.split('/')[2]
  }
  return false
}
