import rtApi from '@roosterteethproductions/svod-api'

import { accessTokenKey, refreshTokenKey } from 'common/config'
import { deleteCookie } from 'common/helpers'
import getRtApiLoginFuncFromIdp from 'common/helpers/getRtApiLoginFuncFromIdp'

import {
  GET_3_PARTY_LOGIN_FAILURE,
  GET_3_PARTY_LOGIN_REQUEST,
  GET_3_PARTY_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGOUT_FAILURE,
  POST_LOGOUT_REQUEST,
  POST_LOGOUT_SUCCESS,
} from './types'

// LOGIN ACTIONS
export const loginRequest = () => ({
  type: POST_LOGIN_REQUEST,
})

export const loginRequest3Party = () => ({
  type: GET_3_PARTY_LOGIN_REQUEST,
})

export const loginSuccess = () => ({
  type: POST_LOGIN_SUCCESS,
})

export const loginSuccess3Party = user => ({
  type: GET_3_PARTY_LOGIN_SUCCESS,
  user,
})

export const loginError = message => ({
  type: POST_LOGIN_FAILURE,
  message,
})

export const loginError3Party = message => ({
  type: GET_3_PARTY_LOGIN_FAILURE,
  message,
})

export const loginUser = (
  { password, username },
  errorCallback
) => dispatch => {
  dispatch(loginRequest())
  return rtApi.auth
    .login({ username, password, errorCallback })
    .then(_json => Promise.resolve(dispatch(loginSuccess())))
    .catch(ex => Promise.reject(dispatch(loginError(ex.message))))
}

/**
 * Uses an access token from a third party identity provider to get an access token for our app, effectively logging the user in. This action also emits other actions depending on the status of the login attempt.
 *
 * The resolved object includes a `status` property which indicates whether the account was created for the first time, or simply logged in (OAuth login is like a "PUT"—the same endpoint is used for both of these actions). It also includes a `user` object, an `access_token` object, and other objects for different respective identity providers.
 * @param {'apple'|'facebook'|'google'} type
 * @param {string} accessToken
 * @returns {function(function): Promise<{
 *   status: 'signed_in'|'account_created',
 *   user: object,
 *   access_token: object,
 *   google_user?: object,
 *   google_access_token?: object,
 *   facebook_user?: object,
 *   facebook_access_token?: object,
 *   apple_user?: object,
 *   apple_id_token?: object,
 * }>} Dispatch function. The dispatch function returns a promise resolving to the auth object.
 */
export const loginUser3Party = (type, accessToken) => dispatch => {
  if (!type) {
    return Promise.reject(
      new Error(
        `Please provide third party type ['apple', 'facebook', 'google']`
      )
    )
  }
  dispatch(loginRequest3Party())

  const apiCall = getRtApiLoginFuncFromIdp(type)

  return apiCall(accessToken)
    .then(rtAuthResponse => {
      const { user } = rtAuthResponse
      // Dispatch the success action
      dispatch(loginSuccess3Party(user))
      // Resolve to the response we got from the auth server. This includes helpful info such as whether the account was signed in or created for the first time.
      return Promise.resolve(rtAuthResponse)
    })
    .catch(ex => {
      dispatch(loginError3Party(ex.message))
      return Promise.reject(new Error())
    })
}

// LOGOUT ACTIONS
const logoutRequest = () => ({
  type: POST_LOGOUT_REQUEST,
})

export const logoutSuccess = () => ({
  type: POST_LOGOUT_SUCCESS,
})

const logoutError = message => ({
  type: POST_LOGOUT_FAILURE,
  message,
})

export const logoutUser = () => (dispatch, _getState) => {
  dispatch(logoutRequest())
  return rtApi.auth
    .logout()
    .then(_json => {
      deleteCookie(accessTokenKey)
      deleteCookie(refreshTokenKey)
      // Dispatch the success action
      return Promise.resolve(dispatch(logoutSuccess()))
    })
    .catch(ex => {
      // console.log('logout fail', ex)
      dispatch(logoutError(ex))
      return Promise.reject(new Error())
    })
}
