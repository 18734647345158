import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Post } from 'common/components'
import { CommentsContainer } from 'common/containers'
import {
  getPostCommentUuid,
  getPostType,
  getSubjectPostId,
} from 'common/helpers/post'

import './styles.scss'

/**
 * Displays a "full" post. Responsible to fetching full post data, and the
 * comments attached to it
 * */
class PostDetailsBase extends Component {
  static propTypes = {
    id: PropTypes.string,
    showComments: PropTypes.bool,
    focusCommentBox: PropTypes.bool,
    showReplyForm: PropTypes.bool,
  }

  static defaultProps = {
    showComments: true,
    focusCommentBox: false,
    showReplyForm: true,
  }

  // ------
  // Lifecycle
  // ------
  componentDidMount() {
    const { dispatch, id, user } = this.props
    dispatch({
      authenticated: !!user,
      type: 'common/GET_POST',
      id,
    })
  }

  componentDidUpdate(prevProps) {
    const { dispatch, id, user } = this.props
    if (prevProps.user !== user || prevProps.id !== id) {
      dispatch({
        authenticated: !!user,
        type: 'common/GET_POST',
        id,
      })
    }
  }

  // ------
  // Methods
  // ------
  getCommentsId() {
    const { post } = this.props
    const { id, foreign_association } = post
    const postType = getPostType(post)
    if (postType === 'video') {
      return foreign_association.id
    }

    return id
  }

  // ------
  // Render
  // ------
  render() {
    const {
      allowReply,
      dispatch,
      featureFlags,
      focusCommentBox,
      isFetchingPost,
      post,
      posts,
      showComments,
      showReplyForm,
      user,
      userList,
    } = this.props

    const subjectPost = posts[getSubjectPostId(post)]
    const { id: topicUuid, type: topicType } = getPostCommentUuid({
      post: subjectPost,
    })
    return (
      <div className='post-details'>
        {post && (
          <article>
            <Post
              allowReply={allowReply}
              post={post}
              posts={posts}
              dispatch={dispatch}
              featureFlags={featureFlags}
              enablePostDetails={false}
              showGallery
              user={user}
              userList={userList}
              showReplyForm={showReplyForm}
              focusCommentBox={focusCommentBox}
            >
              {topicUuid && showComments && !isFetchingPost && (
                <CommentsContainer
                  bg='dark'
                  handleHashTags
                  topicType={topicType}
                  uuid={topicUuid}
                />
              )}
            </Post>
          </article>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, { id }) => {
  const { featureFlags, userFeed, posts, comments } = state.commonReducer
  const { isFetchingPost } = userFeed
  const post = posts[id]
  const { isCommenting, userList } = comments
  const { user } = state.authReducer

  return {
    post,
    posts,
    featureFlags,
    isCommenting,
    isFetchingPost,
    user,
    userList,
  }
}

export default connect(mapStateToProps)(PostDetailsBase)
