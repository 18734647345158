import React from 'react'

import logoAndroid from 'common/assets/logo-android.svg'
import logoAppStore from 'common/assets/logo-app-store.png'
import logoAppleTV from 'common/assets/logo-apple-tv.svg'
import logoFireTV from 'common/assets/logo-fire-tv.svg'
import logoPlayStore from 'common/assets/logo-play-store.svg'
import logoRoku from 'common/assets/logo-roku.svg'
import logoXbox from 'common/assets/logo-xbox.svg'

import './styles.scss'

const DevicesLinks = ({ includeMobile = false, scale = 'm' }) => (
  <>
    <div className={`devices-links__section--size-${scale}`}>
      <div className={`devices-links__section-header--size-${scale}`}>
        Stream on
      </div>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://channelstore.roku.com/details/6323051b9c72a2ee5eb6f546909b2c2f/rooster-teeth'
      >
        <img
          alt='Roku Logo'
          className={`devices-links__img--size-${scale} devices-links__img--roku`}
          src={logoRoku}
        />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.microsoft.com/en-us/p/rooster-teeth-first/9nblggh43d8w?atc=true&activetab=pivot:overviewtab'
      >
        <img
          alt='Xbox Logo'
          className={`devices-links__img--size-${scale} devices-links__img--xbox`}
          src={logoXbox}
        />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://apps.apple.com/us/app/rooster-teeth/id1012562632#?platform=appleTV'
      >
        <img
          alt='Apple TV Logo'
          className={`devices-links__img--size-${scale} devices-links__img--apple-tv`}
          src={logoAppleTV}
        />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.amazon.com/dp/B08LNWZWVR/ref=sr_1_1?dchild=1&keywords=rooster+teeth&qid=1604343117&s=mobile-apps&sr=1-1'
      >
        <img
          alt='Fire TV Logo'
          className={`devices-links__img--size-${scale} devices-links__img--fire-tv`}
          src={logoFireTV}
        />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://play.google.com/store/apps/details?id=tv.roosterteeth.roosterteeth'
      >
        <img
          alt='Android Logo'
          className={`devices-links__img--size-${scale} devices-links__img--android`}
          src={logoAndroid}
        />
      </a>
    </div>
    {includeMobile ? (
      <>
        <div className={`devices-links__divider--size-${scale}`} />
        <div className={`devices-links__section--size-${scale}`}>
          <div className={`devices-links__section-header--size-${scale}`}>
            Mobile
          </div>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://apps.apple.com/us/app/rooster-teeth/id1012562632'
          >
            <img
              className={`devices-links__img--size-${scale} devices-links__img--app-store`}
              src={logoAppStore}
              alt='App Store Logo'
            />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://play.google.com/store/apps/details?id=com.roosterteeth.roosterteeth'
          >
            <img
              className={`devices-links__img--size-${scale} devices-links__img--play-store`}
              src={logoPlayStore}
              alt='Google Play Store Logo'
            />
          </a>
        </div>
      </>
    ) : null}
  </>
)

export default DevicesLinks
