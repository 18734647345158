import { all } from 'redux-saga/effects'

import { follows } from './follows'
import { images } from './image-upload'
import { posts } from './posts'
import { richMedia } from './richMedia'
import { users } from './users'

export function* common() {
  yield all([posts(), richMedia(), follows(), images(), users()])
}
