import { getLoggedInUsername, isVerified } from 'auth/selectors'

export const getCommunityV2State = state => state.communityV2Reducer

export const getMyFeed = state => getCommunityV2State(state).homeFeed

export const getMyFeedPost = (state, postId) =>
  getMyFeedPostFromHomeFeed(getMyFeed(state), postId)

export const getCommunityUsers = state =>
  getCommunityV2State(state).communityUsers

export const getCommunityUserByUsername = (state, username) =>
  getCommunityUsers(state)[username]

export const getCommunityUserById = (state, userId) =>
  Object.values(getCommunityUsers(state)).find(user => user.id === userId)

export const getUserActivityFeed = (state, userId) =>
  getCommunityUserById(state, userId)?.activity

export const getExamplePosts = state => getCommunityV2State(state).examplePosts

export const getExampleCommunities = state =>
  getCommunityV2State(state).exampleCommunities

export const getSubCommunities = state =>
  getCommunityV2State(state).subCommunities

export const getSubCommunityById = (state, subCommunityId) =>
  getSubCommunities(state).find(
    subCommunity => subCommunity.id === subCommunityId
  )

export const getSubCommunityBySlug = (state, subCommunitySlug) =>
  getSubCommunities(state).find(
    subCommunity => subCommunity.slug === subCommunitySlug
  )

export const getMissingSubCommunity = state =>
  getCommunityV2State(state).missingSubCommunity

export const getCurrentSubCommunity = state => {
  const subCommunitySlug = state.urlReducer.params.subcommunity

  return getSubCommunityBySlug(state, subCommunitySlug)
}

export const getLoggedInCommunityUser = state => {
  const loggedInUserUsername = getLoggedInUsername(state)

  return getCommunityUserByUsername(state, loggedInUserUsername)
}

export const getMyMemberships = state =>
  getLoggedInCommunityUser(state)?.membershipsList

export const getBannedUntil = state =>
  getLoggedInCommunityUser(state)?.banned_until

export const getUsernamesLoading = state =>
  getCommunityV2State(state).usernamesLoading

export const getIsEditingUser = state =>
  getCommunityV2State(state).isEditingUser

export const getUserUpdateForm = state =>
  getCommunityV2State(state).userUpdateForm

// A user may have a `membership` to a group, but when the user is banned, that membership's `member` attribute is false.
export const isLoggedInUserNonBannedMemberOfCommunity = (state, communityId) =>
  getMyMemberships(state)?.listByCommunityId?.get(communityId)?.member

export const getMyFeedPostFromHomeFeed = (homeFeed, postId) => {
  const postActivities = homeFeed.posts
  const postActivity = postActivities.find(
    postActivity => postActivity.post.id === postId
  )
  return postActivity.post
}

export const getUserUpdateErrors = state =>
  getCommunityV2State(state).userUpdateErrors

export const getUserInfoFromStateAndUrl = state => {
  // Get username from URL: /g/user/JimboJones
  const usernameFromUrl = state.urlReducer.params.username

  // Get auth reducer user
  const loggedInUser = state.authReducer.user

  // Auth reducer username
  const loggedInUserUsername = getLoggedInUsername(state)

  // Is logged in?
  const loggedIn = !!loggedInUser

  const verified = isVerified(state)

  // Get subcommunity from URL: /g/rooster-teeth
  const subCommunitySlug = state.urlReducer.params.subcommunity

  // If there's a username in the URL, we're on a User Profile page
  const isOnUserProfilePage = !!usernameFromUrl

  // If there's a subcommunity in the URL, we're on a subcommunity page
  const isOnSubCommunityPage = !!subCommunitySlug

  // Some custom logic happens on the explore tab. The explore tab is the default tab for logged out users on '/g'.
  const isAnonOnHomePage =
    !isOnSubCommunityPage && !isOnUserProfilePage && !loggedIn
  const isOnExploreTab =
    state.urlReducer.params.tab === 'explore' || isAnonOnHomePage

  // Otherwise, we're on the Feed page (community homepage)
  const isOnFeedPage = !usernameFromUrl && !isOnSubCommunityPage

  // If my username is in the URL, I'm on my own profile page. For the most part, viewing one's own profile is no different from viewing another person's. Different from `isMyCommunityProfile`.
  const isOnMyCommunityProfilePage =
    usernameFromUrl !== undefined && usernameFromUrl === loggedInUserUsername

  // This is true when the lefthand side shows our profile. This is distinct from `isOnMyCommunityProfilePage` in that the righthand side (tabs) are different between the homepage and own community profile. But the lefthand side is not.
  const isMyCommunityProfile = isOnFeedPage || isOnMyCommunityProfilePage

  const profileUser =
    state.communityV2Reducer.communityUsers[
      usernameFromUrl || loggedInUserUsername
    ]
  const subCommunity = getSubCommunityBySlug(state, subCommunitySlug)
  const loggedInCommunityUser = getLoggedInCommunityUser(state)

  let isLoadingCommunityProfile = false
  // We're on a group page, but the group has not loaded
  if (isOnSubCommunityPage && !subCommunity) {
    isLoadingCommunityProfile = true

    // We're on a profile page, but the user has not loaded
  } else if (isOnUserProfilePage && !profileUser) {
    isLoadingCommunityProfile = true

    // We're logged in and on our homepage, but our user has not loaded
  } else if (isOnFeedPage && loggedIn && !profileUser) {
    isLoadingCommunityProfile = true
  }

  return {
    isLoadingCommunityProfile,
    isMyCommunityProfile,
    isOnExploreTab,
    isOnFeedPage,
    isOnMyCommunityProfilePage,
    isOnSubCommunityPage,
    isOnUserProfilePage,
    loggedIn,
    // This comes from communityV2Reducer
    loggedInCommunityUser,
    // This comes from authReducer
    loggedInUser,
    profileUser,
    subCommunity,
    subCommunitySlug,
    username: usernameFromUrl,
    verified,
  }
}
