import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

const PictureCard = ({
  alt,
  children,
  className,
  onClick,
  src,
  srcSet,
  width,
}) => {
  const classes = {
    base: classnames('picture-card', 'qa-picture-card', className),
  }

  return (
    <div className={classes.base} role='presentation' onClick={onClick}>
      <div className='picture-card__spacer'>
        <img
          alt={alt}
          className='picture-card__img'
          src={src}
          srcSet={srcSet}
          width={width}
        />
      </div>

      <div className='picture-card__content'>{children}</div>
    </div>
  )
}

PictureCard.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  width: PropTypes.number,
}

PictureCard.defaultProps = {
  alt: '',
  className: null,
  onClick: () => null,
  srcSet: null,
  width: 200,
}

export default PictureCard
