import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  POST_RESEND_VERIFICATION_FAILURE,
  POST_RESEND_VERIFICATION_REQUEST,
  POST_RESEND_VERIFICATION_SUCCESS,
} from './types'

// Email verification

const postResendVerificationRequest = () => ({
  type: POST_RESEND_VERIFICATION_REQUEST,
})

const postResendVerificationSuccess = () => ({
  type: POST_RESEND_VERIFICATION_SUCCESS,
})

const postResendVerificationError = message => ({
  type: POST_RESEND_VERIFICATION_FAILURE,
  message: String(message),
})

export const postResendVerification = () => (dispatch, getState) => {
  dispatch(postResendVerificationRequest())

  const state = getState()
  const { id } = state.authReducer.user

  return rtApi.business
    .resendEmailVerification({ id })
    .then(json =>
      Promise.resolve(dispatch(postResendVerificationSuccess(json)))
    )
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      dispatch(postResendVerificationError(ex))
      return Promise.reject(new Error())
    })
}
