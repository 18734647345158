import { waitForGlobal } from 'common/helpers'

/**
 * Attempts to authenticate via Apple OAuth. This may prompt the user with an Apple OAuth signup window. If successful, a token is provided.
 * @returns {Promise<{ token: string, response?: object }>} Promise fulfilled by an object with an access token if successful. Provides an access token for successful authentication, as well as the original auth response, which contains more details.
 */
export const authenticateAppleUser = () =>
  new Promise((resolve, reject) =>
    waitForGlobal('AppleID', 60)
      .then(() => window.AppleID.auth.signIn())
      .then(appleAuthResponse =>
        resolve({
          token: appleAuthResponse.authorization.code,
          response: appleAuthResponse,
        })
      )
      .catch(ex => reject(ex))
  )

export default authenticateAppleUser
