import React, { useCallback, useMemo } from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'
import { useToggle } from 'common/hooks'
import useRefScrolledIntoView from 'common/hooks/useRefScrolledIntoView'

import { useCarouselPaging, useFixInvalidOffsets } from './page-helpers'
import {
  useCarouselStyle,
  useHeightAdjustStyle,
  useItemOuterStyle,
} from './style-helpers'

import './styles.scss'

const defaultPageButtonStyle = {
  top: '50%',
  transform: `translateY(-50%)`,
}

const usePlaceholderListElem = ({
  carouselInnerStyle,
  carouselStyle,
  currentOffset,
  getItemKey,
  heightAdjustStyle,
  isScroll,
  itemInnerStyle,
  perPage,
  placeholder,
  scrollModeMargins,
  scrollSpacerStyle,
}) =>
  useMemo(
    () => {
      if (!placeholder) return () => null
      const placeholderElems = []
      for (let index = 0; index < perPage; index += 1) {
        placeholderElems.push(
          <PositionedItemOuter
            currentOffset={currentOffset}
            getItemKey={getItemKey}
            index={index}
            isScroll={isScroll}
            itemInnerStyle={itemInnerStyle}
            key={index}
            perPage={perPage}
            renderItem={placeholder}
            scrollModeMargins={scrollModeMargins}
          />
        )
      }

      const Placeholder = placeholder

      return () => (
        <div
          className={`rt-carousel-v2 rt-carousel-v2--${perPage}-items`}
          style={carouselStyle}
        >
          <div className='rt-carousel-v2__inner' style={carouselInnerStyle}>
            <div
              className='rt-carousel-v2__height-adjust'
              style={heightAdjustStyle}
            >
              <div style={itemInnerStyle}>
                <Placeholder />
              </div>
            </div>
            {placeholderElems}
            {isScroll && <div style={scrollSpacerStyle} />}
          </div>
        </div>
      )
    },
    [
      carouselInnerStyle,
      carouselStyle,
      currentOffset,
      getItemKey,
      heightAdjustStyle,
      isScroll,
      itemInnerStyle,
      perPage,
      placeholder,
      scrollModeMargins,
      scrollSpacerStyle,
    ]
  )

const PositionedItemOuter = React.memo(
  ({
    currentOffset,
    index,
    isScroll = true,
    item,
    itemInnerStyle,
    perPage,
    renderItem,
    scrollModeMargins,
    selected,
  }) => {
    const itemOuterStyle = useItemOuterStyle({
      currentOffset,
      index,
      isScroll,
      perPage,
      scrollModeMargins,
    })

    return (
      <div
        className={`rt-carousel-v2-item rt-carousel-v2-item--position-${index}`}
        style={itemOuterStyle}
      >
        <div style={itemInnerStyle}>
          {renderItem({
            index,
            item,
            selected,
          })}
        </div>
      </div>
    )
  }
)

const PaginationDot = React.memo(({ index, selected, setCurrentOffset }) => {
  const memoizedSetCurrentOffset = useCallback(
    () => {
      setCurrentOffset(index)
    },
    [index, setCurrentOffset]
  )
  return (
    <div
      className={`rt-carousel-v2-page-dots-container__dot-outer ${
        selected
          ? 'rt-carousel-v2-page-dots-container__dot-outer--selected'
          : ''
      }`}
      key={index}
      {...a11yButtonProps(memoizedSetCurrentOffset)}
    >
      <div
        className={`rt-carousel-v2-page-dots-container__dot ${
          selected ? 'rt-carousel-v2-page-dots-container__dot--selected' : ''
        }`}
      />
    </div>
  )
})

/**
 * A carousel based on the `items` provided.
 * @param {function} getItemKey - **Required.** Used to get the key for `items` within the carousel. Has the signature `(item) => String`
 * @param {number} initialIndex - The offset on which to start the carousel. Default `0`.
 * @param {boolean} isScroll - Whether the carousel should use natural scrolling or pages with buttons. By default will be `true` for touch devices, otherwise will be `false` by default. If `true`, `scrollModeMargins` should also be provided.
 * @param {array} items - The elements of the carousel. Each of these will be passed to `renderItem` within a carousel item element, which determines the width and position of the rendered element.
 * @param {function} onNextClick - Optional callback for when the "next page" button is clicked.
 * @param {function} onPrevClick - Optional callback for when the "previous page" button is clicked.
 * @param {function} pageButtonStyle - Optional style object applied to the page buttons. Default is a style object that provides sane vertical centering for most carousels.
 * @param {number} perPage - How many items should be displayed on a page. Default `5`.
 * @param {Component} placeholder - Used to render a placeholder item when the carousel has not been loaded. Matches the same signature as `renderItem`.
 * @param {Component} renderItem - **Required.** Used to render an item from `items` within the carousel. Has the signature `({ item, selected }) => Component`
 * @param {number} scrollModeMargins - When we're in natural scrolling mode, this component needs to know how much overflow should be visible on the left and right sides. It will expand the outer carousel component based on this number. It cannot simply use a div the width of its container because `overflow: scroll` causes the content to be clipped to that parent div. Default `0`.
 * @param {number|undefined} selectedIndex - Index of the currently selected item, or `undefined`. If an item is selected, the `selected` property in its `renderItem` callback will be `true`. Default `undefined`.
 * @param {number} spacing - The spacing, in pixels, between each element of the carousel. Default `20`. Note that this cannot be accomplished with pure CSS—see `carouselCSS`.
 * @param {number} teasedItemCount - The number of items which should be displayed before or after the current page of the carousel.
 * @param {number} transitionDuration - The duration of the page transition, in milliseconds.
 * @return {Component} The carousel.
 */
const CarouselV2 = ({
  allowOverflow,
  getItemKey,
  initialIndex = 0,
  isScroll = window.innerWidth < 600,
  items = [],
  onNextClick,
  onPrevClick,
  pageButtonStyle = defaultPageButtonStyle,
  paginationDots = true,
  perPage = 5,
  placeholder,
  renderItem,
  scrollModeMargins = 0,
  selectedIndex,
  spacing: spacingFromProps,
  teasedItemCount: teasedItemCountFromProps,
  transitionDuration = 200,
}) => {
  let teasedItemCount =
    teasedItemCountFromProps === undefined ? perPage : teasedItemCountFromProps

  // TODO: This means that all tiles will load immediately for mobile users. We may need to provide the caller of CarouselV2 with approach for lazy loading individual tiles so that we don't load 20+ images at once when the whole carousel is lazy loaded.
  if (isScroll) teasedItemCount = Infinity

  const [inView, setInView] = useToggle()

  const {
    canGoBack,
    canGoForward,
    currentOffset,
    displayedItemsWithIndices,
    handleNextPageClick,
    handlePrevPageClick,
    pageIndices,
    setCurrentOffset,
  } = useCarouselPaging({
    initialIndex,
    items,
    onNextClick,
    onPrevClick,
    perPage,
    teasedItemCount,
    transitionDuration,
  })

  useFixInvalidOffsets({ currentOffset, pageIndices, setCurrentOffset })

  const spacing = spacingFromProps === undefined ? 10 : spacingFromProps / 2

  const {
    carouselInnerStyle,
    carouselOuterStyle,
    carouselStyle,
    itemInnerStyle,
    scrollSpacerStyle,
  } = useCarouselStyle({
    allowOverflow: teasedItemCount > 0 && allowOverflow,
    currentOffset,
    inView,
    isScroll,
    items,
    perPage,
    scrollModeMargins,
    spacing,
    transitionDuration,
  })

  const [firstItem] = items

  // Load carousels on demand.
  const carouselRef = useRefScrolledIntoView(setInView, {
    topOffset: 500,
    bottomOffset: 500,
    mode: 'any-visible',
  })

  const heightAdjustStyle = useHeightAdjustStyle({
    isScroll,
    perPage,
    scrollModeMargins,
  })

  // Reduces cumulative layout shift and helps ensure correct loading of carousels. If a "placeholder" element is provided, this element is used render a layout before the contents have been loaded via "inView".
  const PlaceholderList = usePlaceholderListElem({
    carouselInnerStyle,
    carouselStyle,
    currentOffset,
    getItemKey,
    heightAdjustStyle,
    isScroll,
    itemInnerStyle,
    perPage,
    placeholder,
    scrollModeMargins,
    scrollSpacerStyle,
  })

  return (
    <div
      className={`rt-carousel-v2__outer ${
        inView ? '' : 'rt-carousel-v2__outer--placeholder'
      }`}
      ref={carouselRef}
      style={carouselOuterStyle}
    >
      {inView ? (
        <>
          <div
            className={`rt-carousel-v2 rt-carousel-v2--${perPage}-items`}
            style={carouselStyle}
          >
            <div className='rt-carousel-v2__inner' style={carouselInnerStyle}>
              {firstItem !== undefined && (
                <div
                  className='rt-carousel-v2__height-adjust'
                  style={heightAdjustStyle}
                >
                  <div style={itemInnerStyle}>
                    {renderItem({ item: firstItem })}{' '}
                  </div>
                </div>
              )}
              {displayedItemsWithIndices.map(([index, item]) => (
                <PositionedItemOuter
                  currentOffset={currentOffset}
                  getItemKey={getItemKey}
                  index={index}
                  isScroll={isScroll}
                  item={item}
                  itemInnerStyle={itemInnerStyle}
                  key={getItemKey(item)}
                  perPage={perPage}
                  renderItem={renderItem}
                  scrollModeMargins={scrollModeMargins}
                  selected={selectedIndex === items.indexOf(item)}
                />
              ))}
              {isScroll && <div style={scrollSpacerStyle} />}
            </div>
          </div>
          {!isScroll && canGoBack && (
            <div
              {...a11yButtonProps(handlePrevPageClick)}
              className='rt-carousel-v2-page-button rt-carousel-v2-page-button--prev'
              style={pageButtonStyle}
            >
              <div className='rt-carousel-v2-page-button__content'>
                <div className='rt-carousel-v2-page-button__highlight' />
                <div className='rt-carousel-v2-page-button__inner' />
                <div className='rt-carousel-v2-page-button__inner-highlight' />
                <div className='rt-carousel-v2-page-button__chevron' />
              </div>
            </div>
          )}
          {!isScroll && canGoForward && (
            <div
              {...a11yButtonProps(handleNextPageClick)}
              className='rt-carousel-v2-page-button rt-carousel-v2-page-button--next'
              style={pageButtonStyle}
            >
              <div className='rt-carousel-v2-page-button__content'>
                <div className='rt-carousel-v2-page-button__highlight' />
                <div className='rt-carousel-v2-page-button__inner' />
                <div className='rt-carousel-v2-page-button__inner-highlight' />
                <div className='rt-carousel-v2-page-button__chevron' />
              </div>
            </div>
          )}
          {paginationDots && !isScroll && pageIndices.length > 1 && (
            <div className='rt-carousel-v2-page-dots-container'>
              {pageIndices.map(index => (
                <PaginationDot
                  index={index}
                  key={index}
                  selected={index === currentOffset}
                  setCurrentOffset={setCurrentOffset}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <PlaceholderList />
      )}
    </div>
  )
}

export default React.memo(CarouselV2)
