import {
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  RESET_SCHEDULE,
  SET_CONTEXT,
  SET_DATES,
} from '../actions/types'

const initialState = {
  context: 'future',
  errorMessage: null,
  isFetching: false,
  dates: [],
  futureDates: [],
  pastDates: [],
  videos: {
    byDate: {},
  },
}

function schedule(state = initialState, action) {
  switch (action.type) {
    case SET_CONTEXT:
      return {
        ...state,
        context: action.context,
      }
    case SET_DATES:
      return {
        ...state,
        dates: action.dates,
        futureDates: action.futureDates,
        pastDates: action.pastDates,
      }
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isFetching: false,
        videos: {
          ...state.videos,
          byDate: {
            ...state.videos.byDate,
            [action.date]: action.videos,
          },
        },
      }
    case GET_SCHEDULE_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case RESET_SCHEDULE:
      return initialState
    default:
      return state
  }
}

export default schedule
