import { endpoints } from 'common/config'
import { authenticatedFetch } from 'common/helpers/authenticated-fetch'

const v1ApiBase = `${endpoints.communityBase}/v1`

const addParams = (url, params = {}) => {
  if (Object.keys(params).length === 0) return url
  const searchParams = new URLSearchParams(params)
  return `${url}?${searchParams.toString()}`
}

const communityRequest = (method, urlPath, fetchOpts = {}) =>
  authenticatedFetch(`${v1ApiBase}${urlPath}`, {
    ...fetchOpts,
    // Attempt to use existing tokens (until Community is included in svod-api)
    defaultAuth: true,
    method,
  }).then(response => response.json())

const communityGet = (urlPath, fetchOpts = {}) =>
  communityRequest('GET', addParams(urlPath, fetchOpts.params), fetchOpts)

const communityPost = (urlPath, body = {}) =>
  communityRequest('POST', urlPath, { body: JSON.stringify(body) })

const communityPatch = (urlPath, body = {}) =>
  communityRequest('PATCH', urlPath, { body: JSON.stringify(body) })

const communityDelete = urlPath => communityRequest('DELETE', urlPath)

// For some routes, the community API requires a UUID but doesn't actually care what the UUID is.
const phoneyUuid = '00000000-0000-0000-0000-000000000000'

const communityApi = {
  // Get a user
  getCommunityUser: userId =>
    // Allow this request to be made for anons
    communityGet(`/users/${userId}`, { doNotForceAuth: true }),

  // Mute and unmute a user
  muteCommunityUser: userId =>
    communityPost(`/users/${phoneyUuid}/mute`, { users: [userId] }),
  unmuteCommunityUser: userId =>
    communityDelete(`/users/${phoneyUuid}/unmute/${userId}`),

  // Follow and unfollow a user
  followCommunityUser: userId => communityPost(`/users/${userId}/follow`),
  unfollowCommunityUser: userId => communityDelete(`/users/${userId}/follow`),

  // See who a user is following
  getCommunityUserFollowing: (userId, params = {}) =>
    communityGet(`/users/${userId}/following`, {
      params,
      doNotForceAuth: true,
    }),

  // See who a user's followers are
  getCommunityUserFollowers: (userId, params = {}) =>
    communityGet(`/users/${userId}/followers`, {
      params,
      doNotForceAuth: true,
    }),

  // See groups that a community user is a member of
  getCommunityUserMemberships: (userId, params) =>
    communityGet(`/memberships/user/${userId}`, {
      params,
      doNotForceAuth: true,
    }),

  // NOTE: The backend routes `feed` and `home_feed` are misnamed. The `home_feed` is a user's activity, whereas `feed` is the "home" feed seen by an authenticated user.
  // See home feed for logged in user, such as posts from followees and groups followed by the user. Note that the userId provided must match the authenticated user, or the request will fail with a 401.
  getHomeFeedForLoggedInUser: (userId, params) =>
    communityGet(`/users/${userId}/feed`, { params }),

  // See posts that a user has made or reposted
  getUserActivityFeed: (userId, params) =>
    communityGet(`/users/${userId}/home_feed`, {
      params,
      doNotForceAuth: true,
    }),

  // See public feed for all activity in a sub-community
  getSubCommunityFeed: (communityId, params) =>
    communityGet(`/communities/${communityId}/home_feed`, {
      params,
      doNotForceAuth: true,
    }),

  // Fetch sub-communities
  getSubCommunities: params =>
    communityGet(`/communities`, { params, doNotForceAuth: true }),

  // See a sub-community
  getSubCommunity: subCommunitySlug =>
    communityGet(`/communities/slug/${subCommunitySlug}`, {
      doNotForceAuth: true,
    }),

  // See members of a sub-community
  getSubCommunityMembers: (subCommunityId, params) =>
    communityGet(`/communities/${subCommunityId}/members`, {
      params,
      doNotForceAuth: true,
    }),

  // As the logged in user, leave a sub-community
  leaveSubCommunity: subCommunityId =>
    communityPost(`/communities/${subCommunityId}/leave`),

  // As the logged in user, join a sub-community
  joinSubCommunity: subCommunityId =>
    communityPost(`/communities/${subCommunityId}/join`),

  // Like or unlike a post
  likePost: postId => communityPost(`/posts/${postId}/like`),
  unlikePost: postId => communityDelete(`/posts/${postId}/like`),

  // Flag post as "abuse" or "spam"
  flagPost: (postId, type) => communityPost(`/posts/${postId}/flag`, { type }),

  // Pin post to community
  pinPostToCommunity: (postId, communityId) =>
    communityPatch(`/communities/${communityId}`, {
      stickied_post_id: postId,
    }),

  unpinPostInCommunity: communityId =>
    communityPatch(`/communities/${communityId}`, {
      stickied_post_id: null,
    }),

  // Fetch example posts
  getExamplePosts: params =>
    communityGet(`/communities/explore_feed`, { params, doNotForceAuth: true }),
}

export default communityApi
