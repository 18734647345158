import uniqBy from 'lodash.uniqby'

import { addOrReplacePositionObj } from 'common/helpers'

import {
  GET_BONUS_FAILURE,
  GET_BONUS_PERCENT_FAILURE,
  GET_BONUS_PERCENT_REQUEST,
  GET_BONUS_PERCENT_SUCCESS,
  GET_BONUS_REQUEST,
  GET_BONUS_SUCCESS,
} from '../actions/types'

const bonusReducer = (
  state = {
    bonus: [],
    isFetching: false,
    itemPercentages: [],
    lastUpdated: null,
  },
  action
) => {
  switch (action.type) {
    case GET_BONUS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_BONUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bonus: uniqBy([...state.bonus, ...action.bonus], 'uuid'),
        lastUpdated: action.receivedAt,
      }
    case GET_BONUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_BONUS_PERCENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_BONUS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: action.items,
        lastUpdated: Date.now(),
        isFetching: false,
      }
    case GET_BONUS_PERCENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case 'video/POST_VIDEO_SAVE_POSITION_SUCCESS':
      return {
        ...state,
        itemPercentages: addOrReplacePositionObj(state.itemPercentages, {
          from: 'es',
          uuid: action.episodeId,
          value: action.played,
        }),
        lastUpdated: Date.now(),
      }
    default:
      return state
  }
}

export default bonusReducer
