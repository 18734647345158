import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { useDateBasedFeatureFlag } from 'common/hooks'

import { TryFirstBase, TryFirstLogo } from './styled'

function TryFirst({ className, isAuthenticated, onClick }) {
  const signupHref = isAuthenticated ? '/signup/select' : '/signup'

  const shouldHideFirstLogo = useDateBasedFeatureFlag(
    'disableFirstUpgradesDate'
  )

  if (shouldHideFirstLogo) {
    return null
  }

  return (
    <TryFirstBase
      aria-label='FIRST free trial'
      className={classnames('qa-try-first', className)}
      linkTo={signupHref}
      onClick={onClick}
    >
      <TryFirstLogo />
    </TryFirstBase>
  )
}

TryFirst.propTypes = {
  className: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

TryFirst.defaultProps = {
  className: null,
}

export default TryFirst
