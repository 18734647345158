import { all, call, put, takeEvery } from 'redux-saga/effects'

import { endpoints } from 'common/config'
import { simpleCall } from 'common/helpers/saga'

export function* requestUploadImage(image) {
  return yield call(simpleCall, {
    url: endpoints.imageUpload,
    method: 'POST',
    type: 'common/GET_UPLOAD_URL',
    authenticated: true,
    body: {
      filename: image.name,
    },
  })
}

export function uploadToBucket(metaData, file) {
  const body = new FormData()
  for (const key in metaData.fields) {
    if ({}.hasOwnProperty.call(metaData.fields, key)) {
      body.append(key, metaData.fields[key])
    }
  }
  body.append('file', file)
  return fetch(metaData.url, {
    method: 'POST',
    body,
  })
}

export function* uploadImage(file) {
  const resp = yield call(requestUploadImage, file)
  yield call(uploadToBucket, resp, file)
  const image = {
    awsBucket: resp.url,
    name: file.name,
    path: resp.fields.key,
    url: resp.file_path,
  }
  yield put({
    type: 'common/UPLOAD_IMAGE_RESP',
    image,
  })
  return image
}

export function* uploadImages({
  files,
  respType = 'common/UPLOAD_IMAGES_RESP',
}) {
  const images = yield all(files.map(file => call(uploadImage, file)))
  yield put({
    type: respType,
    images,
  })
  return images
}

export function* images() {
  yield takeEvery('common/UPLOAD_IMAGES', uploadImages)
}
