import { GrowthBook } from '@growthbook/growthbook'

import { config } from 'common/config'
import { trackEvent } from 'common/metrics'

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    trackEvent('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.variationId,
      // NOTE: Soon, user_tier will not need to be provided to `trackEvent` because `trackEvent` will have `store.getState()` internally, which will be our way of including whatever we want to include on all tracked events. But for the time being, while no store is exported, it's practical to simply get the user tier from the growthbook instance, since we know it will have one.
      userTier: growthbook?.getAttributes()?.userTier,
    })
  },
})

// Uncomment this to test the growthBook instance.
// window.growthbook = growthbook

if (process.env.NODE_ENV !== 'test') {
  // Load feature definitions from API
  fetch(config.growthBook.featuresEndpoint)
    .then(res => res.json())
    .then(json => growthbook.setFeatures(json.features))
    .catch(() => {
      throw new Error('Failed to fetch feature definitions from GrowthBook')
    })
}

export default growthbook
