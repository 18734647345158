import sortBy from 'lodash.sortby'

const initialState = {
  list: [],
  listByCommunityId: new Map(),
  userMemberships: {}, // memberships for other users
  userMembershipsFeedIsFetching: false,
  userMembershipsCanPage: true,
  userMembershipsPagination: {},
}

export default (
  state = initialState,
  { type, data: list, req, pagination }
) => {
  let nextState
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/MEMBERSHIPS_RESP':
      const listByCommunityId = new Map()
      // TODO: remove this once we have an alpha sort option on the BE
      list = sortBy(list, membership => {
        return membership.community.name.toLowerCase()
      })

      for (let membership of list) {
        listByCommunityId.set(membership.community_id, membership)
      }

      return {
        ...state,
        totalModeratorships: list.length,
        total: list.length,
        list,
        listByCommunityId,
      }
    case 'communityApp/MEMBERSHIPS_USER_REQ':
      if (!req.nextPage) {
        return {
          ...state,
          userMemberships: initialState.userMemberships,
          userMembershipsCanPage: initialState.userMembershipsCanPage,
          userMembershipsPagination: initialState.userMembershipsPagination,
          userMembershipsFeedIsFetching: true,
        }
      } else {
        return {
          ...state,
          userMembershipsFeedIsFetching: true,
        }
      }
    case 'communityApp/MEMBERSHIPS_USER_RESP':
      let userMemberships = {
        ...state.userMemberships,
        [req.id]: [
          ...(state.userMemberships[req.id]
            ? state.userMemberships[req.id]
            : []),
          ...list,
        ],
      }

      return {
        ...state,
        userMemberships,
        userMembershipsPagination: pagination,
        userMembershipsCanPage: !!pagination.next,
        userMembershipsFeedIsFetching: false,
      }
    case 'communityApp/subCommunity/join_RESP':
      nextState = new Map(state.listByCommunityId)
      nextState.set(list.community_id, list)
      return {
        ...state,
        listByCommunityId: nextState,
      }
    case 'communityApp/subCommunity/leave_RESP':
      // TODO: figure out if we need to modify anything based on the response
      nextState = new Map(state.listByCommunityId)
      nextState.delete(req.id)
      return {
        ...state,
        listByCommunityId: nextState,
      }
    default:
      return state
  }
}
