import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { getCanonicalUrl, getIsEpisodePage } from 'routing/selectors'

const CanonicalLinks = () => {
  const isEpisodePage = useSelector(getIsEpisodePage)
  const canonicalUrl = useSelector(getCanonicalUrl)
  if (isEpisodePage) {
    return (
      <Helmet>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>
    )
  }
  return null
}

export default CanonicalLinks
