/**
 * Gets src of image based on params
 * @param {Array} images
 * @param {String} size
 * @param {String} type
 * @return {String} url
 */
export const getImageSrc = (images, size = 'thumb', type = 'cover') => {
  if (images && images.length) {
    const image = images.find(
      pic => pic.attributes && pic.attributes.image_type === type
    )
    if (image !== undefined) {
      return image.attributes[size]
    }
  }

  if (type === 'title_card') {
    return `${process.env.PUBLIC_URL}/img/generic_rt_title_card_small.jpeg`
  }
  return `${process.env.PUBLIC_URL}/img/generic_rt_cover.jpg`
}

export const getCloudinaryPublicIdFromGearItem = gearItem => {
  const url = getImageSrc(gearItem.included.images, 'large', 'content_picture')
  const urlEnding = url.split('f_auto')[1]
  return urlEnding
}
