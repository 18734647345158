import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { restrictFiles } from 'common/helpers'

export class DropUpload extends Component {
  static propTypes = {
    addFiles: PropTypes.func.isRequired,
  }

  stopEvent = event => {
    event.stopPropagation()
    event.preventDefault()
  }

  onDrop = event => {
    this.stopEvent(event)
    if (event && event.nativeEvent && event.nativeEvent.dataTransfer) {
      const files = restrictFiles(event.nativeEvent.dataTransfer.files)
      this.props.addFiles(files)
    }
  }

  render() {
    const { children } = this.props
    return (
      <div
        onDragEnter={this.stopEvent}
        onDragOver={this.stopEvent}
        onDrop={this.onDrop}
      >
        {children}
      </div>
    )
  }
}
