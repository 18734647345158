import React from 'react'

import { TAB_ALL_RESULTS } from 'rootApp/containers/SearchV2/helpers/constants'

import WrappedCommunityGroup from '../../shared/WrappedCommunityGroup'

const GroupsColumn = ({ closeSearchV2, groups, searchTerm }) => (
  <div className='search-v2-all-results__groups'>
    {groups.map(group => (
      <WrappedCommunityGroup
        key={group.uuid}
        closeSearchV2={closeSearchV2}
        group={group}
        searchTerm={searchTerm}
        tab={TAB_ALL_RESULTS}
      />
    ))}
  </div>
)

export default GroupsColumn
