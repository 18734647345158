import { simpleGET } from 'common/helpers/saga'
import { takeEvery } from 'redux-saga/effects'
import { endpoints } from 'common/config'

export function* getTaggedPosts({ tag, nextPage }) {
  yield simpleGET({
    authenticated: false,
    url: `${endpoints.communityBase}/v1/posts/tags?q=${tag}${
      nextPage ? '&cursor=' + nextPage : ''
    }`,
    type: 'communityApp/c/getTaggedPosts',
    tag,
    id: tag,
  })
}

export function* taggedPosts() {
  yield takeEvery('communityApp/c/getTaggedPosts', getTaggedPosts)
}
