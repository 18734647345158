import { createReducer } from '@reduxjs/toolkit'

import {
  getUserSuccess,
  setPendingRssActions,
} from 'showsApp/actions/rssActions'

const initialState = {
  pendingRssActions: {
    isPending: false,
    seriesUuid: null,
  },
  rssLoginToken: null,
  rssLoginUrl: null,
}

const rssReducer = createReducer(initialState, builder => {
  builder.addCase(setPendingRssActions, (state, action) => {
    state.pendingRssActions = action.payload
  })
  builder.addCase(getUserSuccess, (state, action) => {
    state.rssLoginToken = action.user.attributes.supporting_cast_login_token
    state.rssLoginUrl = action.user.attributes.supporting_cast_login_url
  })
})

export default rssReducer
