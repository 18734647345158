import React from 'react'

import { EpisodeCard } from 'common/components'
import useGenericContentBlockItemTracking from 'common/hooks/useGenericContentBlockItemTracking'

import ContentBlockBase, { ContentBlockHeaderLink } from './ContentBlockBase'

const EpisodeCardOuter = ({ contentBlock, item, listId, location }) => {
  const handleClick = useGenericContentBlockItemTracking({
    contentBlock,
    item,
    location,
  })

  return (
    <div className='content-block__episode-group-episode'>
      <EpisodeCard
        item={item}
        key={item.uuid}
        listId={listId}
        onThumbnailClick={handleClick}
        onTitleClick={handleClick}
        width='600'
      />
    </div>
  )
}

const ContentBlockEpisodeGroup = ({
  contentBlock,
  headerUrl,
  items,
  listId,
  location,
  title1,
  title2,
}) => (
  <ContentBlockBase>
    <div className='content-block__episode-group-container'>
      <ContentBlockHeaderLink
        contentBlock={contentBlock}
        header1={title1}
        header2={title2}
        location={location}
        url={headerUrl}
      />
      <div className='content-block__episode-group-episodes'>
        {items?.map(item => (
          <EpisodeCardOuter
            contentBlock={contentBlock}
            item={item}
            key={item.attributes.uuid}
            listId={listId}
            location={location}
          />
        ))}
      </div>
    </div>
  </ContentBlockBase>
)

export default ContentBlockEpisodeGroup
