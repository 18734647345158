/* eslint-disable no-return-assign, camelcase */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Dropdown } from 'materialize-css'
import PropTypes from 'prop-types'
import {
  getPostType,
  userOwnsPost,
  isModeratorOfPost,
  isAdminOfPost,
  hasRole,
  ROLE_ADMIN,
  ROLE_GLOBAL_MODERATOR,
} from 'common/helpers'
import uniq from 'lodash.uniq'
import actions from './post-actions'

class PostActions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    post: PropTypes.object.isRequired,
    user: PropTypes.object,
    // for communities, we need to know the users membership privledges
    membership: PropTypes.object,
    uuid: PropTypes.string.isRequired,
  }
  static defaultProps = {
    dispatch: () => null,
    onEdit: () => null,
    owner_uuid: '',
    user: {},
    uuid: '',
  }

  dropdownRef = React.createRef()

  // ----------
  // Lifecycle
  // ----------
  componentDidMount() {
    const { dispatch, post, user } = this.props
    // sometimes we already have a user so bind the dropdown, as we should have actions
    if (user) {
      this.bindDropdown()
    }
    if (!post || !post.id || !user) {
      return
    }
    // FIXME: The "id" parameter here is ignored by the reducers / sagas. The action simply fetches memberships for the current logged in user. Call this only once at a higher level.
    dispatch({ type: 'communityApp/MEMBERSHIPS', id: post.id })
  }

  componentDidUpdate(prevProps) {
    const { post, memberships, dispatch, user } = this.props
    if (
      post &&
      !memberships.list.length &&
      prevProps.memberships.list.length !== memberships.list.length &&
      user
    ) {
      // FIXME: The "id" parameter here is ignored by the reducers / sagas. The action simply fetches memberships for the current logged in user. Call this only once at a higher level.
      dispatch({ type: 'communityApp/MEMBERSHIPS', id: post.id })
    }

    // we just got a user, so we can have actions, hooray.
    if (!prevProps.user && prevProps.user !== user) {
      this.bindDropdown()
    }
  }

  // ----------
  // Methods
  // ----------
  /**
   * Pulls appropriate action set from `post-actions` based on post type
   * user membership (if a community) and user roles
   */
  getActions() {
    const { post, user, memberships } = this.props
    const postType = getPostType({ post })
    let availableActions = [...actions.POST_FLAG_ACTIONS]

    if (userOwnsPost({ user, post })) {
      availableActions = [...actions['POST_USER_OWNER_ACTIONS']]
    }

    if (hasRole(ROLE_ADMIN, user) || hasRole(ROLE_GLOBAL_MODERATOR, user)) {
      availableActions = [
        ...availableActions,
        ...actions['POST_USER_ADMIN_ACTIONS'],
      ]
    }

    if (
      postType === 'community' &&
      (isAdminOfPost({ post, memberships }) ||
        isModeratorOfPost({ post, memberships }))
    ) {
      availableActions = [
        ...availableActions,
        ...actions['POST_COMMUNITY_ADMIN_ACTIONS'],
      ]
    }

    return uniq(availableActions)
  }

  renderActions(items) {
    return items.map((item, idx) => {
      let text =
        typeof item.text === 'function' ? item.text(this.props) : item.text
      if (text === 'divider') {
        return <li key={idx} className='divider' />
      }

      if (text === 'Flag this comment') {
        return (
          <li key={idx}>
            <a
              className='dropdown-control-nested modal-trigger'
              data-target={`${this.props.uuid}-dropdown-flags`}
            >
              {text}
            </a>
          </li>
        )
      } else {
        return (
          <li key={idx}>
            <span
              className={item.className}
              onClick={() => {
                if (item.action) {
                  item.action(this.props)
                }
              }}
            >
              {text}
            </span>
          </li>
        )
      }
    })
  }

  bindDropdown() {
    Dropdown.init(this.dropdownRef.current, {
      alignment: 'right',
      constrainWidth: false,
      coverTrigger: false,
    })
  }

  // ------
  // Render
  // ------
  render() {
    const uuid = this.props.post.id
    const items = this.getActions()
    if (!this.props.user) return null
    return (
      <div
        className='post--admin-controls'
        ref={ref => (this.containerEl = ref)}
      >
        {items?.length > 0 ? (
          <Fragment>
            <a
              className='dropdown-control link-overlay'
              data-target={`${uuid}-dropdown`}
              ref={this.dropdownRef}
            >
              <i className='icon icon-more_horiz lg' />
            </a>

            <ul
              id={`${uuid}-dropdown`}
              className='dropdown-content dropdown-content-inverted'
            >
              {this.renderActions(items)}
            </ul>
          </Fragment>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { memberships } = state.communityReducer
  const { user } = state.authReducer
  const id = ownProps.post.owner.data.id
  const membershipInCurrentCommunity = memberships.listByCommunityId.get(id)
  return {
    membership: membershipInCurrentCommunity,
    memberships,
    user,
  }
}

export default connect(mapStateToProps)(PostActions)
