export const flagAction = {
  action: 'flagComment',
  text: 'Flag this comment',
}

export const editAction = {
  action: 'editComment',
  text: 'Edit this comment',
}

export const deleteAction = {
  action: 'deleteComment',
  text: 'Delete this comment',
}

export const restoreAction = {
  action: 'restoreComment',
  text: 'Restore this comment',
}
