/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import classnames from 'classnames'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { refreshLivestreams } from 'common/actions'
import { PremiumBadge } from 'common/components'
import { isTimeInFuture } from 'common/helpers'

import './styles.scss'

class LivestreamCard extends Component {
  static propTypes = {
    imageType: PropTypes.string,
    item: PropTypes.object.isRequired,
    onThumbnailClick: PropTypes.func,
    onTitleClick: PropTypes.func,
  }

  static defaultProps = {
    imageType: 'content_picture',
    onThumbnailClick() {},
    onTitleClick() {},
  }

  state = {
    isLive: !isTimeInFuture(this.props.item.attributes.starts_at),
    isSoon: false,
    timeLeft: 0,
  }

  componentDidMount() {
    if (this.state.isLive === false) {
      this.initializeCountdown()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.item !== nextProps.item ||
      this.state.isLive !== nextState.isLive ||
      this.state.isSoon !== nextState.isSoon ||
      this.state.timeLeft !== nextState.timeLeft ||
      this.props.user !== nextProps.user
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  getLivestreamSchedule = startTime => {
    // Using moment-timezone.js we guess the users timezone
    const userTimezone = moment.tz.guess()
    const time = moment(startTime)
      .tz(userTimezone)
      .format('dddd h:mmA z')
    return time
  }

  initializeCountdown = () => {
    const { item } = this.props
    // start countdown here, static timer only
    const endTime = Date.parse(item.attributes.starts_at)
    const currentTime = Date.parse(new Date())
    // How much time until a livestream is live in seconds
    const seconds = endTime - currentTime
    this.minutes = Math.ceil(seconds / 1000 / 60)
    // call countdown with minutes remaining
    this.countdown(this.minutes)
  }

  countdown = minutes => {
    const { dispatch, item } = this.props
    // if it's within 60 minutes of a livestream going live then show the countdown, else hide it
    if (minutes > 0 && minutes < 60) {
      this.setState({
        isSoon: true,
        timeLeft: minutes,
      })
    } else {
      const liveNow = !isTimeInFuture(item.attributes.starts_at)
      this.setState({
        isSoon: false,
      })
      if (liveNow) {
        dispatch(refreshLivestreams())
      }
    }
    minutes -= 1
    this.timer = setTimeout(() => this.countdown(minutes), 60000)
  }

  getLivestreamImage = size => {
    const { imageType, item } = this.props
    let img = ''

    // if image from BE doesn't exist, use placeholder
    if (!item.included?.images || item.included?.images?.length === 0) {
      return `${process.env.PUBLIC_URL}/img/generic_rt_cover.jpg`
    }

    // look for requested image type
    img = item.included.images.find(
      image => image.attributes.image_type === imageType
    )

    // if it didn't find a type requested, just use the first image available
    if (img === undefined) {
      return item.included.images[0].attributes[size]
        ? item.included.images[0].attributes[size]
        : `${process.env.PUBLIC_URL}/img/generic_rt_cover.jpg`
    }

    return img.attributes[size]
  }

  getPremium = () => {
    const { user } = this.props
    const {
      is_sponsors_only: firstOnly,
      member_golive_at: memberGoLive,
    } = this.props.item.attributes
    let userIsFirst = false
    if (user && user?.attributes?.member_tier_i === 1) {
      userIsFirst = true
    }
    if (firstOnly) {
      return <PremiumBadge userIsFirst={userIsFirst} />
    }
    if (isTimeInFuture(memberGoLive)) {
      return <PremiumBadge userIsFirst={userIsFirst} />
    }
    return null
  }

  render() {
    const { item } = this.props
    const { isLive, isSoon } = this.state
    const titleId = `${item.uuid}-label`
    const titleClass = classnames({
      title: true,
      'title--countdown': isLive || isSoon,
    })
    const premiumBadge = this.getPremium()
    return (
      <div className='live-card' ref={elem => (this.card = elem)}>
        <div className='card-content'>
          <div className='card-image-wrapper'>
            <Link
              aria-labelledby={titleId}
              onClick={this.props.onThumbnailClick}
              to={`/live/${item.attributes.slug}`}
            >
              <div
                className='image'
                style={{
                  backgroundImage: `url('${this.getLivestreamImage(
                    `medium`
                  )}')`,
                }}
              />
              {premiumBadge}
            </Link>
          </div>
          <div className='bottom live-bottom'>
            <Link
              to={`/live/${item.attributes.slug}`}
              onClick={this.props.onTitleClick}
            >
              <div className={titleClass}>{item.attributes.title}</div>
            </Link>
            <div className='live-schedule'>
              {this.getLivestreamSchedule(item.attributes.starts_at)}
            </div>
            {isLive ? (
              <div className='live-live'>
                <i className='icon-live_now live-icon' />
                <span className='live-text'>LIVE</span>
              </div>
            ) : this.state.isSoon ? (
              <div className='live-countdown'>
                <span>Live in {this.state.timeLeft}M</span>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(LivestreamCard)
