import {
  GET_DEFAULT_AVATARS_FAILURE,
  GET_DEFAULT_AVATARS_REQUEST,
  GET_DEFAULT_AVATARS_SUCCESS,
} from 'common/actions/types'

const initialState = {
  defaultAvatars: null,
  isLoadingDefault: false,
}

function avatarReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEFAULT_AVATARS_REQUEST:
      return {
        ...state,
        isLoadingDefault: true,
      }
    case GET_DEFAULT_AVATARS_SUCCESS:
      return {
        ...state,
        defaultAvatars: action.avatars,
        isLoadingDefault: false,
      }
    case GET_DEFAULT_AVATARS_FAILURE:
      return {
        ...state,
        isLoadingDefault: false,
      }
    default:
      return state
  }
}

export default avatarReducer
