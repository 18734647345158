import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PrimaryBtn, TertiaryBtn } from 'common/components'
import PropTypes from 'prop-types'

export class StickyPrompt extends Component {
  static propTypes = {
    approveText: PropTypes.string,
    post: PropTypes.object.isRequired,
    removeSticky: PropTypes.bool,
    // Decouple from Community V1 by allowing the caller to provide their own completion handler.
    onConfirm: PropTypes.func,
    text: PropTypes.string,
  }

  static defaultProps = {
    approveText: 'Replace',
    removeSticky: false,
    text:
      'Another post is already pinned. Would you like to replace it with this post?',
  }

  handleConfirm = () => {
    const { onComplete, onConfirm } = this.props

    if (onConfirm) {
      onConfirm()
    } else {
      this.stickyPost()
    }

    if (onComplete) {
      onComplete()
    }
  }

  stickyPost = () => {
    const {
      dispatch,
      post: {
        id: postId,
        owner: {
          data: { id: communityId },
        },
      },
      removeSticky,
    } = this.props

    if (removeSticky) {
      dispatch({
        type: 'communityApp/subCommunity/unpin',
        communityId,
        postToUnpinId: postId,
      })
    } else {
      dispatch({
        type: 'communityApp/subCommunity/pin',
        communityId,
        postToPinId: postId,
      })
    }
  }

  render() {
    const { approveText, onComplete, text } = this.props

    return (
      <div className='sticky-prompt center'>
        <h3>{text}</h3>

        <div className='controls'>
          <TertiaryBtn bg='dark' onClick={() => onComplete()}>
            Cancel
          </TertiaryBtn>
          <PrimaryBtn bg='dark' onClick={this.handleConfirm}>
            {approveText}
          </PrimaryBtn>
        </div>
      </div>
    )
  }
}

export default connect(null)(StickyPrompt)
