import React, { Component } from 'react'
import Parser from 'html-react-parser'
import { markNotificationRead } from 'notifications/actions'
import PropTypes from 'prop-types'

import * as metrics from 'common/metrics'

class ToastTemplate extends Component {
  static propTypes = {
    payload: PropTypes.shape({
      imageUrl: PropTypes.string,
      imageTag: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
      ctaUrl: PropTypes.string,
    }),
  }

  onClick = _e => {
    const { read_at, id } = this.props.payload
    this.props.markRead(id)
    if (!read_at) {
      metrics.trackClick('notification_clicked', { context: 'toast' })
    }
  }

  markRead = id => {
    this.props.dispatch(markNotificationRead(id))
  }

  render() {
    if (!this.props.payload) {
      return null
    }

    const { imageUrl, imageTag, title, body, ctaUrl } = this.props.payload
    return (
      <div className='Toastify__toast-wrapper'>
        {imageUrl && (
          <div className='Toastify__icon'>
            <a href={ctaUrl} onClick={this.onClick}>
              <img src={imageUrl} alt={imageTag} />
            </a>
          </div>
        )}
        <div className='Toastify__body'>
          {title && (
            <div className='Toastify__title'>
              <a href={ctaUrl} onClick={this.onClick}>
                {Parser(title)}
              </a>
            </div>
          )}
          <a href={ctaUrl} onClick={this.onClick}>
            {Parser(body)}
          </a>
        </div>
        {ctaUrl && (
          <div className='Toastify__cta'>
            <a href={ctaUrl} onClick={this.onClick}>
              VIEW
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default ToastTemplate
