import { rgba } from 'polished'
import styled from 'styled-components'

import Avatar from 'common/components/Avatar'

export const EditAvatarBase = styled.div`
  --edit-avatar-button-size: 50px;
  position: relative;
  width: ${({ size }) => `${size}px`};
  margin: auto;
`

export const EditAvatarAvatar = styled(Avatar)`
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  display: block;
`

EditAvatarAvatar.defaultProps = {
  border: 4,
}

export const EditAvatarButton = styled.button`
  appearance: none !important; // Materialize
  background-color: ${rgba('#f7f7f8', 0.8)};
  border-radius: 999px;
  border: none;
  bottom: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: var(--edit-avatar-button-size);
  padding: 0;
  position: absolute;
  right: 2px;
  transition: background-color 0.2s ease-in-out;
  width: var(--edit-avatar-button-size);

  &:focus {
    background-color: ${rgba('#f7f7f8', 0.8)}; // Materialize
  }

  &:hover {
    background-color: ${rgba('#f7f7f8', 0.9)};
  }
`

EditAvatarButton.defaultProps = {
  type: 'button',
}

export const EditAvatarIcon = styled.i`
  font-size: calc(var(--edit-avatar-button-size) * 0.4);
  position: relative;
  right: -0.05em; // Optical center offset
  top: 0.05em; // Optical center offset
`
EditAvatarIcon.defaultProps = {
  className: 'icon-pencil',
}
