export const getFeaturedItemsV3 = state =>
  state.homeReducer.featured.featuredItemsV3

export const getFeaturedItemV3 = (state, channel = 'all') =>
  getFeaturedItemsV3(state)[channel]

export const getFeaturedItemAttributes = (state, channel) =>
  getFeaturedItemV3(state, channel)?.attributes

export const getFeaturedItemLinks = (state, channel) =>
  getFeaturedItemV3(state, channel)?.links

export const getCaption = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.body_caption

export const getCustomItemName = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.custom_item_name

export const getCustomItemUseType = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.custom_item_use_type

export const getDisplayVideoInHero = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.display_video_in_hero

export const getGlitch = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.glitch

export const getHeroImg = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.hero_image_url

export const getHeroImgMobile = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.mobile_hero_image_url

export const getIsSponsorsOnly = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.is_sponsors_only

export const getItemType = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.child_type

export const getItemUuid = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.parent_uuid

export const getLogoImg = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.logo_image_url

export const getMemberGoLiveAt = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.member_golive_at

export const getMemberTierIntegerValue = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.member_tier_i

export const getPrimaryCtaCopy = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.primary_cta_copy

export const getPrimaryCtaOpenNewTab = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.primary_cta_open_new_tab

export const getPrimaryCtaUrl = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.primary_cta_url

export const getSecondaryCtaCopy = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.secondary_cta_copy

export const getSecondaryCtaOpenNewTab = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.secondary_cta_open_new_tab

export const getSecondaryCtaUrl = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.secondary_cta_url

export const getSelfLink = (state, channel) =>
  getFeaturedItemLinks(state, channel)?.self

export const getShowLink = (state, channel) =>
  getFeaturedItemLinks(state, channel)?.show

export const getSsoOverlay = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.sso_overlay

export const getTitle = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.header_caption

export const getVideoUrl = (state, channel) =>
  getFeaturedItemAttributes(state, channel)?.video_url
