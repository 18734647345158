// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

import { accessTokenKey, config, endpoints } from 'common/config'
import { getAuthHeaders } from 'common/headers'
import { getCookie } from 'common/helpers'
import { trackEvent } from 'common/metrics'

function rtRequestPermission({ messaging, uuid, registration }) {
  // [START request_permission]
  Notification.requestPermission()
    .then(permissionResult => {
      if (permissionResult === 'granted') {
        trackEvent('Browser Notifications Consent', { status: 'approved' })
        // eslint-disable-next-line no-console
        console.log('requestPermission succeeded...')
        // console.log('Notification permission granted.')
        // Retrieve an Instance ID token for use with FCM.
        getToken(messaging, { serviceWorkerRegistration: registration })
          .then(currentToken => {
            // eslint-disable-next-line no-console
            console.log('currentToken', currentToken)
            if (currentToken) {
              sendTokenToServer(currentToken, uuid)
            } else {
              // show permission request
              // console.log(
              //   'No Instance ID token available, Request permission to generate one.'
              // )
              setTokenSentToServerForThisUuid('')
            }
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('Unable to get token.', err)
          })
      } else {
        trackEvent('Browser Notifications Consent', { status: 'denied' })
      }
    })
    .catch(_err => {
      trackEvent('Browser Notifications Consent', { status: 'error' })
    })
}

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken, uuid) {
  if (!isTokenSentToServerForThisUuid(uuid)) {
    // console.log('Sending token to server...')
    // Send the current token to your server.
    fetch(`${endpoints.notify}/api/v2/my/fcm_instances`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({
        registration_token: currentToken,
        project_id: config.notify.projectId,
      }),
    })
      // .then(response => console.log('sent to server'))
      .then(_response => setTokenSentToServerForThisUuid(uuid))
  } else {
    // console.log(
    //   "Token already sent to server so won't send it again unless it changes"
    // )
  }
}

function isTokenSentToServerForThisUuid(uuid) {
  return window.localStorage.getItem('sentToServer') === uuid
}

function setTokenSentToServerForThisUuid(uuid) {
  window.localStorage.setItem('sentToServer', uuid)
}

async function registerFirebaseSW(uuid) {
  const browserNotifications =
    typeof Notification !== 'undefined' && Notification

  const supported = await isSupported()

  const pushEnabled =
    browserNotifications &&
    process.env.NODE_ENV !== 'test' &&
    !process.env.CI &&
    supported

  // Initialize Firebase
  const firebaseConfig = {
    apiKey: config.notify.apiKey,
    appId: config.notify.appId,
    authDomain: `${config.notify.projectId}.firebaseapp.com`,
    databaseURL: `https://${config.notify.projectId}.firebaseio.com`,
    projectId: config.notify.projectId,
    messagingSenderId: config.notify.senderId,
  }

  const firebaseApp = initializeApp(firebaseConfig)
  // eslint-disable-next-line no-console
  console.log('firebaseConfig', firebaseConfig)
  // eslint-disable-next-line no-console
  console.log('firebaseApp', firebaseApp)
  // eslint-disable-next-line no-console
  console.log('pushEnabled', pushEnabled)

  if (pushEnabled) {
    const swFile =
      process.env.NODE_ENV === 'production'
        ? '/firebase-messaging-sw-prod.js'
        : '/firebase-messaging-sw.js'

    navigator.serviceWorker
      .register(swFile, { scope: '/firebase-cloud-messaging-push-scope' })
      .then(
        async registration => {
          // Registration was successful
          // eslint-disable-next-line no-console
          console.log(
            'Firebase ServiceWorker registration successful with scope: ',
            registration.scope
          )

          const messaging = pushEnabled ? getMessaging(firebaseApp) : {}
          // eslint-disable-next-line no-console
          console.log('messaging', messaging)

          // useServiceWorker(registration)
          rtRequestPermission({ messaging, registration, uuid })
        },
        err => {
          // registration failed :(
          // eslint-disable-next-line no-console
          console.log('Firebase ServiceWorker registration failed: ', err)
        }
      )
      // eslint-disable-next-line no-console
      .catch(e => console.log('Encountered an error with Firebase:', e))
  }
}

export class WebPushContainer extends Component {
  componentDidMount() {
    if (getCookie(accessTokenKey) && this.props.uuid) {
      registerFirebaseSW(this.props.uuid)
    }
  }

  componentDidUpdate(prevProps) {
    if (getCookie(accessTokenKey) && !prevProps.uuid && this.props.uuid) {
      registerFirebaseSW(this.props.uuid)
    }
  }

  render() {
    return null
  }
}
