import React from 'react'
import { propOr } from 'ramda'
import { connect } from 'react-redux'

import { getFeatureFlags } from 'common/selectors'

export const withDateBasedFeatureFlag = featureFlag => WrappedComponent => {
  const mapStateToProps = state => ({
    featureDate: propOr(null, featureFlag, getFeatureFlags(state)),
  })

  const WithDateBasedFeatureFlag = ({ featureDate, ...rest }) => {
    let featureEnabled = false

    if (featureDate) {
      const featureDateTime = new Date(featureDate).getTime()
      const currentDateTime = new Date().getTime()

      // Feature is enabled if the current date/time is greater than or equal to feature date/time
      featureEnabled = currentDateTime >= featureDateTime
    }

    return <WrappedComponent featureEnabled={featureEnabled} {...rest} />
  }

  return connect(mapStateToProps)(WithDateBasedFeatureFlag)
}

export default withDateBasedFeatureFlag
