import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  DELETE_FROM_LIKES_FAILURE,
  DELETE_FROM_LIKES_REQUEST,
  DELETE_FROM_LIKES_SUCCESS,
  GET_LIKES_EPISODE_DATA_FAILURE,
  GET_LIKES_EPISODE_DATA_REQUEST,
  GET_LIKES_EPISODE_DATA_SUCCESS,
  PUT_ADD_TO_LIKES_FAILURE,
  PUT_ADD_TO_LIKES_REQUEST,
  PUT_ADD_TO_LIKES_SUCCESS,
} from './types'

// Get if specific episode is liked and add it to redux state list of liked episodes
let batchedRequestIds = []
let batchedTimeout = null

const requestLikesShowData = () => ({
  type: GET_LIKES_EPISODE_DATA_REQUEST,
})

const receiveLikesShowData = res => ({
  type: GET_LIKES_EPISODE_DATA_SUCCESS,
  rating: res.rating,
  uuid: res.item_uuid,
})

const fetchLikesShowDataFailure = ex => ({
  type: GET_LIKES_EPISODE_DATA_FAILURE,
  error: ex.message,
})

const getLikes = (dispatch, item_uuids) => {
  rtApi.ratings
    .fetchBulkLikes({ item_uuids })
    .then(json => {
      for (const item of json.data) dispatch(receiveLikesShowData(item))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(fetchLikesShowDataFailure(ex))
    })
}

export const fetchLikesShowData = id => (dispatch, _getState) => {
  dispatch(requestLikesShowData())
  batchedRequestIds.push(id)
  clearTimeout(batchedTimeout)
  if (batchedRequestIds.length === 18) {
    getLikes(dispatch, batchedRequestIds)
    batchedRequestIds = []
  }
  batchedTimeout = setTimeout(() => {
    getLikes(dispatch, batchedRequestIds)
    batchedRequestIds = []
  }, 1000)
}

// Add episode to user's likes

const addToLikesRequest = () => ({
  type: PUT_ADD_TO_LIKES_REQUEST,
})

const addToLikesSuccess = (res, uuid) => ({
  type: PUT_ADD_TO_LIKES_SUCCESS,
  uuid,
})

const addToLikesFailure = ex => ({
  type: PUT_ADD_TO_LIKES_FAILURE,
  error: ex.message,
})

export const addToLikes = (id, type) => (dispatch, _getState) => {
  dispatch(addToLikesRequest())
  rtApi.ratings
    .addLike({ id, type })
    .then(json => dispatch(addToLikesSuccess(json.data, id)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(addToLikesFailure(ex))
    })
}

// Remove from to users likes

const rmFromLikesRequest = () => ({
  type: DELETE_FROM_LIKES_REQUEST,
})

const rmFromLikesSuccess = removedUuid => ({
  type: DELETE_FROM_LIKES_SUCCESS,
  removedUuid,
})

const rmFromLikesFailure = ex => ({
  type: DELETE_FROM_LIKES_FAILURE,
  error: ex.message,
})

export const rmFromLikes = id => (dispatch, _getState) => {
  dispatch(rmFromLikesRequest())
  rtApi.ratings
    .removeLike({ id })
    .then(() => dispatch(rmFromLikesSuccess(id)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(rmFromLikesFailure(ex))
    })
}
