const initialState = {
  followers: {
    list: [],
    pagination: {},
    isFetching: false,
  },
  following: {
    list: [],
    pagination: {},
    isFetching: false,
  },
}

export default (
  state = initialState,
  { type, _resp, pagination, data, req }
) => {
  let myFollowing
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'common/GET_USER_FOLLOWERS_REQ':
      return {
        ...state,
        followers: {
          ...state.followers,
          isFetching: true,
        },
      }
    case 'common/GET_USER_FOLLOWERS_RESP':
      let list

      if (pagination.previous) {
        list = [...state.followers.list, ...data.map(user => user.id)]
      } else {
        list = data.map(user => user.id)
      }

      return {
        ...state,
        followers: {
          ...state.followers,
          list,
          pagination,
          isFetching: false,
        },
      }
    case 'common/GET_USER_FOLLOWING_REQ':
      return {
        ...state,
        following: {
          ...state.following,
          isFetching: true,
        },
      }
    case 'common/GET_USER_FOLLOWING_RESP':
      let listF

      if (pagination.previous) {
        listF = [...state.following.list, ...data.map(user => user.id)]
      } else {
        listF = data.map(user => user.id)
      }

      return {
        ...state,
        following: {
          ...state.following,
          list: listF,
          pagination,
          isFetching: false,
        },
      }
    case 'common/GET_MY_FOLLOWING_RESP':
      return {
        ...state,
        myFollowing: data.map(user => user.id),
      }
    case 'common/POST_USER_FOLLOW_RESP':
      myFollowing = [...(state.myFollowing || []), req.id]
      return {
        ...state,
        myFollowing,
      }
    case 'common/DELETE_USER_FOLLOW_RESP':
      myFollowing = [...(state.myFollowing || [])]
      return {
        ...state,
        myFollowing: myFollowing.filter(id => id !== req.id),
      }
    case 'common/DELETE_USER_FOLLOWER_RESP':
      return {
        ...state,
        followers: {
          ...state.followers,
          list: state.followers.list.filter(follower => follower !== req.id),
        },
      }
    default:
      return state
  }
}
