import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import moment from 'moment'
import PropTypes from 'prop-types'

import { fetchUsernames, postAddComment, updateComment } from 'common/actions'
import SecondaryBtn from 'common/components/Buttons/Secondary'
import TertiaryBtn from 'common/components/Buttons/Tertiary'
import ReCAPTCHALegalBlurb from 'common/components/ReCAPTCHALegalBlurb'
import {
  getDraft,
  isTimeInFuture,
  notice,
  removeDraft,
  saveDraft,
} from 'common/helpers'
import getEnterpriseReCAPTCHAToken from 'common/helpers/get-enterprise-recaptcha-token'

import { showModal } from 'modalApp/actions'

import ChatInput from '../ChatInput'

import './styles.scss'

export default class AddComment extends Component {
  static propTypes = {
    bg: PropTypes.oneOf(['dark', 'light']),
    canComment: PropTypes.bool,
    commentId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    error: PropTypes.object,
    focusIn: PropTypes.bool,
    isCommenting: PropTypes.bool.isRequired,
    // ties reply to specific comment user is replying to
    lockedValue: PropTypes.string,
    // function to invoke when comment cancel button is triggered
    onCancel: PropTypes.func.isRequired,
    // function to invoke when comment submit button is triggered
    onSubmitClick: PropTypes.func,
    // Id of the immediate media parent
    parentId: PropTypes.string,
    // CTA for input
    placeholder: PropTypes.string.isRequired,
    // comment being replied to. Really only used to properly attribute errors
    // to the input field being used
    replyId: PropTypes.string,
    // reflects whether a comment is currently being submitted
    submitCopy: PropTypes.string,
    // top-level media item of a thread.
    topicId: PropTypes.string,
    topicType: PropTypes.string,
    trackSubmission: PropTypes.bool,
    user: PropTypes.object,
    value: PropTypes.string,
  }

  static defaultProps = {
    bg: 'light',
    canComment: true,
    commentId: '',
    error: {},
    focusIn: false,
    lockedValue: '',
    onSubmitClick: () => null,
    parentId: '',
    replyId: '',
    submitCopy: 'Comment',
    topicId: '',
    topicType: 'episode',
    trackSubmission: false,
    user: null,
    value: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      // extra state here vs prop is to help distinguish that *this* add comment field
      // was the one that is making the comment.
      isCommenting: false,
      isReply: !!props.parentId,
      spoiler: false,
      token: null,
      value:
        !props.value || props.value === ' '
          ? getDraft('comment', props.parentId || props.topicId)
          : props.value,
    }
    this.chatInput = React.createRef()
    this.debouncedMentionList = debounce(this.getMentionList, 200)
  }

  // ------
  // Lifecycle
  // ------
  UNSAFE_componentWillReceiveProps(props) {
    if (this.state.isCommenting && !props.isCommenting) {
      this.setState({ isCommenting: false })
    }
  }

  // -------
  // Methods
  // -------

  handleSubmitClick = e => {
    e.preventDefault()

    const { canComment, user } = this.props
    if (!user) {
      this.showLogin()
      return
    }

    if (user && !user?.attributes?.email_verified) {
      this.showVerify()
    }

    // check if user is banned
    if (isTimeInFuture(user?.attributes?.banned_until)) {
      this.showBannedNotice()
      return
    }

    if (!canComment) {
      return
    }

    getEnterpriseReCAPTCHAToken(`Add comment (${this.props.topicType})`).then(
      token => {
        if (!token) return

        this.setState({ token })
        this.props.onSubmitClick()
        this.chatInput.current.handleSubmit(e)

        removeDraft('comment', this.props.parentId || this.props.topicId)
      }
    )
  }

  postComment = message => {
    const { spoiler, token } = this.state
    const {
      parentId,
      replyId,
      topicId,
      topicType,
      trackSubmission,
    } = this.props

    if (this.props.isFetching || message === '' || !token) {
      return
    }

    this.setState({ isCommenting: true })

    if (this.props.commentId) {
      this.props.dispatch(
        updateComment({ uuid: this.props.commentId, message })
      )
    } else {
      this.props.dispatch(
        postAddComment({
          message,
          parentId,
          replyId,
          spoiler,
          token,
          topicId,
          topicType,
          trackSubmission,
        })
      )
    }
  }

  showLogin = () => {
    this.props.dispatch(showModal('USER_LOGIN', { user: '' }))
  }

  showVerify = () => {
    this.props.dispatch(showModal('VERIFY_EMAIL'))
  }

  onCancel = () => {
    const { onCancel } = this.props

    this.setState({ value: '' })
    this.chatInput.current.reset()
    // it's a little verbose, but we need to access the actual
    // DOM node of the chatInput here... GROSS
    this.chatInput.current.chatInput.current.blur()

    if (onCancel) {
      onCancel()
    }
  }

  onBlur = () => {
    clearTimeout(this.scrolling)
  }

  onChange = event => {
    const { value } = event.target
    this.setState({ value })
    saveDraft('comment', this.props.parentId || this.props.topicId, value)
  }

  getMentionList(name) {
    if (name === '') {
      return
    }
    this.props.dispatch(fetchUsernames(name))
  }

  showBannedNotice = () => {
    // turning the banned_until user readable
    const bannedUntil = moment(
      this.props.user?.attributes?.banned_until
    ).format('MM-DD-YYYY')

    notice({
      icon: 'icon-close2',
      iconColor: 'red',
      header: 'Error',
      body: `you are banned until: ${bannedUntil}`,
    })
  }

  spoilerUpdate = e => {
    this.setState({ spoiler: e.target.checked })
  }

  // -------
  // Render
  // -------
  render() {
    const {
      bg,
      canComment,
      error,
      focusIn,
      lockedValue,
      parentId,
      placeholder,
      replyId,
      submitCopy,
      user,
      userList,
    } = this.props

    const { isCommenting, isReply, spoiler, value } = this.state

    let errorMessage = ''
    if (error && (error.parentId === parentId && error.replyId === replyId)) {
      errorMessage = error.message
    }

    return (
      <div className='add-comment'>
        <div className='form-group'>
          <div id='add-comment__input' className='input-field'>
            <ChatInput
              enableMentions
              focusIn={focusIn}
              focusOnSend={false}
              getMentionList={name => this.debouncedMentionList(name)}
              inProgress={isCommenting}
              isReply={isReply}
              lockedValue={lockedValue}
              mentionList={userList}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onEnter={this.runRecaptcha}
              placeholder={placeholder}
              ref={this.chatInput}
              sendMsg={this.postComment}
              showSpoilers
              showLogin={this.showLogin}
              showVerify={this.showVerify}
              submitOnEnter={false}
              user={user}
              value={value}
            />
            <div className='error'>{errorMessage}</div>
          </div>
          <div className='add-comment__recaptcha-blurb'>
            <ReCAPTCHALegalBlurb />
          </div>
          <div className='add-comment-controls right-align'>
            <div className='checkbox-field-light add-post-spoiler'>
              <label>
                <input
                  type='checkbox'
                  id='spoiler'
                  onChange={this.spoilerUpdate}
                  checked={spoiler}
                />
                <span>Spoiler</span>
              </label>
            </div>
            <TertiaryBtn bg={bg} onClick={this.onCancel}>
              Cancel
            </TertiaryBtn>
            <SecondaryBtn
              bg={bg}
              disabled={isCommenting || (user && !canComment)}
              onClick={this.handleSubmitClick}
            >
              {submitCopy}
            </SecondaryBtn>
          </div>
        </div>
      </div>
    )
  }
}
