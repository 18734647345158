import { takeEvery } from 'redux-saga/effects'
import { simpleGET } from 'common/helpers/saga'
import { endpoints } from 'common/config'

/**
 * This is the same endpoint as `getUserFollowing`, but we need to store
 * this in a different reducer for the target user so it doesn't get
 * overwritten by requests for other users
 * TODO: make this call more efficient in code-use
 * @param {Object} action
 * @param {Object} action.id auth'd user's id
 **/
export function* getCommunityUser({ id }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/users/${id}`,
    type: 'communityApp/get_community_user',
    method: 'GET',
    authenticated: true,
  })
}

export function* communityUser() {
  yield takeEvery('communityApp/get_community_user', getCommunityUser)
}
