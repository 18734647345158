import rtApi from '@roosterteethproductions/svod-api'

import { getEpisodePercentages } from 'common/helpers'

import {
  GET_MERCH_FAILURE,
  GET_MERCH_REQUEST,
  GET_MERCH_SUCCESS,
  GET_NEXT_EPS_PERCENT_FAILURE,
  GET_NEXT_EPS_PERCENT_REQUEST,
  GET_NEXT_EPS_PERCENT_SUCCESS,
  GET_NEXT_FAILURE,
  GET_NEXT_REQUEST,
  GET_NEXT_SUCCESS,
} from './types'

// --------
// GET related episode actions
// --------
export const requestNextEpisodes = (nextHref, slug) => ({
  type: GET_NEXT_REQUEST,
  nextHref,
  slug,
})

const receiveNextEpisodes = (nextHref, slug, items) => ({
  type: GET_NEXT_SUCCESS,
  nextHref,
  slug,
  items: items.data,
  receivedAt: Date.now(),
})

const fetchNextEpisodesFailure = ex => ({
  type: GET_NEXT_FAILURE,
  error: ex.message,
})

export const fetchNextEpisodes = (nextHref, slug, type) => (
  dispatch,
  getState
) => {
  dispatch(requestNextEpisodes(nextHref, slug))

  const { isAuthenticated } = getState().authReducer
  const getNext = id =>
    type === 'episode'
      ? rtApi.episodes.fetchNext({ authenticated: isAuthenticated, id })
      : rtApi.bonus.fetchNext({ authenticated: isAuthenticated, id })

  getNext(slug)
    .then(json => dispatch(receiveNextEpisodes(nextHref, slug, json)))
    .then(json => dispatch(fetchNextEpisodesPercentage(json)))
    .catch(ex => dispatch(fetchNextEpisodesFailure(ex)))
}

// Get NextEpisodes Watched percentage
const requestNextEpisodesPercent = () => ({
  type: GET_NEXT_EPS_PERCENT_REQUEST,
})

const receiveNextEpisodesPercent = items => ({
  type: GET_NEXT_EPS_PERCENT_SUCCESS,
  items,
})

const fetchNextEpisodesPercentFailure = ex => ({
  type: GET_NEXT_EPS_PERCENT_FAILURE,
  error: ex.message,
})

export const fetchNextEpisodesPercentage = nextData =>
  getEpisodePercentages({
    failureAction: fetchNextEpisodesPercentFailure,
    items: nextData.items,
    requestAction: requestNextEpisodesPercent,
    successAction: receiveNextEpisodesPercent,
  })

// --------
// GET merch actions
// --------
const requestMerch = showUuid => ({
  type: GET_MERCH_REQUEST,
  showUuid,
})

const receiveMerch = (showUuid, json) => ({
  type: GET_MERCH_SUCCESS,
  showUuid,
  collection: json.filter(item => Object.keys(item).length !== 0),
  receivedAt: Date.now(),
})

const fetchMerchFailure = (showUuid, ex) => ({
  type: GET_MERCH_FAILURE,
  showUuid,
  error: ex.message,
})

export const fetchMerch = showUuid => dispatch => {
  dispatch(requestMerch(showUuid))
  rtApi.shows
    .fetchMerch({ id: showUuid })
    .then(json => {
      if (json.data.length !== 0) {
        dispatch(receiveMerch(showUuid, json.data[0].included.collections))
      } else {
        // No collection found for this show, set it as default
        dispatch(fetchFeaturedMerch(showUuid))
      }
    })
    .catch(ex => dispatch(fetchMerchFailure(showUuid, ex)))
}

const fetchFeaturedMerch = showUuid => dispatch => {
  dispatch(requestMerch(showUuid))
  rtApi.featured
    .fetchProducts()
    .then(json => {
      if (json.data.length !== 0) {
        dispatch(receiveMerch(showUuid, json.data[0].included.collections))
      } else {
        dispatch(
          fetchMerchFailure(showUuid, { message: 'No collection found' })
        )
      }
    })
    .catch(ex => dispatch(fetchMerchFailure(showUuid, ex)))
}
