import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const PaginatorButton = ({
  active = false,
  children,
  className,
  disabled = false,
  href = '#',
  onSelect,
}) => {
  const classes = {
    'waves-effect': true,
    disabled,
    active,
  }

  return (
    <li
      className={classnames(classes, className)}
      role='presentation'
      onClick={onSelect}
    >
      <a href={href}>{children}</a>
    </li>
  )
}

PaginatorButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onSelect: PropTypes.func,
}

export default PaginatorButton
