/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { trackClick } from 'common/metrics'

const ExtremelyHighQualityCSS = () => (
  <Helmet>
    <style>{`
		* {
			font-family: "Comic Sans", "Comic Sans MS", "comic sans" !important;
			border-radius: 0px !important;
		}
		html {
			filter: saturate(3) !important;
		}
	`}</style>
  </Helmet>
)

const useShiftKeyHeld = () => {
  const [shiftKeyHeld, setShiftKeyHeld] = useState(false)
  useEffect(() => {
    function turnOn(e) {
      if (e.key === 'Shift') {
        setShiftKeyHeld(true)
      }
    }
    function turnOff(e) {
      if (e.key === 'Shift') {
        setShiftKeyHeld(false)
      }
    }
    document.addEventListener('keydown', turnOn)
    document.addEventListener('keyup', turnOff)

    return () => {
      document.removeEventListener('keydown', turnOn)
      document.removeEventListener('keyup', turnOff)
    }
  }, [])
  return shiftKeyHeld
}

const GraphicDesignEasterEgg = () => {
  const shiftKeyHeld = useShiftKeyHeld()
  const [enhanceGraphicDesign, setEnhanceGraphicDesign] = useState(false)

  return (
    <>
      {enhanceGraphicDesign ? <ExtremelyHighQualityCSS /> : null}
      <a
        style={{ display: shiftKeyHeld ? 'inline' : 'none', cursor: 'pointer' }}
        onClick={e => {
          e.preventDefault()
          trackClick('GRAPHIC_DESIGN_IS_MY_PASSION')
          setEnhanceGraphicDesign(!enhanceGraphicDesign)
        }}
      >
        {enhanceGraphicDesign
          ? 'MAKE IT STOP!'
          : 'Graphic Design is My Passion'}
      </a>
    </>
  )
}

export default GraphicDesignEasterEgg
