import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import RTBlurredBackground from 'common/components/RTBlurredBackground'
import { getImageSrc } from 'common/helpers'
import getBlurredBGImageFromCloudinaryPublicId from 'common/helpers/getBlurredBGImageFromCloudinaryPublicId'
import showPosterImage from 'common/helpers/showPosterImage'

import { getEpisodeBySlug } from 'episodeApp/selectors'
import { getHeroImg } from 'homeApp/selectors/featuredItemV3'
import { getSelectedChannel } from 'rootApp/selectors'

const BlurredBackgroundImage = ({ imgUrl, shouldHaveBackground }) => {
  const [activeUrl, setActiveUrl] = useState()
  useEffect(
    () => {
      const cloudinaryPublicId = imgUrl?.split('f_auto')[1]
      if (!cloudinaryPublicId) return
      const blurredPosterUrl = getBlurredBGImageFromCloudinaryPublicId(
        cloudinaryPublicId
      )
      setActiveUrl(blurredPosterUrl)
    },
    [imgUrl]
  )

  if (!activeUrl || !shouldHaveBackground) return null

  return <RTBlurredBackground background={activeUrl} />
}

const BlurredBackgroundImageContainer = connect(state => {
  const channelSlug = getSelectedChannel(state)
  const seriesSlug = state.urlReducer.params?.seriesSlug
  const episodeSlug = state.urlReducer.params?.episodeSlug
  const { shows } = state.showsReducer.shows

  const pathname = state.router.location.pathname

  const isHomePage = ['/', '/login', '/forgot', '/password'].includes(pathname)
  const isShowsPage = pathname === '/series'

  let imgUrl
  // We have to set this flag explicitly because the Blurred Background component will wait indefinitely to receive a new background, causing backgrounds to render on pages that shouldn't have them, such as Schedule or Settings
  let shouldHaveBackground = false

  if (isHomePage) {
    shouldHaveBackground = true
    imgUrl = getHeroImg(state, 'all')
  } else if (isShowsPage) {
    shouldHaveBackground = true
    const { shows } = state.showsReducer.browseShows
    const show = shows && shows[0]
    if (show) imgUrl = showPosterImage(show)
  } else if (channelSlug) {
    shouldHaveBackground = true
    imgUrl = getHeroImg(state, channelSlug)
  } else if (seriesSlug) {
    shouldHaveBackground = true
    const selectedShow = shows.find(s => s.attributes.slug === seriesSlug)
    const imagesArr = selectedShow?.included?.images

    imgUrl = getImageSrc(imagesArr, 'large', 'hero')
    if (imgUrl.indexOf('generic_rt_cover.jpg') !== -1) {
      // if it passeed back a generic cover, try to get cover image as fallback
      imgUrl = getImageSrc(imagesArr, 'large', 'cover')
    }
  } else if (episodeSlug) {
    shouldHaveBackground = true
    const episode = getEpisodeBySlug(state, episodeSlug)
    const images = episode?.included?.images
    if (images) {
      imgUrl = getImageSrc(images, 'small', 'profile')
    }
  }
  return { imgUrl, shouldHaveBackground }
})(BlurredBackgroundImage)

export default BlurredBackgroundImageContainer
