import { getPlatform } from './platform'

const SCREEN_M = 768

// Determines "mobile" based on window width and CSS breakpoints
export function isMobileWidth(width = window.innerWidth) {
  return width <= SCREEN_M
}

// Determines "mobile" based on UA sniffing. Yeah I know, UA sniffing is bad, blah blah blah. This is only used for reporting in aggregates and should be generally correct.
const uaString = navigator.userAgent
export const isMobileUA =
  uaString.includes('iPhone') ||
  (uaString.includes('Android') && uaString.includes('Mobile')) ||
  (uaString.includes('Android') && uaString.includes('Linux')) ||
  uaString.includes('Windows Phone') ||
  uaString.includes('J2ME/MIDP') ||
  uaString.includes('iPod')

// Determines whether we're browsing the site in a "WebView", which means that we're on a Webkit browser that is contained within an app. For example, when a user visits "Settings" in the iPhone app, they see a WebView rather than Safari.
export const displaySmartAppBanner = () => {
  const platform = getPlatform()
  const isSettingsPage = window.location.pathname.startsWith('/settings')

  const hasMobileCapabilities =
    window?.webkit && window?.webkit?.messageHandlers
  const isWebViewUserAgent =
    window.navigator.userAgent.includes('iPhone') &&
    !window.navigator.userAgent.includes('Safari')
  const isWebView = hasMobileCapabilities || isWebViewUserAgent

  // iPadOS is detected as the MacIntel platform, so if the platform is
  // not iPhone/Android, we should just insert the metatag. Otherwise,
  // we can allow the custom app banner to show
  const isMobileOS = platform === 'ios' || platform === 'android'

  const shouldNotShowOnPlatform = !isMobileOS

  const shouldNotRender = shouldNotShowOnPlatform || isSettingsPage || isWebView

  return !shouldNotRender
}
