import React, { useEffect, useRef, useState } from 'react'
import rtApi from '@roosterteethproductions/svod-api'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import coin from 'common/assets/8bit-play-coin.png'
import coin2x from 'common/assets/8bit-play-coin@2x.png'
import { ReactComponent as Box } from 'common/assets/random-box.svg'
import { ReactComponent as BlueBox } from 'common/assets/random-box-blue.svg'
import { config } from 'common/config'
import { encodeUuid, isUserFirst } from 'common/helpers'

import { getIsAuthenticated, getLoggedInUser } from 'auth/selectors'

import './styles.scss'

// This component handles the random video logic, "coin" logic, and renders the markup (including the block SVG). Also allows children which can receive clicks for the random video effect.
const RandomVideoBox = ({
  boxType,
  children,
  isAuthenticated,
  isFirst,
  onVideoSelected,
  push,
}) => {
  const [clicked, setClicked] = useState(false)
  const [showCoin, setShowCoin] = useState(false)
  const [randomVideo, setRandomVideo] = useState(null)

  const buttonRef = useRef()

  useEffect(
    () => {
      if (clicked) {
        rtApi.featured
          .fetchRandomVideo({ authenticated: isAuthenticated })
          .then(json => {
            if (!json.data[0]) {
              throw new Error('no episode found')
            }
            setRandomVideo(json.data[0])
          })
          .catch(_err => {
            // console.error(err)
          })
        setTimeout(() => {
          setClicked(false)
        }, 200)
        setTimeout(() => {
          setShowCoin(false)
          buttonRef.current.blur()
        }, 1500)
      }
    },
    [buttonRef, clicked, isAuthenticated]
  )

  useEffect(
    () => {
      let timeout = null
      if (randomVideo) {
        onVideoSelected(randomVideo)
        timeout = setTimeout(() => {
          const playlistId = isFirst
            ? config.playlistIds.onboardingPremium
            : config.playlistIds.onboardingFree
          const playlistIdParamString = playlistId
            ? `?list=${encodeUuid(playlistId)}`
            : ''
          push(
            `/watch/${
              randomVideo.attributes.content_slug
            }${playlistIdParamString}`
          )
        }, 800)
      }
      return () => {
        clearTimeout(timeout)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [push, randomVideo]
  )

  const handleRandomVideo = () => {
    // don't do anything if we are still in the animation loop
    if (showCoin) {
      return
    }
    setClicked(true)
    setShowCoin(true)
  }

  const BoxComponent = boxType === 'gradient-blue' ? BlueBox : Box

  return (
    <div
      aria-label='Play random video'
      className='random-video__outer'
      onClick={handleRandomVideo}
      onKeyPress={handleRandomVideo}
      ref={buttonRef}
      role='button'
      tabIndex='0'
    >
      <div className={classnames('random-video', { click: clicked })}>
        <BoxComponent className='box' />
        <span className={classnames('coin', { play: showCoin })}>
          <img alt='Coin' src={coin} srcSet={`${coin}, ${coin2x} 2x`} />
        </span>
      </div>
      {children}
    </div>
  )
}

function mapStateToProps(state) {
  const isAuthenticated = getIsAuthenticated(state)
  const isFirst = isUserFirst(getLoggedInUser(state))
  return { isAuthenticated, isFirst }
}

export default connect(
  mapStateToProps,
  { push }
)(RandomVideoBox)
