import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  getDisplayedItemsWithIndices,
  getNearestPageFromOffset,
  getPageIndices,
  nextPage,
  prevPage,
} from './page-helpers-core'

/**
 * Returns:
 *   * Click handlers which correspond to the "prev" and "next" buttons for safely navigating between pages (setting the offset to a new safe value). These handlers call the onNextClick and onPrevClick buttons, if provided.
 *   * Booleans which tell the carousel if it is allowed to go back or forward.
 */
const useForwardAndBack = ({
  currentOffset,
  items,
  onNextClick,
  onPrevClick,
  pageIndices,
  perPage,
  setCurrentOffset,
}) => {
  const canGoBack = currentOffset !== 0
  const canGoForward =
    currentOffset !== pageIndices[pageIndices.length - 1] &&
    items.length > perPage
  const handleNextPageClick = useCallback(
    () => {
      onNextClick?.()
      setCurrentOffset(nextPage(pageIndices, currentOffset))
    },
    [onNextClick, pageIndices, currentOffset, setCurrentOffset]
  )
  const handlePrevPageClick = useCallback(
    () => {
      onPrevClick?.()
      setCurrentOffset(prevPage(pageIndices, currentOffset))
    },
    [onPrevClick, pageIndices, currentOffset, setCurrentOffset]
  )
  return { canGoBack, canGoForward, handleNextPageClick, handlePrevPageClick }
}

/**
 * When the offset prop has been changed, we need to access the previous offset prop to make sure we continue rendering the carousel items from the previous page. For example, assume page size is 3 and we are navigating from offset 0 (page 0,1,2) to offset 5 (page 5,6,7). During this animation, the items (0,1,2,3,4) would disappear from view while the CSS animation is happening, so we keep track of the previous offset for the duration of the animation.
 */
const useAnimationStartOffset = ({ currentOffset, transitionDuration }) => {
  const [animationStartOffset, setAnimationStartOffset] = useState(
    currentOffset
  )

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        setAnimationStartOffset(currentOffset)
      }, transitionDuration)
      return () => clearTimeout(timeout)
    },
    [currentOffset, transitionDuration]
  )

  return animationStartOffset
}

/**
 * Returns:
 *   * A `currentOffset` value which can be changed with the page click handlers.
 *   * Booleans and click handlers for safe page navigation (see `useForwardAndBack`): `canGoBack`, `canGoForward`, `handleNextPageClick`, `handlePrevPageClick`.
 *   * A data structure called `displayedItemsWithIndices` which includes only the items which are to be rendered, grouped with their original indices.
 */
export const useCarouselPaging = ({
  initialIndex,
  items,
  onNextClick,
  onPrevClick,
  perPage,
  teasedItemCount,
  transitionDuration,
}) => {
  const [currentOffset, setCurrentOffset] = useState(initialIndex)
  const pageIndices = useMemo(() => getPageIndices(items.length, perPage), [
    items,
    perPage,
  ])
  const {
    canGoBack,
    canGoForward,
    handleNextPageClick,
    handlePrevPageClick,
  } = useForwardAndBack({
    currentOffset,
    items,
    onNextClick,
    onPrevClick,
    pageIndices,
    perPage,
    setCurrentOffset,
  })

  const animationStartOffset = useAnimationStartOffset({
    currentOffset,
    transitionDuration,
  })

  const displayedItemsWithIndices = useMemo(
    () =>
      getDisplayedItemsWithIndices({
        animationStartOffset,
        currentOffset,
        teasedItemCount,
        items,
        perPage,
      }),
    [animationStartOffset, currentOffset, teasedItemCount, items, perPage]
  )

  return {
    canGoBack,
    canGoForward,
    currentOffset,
    displayedItemsWithIndices,
    handleNextPageClick,
    handlePrevPageClick,
    pageIndices,
    setCurrentOffset,
  }
}

// This function prevents the carousel from breaking on window resizes When the window size changes, our `perPage`, or the number of tiles visible, changes. For example, if the `perPage` changed from 4 tiles to 3 tiles, and we were on the third page, it means that our previous offset (8) is no longer valid, because it is not divisible by 3.
export const useFixInvalidOffsets = ({
  currentOffset,
  pageIndices,
  setCurrentOffset,
}) =>
  useEffect(
    () => {
      const newOffset = getNearestPageFromOffset(currentOffset, pageIndices)
      if (currentOffset === newOffset) return
      setCurrentOffset(newOffset)
    },
    [currentOffset, pageIndices, setCurrentOffset]
  )
