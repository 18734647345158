/* eslint-disable camelcase */
import { put, takeEvery, select } from 'redux-saga/effects'
import { simpleGET } from 'common/helpers/saga'
import { endpoints } from 'common/config'

export function* getLog({ slug }) {
  yield simpleGET({
    url: `${endpoints.communityBase}/v1/communities/slug/${encodeURIComponent(
      slug
    )}/action_logs`,
    type: 'communityApp/manage/GET_LOG',
  })
}

export function* getMoreLog({ slug }) {
  const state = yield select()
  const nextPage = state.communityReducer.subCommunity.log.pagination.next
  const type = 'communityApp/manage/GET_MORE_LOG'
  if (!nextPage) {
    return yield put({ type: type + '_ERR', error: 'no more pages' })
  }

  yield put({ type: type + '_REQ' })

  yield simpleGET({
    url: `${endpoints.communityBase}/v1/communities/slug/${encodeURIComponent(
      slug
    )}/action_logs?cursor=${nextPage}`,
    type,
  })
}

export function* log() {
  yield takeEvery('communityApp/manage/GET_LOG', getLog)
  yield takeEvery('communityApp/manage/GET_MORE_LOG', getMoreLog)
}
