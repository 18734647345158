import React from 'react'

import { TAB_GROUPS } from '../../helpers/constants'
import EmptySearch from '../shared/EmptySearch'
import WrappedCommunityGroup from '../shared/WrappedCommunityGroup'

import './styles.scss'

const TabGroups = ({ closeSearchV2, groups, searchTerm }) =>
  groups.data.length === 0 ? (
    <EmptySearch />
  ) : (
    <div className='search-v2-groups'>
      {groups.data.map(group => (
        <WrappedCommunityGroup
          key={group?.data?.id || group?.id}
          closeSearchV2={closeSearchV2}
          group={group}
          searchTerm={searchTerm}
          tab={TAB_GROUPS}
        />
      ))}
    </div>
  )

export default TabGroups
