import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { hasRole, ROLE_GLOBAL_MODERATOR } from 'common/helpers'
import withRouter from 'common/helpers/withRouter'

import CommunityDropdown from 'rootApp/components/CommunityDropdown'

import './styles.scss'

function CommunityNav({ communities, user }) {
  return (
    <div className='community-nav'>
      <NavLink className='community-nav__link' exact to='/g'>
        Feed
      </NavLink>

      {communities && communities.length ? (
        <CommunityDropdown communities={communities} />
      ) : (
        <NavLink className='community-nav__link' to='/g/explore'>
          Groups
        </NavLink>
      )}

      {user && hasRole(ROLE_GLOBAL_MODERATOR, user) && (
        <NavLink className='community-nav__link' to='/g/admin'>
          Admin
        </NavLink>
      )}
    </div>
  )
}

CommunityNav.propTypes = {
  communities: PropTypes.array,
}

const mapStateToProps = state => {
  const { featureFlags } = state.commonReducer
  const { memberships } = state.communityReducer
  const { user } = state.authReducer

  return {
    communities: memberships.list,
    featureFlags,
    user,
  }
}

export default withRouter(connect(mapStateToProps)(CommunityNav))
