import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  addToPlaylistByCategory,
  rmFromPlaylistByCategory,
} from 'common/actions'

import { showModal } from 'modalApp/actions'

import './styles.scss'

class QueueToggle extends Component {
  // Needs to come before use in state
  // eslint-disable-next-line react/sort-comp
  checkIfQueued = () => {
    const { itemUuids, uuid, type, watchLaterUuids } = this.props
    if (type === 'playlist') return false
    return (
      itemUuids.indexOf(uuid) !== -1 || watchLaterUuids.indexOf(uuid) !== -1
    )
  }

  state = {
    isQueued: this.checkIfQueued(),
    isSubmitting: false,
  }

  componentDidUpdate(prevProps, _prevState) {
    const { itemUuids, watchLaterUuids } = this.props
    // console.log('cdu', this.props)
    if (
      itemUuids !== prevProps.itemUuids ||
      watchLaterUuids !== prevProps.watchLaterUuids
    ) {
      this.setState({ isQueued: this.checkIfQueued() })
    }
  }

  addItem = () => {
    const {
      addToPlaylistByCategory,
      isAuthenticated,
      showModal,
      type,
      uuid,
    } = this.props

    if (!isAuthenticated) {
      showModal('USER_LOGIN')
      return
    }

    if (type === 'playlist') return

    this.setState({ isSubmitting: true })
    if (type === 'show') {
      addToPlaylistByCategory('followed_series', type, uuid).then(() =>
        this.setState({ isSubmitting: false })
      )
    } else if (type === 'episode' || type === 'bonus_feature') {
      addToPlaylistByCategory('watch_later', type, uuid).then(() =>
        this.setState({ isSubmitting: false })
      )
    }
  }

  getTooltipText = () => {
    const { type } = this.props
    const { isQueued } = this.state

    const isShow = type === 'show'
    const isEpisode = type === 'episode' || type === 'bonus_feature'

    if (isShow) {
      return isQueued ? 'Unfollow series' : 'Follow series'
    }

    if (isEpisode) {
      return isQueued ? 'Remove from Watch Later' : 'Add to Watch Later'
    }

    return 'Add to Playlist'
  }

  handleAddClick = e => {
    e.preventDefault()
    this.addItem()
    this.props.onAddClick()
  }

  handleRemoveClick = e => {
    e.preventDefault()
    this.removeItem()
    this.props.onRemoveClick()
  }

  removeItem = () => {
    const {
      isAuthenticated,
      rmFromPlaylistByCategory,
      showModal,
      type,
      uuid,
    } = this.props

    if (!isAuthenticated) {
      showModal('USER_LOGIN')
      return
    }

    if (type === 'playlist') return

    this.setState({ isSubmitting: true })

    // FIXME: We try to perform setState on an unmounted component when clicking "Remove from Watch Later" on an EpisodeCard in the watch later carousel, because the EpisodeCard has already been removed. This doesn't happen for other carousels.
    if (type === 'show') {
      rmFromPlaylistByCategory('followed_series', uuid).then(() =>
        this.setState({ isSubmitting: false })
      )
    } else if (type === 'episode' || type === 'bonus_feature') {
      rmFromPlaylistByCategory('watch_later', uuid).then(() =>
        this.setState({ isSubmitting: false })
      )
    }
  }

  render() {
    const { children, type } = this.props
    const { isQueued, isSubmitting } = this.state

    const renderProps = {
      isQueued,
      isSubmitting,
      toggleQueue: isQueued ? this.handleRemoveClick : this.handleAddClick,
      tooltipText: this.getTooltipText(),
      type,
    }

    return children(renderProps)
  }
}

QueueToggle.propTypes = {
  addToPlaylistByCategory: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  itemUuids: PropTypes.array.isRequired,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  rmFromPlaylistByCategory: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  watchLaterUuids: PropTypes.array.isRequired,
}

QueueToggle.defaultProps = {
  onAddClick: () => null,
  onRemoveClick: () => null,
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.authReducer
  const { followedShows, watchLaterUuids } = state.commonReducer.myStuff
  const itemUuids = followedShows.map(show => show.attributes.content_uuid)

  return {
    isAuthenticated,
    itemUuids,
    watchLaterUuids,
  }
}

const mapDispatchToProps = {
  addToPlaylistByCategory,
  rmFromPlaylistByCategory,
  showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueueToggle)
