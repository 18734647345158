import { getAccessToken } from 'common/helpers'

import { SUBMIT_USER_UPDATE_FORM_SUCCESSFUL } from 'communityApp/actions/types'

import {
  PUT_UPDATE_PROFILE,
  PUT_UPDATE_PROFILE_IMAGE_RESP,
} from '../../profileApp/actions/types'
import {
  GET_3_PARTY_LOGIN_FAILURE,
  GET_3_PARTY_LOGIN_REQUEST,
  GET_3_PARTY_LOGIN_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_VERIFY_PASSWORD_TOKEN_FAILURE,
  GET_VERIFY_PASSWORD_TOKEN_REQUEST,
  GET_VERIFY_PASSWORD_TOKEN_SUCCESS,
  POST_3_PARTY_USER_FAILURE,
  POST_3_PARTY_USER_REQUEST,
  POST_FORGOT_PASSWORD_FAILURE,
  POST_FORGOT_PASSWORD_REQUEST,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGOUT_FAILURE,
  POST_LOGOUT_REQUEST,
  POST_LOGOUT_SUCCESS,
  POST_PIN_AUTH_FAILURE,
  POST_PIN_AUTH_REQUEST,
  POST_PIN_AUTH_SUCCESS,
  POST_REFRESH_TOKEN_FAILURE,
  POST_REFRESH_TOKEN_REQUEST,
  POST_REFRESH_TOKEN_SUCCESS,
  POST_USER_FAILURE,
  POST_USER_REQUEST,
  PUT_PASSWORD_FAILURE,
  PUT_PASSWORD_REQUEST,
  PUT_PASSWORD_SUCCESS,
  PUT_RESET_PASSWORD_FAILURE,
  PUT_RESET_PASSWORD_REQUEST,
  PUT_RESET_PASSWORD_SUCCESS,
  PUT_USER_FAILURE,
  PUT_USER_REQUEST,
  PUT_USER_SETTINGS_FAILURE,
  PUT_USER_SETTINGS_REQUEST,
  PUT_USER_SETTINGS_SUCCESS,
  PUT_USER_SUCCESS,
} from '../actions/types'

const initialState = {
  isFetching: false,
  isAuthenticated: !!getAccessToken(),
  client_credentials: null,
  user: null,
  userData: {
    download: null,
    recentRequest: null,
    requests: null,
  },
  errorMessage: null,
}

function auth(state = initialState, action) {
  switch (action.type) {
    case POST_LOGIN_REQUEST:
    case GET_3_PARTY_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
      }
    case GET_3_PARTY_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        user: action.user,
      }
    case POST_LOGIN_FAILURE:
    case GET_3_PARTY_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case POST_USER_REQUEST:
    case POST_3_PARTY_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      }
    case POST_USER_FAILURE:
    case POST_3_PARTY_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      }
    case GET_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
        user: action.user,
      }
    case GET_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
        user: null,
      }
    case GET_VERIFY_PASSWORD_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_VERIFY_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
      }
    case GET_VERIFY_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case POST_LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case POST_LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        userData: {
          download: null,
          recentRequest: null,
          requests: null,
        },
        user: null,
      }
    case POST_LOGOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      }
    case PUT_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case PUT_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case POST_REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        errorMessage: '',
      }
    case POST_REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      }
    case POST_REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        errorMessage: 'Authentication Failed',
        isAuthenticated: false,
        user: null,
      }
    case POST_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case POST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
      }
    case POST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case POST_PIN_AUTH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case POST_PIN_AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case POST_PIN_AUTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error.message,
      }
    case PUT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: '',
      }
    case PUT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case PUT_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_USER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isFetching: false,
        user: action.user,
      }
    case PUT_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case PUT_USER_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        user: action.user,
      }
    case PUT_USER_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case 'modalApp/SHOW_MODAL':
      return {
        ...state,
        errorMessage: '',
      }
    case 'modalApp/HIDE_ERROR':
      return {
        ...state,
        errorMessage: '',
      }
    case 'modalApp/SHOW_ERROR':
      if (action.reducer === 'authReducer') {
        return {
          ...state,
          errorMessage: action.message,
        }
      }

      return state
    // LEGACY: These actions came from ProfileApp
    case PUT_UPDATE_PROFILE:
    case PUT_UPDATE_PROFILE_IMAGE_RESP:
      // if we're updating our user profile, we need to update our auth data
      return {
        ...state,
        user: action.data,
      }
    // NEW VERSION: These actions come from the new Community code, which replaces ProfileApp.
    case SUBMIT_USER_UPDATE_FORM_SUCCESSFUL:
      return {
        ...state,
        user: action.userFromBusinessService,
      }
    default:
      return state
  }
}

export default auth
