import { getChannelGenres } from 'common/helpers/channelGenreHelpers'

import {
  CLOSE_MOBILE_NAV,
  CLOSE_SEARCH_V2,
  GET_BADGES_SUCCESS,
  GET_CHANNELS_SUCCESS,
  OPEN_MOBILE_NAV,
  OPEN_SEARCH_V2,
  SELECT_CHANNEL,
  SET_VIDEO_PLAYER_STATE,
} from '../actions/types'

// we have a single page app per channel so no need to store more than one featured in our state
const root = (
  state = {
    selectedChannel: '',
    channels: [],
    channelGenres: new Set(),
    isSearchOpen: false,
    userBadges: [],
    videoPlayerState: 'closed',
    searchV2Open: false,
    mobileNavOpen: false,
  },
  action
) => {
  switch (action.type) {
    // TODO: When we've upgraded to the new homepage, which always uses selectedChannel from the URL, remove this action (selectChannel) and property (selectedChannel) from the state.
    case SELECT_CHANNEL:
      return {
        ...state,
        selectedChannel: action.channel,
      }
    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.channels,
        channelGenres: getChannelGenres(action.channels),
      }
    case GET_BADGES_SUCCESS:
      return {
        ...state,
        userBadges: action.badges,
      }
    case SET_VIDEO_PLAYER_STATE:
      return {
        ...state,
        videoPlayerState: action.playerState,
      }
    case OPEN_SEARCH_V2:
      return {
        ...state,
        searchV2Open: true,
      }
    case CLOSE_SEARCH_V2:
      return {
        ...state,
        searchV2Open: false,
      }
    case OPEN_MOBILE_NAV:
      return {
        ...state,
        mobileNavOpen: true,
      }
    case CLOSE_MOBILE_NAV:
      return {
        ...state,
        mobileNavOpen: false,
      }
    default:
      return state
  }
}

export default root
