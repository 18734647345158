import React, { useEffect } from 'react'
import { Transition } from 'react-transition-group'

import { useToggle } from 'common/hooks'

import ExternalLink from '../ExternalLink'
import BG from './images/bg.jpg'
import Sammy from './images/headshots/big-ol-nerd.png'
import Nick from './images/headshots/frontend-expert.png'
import Buddy from './images/headshots/inventor-of-ios-probably.png'
import Johnny from './images/headshots/my-bestest-friend.png'
import Steven from './images/headshots/my-one-and-only-steven.png'
import Ben from './images/headshots/my-real-dad.png'
import Dave from './images/headshots/not-my-real-dad.png'
import Christina from './images/headshots/our-teams-secret-weapon.png'
import Nankai from './images/headshots/smartest-guy-i-know.png'
import Praveen from './images/headshots/THE-MACHINE.png'
import Mat from './images/headshots/the-yin-to-my-yang.png'
import Daniel from './images/headshots/who-even-is-this-guy.png'
import Ryan from './images/headshots/world-class-hacker.png'
import Logo from './images/old-school-logo.png'
import Simmons from './images/simmons.png'
import SimmonsBlue from './images/simmons-blue.png'

import './styles.scss'

const privacyHref = 'https://www.warnermediaprivacy.com/policycenter/b2c/WM/'
const supportHref = 'https://roosterteethsupport.zendesk.com/hc/en-us'

const EasterEggTile = ({ img, name, title }) => (
  <div className='rt-goodbye__easter-egg-tile'>
    <img
      className='rt-goodbye__easter-egg-tile-img'
      alt={`Professional headshot of ${name}`}
      src={img}
    />
    <div className='rt-goodbye__easter-egg-tile-name'>{name}</div>
    <div className='rt-goodbye__easter-egg-tile-title'>{title}</div>
  </div>
)

const tiles = [
  [Ben, 'Ben Stedman', 'Sr. Director, Product & Engineering'],
  [Johnny, 'John Revels', 'Sr. Product Manager'],
  [Mat, 'Mat Proano', 'Sr. Product Designer'],
  [Christina, 'Christina "Duck" Hinson', 'Product Owner'],
  [Nankai, 'Nankai Pan', 'Director of Web Engineering'],
  [Sammy, 'Sammy Taylor', 'Front-end Engineer'],
  [Nick, 'Nick Prokesch', 'Front-end Engineer'],
  [Praveen, 'Praveen Gudimetla', 'Back-end Engineer'],
  [Dave, 'Dave Pratt', 'Director of Data'],
  [Daniel, 'Daniel Soleiman', 'Data Engineer'],
  [Buddy, 'Buddy Rich', 'iOS Engineer'],
  [Ryan, 'Ryan Smith', 'Android Engineer'],
  [Steven, 'Steven Hyden', 'Infrastructure Engineer'],
]

const isEscape = e => e.code === 'Escape'

const Goodbye = () => {
  const [easterEggOpen, openEasterEgg, closeEasterEgg] = useToggle()

  useEffect(
    () => {
      let charArray = []
      document.addEventListener('keypress', e => {
        charArray.push(e.key)
        charArray = charArray.slice(-7)
        if (charArray.join('') === 'product') {
          openEasterEgg()
        }
      })

      document.addEventListener('keypress', e => {
        if (isEscape(e)) closeEasterEgg()
      })
    },
    [openEasterEgg, closeEasterEgg]
  )

  return (
    <main className='rt-goodbye'>
      <img className='rt-goodbye__bg' src={BG} alt='Background' />
      <div className='rt-goodbye__layout'>
        <div className='rt-goodbye__character-desktop-container'>
          <div className='rt-goodbye__character-desktop-container-inner'>
            <img
              className='rt-goodbye__character-desktop'
              src={Simmons}
              alt='Simmons from Red Vs Blue'
            />
            <img
              className='rt-goodbye__character-desktop rt-goodbye__character-desktop--hover'
              src={SimmonsBlue}
              alt='Simmons from Red Vs Blue'
            />
          </div>
        </div>
        <div className='rt-goodbye__main'>
          <img
            className='rt-goodbye__logo'
            src={Logo}
            alt='Old Rooster Teeth Logo'
          />
          <p className='rt-goodbye__tagline'>
            Thank you for 21 incredible years
          </p>
          <iframe
            className='rt-goodbye__video'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/IVjomWoOx2s?si=Qyuaq1RsP5f4hE8r'
            title='Video: Thank You For 21 Years Of Rooster Teeth'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowfullscreen
          />
          <article className='rt-goodbye__text'>
            <p>Twenty-One Years.</p>
            <p>
              It seems like only yesterday that we began the journey of
              wondering why we&apos;re here; only yesterday that Red vs. Blue
              became the foundation of our company and changed the Internet
              forever; only yesterday that we were just getting started.
            </p>
            <p>But now, after all this time, it&apos;s time to say goodbye.</p>
            <p>
              Thank you, from the bottom of our hearts, for spending the last
              two decades with us. Thank you for supporting us, for believing in
              us, for allowing us to experiment and to achieve our wildest
              dreams, and for helping us create life-long memories; but most of
              all, thank you for allowing us to do what we love with the people
              we love. While this is the end of Rooster Teeth, we hope it&apos;s
              the beginning of the next generation of online entertainment and
              creators. We hope more than anything that we&apos;ve inspired you,
              and to someday see you beat our 21-year run with your own Rooster
              Teeth. Hopefully you can come up with a better name, though.
            </p>
            <p>
              Thank you for one hell of a ride. We&apos;ll always remember
              yesterday.
            </p>
          </article>
        </div>
      </div>
      <p className='rt-goodbye__footer'>
        <ExternalLink aria-label='Privacy Policy' href={privacyHref}>
          Privacy Policy
        </ExternalLink>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <ExternalLink aria-label='Support' href={supportHref}>
          Support
        </ExternalLink>
        <br />© 2004 – 2024 Rooster Teeth Productions, LLC. All rights reserved
      </p>
      <Transition
        in={easterEggOpen}
        timeout={{ enter: 0, exit: 300 }}
        unmountOnExit
      >
        {state => (
          <div
            className={`rt-goodbye__easter-egg rt-goodbye__easter-egg--${state}`}
          >
            <div className='rt-goodbye__easter-egg-blurred-bg' />
            <div className='rt-goodbye__easter-egg-header'>Thank you!</div>
            <div className='rt-goodbye__easter-egg-subheader'>
              On behalf of the Product & Engineering Team, and all those who
              have worked on Rooster Teeth&apos;s website and apps, it&apos;s
              been an honor serving you.
            </div>
            <div className='rt-goodbye__easter-egg-tiles'>
              {tiles.map(([img, name, title]) => (
                <EasterEggTile img={img} name={name} title={title} />
              ))}
            </div>
          </div>
        )}
      </Transition>
    </main>
  )
}

export default Goodbye
