import moment from 'moment'

/**
 * Generates a display string for where a post was posted to
 * @param {Object} owner - owner of a post to generate a destination for
 * @return {String}
 */
export function toDestinationString(owner, activeUser) {
  if (!owner) {
    return ''
  }

  if (
    activeUser &&
    owner.type.toLowerCase() === 'user' &&
    activeUser.id === owner.data.id
  ) {
    return owner.data?.metadata?.followers_count > 1
      ? `${owner.data.metadata.followers_count} Followers`
      : 'Followers'
  }
  if (owner.type.toLowerCase() === 'user') {
    return `${owner.data.name}’s Feed`
  }

  return owner.data.name
}

/**
 * Generates internal path for where a post was posted to
 * @param {Object} owner - owner of a post to generate path to
 * @return {String}
 */
export function toDestinationPath(owner) {
  if (!owner || !owner.data) {
    return ''
  }

  if (owner.type.toLowerCase() === 'user') {
    return `/g/user/${owner.data.name}`
  }

  if (owner.type.toLowerCase() === 'community') {
    return `/g/${owner.data.slug}`
  }

  return ''
}

export function addCommentToPost({ comment, post }) {
  const upPost = { ...post, replies: [comment] }
  if (
    post.metadata &&
    post.metadata.comments &&
    post.metadata.comments.count !== undefined
  ) {
    upPost.metadata = {
      ...post.metadata,
      comments: {
        ...post.metadata.comments,
        count: post.metadata.comments.count + 1,
      },
    }
  }

  return upPost
}

/**
 * Attempts to add a comment to its parent post in a feed
 * @param {Object} comment - comment to add
 * @param {Array} feed - feed of posts
 * @return Array
 * @note this needs to be used for each instance of a feed, as a user can
 * generate a comment from the same action for multiple feed instances in the
 * redux store
 * */
export function addCommentToFeed({ comment, feed }) {
  // All comments made against posts are marked as `topic_type` === 'community'
  if (
    (comment.topic_type !== 'community' && comment.topic_type !== 'post') ||
    !comment.topic_uuid
  ) {
    return feed
  }

  if (Array.isArray(feed.list)) {
    feed = feed.list
  }

  return feed.map(post => {
    if (post.id !== comment.topic_uuid) {
      return post
    }
    return addCommentToPost({ comment, post })
  })
}

/**
 * Determines if a user is the owner of a post
 * @param {Object} user - user to compare against
 * @param {Object} post - post to compare against
 * @return {Boolean}
 */
export function userOwnsPost({ user = {}, post = {} }) {
  return post.author && user && post.author.id === user.id
}

/**
 * parses the type of post
 * @param {Object} post - model of the post to get the type for
 * @return {('merch'|'video'|'user'|'community'|'')} post type
 */
export function getPostType(postOrMetaWrapper) {
  // For backwards compatibility, we're allowing a post meta wrapper to be passed as an argument.
  const post = postOrMetaWrapper?.post || postOrMetaWrapper
  if (!post) return false
  // merch and video types use `category` to distinguish themselves
  if (post.category !== 'default') {
    return post.category
  }

  const { owner } = post
  const type = owner.type || ''
  return type.toLowerCase()
}

/**
 * Returns the display type of the post.
 * @note this is similar but different from #getPostType, it adds 'images' as
 * a type
 * */
export function getPostDisplayType(postOrMetaWrapper) {
  const post = postOrMetaWrapper?.post || postOrMetaWrapper
  if (!post) return false
  const postType = getPostType(post) || 'default'
  if (postType === 'merch' || postType === 'video') {
    return postType
  }
  if (post.media && post.media.images && post.media.images.length > 0) {
    return 'images'
  }
  return postType
}

function prescreenMembership({ post, memberships }) {
  if (!post || !post.owner || !post.owner.data) return false

  const { data: community } = post.owner
  if (!community || !community.id) return false

  return memberships.listByCommunityId.get(community.id)
}

export function isAdminOfPost({ post, memberships }) {
  const membership = prescreenMembership({ post, memberships })
  return membership && membership.admin
}

export function isModeratorOfPost({ post, memberships }) {
  const membership = prescreenMembership({ post, memberships })
  return membership && membership.moderator
}

/**
 * Fetches the relevant uuid for comments to a post
 * Video posts use the SVOD video uuid to write and fetch comments from
 */
export function getPostCommentUuid({ post }) {
  if (!post) {
    return {}
  }
  const postType = getPostType({ post })
  if (!postType) {
    return {}
  }

  // comments are shared for video posts and episodes, uuids get matched to the
  // episode instead of the video post
  if (postType === 'video') {
    return {
      id: (post.foreign_association && post.foreign_association.id) || '',
      type: 'episode',
    }
  }

  return {
    id: post.id,
    // all other post comments just belong to 'posts'
    type: 'post',
  }
}

/**
 * Returns human readable timeFromNow under a week, over a week, should return
 * date string
 * @param {String} date - ISO date string to convert
 * */
export function postTimeFromNow(date) {
  const now = moment()
  const postDate = moment(date)

  if (now.diff(postDate, 'days') > 7) {
    return postDate.format('MMM D, YYYY')
  }

  return moment(date).fromNow()
}

/**
 * Given a post return what a user's interaction should count against
 * IE when I comment on a repost, am I commenting against the repost id or the op post id
 * In this case only reposts with a body should count as distinct entities
 * @param {Object} post
 */
export function getSubjectPostId(post) {
  if (post && post.repostId && !post.body) {
    return post.repostId
  }
  if (post) {
    return post.id
  }
  return post
}

/**
 * Same as `getSubjectPostId`, but extracts the subject post entirely (the post or repost) rather than fetching only the ID.
 * @param {Object} post
 */
export function extractSubjectPost(post) {
  const subjectIsOriginalPost = post.repost && !post.body
  if (subjectIsOriginalPost) return post.repost
  return post
}
