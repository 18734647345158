import moment from 'moment-timezone'

/** **********************
 *
 *  Time Helper Functions
 *
 ************************ */

// using Date() to parse api time responses so we can compare it to current time
export const checkIfLive = (start, end) => {
  const now = new Date()
  const parsedStart = new Date(start)
  const parsedEnd = new Date(end)

  return now > parsedStart && parsedEnd > now
}

// Return the number of hours between two Date objects
export const getDiffInHours = (time1, time2) => {
  const m1 = moment(time1)
  const m2 = moment(time2)
  const duration = moment.duration(m2.diff(m1))
  const diff = duration.asHours()
  return diff
}

export const getHoursAgo = hours => {
  const today = new Date()
  const [minutes, seconds, milliseconds] = [60, 60, 1000]
  const hoursInMillisecons = minutes * seconds * milliseconds * hours

  return today - hoursInMillisecons
}

export const getTimeString = timeInSeconds => {
  const time = new Date(1000 * timeInSeconds).toISOString().substr(11, 8)
  const [hours, minutes, seconds] = time.split(':')
  let segments

  if (hours > 0) {
    segments = [Number(hours), minutes, seconds] // Strip leading zero
  } else {
    segments = [Number(minutes), seconds] // Strip leading zero
  }

  return segments.join(':')
}

export const getYearsAgo = years => {
  const today = new Date()

  return new Date(
    today.getFullYear() - years,
    today.getMonth(),
    today.getDate()
  )
}

export const isTimeInFuture = time => {
  // take current Client date and convert to UTC/ISO for comparing against API Date
  const currentTime = new Date().toISOString()

  return currentTime < time
}

export const convertTo24Hour = time => {
  // accept '10:00 PM' => '22:00'
  if (!time) return null

  const [hours, minutes] = time.toLowerCase().split(/:| |am|pm/)

  const convertedHours = hours === '12' ? '00' : hours

  const militaryHours =
    time.toLowerCase().indexOf('pm') > 0
      ? parseInt(convertedHours, 10) + 12
      : convertedHours

  return `${militaryHours}:${minutes}`
}
