/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import classnames from 'classnames'
import isequal from 'lodash.isequal'
import { connect } from 'react-redux'

import {
  fetchEpisodes,
  fetchEpisodesPercentage,
  fetchFeaturedPlaylists,
  fetchLivestreams,
  fetchMerch,
  fetchPlaylists,
  fetchSeasons,
  fetchShows,
  fetchWatchLater,
  fetchWatchLaterItems,
  getFeedCarousel,
} from 'common/actions'
import { Carousel } from 'common/components'
import CarouselHeader from 'common/components/Carousel/Header'

import './styles.scss'

export class CarouselRow extends Component {
  static defaultProps = {
    onFollowSeriesClick: () => null,
    onMerchItemClick() {},
    onNextPage: () => null,
    onPreviousPage: () => null,
    onThumbnailClick() {},
    onThumbnailTitleClick() {},
    onTitleClick: () => null,
    onUnfollowSeriesClick: () => null,
  }

  componentDidMount() {
    const { dispatch, dontFetch, filter, sort, source, type } = this.props

    if (dontFetch) return

    if (source && type !== 'watchlater') {
      dispatch(getFeedCarousel({ filter, source, type }))
      return
    }

    // fetch data for this carouselRow
    switch (type) {
      case 'shows':
        dispatch(fetchShows(filter))
        break
      case 'seasons':
        dispatch(fetchSeasons(filter))
        break
      case 'episodes':
        dispatch(fetchEpisodes(filter, sort))
        break
      case 'livestreams':
        dispatch(fetchLivestreams(filter.channel))
        break
      case 'merch':
        dispatch(fetchMerch(filter))
        break
      case 'playlist':
        dispatch(fetchFeaturedPlaylists({ filter: filter.name }))
          .then(items => {
            if (items.length) {
              dispatch(
                fetchPlaylists(
                  items.map(i => i.attributes.content_uuid),
                  filter
                )
              )
            }
          })
          .catch(ex => Promise.reject(ex))
        break
      case 'watchlater':
        dispatch(fetchWatchLater())
        dispatch(fetchWatchLaterItems())
        break
      default:
        return null
    }
  }

  shouldComponentUpdate(nextProps) {
    // have to check carousel data since the array changing isn't registering as the carousel object changing
    return (
      nextProps.carousel !== this.props.carousel ||
      nextProps.carousel?.data !== this.props.carousel?.data ||
      nextProps.filter !== this.props.filter ||
      nextProps.episodePercentages !== this.props.episodePercentages
    )
  }

  componentDidUpdate(prevProps, _prevState) {
    const { carousel, dispatch, user } = this.props
    // if the carousel has episodes, fetch saved watch times
    if (carousel?.type === 'episodes' || carousel?.type === 'watchlater') {
      if (prevProps.user === null && user !== null && carousel) {
        dispatch(fetchEpisodesPercentage({ items: carousel.data }))
      } else if (user && !prevProps.carousel && carousel) {
        dispatch(fetchEpisodesPercentage({ items: carousel.data }))
      }
    }
  }

  render() {
    const {
      carousel,
      dispatch,
      episodePercentages,
      featureFlags,
      filter,
      headerLink,
      isAuthenticated,
      title,
      watchLaterId,
    } = this.props
    if (
      carousel === undefined ||
      !carousel?.data ||
      carousel?.data?.length <= 0
    )
      return null
    const rowClass = classnames({
      'carousel-container': true,
    })
    const percentages = isAuthenticated ? episodePercentages : []

    return (
      <section className={rowClass} ref={section => (this.container = section)}>
        <CarouselHeader
          title={title}
          link={watchLaterId ? `/playlist/${watchLaterId}` : headerLink}
          onClick={this.props.onTitleClick}
        />
        {this.props.children}
        <Carousel
          data={carousel.data}
          dispatch={dispatch}
          featureFlags={featureFlags}
          filter={filter}
          listId={watchLaterId}
          onFollowSeriesClick={this.props.onFollowSeriesClick}
          onMerchItemClick={this.props.onMerchItemClick}
          onNextPage={this.props.onNextPage}
          onPreviousPage={this.props.onPreviousPage}
          onThumbnailClick={this.props.onThumbnailClick}
          onThumbnailTitleClick={this.props.onThumbnailTitleClick}
          onUnfollowSeriesClick={this.props.onUnfollowSeriesClick}
          percentages={percentages}
          type={carousel.type}
        />
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { carousels } = state.commonReducer.carousel
  const { isAuthenticated, user } = state.authReducer
  const { featureFlags, myStuff } = state.commonReducer
  const episodePercentages = state.commonReducer.carousel.itemPercentages

  // If passed in a carousel object use that, else fetch it
  let carousel = ownProps.carousel
  if (!carousel) {
    carousel = carousels.find(
      item =>
        isequal(item.filter.name, ownProps.filter.name) &&
        item.filter.value === ownProps.filter.value &&
        item.type === ownProps.type &&
        item.data.length !== 0
    )
  }
  let watchLaterId = null
  if (ownProps.type === 'watchlater' && myStuff.watchLaterInfo) {
    watchLaterId = myStuff.watchLaterInfo.id
    carousel = {
      ...ownProps,
      data: myStuff.watchLater,
    }
  }

  return {
    carousel,
    episodePercentages,
    featureFlags,
    fetchEpisodesPercentage,
    isAuthenticated,
    user,
    watchLaterId,
  }
}

export default connect(mapStateToProps)(CarouselRow)
