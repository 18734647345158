export const getPlatform = ({
  platform = window?.navigator?.platform,
  userAgent = window?.navigator?.userAgent,
} = {}) => {
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  let os = ''

  if (macPlatforms.includes(platform)) {
    os = 'mac'
  } else if (iosPlatforms.includes(platform)) {
    os = 'ios'
  } else if (windowsPlatforms.includes(platform)) {
    os = 'windows'
  } else if (/Android/.test(userAgent)) {
    os = 'android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'linux'
  }

  return os
}
