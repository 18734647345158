import { combineReducers } from 'redux'

import bonusReducer from './bonusReducer'
import browseShowsReducer from './browseShowsReducer'
import rssReducer from './rssReducer'
import showsReducer from './showsReducer'

export default combineReducers({
  bonus: bonusReducer,
  browseShows: browseShowsReducer,
  shows: showsReducer,
  rss: rssReducer,
})
