import React, { useEffect, useState } from 'react'
import Konami from 'react-konami-code'

import './styles.scss'

const initialY = -100

const importAll = r => {
  const images = []
  for (const [index, item] of r.keys().entries()) {
    images[index] = r(item).default
  }
  return images
}

function EmojiImg(x, y, emoji, velocity, range) {
  const _this = this
  this.x = x
  this.y = y
  this.velocity = velocity
  this.range = range
  this.element = document.createElement('span')
  this.element.style.opacity = 0
  this.element.style.position = 'fixed'
  this.element.innerHTML = `<img src='${emoji}'></img>`
  document.getElementById('drops').appendChild(this.element)

  this.update = function update() {
    if (_this.y > window.innerHeight) {
      _this.y = Math.random() * initialY
      _this.x = Math.random() * _this.range
    }
    _this.y += _this.velocity.y
    _this.x += _this.velocity.x
    this.element.style.opacity = 1
    this.element.style.transform = `translate3d(${_this.x}px, ${
      _this.y
    }px, 0px)`
    this.element.style.webkitTransform = `translate3d(${_this.x}px, ${
      _this.y
    }px, 0px)`
    this.element.style.mozTransform = `translate3d(${_this.x}px, ${
      _this.y
    }px, 0px)`
  }
}

const EasterEgg = () => {
  const [emoji, setEmoji] = useState([])
  const [floaters] = useState([])

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return null
    }
    const emoji = importAll(
      require.context(
        '../../../common/assets/emoji',
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
    setEmoji(emoji)
  }, [])

  const makeItRain = () => {
    const floaterWaves = 20
    const perWave = 8
    const totalEmojis = emoji.length
    const waveDelay = 250
    for (let i = 0; i < floaterWaves; i += 1) {
      addEmoji(i * waveDelay, emoji[(i * perWave) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 1) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 2) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 3) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 4) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 5) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 6) % totalEmojis])
      addEmoji(i * waveDelay, emoji[(i * perWave + 7) % totalEmojis])
    }
    animate()
  }

  const addEmoji = (delay, emoji) => {
    const range = window.innerWidth
    setTimeout(() => {
      const float = new EmojiImg(
        Math.random() * range,
        Math.random() * initialY,
        emoji,
        {
          x: -0.15 + Math.random() * 0.5,
          y: 1 + Math.random() * 2,
        },
        range
      )
      floaters.push(float)
    }, delay)
  }

  const animate = () => {
    for (const floater of floaters) floater.update()
    requestAnimationFrame(animate)
  }

  return (
    <Konami action={makeItRain}>
      <div id='drops' />
    </Konami>
  )
}

export default EasterEgg
