import { addOrReplacePositionObj } from 'common/helpers'

import {
  GET_SEASON_EPS_FAILURE,
  GET_SEASON_EPS_PERCENT_FAILURE,
  GET_SEASON_EPS_PERCENT_REQUEST,
  GET_SEASON_EPS_PERCENT_SUCCESS,
  GET_SEASON_EPS_REQUEST,
  GET_SEASON_EPS_SUCCESS,
  GET_SEASONS_FAILURE,
  GET_SEASONS_REQUEST,
  GET_SEASONS_SUCCESS,
} from '../actions/types'

export default (
  state = {
    allSeasons: [],
    allEpisodes: [],
    error: null,
    isFetching: false,
    itemPercentages: [],
  },
  action
) => {
  switch (action.type) {
    case GET_SEASONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_SEASONS_SUCCESS:
      return {
        ...state,
        allSeasons: [
          ...state.allSeasons,
          { showId: action.showId, items: action.items },
        ],
        isFetching: false,
      }
    case GET_SEASONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_SEASON_EPS_REQUEST:
      return {
        ...state,
        allEpisodes: [...state.allEpisodes, { seasonId: action.seasonId }],
        isFetching: true,
      }
    case GET_SEASON_EPS_SUCCESS:
      return {
        ...state,
        allEpisodes: state.allEpisodes.map(item => {
          if (item.seasonId === action.seasonId) {
            return {
              seasonId: action.seasonId,
              items: action.items,
              total: action.total,
            }
          }
          return item
        }),
        isFetching: false,
      }
    case GET_SEASON_EPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_SEASON_EPS_PERCENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_SEASON_EPS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: action.items,
        lastUpdated: Date.now(),
        isFetching: false,
      }
    case GET_SEASON_EPS_PERCENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case 'video/POST_VIDEO_SAVE_POSITION_SUCCESS':
      return {
        ...state,
        itemPercentages: addOrReplacePositionObj(state.itemPercentages, {
          from: 'es',
          uuid: action.episodeId,
          value: action.played,
        }),
        lastUpdated: Date.now(),
      }
    default:
      return state
  }
}
