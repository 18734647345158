import { createSelector } from '@reduxjs/toolkit'
import { includes, path } from 'ramda'

export const getRouting = state => state.router

export const getSearchString = state => state.router.location.search

export const getHostname = () => window.location.hostname
export const getProtocal = () => window.location.protocol

export const getPathname = createSelector(
  getRouting,
  path(['location', 'pathname'])
)

export const getHash = createSelector(
  getRouting,
  path(['location', 'hash'])
)

export const getIsEpisodePage = createSelector(
  getPathname,
  includes('/watch/')
)

export const getCanonicalUrl = createSelector(
  getPathname,
  getHostname,
  getProtocal,
  (pathname, hostname, protocol) => `${protocol}//${hostname}${pathname}`
)
