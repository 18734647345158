import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

class MaterializeInput extends Component {
  static propTypes = {
    actionText: PropTypes.string,
    className: PropTypes.string,
    countdown: PropTypes.bool,
    errorMessage: PropTypes.string,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    inputClassName: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onActionClick: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['email', 'number', 'password', 'text']).isRequired,
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }

  static defaultProps = {
    actionText: '',
    countdown: false,
    label: '',
    onActionClick: () => null,
    onBlur: () => null,
    onFocus: () => null,
    required: false,
  }

  state = {
    isFocused: false,
  }

  handleBlur = () => {
    this.props.onBlur()
    this.unsetFocus()
  }

  handleFocus = () => {
    this.props.onFocus()
    this.setFocus()
  }

  unsetFocus = () => {
    this.setState({ isFocused: false })
  }

  setFocus = () => {
    this.setState({ isFocused: true })
  }

  render() {
    const { isFocused } = this.state
    const {
      actionText,
      className,
      countdown,
      errorMessage,
      helperText,
      id,
      inputClassName,
      label,
      maxLength,
      name,
      onActionClick,
      onChange,
      required,
      valid,
      value,
      ...moreProps
    } = this.props

    const isDirty = String(value).length > 0

    const classes = {
      base: classnames('materialize-input', 'input-field', className),
      label: classnames({
        active: isFocused || isDirty,
      }),
      input: classnames(inputClassName, {
        invalid:
          (isDirty && (typeof valid === 'boolean' && !valid) && required) ||
          errorMessage,
        valid,
      }),
    }

    return (
      <div className={classes.base}>
        {label && (
          <label className={classes.label} htmlFor={id}>
            {label}
          </label>
        )}
        <input
          className={classes.input}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={this.handleBlur}
          onChange={onChange}
          onFocus={this.handleFocus}
          required={required}
          value={value}
          {...moreProps}
        />
        {helperText && !errorMessage && (
          <span className='materialize-input__text'>{helperText}</span>
        )}
        {actionText && !errorMessage && (
          <button
            className='materialize-input__action'
            onClick={onActionClick}
            type='button'
          >
            {actionText}
          </button>
        )}
        {!isFocused && errorMessage && (
          <span className='materialize-input__text materialize-input__text--error'>
            {errorMessage}
          </span>
        )}
        {countdown && maxLength && (
          <span className='materialize-input__text materialize-input__text--countdown'>
            {String(value).length}/{maxLength}
          </span>
        )}
      </div>
    )
  }
}

export default MaterializeInput
