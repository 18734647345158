import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import classNames from 'classnames'
import './styles.scss'

class FadeImage extends Component {
  static propTypes = {
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
    this.imgRef = React.createRef()
    this.onImageLoad = this.onImageLoad.bind(this)
  }

  componentDidMount() {
    const imgTag = this.imgRef.current
    const imgSrc = imgTag.getAttribute('src')
    const img = new window.Image()
    img.onload = this.onImageLoad
    img.src = imgSrc
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onImageLoad() {
    // if (this.mounted) {
    this.setState({ loaded: true })
    // }
  }

  render() {
    const { className, ...props } = this.props
    let rootClassName = className ? `${className} fade-image` : 'fade-image'
    if (this.state.loaded) {
      rootClassName += ' image-loaded'
    }
    return (
      <img
        ref={this.imgRef}
        alt='fade-in'
        {...props}
        className={rootClassName}
      />
    )
  }
}

export default FadeImage
