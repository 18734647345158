import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'
import { trackEvent } from 'common/metrics'

import { logoutSuccess } from 'auth/actions/loginActions'

import { showModal } from '../../modalApp/actions'
import {
  COMMENTS_UPDATE_PAGE,
  COMMENTS_UPDATE_SORT_BY,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_LIKE_FAILURE,
  DELETE_COMMENT_LIKE_REQUEST,
  DELETE_COMMENT_LIKE_SUCCESS,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  FLAG_COMMENT_FAILURE,
  FLAG_COMMENT_REQUEST,
  FLAG_COMMENT_SUCCESS,
  GET_ALL_REPLIES_FAILURE,
  GET_ALL_REPLIES_REQUEST,
  GET_ALL_REPLIES_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  GET_SINGLE_COMMENT_FAILURE,
  GET_SINGLE_COMMENT_REQUEST,
  GET_SINGLE_COMMENT_SUCCESS,
  GET_TOTAL_COMMENTS_FAILURE,
  GET_TOTAL_COMMENTS_REQUEST,
  GET_TOTAL_COMMENTS_SUCCESS,
  GET_USERNAMES_FAILURE,
  GET_USERNAMES_REQUEST,
  GET_USERNAMES_SUCCESS,
  POST_ADD_COMMENT_FAILURE,
  POST_ADD_COMMENT_REQUEST,
  POST_ADD_COMMENT_SUCCESS,
  POST_COMMENT_LIKE_FAILURE,
  POST_COMMENT_LIKE_REQUEST,
  POST_COMMENT_LIKE_SUCCESS,
  PUT_COMMENT_FAILURE,
  PUT_COMMENT_REQUEST,
  PUT_COMMENT_SUCCESS,
  RESTORE_COMMENT_FAILURE,
  RESTORE_COMMENT_REQUEST,
  RESTORE_COMMENT_SUCCESS,
  SET_SINGLE_COMMENT,
} from './types'

// @note using uuids here since comments theoretically can be attached to
// anything

export const COMMENTS_PER_PAGE = 20
export const REPLIES_PER_COMMENT = 0 // Default replies onload before fetching the rest onclick

// --------
export const updatePage = page => ({
  type: COMMENTS_UPDATE_PAGE,
  page,
})

export const updateCommentsSortBy = sortBy => ({
  type: COMMENTS_UPDATE_SORT_BY,
  sortBy,
})

// --------
// GET comments actions
// --------
export const requestComments = uuid => ({
  type: GET_COMMENTS_REQUEST,
  uuid,
})

const receiveComments = (uuid, comments, page, merge) => ({
  type: GET_COMMENTS_SUCCESS,
  comments: comments.data,
  merge,
  page,
  total: comments.total_count,
  uuid,
})

const fetchCommentsFailure = ex => ({
  type: GET_COMMENTS_FAILURE,
  error: ex.message,
})

export function fetchComments(id, page = 1, merge = true, sort) {
  return (dispatch, getState) => {
    const state = getState()
    const { isAuthenticated } = state.authReducer
    dispatch(requestComments(id))
    dispatch(getTotalComments(id))
    rtApi.commentsV2
      .fetchAll({ authenticated: isAuthenticated, id, page, sort })
      .then(comments => {
        dispatch(receiveComments(id, comments, page, merge))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(fetchCommentsFailure(ex))
      })
  }
}

// --------
// POST comments actions
// --------
export const postCommentRequest = message => ({
  type: POST_ADD_COMMENT_REQUEST,
  message,
})

export const postCommentSuccess = comment => ({
  type: POST_ADD_COMMENT_SUCCESS,
  comment,
})

export const postCommentsFailure = error => ({
  type: POST_ADD_COMMENT_FAILURE,
  error,
})

export const receiveTotalComments = total => ({
  type: GET_TOTAL_COMMENTS_SUCCESS,
  total,
})

class CommentError extends Error {
  constructor(parentId, replyId, ...params) {
    super(...params)
    this.parentId = parentId
    this.replyId = replyId
  }
}

export const requestTotalComments = id => ({
  type: GET_TOTAL_COMMENTS_REQUEST,
  id,
  total: undefined,
})

export const handleTotalCommentsFailure = id => ({
  type: GET_TOTAL_COMMENTS_FAILURE,
  id,
})

export function getTotalComments(id) {
  return async dispatch => {
    dispatch(requestTotalComments(id))
    rtApi.commentsV2
      .fetchCount({ id, errorCallback: handleTotalCommentsFailure })
      .then(({ data: total }) => {
        dispatch(receiveTotalComments(total))
      })
      .catch(err => {
        handleTotalCommentsFailure(err)
      })
  }
}

export function postAddComment({
  message = '',
  parentId = '',
  replyId = '',
  spoiler = false,
  token = '',
  topicId = '',
  topicType = '',
  trackSubmission = false,
}) {
  return (dispatch, getState) => {
    const state = getState()
    const { user } = state.authReducer

    dispatch(postCommentRequest(message))
    rtApi.commentsV2
      .addComment({
        comment: {
          message,
          parent_uuid: parentId,
          recaptcha_response: token,
          spoiler,
          topic_type: topicType,
          topic_uuid: topicId,
        },
      })
      .then(({ data: comment }) => {
        dispatch(postCommentSuccess(comment))
        dispatch(getTotalComments(topicId))

        if (trackSubmission) {
          if (parentId) {
            trackReply({ parentId, userId: user.id })
          } else {
            trackComment({ topicId, userId: user.id })
          }
        }
      })
      .catch(ex => {
        if (ex.status === 401) {
          return dispatch(showModal('USER_LOGIN', { user: '' }))
        }
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(
          postCommentsFailure(
            new CommentError(parentId, replyId, ex.message || 'Unknown Error')
          )
        )
      })
  }
}

function trackComment({ topicId, userId }) {
  trackEvent('SocialEvent', {
    label: 'comment_created',
    platform: 'web',
    target_id: topicId,
    target_type: '',
    user_uuid: userId,
  })
}

function trackReply({ parentId, userId }) {
  // we only want replies; which means they need to be nested under a parent
  // comment
  if (!parentId) {
    return
  }

  trackEvent('SocialEvent', {
    label: 'reply_created',
    platform: 'web',
    target_id: parentId,
    target_type: 'reply',
    user_uuid: userId,
  })
}

// --------
// GET single comment actions
// --------
export function fetchSingleComment(id) {
  return (dispatch, getState) => {
    const state = getState()
    const { isAuthenticated } = state.authReducer
    dispatch(requestSingleComment(id))
    return rtApi.commentsV2
      .fetchId({ authenticated: isAuthenticated, id })
      .then(json => {
        let comment = json.data
        if (json.data.parent_comment) {
          comment = json.data.parent_comment
        }
        return dispatch(getSingleCommentSuccess(comment, id))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(getSingleCommentFailure(ex))
      })
  }
}

export const requestSingleComment = uuid => ({
  type: GET_SINGLE_COMMENT_REQUEST,
  uuid,
})

export const getSingleCommentSuccess = (comment, uuid) => ({
  type: GET_SINGLE_COMMENT_SUCCESS,
  comment,
  uuid,
})

export const getSingleCommentFailure = error => ({
  type: GET_SINGLE_COMMENT_FAILURE,
  error,
})

export const resetSingleComment = () => ({
  type: SET_SINGLE_COMMENT,
  comment: null,
  uuid: '',
})

// --------
// GET all replies actions
// --------
export function fetchAllReplies(id) {
  return (dispatch, getState) => {
    const state = getState()
    const { isAuthenticated } = state.authReducer
    dispatch(requestAllReplies(id))
    rtApi.commentsV2
      .fetchReplies({ authenticated: isAuthenticated, id })
      .then(json => dispatch(getAllRepliesSuccess(json.data, id)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(getAllRepliesFailure(ex))
      })
  }
}

export const requestAllReplies = uuid => ({
  type: GET_ALL_REPLIES_REQUEST,
  uuid,
})

export const getAllRepliesSuccess = (replies, uuid) => ({
  type: GET_ALL_REPLIES_SUCCESS,
  replies,
  uuid,
})

export const getAllRepliesFailure = error => ({
  type: GET_ALL_REPLIES_FAILURE,
  error,
})

class CommentUpdateError extends Error {
  constructor(commentId, ...params) {
    super(...params)
    this.commentId = commentId
  }
}

// ///////
// PUT comments actions
// ///////
export const putCommentRequest = comment => ({
  type: PUT_COMMENT_REQUEST,
  comment,
})

export const putCommentSuccess = comment => ({
  type: PUT_COMMENT_SUCCESS,
  comment,
})

export const putCommentFailure = error => ({
  type: PUT_COMMENT_FAILURE,
  error,
})

export function updateComment(comment) {
  return (dispatch, _getState) => {
    dispatch(putCommentRequest(comment))
    rtApi.commentsV2
      .update({ id: comment.uuid, comment })
      .then(json => dispatch(putCommentSuccess(json.data)))
      .catch(ex => {
        if (ex.status === 401) {
          return dispatch(showModal('USER_LOGIN', { user: '' }))
        }
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(
          putCommentFailure(
            new CommentUpdateError(comment.uuid, 'Unknown Error')
          )
        )
      })
  }
}

// ------
// DELETE comment actions
// ------
export function deleteComment({ id, reason }) {
  return (dispatch, _getState) => {
    dispatch(deleteCommentRequest)
    rtApi.commentsV2
      .delete({ id, reason })
      .then(json => dispatch(deleteCommentSuccess(json.data, id)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(deleteCommentFailure(ex))
      })
  }
}

export const deleteCommentRequest = uuid => ({
  type: DELETE_COMMENT_REQUEST,
  uuid,
})

export const deleteCommentSuccess = (comment, uuid) => ({
  type: DELETE_COMMENT_SUCCESS,
  comment,
  uuid,
})

export const deleteCommentFailure = error => ({
  type: DELETE_COMMENT_FAILURE,
  error,
})

// -------
// Flag comments actions
// -------
export const flagCommentRequest = (uuid, flagType) => ({
  type: FLAG_COMMENT_REQUEST,
  uuid,
  flagType,
})

export const flagCommentSuccess = comment => ({
  type: FLAG_COMMENT_SUCCESS,
  comment,
})

export const flagCommentFailure = error => ({
  type: FLAG_COMMENT_FAILURE,
  error,
})

class FlagCommentError extends CommentUpdateError {}

export function flagComment(id, type) {
  return (dispatch, _getState) => {
    dispatch(flagCommentRequest(id, type))
    rtApi.commentsV2
      .flag({ id, type })
      .then(json => dispatch(flagCommentSuccess(json.data)))
      .catch(ex => {
        if (ex.status === 401) {
          return dispatch(showModal('USER_LOGIN', { user: '' }))
        }
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(
          flagCommentFailure(new FlagCommentError(id, 'Unknown Error'))
        )
      })
  }
}

// -------
// Restore Comment
// -------

export function restoreComment(id) {
  return (dispatch, _getState) => {
    dispatch(deleteCommentRequest)
    rtApi.commentsV2
      .restore({ id })
      .then(json => dispatch(restoreCommentSuccess(json.data, id)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(restoreCommentFailure(ex))
      })
  }
}

export const restoreCommentRequest = uuid => ({
  type: RESTORE_COMMENT_REQUEST,
  uuid,
})

export const restoreCommentSuccess = (comment, uuid) => ({
  type: RESTORE_COMMENT_SUCCESS,
  comment,
  uuid,
})
export const restoreCommentFailure = error => ({
  type: RESTORE_COMMENT_FAILURE,
  error,
})

// --------
// GET @ mention list
// --------
export function fetchUsernames(nameFragment) {
  return (dispatch, _getState) => {
    if (nameFragment === '' || nameFragment === undefined) {
      return
    }
    // making sure we don't make case-sensative requests that break cache
    const name = nameFragment.toLowerCase()
    dispatch(getUsernamesRequest(name))
    rtApi.users
      .fetchUsernames({ name })
      .then(json => dispatch(getUsernamesSuccess(json)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(getUsernamesFailure(ex))
      })
  }
}

export const getUsernamesRequest = name => ({
  type: GET_USERNAMES_REQUEST,
  name,
})

export const getUsernamesSuccess = users => ({
  type: GET_USERNAMES_SUCCESS,
  users,
})

export const getUsernamesFailure = error => ({
  type: GET_USERNAMES_FAILURE,
  error,
})

// --------
// POST comment like
// --------

export const postCommentLikeRequest = uuid => ({
  type: POST_COMMENT_LIKE_REQUEST,
  uuid,
})

export const postCommentLikeSuccess = uuid => ({
  type: POST_COMMENT_LIKE_SUCCESS,
  uuid,
})

export const postCommentLikeFailure = error => ({
  type: POST_COMMENT_LIKE_FAILURE,
  error,
})

export function postCommentLike(uuid) {
  return (dispatch, _getState) => {
    dispatch(postCommentLikeRequest(uuid))
    rtApi.commentsV2
      .like({ id: uuid })
      .then(_json => dispatch(postCommentLikeSuccess(uuid)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(postCommentLikeFailure(ex))
      })
  }
}

// --------
// DELETE comment like
// --------

export const deleteCommentLikeRequest = uuid => ({
  type: DELETE_COMMENT_LIKE_REQUEST,
  uuid,
})

export const deleteCommentLikeSuccess = uuid => ({
  type: DELETE_COMMENT_LIKE_SUCCESS,
  uuid,
})

export const deleteCommentLikeFailure = error => ({
  type: DELETE_COMMENT_LIKE_FAILURE,
  error,
})

export function deleteCommentLike(uuid) {
  return (dispatch, _getState) => {
    dispatch(deleteCommentLikeRequest(uuid))
    rtApi.commentsV2
      .removeLike({ id: uuid })
      .then(_json => dispatch(deleteCommentLikeSuccess(uuid)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(deleteCommentLikeFailure(ex))
      })
  }
}
