import { waitForGlobal } from 'common/helpers'

const facebookLoginOptions = {
  scope: 'public_profile,email',
  return_scopes: true,
}

/**
 * Attempts to sign in via Facebook OAuth. This may prompt the user with a Facebook OAuth signup window. If successful, a token is provided.
 * @returns {Promise<{ token: string, alreadyConnected: bool, response?: object }>} Promise fulfilled by an object with an access token if successful. Provides an access token for successful authentication, as well as an indicator of whether the user had already been connected, along with the original response, which contains more details.
 */
export const authenticateFacebookUser = () =>
  new Promise((resolve, reject) =>
    waitForGlobal('FB', 3)
      .then(() =>
        window.FB.getLoginStatus(response => {
          if (response.status === 'connected') {
            // The person is logged into the app and Facebook
            resolve({
              token: response.authResponse.accessToken,
              response: response.authResponse,
              alreadyConnected: true,
            })
          } else {
            // The person is not logged into app or we are unable to tell.
            window.FB.login(response => {
              if (response.status === 'connected') {
                resolve({
                  token: response.authResponse.accessToken,
                  response: response.authResponse,
                  alreadyConnected: false,
                })
              } else {
                // The person is not logged into this app or we are unable to tell.
                reject(response)
              }
            }, facebookLoginOptions)
          }
        })
      )
      .catch(waitForFBGlobalError => reject(waitForFBGlobalError))
  )

export default authenticateFacebookUser
