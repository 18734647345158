import uniqBy from 'lodash.uniqby'

import {
  GET_ALL_EPS_PERCENT_FAILURE,
  GET_ALL_EPS_PERCENT_REQUEST,
  GET_ALL_EPS_PERCENT_SUCCESS,
  GET_EPISODE_FAILURE,
  GET_EPISODE_REQUEST,
  GET_EPISODE_SUCCESS,
  GET_EPISODES_FAILURE,
  GET_EPISODES_REQUEST,
  GET_EPISODES_SUCCESS,
  GET_NEXT_PAGE_EPISODES_FAILURE,
  GET_NEXT_PAGE_EPISODES_REQUEST,
  GET_NEXT_PAGE_EPISODES_SUCCESS,
} from '../actions/types'

const initialState = {
  episodes: [],
  errors: null,
  isFetching: false,
  itemPercentages: [],
  page: 1,
  totalPages: null,
  totalResults: null,
}

const episodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EPISODE_REQUEST:
      return {
        ...state,
        errors: null,
        isFetching: true,
      }
    case GET_EPISODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.error,
      }
    case GET_EPISODE_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        lastUpdated: action.receivedAt,
        episodes: uniqBy([...state.episodes, action.episode], 'id'),
      }
    case GET_EPISODES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_EPISODES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        totalPages: null,
        totalResults: null,
      }
    case GET_EPISODES_SUCCESS:
      return {
        ...state,
        episodes: [
          // ...state.episodes,
          ...action.items,
        ],
        isFetching: false,
        lastUpdated: action.receivedAt,
        page: action.page,
        totalPages: action.total_pages,
        totalResults: action.total_results,
      }
    case GET_NEXT_PAGE_EPISODES_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_NEXT_PAGE_EPISODES_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        totalPages: null,
        totalResults: null,
      }
    case GET_NEXT_PAGE_EPISODES_SUCCESS:
      return {
        ...state,
        canPage: action.total_pages > action.page,
        episodes:
          Number(action.page) === 1
            ? action.items
            : [...state.episodes, ...action.items],
        isFetching: false,
        lastUpdated: action.receivedAt,
        page: action.page,
        totalPages: action.total_pages,
        totalResults: action.total_results,
      }
    case GET_ALL_EPS_PERCENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_ALL_EPS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: [...state.itemPercentages, ...action.items],
        lastUpdated: Date.now(),
        isFetching: false,
      }
    case GET_ALL_EPS_PERCENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case 'video/POST_VIDEO_SAVE_POSITION_SUCCESS':
      return {
        ...state,
        episodes: state.episodes.map(ep => {
          if (ep.uuid === action.episodeId) {
            return {
              ...ep,
              played: action.played,
            }
          }
          return ep
        }),
      }
    case 'video/POST_VIDEO_LAST_PLAYED_SUCCESS':
      return state
    default:
      return state
  }
}

export default episodeReducer
