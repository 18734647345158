import React from 'react'

import { getRelationship } from 'common/helpers/jsonApiHelpers'

import ContentBlockCommunityGroups from '../ContentBlockCommunityGroups'
import ContentBlockDevices from '../ContentBlockDevices'
import ContentBlockEpisodeCarousel from '../ContentBlockEpisodeCarousel'
import ContentBlockEpisodeGroup from '../ContentBlockEpisodeGroup'
import ContentBlockFeaturedEpisode from '../ContentBlockFeaturedEpisode'
import ContentBlockMerchGroup from '../ContentBlockMerchGroup'
import ContentBlockPlaylistCarousel from '../ContentBlockPlaylistCarousel'
import ContentBlockRandomVideo from '../ContentBlockRandomVideo'
import ContentBlockSeriesCarousel from '../ContentBlockSeriesCarousel'
import ContentBlockSeriesGroup from '../ContentBlockSeriesGroup'
import ContentBlockStatic from '../ContentBlockStatic'

const ContentBlocksFeed = React.memo(
  ({
    brandColor,
    episodesPerPage,
    feed,
    gutter,
    location,
    selectedChannel,
    seriesPerPage,
    showRandomBlockSocialLinks = true,
    watchLaterItems,
    watchLaterPlaylistId,
  }) => (
    <>
      {feed?.data.map(contentBlock => {
        const { attributes } = contentBlock
        const contentBlockUuid = attributes.uuid
        if (attributes.display_type === 'devices_1') {
          return (
            <ContentBlockDevices
              contentBlock={contentBlock}
              key={contentBlockUuid}
              location={location}
            />
          )
        }
        if (attributes.display_type === 'featured_communities') {
          return (
            <ContentBlockCommunityGroups
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              key={contentBlockUuid}
              location={location}
            />
          )
        }
        if (attributes.display_type === 'static_spotlight') {
          return (
            <ContentBlockStatic
              blockUrl={attributes.block_url}
              blockUrlOpenInNewTab={attributes.block_url_open_in_new_tab}
              brandColor={brandColor}
              bodyText={attributes.body_text}
              contentBlock={contentBlock}
              desktopBackgroundImageUrl={
                attributes.desktop_background_image_url
              }
              desktopForegroundImageUrl={
                attributes.desktop_foreground_image_url
              }
              displayDesktopBackgroundImage={
                attributes.display_desktop_background_image
              }
              imageUrl={attributes.image_url}
              key={contentBlockUuid}
              location={location}
              logoImageUrl={attributes.logo_image_url}
              mobileImageUrl={attributes.mobile_image_url}
              particles={attributes.particle_effect_enabled}
              primaryCtaButtonEnabled={attributes.primary_cta_button_enabled}
              primaryCtaCopy={attributes.primary_cta_copy}
              primaryCtaOpenInNewTab={attributes.primary_cta_open_in_new_tab}
              primaryCtaUrl={attributes.primary_cta_url}
              secondaryCtaButtonEnabled={
                attributes.secondary_cta_button_enabled
              }
              secondaryCtaCopy={attributes.secondary_cta_copy}
              secondaryCtaOpenInNewTab={
                attributes.secondary_cta_open_in_new_tab
              }
              secondaryCtaUrl={attributes.secondary_cta_url}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }
        if (attributes.display_type === 'featured_episode_block') {
          const episode = getRelationship(
            contentBlock,
            'episode',
            'episode',
            feed
          )
          if (!episode) return null
          return (
            <ContentBlockFeaturedEpisode
              body={
                attributes.display_body_caption
                  ? attributes.body_caption
                  : episode.attributes.caption
              }
              brandColor={brandColor}
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              item={episode}
              key={contentBlockUuid}
              listId={attributes.playlist_uuid}
              location={location}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }
        if (attributes.display_type === 'featured_episodes_row') {
          const episodes = getRelationship(
            contentBlock,
            'episodes',
            'episode',
            feed
          )
          if (!episodes) return null
          return (
            <ContentBlockEpisodeGroup
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              items={episodes}
              key={contentBlockUuid}
              listId={attributes.playlist_uuid}
              location={location}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }
        if (attributes.display_type === 'featured_store_carousel') {
          const collections = getRelationship(
            contentBlock,
            'store_carousels',
            'store_carousel',
            feed
          )
          if (!collections) return null
          return (
            <ContentBlockMerchGroup
              contentBlock={contentBlock}
              items={collections}
              headerUrl={attributes.link_to}
              key={contentBlockUuid}
              location={location}
              merchType='store_carousel'
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }
        if (attributes.display_type === 'merch_carousel') {
          const products = getRelationship(
            contentBlock,
            'products',
            'product',
            feed
          )
          if (!products) return null
          return (
            <ContentBlockMerchGroup
              contentBlock={contentBlock}
              items={products}
              headerUrl={attributes.link_to}
              key={contentBlockUuid}
              location={location}
              merchType='product'
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }
        if (attributes.display_type === 'featured_series_row') {
          const shows = getRelationship(contentBlock, 'shows', 'show', feed)
          if (!shows) return null
          return (
            <ContentBlockSeriesGroup
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              items={shows}
              key={contentBlockUuid}
              location={location}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }

        if (attributes.display_type === 'series_carousel') {
          const shows = getRelationship(contentBlock, 'shows', 'show', feed)
          if (!shows?.length) return null

          return (
            <ContentBlockSeriesCarousel
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              items={shows}
              location={location}
              perPage={seriesPerPage}
              scrollModeMargins={gutter}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }

        if (attributes.display_type === 'episode_carousel') {
          let listId
          let episodes

          if (attributes.carousel_content === 'watch_later_carousel') {
            listId = watchLaterPlaylistId
            episodes = watchLaterItems
            // For Channel watch later, we use the same reducer and endpoints, but we filter the items by channel on the frontend.
            if (selectedChannel) {
              episodes = episodes.filter(
                episode => episode?.attributes?.channel_slug === selectedChannel
              )
            }
          } else {
            episodes = getRelationship(
              contentBlock,
              'episodes',
              'episode',
              feed
            )
          }

          if (!episodes?.length) return null

          return (
            <ContentBlockEpisodeCarousel
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              items={episodes}
              listId={listId}
              location={location}
              perPage={episodesPerPage}
              scrollModeMargins={gutter}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }

        if (attributes.display_type === 'playlist_carousel') {
          const playlists = getRelationship(
            contentBlock,
            'playlists',
            'playlist',
            feed
          )

          if (!playlists?.length) return null

          return (
            <ContentBlockPlaylistCarousel
              contentBlock={contentBlock}
              headerUrl={attributes.link_to}
              items={playlists}
              location={location}
              perPage={episodesPerPage}
              scrollModeMargins={gutter}
              title1={attributes.title_1}
              title2={attributes.title_2}
            />
          )
        }

        if (attributes.display_type === 'random_video_block') {
          return (
            <ContentBlockRandomVideo
              contentBlock={contentBlock}
              location={location}
              showSocialLinks={showRandomBlockSocialLinks}
            />
          )
        }

        return null
      })}
    </>
  )
)

export default ContentBlocksFeed
