import React from 'react'

import { TAB_ALL_RESULTS } from 'rootApp/containers/SearchV2/helpers/constants'

import WrappedEpisodeCard from '../../shared/WrappedEpisodeCard'

const EpisodesColumn = ({
  closeSearchV2,
  episodes,
  isBonusFeatures,
  searchTerm,
}) => (
  <div className='search-v2-all-results__episodes'>
    {episodes.map(episode => (
      <WrappedEpisodeCard
        key={episode.uuid}
        closeSearchV2={closeSearchV2}
        episode={episode}
        isBonusFeature={isBonusFeatures}
        searchTerm={searchTerm}
        tab={TAB_ALL_RESULTS}
      />
    ))}
  </div>
)

export default EpisodesColumn
