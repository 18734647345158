import React, { Component } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import './styles.scss'

class FunnelNav extends Component {
  static defaultProps = {
    authComponent: null,
    isAuthenticated: false,
    showAuth: true,
    textColor: 'white',
    user: {},
  }

  state = {
    isOpaque: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledHandleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledHandleScroll)
  }

  throttledHandleScroll = () => {
    window.requestAnimationFrame(this.toggleOpacity)
  }

  toggleOpacity = () => {
    if (window.scrollY >= 40) {
      this.setState({ isOpaque: true })
    } else {
      this.setState({ isOpaque: false })
    }
  }

  render() {
    const {
      authComponent: AuthComponent,
      isAuthenticated,
      showAuth,
      textColor,
    } = this.props
    const { isOpaque } = this.state

    const classes = {
      base: classnames('funnel-nav', 'qa-funnel-nav', {
        'funnel-nav--black-text': textColor === 'black',
        'funnel-nav--dark': isOpaque,
      }),
    }

    return (
      <div className={classes.base}>
        <Link className='funnel-nav__logo' to='/'>
          <img
            alt='Rooster Teeth'
            className='funnel-nav__logo-img qa-funnel-nav-logo'
            src={
              textColor === 'black'
                ? isOpaque
                  ? `${
                      process.env.PUBLIC_URL
                    }/img/logo-new-rooster-teeth-white.svg`
                  : `${
                      process.env.PUBLIC_URL
                    }/img/logo-new-rooster-teeth-black.svg`
                : `${
                    process.env.PUBLIC_URL
                  }/img/logo-new-rooster-teeth-white.svg`
            }
          />
        </Link>

        {AuthComponent && <AuthComponent />}

        {showAuth && !isAuthenticated && !AuthComponent && (
          <p className='funnel-nav__text qa-funnel-nav-login'>
            <span className='funnel-nav__prompt'>
              Have a Rooster Teeth account?{' '}
            </span>
            <Link className='funnel-nav__link' to='/login'>
              Log In
            </Link>
            <span className='funnel-nav__prompt'>.</span>
          </p>
        )}
      </div>
    )
  }
}

export default FunnelNav
