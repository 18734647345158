import React, { useCallback } from 'react'
import Helmet from 'helmet'
import { connect } from 'react-redux'

import SmartAppBanner from 'common/components/SmartAppBanner'
import { displaySmartAppBanner } from 'common/helpers'
import { getPlatform } from 'common/helpers/platform'
import { trackEvent } from 'common/metrics'

import {
  APP_URL_SCHEME,
  GOOGLE_PLAY_STORE_URL,
  IOS_APP_REDIRECT_URL,
  IPHONE_APP_ID,
  SMART_APP_BANNER_CLICK_OPEN,
} from './constants'

const SmartAppBannerContainer = () => {
  const platform = getPlatform()

  // On Android platforms, we are able to launch the app using URI Schemes.
  // If the page doesn't change, then we know the app isn't installed, so
  // we can send them to the Play store
  const openAppOrAppStore = () => {
    trackEvent(SMART_APP_BANNER_CLICK_OPEN, { device: platform })
    setTimeout(() => {
      window.location = GOOGLE_PLAY_STORE_URL
    }, 25)
    window.location = APP_URL_SCHEME
  }

  const trackButtonClick = useCallback(
    () => trackEvent(SMART_APP_BANNER_CLICK_OPEN, { device: platform }),
    [platform]
  )

  if (!displaySmartAppBanner()) {
    return (
      <Helmet>
        <meta name='apple-itunes-app' content={`app-id=${IPHONE_APP_ID}`} />
      </Helmet>
    )
  }

  return (
    <SmartAppBanner
      onOpen={openAppOrAppStore}
      downloadLink={IOS_APP_REDIRECT_URL}
      platform={platform}
      trackButtonClick={trackButtonClick}
    />
  )
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
})

export default connect(mapStateToProps)(SmartAppBannerContainer)
