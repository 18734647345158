import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

const CheckBtn = ({ checked, children, className, disabled, onClick }) => {
  const buttonClass = classNames('check-btn', 'btn', className, {
    'check-btn--checked': checked,
  })

  return (
    <button
      className={buttonClass}
      type='button'
      disabled={disabled}
      onClick={onClick}
    >
      <i className='check-btn__icon icon-check' />
      <span className='check-btn__text'>{children}</span>
    </button>
  )
}

CheckBtn.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

CheckBtn.defaultProps = {
  checked: null,
  children: null,
  className: '',
  disabled: false,
}

export default CheckBtn
