import rtApi from '@roosterteethproductions/svod-api'
import sample from 'lodash.sample'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { getUserObject } from 'auth/actions'
import { logoutSuccess } from 'auth/actions/loginActions'
import { fetchProfile } from 'profileApp/actions'

import {
  GET_DEFAULT_AVATARS_FAILURE,
  GET_DEFAULT_AVATARS_REQUEST,
  GET_DEFAULT_AVATARS_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_AVATAR_REQUEST,
  UPDATE_USER_AVATAR_SUCCESS,
} from './types'

// Default Avatars Actions

const getDefaultAvatarsRequest = () => ({
  type: GET_DEFAULT_AVATARS_REQUEST,
})

const getDefaultAvatarsSuccess = avatars => ({
  type: GET_DEFAULT_AVATARS_SUCCESS,
  avatars,
})

const getDefaultAvatarsError = message => ({
  type: GET_DEFAULT_AVATARS_FAILURE,
  message: String(message),
})

export const getDefaultAvatars = () => (dispatch, _getState) => {
  dispatch(getDefaultAvatarsRequest())

  return rtApi.users
    .fetchAvatars()
    .then(json => Promise.resolve(dispatch(getDefaultAvatarsSuccess(json))))
    .catch(ex => Promise.reject(dispatch(getDefaultAvatarsError(ex))))
}

export const selectRandomAvatar = () => dispatch =>
  rtApi.users.fetchAvatars().then(avatars => {
    const randomAvatar = sample(avatars)
    const url = new URL(randomAvatar.cdn_url)
    const bucket = url.hostname
    const imagePath = url.pathname.replace(/^\//, '')
    // updateUserAvatar fetches the profile for profileApp's reducer,
    return dispatch(updateUserAvatar({ bucket, imagePath })).then(() =>
      // but we also need to get the user object for the auth reducer.
      dispatch(getUserObject())
    )
  })

// User Avatar Actions

const updateUserAvatarRequest = () => ({
  type: UPDATE_USER_AVATAR_REQUEST,
})

const updateUserAvatarSuccess = avatars => ({
  type: UPDATE_USER_AVATAR_SUCCESS,
  avatars,
})

const updateUserAvatarError = message => ({
  type: UPDATE_USER_AVATAR_FAILURE,
  message: String(message),
})

export const updateUserAvatar = ({ bucket, imagePath }) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { user } = state.authReducer

  dispatch(updateUserAvatarRequest())

  return rtApi.users
    .updateProfilePicture({
      id: user.id,
      picture: {
        aws_bucket: bucket,
        image_path: imagePath,
        picture_type: 'profile_picture',
      },
    })
    .then(json => {
      dispatch(fetchProfile(user.attributes.username))
      dispatch(updateUserAvatarSuccess(json))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(updateUserAvatarError(ex)))
    })
}
