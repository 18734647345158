import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import BtnBase from '../BtnBase'

import './styles.scss'

const FacebookButton = props => {
  const { children, className, ...moreProps } = props

  return (
    <BtnBase className={classnames('btn--facebook', className)} {...moreProps}>
      <i className='icon-facebook-f left' />
      <span className='button-text'>{children}</span>
    </BtnBase>
  )
}

FacebookButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

FacebookButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => null,
}

export default FacebookButton
