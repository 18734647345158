import React from 'react'
import PropTypes from 'prop-types'

import { copyToClipboard } from 'common/helpers'

import ShareItem from './ShareItem'

const LinkShare = ({ href }) => (
  <ShareItem
    clickedMessage='Copied to clipboard'
    hoveredMessage='Copy link'
    href={href}
    icon='link'
    onClick={() => {
      copyToClipboard(href)
    }}
  />
)

LinkShare.propTypes = {
  href: PropTypes.string.isRequired,
}

export default LinkShare
