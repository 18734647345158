import React from 'react'
import classNames from 'classnames'
import { getPostDisplayType, getPostCommentUuid } from 'common/helpers/post'
import { AddComment, InlineLike } from 'common/components'
import { PostContext } from './index'

function PostFooter() {
  return (
    <PostContext.Consumer>
      {({
        post,
        showSpoiler,
        isReply,
        showGallery,
        featureFlags,
        dispatch,
        user,
        isCommenting,
        error,
        toggleSpoiler,
        commentCount,
        shareCount,
        likeCount,
        showReplyForm,
        toggleReplyForm,
        getImages,
        onClickUser,
        preventFullPost,
        shareDropDownIsOpen,
        onCopyShare,
        onInternalShare,
        userList,
        handleReplyClick,
        focusCommentBox,
        toggleLiked,
        posts,
        subjectPost,
      }) => {
        let { uuid, metadata } = post

        const { id: topicUuid, type: topicType } = getPostCommentUuid({
          post: subjectPost,
        })
        const replyId = isReply && subjectPost.uuid
        const commentCountValue = commentCount(metadata)
        const shareCountValue = shareCount(metadata)
        const likeCountValue = likeCount(metadata)
        let images = getImages()
        const postType = getPostDisplayType({ post })
        const allowReply = postType !== 'merch'
        const liked = subjectPost.metadata.likes.liked

        // ------
        // Render
        // ------
        return (
          <div className='post-footer'>
            <div
              className={classNames('post-activity', {
                'expanded-comment': images.length && showReplyForm,
              })}
            >
              <InlineLike
                className='post-link'
                count={likeCountValue}
                isLiked={liked}
                onClick={e => toggleLiked(e, liked)}
              />
              <a
                className={`post-comments-toggle post-link ${showReplyForm &&
                  'comment-open'}`}
                onClick={handleReplyClick}
                title='reply to post'
              >
                <i className='icon icon-comment2' />
                {commentCountValue > 0 ? (
                  <span className='comment-count-value'>
                    {commentCountValue}
                  </span>
                ) : (
                  'Comment'
                )}
              </a>
              <a
                className='post-share post-link'
                onClick={onInternalShare}
                title='repost'
              >
                <i className='icon icon-repeat' />
                {shareCountValue > 0 ? shareCountValue : 'Repost'}
              </a>
              <a
                className='post-copy post-link'
                onClick={onCopyShare}
                title='copy link for post to clipboard'
              >
                <i className='icon icon-link' />
                Share
              </a>
            </div>

            {allowReply && showReplyForm && (
              <div
                className='post-reply'
                onClick={e => preventFullPost(e, false)}
              >
                <AddComment
                  bg='dark'
                  className='comment-reply-form'
                  dispatch={dispatch}
                  error={error}
                  focusIn={focusCommentBox}
                  isCommenting={isCommenting || false}
                  onCancel={toggleReplyForm}
                  parentId={uuid}
                  placeholder='Add a reply'
                  replyId={replyId}
                  replyTimestamp={post.created_at}
                  topicId={topicUuid}
                  topicType={topicType}
                  trackSubmission
                  user={user}
                  userList={userList}
                  value=''
                />
              </div>
            )}
          </div>
        )
      }}
    </PostContext.Consumer>
  )
}

export default PostFooter
