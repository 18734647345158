import { addOrReplacePositionObj } from 'common/helpers'

import {
  GET_MERCH_FAILURE,
  GET_MERCH_REQUEST,
  GET_MERCH_SUCCESS,
  GET_NEXT_EPS_PERCENT_FAILURE,
  GET_NEXT_EPS_PERCENT_REQUEST,
  GET_NEXT_EPS_PERCENT_SUCCESS,
  GET_NEXT_FAILURE,
  GET_NEXT_REQUEST,
  GET_NEXT_SUCCESS,
} from '../actions/types'

const initialState = {
  error: null,
  isFetching: false,
  merch: [],
  next: [],
}

const relatedReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEXT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_NEXT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_NEXT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        next: [...state.next, { slug: action.slug, items: action.items }],
      }
    case GET_NEXT_EPS_PERCENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_NEXT_EPS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: action.items,
        lastUpdated: Date.now(),
        isFetching: false,
      }
    case GET_NEXT_EPS_PERCENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case 'video/POST_VIDEO_SAVE_POSITION_SUCCESS':
      return {
        ...state,
        itemPercentages: addOrReplacePositionObj(state.itemPercentages, {
          from: 'es',
          uuid: action.episodeId,
          value: action.played,
        }),
        lastUpdated: Date.now(),
      }
    case GET_MERCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_MERCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case GET_MERCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.receivedAt,
        merch: [
          ...state.merch,
          {
            showUuid: action.showUuid,
            items: action.collection
              .filter(item => item.image !== null)
              .slice(0, 30),
          },
        ],
      }
    default:
      return state
  }
}

export default relatedReducer
