import React, { useCallback, useState } from 'react'

import {
  startFalling,
  stopFalling,
} from 'rootApp/components/FallingParticlesEasterEgg/particlesEffect'

import './styles.scss'

const FallingParticlesEasterEggContainer = () => (
  <div className='falling-particles-easter-egg-container' />
)

const FallingParticlesEasterEggButton = ({
  compression,
  drawParticle,
  element,
  inverted,
  particleCount,
  resetParticle,
  speed,
  speedVariance,
  startYOffset,
  stopAfter,
}) => {
  const [falling, setFalling] = useState(false)

  const turnOnParticles = useCallback(
    () => {
      let onComplete
      if (stopAfter) onComplete = () => setFalling(false)
      startFalling({
        compression,
        drawParticle,
        inverted,
        onComplete,
        particleCount,
        resetParticle,
        speed,
        speedVariance,
        startYOffset,
        stopAfter,
      })
      setFalling(true)
    },
    [
      compression,
      drawParticle,
      inverted,
      particleCount,
      resetParticle,
      setFalling,
      speed,
      speedVariance,
      startYOffset,
      stopAfter,
    ]
  )

  const turnOffParticles = useCallback(
    () => {
      stopFalling()
      setFalling(false)
    },
    [setFalling]
  )

  return element({
    falling,
    toggleFalling: () => (falling ? turnOffParticles() : turnOnParticles()),
  })
}

export { FallingParticlesEasterEggContainer, FallingParticlesEasterEggButton }
