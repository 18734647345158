import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Preloader from 'common/components/MaterializePreloader'
import Tooltip from 'common/components/Tooltip'

import QueueToggleBlankBtn from './BlankBtn'
import QueueToggleIcon from './Icon'
import QueueToggle from './index'

function QueueToggleIconBtn({
  className,
  onAddClick,
  onRemoveClick,
  type,
  uuid,
}) {
  return (
    <QueueToggle
      onAddClick={onAddClick}
      onRemoveClick={onRemoveClick}
      type={type}
      uuid={uuid}
    >
      {({ isQueued, isSubmitting, toggleQueue, tooltipText }) => (
        <Tooltip text={tooltipText}>
          <QueueToggleBlankBtn
            className={classnames('queue-toggle__icon-btn', className)}
            isQueued={isQueued}
            toggleQueue={toggleQueue}
          >
            <Preloader color='currentColor' condition={!isSubmitting}>
              <QueueToggleIcon isQueued={isQueued} type={type} />
            </Preloader>
          </QueueToggleBlankBtn>
        </Tooltip>
      )}
    </QueueToggle>
  )
}

QueueToggleIconBtn.propTypes = {
  className: PropTypes.string,
  onAddClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
}

QueueToggleIconBtn.defaultProps = {
  className: '',
  onAddClick: () => null,
  onRemoveClick: () => null,
}

export default QueueToggleIconBtn
