import { useMemo } from 'react'

// The position (left) is different for every element in the carousel so it is called for each item.
export const useItemOuterStyle = ({
  currentOffset,
  index,
  isScroll,
  perPage,
  scrollModeMargins,
}) => {
  const itemOnPage = index >= currentOffset && index < currentOffset + perPage

  return useMemo(
    () =>
      isScroll
        ? // When natural scrolling is on, the position of the items has to account for the desired margins on the left and right sides of the container.
          {
            left: `calc(((${index} / ${perPage}) * (100% - ${scrollModeMargins *
              2}px)) + ${scrollModeMargins}px)`,
            width: `calc(((100% - ${scrollModeMargins * 2}px) / ${perPage}))`,
          }
        : {
            left: `${(index / perPage) * 100}%`,
            width: `${100 / perPage}%`,
            opacity: itemOnPage ? 1 : 0.5,
            filter: itemOnPage ? 'none' : `blur(4px)`,
            transition: itemOnPage
              ? '0.2s 0.2s filter ease, 0.2s 0.2s opacity ease'
              : '0.5s 0.5s filter ease, 0.5s 0.5s opacity ease',
            pointerEvents: itemOnPage ? 'auto' : 'none',
            willChange: 'filter',
          },

    [index, isScroll, itemOnPage, perPage, scrollModeMargins]
  )
}

export const useHeightAdjustStyle = ({
  isScroll,
  perPage,
  scrollModeMargins,
}) =>
  useMemo(
    () => {
      if (isScroll) {
        return {
          width: `calc(((100% - ${scrollModeMargins * 2}px) / ${perPage}))`,
        }
      }

      if (perPage) {
        return { width: `${100 / perPage}%` }
      }

      return {}
    },
    [isScroll, perPage, scrollModeMargins]
  )

export const useCarouselStyle = ({
  allowOverflow,
  currentOffset,
  inView,
  isScroll,
  items,
  perPage,
  scrollModeMargins,
  spacing,
  transitionDuration,
}) => {
  // The main carousel element must be slightly larger than its container to allow space between elements but flush margins on the right and left side. This is similar to `justify-content: space-between`, except that for us the elements are a list of divs which animates and exceeds the bounds of its parent.
  const carouselStyle = useMemo(
    () => {
      const style = {
        left: `-${spacing}px`,
        position: 'relative',
        width: `calc(100% + ${spacing}px * 2)`,
      }
      if (isScroll) {
        style.overflowX = 'scroll'
        style.overflowY = 'hidden'
      } else if (!allowOverflow) {
        // When overflow is not desired, explicitly hide anything not in the container.
        style.overflow = 'hidden'
      }

      return style
    },
    [allowOverflow, isScroll, spacing]
  )

  // The for non-scrolling mode, carousel's position is transformed based on the current offset and number of items per page.
  const cssPropertyTransform = useMemo(
    () => `translateX(-${(currentOffset / perPage) * 100}%)`,
    [currentOffset, perPage]
  )
  const cssPropertyTransition = `${transitionDuration}ms transform cubic-bezier(0.5, 0, 0.1, 1)`
  const carouselInnerStyle = useMemo(
    () =>
      isScroll
        ? {}
        : {
            transform: cssPropertyTransform,
            transition: cssPropertyTransition,
            willChange: 'transform',
          },
    [cssPropertyTransform, cssPropertyTransition, isScroll]
  )

  // In natural scrolling mode, extend the scrollable area to the margins.
  const carouselOuterStyle = useMemo(
    () =>
      // Only do this when "inView" is true, otherwise the extension causes useRefScrolledIntoView to freak out.
      inView && isScroll
        ? {
            width: `calc(100% + ${scrollModeMargins * 2}px)`,
            left: `-${scrollModeMargins}px`,
          }
        : {},
    [inView, isScroll, scrollModeMargins]
  )

  // The style for spacing and width of all elements is the same so it can be returned from here.
  const itemInnerStyle = useMemo(() => ({ margin: `0 ${spacing}px` }), [
    spacing,
  ])

  // This is a spacer that goes at the end of the carousel to create margin on the right side after the last element. Otherwise the last element's right side will be flush with the window edge.
  const scrollSpacerStyle = useMemo(
    () =>
      isScroll
        ? {
            left: `calc(((${
              items.length
            } / ${perPage}) * (100% - ${scrollModeMargins *
              2}px)) + ${scrollModeMargins}px)`,
            width: `${scrollModeMargins}px`,
            height: '20px',
            position: 'absolute',
          }
        : {},
    [isScroll, items.length, perPage, scrollModeMargins]
  )

  return {
    carouselInnerStyle,
    carouselOuterStyle,
    carouselStyle,
    itemInnerStyle,
    scrollSpacerStyle,
  }
}
