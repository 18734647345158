import React from 'react'
import { Flip, toast } from 'react-toastify'

import { normalizeNotifications } from 'common/helpers'

import ToastTemplate from './ToastTemplate'

import 'react-toastify/dist/ReactToastify.min.css'
import './styles.scss'

function ToastWrapper(payload) {
  const data = payload.data || payload.meta
  const type = data['notify.template_token']

  // If it doesn't follow a template, just Toast whatever message they sent
  if (!type) {
    return toast(JSON.stringify(payload), {
      className: 'Toastify__toast--rtdefault',
      autoClose: 4000,
      pauseOnFocusLoss: true,
      transition: Flip,
    })
  }

  const normalized = normalizeNotifications(data)
  normalized.read_at = payload.attributes?.read_at
  normalized.id = payload.id

  toast(<ToastTemplate payload={normalized} />, {
    className: 'Toastify__toast--rtdefault',
    autoClose: type === 'site_admin_message' ? false : 5000,
    pauseOnFocusLoss: true,
    transition: Flip,
  })
}

export default ToastWrapper
