import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { PaymentType, PrettyDate, Tooltip } from 'common/components'

import './styles.scss'

class CurrentPaymentMethod extends Component {
  static propTypes = {
    billingInfo: PropTypes.shape({
      first_name: PropTypes.string,
      last_four: PropTypes.string,
      last_name: PropTypes.string,
      month: PropTypes.number,
      updated_at: PropTypes.string.isRequired,
      year: PropTypes.number,
    }).isRequired,
    className: PropTypes.string,
    onEditClick: PropTypes.func,
    to: PropTypes.string,
    usePencilIcon: PropTypes.bool,
  }

  static defaultProps = {
    onEditClick: () => null,
  }

  isCredit = () => Boolean(this.props.billingInfo.card_type)

  // The edit button may be an anchored link or just a button with an onClick.
  renderEditButton() {
    const { onEditClick, to, usePencilIcon } = this.props

    const text = usePencilIcon ? (
      <Tooltip text='Edit'>
        <i className='icon-pencil' />
      </Tooltip>
    ) : (
      <>Edit</>
    )

    const className = usePencilIcon
      ? 'current-payment-method__edit-pencil'
      : 'current-payment-method__edit'

    if (to) {
      return (
        <Link className={className} onClick={onEditClick} to={to}>
          {text}
        </Link>
      )
    }

    if (onEditClick) {
      return (
        <span
          className={className}
          onClick={onEditClick}
          onKeyPress={onEditClick}
          role='button'
          tabIndex={0}
        >
          {text}
        </span>
      )
    }

    return null
  }

  render() {
    const { billingInfo, className } = this.props

    const classes = {
      base: classnames(
        'current-payment-method qa-current-payment-method',
        className
      ),
    }

    return (
      <div className={classes.base}>
        <div className='current-payment-method__top'>
          {this.isCredit() ? (
            <span className='current-payment-method__name'>
              {billingInfo.first_name} {billingInfo.last_name}
            </span>
          ) : (
            <PaymentType type='paypal' />
          )}

          {this.renderEditButton()}
        </div>

        {this.isCredit() && (
          <PaymentType
            lastFour={billingInfo.last_four}
            type={billingInfo.card_type}
          />
        )}

        <p className='current-payment-method__meta'>
          {this.isCredit() && (
            <Fragment>
              Expires {billingInfo.month}/{billingInfo.year} •{' '}
            </Fragment>
          )}
          Updated <PrettyDate date={billingInfo.updated_at} format='numeric' />
        </p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { billing_info } = state.subscriptions.account
  const { onEditClick } = ownProps

  return {
    billingInfo: billing_info,
    onEditClick,
  }
}

export default connect(mapStateToProps)(CurrentPaymentMethod)
