import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import throttle from 'lodash.throttle'

/**
 * @typedef {Object} DynamicSizeRefs
 * @property {Ref} referenceSpan - A ref for the invisible reference text which is used by JS to determine the math needed to convert the displaySpan to the correct size
 * @property {Ref} displaySpan - A ref for the visible text which will shrink or grow to fill the width of its container.
 */

/**
 * Allows text in a span to dynamically shrink or grow to fill the width of its container. Requires a span for the visible text (`displaySpan`), as well as an invisible span in the DOM which will be used to determine the math needed to adjust the size of the other span (`referenceSpan`).
 * 
 * When the ref elements are loaded, the hook checks the width of the `referenceSpan` (e.g., 60px), and checks the width of the parent of the `displaySpan` (e.g., 100px). It will then adjust the font size of the `displaySpan` so that it fills its parent's width (e.g., fontSize * (100 / 60)).
 * 
 * The hook uses direct DOM manipulation on the `displaySpan` ref.
 *
 * @return An object with the refs which must be provided to the reference element and the display element: `{ referenceSpan, displaySpan }`
 * 
 * @example

    const DynamicText = () => {
      const { referenceSpan, displaySpan } = useDynamicTextSize()
			const text = 'Some text'
      return (
				<div style={{ width: '200px' }}>
					<span
						// This reference span must have the same CSS as the display span (at least regarding typography), otherwise the math won't work. It must also have the same text contents.
						class="fancy-class"
						ref={referenceSpan}
						style={{ opacity: '0' }}
					>
						{text}
					</span>
					<span
						// The hook will automatically adjust this span so that it fills the width of its container. In this case, 200px, but the container size may be dynamic and it will still work.
						class="fancy-class"
						ref={displaySpan}
					>
						{text}
					</span>
				</div>
			)
    }
 */
const useDynamicTextSize = () => {
  const referenceSpan = useRef()
  const displaySpan = useRef()

  const applyDynamicSize = useCallback(() => {
    const MAX_FONT_SIZE = 200

    if (!referenceSpan.current || !displaySpan.current) return
    const referenceWidth = getWidth(referenceSpan.current)
    const referenceFontSize = getFontSize(referenceSpan.current)

    const parentWidth = getWidth(referenceSpan.current.parentElement)
    const scale = parentWidth / referenceWidth
    const newFontSize = referenceFontSize * scale
    const fontSizeSafe =
      newFontSize > MAX_FONT_SIZE ? MAX_FONT_SIZE : newFontSize

    displaySpan.current.style.fontSize = `${fontSizeSafe}px`
  }, [])

  // Apply dynamic username size on resizes
  useEffect(() => {
    const throttled = throttle(applyDynamicSize, 50)
    window.addEventListener('resize', throttled)
    return () => window.removeEventListener('resize', throttled)
  })

  // Apply dynamic username size on initial mount
  useLayoutEffect(applyDynamicSize)

  return { displaySpan, referenceSpan }
}

const getComputedProperty = (elem, property) =>
  window.getComputedStyle(elem)[property]

const getParsedComputedProperty = (elem, property) =>
  parseFloat(getComputedProperty(elem, property))
const getWidth = elem => getParsedComputedProperty(elem, 'width')
const getFontSize = elem => getParsedComputedProperty(elem, 'fontSize')

export default useDynamicTextSize
