import { takeEvery, put } from 'redux-saga/effects'

export function* setSetting({ settingName, settingValue }) {
  window.localStorage.setItem(settingName, settingValue)
  yield put({
    type: 'c/set_setting_RESP',
    settingName,
    settingValue,
  })
}

export function* settings() {
  yield takeEvery('c/set_setting', setSetting)
}
