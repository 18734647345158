import { useEffect } from 'react'
import rtApi from '@roosterteethproductions/svod-api'

const useFetchRecommendations = ({
  setSearchResults,
  searchTerm,
  userTier,
  recommendedEpisodes,
}) =>
  useEffect(
    () => {
      // Only fetch recommendations if we have not made a valid search (including preexisting searches from the URL), and if we have not already fetched recommendations.
      const isIncompleteSearch = !searchTerm || searchTerm.length < 3
      const hasNotFetched = recommendedEpisodes.length === 0
      if (isIncompleteSearch && hasNotFetched) {
        const fetchContentBlockRecommendations = rtApi.feeds.fetchContentBlocks(
          {
            type:
              userTier === 'first'
                ? 'search-rec-first'
                : 'search-rec-anon-free',
          }
        )

        fetchContentBlockRecommendations.then(contentBlocksResp => {
          const { included } = contentBlocksResp
          const recommendedEpisodes = included.filter(
            item => item.type === 'episode'
          )
          const recommendedSeries = included.filter(
            item => item.type === 'show'
          )

          setSearchResults(s => ({
            ...s,
            recommendedEpisodes,
            recommendedSeries,
          }))
        })
      }
    },
    [searchTerm, userTier, recommendedEpisodes.length, setSearchResults]
  )

export default useFetchRecommendations
