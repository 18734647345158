// Avatar Actions
export const GET_DEFAULT_AVATARS_FAILURE = 'avatars/GET_DEFAULT_AVATARS_FAILURE'
export const GET_DEFAULT_AVATARS_REQUEST = 'avatars/GET_DEFAULT_AVATARS_REQUEST'
export const GET_DEFAULT_AVATARS_SUCCESS = 'avatars/GET_DEFAULT_AVATARS_SUCCESS'
export const UPDATE_USER_AVATAR_FAILURE = 'avatars/UPDATE_USER_AVATAR_FAILURE'
export const UPDATE_USER_AVATAR_REQUEST = 'avatars/UPDATE_USER_AVATAR_REQUEST'
export const UPDATE_USER_AVATAR_SUCCESS = 'avatars/UPDATE_USER_AVATAR_SUCCESS'

// Carousel Actions
export const GET_EPISODES_REQUEST = 'carousel/GET_EPISODES_REQUEST'
export const GET_EPISODES_SUCCESS = 'carousel/GET_EPISODES_SUCCESS'
export const GET_EPISODES_FAILURE = 'carousel/GET_EPISODES_FAILURE'
export const GET_EPS_PERCENT_REQUEST = 'carousel/GET_EPS_PERCENT_REQUEST'
export const GET_EPS_PERCENT_SUCCESS = 'carousel/GET_EPS_PERCENT_SUCCESS'
export const GET_EPS_PERCENT_FAILURE = 'carousel/GET_EPS_PERCENT_FAILURE'
export const GET_FEEDS_FAILURE = 'feeds/GET_FEEDS_FAILURE'
export const GET_FEEDS_REQUEST = 'feeds/GET_FEEDS_REQUEST'
export const GET_FEEDS_SUCCESS = 'feeds/GET_FEEDS_SUCCESS'
export const GET_FEED_CAROUSEL_REQUEST = 'carousel/GET_FEED_CAROUSEL_REQUEST'
export const GET_FEED_CAROUSEL_SUCCESS = 'carousel/GET_FEED_CAROUSEL_SUCCESS'
export const GET_FEED_CAROUSEL_FAILURE = 'carousel/GET_FEED_CAROUSEL_FAILURE'
export const GET_GENRE_REQUEST = 'genre/GET_GENRE_REQUEST'
export const GET_GENRE_SUCCESS = 'genre/GET_GENRE_SUCCESS'
export const GET_GENRE_FAILURE = 'genre/GET_GENRE_FAILURE'
export const GET_LIVESTREAMS_REQUEST = 'livestream/GET_LIVESTREAMS_REQUEST'
export const GET_LIVESTREAMS_SUCCESS = 'livestream/GET_LIVESTREAMS_SUCCESS'
export const GET_LIVESTREAMS_FAILURE = 'livestream/GET_LIVESTREAMS_FAILURE'
export const GET_LIVESTREAMS_SCHEDULE_SUCCESS =
  'livestream/GET_LIVESTREAMS_SCHEDULE_SUCCESS'
export const GET_LIVESTREAMS_SCHEDULE_FAILURE =
  'livestream/GET_LIVESTREAMS_SCHEDULE_FAILURE'
export const GET_LIVESTREAMS_SCHEDULE_REQUEST =
  'livestream/GET_LIVESTREAMS_SCHEDULE_REQUEST'
export const GET_LIVESTREAMS_SHOWS_SUCCESS =
  'livestream/GET_LIVESTREAMS_SHOWS_SUCCESS'
export const GET_LIVESTREAMS_SHOWS_FAILURE =
  'livestream/GET_LIVESTREAMS_SHOWS_FAILURE'
export const GET_LIVESTREAMS_SHOWS_REQUEST =
  'livestream/GET_LIVESTREAMS_SHOWS_REQUEST'
export const REFRESH_LIVESTREAMS = 'livestream/REFRESH_LIVESTREAMS'
export const GET_SHOWS_REQUEST = 'carousel/GET_SHOWS_REQUEST'
export const GET_SHOWS_SUCCESS = 'carousel/GET_SHOWS_SUCCESS'
export const GET_SHOWS_FAILURE = 'carousel/GET_SHOWS_FAILURE'
export const GET_SEASONS_REQUEST = 'carousel/GET_SEASONS_REQUEST'
export const GET_SEASONS_SUCCESS = 'carousel/GET_SEASONS_SUCCESS'
export const GET_SEASONS_FAILURE = 'carousel/GET_SEASONS_FAILURE'
export const GET_SEASON_EPS_REQUEST = 'carousel/GET_SEASON_EPS_REQUEST'
export const GET_SEASON_EPS_SUCCESS = 'carousel/GET_SEASON_EPS_SUCCESS'
export const GET_SEASON_EPS_FAILURE = 'carousel/GET_SEASON_EPS_FAILURE'
export const GET_SEASON_EPS_PERCENT_REQUEST =
  'carousel/GET_SEASON_EPS_PERCENT_REQUEST'
export const GET_SEASON_EPS_PERCENT_SUCCESS =
  'carousel/GET_SEASON_EPS_PERCENT_SUCCESS'
export const GET_SEASON_EPS_PERCENT_FAILURE =
  'carousel/GET_SEASON_EPS_PERCENT_FAILURE'
export const GET_MERCH_REQUEST = 'carousel/GET_MERCH_REQUEST'
export const GET_MERCH_SUCCESS = 'carousel/GET_MERCH_SUCCESS'
export const GET_MERCH_FAILURE = 'carousel/GET_MERCH_FAILURE'

// Comments Actions
export const GET_COMMENTS_REQUEST = 'comments/GET_COMMENTS_REQUEST'
export const GET_COMMENTS_SUCCESS = 'comments/GET_COMMENTS_SUCCESS'
export const GET_COMMENTS_FAILURE = 'comments/GET_COMMENTS_FAILURE'
export const POST_ADD_COMMENT_REQUEST = 'comments/POST_ADD_COMMENT_REQUEST'
export const POST_ADD_COMMENT_SUCCESS = 'comments/POST_ADD_COMMENT_SUCCESS'
export const POST_ADD_COMMENT_FAILURE = 'comments/POST_ADD_COMMENT_FAILURE'
export const PUT_COMMENT_REQUEST = 'comments/PUT_COMMENT_REQUEST'
export const PUT_COMMENT_SUCCESS = 'comments/PUT_COMMENT_SUCCESS'
export const PUT_COMMENT_FAILURE = 'comments/PUT_COMMENT_FAILURE'
export const COMMENTS_UPDATE_PAGE = 'comments/COMMENTS_UPDATE_PAGE'
export const COMMENTS_UPDATE_SORT_BY = 'comments/COMMENTS_UPDATE_SORT_BY'
export const GET_SINGLE_COMMENT_REQUEST = 'comments/GET_SINGLE_COMMENT_REQUEST'
export const GET_SINGLE_COMMENT_SUCCESS = 'comments/GET_SINGLE_COMMENT_SUCCESS'
export const GET_SINGLE_COMMENT_FAILURE = 'comments/GET_SINGLE_COMMENT_FAILURE'
export const GET_ALL_REPLIES_REQUEST = 'comments/GET_ALL_REPLIES_REQUEST'
export const GET_ALL_REPLIES_SUCCESS = 'comments/GET_ALL_REPLIES_SUCCESS'
export const GET_ALL_REPLIES_FAILURE = 'comments/GET_ALL_REPLIES_FAILURE'
export const SET_SINGLE_COMMENT = 'comments/SET_SINGLE_COMMENT'
export const DELETE_COMMENT_REQUEST = 'comments/DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS = 'comments/DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAILURE = 'comments/DELETE_COMMENT_FAILURE'
export const FLAG_COMMENT_REQUEST = 'comments/FLAG_COMMENT_REQUEST'
export const FLAG_COMMENT_SUCCESS = 'comments/FLAG_COMMENT_SUCCESS'
export const FLAG_COMMENT_FAILURE = 'comments/FLAG_COMMENT_FAILURE'
export const RESTORE_COMMENT_REQUEST = 'comments/RESTORE_COMMENT_REQUEST'
export const RESTORE_COMMENT_SUCCESS = 'comments/RESTORE_COMMENT_SUCCESS'
export const RESTORE_COMMENT_FAILURE = 'comments/RESTORE_COMMENT_FAILURE'
export const GET_USERNAMES_REQUEST = 'comments/GET_USERNAMES_REQUEST'
export const GET_USERNAMES_SUCCESS = 'comments/GET_USERNAMES_SUCCESS'
export const GET_USERNAMES_FAILURE = 'comments/GET_USERNAMES_FAILURE'
export const POST_COMMENT_LIKE_REQUEST = 'comments/POST_COMMENT_LIKE_REQUEST'
export const POST_COMMENT_LIKE_SUCCESS = 'comments/POST_COMMENT_LIKE_SUCCESS'
export const POST_COMMENT_LIKE_FAILURE = 'comments/POST_COMMENT_LIKE_FAILURE'
export const DELETE_COMMENT_LIKE_REQUEST =
  'comments/DELETE_COMMENT_LIKE_REQUEST'
export const DELETE_COMMENT_LIKE_SUCCESS =
  'comments/DELETE_COMMENT_LIKE_SUCCESS'
export const DELETE_COMMENT_LIKE_FAILURE =
  'comments/DELETE_COMMENT_LIKE_FAILURE'

// Scrollable Actions
export const SCROLLABLE_ENABLE = 'scrollable/SCROLLABLE_ENABLE'
export const SCROLLABLE_DISABLE = 'scrollable/SCROLLABLE_DISABLE'
export const SCROLLABLE_TOGGLE = 'scrollable/SCROLLABLE_TOGGLE'

// Video Actions
export const GET_VIDEO_REQUEST = 'video/GET_VIDEO_REQUEST'
export const GET_VIDEO_SUCCESS = 'video/GET_VIDEO_SUCCESS'
export const GET_VIDEO_FAILURE = 'video/GET_VIDEO_FAILURE'
export const POST_VIDEO_SAVE_POSITION_SUCCESS =
  'video/POST_VIDEO_SAVE_POSITION_SUCCESS'
export const POST_VIDEO_LAST_PLAYED_SUCCESS =
  'video/POST_VIDEO_LAST_PLAYED_SUCCESS'

// Rating - Like Actions
export const GET_LIKES_EPISODE_DATA_REQUEST =
  'rating/GET_LIKES_EPISODE_DATA_REQUEST'
export const GET_LIKES_EPISODE_DATA_SUCCESS =
  'rating/GET_LIKES_EPISODE_DATA_SUCCESS'
export const GET_LIKES_EPISODE_DATA_FAILURE =
  'rating/GET_LIKES_EPISODE_DATA_FAILURE'
export const PUT_ADD_TO_LIKES_REQUEST = 'rating/PUT_ADD_TO_LIKES_REQUEST'
export const PUT_ADD_TO_LIKES_SUCCESS = 'rating/PUT_ADD_TO_LIKES_SUCCESS'
export const PUT_ADD_TO_LIKES_FAILURE = 'rating/PUT_ADD_TO_LIKES_FAILURE'
export const DELETE_FROM_LIKES_REQUEST = 'rating/DELETE_FROM_LIKES_REQUEST'
export const DELETE_FROM_LIKES_SUCCESS = 'rating/DELETE_FROM_LIKES_SUCCESS'
export const DELETE_FROM_LIKES_FAILURE = 'rating/DELETE_FROM_LIKES_FAILURE'

// Library/Playlist Actions
export const GET_MINE_BY_CONTENT_REQUEST = 'library/GET_MINE_BY_CONTENT_REQUEST'
export const GET_MINE_BY_CONTENT_SUCCESS = 'library/GET_MINE_BY_CONTENT_SUCCESS'
export const GET_MINE_SUCCESS = 'library/GET_MINE_SUCCESS'
export const GET_FEATURED_SUCCESS = 'library/GET_FEATURED_SUCCESS'
export const GET_FOLLOWED_SUCCESS = 'library/GET_FOLLOWED_SUCCESS'
export const GET_FOLLOWED_SHOWS_SUCCESS = 'library/GET_FOLLOWED_SHOWS_SUCCESS'
export const GET_PLAYLIST_SUCCESS = 'library/GET_PLAYLIST_SUCCESS'
export const GET_FEATURED_REQUEST = 'library/GET_FEATURED_REQUEST'
export const GET_PLAYLISTS_SUCCESS = 'library/GET_PLAYLISTS_SUCCESS'
export const GET_PLAYLIST_DATA_SUCCESS = 'library/GET_PLAYLIST_DATA_SUCCESS'
export const GET_PLAYLIST_OWNER_SUCCESS = 'library/GET_PLAYLIST_OWNER_SUCCESS'
export const DELETE_PLAYLIST_SUCCESS = 'library/DELETE_PLAYLIST_SUCCESS'
export const PUT_FOLLOW_PLAYLIST_SUCCESS = 'library/PUT_FOLLOW_PLAYLIST_SUCCESS'
export const DELETE_FOLLOW_PLAYLIST_SUCCESS =
  'library/DELETE_FOLLOW_PLAYLIST_SUCCESS'
export const PUT_PLAYLIST_SUCCESS = 'library/PUT_PLAYLIST_SUCCESS'
export const PATCH_PLAYLIST_SUCCESS = 'library/PATCH_PLAYLIST_SUCCESS'
export const GET_PLAYLIST_ITEMS_PERCENT_SUCCESS =
  'library/GET_PLAYLIST_ITEMS_PERCENT_SUCCESS'
export const GET_PLAYLIST_ITEMS_UUIDS_SUCCESS =
  'library/GET_PLAYLIST_ITEMS_UUIDS_SUCCESS'
export const GET_PLAYLIST_ITEMS_SUCCESS = 'library/GET_PLAYLIST_ITEMS_SUCCESS'
export const GET_ITEM_IN_WATCH_LATER_REQUEST =
  'library/GET_ITEM_IN_WATCH_LATER_REQUEST'
export const GET_ITEM_IN_WATCH_LATER_SUCCESS =
  'library/GET_ITEM_IN_WATCH_LATER_SUCCESS'
export const GET_WATCH_LATER_SUCCESS = 'library/GET_WATCH_LATER_SUCCESS'
export const GET_WATCH_LATER_DATA_SUCCESS =
  'library/GET_WATCH_LATER_DATA_SUCCESS'
export const DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS =
  'library/DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS'
export const DELETE_PLAYLIST_ITEM_SUCCESS =
  'library/DELETE_PLAYLIST_ITEM_SUCCESS'
export const PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS =
  'library/PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS'
export const PUT_PLAYLIST_ITEM_SUCCESS = 'library/PUT_PLAYLIST_ITEM_SUCCESS'
export const PUT_REORDER_PLAYLIST_ITEMS_SUCCESS =
  'library/PUT_REORDER_PLAYLIST_ITEMS_SUCCESS'
export const GET_TOTAL_COMMENTS_REQUEST = 'comments/GET_TOTAL_COMMENTS_REQUEST'
export const GET_TOTAL_COMMENTS_SUCCESS = 'comments/GET_TOTAL_COMMENTS_SUCCESS'
export const GET_TOTAL_COMMENTS_FAILURE = 'comments/GET_TOTAL_COMMENTS_FAILURE'
