const initialState = {
  list: [],
  byCommunity: {},
}

function byCommunityReducer(accumulator, invite) {
  accumulator[invite.community_id] = invite
  return accumulator
}

export default (state = initialState, { type, data: list }) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/INVITES_RESP':
      return {
        ...state,
        list,
        byCommunity: list.reduce(byCommunityReducer, {}),
      }
    case 'communityApp/c/acceptInvite_RESP':
      const filteredInvites = state.list.filter(invite => invite.id !== list.id)
      return {
        ...state,
        list: filteredInvites,
        byCommunity: filteredInvites.reduce(byCommunityReducer, {}),
      }
    default:
      return state
  }
}
