import { useEffect, useState } from 'react'
import throttle from 'lodash.throttle'

import handleNavStickiness from 'common/helpers/handleNavStickiness'
import wait from 'common/helpers/wait'

// A banner around `handleNavStickiness` which binds it to scroll events and also fires it 10 times over the first 10 seconds of page load to handle repaints from the Marketing Banner.
const useMarketingBannerAwareStickiness = () => {
  const [sticky, setSticky] = useState(handleNavStickiness())
  useEffect(() => {
    // Each second for the first ten seconds after page load, we check if the marketing banner is loaded. We can only determine the "sticky" state of the top nav once the banner has loaded, but there are no reliable events that indicate that it has been painted to the DOM, and it is a variable size image which takes up a dynamic amount of space. This is kludgy but I can't find a better way.
    const recalculateMarketingBannerHeight = async () => {
      await wait(1000)
      setSticky(handleNavStickiness())
    }
    for (let i = 0; i <= 10; i = i + 1) recalculateMarketingBannerHeight()

    const throttledSetSticky = throttle(
      () => setSticky(handleNavStickiness()),
      50
    )

    document.addEventListener('scroll', throttledSetSticky)
    return () => document.removeEventListener('scroll', throttledSetSticky)
  }, [])

  return sticky
}

export default useMarketingBannerAwareStickiness
