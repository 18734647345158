/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const LabelBase = React.forwardRef(({ className, ...props }, ref) => (
  <label
    className={classnames('btn waves-effect waves-light', className)}
    ref={ref}
    {...props}
  />
))

LabelBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
}

LabelBase.defaultProps = {
  className: '',
}

export default LabelBase
