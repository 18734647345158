import { replace } from 'redux-first-history'

import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'

const defaultConfig = {
  allowRedirectBack: false,
  redirectPath: '/login',
  authenticatedSelector: state => state.authReducer.isAuthenticated,
  redirectAction: path => dispatch => {
    dispatch(
      replace(
        `${path.pathname}?redirect_url=${encodeURI(window.location.href)}`
      )
    )
  },
  wrapperDisplayName: 'UserIsAuthenticated',
}

const UserIsAuthenticated = config =>
  connectedReduxRedirect({ ...defaultConfig, ...config })

export default UserIsAuthenticated
