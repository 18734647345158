/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useMemo } from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'
import { useToggle } from 'common/hooks'
import useRefScrolledIntoView from 'common/hooks/useRefScrolledIntoView'
import useWidthThreshold from 'common/hooks/useWidthThreshold'
import { trackEvent } from 'common/metrics'

import PrimaryBtn from '../Buttons/Primary'
import SecondaryBtn from '../Buttons/Secondary'
import KokiriParticles from '../KokiriParticles'
import MemoCloudinaryImg from '../MemoCloudinaryImg'
import ContentBlockBase, { Header1, Header2 } from './ContentBlockBase'

// Not necessarily the same "Mobile" breakpoint used by other layouts.
const STATIC_BLOCK_MOBILE_BREAKPOINT = 600

const BrandColorBackgroundGradient = ({ brandColor }) => {
  const style = useMemo(
    () => {
      const translucent = `#${brandColor}50`
      const middle1 = `#${brandColor}30`
      const middle2 = `#${brandColor}10`
      const transparent = `#${brandColor}00`
      return {
        background: `radial-gradient(closest-side at center, ${translucent} 0%, ${middle1} 35%, ${middle2} 55%, ${transparent} 80%)`,
      }
    },
    [brandColor]
  )

  return (
    <>
      <div className='content-block__static-brand-gradient-1' style={style} />
      <div className='content-block__static-brand-gradient-2' style={style} />
    </>
  )
}

const MaybeCloudinaryImage = ({ src, alt, width }) => {
  const isCloudinaryImage = src?.includes('f_auto')

  if (isCloudinaryImage) {
    const publicId = src?.split('f_auto')[1]
    return <MemoCloudinaryImg src={publicId} width={width} />
  }

  return <img src={src} alt={alt} />
}

const StaticBlockAnchorWrapper = ({ children, ...props }) => (
  <a {...props}>{children}</a>
)
const StaticBlockGenericWrapper = ({ children }) => children

const ContentBlockStatic = ({
  blockUrl,
  blockUrlOpenInNewTab,
  bodyText,
  brandColor,
  contentBlock,
  desktopBackgroundImageUrl,
  desktopForegroundImageUrl,
  displayDesktopBackgroundImage,
  imageUrl,
  location,
  logoImageUrl,
  mobileImageUrl,
  particles,
  primaryCtaButtonEnabled,
  primaryCtaCopy,
  primaryCtaOpenInNewTab,
  primaryCtaUrl,
  secondaryCtaButtonEnabled,
  secondaryCtaCopy,
  secondaryCtaOpenInNewTab,
  secondaryCtaUrl,
  title1,
  title2,
}) => {
  const [inView, setInView] = useToggle()
  const contentBlockRef = useRefScrolledIntoView(setInView, {
    topOffset: 500,
    bottomOffset: 500,
    mode: 'any-visible',
  })

  const displayDesktop = useWidthThreshold(STATIC_BLOCK_MOBILE_BREAKPOINT)

  const handleClick = useCallback(
    ({ copy, element }) => {
      trackEvent('Content Block Link Clicked', {
        content_block_display_type: contentBlock?.attributes?.display_type,
        content_block_item_use_type: contentBlock?.attributes?.item_use_type,
        content_block_uuid: contentBlock?.attributes?.uuid,
        location,
        static_spotlight_element_copy: copy,
        static_spotlight_element_clicked: element,
        target_url: blockUrl,
      })
    },
    [contentBlock, blockUrl, location]
  )

  const handleBackgroundClick = useCallback(
    () => handleClick({ copy: undefined, element: 'background' }),
    [handleClick]
  )
  const handlePrimaryCTAClick = useCallback(
    () => handleClick({ copy: primaryCtaCopy, element: 'primary_cta' }),
    [handleClick, primaryCtaCopy]
  )
  const handleSecondaryCTAClick = useCallback(
    () => handleClick({ copy: secondaryCtaCopy, element: 'secondary_cta' }),
    [handleClick, secondaryCtaCopy]
  )

  const hasButtons = primaryCtaButtonEnabled || secondaryCtaButtonEnabled

  const StaticBlockWrapperElem = blockUrl
    ? StaticBlockAnchorWrapper
    : StaticBlockGenericWrapper

  // TODO: Once CMS has been updated, we'll get rid of this condition because all blocks will require these fields.
  const hasNewFields = !!(bodyText && imageUrl)
  if (hasNewFields) {
    return (
      <ContentBlockBase ref={contentBlockRef}>
        <div
          className={`content-block__rich-static-container ${
            inView ? '' : 'content-block__rich-static-container--placeholder'
          } ${
            displayDesktop
              ? 'content-block__rich-static-container--desktop'
              : ''
          } ${
            // To preserve brand identities, don't do hue drift if a brand color was provided.
            brandColor ? '' : 'content-block__rich-static-container--hue-drift'
          }`}
        >
          <div className='content-block__rich-static-particles-container'>
            {particles ? (
              <KokiriParticles
                count={20}
                particleMaxSize={2}
                particleMinSize={1}
              />
            ) : null}
          </div>
          <StaticBlockWrapperElem
            href={blockUrl}
            {...a11yButtonProps(handleBackgroundClick)}
            target={blockUrlOpenInNewTab ? '_blank' : '_self'}
          >
            {displayDesktop ? (
              <>
                <div className='content-block__rich-static-desktop-image-bg-outer'>
                  <div
                    className={`content-block__rich-static-desktop-image-bg ${
                      brandColor
                        ? 'content-block__rich-static-desktop-image-bg--centered'
                        : ''
                    }`}
                  >
                    {inView ? (
                      brandColor ? (
                        <BrandColorBackgroundGradient brandColor={brandColor} />
                      ) : (
                        <MaybeCloudinaryImage
                          src={desktopBackgroundImageUrl}
                          alt='Promotional Image'
                          width='300'
                        />
                      )
                    ) : null}
                  </div>
                </div>
                <div className='content-block__rich-static-desktop-left-side'>
                  {logoImageUrl ? (
                    <div className='content-block__rich-static-logo-image'>
                      {inView ? (
                        <img src={logoImageUrl} alt='Logo Image' width='256' />
                      ) : null}
                    </div>
                  ) : null}
                  {title1 ? <Header1>{title1}</Header1> : null}
                  {title2 ? <Header2>{title2}</Header2> : null}
                  {bodyText ? (
                    <div className='content-block__rich-static-body-text'>
                      {bodyText}
                    </div>
                  ) : null}
                  {hasButtons && (
                    <div className='content-block__rich-static-ctas-container'>
                      {primaryCtaButtonEnabled && (
                        <PrimaryBtn
                          external
                          linkTo={primaryCtaUrl}
                          onClick={handlePrimaryCTAClick}
                          target={primaryCtaOpenInNewTab ? '_blank' : '_self'}
                        >
                          {primaryCtaCopy}
                        </PrimaryBtn>
                      )}
                      {secondaryCtaButtonEnabled && (
                        <SecondaryBtn
                          bg='dark'
                          external
                          linkTo={secondaryCtaUrl}
                          onClick={handleSecondaryCTAClick}
                          target={secondaryCtaOpenInNewTab ? '_blank' : '_self'}
                        >
                          {secondaryCtaCopy}
                        </SecondaryBtn>
                      )}
                    </div>
                  )}
                </div>

                <div className='content-block__rich-static-desktop-image'>
                  {inView ? (
                    <MaybeCloudinaryImage
                      src={imageUrl}
                      alt='Promotional Image'
                      width='2080'
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className='content-block__rich-static-mobile-image'>
                {inView ? (
                  <MaybeCloudinaryImage
                    src={imageUrl}
                    alt='Promotional Image'
                    width='800'
                  />
                ) : null}
                {logoImageUrl ? (
                  <div className='content-block__rich-static-logo-image'>
                    {inView ? (
                      <img src={logoImageUrl} alt='Logo Image' width='256' />
                    ) : null}
                  </div>
                ) : null}
                {title1 ? <Header1>{title1}</Header1> : null}
                {title2 ? <Header2>{title2}</Header2> : null}
                {bodyText ? (
                  <div className='content-block__rich-static-body-text'>
                    {bodyText}
                  </div>
                ) : null}
                {hasButtons && (
                  <div className='content-block__rich-static-mobile-ctas-container'>
                    {primaryCtaButtonEnabled && (
                      <PrimaryBtn
                        external
                        linkTo={primaryCtaUrl}
                        onClick={handlePrimaryCTAClick}
                        target={primaryCtaOpenInNewTab ? '_blank' : '_self'}
                      >
                        {primaryCtaCopy}
                      </PrimaryBtn>
                    )}
                    {secondaryCtaButtonEnabled && (
                      <SecondaryBtn
                        bg='dark'
                        external
                        linkTo={secondaryCtaUrl}
                        onClick={handleSecondaryCTAClick}
                        target={secondaryCtaOpenInNewTab ? '_blank' : '_self'}
                      >
                        {secondaryCtaCopy}
                      </SecondaryBtn>
                    )}
                  </div>
                )}
              </div>
            )}
          </StaticBlockWrapperElem>
        </div>
      </ContentBlockBase>
    )
  }
  return (
    <ContentBlockBase ref={contentBlockRef}>
      <div
        className={`content-block__static-container ${
          inView ? '' : 'content-block__static-container--placeholder'
        } ${
          // To preserve brand identities, don't do hue drift if a brand color was provided.
          brandColor ? '' : 'content-block__rich-static-container--hue-drift'
        }`}
      >
        <div className='content-block__static-particles-container'>
          {particles ? (
            <KokiriParticles
              count={20}
              particleMaxSize={2}
              particleMinSize={1}
            />
          ) : null}
        </div>
        <StaticBlockWrapperElem
          href={blockUrl}
          {...a11yButtonProps(handleBackgroundClick)}
          target={blockUrlOpenInNewTab ? '_blank' : '_self'}
        >
          {displayDesktop ? (
            <>
              {displayDesktopBackgroundImage || brandColor ? (
                <div className='content-block__static-desktop-image-bg-outer'>
                  <div
                    className={`content-block__rich-static-desktop-image-bg ${
                      brandColor
                        ? 'content-block__rich-static-desktop-image-bg--centered'
                        : ''
                    }`}
                  >
                    {inView ? (
                      brandColor ? (
                        <BrandColorBackgroundGradient brandColor={brandColor} />
                      ) : (
                        <MaybeCloudinaryImage
                          src={desktopBackgroundImageUrl}
                          alt='Promotional Image'
                          width='300'
                        />
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className='content-block__static-desktop-image'>
                {inView ? (
                  <MaybeCloudinaryImage
                    src={desktopForegroundImageUrl}
                    alt='Promotional Image'
                    width='2080'
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className='content-block__static-mobile-image'>
              {inView ? (
                <MaybeCloudinaryImage
                  src={mobileImageUrl}
                  alt='Promotional Image'
                  width='800'
                />
              ) : null}
            </div>
          )}
        </StaticBlockWrapperElem>
        {hasButtons && (
          <div className='content-block__static-ctas-container'>
            {primaryCtaButtonEnabled && (
              <PrimaryBtn
                external
                linkTo={primaryCtaUrl}
                onClick={handlePrimaryCTAClick}
                target={primaryCtaOpenInNewTab ? '_blank' : '_self'}
              >
                {primaryCtaCopy}
              </PrimaryBtn>
            )}
            {secondaryCtaButtonEnabled && (
              <SecondaryBtn
                bg='dark'
                external
                linkTo={secondaryCtaUrl}
                onClick={handleSecondaryCTAClick}
                target={secondaryCtaOpenInNewTab ? '_blank' : '_self'}
              >
                {secondaryCtaCopy}
              </SecondaryBtn>
            )}
          </div>
        )}
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockStatic
