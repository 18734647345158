import React, { Fragment } from 'react'
import classnames from 'classnames'
import FormattedText from 'common/components/FormattedText'
import { PostImagePreview } from 'common/components'
import { PostContext } from './index'

const SPOILER_CONTENT =
  '01001001 01010010 01110011 01010000 01101000 01100101 01000101 01110010 01000010 01101010 00111000'
const LG_TEXT_CHAR_LIMIT = 53
const PREVIEW_CHAR_LIMIT = 140

export default function PostBody() {
  return (
    <PostContext.Consumer>
      {({
        commentCount,
        dispatch,
        enablePostDetails,
        enclosedPost,
        error,
        featureFlags,
        getImages,
        isCommenting,
        isReply,
        onClickUser,
        onCopyShare,
        onInternalShare,
        post,
        posts,
        preventFullPost,
        shareCount,
        shareDropDownIsOpen,
        showGallery,
        showReplyForm,
        showSpoiler,
        toggleReplyForm,
        toggleSpoiler,
        user,
      }) => {
        let { unfurl_url: unfurlURL, body: message, spoiler, deleted } = post

        let images = getImages()
        if (!showSpoiler) {
          message = SPOILER_CONTENT
        }

        // we don't want to show 'view more' if we're in a detail view
        const showViewMore =
          enablePostDetails &&
          (post.body_length > PREVIEW_CHAR_LIMIT ||
            (post.body && post.body.length < post.body_length - 1)) // -1 because BE is off by one

        // ------
        // Render
        // ------
        return (
          <div className='post-body'>
            {images && images.length > 0 && (
              <Fragment>
                <div
                  className={`post-images-container ${
                    showSpoiler ? '' : 'spoiler'
                  }`}
                >
                  {!showGallery && <PostImagePreview images={images} />}
                </div>
                {spoiler && !showSpoiler && (
                  <div className='post-spoiler-overlay'>
                    <span className='btn' onClick={toggleSpoiler}>
                      <i className='icon icon-eye' />
                      Show Spoiler
                    </span>
                  </div>
                )}
              </Fragment>
            )}

            <div className='post-message'>
              {deleted && (
                <span className='post-deleted'>
                  <i className='icon icon-skull1' />
                  This post has been deleted
                </span>
              )}
              {!deleted && (
                <FormattedText
                  text={message}
                  handleClickOnUser={onClickUser}
                  featureFlags={featureFlags}
                  unfurlURL={unfurlURL}
                  className={classnames({
                    spoiler: spoiler && !showSpoiler,
                  })}
                  handleHashTags
                  repost={enclosedPost}
                >
                  {showViewMore && (
                    <span className='link-light post-view-more'> ...more</span>
                  )}
                </FormattedText>
              )}
            </div>

            {spoiler && !showSpoiler && !deleted && (
              <div
                className='post-toggle-spoilers post-link'
                onClick={toggleSpoiler}
              >
                Show Spoilers
              </div>
            )}
          </div>
        )
      }}
    </PostContext.Consumer>
  )
}
