import { rem } from 'polished'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Avatar from 'common/components/Avatar'

export const CommunityCardBase = styled(Link)`
  background-color: var(--community-card-background);
  border-radius: var(--community-card-radius);
  box-shadow: var(--community-card-shadow);
  display: block;
  height: 185px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
`

export const CommunityCardBanner = styled.div`
  background-image: url(${props => props.src});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75px;
`

CommunityCardBanner.propTypes = {
  src: PropTypes.string,
}

CommunityCardBanner.defaultProps = {
  src: `${process.env.PUBLIC_URL}/img/generic_rt_cover.jpg`,
}

export const CommunityCardAvatar = styled(Avatar)`
  background-color: #f7f7f8;
  left: 50%;
  position: absolute;
  top: 30px;
  transform: translateX(-50%);
  transition: 0.15s ease-in-out;

  ${CommunityCardBase}:focus &,
  ${CommunityCardBase}:hover & {
    transform: translateX(-50%) scale(1.05);
  }
`

export const CommunityCardContent = styled.div`
  min-height: 100px;
  padding: 40px 10px 10px;
`

export const CommunityCardName = styled.p`
  color: #e0e0e0;
  font-size: ${rem(18, 15)};
  font-weight: 600;
  margin: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CommunityCardMembers = styled.p`
  color: #bbb;
  margin: 0;
`
