import React from 'react'
import { Transition } from 'react-transition-group'

import useDynamicTextSize from 'common/hooks/useDynamicTextSize'

const BigSidewaysUsername = ({ loaded, username }) => {
  const { displaySpan, referenceSpan } = useDynamicTextSize()

  return (
    <Transition appear in={loaded} timeout={500} unmountOnExit>
      {state => (
        <div
          className={`my-hub__big-sideways-username-container my-hub__big-sideways-username-container--${state}`}
        >
          <span
            className='my-hub__big-sideways-username my-hub__big-sideways-username--dom-reference'
            ref={referenceSpan}
          >
            {username}
          </span>
          <span ref={displaySpan} className='my-hub__big-sideways-username'>
            {username}
          </span>
        </div>
      )}
    </Transition>
  )
}
export default BigSidewaysUsername
