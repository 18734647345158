import { RECEIVE_HUB_DATA, SET_SHOW_LOADING_SCREEN } from 'myHubApp/types'

const initialState = { feeds: {}, showLoadingScreen: false }

const myHubReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_LOADING_SCREEN: {
      const { showLoadingScreen } = action
      return {
        ...state,
        showLoadingScreen,
      }
    }
    case RECEIVE_HUB_DATA: {
      const { feed, feedType } = action
      return {
        ...state,
        feeds: {
          ...state.feeds,
          [feedType]: feed,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default myHubReducer
