const initialState = {
  checkingSlugValidity: false,
  checkingNameValidity: false,
  slugIsUnique: null,
  nameIsUnique: null,
  errors: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'modalApp/HIDE_MODAL':
      return initialState
    case 'communityApp/CREATE_RESP':
      return { ...state, ...action.resp.data }
    case 'communityApp/IS_UNIQUE_REQ':
      return {
        ...state,
        checkingSlugValidity: true,
      }
    case 'communityApp/IS_UNIQUE_RESP':
      return {
        ...state,
        checkingSlugValidity: false,
        slugIsUnique: action.isUnique,
      }
    case 'communityApp/IS_NAME_UNIQUE_REQ':
      return {
        ...state,
        checkingNameValidity: true,
      }
    case 'communityApp/IS_NAME_UNIQUE_RESP':
      return {
        ...state,
        checkingNameValidity: false,
        nameIsUnique: action.isUnique,
      }
    case 'communityApp/CREATE_RESP_ERR':
      return {
        ...state,
        errors: action.errors,
      }
    case 'communityApp/CREATE_RESP_ERR_UNKNOWN':
      return {
        ...state,
        errors: ['unknown'],
      }
    default:
      return state
  }
}
