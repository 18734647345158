import { getSubjectPostId } from 'common/helpers/post'
import { StickyPrompt, DeletePrompt } from 'communityApp/components'
import { showModal } from 'modalApp/actions'

/**
 * Defines static actions to be used for PostActions
 * text - can be a String or a function that accepts the components `props`
 * action - function that accepts `props` and does thing
 * @note most of these are stubbed for the time being
 */
// ------
// Methods
// ------
const DELETE_POST = {
  text: 'Delete Post',
  className: 'delete-button',
  action: ({ post, dispatch }) => {
    dispatch(
      showModal('COMPONENT_MODAL', {
        component: DeletePrompt,
        post,
        className: 'modal-overlay modal-prompt modal-mobile-page',
      })
    )
  },
}

const FLAG_POST_AS_ABUSE = {
  text: 'Flag as Abuse',
  className: 'flag-abuse-button',
  action: ({ post, dispatch }) => {
    dispatch({
      label: 'abuse',
      postId: getSubjectPostId(post),
      type: 'communityApp/g/post/flag',
    })
  },
}

const FLAG_POST_AS_SPAM = {
  text: 'Flag as Spam',
  className: 'flag-spam-button',
  action: ({ post, dispatch }) => {
    dispatch({
      label: 'spam',
      postId: getSubjectPostId(post),
      type: 'communityApp/g/post/flag',
    })
  },
}

const STICKY_POST = {
  text: 'Pin Post',
  className: 'pin-button',
  action: ({ dispatch, post }) => {
    const community = post.owner.data
    // prompt the user to override sticky
    if (community.stickied_post_id) {
      return dispatch(
        showModal('COMPONENT_MODAL', {
          component: StickyPrompt,
          post,
          className: 'modal-overlay modal-prompt modal-mobile-page',
        })
      )
    }
    dispatch({
      type: 'communityApp/subCommunity/pin',
      communityId: community.id,
      postToPinId: post.id,
    })
  },
}

// TODO: unpin

// ------
// Actions
// ------
export const POST_USER_OWNER_ACTIONS = [
  // edit post
  {
    text: 'Edit Post',
    className: 'edit-button',
    action: ({ dispatch, post }) => {
      const { owner } = post
      let topicId =
        owner.type.toLowerCase() === 'community' ? owner.data.id : ''
      dispatch(
        showModal('CREATE_POST', {
          topicId,
          submitCopy: 'Update',
          editing: true,
          post,
        })
      )
    },
  },
  // delete / restore post
  DELETE_POST,
]

export const POST_FLAG_ACTIONS = [FLAG_POST_AS_ABUSE, FLAG_POST_AS_SPAM]

export const POST_USER_ACTIONS = [
  // mute / unmute post
  // mute / unmute member
  // follow member
  // unfollow member
  // delete / restore post
  DELETE_POST,
]

export const POST_USER_ADMIN_ACTIONS = [
  // mute post
  // mute member
  // remove post / restore
  DELETE_POST,
  STICKY_POST,
]

export const POST_COMMUNITY_ACTIONS = [
  //
]

export const POST_COMMUNITY_MODERATOR_ACTIONS = [
  // mute post
  // mute member
  // remove post / restore
  DELETE_POST,
  // sticky post
  STICKY_POST,
]

export const POST_COMMUNITY_ADMIN_ACTIONS = [
  // mute post
  // mute member
  // remove post / restore
  DELETE_POST,
  // sticky post
  STICKY_POST,
]

export const POST_MERCH_ACTIONS = []

export const POST_VIDEO_ACTIONS = []

const POST_ACTIONS = {
  POST_FLAG_ACTIONS,
  POST_USER_OWNER_ACTIONS,
  POST_USER_ADMIN_ACTIONS,
  POST_COMMUNITY_ACTIONS,
  POST_COMMUNITY_MODERATOR_ACTIONS,
  POST_COMMUNITY_ADMIN_ACTIONS,
  POST_MERCH_ACTIONS,
  POST_VIDEO_ACTIONS,
}

export default POST_ACTIONS
