import React, { useCallback } from 'react'

import { EpisodeCard } from 'common/components'
import { trackSearchSelection } from 'common/metrics'

import getAnalyticsTabName from '../../helpers/getAnalyticsTabName'

const WrappedEpisodeCard = ({
  closeSearchV2,
  episode,
  isBonusFeature,
  searchTerm,
  tab,
}) => {
  const trackClickAndClose = useCallback(
    () => {
      closeSearchV2()
      trackSearchSelection({
        query: searchTerm,
        tab: getAnalyticsTabName(tab),
        type: isBonusFeature ? 'bonus_features' : 'episodes',
        uuid: episode.attributes?.uuid || episode.uuid || episode.id,
      })
    },
    [
      closeSearchV2,
      episode.attributes?.uuid,
      episode.id,
      episode.uuid,
      isBonusFeature,
      searchTerm,
      tab,
    ]
  )

  return (
    <EpisodeCard
      item={episode}
      onThumbnailClick={trackClickAndClose}
      onTitleClick={trackClickAndClose}
      width='500'
    />
  )
}

export default WrappedEpisodeCard
