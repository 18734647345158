let submitOnEnterDefault = window.localStorage.getItem('submitOnEnter')
if (submitOnEnterDefault === 'true') {
  submitOnEnterDefault = true
} else if (
  submitOnEnterDefault === 'false' ||
  typeof submitOnEnterDefault !== 'boolean'
) {
  submitOnEnterDefault = false
}

const initialState = {
  submitOnEnter: submitOnEnterDefault,
}

export default (state = initialState, { type, settingName, settingValue }) => {
  switch (type) {
    case 'c/set_setting_RESP':
      return { ...state, [settingName]: settingValue }
    default:
      return state
  }
}
