import React, { Component } from 'react'
import { compose } from '@reduxjs/toolkit'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import URLSearchParams from 'url-search-params'

import { ExternalLink, Preloader, PrimaryBtn } from 'common/components'
import { withDateBasedFeatureFlag } from 'common/helpers'
import { trackClick } from 'common/metrics'
import scssVariables from 'common/styles/variables.scss'

import { showModal } from 'modalApp/actions'

import background from './rwby-promo-bg.jpg'

import './styles.scss'

class FirstPaywall extends Component {
  static defaultProps = {
    openInNewTab: false,
  }

  getRedirectHref = ({ path, upgrade = false }) => {
    const { location } = this.props

    const params = new URLSearchParams()

    params.append('redirect_url', location.pathname)

    if (upgrade) {
      params.append('upgrade', true)
    }

    return `${path}?${params.toString()}`
  }

  getLoginHref = () => this.getRedirectHref({ path: '/login' })

  getSignupHref = () => {
    const { isAuthenticated } = this.props

    return isAuthenticated
      ? this.getRedirectHref({ path: '/signup/select', upgrade: true })
      : this.getRedirectHref({ path: '/signup' })
  }

  getUnpauseHref = () =>
    this.getRedirectHref({
      path: '/settings/account/billing/change-plan/unpause',
    })

  handleLoginClick = e => {
    e.preventDefault()

    this.props.showModal('USER_LOGIN')
  }

  handleSignup = () => {
    trackClick('episode_page_paywall_upsell')
  }

  isPaused = () => {
    const { user } = this.props

    return Boolean(
      user?.attributes?.sponsorship_details?.subscription_paused_at
    )
  }

  isPreloaded = () => {
    const { isAuthenticated, user } = this.props

    return Boolean(!isAuthenticated || (isAuthenticated && user))
  }

  render() {
    const {
      featureEnabled: shouldHideFirstButton,
      isAuthenticated,
      openInNewTab,
    } = this.props

    return (
      <div className='paywall-first'>
        <img alt='' className='paywall-bg' src={background} />
        <div className='paywall-overlay' />
        <div className='paywall-content'>
          <Preloader color={scssVariables.blue} condition={this.isPreloaded()}>
            <h5 className='paywall-title'>
              {this.isPaused()
                ? 'Resume your FIRST subscription to get access!'
                : 'This content was made through the support of our FIRST Members!'}
            </h5>
            {!shouldHideFirstButton && (
              <p className='paywall-subtext'>
                Support us with a FIRST Membership to gain access to this
                Exclusive Content.
              </p>
            )}
            {this.isPaused() ? (
              <PrimaryBtn
                external={openInNewTab}
                linkTo={this.getUnpauseHref()}
              >
                Unpause Subscription
              </PrimaryBtn>
            ) : (
              !shouldHideFirstButton && (
                <PrimaryBtn
                  className={classnames('trk-paywall-first-trial', {
                    'cy-submit': !openInNewTab,
                  })}
                  external={openInNewTab}
                  linkTo={this.getSignupHref()}
                  onClick={this.handleSignup}
                >
                  Join FIRST
                </PrimaryBtn>
              )
            )}

            {!isAuthenticated && (
              <span className='paywall-login'>
                Already a FIRST member?{' '}
                {openInNewTab ? (
                  <ExternalLink
                    className='paywall-login__link'
                    href={this.getLoginHref()}
                  >
                    Log In
                  </ExternalLink>
                ) : (
                  <Link
                    className='paywall-login__link'
                    onClick={this.handleLoginClick}
                    to={this.getLoginHref()}
                  >
                    Log In
                  </Link>
                )}
              </span>
            )}
          </Preloader>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, user } = state.authReducer
  const { location } = state.router

  return {
    isAuthenticated,
    location,
    user,
  }
}

const mapDispatchToProps = {
  showModal,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withDateBasedFeatureFlag('disableFirstUpgradesDate')
)(FirstPaywall)
