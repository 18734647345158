//
// This file is shared by the node server
//
import deepFreeze from 'deep-freeze'
import isEmpty from 'lodash.isempty'

let rtConfig = {}

// window?
try {
  rtConfig = window.rtConfig
} catch (e) {
  // console.error('No window object', e)
}

// fallback to node process
if (isEmpty(rtConfig) && process.env) {
  rtConfig = process.env
}

// absence of a value or falsy value implies false
// presence of a value implies true unless value is explicitly false
const truthy = env => env && env !== 'false'
// defaults to ON unless explicitly off
const defaultToTrue = val => val !== 'false' && val !== false

export const endpoints = Object.freeze({
  airtable: rtConfig.REACT_APP_AIRTABLE_SERVER,
  airtableCancelSurvey: rtConfig.REACT_APP_AIRTABLE_CANCEL_SURVEY,
  api: rtConfig.REACT_APP_API_SERVER,
  auth: rtConfig.REACT_APP_AUTH_SERVER,
  business: rtConfig.REACT_APP_BUSINESS_SERVER,
  chat: rtConfig.REACT_APP_CHAT_SERVER,
  comments: rtConfig.REACT_APP_COMMENTS_SERVER,
  communityBase: rtConfig.REACT_APP_COMMUNITY_BASE,
  communityImageAPI: rtConfig.REACT_APP_COMMUNITY_IMAGE_API,
  communityImageCDN: rtConfig.REACT_APP_COMMUNITY_IMAGE_CDN,
  communityImageCDNBucket: rtConfig.REACT_APP_COMMUNITY_IMAGE_CDN_BUCKET,
  compliance: rtConfig.REACT_APP_COMPLIANCE_SERVER,
  imageUpload: rtConfig.REACT_APP_IMAGE_UPLOAD_BASE,
  lambda: rtConfig.REACT_APP_LAMBDA_SERVER,
  lists: rtConfig.REACT_APP_LIST_SERVER,
  notification: rtConfig.REACT_APP_NOTIFICATION_API,
  notify: rtConfig.REACT_APP_NOTIFY_API,
  profile: rtConfig.REACT_APP_PROFILE_SERVER,
  rating: rtConfig.REACT_APP_RATING_SERVER,
  socket: rtConfig.REACT_APP_NOTIFY_SOCKET,
  wtc: rtConfig.REACT_APP_WTC_SERVER,
})

export const config = deepFreeze({
  airtable: {
    publicKey: rtConfig.REACT_APP_AIRTABLE_PUBLIC_KEY,
  },
  brightcove: {
    accountId: rtConfig.REACT_APP_BRIGHTCOVE_ACCOUNT_ID,
    playerId: rtConfig.REACT_APP_BRIGHTCOVE_PLAYER_ID,
    adConfigId: rtConfig.REACT_APP_BRIGHTCOVE_AD_CONFIG_ID,
  },
  freewheel: {
    networkId: rtConfig.REACT_APP_FREEWHEEL_NETWORK_ID,
    prof: rtConfig.REACT_APP_FREEWHEEL_PROF,
    server: rtConfig.REACT_APP_FREEWHEEL_SERVER,
    siteSectionId: rtConfig.REACT_APP_FREEWHEEL_SITE_SECTION_ID,
    videoAssetFallbackId: rtConfig.REACT_APP_FREEWHEEL_VIDEO_ASSET_FALLBACK_ID,
  },
  growthBook: {
    featuresEndpoint: rtConfig.REACT_APP_GROWTHBOOK_FEATURES_ENDPOINT,
  },
  apiEnv: rtConfig.REACT_APP_API_ENV,
  auth: {
    clientId: rtConfig.REACT_APP_AUTH_CLIENT_ID,
    appleClientId: rtConfig.REACT_APP_APPLE_CLIENT_ID,
    appleRedirect: rtConfig.REACT_APP_APPLE_REDIRECT,
    googleClientId: rtConfig.REACT_APP_GOOGLE_CLIENT_ID,
    googleSiteKey: rtConfig.REACT_APP_GOOGLE_SITEKEY,
    accessTokenKey: rtConfig.REACT_APP_ACCESS_TOKEN_KEY,
    refreshTokenKey: rtConfig.REACT_APP_REFRESH_TOKEN_KEY,
  },
  metrics: {
    brazeApiKey: rtConfig.REACT_APP_BRAZE_API_KEY,
    env: rtConfig.REACT_APP_REPORT_ENV,
    googleContainer: rtConfig.REACT_APP_GTM_CONTAINER_KEY,
    gtmAccount: rtConfig.REACT_APP_GTM_ACCOUNT_KEY,
    segmentLink: rtConfig.REACT_APP_SEGMENT_LINK,
    send: defaultToTrue(rtConfig.REACT_APP_SEND_METRICS),
    showWhyDidYouUpdate: truthy(rtConfig.REACT_APP_WHY_UPDATE),
  },
  playlistIds: {
    onboardingFree: rtConfig.REACT_APP_PLAYLIST_ID_ONBOARDING_FREE,
    onboardingPremium: rtConfig.REACT_APP_PLAYLIST_ID_ONBOARDING_PREMIUM,
  },
  chat: {
    apiKey: rtConfig.REACT_APP_CHAT_API_KEY,
  },
  recurly: {
    publicKey: rtConfig.REACT_APP_RECURLY_PUBLIC_KEY,
  },
  discord: {
    redirectUri: rtConfig.REACT_APP_DISCORD_REDIRECT_URI,
    clientId: rtConfig.REACT_APP_DISCORD_CLIENT_ID,
    anonInviteLink: rtConfig.REACT_APP_ANON_INVITE_LINK,
  },
  // / ////
  // DO NOT DIRECTLY IMPORT THESE, use the reducer instead
  // / ////
  ff: {
    channelV2: truthy(rtConfig.REACT_APP_FF_CHANNEL_V2),
    comments: truthy(rtConfig.REACT_APP_FF_COMMENTS),
    directChat: truthy(rtConfig.REACT_APP_FF_DIRECT_CHAT),
    disableFirstUpgradesDate: rtConfig.REACT_APP_FF_DISABLE_FIRST_UPGRADES_DATE,
    feeds: truthy(rtConfig.REACT_APP_FF_FEEDS),
    holidayEasterEgg: truthy(rtConfig.REACT_APP_FF_HOLIDAY_EASTER_EGG),
    newCommunity: truthy(rtConfig.REACT_APP_FF_NEW_COMMUNITY),
    newMobileNav: truthy(rtConfig.REACT_APP_FF_NEW_MOBILE_NAV),
    prideMonth: truthy(rtConfig.REACT_APP_FF_PRIDE_MONTH),
    rtxCountdown: truthy(rtConfig.REACT_APP_FF_RTX_COUNTDOWN),
    rtx23CharactersEasterEgg: truthy(
      rtConfig.REACT_APP_RTX_23_CHARACTERS_EASTER_EGG
    ),
    showsV2: truthy(rtConfig.REACT_APP_FF_SHOWS_V2),
    stinkyDragonButton: truthy(rtConfig.REACT_APP_FF_STINKY_DRAGON_BUTTON),
    supportUkraineButton: truthy(rtConfig.REACT_APP_FF_SUPPORT_UKRAINE_BUTTON),
    unoInfiniteButton: truthy(rtConfig.REACT_APP_FF_UNO_INFINITE_BUTTON),
    valentinesHearts: truthy(rtConfig.REACT_APP_FF_VALENTINES_HEARTS),
    womensHistoryMonth: truthy(rtConfig.REACT_APP_FF_WOMENS_HISTORY_MONTH),
  },
  notify: {
    apiKey: rtConfig.REACT_APP_FIREBASE_WEB_API_KEY,
    appId: rtConfig.REACT_APP_FIREBASE_APP_ID,
    projectId: rtConfig.REACT_APP_FIREBASE_PROJECT_ID,
    senderId: rtConfig.REACT_APP_FIREBASE_SENDER_ID,
  },
  dates: {
    rtx: rtConfig.REACT_APP_RTX_DATE,
  },
})

export const accessTokenKey = config.auth.accessTokenKey
export const refreshTokenKey = config.auth.refreshTokenKey
