import React from 'react'
import PropTypes from 'prop-types'

import { priceInDollars } from 'common/helpers'

import './styles.scss'

export function PlanPrice(props) {
  const {
    hasInterval,
    plan: { amount_in_cents, plan_interval_length, plan_interval_unit },
  } = props

  if (hasInterval) {
    if (plan_interval_length === 1 && plan_interval_unit === 'months') {
      return (
        <span className='plan-price qa-plan-price'>
          ${priceInDollars(amount_in_cents)}/mo
        </span>
      )
    }
    return (
      <span className='plan-price qa-plan-price'>
        ${priceInDollars(amount_in_cents)}{' '}
        <span className='plan-price__breakdown'>
          (${priceInDollars(amount_in_cents, plan_interval_length)} x{' '}
          {plan_interval_length} {plan_interval_unit})
        </span>
      </span>
    )
  }
  return (
    <span className='plan-price qa-plan-price'>
      ${priceInDollars(amount_in_cents)}
    </span>
  )
}

PlanPrice.propTypes = {
  hasInterval: PropTypes.bool,
  plan: PropTypes.object,
}

PlanPrice.defaultProps = {
  hasInterval: false,
  plan: {},
}

export default PlanPrice
