import React from 'react'
import { Transition } from 'react-transition-group'

const MobileMainWelcome = ({ loaded, username }) => (
  <>
    <Transition appear in={loaded} timeout={200} unmountOnExit>
      {state => (
        <div
          className={`my-hub__mobile-main-welcome my-hub__mobile-main-welcome--${state}`}
        >
          Welcome
        </div>
      )}
    </Transition>
    <Transition appear in={loaded} timeout={300} unmountOnExit>
      {state => (
        <div
          className={`my-hub__mobile-main-username my-hub__mobile-main-username--${state}`}
        >
          {username}
        </div>
      )}
    </Transition>
  </>
)

export default MobileMainWelcome
