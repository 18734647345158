import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { copyToClipboard, getDomain } from 'common/helpers'

import ShareItem from './ShareItem'

class EmbedShare extends Component {
  static propTypes = {
    href: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['bonus_feature', 'episode', 'post']).isRequired,
  }

  getEmbedHref = () => {
    const { href, slug, type } = this.props
    const domain = getDomain(href, true)
    const protocol = window.location.protocol
    let path

    switch (type) {
      case 'bonus_feature':
        path = 'embed-bonus'
        break
      case 'post':
        path = 'embed-post'
        break
      default:
        path = 'embed'
        break
    }

    return `${protocol}//${domain}/${path}/${slug}`
  }

  getEmbedSnippet = () =>
    `<iframe width="560" height="315" src="${this.getEmbedHref()}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`

  handleClick = () => {
    copyToClipboard(this.getEmbedSnippet())
  }

  render() {
    return (
      <ShareItem
        clickedMessage='Copied to clipboard'
        hoveredMessage='Copy embed code'
        href={this.getEmbedHref()}
        icon='code'
        onClick={this.handleClick}
      />
    )
  }
}

export default EmbedShare
