import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useIsNonIndexablePostPage = () => {
  const posts = useSelector(state => state.commonReducer.posts)
  const isCommunityPostPage = () =>
    window.location.pathname.includes('/g/post/')

  const isStaffPost = useMemo(
    () => {
      const url = window.location.pathname
      const segments = url.split('/')
      const postId = segments[segments.length - 1]
      const post = posts[postId]
      return post?.author?.badges?.includes('Staff')
    },
    [posts]
  )
  const shouldNotIndexPage = isCommunityPostPage() && !isStaffPost
  return shouldNotIndexPage
}

export default useIsNonIndexablePostPage
