export const getPageIndices = (totalItems, perPage) => {
  if (totalItems === 0 || perPage === 0) return []
  if (totalItems <= perPage) return [0]

  const finalPageOffset = totalItems - perPage
  const indices = []
  for (let index = 0; index < finalPageOffset; index += 1) {
    if (index % perPage === 0) indices.push(index)
  }
  indices.push(finalPageOffset)
  return indices
}

export const prevPage = (pageIndices, currentOffset) => {
  for (const index of [...pageIndices].reverse()) {
    if (index < currentOffset) return index
  }
  return currentOffset
}

export const nextPage = (pageIndices, currentOffset) => {
  for (const index of pageIndices) {
    if (index > currentOffset) return index
  }
  return currentOffset
}

export const getDisplayedItemsWithIndices = ({
  animationStartOffset,
  currentOffset,
  teasedItemCount,
  items,
  perPage,
}) => {
  const minIndexToDisplay = Math.max(
    0,
    Math.min(animationStartOffset, currentOffset) - teasedItemCount
  )

  const maxIndexToDisplay = Math.min(
    items.length,
    Math.max(animationStartOffset, currentOffset) +
      perPage +
      teasedItemCount -
      1
  )

  const displayedItemsWithIndices = []
  let index = 0
  for (const item of items) {
    if (index <= maxIndexToDisplay && index >= minIndexToDisplay) {
      displayedItemsWithIndices.push([index, item])
    }
    index += 1
  }
  return displayedItemsWithIndices
}

// When given a current offset and a new list of page offsets, choose the ideal new page offset.
export const getNearestPageFromOffset = (currentOffset, pageOffsets) => {
  // Page offset is valid, return.
  if (pageOffsets.includes(currentOffset)) return currentOffset

  // Iterate through allowed page offsets
  for (let index = 0; index < pageOffsets.length; index += 1) {
    const pageOffset = pageOffsets[index]
    // Encountered a pageOffset greater than the one we had with the previous `perPage`. For example, when changing from `perPage` of 4 (while on offset 8) to `perPage` of 3, we would run into offset 9, which is greater than our offset of 8. So we round down to the most recent offset divisible by the new `perPage` of 3, which is 6.
    if (pageOffset > currentOffset && index > 0) return pageOffsets[index - 1]
  }

  // If we never encountered an offset as high as our current offset, just return the last page offset.
  return pageOffsets[pageOffsets.length - 1]
}
