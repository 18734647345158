// Helpers for traversing JSON APIs.

export const getIncludedEntity = (jsonApiBase, entityType, entityId) =>
  jsonApiBase.included.find(
    entity => entity.type === entityType && entity.id === entityId
  )

export const getRelationship = (
  entity,
  relationshipName,
  entityType,
  jsonApiBase
) => {
  const relationship = entity.relationships[relationshipName]

  if (!relationship) return

  if (Array.isArray(relationship.data)) {
    return relationship.data.map(({ id: entityId }) =>
      getIncludedEntity(jsonApiBase, entityType, entityId)
    )
  }

  const entityId = relationship.data.id
  return getIncludedEntity(jsonApiBase, entityType, entityId)
}
