import { objectsByKey, priceInDollars } from 'common/helpers'

import {
  CLEAR_CANCEL_OFFERS,
  CLEAR_GIFT_CARD,
  CLEAR_PREVIEW,
  CLEAR_SELECTED_PLAN,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_AVAILABLE_PLANS_FAILURE,
  GET_AVAILABLE_PLANS_REQUEST,
  GET_AVAILABLE_PLANS_SUCCESS,
  GET_CANCEL_OFFERS_SUCCESS,
  GET_PLANS_FAILURE,
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_REDEEMED_GIFT_CARDS_FAILURE,
  GET_REDEEMED_GIFT_CARDS_REQUEST,
  GET_REDEEMED_GIFT_CARDS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  POST_CANCELLATION_SURVEY_FAILURE,
  POST_CANCELLATION_SURVEY_SUCCESS,
  POST_PREVIEW_FAILURE,
  POST_PREVIEW_SUCCESS,
  POST_SUBSCRIPTION_FAILURE,
  POST_SUBSCRIPTION_SUCCESS,
  PUT_CANCEL_PENDING_FAILURE,
  PUT_CANCEL_PENDING_REQUEST,
  PUT_CANCEL_PENDING_SUCCESS,
  PUT_PAUSE_SUBSCRIPTION_FAILURE,
  PUT_PAUSE_SUBSCRIPTION_REQUEST,
  PUT_PAUSE_SUBSCRIPTION_SUCCESS,
  PUT_REDEEM_GIFT_CARD_FAILURE,
  PUT_REDEEM_GIFT_CARD_REQUEST,
  PUT_REDEEM_GIFT_CARD_SUCCESS,
  PUT_SUBSCRIPTION_FAILURE,
  PUT_SUBSCRIPTION_REQUEST,
  PUT_SUBSCRIPTION_SUCCESS,
  RESET_POST_PREVIEW_FAILURE,
  SELECT_PLAN_CHANGE,
} from '../actions/types'

export const initialState = {
  account: null,
  errorMessage: null,
  isFetching: false,
  redeemedGiftCards: null,
  subscriptions: null,
  transactions: null,
  checkout: {
    selectedPlan: null,
  },
  preview: null,
  giftCard: null,
  offers: null,
  plans: {
    data: [],
    byCode: null,
    isFetching: false,
    lastUpdated: null,
  },
  availablePlans: null,
  decoratedPlans: null,
}

const getActivePlans = plans => plans.filter(plan => plan.active)

const getGroupedPlans = plans => {
  let groupedPlans = {
    monthly: plans.find(plan => plan.plan_code === '1month'),
    sixMonthly: plans.find(plan => plan.plan_code === '6month'),
    yearly: plans.find(
      // Mismatch between Business and Recurly capitalization
      plan => plan.plan_code === '1Year' || plan.plan_code === '1year'
    ),
    yearlyPlus: plans.find(plan => plan.plan_code === '1year_plus'),
  }

  const daily = plans.find(plan => plan?.plan_code === '1day')

  if (daily) {
    groupedPlans = { daily, ...groupedPlans }
  }

  return groupedPlans
}

const getDecoratedPlans = plans => {
  const { daily, monthly, sixMonthly, yearly, yearlyPlus } = getGroupedPlans(
    plans
  )

  const decoratedMonthly = {
    ...monthly,
    displayName: 'Monthly',
    interval: 'mo',
    price: priceInDollars(monthly.amount_in_cents),
  }

  const decoratedSixMonthly = {
    ...sixMonthly,
    displayName: '6 Months',
    interval: '6-mo',
    price: priceInDollars(sixMonthly.amount_in_cents),
    pricePerMonth: priceInDollars(Math.floor(sixMonthly.amount_in_cents / 6)),
  }

  const decoratedYearly = {
    ...yearly,
    displayName: 'Annual',
    interval: 'yr',
    price: priceInDollars(yearly.amount_in_cents),
    pricePerMonth: priceInDollars(Math.floor(yearly.amount_in_cents / 12)),
  }

  const decoratedYearlyPlus = {
    ...yearlyPlus,
    displayName: 'FIRST+',
    interval: 'yr',
    price: priceInDollars(yearlyPlus.amount_in_cents),
  }

  let decoratedPlans = {
    monthly: decoratedMonthly,
    sixMonthly: decoratedSixMonthly,
    yearly: decoratedYearly,
    yearlyPlus: decoratedYearlyPlus,
  }

  if (daily) {
    decoratedPlans = {
      daily: {
        ...daily,
        displayName: 'Daily',
        interval: 'day',
        price: priceInDollars(daily.amount_in_cents),
      },
      ...decoratedPlans,
    }
  }

  return decoratedPlans
}

const getSortedPlans = plans => {
  const first = plans.filter(plan => plan.plan_type === 'first_member')

  const sortedFirst = first.sort(
    (a, b) => a.plan_interval_length - b.plan_interval_length
  )

  return [...sortedFirst]
}

const getSortedSubscriptions = subscriptions => {
  const ifState = state => subscription => subscription.state === state

  const active = subscriptions.filter(ifState('active'))
  const canceled = subscriptions.filter(ifState('canceled'))
  const paused = subscriptions.filter(ifState('paused'))

  return {
    active,
    canceled,
    paused,
    recentActive: [...active].pop(),
    recentCanceled: [...canceled].pop(),
    recentPaused: [...paused].pop(),
  }
}

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CANCEL_OFFERS:
      return {
        ...state,
        offers: null,
      }
    case CLEAR_GIFT_CARD:
      return {
        ...state,
        giftCard: null,
      }
    case CLEAR_PREVIEW:
      return {
        ...state,
        preview: null,
      }
    case CLEAR_SELECTED_PLAN:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          selectedPlan: null,
        },
      }
    case GET_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        account: action.account,
      }
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        account: null,
        isFetching: false,
        errorMessage: action.message,
      }
    case GET_AVAILABLE_PLANS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_AVAILABLE_PLANS_SUCCESS:
      return {
        ...state,
        availablePlans: getSortedPlans(getActivePlans(action.plans)),
        isFetching: false,
      }
    case GET_AVAILABLE_PLANS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case GET_CANCEL_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.offers,
      }
    case GET_PLANS_REQUEST:
      return {
        ...state,
        plans: {
          ...state.plans,
        },
      }
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        decoratedPlans: getDecoratedPlans(
          getSortedPlans(getActivePlans(action.plans))
        ),
        plans: {
          byCode: objectsByKey(action.plans, 'plan_code'),
          data: action.plans,
          lastUpdated: Date.now(),
        },
      }
    case GET_PLANS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        plans: {
          ...state.plans,
        },
      }
    case GET_REDEEMED_GIFT_CARDS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_REDEEMED_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        redeemedGiftCards: action.redeemedGiftCards,
      }
    case GET_REDEEMED_GIFT_CARDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        redeemedGiftCards: null,
      }
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: getSortedSubscriptions(action.subscriptions),
        isFetching: false,
      }
    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        transactions: null, // Never display old transactions
      }
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isFetching: false,
        transactions: action.transactions,
      }
    case GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case POST_CANCELLATION_SURVEY_SUCCESS:
      return {
        ...state,
        errorMessage: null,
      }
    case POST_CANCELLATION_SURVEY_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
      }
    case POST_PREVIEW_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isFetching: false,
        preview: action.preview,
      }
    case POST_PREVIEW_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case RESET_POST_PREVIEW_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: null,
      }
    case POST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        account: action.account,
        isFetching: false,
      }
    case POST_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
      }
    case PUT_CANCEL_PENDING_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_CANCEL_PENDING_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isFetching: false,
      }
    case PUT_CANCEL_PENDING_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case PUT_REDEEM_GIFT_CARD_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_REDEEM_GIFT_CARD_SUCCESS:
      return {
        ...state,
        giftCard: action.giftCard,
        isFetching: false,
      }
    case PUT_REDEEM_GIFT_CARD_FAILURE:
      return {
        ...state,
        giftCard: null,
        isFetching: false,
      }
    case PUT_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isFetching: false,
      }
    case PUT_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case PUT_PAUSE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PUT_PAUSE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isFetching: false,
        subscriptions: getSortedSubscriptions(
          [...state.subscriptions.active, ...state.subscriptions.canceled].map(
            subscription => {
              if (subscription.uuid === action.subscription.uuid) {
                return action.subscription
              }

              return subscription
            }
          )
        ),
      }
    case PUT_PAUSE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        errorMessage: action.message,
        isFetching: false,
      }
    case SELECT_PLAN_CHANGE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          selectedPlan: action.plan,
        },
      }
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'modalApp/HIDE_ERROR':
      return {
        ...state,
        errorMessage: '',
      }
    case 'modalApp/SHOW_ERROR':
      if (action.reducer === 'subscriptions') {
        return {
          ...state,
          errorMessage: action.message,
        }
      }

      return state
    default:
      return state
  }
}

export default subscriptions
