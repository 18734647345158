import React, { Component, Fragment, Suspense } from 'react'
import rtApi from '@roosterteethproductions/svod-api'
import classnames from 'classnames'
import { CloudinaryContext } from 'cloudinary-react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector/build/withPolyfill'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { push } from 'redux-first-history'
import { ThemeProvider } from 'styled-components'

import {
  fetchFollowedShows,
  fetchLivestreamSchedule,
  fetchLivestreamShows,
  fetchWatchLaterItems,
} from 'common/actions'
import { AsyncComponent, DetectScrollbars } from 'common/components'
import ErrorBoundary from 'common/components/ErrorBoundary'
import { accessTokenKey, config, refreshTokenKey } from 'common/config'
import { PostDetailsContainer } from 'common/containers'
import SEOTags from 'common/containers/SEOTags'
import PageMetaTags from 'common/containers/SEOTags/PageMetaTags'
import SmartAppBanner from 'common/containers/SmartAppBanner'
// import VideoContainer from 'common/containers/VideoContainer'
import {
  deleteCookie,
  displaySmartAppBanner,
  getAccessToken,
  getCookie,
  getPlatform,
  pageTitle,
  setAccessTokenCookie,
  setRefreshTokenCookie,
} from 'common/helpers'
import { bindBrowserSearchHandlers } from 'common/helpers/browser-search-handlers'
import { ProvideSocket } from 'common/hooks/useSocket'
import * as metrics from 'common/metrics'

import { getUserObject, refreshToken } from 'auth/actions'
import { shouldWaitForUserObject } from 'auth/selectors'
import { fetchBlockedUsers, fetchMutedUsers } from 'chatApp/actions'
import EmbeddableVideo from 'episodeApp/containers/embeddable'
import MyHubContainer from 'myHubApp/containers/MyHubContainer'
import {
  closeMobileNav,
  fetchBadges,
  fetchChannels,
  openMobileNav,
  openSearchV2,
} from 'rootApp/actions'
import EasterEgg from 'rootApp/components/EasterEgg'
import ExternalScripts from 'rootApp/components/ExternalScripts'
import { FallingParticlesEasterEggContainer } from 'rootApp/components/FallingParticlesEasterEgg'
import FunnelNav from 'rootApp/components/FunnelNav'
import MobileBottomNav from 'rootApp/components/MobileBottomNav'
import MobileOverlayNav from 'rootApp/components/MobileOverlayNav'
import MobileTopNav from 'rootApp/components/MobileTopNav'
import SideNav from 'rootApp/components/SideNav'
import SiteVersionIndicator from 'rootApp/components/SiteVersionIndicator'
import TopNav from 'rootApp/components/TopNav'
import { getIsMobileNavOpen, getIsSearchV2Open } from 'rootApp/selectors'

import BlurredBackgroundImageContainer from '../BlurredBackgroundImageContainer'
import SearchV2Outer from '../SearchV2/SearchV2Outer'
import { WebPushContainer } from '../WebPushContainer'

import 'common/styles/index'
import './styles.scss'

const MarketingContainer = React.lazy(() =>
  import('marketingApp/containers/App')
)

// eslint-disable-next-line import/extensions
const ScrollToTop = React.lazy(() => import('/common/containers/ScrollToTop'))

const ModalApp = AsyncComponent({
  loader: () => import('modalApp/containers/App'),
})
let RtRoutes
if (
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'staging'
) {
  RtRoutes = require('routes.prod').default
} else {
  RtRoutes = require('routes.dev').default
}

const AsyncActivate = AsyncComponent({
  loader: () => import('activateApp/containers'),
})

const AsyncRedeem = AsyncComponent({
  loader: () => import('redeemApp/containers/App'),
})

const AsyncSignup = AsyncComponent({
  loader: () => import('signupApp/containers/App'),
})

const AsyncYoutubeAuth = AsyncComponent({
  loader: () => import('auth/containers/YouTubeAuthContainer'),
})

const AsyncUnsubscribe = AsyncComponent({
  loader: () => import('unsubscribeApp/containers/App'),
})

// setup Api
rtApi.config.setEnv(config.apiEnv)
const savedAccessToken = getAccessToken()
if (savedAccessToken) {
  rtApi.config.setTokens(savedAccessToken, getCookie(refreshTokenKey))
}
rtApi.on('tokensChanged', ({ accessToken, refreshToken }) => {
  if (accessToken) {
    setAccessTokenCookie(accessToken)
    setRefreshTokenCookie(refreshToken)
  }
})
rtApi.on('tokensCleared', () => {
  deleteCookie(accessTokenKey)
  deleteCookie(refreshTokenKey)
})
rtApi.config.setClientId(config.auth.clientId)
// Adding a client debug ID which we send to every API for debugging purposes
let clientDebugId = window.localStorage.getItem('client-id')
if (!clientDebugId) {
  clientDebugId = Math.random()
  window.localStorage.setItem('client-id', clientDebugId)
}
rtApi.config.setClientDebugId(clientDebugId)
rtApi.config.setClientType('web')

// If the page was loaded at less than 600px wide, use mobile nav.
const useMobileNav = window.innerWidth < 600

const MainComponent = React.memo(
  ({
    channels,
    closeMobileNav,
    featureFlags,
    isAuthenticated,
    isBannerLive,
    isFunnelNav,
    isLiveEventOn,
    isMobileNavOpen,
    isPaidUser,
    location,
    openMobileNav,
    openSearchV2,
    pathname,
    PlayerContainer,
    searchV2IsOpen,
    user,
  }) => (
    <>
      <div
        className={classnames('app-page', {
          'top-marketing-banner-live': isBannerLive,
          'has-smart-app-banner': displaySmartAppBanner(),
        })}
      >
        <SEOTags />
        <PageMetaTags />
        <BlurredBackgroundImageContainer />
        <WebPushContainer uuid={user?.attributes?.uuid || ''} />
        {!isFunnelNav && (
          <Suspense fallback={<div />}>
            <MarketingContainer />
          </Suspense>
        )}
        <DetectScrollbars />
        <Suspense fallback={<div />}>
          <ScrollToTop />
        </Suspense>
        <ExternalScripts user={user} featureFlags={featureFlags} />
        {isFunnelNav ? (
          <FunnelNav isAuthenticated={isAuthenticated} />
        ) : (
          <>
            {featureFlags.newMobileNav && useMobileNav ? (
              <>
                <MobileTopNav
                  featureFlags={featureFlags}
                  isAuthenticated={isAuthenticated}
                  isFirst={isPaidUser}
                  pathname={pathname}
                />
                <MobileBottomNav
                  isAuthenticated={isAuthenticated}
                  openSearch={openSearchV2}
                  openNav={openMobileNav}
                  pathname={pathname}
                />
                <MobileOverlayNav
                  channels={channels}
                  closeNav={closeMobileNav}
                  featureFlags={featureFlags}
                  isAuthenticated={isAuthenticated}
                  isLiveEventOn={isLiveEventOn}
                  isOpen={isMobileNavOpen}
                />
              </>
            ) : (
              <>
                <TopNav
                  featureFlags={featureFlags}
                  isAuthenticated={isAuthenticated}
                  openSearchV2={openSearchV2}
                  pathname={pathname}
                  subscribed={isPaidUser}
                  user={user}
                />
                {!isFunnelNav && (
                  <SideNav
                    channels={channels}
                    featureFlags={featureFlags}
                    isAuthenticated={isAuthenticated}
                    isLiveEventOn={isLiveEventOn}
                    path={pathname}
                    user={user}
                  />
                )}
              </>
            )}
            <SmartAppBanner />
          </>
        )}
        <ModalApp />
        <SearchV2Outer
          openSearch={openSearchV2}
          searchV2IsOpen={searchV2IsOpen}
        />
        <ReactResizeDetector handleWidth>
          {({ width }) => (
            <ThemeProvider theme={{ width }}>
              <div className='main-col'>
                <ProvideSocket>
                  <RtRoutes location={location} featureFlags={featureFlags} />
                  {PlayerContainer && <PlayerContainer />}
                </ProvideSocket>
              </div>
            </ThemeProvider>
          )}
        </ReactResizeDetector>
        <ToastContainer />
      </div>
      <MyHubContainer />
    </>
  )
)

// To aid in debugging
MainComponent.displayName = 'MainComponent'
// MainComponent.whyDidYouRender = true

export class App extends Component {
  state = {
    PlayerContainer: null,
  }

  componentDidMount() {
    const {
      fetchBadges,
      fetchBlockedUsers,
      fetchChannels,
      fetchLivestreamShows,
      fetchLivestreamSchedule,
      fetchMutedUsers,
      fetchWatchLaterItems,
      isAuthenticated,
      user,
      getUserObject,
      waitForUserObject,
    } = this.props

    // TODO: Do we still need this? Figure out what this was for.
    import('common/containers/VideoContainer').then(component =>
      this.setState({ PlayerContainer: component.default })
    )

    // get channels
    fetchChannels()

    // get badges
    fetchBadges()

    // get if there's a live event on for SideNav treatment
    fetchLivestreamShows()
    fetchLivestreamSchedule()

    // grab user info if authenticated
    if (waitForUserObject) {
      getUserObject().catch(() => {
        // api handles refresh token getting, so if we get here we have a problem
        rtApi.config.clearTokens()
        // if they've gotten here, that means we are still unable to get the user, so clear the store/localStorage of tokens
        deleteCookie(accessTokenKey)
        deleteCookie(refreshTokenKey)
        // })
      })
    }

    if (isAuthenticated) {
      window.localStorage.setItem(metrics.authenticatedEverKey, true)
    }

    if (user) {
      fetchWatchLaterItems()
      fetchFollowedShows({ fetchAll: true })
      // TODO: Move muted/blocked to context
      fetchMutedUsers()
      fetchBlockedUsers()
    }

    // call page on mount since listener isn't set up on first mount
    metrics.pageView(user, this.getPathName(this.props.pathname), {
      previous_url: document.referrer,
    })

    // listen for "Ctrl F" / "Cmd F" to make the browser search experience better.
    bindBrowserSearchHandlers()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user } = this.props
    // track user logging out in metrics
    if (user && !nextProps.user) {
      metrics.logoutUser()
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    const { pathname: prevPathname } = prevProps
    const {
      fetchBlockedUsers,
      fetchMutedUsers,
      fetchFollowedShows,
      fetchWatchLaterItems,
      isAuthenticated,
      pathname,
      user,
    } = this.props

    if (isAuthenticated && !prevProps.isAuthenticated) {
      window.localStorage.setItem(metrics.authenticatedEverKey, true)
    }

    if (
      prevProps.user === null &&
      user !== null &&
      prevPathname !== '/redeem'
    ) {
      fetchWatchLaterItems()
      fetchFollowedShows({ fetchAll: true })
      // track user info in metrics
      metrics.loginUser(user)
      // TODO: Move muted/blocked to context
      fetchMutedUsers()
      fetchBlockedUsers()
    }

    if (prevPathname !== undefined && pathname !== prevPathname) {
      const opts = { previous_url: prevPathname }

      metrics.pageView(user, this.getPathName(pathname), opts)
    }
  }

  getPathName = pathName => {
    if (pathName === '/signup') {
      return 'Sign Up'
    }
    if (pathName === '/login') {
      return 'Login'
    }
  }

  isFunnelNav = () => {
    const { pathname } = this.props
    const funnelPatterns = [/^\/first-promo/, /^\/signup/]
    return funnelPatterns.some(route => pathname.match(route))
  }

  isPaidUser = () => {
    const { user } = this.props

    return Boolean(user && user.attributes && user.attributes.member_tier_i > 0)
  }

  openSearchV2 = () => {
    this.props.openSearchV2()
  }

  render() {
    const {
      channels,
      closeMobileNav,
      featureFlags,
      isAuthenticated,
      isBannerLive,
      isLiveEventOn,
      isMobileNavOpen,
      openMobileNav,
      pathname,
      router: { location },
      searchV2IsOpen,
      user,
    } = this.props
    const { PlayerContainer } = this.state
    const cloudinaryContextProps = {
      cloudName: 'rtv3',
      privateCdn: true,
      cname: 'cdn.roosterteeth.com',
      secureDistribution: 'cdn.roosterteeth.com',
    }

    return (
      <ErrorBoundary>
        <FallingParticlesEasterEggContainer />
        <EasterEgg />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <CloudinaryContext {...cloudinaryContextProps}>
          <Routes location={location}>
            <Route path='/embed/:slug' Component={EmbeddableVideo} />
            <Route path='/embed-bonus/:slug' Component={EmbeddableVideo} />
            <Route
              path='/embed-post/:id'
              render={props => (
                <PostDetailsContainer
                  id={props.match.params.id}
                  showComments={false}
                  allowReply={false}
                />
              )}
            />
            <Route
              exact
              path='/redeem'
              // eslint-disable-next-line react/no-unstable-nested-components
              Component={() => (
                <>
                  <Helmet htmlAttributes={{ 'data-platform': getPlatform() }}>
                    <meta property='og:title' content='Redeem Gift Card' />
                    <script
                      src='https://www.google.com/recaptcha/api.js'
                      async
                      defer
                    />
                    <title>{pageTitle('Redeem Gift Card')}</title>
                  </Helmet>
                  <AsyncRedeem />
                  <DetectScrollbars />
                  <FunnelNav
                    isAuthenticated={isAuthenticated}
                    showAuth={false}
                    textColor='black'
                  />
                </>
              )}
            />
            <Route exact path='/activate/*' Component={AsyncActivate} />
            <Route path='/signup/*' Component={AsyncSignup} />
            <Route exact path='/unsubscribe' Component={AsyncUnsubscribe} />
            <Route exact path='/youtube' Component={AsyncYoutubeAuth} />
            <Route
              path='*'
              element={
                <MainComponent
                  channels={channels}
                  closeMobileNav={closeMobileNav}
                  featureFlags={featureFlags}
                  isAuthenticated={isAuthenticated}
                  isBannerLive={isBannerLive}
                  isFunnelNav={this.isFunnelNav()}
                  isLiveEventOn={isLiveEventOn}
                  isMobileNavOpen={isMobileNavOpen}
                  isPaidUser={this.isPaidUser()}
                  location={location}
                  openMobileNav={openMobileNav}
                  openSearchV2={this.openSearchV2}
                  pathname={pathname}
                  PlayerContainer={PlayerContainer}
                  searchV2IsOpen={searchV2IsOpen}
                  user={user}
                />
              }
            />
          </Routes>
        </CloudinaryContext>
        <SiteVersionIndicator />
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { channels, selectedChannel } = state.rootReducer
  const { isAuthenticated, user } = state.authReducer
  const waitForUserObject = shouldWaitForUserObject(state)
  const { router } = state
  const { pathname } = router.location
  const { topMarketingBanner } = state.marketingReducer
  const {
    featureFlags,
    livestreams: { isLiveEventOn },
  } = state.commonReducer
  const pageName = ownProps.name || 'generic'
  const isBannerLive = topMarketingBanner?.isLive
  const searchV2IsOpen = getIsSearchV2Open(state)
  const isMobileNavOpen = getIsMobileNavOpen(state)

  return {
    channels,
    featureFlags,
    isAuthenticated,
    isBannerLive,
    isLiveEventOn,
    isMobileNavOpen,
    pageName,
    pathname,
    router,
    searchV2IsOpen,
    selectedChannel,
    user,
    waitForUserObject,
  }
}

// Use default export for the connected component (for app)
const ConnectedApp = connect(
  mapStateToProps,
  {
    closeMobileNav,
    fetchBadges,
    fetchBlockedUsers,
    fetchChannels,
    fetchFollowedShows,
    fetchLivestreamShows,
    fetchLivestreamSchedule,
    fetchMutedUsers,
    fetchWatchLaterItems,
    getUserObject,
    openMobileNav,
    openSearchV2,
    push,
    refreshToken,
  }
)(App)

const ConnectedMemoizedApp = React.memo(ConnectedApp)

export default ConnectedMemoizedApp
