const initialState = {
  adminPosts: {},
  adminComments: {},
  loading: false,
}

export default (state = initialState, { type, req, resp, ...rest }) => {
  switch (type) {
    case 'communityApp/ADMIN_POSTS_REQ':
      return {
        ...state,
        loading: true,
      }
    case 'communityApp/ADMIN_POSTS_RESP':
      let posts = resp
      if (req.merge) {
        posts = {
          data: [...state.adminPosts.data, ...resp.data],
          links: resp.links,
        }
      }
      return {
        ...state,
        loading: false,
        adminPosts: posts,
      }
    case 'communityApp/ADMIN_COMMENTS_REQ':
      return {
        ...state,
        loading: true,
      }
    case 'communityApp/ADMIN_COMMENTS_RESP':
      let comments = resp
      if (req.merge) {
        comments = {
          data: [...state.adminComments.data, ...resp.data],
          links: resp.links,
        }
      }
      return {
        ...state,
        loading: false,
        adminComments: comments,
      }
    case 'common/DELETE_POST_RESP':
    case 'common/DELETE_POST_FLAGS_RESP':
      if (!state.adminPosts?.data?.length) {
        return state
      }
      const postId = req.id
      const filterDeletedPost = post => {
        return post.id !== postId
      }
      return {
        ...state,
        adminPosts: {
          ...state.adminPosts,
          data: state.adminPosts.data
            ? state.adminPosts.data.filter(filterDeletedPost)
            : null,
        },
      }
    case 'communityApp/DELETE_COMMENT_FLAGS_RESP':
    case 'comments/DELETE_COMMENT_SUCCESS':
      if (!state.adminComments?.data?.length) {
        return state
      }
      const commentId = rest.uuid || req.id
      const filterDeletedComment = comment => {
        return comment.id !== commentId
      }
      return {
        ...state,
        adminComments: {
          ...state.adminComments,
          data: state.adminComments.data
            ? state.adminComments.data.filter(filterDeletedComment)
            : null,
        },
      }
    default:
      return state
  }
}
