import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  DELETE_FOLLOW_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  DELETE_PLAYLIST_ITEM_SUCCESS,
  DELETE_PLAYLIST_SUCCESS,
  GET_FEATURED_REQUEST,
  GET_FEATURED_SUCCESS,
  GET_FOLLOWED_SHOWS_SUCCESS,
  GET_FOLLOWED_SUCCESS,
  GET_ITEM_IN_WATCH_LATER_REQUEST,
  GET_ITEM_IN_WATCH_LATER_SUCCESS,
  GET_MINE_BY_CONTENT_SUCCESS,
  GET_MINE_SUCCESS,
  GET_PLAYLIST_ITEMS_PERCENT_SUCCESS,
  GET_PLAYLIST_ITEMS_SUCCESS,
  GET_PLAYLIST_ITEMS_UUIDS_SUCCESS,
  GET_PLAYLIST_OWNER_SUCCESS,
  GET_PLAYLIST_SUCCESS,
  GET_PLAYLISTS_SUCCESS,
  GET_WATCH_LATER_DATA_SUCCESS,
  GET_WATCH_LATER_SUCCESS,
  PATCH_PLAYLIST_SUCCESS,
  PUT_FOLLOW_PLAYLIST_SUCCESS,
  PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  PUT_PLAYLIST_ITEM_SUCCESS,
  PUT_PLAYLIST_SUCCESS,
  PUT_REORDER_PLAYLIST_ITEMS_SUCCESS,
} from './types'

const getContentUuidFromPlaylistItem = item => item?.attributes?.content_uuid

const fetchPlaylistData = array => (dispatch, _getState) => {
  const ids = []
  for (const item of array) {
    if (
      item?.attributes?.content_type === 'show' ||
      item?.attributes?.display_content_type === 'show'
    ) {
      ids.push(
        item?.attributes?.content_uuid || item?.attributes?.display_content_uuid
      )
    } else if (item?.attributes?.display_content_uuid) {
      ids.push(item.attributes.display_content_uuid)
    } else if (item?.attributes?.content_uuid) {
      ids.push(item.attributes.content_uuid)
    }
  }
  rtApi.episodes
    .fetchBulk({ ids })
    .then(json => {
      dispatch({ type: `library/GET_PLAYLIST_DATA_SUCCESS`, items: json.data })
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch({
        type: `library/GET_PLAYLIST_DATA_FAILURE`,
        error: ex.message,
      })
    })
}

const receiveMyPlaylists = (items, totalPages, merge) => ({
  type: GET_MINE_SUCCESS,
  items,
  merge,
  totalPages,
})

export const fetchMyPlaylists = ({
  merge = true,
  page,
  perPage = 24,
  sort,
} = {}) => (dispatch, _getState) =>
  rtApi.playlists
    .fetchForUser({ sort, page, perPage })
    .then(json => {
      dispatch(receiveMyPlaylists(json.data, json.meta.page.total, merge))
      dispatch(fetchPlaylistData(json.data))
      dispatch(fetchPlaylistOwner(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

const receiveMyPlaylistsByContent = ids => ({
  type: GET_MINE_BY_CONTENT_SUCCESS,
  ids,
})

export const fetchMyPlaylistsByContent = ({ uuid } = {}) => (
  dispatch,
  _getState
) =>
  rtApi.playlists
    .fetchForUserByContent({ uuid })
    .then(json => {
      dispatch(receiveMyPlaylistsByContent(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

const requestFeaturedPlaylists = filter => ({
  type: GET_FEATURED_REQUEST,
  filter,
})

const receiveFeaturedPlaylists = (items, filter, totalPages) => ({
  type: GET_FEATURED_SUCCESS,
  items,
  filter,
  totalPages,
})

export const fetchFeaturedPlaylists = ({ filter, page, perPage }) => (
  dispatch,
  _getState
) => {
  dispatch(requestFeaturedPlaylists(filter))
  return rtApi.playlists
    .fetchFeatured({ filter, page, perPage })
    .then(json => {
      dispatch(receiveFeaturedPlaylists(json.data, filter, json.total_pages))
      return Promise.resolve(json.data)
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })
}

const receiveFollowedPlaylists = (items, totalPages, merge) => ({
  type: GET_FOLLOWED_SUCCESS,
  items,
  merge,
  totalPages,
})

export const fetchFollowedPlaylists = ({
  sort,
  page,
  perPage = 24,
  merge = true,
} = {}) => (dispatch, _getState) =>
  rtApi.playlists
    .fetchFollowed({ sort, page, perPage })
    .then(json => {
      dispatch(receiveFollowedPlaylists(json.data, json.meta.page.total, merge))
      dispatch(fetchPlaylistData(json.data))
      dispatch(fetchPlaylistOwner(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

const receiveFollowedShows = (items, totalPages, merge) => ({
  type: GET_FOLLOWED_SHOWS_SUCCESS,
  items,
  merge,
  totalPages,
})

export const fetchFollowedShows = ({
  fetchAll = true,
  merge = true,
  page,
  perPage = 24,
  sort,
} = {}) => (dispatch, _getState) =>
  rtApi.playlists
    .fetchItemsForUser({
      category: 'followed_series',
      fetchAll,
      sort,
      page,
      perPage,
    })
    .then(json => {
      dispatch(
        receiveFollowedShows(json.data, json?.meta?.page?.total || 1, merge)
      )
      dispatch(fetchPlaylistData(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

const receivePlaylist = item => ({
  type: GET_PLAYLIST_SUCCESS,
  item,
})

export const fetchPlaylist = id => (dispatch, getState) => {
  const { isAuthenticated } = getState().authReducer
  return rtApi.playlists
    .fetchId({ authenticated: isAuthenticated, id })
    .then(json => {
      dispatch(receivePlaylist(json.data))
      dispatch(fetchPlaylistOwner([json.data]))
    })
    .catch(ex => Promise.reject(ex))
}

const receivePlaylists = (items, filter = {}) => ({
  type: GET_PLAYLISTS_SUCCESS,
  items,
  filter,
})

export const fetchPlaylists = (ids, filter) => (dispatch, _getState) =>
  rtApi.playlists
    .fetchBulkPublic({ ids: ids.join(',') })
    .then(json => {
      dispatch(receivePlaylists(json.data, filter))
      dispatch(fetchPlaylistOwner(json.data, filter))
      dispatch(fetchPlaylistData(json.data))
    })
    .catch(ex => Promise.reject(ex))

const receivePlaylistOwner = (items, filter) => ({
  type: GET_PLAYLIST_OWNER_SUCCESS,
  items,
  filter,
})

export const fetchPlaylistOwner = (items, filter = {}) => (
  dispatch,
  _getState
) => {
  if (!items || !items.length) {
    return
  }
  const ids = items.map(i => i.attributes.owner_uuid)
  return rtApi.users
    .fetchBulk({ ids })
    .then(json => dispatch(receivePlaylistOwner(json.data, filter)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })
}

export const fetchFollowedStatus = ids => (_dispatch, _getState) =>
  rtApi.playlists
    .fetchFollowedStatus({ ids })
    .then(json => json.data)
    .catch(ex => Promise.reject(ex))

const receiveFollow = item => ({
  type: PUT_FOLLOW_PLAYLIST_SUCCESS,
  item,
})

export const followPlaylist = (id, item) => (dispatch, _getState) =>
  rtApi.playlists
    .addFollowed({ id })
    .then(() => dispatch(receiveFollow(item)))
    .catch(ex => Promise.reject(ex))

const receiveUnfollow = id => ({
  type: DELETE_FOLLOW_PLAYLIST_SUCCESS,
  id,
})

export const unfollowPlaylist = id => (dispatch, _getState) =>
  rtApi.playlists
    .removeFollowed({ id })
    .then(_json => dispatch(receiveUnfollow(id)))
    .catch(ex => Promise.reject(ex))

const receiveCreatePlaylist = item => ({
  type: PUT_PLAYLIST_SUCCESS,
  item,
})

export const createPlaylist = ({
  description,
  name,
  official,
  privacyLevel,
  playlistItems,
}) => (dispatch, _getState) =>
  rtApi.playlists
    .create({
      description,
      name,
      official,
      privacyLevel,
      playlistItems,
    })
    .then(json => dispatch(receiveCreatePlaylist(json.data)))
    .catch(ex => Promise.reject(ex))

const receiveUpdatePlaylist = item => ({
  type: PATCH_PLAYLIST_SUCCESS,
  item,
})

export const updatePlaylist = ({
  id,
  description,
  name,
  official,
  privacyLevel,
}) => (dispatch, _getState) =>
  rtApi.playlists
    .update({ id, description, name, official, privacyLevel })
    .then(json => dispatch(receiveUpdatePlaylist(json.data)))
    .catch(ex => Promise.reject(ex))

export const clonePlaylist = ({
  id,
  description,
  name,
  official,
  privacyLevel,
}) => (_dispatch, _getState) =>
  rtApi.playlists
    .clone({ id, description, name, official, privacyLevel })
    .catch(ex => Promise.reject(ex))

const receiveDeletePlaylist = id => ({
  type: DELETE_PLAYLIST_SUCCESS,
  id,
})

export const deletePlaylist = id => (dispatch, _getState) =>
  rtApi.playlists
    .delete({ id })
    .then(() => dispatch(receiveDeletePlaylist(id)))
    .catch(ex => Promise.reject(ex))

const receivePlaylistItemsUuids = (items, totalPages) => ({
  type: GET_PLAYLIST_ITEMS_UUIDS_SUCCESS,
  items,
  totalPages,
})

export const fetchPlaylistItemsUuids = ({
  id,
  merge = true,
  page = 1,
  perPage = 20,
} = {}) => (dispatch, getState) => {
  const { isAuthenticated } = getState().authReducer
  return rtApi.playlists
    .fetchItems({ authenticated: isAuthenticated, id, page, perPage })
    .then(json =>
      dispatch(receivePlaylistItemsUuids(json.data, json.meta.page.total))
    )
    .then(() => dispatch(fetchPlaylistItems({ merge })))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })
}

const receivePlaylistItems = (items, merge) => ({
  type: GET_PLAYLIST_ITEMS_SUCCESS,
  items,
  merge,
})

const requestItems = (dispatch, ids, isAuthenticated, merge) =>
  rtApi.episodes
    .fetchBulk({ ids })
    .then(json => dispatch(receivePlaylistItems(json.data, merge)))
    .then(() => {
      if (isAuthenticated) {
        dispatch(fetchPlaylistPercentage())
      }
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

export const fetchPlaylistItems = ({ merge = true } = {}) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { isAuthenticated } = getState().authReducer
  const { playlistItemsUuids } = state.commonReducer.myStuff
  // this needs to be split if there are too many uuids
  if (playlistItemsUuids.length > 50) {
    for (let i = 0; i < playlistItemsUuids.length; i += 50) {
      requestItems(
        dispatch,
        playlistItemsUuids.slice(i, i + 50),
        isAuthenticated,
        merge
      )
    }
  } else {
    requestItems(dispatch, playlistItemsUuids, isAuthenticated, merge)
  }
}

const receiveAddToPlaylistByCategory = (item, category) => ({
  type: PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  category,
  item,
})

export const addToPlaylistByCategory = (category, type, uuid) => (
  dispatch,
  _getState
) =>
  rtApi.playlists
    .addItemsForUser({ category, type, uuid })
    .then(json => {
      dispatch(receiveAddToPlaylistByCategory(json.data, category))
      if (category === 'watch_later') {
        dispatch(
          fetchWatchLaterData([getContentUuidFromPlaylistItem(json.data)])
        )
      }
    })
    .catch(ex => Promise.reject(ex))

const receiveAddToPlaylist = (item, id) => ({
  type: PUT_PLAYLIST_ITEM_SUCCESS,
  item,
  id,
})

export const addToPlaylist = (id, type, uuid) => (dispatch, getState) => {
  const state = getState()
  return rtApi.playlists
    .addItem({ id, type, uuid })
    .then(json => {
      dispatch(receiveAddToPlaylist(json.data, id))
      if (state.commonReducer.myStuff.watchLaterInfo?.id === id) {
        dispatch(
          fetchWatchLaterData([getContentUuidFromPlaylistItem(json.data)])
        )
      }
    })
    .catch(ex => Promise.reject(ex))
}

const receiveRmFromPlaylistByCategory = (uuid, category) => ({
  type: DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  category,
  uuid,
})

export const rmFromPlaylistByCategory = (category, uuid) => (
  dispatch,
  _getState
) =>
  rtApi.playlists
    .deleteItemsForUser({ category, uuid })
    .then(_json => dispatch(receiveRmFromPlaylistByCategory(uuid, category)))
    .catch(ex => Promise.reject(ex))

const receiveRmFromPlaylist = (id, uuid) => ({
  type: DELETE_PLAYLIST_ITEM_SUCCESS,
  id,
  uuid,
})

export const rmFromPlaylist = (id, uuid) => (dispatch, _getState) =>
  rtApi.playlists
    .deleteItem({ id, uuid })
    .then(_json => dispatch(receiveRmFromPlaylist(id, uuid)))
    .catch(ex => Promise.reject(ex))

const reorderPlaylistSuccess = (items, playlistId) => ({
  type: PUT_REORDER_PLAYLIST_ITEMS_SUCCESS,
  items,
  playlistId,
})

export const reorderPlaylist = (id, insertAfter, position, uuid, newOrder) => (
  dispatch,
  _getState
) =>
  rtApi.playlists
    .reorderItem({ id, insertAfter, position, uuid })
    .then(() => dispatch(reorderPlaylistSuccess(newOrder, id)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

// Get Playlist Watched percentage

const receivePlaylistPercent = items => ({
  type: GET_PLAYLIST_ITEMS_PERCENT_SUCCESS,
  items,
})

const fetchPlaylistPercentage = () => (dispatch, getState) => {
  const state = getState()
  const { playlistItemsUuids } = state.commonReducer.myStuff
  return rtApi.wtc
    .fetchPositions({ ids: playlistItemsUuids })
    .then(json => dispatch(receivePlaylistPercent(json)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })
}

// Watch Later
export const fetchWatchLater = () => (dispatch, _getState) =>
  rtApi.playlists
    .fetchWatchLater()
    .then(json => {
      dispatch(receiveWatchLaterInfo(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

const receiveWatchLaterInfo = info => ({
  type: GET_WATCH_LATER_SUCCESS,
  info,
})

const receiveWatchLaterData = items => ({
  type: GET_WATCH_LATER_DATA_SUCCESS,
  items,
})

export const fetchWatchLaterItems = ({
  fetchAll = false,
  page,
  perPage = 30,
  sort = null,
} = {}) => (dispatch, _getState) =>
  rtApi.playlists
    .fetchItemsForUser({
      category: 'watch_later',
      fetchAll,
      sort,
      page,
      perPage,
    })
    .then(json => {
      dispatch(
        fetchWatchLaterData(json.data.map(getContentUuidFromPlaylistItem))
      )
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex)
    })

export const fetchWatchLaterData = contentUuids => (dispatch, _getState) => {
  if (contentUuids.length < 1) return
  rtApi.episodes
    .fetchBulk({ ids: contentUuids })
    .then(json => dispatch(receiveWatchLaterData(json.data)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
    })
}

// Get if specific episode is in watch later and add it to redux state
let batchedRequestIds = []
let batchedTimeout = null

const requestItemInWatchLater = ids => ({
  type: GET_ITEM_IN_WATCH_LATER_REQUEST,
  ids,
})

const receiveItemInWatchLater = items => ({
  type: GET_ITEM_IN_WATCH_LATER_SUCCESS,
  items,
})

const getInWatchlist = (dispatch, ids) => {
  dispatch(requestItemInWatchLater(ids))
  return rtApi.playlists
    .watchLaterStatus({ ids })
    .then(json => {
      dispatch(receiveItemInWatchLater(json.data))
    })
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
    })
}

export const fetchItemInWatchLater = id => (dispatch, _getState) => {
  batchedRequestIds.push(id)
  clearTimeout(batchedTimeout)
  if (batchedRequestIds.length === 18) {
    getInWatchlist(dispatch, batchedRequestIds)
    batchedRequestIds = []
  }
  batchedTimeout = setTimeout(() => {
    getInWatchlist(dispatch, batchedRequestIds)
    batchedRequestIds = []
  }, 1000)
}
