import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const IconDropdownLink = ({ children, href, onClick }) => (
  <li>
    <Link to={href} onClick={onClick}>
      {children}
    </Link>
  </li>
)

IconDropdownLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

IconDropdownLink.defaultProps = {
  onClick: () => null,
}

export default IconDropdownLink
