import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const SideNavChannels = ({ channels, onChannelClick }) =>
  channels ? (
    <ul className='qa-side-nav-channels'>
      {channels.map(channel => {
        const {
          attributes: { name, slug },
          id,
        } = channel

        const channelPath = `/channel/${slug}`

        return (
          <li className='side-nav-menu__item' key={`side-nav-channel-${id}`}>
            <NavLink
              className='side-nav-menu__link waves-effect waves-brand qa-side-nav-channels-link'
              onClick={e => {
                onChannelClick({
                  e,
                  label: 'nav_channel',
                  options: { context: channel.attributes.name },
                })
              }}
              to={channelPath}
            >
              {name}
            </NavLink>
          </li>
        )
      })}
    </ul>
  ) : null

SideNavChannels.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  onChannelClick: PropTypes.func.isRequired,
}

export default SideNavChannels
