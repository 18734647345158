import { config } from 'common/config'
import { waitForGlobal } from 'common/helpers'

/**
 * Attempts to sign in via Google OAuth. This may prompt the user with a Google OAuth signup window. If successful, a token is provided.
 * @returns {Promise<{ token: string, response?: object }>} Promise fulfilled by an object with an access token if successful.
 */
export const authenticateGoogleUser = ({ scope, ...options }) =>
  new Promise((resolve, reject) =>
    waitForGlobal('google', 60).then(() => {
      if (!scope) scope = 'profile email'
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: config.auth.googleClientId,
        scope: scope || 'profile email',
        callback: response =>
          resolve({ token: response.access_token, response }),
        error_callback: reject,
        ...options,
      })
      client.requestAccessToken()
    })
  )

export default authenticateGoogleUser
