import { RECEIVE_HUB_DATA, SET_SHOW_LOADING_SCREEN } from './types'

export const receiveHubData = ({ feed, feedType }) => ({
  feed,
  feedType,
  type: RECEIVE_HUB_DATA,
})

export const setShowLoadingScreen = showLoadingScreen => ({
  showLoadingScreen,
  type: SET_SHOW_LOADING_SCREEN,
})
