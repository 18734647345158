/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import Swipeable from 'react-swipeable'

import { roundFix } from 'common/helpers'

import CarouselItem from './Item'
import PaginationIcons from './PaginationIcons'

import './styles.scss'

class Carousel extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onFollowSeriesClick: PropTypes.func,
    onMerchItemClick: PropTypes.func,
    onNextPage: PropTypes.func,
    onPreviousPage: PropTypes.func,
    onThumbnailClick: PropTypes.func,
    onThumbnailTitleClick: PropTypes.func,
    onUnfollowSeriesClick: PropTypes.func,
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    onFollowSeriesClick: () => null,
    onMerchItemClick() {},
    onNextPage: () => null,
    onPreviousPage: () => null,
    onThumbnailClick() {},
    onThumbnailTitleClick() {},
    onUnfollowSeriesClick: () => null,
  }

  state = {
    animating: false,
    canMove: true,
    hasMoved: false,
    firstVisibleIndex: 0,
    scaleFactor: 1.5,
    itemsPerPage: 4,
    width: '25%',
  }

  cardRefs = []

  contentRef = React.createRef()

  debouncedSetItems = debounce(() => this.setItemsPerPage(), 500)

  updateTimeout = null

  componentDidMount() {
    this.setItemsPerPage()
    // wait until we've finished our resize, then calc itemsPerPage
    window.addEventListener('resize', this.debouncedSetItems)
  }

  shouldComponentUpdate(nextProps, nextState) {
    // do not rerender items until after sliding transition animation has completed
    if (nextState.animating) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({ hasMoved: false, firstVisibleIndex: 0 })
      this.setItemsPerPage()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedSetItems)
  }

  findAndModDupes = input => {
    const itemObjects = []
    return input.map(item => {
      // Item is dupe, return item with modified key
      if (itemObjects.includes(item)) return { ...item, id: `${item.id}-1` }

      itemObjects.push(item)
      return item
    })
  }

  getDataSubset = arr => {
    // get only the items necessary to populate current slice, prev and next
    const { firstVisibleIndex, itemsPerPage, hasMoved } = this.state
    let subset = []
    let index1 = 0
    let index2 = 0
    if (hasMoved) {
      index1 = firstVisibleIndex - itemsPerPage - 1
      index2 = firstVisibleIndex + 2 * itemsPerPage + 1
      if (index1 < 0 && index2 > arr.length) {
        subset = arr
          .slice(index1)
          .concat(arr)
          .concat(arr.slice(0, index2 - arr.length))
      } else if (index1 < 0) {
        subset = arr.slice(index1).concat(arr.slice(0, index2))
      } else if (index2 > arr.length) {
        subset = arr
          .slice(index1, arr.length)
          .concat(arr.slice(0, index2 - arr.length))
      } else {
        subset = arr.slice(index1, index2)
      }
      return this.findAndModDupes(subset)
    }
    if (arr.length <= itemsPerPage) {
      return arr
    }
    index1 = firstVisibleIndex
    index2 = 2 * itemsPerPage + 1
    if (index2 > arr.length) {
      subset = arr
        .slice(index1, arr.length)
        .concat(arr.slice(0, index2 - arr.length))
    } else {
      subset = arr.slice(index1, index2)
    }
    return this.findAndModDupes(subset)
  }

  getViewPostion = index => {
    // get position of current item as it relates to the viewport
    const { hasMoved } = this.state
    const itemsPerPage = this.calculateItemsPerPage()
    const left = hasMoved ? itemsPerPage : 0
    const right = hasMoved ? itemsPerPage * 2 + 1 : itemsPerPage

    if (index === left && hasMoved) {
      return { name: 'leftPart', index: 0 }
    }
    if (index === left && !hasMoved) {
      return { name: 'leftFull', index: 0 }
    }
    if (index === left + 1 && hasMoved) {
      return { name: 'leftFull', index: 1 }
    }
    if (index === right) {
      return {
        name: 'rightPart',
        index: hasMoved ? itemsPerPage + 1 : itemsPerPage,
      }
    }
    if (index === right - 1) {
      return {
        name: 'rightFull',
        index: hasMoved ? itemsPerPage : itemsPerPage - 1,
      }
    }
    if (index > left && index < right) {
      return { name: 'middle', index: hasMoved ? index - itemsPerPage : index }
    }
    return { name: '', index: '' }
  }

  getCardWidth = () => 100 / this.calculateItemsPerPage()

  getTransformStyle = () => {
    // get transform percentage for slider once animation of sliding is done
    const transformStyle = this.state.hasMoved
      ? `translate(-${100 + this.getCardWidth()}%, 0px)`
      : 'translate(0%, 0px)'
    return transformStyle
  }

  handlePreviousPage = () => {
    this.props.onPreviousPage()
    this.shiftFromLeft()
  }

  handleNextPage = () => {
    this.props.onNextPage()
    this.shiftFromRight()
  }

  shiftFromLeft = () => {
    // slide carousel 1->
    this.setItemsPerPage()
    const { animating, firstVisibleIndex, hasMoved, itemsPerPage } = this.state
    if (!hasMoved || animating) return
    const dataLength = this.props.data.length
    let shiftAmount = 100
    let nextVisibleIndex = firstVisibleIndex - itemsPerPage
    if (firstVisibleIndex === 0) {
      nextVisibleIndex = dataLength - itemsPerPage
    } else if (nextVisibleIndex < 0) {
      nextVisibleIndex = 0
      const cardWidth = roundFix(100 / itemsPerPage, 6)
      shiftAmount = firstVisibleIndex * cardWidth
    }
    this.setState({ firstVisibleIndex: nextVisibleIndex, animating: true })
    this.contentRef.current.classList.add('animating')
    this.contentRef.current.style.transform = `translate(${this.getOffset() +
      shiftAmount}%, 0px)`
  }

  shiftFromRight = () => {
    // slide carousel <- 1-
    this.setItemsPerPage()
    const { animating, firstVisibleIndex, itemsPerPage } = this.state
    if (animating) return
    let nextVisibleIndex = firstVisibleIndex + itemsPerPage
    const dataLength = this.props.data.length
    const lastRoundedIndex = dataLength - itemsPerPage
    let shiftAmount = 100
    // wrap if at the end of the items
    if (nextVisibleIndex === dataLength) {
      nextVisibleIndex = 0
    } else if (
      nextVisibleIndex > lastRoundedIndex &&
      nextVisibleIndex < dataLength
    ) {
      nextVisibleIndex = lastRoundedIndex
      const cardWidth = roundFix(100 / itemsPerPage, 6)
      shiftAmount = (lastRoundedIndex - firstVisibleIndex) * cardWidth
    }
    // shift less than a full page if not enough itemsPerPage to fill a page
    const currentOffset = this.getOffset()
    this.setState({
      hasMoved: true,
      firstVisibleIndex: nextVisibleIndex,
      animating: true,
    })
    this.contentRef.current.classList.add('animating')
    this.contentRef.current.style.transform = `translate(${currentOffset -
      shiftAmount}%, 0px)`
  }

  transitionDone = e => {
    e.stopPropagation()
    // if the transition that just ended is a carousel shift do the following
    if (e.target === this.contentRef.current) {
      this.contentRef.current.classList.remove('animating')
      this.contentRef.current.style.transform = this.getTransformStyle()
      this.setState({ animating: false })
    }
  }

  /**
   * Calculate the number of items to display per page
   * we're doing this so that we can define our responsive sizes in
   * css for perf reasons
   */
  setItemsPerPage = () => {
    const perPage = this.calculateItemsPerPage()
    this.setState({ itemsPerPage: perPage })
    this.setState({ canMove: perPage < this.props.data.length })
  }

  calculateItemsPerPage = () => {
    if (!this.contentRef.current) {
      return 4
    }
    const childCard = this.contentRef.current.firstChild
    if (!childCard) {
      return 4
    }
    const calculatedItems = Math.round(
      this.contentRef.current.offsetWidth / childCard.offsetWidth
    )
    return calculatedItems || 4
  }

  getOffset = () => {
    // return the current translated percentage for the slider
    const transform = this.contentRef.current.style.transform
    if (transform === '') {
      return 0
    }
    return parseInt(transform.match(/translate\((.+)%/)[1], 10)
  }

  scaleCard = item => {
    const { type } = this.props
    if (type !== 'shows' && type !== 'queue') {
      return
    }
    const { scaleFactor } = this.state
    const { refIndex } = item.props
    // we only scale cards that are in a show carousel
    // TODO: Handle a queue list
    // get width of card in px minus margin
    const currentWidth =
      this.cardRefs[refIndex].cardRef.current.clientWidth - 14
    const newWidth = currentWidth * scaleFactor
    // find amount we need to shift other cards in the row
    const shiftAmount = (newWidth - currentWidth) / 2
    this.shiftCards(item, shiftAmount)
  }

  unscaleCard = item => {
    const { refIndex, type } = item.props
    clearTimeout(this.updateTimeout)
    // we only scale cards that are in a show carousel
    // TODO: handle queue items not in this way
    if (type !== 'shows' && type !== 'queue') {
      return
    }
    // remove slide amounts from all cards
    for (const item of this.cardRefs)
      item
        ? (item.cardRef.current.style.transform = 'translate(0%, 0px)')
        : null

    this.cardRefs[refIndex].cardRef.current.style.transform = 'scale(1,1)'
  }

  shiftCards = (item, amount) => {
    // shift cards to the left or the right based on the current card that is upscaled
    const { refIndex } = item.props
    const itemsPerPage = this.calculateItemsPerPage()
    const viewPos = this.getViewPostion(refIndex)
    const { scaleFactor, hasMoved } = this.state
    // scale this card
    this.cardRefs[
      refIndex
    ].cardRef.current.style.transform = `scale(${scaleFactor},${scaleFactor})`
    this.cardRefs[refIndex].cardRef.current.style['transform-origin'] =
      '50% 50%'
    // find where the current card is within the current pane so we can move those around it
    if (viewPos.name === 'leftFull') {
      // left card is hovered, push all to right
      this.cardRefs[refIndex].cardRef.current.style['transform-origin'] =
        'left center'
      for (let i = refIndex + 1; i <= refIndex + itemsPerPage; i += 1) {
        if (this.cardRefs[i]?.cardRef.current?.style) {
          this.cardRefs[
            i
          ].cardRef.current.style.transform = `translate(${amount * 2}px, 0px)`
        }
      }
    } else if (viewPos.name === 'rightFull') {
      // right is hovered, push all to left
      this.cardRefs[refIndex].cardRef.current.style['transform-origin'] =
        'right center'
      const leastVisible = !hasMoved ? 0 : refIndex - itemsPerPage
      for (let i = refIndex - 1; i >= leastVisible; i -= 1) {
        if (this.cardRefs[i]?.cardRef.current?.style) {
          this.cardRefs[
            i
          ].cardRef.current.style.transform = `translate(-${amount * 2}px, 0px)`
        }
      }
    } else if (viewPos.name === 'middle') {
      // somewhere in middle, push all visible around it
      const leastVisible = !hasMoved ? 0 : refIndex - itemsPerPage
      for (let i = leastVisible; i < refIndex; i += 1) {
        if (this.cardRefs[i]?.cardRef.current?.style) {
          this.cardRefs[
            i
          ].cardRef.current.style.transform = `translate(-${amount}px, 0px)`
        }
      }
      for (let i = refIndex + 1; i < refIndex + itemsPerPage; i += 1) {
        if (this.cardRefs[i]?.cardRef.current?.style) {
          this.cardRefs[
            i
          ].cardRef.current.style.transform = `translate(${amount}px, 0px)`
        }
      }
    }
  }

  render() {
    const { data, type, dispatch, filter } = this.props
    const { firstVisibleIndex, hasMoved, canMove, itemsPerPage } = this.state
    const isEmpty = data.length === 0
    const transformStyle = this.getTransformStyle()
    this.cardRefs = []

    return (
      <Swipeable
        onSwipedLeft={this.handleNextPage}
        trackMouse={canMove}
        disabled={!canMove}
        onSwipedRight={this.handlePreviousPage}
        stopPropagation
      >
        <div
          className={`rt-carousel ${type}`}
          ref={div => (this.carousel = div)}
          onTransitionEnd={this.transitionDone}
        >
          {hasMoved && canMove && (
            <span
              className='shift shiftPrev active'
              role='presentation'
              aria-label='See Previous'
              onClick={this.handlePreviousPage}
            >
              <i className='icon-keyboard_arrow_left' />
            </span>
          )}
          {canMove && itemsPerPage && (
            <PaginationIcons
              totalPages={Math.ceil(data.length / itemsPerPage)}
              currentPage={Math.floor(
                (firstVisibleIndex + itemsPerPage - 1) / itemsPerPage
              )}
            />
          )}
          <div
            className='carousel-content'
            ref={this.contentRef}
            style={{ transform: transformStyle }}
          >
            {!isEmpty &&
              this.getDataSubset(data).map((item, i) => (
                <CarouselItem
                  dataIndex={data.indexOf(item)}
                  dispatch={dispatch}
                  filter={filter}
                  item={item}
                  key={item.id}
                  listId={this.props.listId}
                  onFollowSeriesClick={this.props.onFollowSeriesClick}
                  onMerchItemClick={this.props.onMerchItemClick}
                  onThumbnailClick={this.props.onThumbnailClick}
                  onThumbnailTitleClick={this.props.onThumbnailTitleClick}
                  onUnfollowSeriesClick={this.props.onUnfollowSeriesClick}
                  percentages={this.props.percentages}
                  ref={elem => (this.cardRefs[i] = elem)}
                  refIndex={i}
                  scaleItem={this.scaleCard}
                  type={type}
                  unscaleItem={this.unscaleCard}
                  width={this.state.width}
                />
              ))}
          </div>
          {canMove && (
            <span
              className='shift shiftNext active'
              role='presentation'
              aria-label='See More'
              onClick={this.handleNextPage}
            >
              <i className='icon-keyboard_arrow_right' />
            </span>
          )}
        </div>
      </Swipeable>
    )
  }
}

export default Carousel
