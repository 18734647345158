const initialState = {
  isFetching: false,
  list: [],
}
export default (state = initialState, { type, data: users }) => {
  switch (type) {
    case 'c/user_search_REQ':
      return {
        ...state,
        isFetching: true,
      }
    case 'c/user_search_RESP':
      return {
        ...state,
        isFetching: false,
        list: users,
      }

    case 'c/user_search_reset':
      return initialState
    default:
      return state
  }
}
