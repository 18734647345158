import React, { useState } from 'react'
import PropTypes from 'prop-types'

function TabFocus({ children }) {
  const [tabFocused, setTabFocused] = useState(false)

  return React.cloneElement(children, {
    onBlur: () => {
      setTabFocused(false)
    },
    onKeyUp: e => {
      if (e.key === 'Tab') {
        setTabFocused(true)
      }
    },
    tabFocused,
  })
}

TabFocus.propTypes = {
  children: PropTypes.element.isRequired,
}

export default TabFocus
