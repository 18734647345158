export const SHOW_MODAL = 'modalApp/SHOW_MODAL'
export const HIDE_MODAL = 'modalApp/HIDE_MODAL'

export const hideModal = () => ({
  type: HIDE_MODAL,
})

export const showModal = (modalType, modalProps) => ({
  type: SHOW_MODAL,
  modalType,
  modalProps,
})
