/* eslint-disable import/no-import-module-exports */
import 'common/styles/materialize.css'

import React from 'react'
import Honeybadger from 'honeybadger-js'
import { render } from 'react-dom'
// import { AppContainer } from 'react-hot-loader'
import store from 'store'

// import history from 'history-singleton'
import { config } from './common/config'
import Root from './Root'

let disableErrorHandling = false
if (config.metrics.env !== 'production' && config.metrics.env !== 'staging') {
  disableErrorHandling = true
}

Honeybadger.configure({
  apiKey: 'fe3b5f71',
  environment: config.metrics.env || 'development',
  // will probably need webpack to stuff this in
  revision: window.rtSVODRevision || 'unknown',
  disabled: disableErrorHandling,
  maxErrors: 5,
  onerror: false,
  ignorePatterns: [
    /Loading chunk/,
    /Loading CSS chunk/,
    // player is not properly being disposed of,
    // ignoring as new player will hopefully take care of this
    /Cannot set property 'current' of undefined/,
    /Cannot read property 'current' of undefined/,
    /Cannot read property 'campaign' of/, // Some analytics code is bad.
    /Unable to get property 'current' of undefined or null reference/,
    /Math\.round\(a\.target\.currentTime\)/,
    /evaluating 'b\[a\.target\.id\]\.current'/,
    /b\[a\.target\.id\] is undefined/,
    // high volume of errors
    /appendChild must be an instance of Node/,
    /insertBefore must be an instance of Node/,
    /Blocked a frame with origin "https:\/\/roosterteeth.com" from accessing a frame with origin "https:\/\/tr.snapchat.com"/,
    /Cannot read property 'receiver' of undefined/,
    /Unable to set property 'current' of undefined or null reference/,
    /this\.el_ is null/, // player errors
    /null has no properties/, // player errors
    /Access is denied/,
    /Blocked a frame/, // recaptcha/ads error
  ],
})

Honeybadger.beforeNotify(notice => {
  // helps hb group errors
  notice.fingerprint = notice.name + notice.message
})

render(<Root store={store} />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept(['./Root', './reducers'], () => {
    const NewRoot = require('./Root').default
    render(<NewRoot store={store} />, document.getElementById('root'))
  })
}
