import { takeLatest } from 'redux-saga'

import {
  ATTEMPT_SIGN_IN_WITH_3PARTY,
  CREATE_USER_SAGA,
  SUBMIT_CUSTOMIZE_FORM,
  UPDATE_USERNAME_AND_AVATAR_TO_RANDOM,
} from '../types'
import attemptSignInWith3PartySaga from './attemptSignInWith3PartySaga'
import createUserSaga from './createUserSaga'
import submitCustomizeFormSaga from './submitCustomizeFormSaga'
import updateUserNameAndAvatarToRandomSaga from './updateUserNameAndAvatarToRandomSaga'

function* saga() {
  yield takeLatest(CREATE_USER_SAGA, createUserSaga)
  yield takeLatest(ATTEMPT_SIGN_IN_WITH_3PARTY, attemptSignInWith3PartySaga)
  yield takeLatest(
    UPDATE_USERNAME_AND_AVATAR_TO_RANDOM,
    updateUserNameAndAvatarToRandomSaga
  )
  yield takeLatest(SUBMIT_CUSTOMIZE_FORM, submitCustomizeFormSaga)
}

export default saga
