import { useEffect, useMemo } from 'react'
import { fallbackDescription, fallbackTitle } from 'server/defaultTmplParams'

import { pageTitle } from 'common/helpers'

const useSetPageMetaTags = ({
  title = fallbackTitle,
  description = fallbackDescription,
  canonicalUrl = undefined,
  shortTitle = false,
}) => {
  const formattedTitle = useMemo(
    () => {
      if (shortTitle || title === 'Rooster Teeth') {
        return title
      }
      return pageTitle(title)
    },
    [title, shortTitle]
  )

  useEffect(
    () => {
      if (description) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description)
      }

      document.title = formattedTitle

      return () => {
        if (description !== fallbackDescription) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', fallbackDescription)
        }
      }
    },
    [formattedTitle, description]
  )

  const formattedCanonicalUrl = useMemo(
    () => {
      if (!canonicalUrl) {
        return undefined
      }

      if (
        canonicalUrl.startsWith('http://') ||
        canonicalUrl.startsWith('https://')
      ) {
        return canonicalUrl
      }

      const host = `${window.location.protocol}//${window.location.host}`
      return `${host}${canonicalUrl}`
    },
    [canonicalUrl]
  )

  useEffect(
    () => {
      if (formattedCanonicalUrl) {
        let canonicalLink = document.querySelector('link[rel="canonical"]')
        if (canonicalLink) {
          canonicalLink.setAttribute('href', formattedCanonicalUrl)
        } else {
          canonicalLink = document.createElement('link')
          canonicalLink.setAttribute('rel', 'canonical')
          canonicalLink.setAttribute('href', formattedCanonicalUrl)
          document.head.appendChild(canonicalLink)
        }
      }

      return () => {
        // only remove the canonical link on unmount if we set one in this component
        const canonicalLink = document.querySelector('link[rel="canonical"]')
        if (formattedCanonicalUrl && canonicalLink) {
          document.head.removeChild(canonicalLink)
        }
      }
    },
    [formattedCanonicalUrl]
  )

  return {
    title: formattedTitle,
    description,
    canonicalUrl: formattedCanonicalUrl,
  }
}

export default useSetPageMetaTags
