import { createSelector } from '@reduxjs/toolkit'

import { getIsAuthenticated, getUserTier } from 'auth/selectors'
import { SEARCH_PARAM_SHOW_HUB } from 'myHubApp/constants'
import { getSearchString } from 'routing/selectors'

export const getMyHubReducer = state => state.myHubReducer

export const getShouldDisplayHub = createSelector(
  getSearchString,
  getIsAuthenticated,
  (searchString, isAuthenticated) => {
    const searchParams = new URLSearchParams(searchString)
    const shouldDisplayHubParam =
      searchParams.get(SEARCH_PARAM_SHOW_HUB) === 'true'
    const shouldDisplayHub = shouldDisplayHubParam && isAuthenticated

    return shouldDisplayHub
  }
)

export const getHubFeedType = createSelector(
  getUserTier,
  userTier => {
    const feedType =
      userTier === 'first' ? 'first-user-onboarding' : 'free-user-onboarding'
    return feedType
  }
)

export const getMyHubFeed = createSelector(
  getMyHubReducer,
  getHubFeedType,
  (myHubReducer, feedType) => myHubReducer.feeds[feedType]
)

export const getMyHubShowLoadingScreen = createSelector(
  getMyHubReducer,
  myHubReducer => myHubReducer.showLoadingScreen
)
