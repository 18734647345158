export const checkConsent = category => {
  // smv: Social Media - Third Party Vendor
  // adv: Advertising - Third Party Vendor
  // ad: Ads - General
  // pf: Performance - General
  // sm: Social Media - General
  // req: Strictly Necessary Cookies
  // bb: Behavioral - General

  try {
    if (typeof window.OptanonActiveGroups === 'undefined') {
      return false
    }

    const categories = window.OptanonActiveGroups.split(',')

    return categories.includes(category)
  } catch (ex) {
    console.error(ex) // eslint-disable-line no-console
  }
}

window.checkConsent = checkConsent
