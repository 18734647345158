import React from 'react'
import PropTypes from 'prop-types'

import { PlanName, PlanPrice } from 'common/components'

const RadioPlan = ({ checked, onChange, plan }) => (
  <div className='qa-radio-plan' key={plan.plan_code}>
    <label>
      <input
        name='interval'
        value={plan.plan_code}
        type='radio'
        id={plan.plan_code}
        checked={checked}
        onChange={onChange}
      />
      <PlanName plan={plan} />: <PlanPrice plan={plan} hasInterval />
    </label>
  </div>
)

RadioPlan.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  plan: PropTypes.object,
}
RadioPlan.defaultProps = {
  checked: null,
  onChange: () => null,
  plan: {},
}

export default RadioPlan
