export const APPLY_COUPON = 'signupv2/APPLY_COUPON'
export const ATTEMPT_SIGN_IN_WITH_3PARTY = 'signupv2/ATTEMPT_SIGN_IN_WITH_3PARTY'
export const CREATE_USER_SAGA = 'signupv2/CREATE_USER_SAGA'
export const HIDE_PAYPAL_OVERLAY = 'signupv2/HIDE_PAYPAL_OVERLAY'
export const POST_COUPON_PREVIEW = 'signupv2/POST_COUPON_PREVIEW'
export const SOFT_RESET_SIGNUP_FLOW_FORMS = 'signupv2/SOFT_RESET_SIGNUP_FLOW_FORMS'
export const SELECT_FIRST_PLAN = 'signupv2/SELECT_FIRST_PLAN'
export const SEND_TRACKING_EVENT = 'signupv2/SEND_TRACKING_EVENT'
export const SET_3PARTY_SIGNIN_ERROR = 'signupv2/SET_3PARTY_SIGNIN_ERROR'
export const SET_BILLING_ERROR = 'signupv2/SET_BILLING_ERROR'
export const SET_BILLING_FORM_FIELD = 'signupv2/SET_BILLING_FORM_FIELD'
export const SET_BILLING_FORM_SUBMITTING = 'signupv2/SET_BILLING_FORM_SUBMITTING'
export const SET_CREDIT_CARD_IS_VALID = 'signupv2/SET_CREDIT_CARD_IS_VALID'
export const SET_EMAIL_ERROR = 'signupv2/SET_EMAIL_ERROR'
export const SET_FIRST_NAME_ERROR = 'signupv2/SET_FIRST_NAME_ERROR'
export const SET_LAST_NAME_ERROR = 'signupv2/SET_LAST_NAME_ERROR'
export const SET_PASSWORD_ERROR = 'signupv2/SET_PASSWORD_ERROR'
export const SET_PAYMENT_METHOD = 'signupv2/SET_PAYMENT_METHOD'
export const SET_PLAN_CONFIRMED = 'signupv2/SET_PLAN_CONFIRMED'
export const SET_POSTAL_CODE_ERROR = 'signupv2/SET_POSTAL_CODE_ERROR'
export const SET_PREFER_EXISTING_PAYMENT_METHOD = 'signupv2/SET_PREFER_EXISTING_PAYMENT_METHOD'
export const SET_TERMS_AGREED_CHECKED = 'signupv2/SET_TERMS_AGREED_CHECKED'
export const SET_TERMS_AGREED_CHECKED_ERROR = 'signupv2/SET_TERMS_AGREED_CHECKED_ERROR'
export const SET_URL_PARAMS_TO_STATE = 'signupv2/SET_URL_PARAMS_TO_STATE'
export const SET_USERNAME_ERROR = 'signupv2/SET_USERNAME_ERROR'
export const SHOW_PAYPAL_OVERLAY = 'signupv2/SHOW_PAYPAL_OVERLAY'
export const SUBMIT_CUSTOMIZE_FORM = 'signupv2/SUBMIT_CUSTOMIZE_FORM'
export const UPDATE_COUPON = 'signupv2/UPDATE_COUPON'
export const UPDATE_EMAIL = 'signupv2/UPDATE_EMAIL'
export const UPDATE_FIRST_NAME = 'signupv2/UPDATE_FIRST_NAME'
export const UPDATE_LAST_NAME = 'signupv2/UPDATE_LAST_NAME'
export const UPDATE_PASSWORD = 'signupv2/UPDATE_PASSWORD'
export const UPDATE_POSTAL_CODE = 'signupv2/UPDATE_POSTAL_CODE'
export const UPDATE_SELECTED_PLAN = 'signupv2/UPDATE_SELECTED_PLAN'
export const UPDATE_USERNAME = 'signupv2/UPDATE_USERNAME'
export const UPDATE_USERNAME_AND_AVATAR_TO_RANDOM = 'signupv2/UPDATE_USERNAME_AND_AVATAR_TO_RANDOM'
