import { LOCATION_CHANGE } from 'redux-first-history'

import { HIDE_MODAL, SHOW_MODAL } from '../actions'

const initialState = {
  context: null,
  modalType: null,
  modalProps: {},
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      switch (action.payload.location.pathname) {
        case '/forgot':
          return {
            modalType: 'USER_FORGOT_PASSWORD',
          }
        case '/premium-rss':
          return { modalType: 'FIRST_ONLY_RSS' }
        case '/login':
        case '/login/store':
          return { modalType: 'USER_LOGIN' }
        case '/password':
          return {
            modalType: 'USER_FORGOT_PASSWORD_RESET',
          }
        case '/settings/account/billing':
          return state
        default:
          return state.modalType ? initialState : state
      }
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case HIDE_MODAL:
      return initialState
    default:
      return state
  }
}

export default modal
