/** **********************
 *
 *  Sorting
 *
 ************************ */

// Sort by date/time ascending (Oldest > Newest)
export const sortByDateAsc = (a, b) => {
  const dateA = new Date(a)
  const dateB = new Date(b)

  return dateA - dateB
}

// Sort by date/time descending (Newest > Oldest)
export const sortByDateDesc = (a, b) => {
  const dateA = new Date(a)
  const dateB = new Date(b)

  return dateB - dateA
}
