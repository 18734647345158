const getPositionOffsetsFromTheme = (theme, position) => {
  // The outline-white theme has an arrow attached to it which requires the whole dropdown to be shifted a little bit.
  if (theme === 'white-bubble') return getOffsetFromPosition(15, position)

  return [0, 0]
}

// Not all positions are supported for dropdowns with attached arrows.
const getOffsetFromPosition = (offset, position) => {
  switch (position) {
    case 'top':
      return [0, -offset]
    case 'bottom':
      return [0, offset]
    case 'right':
      return [offset, 0]
    case 'left':
      return [-offset, 0]
    default:
      return [0, 0]
  }
}

export default getPositionOffsetsFromTheme
