import React, { Component } from 'react'
import { Tooltip as materializeTooltip } from 'materialize-css'
import PropTypes from 'prop-types'

class Tooltip extends Component {
  tooltipInstance = null

  childRef = React.createRef()

  componentDidMount() {
    this.createTooltip()
  }

  componentDidUpdate(prevProps, _prevState) {
    const { children, enterDelay, position, text } = this.props

    if (
      children !== prevProps.children ||
      enterDelay !== prevProps.enterDelay ||
      position !== prevProps.position ||
      text !== prevProps.text
    ) {
      this.createTooltip()
    }
  }

  componentWillUnmount() {
    this.tooltipInstance?.destroy()
  }

  createTooltip() {
    const { enterDelay } = this.props

    this.tooltipInstance?.destroy()

    this.tooltipInstance = materializeTooltip.init(this.childRef.current, {
      enterDelay,
    })
  }

  render() {
    const { children, position, text } = this.props

    return React.cloneElement(children, {
      'data-position': position,
      'data-tooltip': text,
      ref: this.childRef,
    })
  }
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  enterDelay: PropTypes.number,
  position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  text: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  enterDelay: 200,
  position: 'bottom',
}

export default Tooltip
