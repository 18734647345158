import React from 'react'

import gif from './RvBLoading.gif'

import './styles.scss'

const Loading = _props => (
  <div className='loader'>
    <img className='loader__img' src={gif} alt='loading gif' />
  </div>
)

export default Loading
