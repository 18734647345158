const initialState = { list: [], pagination: {} }

export default (state = initialState, { type, pagination, data: list }) => {
  switch (type) {
    case 'communityApp/c/achievements_RESP':
      return {
        ...state,
        pagination,
        list,
      }
    default:
      return state
  }
}
