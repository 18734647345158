import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ShareItem extends Component {
  static propTypes = {
    clickedMessage: PropTypes.string,
    hoveredMessage: PropTypes.string,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    target: PropTypes.oneOf(['', '_blank']),
  }

  static defaultProps = {
    clickedMessage: '',
    hoveredMessage: '',
    target: '',
  }

  state = {
    isClicked: false,
    isHovered: false,
  }

  messageTimeout = null

  handleClick = e => {
    const { onClick } = this.props

    e.preventDefault()
    onClick()
    this.hideHoveredMessage()
    this.showClickedMessage()
  }

  handleMouseEnter = () => {
    this.hideClickedMessage()
    this.showHoveredMessage()
  }

  handleMouseLeave = () => {
    this.hideHoveredMessage()
  }

  hideClickedMessage = () => {
    this.setState({ isClicked: false })
  }

  hideHoveredMessage = () => {
    this.setState({ isHovered: false })
  }

  showClickedMessage = () => {
    clearTimeout(this.message)

    this.setState(
      {
        isClicked: true,
      },
      () => {
        this.message = setTimeout(() => {
          this.setState({ isClicked: false })
        }, 1000)
      }
    )
  }

  showHoveredMessage = () => {
    this.setState({ isHovered: true })
  }

  render() {
    const { clickedMessage, hoveredMessage, href, icon, target } = this.props
    const { isClicked, isHovered } = this.state

    return (
      <li className='share-menu__item'>
        <a
          className='share-menu__link'
          href={href}
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          target={target || undefined}
        >
          <i className={`icon-${icon}`} />
        </a>
        {clickedMessage && isClicked && (
          <span className='share-menu__message'>{clickedMessage}</span>
        )}
        {hoveredMessage && isHovered && (
          <span className='share-menu__message'>{hoveredMessage}</span>
        )}
      </li>
    )
  }
}

export default ShareItem
