const initialState = {
  chatMeta: null,
  isFetching: false,
}

export default (state = initialState, { type, response, data, ...rest }) => {
  switch (type) {
    case 'communityApp/subCommunity/chat_REQ':
      return {
        ...state,
        isFetching: true,
      }
    case 'communityApp/subCommunity/chat_RESP':
      return {
        ...state,
        chatMeta: data.chat,
        isFetching: false,
      }
    case 'communityApp/subCommunity/chat_ERR':
      // 403 responses on this endpoint indicate we do not have a membership
      if (response.status === 403) {
        return { initialState }
      }
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}
