import React from 'react'

import Avatar from 'common/components/Avatar'
import { a11yButtonProps } from 'common/helpers/accessibility'

import { TAB_ALL_RESULTS } from 'rootApp/containers/SearchV2/helpers/constants'
import useGoToUserPage from 'rootApp/containers/SearchV2/helpers/useGoToUserPage'

const AllResultsUser = ({ closeSearchV2, goToUserPage, searchTerm, user }) => {
  const { profile_picture, name } = user

  const goToThisUserPage = useGoToUserPage({
    closeSearchV2,
    name,
    goToUserPage,
    searchTerm,
    tab: TAB_ALL_RESULTS,
    user,
  })

  return (
    <div
      {...a11yButtonProps(goToThisUserPage)}
      className='search-v2-all-results__user'
    >
      <Avatar border={2} size={50} src={profile_picture} />
      <div className='search-v2-all-results__user-username'>{name}</div>
    </div>
  )
}

const UsersColumn = ({ closeSearchV2, goToUserPage, users, searchTerm }) => (
  <div className='search-v2-all-results__users'>
    {users.map(user => (
      <AllResultsUser
        key={user.id}
        closeSearchV2={closeSearchV2}
        goToUserPage={goToUserPage}
        user={user}
        searchTerm={searchTerm}
      />
    ))}
  </div>
)

export default UsersColumn
