import React from 'react'
import classnames from 'classnames'

function QueueToggleIcon({ isQueued, type }) {
  return (
    <i
      className={classnames({
        'icon-rt-add-playlist': type === 'playlist',
        'icon-plus-circle': !isQueued && type === 'show',
        'icon-rt-watch-later':
          !isQueued && (type === 'episode' || type === 'bonus_feature'),
        'icon-rt-added-check': isQueued && type !== 'playlist',
      })}
    />
  )
}

export default QueueToggleIcon
