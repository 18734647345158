import React from 'react'
import { Link } from 'react-router-dom'

import ExternalLink from 'common/components/ExternalLink'
import { useDateBasedFeatureFlag } from 'common/hooks'
import { trackClick } from 'common/metrics'

import SocialLinks from '../SocialLinks'
import GraphicDesignEasterEgg from './GraphicDesignEasterEgg'
import IncrediblyHandsomeEasterEgg from './IncrediblyHandsomeEasterEgg'

import './styles.scss'

const businessHref = 'https://www.business.roosterteeth.com'
const careersHref = 'https://jobs.lever.co/roosterteeth'
const conductHref = '/code-of-conduct'
const contactHref = '/contact'
const doNotSellHref = '#do-not-sell'
const privacyHref = 'https://www.warnermediaprivacy.com/policycenter/b2c/WM/'
const supportHref = 'https://roosterteethsupport.zendesk.com/hc/en-us'
const termsHref = '/terms-of-use'
const devicesHref = 'https://devices.roosterteeth.com'
const currentYear = new Date().getFullYear()
const storeLink = 'https://store.roosterteeth.com'
const signupLink = '/signup'
const podcastsLink = '/series?genres=Podcasts'

const trackFooterLink = context => trackClick('footer_link', { context })

const Footer = () => {
  const handleDoNotSellClick = e => {
    e.preventDefault()
    window.Optanon?.ToggleInfoDisplay()
    trackFooterLink(doNotSellHref)
  }

  const shouldHideFirstButton = useDateBasedFeatureFlag(
    'disableFirstUpgradesDate'
  )

  return (
    <footer className='footer'>
      <div className='footer__social'>
        <SocialLinks onClick={trackFooterLink} />
      </div>

      <div className='footer__links'>
        <Link to={termsHref} onClick={() => trackFooterLink(termsHref)}>
          Terms of Use
        </Link>
        <Link to={conductHref} onClick={() => trackFooterLink(conductHref)}>
          Code of Conduct
        </Link>
        <ExternalLink
          aria-label='Privacy Policy'
          href={privacyHref}
          onClick={() => trackFooterLink(privacyHref)}
        >
          Privacy Policy
        </ExternalLink>
        <ExternalLink
          aria-label='Ad Choices'
          href={privacyHref}
          onClick={() => trackFooterLink(privacyHref)}
        >
          Ad Choices
        </ExternalLink>
        <Link to={contactHref} onClick={() => trackFooterLink(contactHref)}>
          Contact
        </Link>
        <ExternalLink
          aria-label='Rooster Teeth Careers'
          href={careersHref}
          onClick={() => trackFooterLink(careersHref)}
        >
          Careers
        </ExternalLink>
        <ExternalLink
          aria-label='Rooster Teeth Business'
          href={businessHref}
          onClick={() => trackFooterLink(businessHref)}
        >
          Business
        </ExternalLink>
        <ExternalLink
          aria-label='Support'
          href={supportHref}
          onClick={() => trackFooterLink(supportHref)}
        >
          Support
        </ExternalLink>
        <Link
          to={doNotSellHref}
          className='ot-sdk-show-settings'
          onClick={handleDoNotSellClick}
        >
          Cookie Settings
        </Link>
        <ExternalLink
          aria-label='Rooster Teeth Devices'
          href={devicesHref}
          onClick={() => trackFooterLink(devicesHref)}
        >
          Devices
        </ExternalLink>
        <ExternalLink
          aria-label='Rooster Teeth Store'
          href={storeLink}
          onClick={() => trackFooterLink(storeLink)}
        >
          Store
        </ExternalLink>
        <Link to={podcastsLink} onClick={() => trackFooterLink(podcastsLink)}>
          Podcasts
        </Link>
        {!shouldHideFirstButton && (
          <Link to={signupLink} onClick={() => trackFooterLink(signupLink)}>
            Join FIRST
          </Link>
        )}

        <GraphicDesignEasterEgg />
        <IncrediblyHandsomeEasterEgg />
      </div>
      <div className='footer__copyright'>
        © 2004 – {currentYear} Rooster Teeth Productions, LLC. All rights
        reserved
      </div>
    </footer>
  )
}

export default Footer
