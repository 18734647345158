/* eslint-disable camelcase */
import { takeEvery, select } from 'redux-saga/effects'
import { simpleGET, simpleCall } from 'common/helpers/saga'
import { endpoints } from 'common/config'

function selectNextCursor(state) {
  return state.communityReducer.subCommunity.related.next
}

// warning this is not idempotent, subsequent calls will return the next page
export function* getRelated({ id }) {
  const next = yield select(selectNextCursor)
  let url = new URL(
    `${endpoints.communityBase}/v1/relationships/community/${id}?tag=related`
  )

  if (next) {
    url.searchParams.append('cursor', next)
  }

  yield simpleGET({ url, type: 'communityApp/subCommunity/related' })
}

export function* createRelation({ from, to }) {
  yield simpleCall({
    authenticated: true,
    type: 'communityApp/subCommunity/createRelation',
    url: `${endpoints.communityBase}/v1/relationships/community/${from}`,
    method: 'POST',
    body: {
      id: to,
      tag: 'related',
    },
  })
}

export function* related() {
  yield takeEvery('communityApp/subCommunity/related', getRelated)
  yield takeEvery('communityApp/subCommunity/createRelation', createRelation)
}
