import React, { useMemo } from 'react'

import BlackConfetti from 'common/assets/womens_history_month_confetti_black.svg'
import WhiteConfetti from 'common/assets/womens_history_month_confetti_white.svg'
import NavIcon from 'common/assets/womens_history_month_nav_icon.svg'
import { Tooltip } from 'common/components'
import { trackThemeButtonClick } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const trackClick = () =>
  trackThemeButtonClick('Womens History Month Button Clicked')

const useHelpers = () =>
  useMemo(() => {
    const whiteConfettiImageElem = new Image(36, 50)
    whiteConfettiImageElem.src = WhiteConfetti

    const blackConfettiImageElem = new Image(36, 50)
    blackConfettiImageElem.src = BlackConfetti

    function resetParticle(particle) {
      particle.alpha = Math.random()
      const dice = Math.random()
      if (dice < 0.5) {
        particle.image = whiteConfettiImageElem
      } else {
        particle.image = blackConfettiImageElem
      }
      particle.width = particle.image.width
      particle.height = particle.image.height
    }

    function drawParticle(context, particle) {
      context.drawImage(
        particle.image,
        particle.x,
        particle.y,
        particle.width,
        particle.height
      )
    }

    return { resetParticle, drawParticle }
  }, [])

const WomensHistoryMonthEasterEgg = () => {
  const { resetParticle, drawParticle } = useHelpers()

  return (
    <FallingParticlesEasterEggButton
      compression={1}
      drawParticle={drawParticle}
      particleCount={30}
      resetParticle={resetParticle}
      speed={1.2}
      startYOffset={150}
      stopAfter={100}
      element={({ toggleFalling, falling }) => (
        <Tooltip text="Women's History Month">
          <div
            className={`whm-easter-egg-nav-button ${falling ? 'active' : ''}`}
            role='button'
            onClick={() => {
              trackClick()
              toggleFalling()
            }}
            onKeyPress={toggleFalling}
            tabIndex={0}
          >
            <img src={NavIcon} alt="Women's History Month Icon" />
          </div>
        </Tooltip>
      )}
    />
  )
}

export default WomensHistoryMonthEasterEgg
