import store from 'store'

import { config } from 'common/config'
import growthbook from 'common/helpers/growthbook'

import { getLoggedInUser } from 'auth/selectors'

import { isMobileUA } from './helpers'

export const authenticatedEverKey = 'authenticated-ever'

const deviceType = /iPad/.test(navigator.userAgent)
  ? 't'
  : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
      navigator.userAgent
    )
  ? 'm'
  : 'd'

export const pageView = (user, pathName = '', opts = {}) => {
  if (user) {
    opts = { ...opts, user_id: user.id, user_tier: user.attributes.member_tier }
  }
  if (!config.metrics.send) return
  window.analytics?.page(pathName, {
    ...opts,
    on_mobile_device: isMobileUA,
  })
}

export const trackEvent = (type, getOptions = {}) => {
  if (!config.metrics.send) return
  const state = store.getState()

  // Allow callers to provide either an object or a function which has access to state.
  let options
  if (typeof getOptions === 'function') options = getOptions(state)
  else options = getOptions

  const userTierFromState = getAnalyticsMemberTier(getLoggedInUser(state))
  window.analytics?.track(type, {
    // Will be overridden if `user_tier` is also present in `options`
    user_tier: userTierFromState,
    on_mobile_device: isMobileUA,
    ...options,
  })
}

export const trackThemeButtonClick = label =>
  trackEvent('Theme Button Clicked', { label })

// Get the member tier from a raw user object or undefined. Returns undefined if the user object is defined but `user?.attributes?.member_tier` does not contain `first` or `free`.
export const getAnalyticsMemberTier = user => {
  if (!user) return 'anon'
  const memberTierFromUser = user?.attributes?.member_tier
  if (memberTierFromUser === 'first') return 'premium'
  if (memberTierFromUser === 'free') return 'free'
  // Return undefined if we found anything unexpected in the attributes object.
}

export const logoutUser = () => {
  if (!config.metrics.send) return
  window.analytics?.reset()

  resetGrowthBookUserId()
}

// Use the Segment anonymous ID for GrowthBook anonymous users. If we do not have consent to use window.analytics, set growthbook ID to random string.
export const resetGrowthBookUserId = () => {
  let anonymousId = window.analytics?.user?.()?.anonymousId()

  // In dev, make sure that anons always get a valid anonymous ID for testing.
  if (process.env.NODE_ENV === 'development' && !anonymousId) {
    anonymousId = `${Math.random()}`.substring(2)
  }

  growthbook.setAttributes({
    loggedIn: false,
    userTier: 'anon',
    id: anonymousId,
  })
}

export const loginUser = user => {
  if (!config.metrics.send) return
  window.analytics?.identify(user.id, {
    email: user.attributes.email,
    user_tier: user.attributes.member_tier,
    username: user.attributes.username,
    on_mobile_device: isMobileUA,
  })

  growthbook.setAttributes({
    id: user.id,
    loggedIn: true,
    email: user.attributes.email,
    userTier: user.attributes.member_tier,
    username: user.attributes.username,
  })
}

export const trackClick = (label, { context } = {}) => {
  trackEvent('ClickEvent', {
    context,
    label,
    platform: 'web',
  })
}

export const trackSearchSelection = ({ query, tab, type, uuid }) => {
  trackEvent('SearchEvent', {
    label: 'search_item_selected',
    query_used: query,
    selected_tab: tab,
    target: uuid,
    target_type: type,
  })
  sendGA({
    event: 'searchEvent',
    eventCategory: 'Search Action',
    eventAction: 'Selected',
    eventLabel: query,
  })
}

export const trackSignupButtonClick = location => {
  trackEvent('Signup Button Clicked', {
    location,
    platform: 'web',
  })
}

export const trackMute = ({ targetUserId, userId }) =>
  trackEvent('SocialEvent', {
    label: 'user_muted',
    platform: 'web',
    target_id: targetUserId,
    target_type: 'user',
    user_id: userId,
  })

export const trackJoinSubCommunity = ({ communityUuid, userId }) =>
  trackCommunityJoinOrLeave('community_joined', { communityUuid, userId })

export const trackLeaveSubCommunity = ({ communityUuid, userId }) =>
  trackCommunityJoinOrLeave('community_left', { communityUuid, userId })

export const trackCommunityJoinOrLeave = (label, { communityUuid, userId }) =>
  trackEvent('SocialEvent', {
    label,
    platform: 'web',
    target_id: communityUuid,
    target_type: 'community',
    user_id: userId,
  })

export const trackLikeEvent = postId =>
  trackEvent('Item Rated', {
    item_id: postId,
    item_type: 'post',
    rating: 1,
    platform: 'web',
  })

export const sendGA = eventObj => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(eventObj)
}

export const sendCriteo = (user, eventObj) => {
  window.criteo_q = window.criteo_q || []

  const subType = user ? user.attributes.member_tier : 'none'

  const data = [
    { event: 'setAccount', account: 64750 },
    { event: 'setSiteType', type: deviceType },
    { event: 'setData', ui_account: subType },
    ...eventObj,
  ]
  window.criteo_q.push(...data)
  // console.log('send criteo', data)
}
