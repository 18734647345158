import React, { useCallback } from 'react'
import { deviceType } from 'detect-it'

import SafeLink from 'common/components/SafeLink'
import { trackEvent } from 'common/metrics'

const isTouch = deviceType === 'hybrid' || deviceType === 'touchOnly'

const WrappedContentBlockBase = ({ children, className, ...props }, ref) => (
  <div
    {...props}
    className={`content-block ${
      isTouch ? 'content-block--touch' : 'content-block--no-touch'
    } ${className || ''}`}
    ref={ref}
  >
    {children}
  </div>
)

const ContentBlockBase = React.forwardRef(WrappedContentBlockBase)

export const Header1 = ({ children }) => (
  <h2 className='content-block__header-1'>{children}</h2>
)
export const Header2 = ({ children }) => (
  <h2 className='content-block__header-2'>{children}</h2>
)

export const ContentBlockHeaderLink = ({
  contentBlock,
  header1,
  header2,
  linkText = 'View All',
  location,
  url,
}) => {
  const onClick = useCallback(
    () => {
      trackEvent('Content Block View All Clicked', {
        content_block_display_type: contentBlock?.attributes?.display_type,
        content_block_item_use_type: contentBlock?.attributes?.item_use_type,
        content_block_uuid: contentBlock?.attributes?.uuid,
        location,
        link_to: url,
      })
    },
    [contentBlock, location, url]
  )

  if (!url) {
    return (
      <>
        {header1 ? <Header1>{header1}</Header1> : null}
        {header2 ? <Header2>{header2}</Header2> : null}
      </>
    )
  }

  return (
    <>
      {header1 ? <Header1>{header1}</Header1> : null}
      <Header2>
        <SafeLink onClick={onClick} title={linkText} url={url}>
          <span className='content-block__header-2-hoverable-link'>
            {header2}
            <span className='content-block__header-view-all'>
              <span className='content-block__header-view-all-text'>
                {linkText}
              </span>
              <i className='content-block__header-view-all-icon icon-keyboard_arrow_right' />
            </span>
          </span>
        </SafeLink>
      </Header2>
    </>
  )
}

export default ContentBlockBase
