import rtApi from '@roosterteethproductions/svod-api'

import {
  GET_LIVESTREAMS_FAILURE,
  GET_LIVESTREAMS_REQUEST,
  GET_LIVESTREAMS_SCHEDULE_FAILURE,
  GET_LIVESTREAMS_SCHEDULE_REQUEST,
  GET_LIVESTREAMS_SCHEDULE_SUCCESS,
  GET_LIVESTREAMS_SHOWS_FAILURE,
  GET_LIVESTREAMS_SHOWS_REQUEST,
  GET_LIVESTREAMS_SHOWS_SUCCESS,
  GET_LIVESTREAMS_SUCCESS,
  REFRESH_LIVESTREAMS,
} from './types'

const requestLivestreams = () => ({
  type: GET_LIVESTREAMS_REQUEST,
})

const fetchLivestreamsFailure = err => ({
  type: GET_LIVESTREAMS_FAILURE,
  error: err,
})

const receiveLivestreams = (json, isAuthenticated, channel) => ({
  type: GET_LIVESTREAMS_SUCCESS,
  lastUpdated: Date.now(),
  isLoggedIn: isAuthenticated,
  livestreams: json.data || [],
  selectedChannel: channel,
})

export const fetchLivestreams = (filter = {}) => (dispatch, getState) => {
  const state = getState()
  const { isAuthenticated } = state.authReducer
  const { channel } = filter
  dispatch(requestLivestreams())

  let myChannel = channel
  if (channel === 'everything') {
    myChannel = undefined
  }
  rtApi.livestreams
    .fetchChannel({ channel: myChannel })
    .then(json => dispatch(receiveLivestreams(json, isAuthenticated, channel)))
    .catch(ex => dispatch(fetchLivestreamsFailure(ex)))
}

export const refreshLivestreams = () => ({
  type: REFRESH_LIVESTREAMS,
})

// RT-TV Schedule page actions
const requestLivestreamSchedule = () => ({
  type: GET_LIVESTREAMS_SCHEDULE_REQUEST,
})

const fetchLivestreamScheduleFailure = err => ({
  type: GET_LIVESTREAMS_SCHEDULE_FAILURE,
  error: err,
})

const receiveLivestreamSchedule = json => ({
  type: GET_LIVESTREAMS_SCHEDULE_SUCCESS,
  scheduleLastUpdated: Date.now(),
  livestreamSchedule: json.data || [],
})

export const fetchLivestreamSchedule = () => dispatch => {
  dispatch(requestLivestreamSchedule())

  rtApi.livestreams
    .fetchLivestreamSchedule()
    .then(json => dispatch(receiveLivestreamSchedule(json)))
    .catch(ex => dispatch(fetchLivestreamScheduleFailure(ex)))
}

// Homepage Carousel Livestream Shows actions
const requestLivestreamShows = () => ({
  type: GET_LIVESTREAMS_SHOWS_REQUEST,
})

const fetchLivestreamShowsFailure = err => ({
  type: GET_LIVESTREAMS_SHOWS_FAILURE,
  error: err,
})

const receiveLivestreamShows = json => ({
  type: GET_LIVESTREAMS_SHOWS_SUCCESS,
  showsLastUpdated: Date.now(),
  livestreamShows: json.data || [],
})

export const fetchLivestreamShows = () => dispatch => {
  dispatch(requestLivestreamShows())

  rtApi.livestreams
    .fetchLivestreamShows()
    .then(json => dispatch(receiveLivestreamShows(json)))
    .catch(ex => dispatch(fetchLivestreamShowsFailure(ex)))
}
