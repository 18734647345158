import React, { memo } from 'react'
import { Image, Placeholder } from 'cloudinary-react'
/**
 * Gets memoized Cloudinary Image
 * @param {String} src
 * @param {Bool} responsive
 * @param {String} width
 * @return {Component} MemoCloudinaryImg
 */
const MemoCloudinaryImg = memo(
  ({
    alt,
    src = 'generic_rt_title_card',
    responsive = false,
    width = 'auto',
    lazy = false,
  }) => (
    <div className='image'>
      <Image
        alt={alt}
        className='cld-responsive'
        crop='scale'
        dpr='auto'
        fetchFormat='auto'
        publicId={src}
        responsive={responsive}
        responsiveUseBreakpoints='true'
        secure='true'
        width={width}
        {...(lazy ? { loading: 'lazy' } : {})}
      >
        <Placeholder type='blur' />
      </Image>
    </div>
  )
)

export default MemoCloudinaryImg
