import React from 'react'
import classnames from 'classnames'

import { SecondaryBtn } from 'common/components'

import './styles.scss'

export function CreatePostBtn(props) {
  const { className } = props

  return (
    <SecondaryBtn
      className={classnames(className, 'btn--create-post')}
      {...props}
    >
      <i className='icon icon-new-post' />
      <span className='create-post-value'>Create a new post</span>
    </SecondaryBtn>
  )
}

export default CreatePostBtn
