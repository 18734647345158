import React, { useCallback, useMemo } from 'react'

import { trackClick } from 'common/metrics'

import TabAllResults from '../tabs/TabAllResults'
import TabEpisodes from '../tabs/TabEpisodes'
import TabGroups from '../tabs/TabGroups'
import TabSeries from '../tabs/TabSeries'
import TabUsers from '../tabs/TabUsers'
import {
  TAB_ALL_RESULTS,
  TAB_BONUS_FEATURES,
  TAB_EPISODES,
  TAB_GROUPS,
  TAB_SERIES,
  TAB_USERS,
} from './constants'
import getAnalyticsTabName from './getAnalyticsTabName'

const useTabs = ({
  setSearchResults,
  bonusFeatures,
  episodes,
  groups,
  series,
  users,
}) => {
  const setTabAndTrackEvent = useCallback(
    (tab, isViewMore) => {
      trackClick(isViewMore ? 'search_see_more' : 'search_tab', {
        context: getAnalyticsTabName(tab),
      })
      setSearchResults(sr => ({ ...sr, selectedTab: tab }))
    },
    [setSearchResults]
  )
  const setTabAll = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_ALL_RESULTS, isViewMore),
    [setTabAndTrackEvent]
  )
  const setTabSeries = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_SERIES, isViewMore),
    [setTabAndTrackEvent]
  )
  const setTabEpisodes = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_EPISODES, isViewMore),
    [setTabAndTrackEvent]
  )
  const setTabBonusFeatures = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_BONUS_FEATURES, isViewMore),
    [setTabAndTrackEvent]
  )
  const setTabGroups = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_GROUPS, isViewMore),
    [setTabAndTrackEvent]
  )
  const setTabUsers = useCallback(
    isViewMore => setTabAndTrackEvent(TAB_USERS, isViewMore),
    [setTabAndTrackEvent]
  )

  const tabConfigs = useMemo(
    () => [
      {
        tab: TAB_ALL_RESULTS,
        text: 'All Results',
        selectTab: setTabAll,
        TabComponent: TabAllResults,
      },
      {
        tab: TAB_SERIES,
        text: 'Series',
        selectTab: setTabSeries,
        TabComponent: TabSeries,
        totalItems: series.data.length,
      },
      {
        tab: TAB_EPISODES,
        text: 'Episodes',
        selectTab: setTabEpisodes,
        TabComponent: TabEpisodes,
        totalItems: episodes.data.length,
      },
      {
        tab: TAB_BONUS_FEATURES,
        text: 'Bonus Features',
        selectTab: setTabBonusFeatures,
        TabComponent: props => <TabEpisodes isBonusFeatures {...props} />,
        totalItems: bonusFeatures.data.length,
      },
      {
        tab: TAB_GROUPS,
        text: 'Groups',
        selectTab: setTabGroups,
        TabComponent: TabGroups,
        totalItems: groups.data.length,
      },
      {
        tab: TAB_USERS,
        text: 'Users',
        selectTab: setTabUsers,
        TabComponent: TabUsers,
        totalItems: users.data.length,
      },
    ],
    [
      bonusFeatures.data.length,
      episodes.data.length,
      groups.data.length,
      series.data.length,
      users.data.length,

      setTabAll,
      setTabBonusFeatures,
      setTabEpisodes,
      setTabGroups,
      setTabSeries,
      setTabUsers,
    ]
  )

  return {
    setTabAll,
    setTabSeries,
    setTabEpisodes,
    setTabBonusFeatures,
    setTabGroups,
    setTabUsers,

    tabConfigs,
  }
}

export default useTabs
