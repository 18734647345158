import uniqBy from 'lodash.uniqby'

import { GET_USER_SUCCESS } from 'auth/actions/types'
import { normalizeCommunityUser } from 'communityApp/helpersV2'

import {
  FETCH_COMMUNITY_USER,
  FETCH_COMMUNITY_USER_FAILED,
  FETCH_COMMUNITY_USER_FOLLOWERS,
  FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL,
  FETCH_COMMUNITY_USER_FOLLOWING,
  FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL,
  FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL,
  FETCH_COMMUNITY_USER_SUCCESSFUL,
  FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL,
  FETCH_HOME_FEED,
  FETCH_HOME_FEED_SUCCESSFUL,
  FETCH_MEMBERS_FOR_SUB_COMMUNITY,
  FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL,
  FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER,
  FETCH_SUB_COMMUNITY_FAILED,
  FETCH_SUB_COMMUNITY_FEED,
  FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL,
  FETCH_SUB_COMMUNITY_SUCCESSFUL,
  FETCH_USER_ACTIVITY_FEED,
  FETCH_USER_ACTIVITY_FEED_SUCCESSFUL,
  FOLLOW_COMMUNITY_USER_SUCCESSFUL,
  LIKE_OR_UNLIKE_POST_SUCCESSFUL,
  MUTE_COMMUNITY_USER_SUCCESSFUL,
  SET_IS_EDITING_USER,
  SUBMIT_USER_UPDATE_FORM_FAILED,
  SUBMIT_USER_UPDATE_FORM_SUCCESSFUL,
  UNFOLLOW_COMMUNITY_USER_SUCCESSFUL,
  UNMUTE_COMMUNITY_USER_SUCCESSFUL,
  UPDATE_USER_FORM,
} from './actions/types'

const addToUniqueArray = (array, elem) => [...new Set([...array, elem])]
const removeFromArray = (array, elem) =>
  array.filter(arrayElem => arrayElem !== elem)

export const updateCommunityUserField = (state, userId, values, opts = {}) => {
  const user = Object.values(state.communityUsers).find(
    ({ id }) => id === userId
  )

  let newState = state

  if (user) {
    newState = {
      ...newState,
      communityUsers: {
        ...newState.communityUsers,
        [user.username]: performUpdateCommunityUserField(user, values),
      },
    }
  }

  newState = {
    ...newState,
    subCommunities: newState.subCommunities.map(sub => {
      if (sub?.members?.users?.some(u => u.id === userId)) {
        return {
          ...sub,
          members: {
            ...sub.members,
            users: sub.members.users.map(user => {
              if (user.id === userId) {
                return performUpdateCommunityUserField(user, values)
              }
              return user
            }),
          },
        }
      }
      return sub
    }),
  }

  // Meta fields, such as "following" and "followed" must recurse through other instances of the given community user. To do this, we recurse through other users in the state and update these meta fields on all instances of the user in nested lists such as "following" and "followers" lists.

  if (opts.recurse) {
    const nestedUserListFields = ['followingList', 'followersList']
    for (const username in newState.communityUsers) {
      for (const nestedUserListField of nestedUserListFields) {
        const recursionUser = newState.communityUsers[username]
        const nestedUserList = recursionUser[nestedUserListField]
        // These nested fields are only populated if needed (for example, going to a user's /followers tab)
        if (!nestedUserList || !nestedUserList.users) continue
        const newNestedUserList = nestedUserList.users.map(nestedUser => {
          if (nestedUser.id !== userId) return nestedUser
          return performUpdateCommunityUserField(nestedUser, values)
        })

        newState = {
          ...newState,
          communityUsers: {
            ...newState.communityUsers,
            [username]: {
              ...recursionUser,
              [nestedUserListField]: {
                ...nestedUserList,
                users: newNestedUserList,
              },
            },
          },
        }
      }
    }
  }

  return newState
}

const performUpdateCommunityUserField = (userObj, values) => {
  const newValues = Object.entries(values).reduce((obj, [key, value]) => {
    let newValue
    if (typeof value === 'function') {
      const oldValue = userObj[key]
      newValue = value(oldValue)
    } else {
      newValue = value
    }
    return { ...obj, [key]: newValue }
  }, {})
  return { ...userObj, ...newValues }
}

export const updateCommunityFeedPostField = (state, postId, updater) => ({
  ...state,
  // Update any instances of post in homeFeed, if homeFeed posts exists
  homeFeed: state.homeFeed.posts
    ? {
        ...state.homeFeed,
        posts: mapPostActivitiesWithPerformUpdate(
          state.homeFeed.posts,
          postId,
          updater
        ),
      }
    : {},
  // Update any instances of post in other feeds that may be in the state
  subCommunities: state.subCommunities.map(sub => {
    const posts = sub?.feed?.posts
    if (!posts) return sub
    return {
      ...sub,
      feed: {
        ...sub.feed,
        posts: mapPostActivitiesWithPerformUpdate(posts, postId, updater),
      },
    }
  }),
  // Update any instances of post in other users' activity feeds. This is a bit of a pain because communityUsers is an object and not an array.
  // TODO: Does it really need to be an object?
  communityUsers: Object.values(state.communityUsers).reduce(
    (newCommunityUsersObj, user) => {
      let newUserObj = user
      if (user?.activity?.posts?.length) {
        newUserObj = {
          ...user,
          activity: {
            ...user.activity,
            posts: mapPostActivitiesWithPerformUpdate(
              user.activity.posts,
              postId,
              updater
            ),
          },
        }
      }
      return {
        ...newCommunityUsersObj,
        [user.username]: newUserObj,
      }
    },
    {}
  ),
  // Update any instances of post in explore page
  examplePosts: mapPostActivitiesWithPerformUpdate(
    state.examplePosts,
    postId,
    updater
  ),
})

const mapPostActivitiesWithPerformUpdate = (postActivities, postId, updater) =>
  postActivities.map(postActivity => ({
    ...postActivity,
    post: performUpdatePost(postActivity.post, postId, updater),
  }))

const performUpdatePost = (post, postId, updater) => {
  // If we found the post, update it.
  if (post?.id === postId) {
    if (typeof updater === 'function') {
      return updater(post)
    }
    return updater
  }
  // If a user likes or unlikes a repost, make sure to check
  if (post?.repost) {
    return {
      ...post,
      repost: performUpdatePost(post.repost, postId, updater),
    }
  }
  // Otherwise, do nothing.
  return post
}

const initialState = {
  communityUsers: {},
  exampleCommunities: [],
  examplePosts: [],
  homeFeed: {},
  isEditingUser: false,
  subCommunities: [],
  usernamesLoading: [],
  userUpdateErrors: {},
  userUpdateForm: { username: '', location: '', about: '' },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMUNITY_USER: {
      return {
        ...state,
        usernamesLoading: addToUniqueArray(
          state.usernamesLoading,
          action.username
        ),
      }
    }
    case FETCH_COMMUNITY_USER_SUCCESSFUL: {
      const { user } = action
      const username = user.username
      return {
        ...state,
        usernamesLoading: removeFromArray(state.usernamesLoading, username),
        communityUsers: {
          ...state.communityUsers,
          [username]: {
            // Some attributes are defined in the reducer and should not be overwritten with every successful fetch.
            ...(state.communityUsers[username] || {}),
            ...user,
          },
        },
      }
    }
    case FETCH_COMMUNITY_USER_FAILED: {
      const { username, reason } = action
      return {
        ...state,
        usernamesLoading: removeFromArray(state.usernamesLoading, username),
        communityUsers: {
          ...state.communityUsers,
          [username]: reason,
        },
      }
    }
    case MUTE_COMMUNITY_USER_SUCCESSFUL:
    case UNMUTE_COMMUNITY_USER_SUCCESSFUL: {
      // For successful mutes and unmutes, simply update the corresponding field in communityUsers.
      const newValue = action.type === MUTE_COMMUNITY_USER_SUCCESSFUL
      return updateCommunityUserField(
        state,
        action.userId,
        { muted: newValue },
        { recurse: true }
      )
    }
    case FOLLOW_COMMUNITY_USER_SUCCESSFUL:
    case UNFOLLOW_COMMUNITY_USER_SUCCESSFUL: {
      // For successful follows and unfollows, simply update the corresponding field in communityUsers.
      const newValue = action.type === FOLLOW_COMMUNITY_USER_SUCCESSFUL

      let newState = updateCommunityUserField(
        state,
        action.userId,
        { followed: newValue },
        { recurse: true }
      )

      // If we're unfollowing people, we may be on our "following" page, in which case they must be removed.
      if (action.type === UNFOLLOW_COMMUNITY_USER_SUCCESSFUL) {
        newState = updateCommunityUserField(newState, action.loggedInUserId, {
          followingList: followingList => {
            if (!followingList) return
            return {
              users: followingList.users.filter(
                ({ id }) => id !== action.userId
              ),
              pagination: followingList.pagination,
            }
          },
        })
      }
      return newState
    }
    case FETCH_COMMUNITY_USER_FOLLOWING: {
      return updateCommunityUserField(state, action.userId, {
        followingLoading: true,
      })
    }
    case FETCH_COMMUNITY_USER_FOLLOWING_SUCCESSFUL: {
      return updateCommunityUserField(state, action.userId, {
        followingLoading: false,
        followingList: existingFollowingList => {
          const followingList = existingFollowingList || { users: [] }
          return {
            users: uniqBy(
              [
                ...followingList.users,
                ...action.users.map(userFromCommunityService =>
                  normalizeCommunityUser({ userFromCommunityService })
                ),
              ],
              'id'
            ),
            pagination: action.pagination,
          }
        },
      })
    }
    case FETCH_COMMUNITY_USER_FOLLOWERS: {
      return updateCommunityUserField(state, action.userId, {
        followersLoading: true,
      })
    }
    case FETCH_COMMUNITY_USER_FOLLOWERS_SUCCESSFUL: {
      return updateCommunityUserField(state, action.userId, {
        followersLoading: false,
        followersList: existingFollowersList => {
          const followersList = existingFollowersList || { users: [] }
          return {
            users: uniqBy(
              [
                ...followersList.users,
                ...action.users.map(userFromCommunityService =>
                  normalizeCommunityUser({ userFromCommunityService })
                ),
              ],
              'id'
            ),
            pagination: action.pagination,
          }
        },
      })
    }
    case FETCH_MEMBERSHIPS_FOR_CURRENT_PROFILE_USER: {
      return updateCommunityUserField(state, action.userId, {
        membershipsLoading: true,
      })
    }
    // NOTE: We don't do any pagination for this, since the total number of memberships belonging to any one user should be in the low double digits at most.
    case FETCH_COMMUNITY_USER_MEMBERSHIPS_SUCCESSFUL: {
      return updateCommunityUserField(state, action.userId, {
        membershipsLoading: false,
        membershipsList: () => ({
          listByCommunityId: new Map(
            action.memberships.map(m => [m.community_id, m])
          ),
          memberships: action.memberships,
        }),
      })
    }
    case FETCH_HOME_FEED: {
      return {
        ...state,
        homeFeed: {
          ...state.homeFeed,
          loading: true,
        },
      }
    }
    case FETCH_HOME_FEED_SUCCESSFUL: {
      const existingPosts = state.homeFeed?.posts || []
      const newPosts = action.resetPagination
        ? action.posts
        : [...existingPosts, ...action.posts]
      return {
        ...state,
        homeFeed: {
          loading: false,
          posts: uniqBy(newPosts, postActivity => postActivity.post.id),
          pagination: action.pagination,
        },
      }
    }
    case FETCH_SUB_COMMUNITY_SUCCESSFUL: {
      return {
        ...state,
        missingSubCommunity: undefined,
        subCommunities: uniqBy(
          [...state.subCommunities, action.subCommunity],
          'id'
        ),
      }
    }
    case FETCH_SUB_COMMUNITY_FAILED: {
      return {
        ...state,
        missingSubCommunity: action.subCommunitySlug,
      }
    }
    case FETCH_SUB_COMMUNITY_FEED: {
      return {
        ...state,
        subCommunities: state.subCommunities.map(sub => {
          if (sub.id === action.subCommunityId) {
            return {
              ...sub,
              feed: {
                ...sub?.feed,
                loading: true,
              },
            }
          }
          return sub
        }),
      }
    }
    case FETCH_MEMBERS_FOR_SUB_COMMUNITY: {
      return {
        ...state,
        subCommunities: state.subCommunities.map(sub => {
          if (sub.id === action.subCommunityId) {
            return {
              ...sub,
              members: {
                ...sub?.members,
                loading: true,
              },
            }
          }
          return sub
        }),
      }
    }
    case FETCH_MEMBERS_FOR_SUB_COMMUNITY_SUCCESSFUL: {
      return {
        ...state,
        subCommunities: state.subCommunities.map(sub => {
          if (sub.id === action.subCommunityId) {
            const normalizedUsersFromAction = action.users.map(
              userFromCommunityService =>
                normalizeCommunityUser({ userFromCommunityService })
            )
            const existingUsers = sub?.members?.users || []
            return {
              ...sub,
              members: {
                loading: false,
                totalMembersCount: action.totalMembersCount,
                users: uniqBy(
                  [...existingUsers, ...normalizedUsersFromAction],
                  'id'
                ),
                pagination: action.pagination,
              },
            }
          }
          return sub
        }),
      }
    }
    case FETCH_SUB_COMMUNITY_FEED_SUCCESSFUL: {
      return {
        ...state,
        subCommunities: state.subCommunities.map(sub => {
          if (sub.id === action.subCommunityId) {
            const existingPosts = sub?.feed?.posts || []
            const newPosts = action.resetPagination
              ? action.posts
              : [...existingPosts, ...action.posts]
            return {
              ...sub,
              feed: {
                loading: false,
                posts: uniqBy(newPosts, postActivity => postActivity.post.id),
                stickiedPost: action.stickiedPost,
                pagination: action.pagination,
              },
            }
          }
          return sub
        }),
      }
    }
    case FETCH_USER_ACTIVITY_FEED: {
      return updateCommunityUserField(state, action.userId, {
        activity: existingActivity => ({ ...existingActivity, loading: true }),
      })
    }
    case FETCH_USER_ACTIVITY_FEED_SUCCESSFUL: {
      return updateCommunityUserField(state, action.userId, {
        activity: existingActivity => {
          const existingPosts = existingActivity?.posts || []
          const newPosts = action.resetPagination
            ? action.posts
            : [...existingPosts, ...action.posts]

          return {
            ...existingActivity,
            loading: false,
            posts: uniqBy(newPosts, postActivity => postActivity.post.id),
            pagination: action.pagination,
          }
        },
      })
    }
    // If the user updates the post from the update modal, show that change in the feed.
    case 'common/PATCH_POST_RESP': {
      const { data: newPost } = action
      return updateCommunityFeedPostField(state, newPost.id, post => ({
        // Responses to the PATCH Post endpoint do not include reposts.
        ...newPost,
        repost: post.repost,
      }))
    }
    // If the user deletes the post, show that change in the feed.
    case 'common/DELETE_POST_RESP': {
      const { data: deletedPost } = action
      return updateCommunityFeedPostField(state, deletedPost.id, deletedPost)
    }
    case LIKE_OR_UNLIKE_POST_SUCCESSFUL: {
      const { postId, likes } = action
      return updateCommunityFeedPostField(state, postId, post => ({
        ...post,
        metadata: { ...post.metadata, likes },
      }))
    }
    case FETCH_EXPLORE_POSTS_AND_COMMUNITIES_SUCCESSFUL: {
      return {
        ...state,
        examplePosts: action.examplePosts,
        exampleCommunities: action.exampleCommunities,
      }
    }
    case SET_IS_EDITING_USER: {
      return {
        ...state,
        isEditingUser: action.isEditingUser,
      }
    }
    case UPDATE_USER_FORM: {
      return {
        ...state,
        userUpdateForm: {
          ...state.userUpdateForm,
          ...action.fields,
        },
      }
    }
    case SUBMIT_USER_UPDATE_FORM_SUCCESSFUL: {
      const {
        userFromBusinessService: {
          id,
          attributes: { about, location, pictures, username },
        },
      } = action
      // Check to see if the username changed. If it did change, we need to change the key (username) in the communityUsers object.
      const userObject = Object.values(state.communityUsers).find(
        communityUser => communityUser.id === id
      )

      const { username: previousUsername } = userObject
      const usernameChanged = previousUsername !== username
      let newState = state
      if (usernameChanged) {
        delete newState.communityUsers[previousUsername]
        newState = {
          ...newState,
          communityUsers: {
            ...newState.communityUsers,
            [username]: userObject,
          },
        }
      }
      return {
        ...updateCommunityUserField(
          newState,
          id,
          { about, location, pictures, username },
          { recurse: true }
        ),
        userUpdateErrors: [],
        isEditingUser: false,
      }
    }
    case SUBMIT_USER_UPDATE_FORM_FAILED: {
      const { errors } = action
      // For some reason, the "about" field has a weird name in the API errors object.
      errors.about = errors['user_profile.about']
      return { ...state, userUpdateErrors: errors }
    }
    // This is called whenever the avatar is changed (via "getUserObject()"").
    case GET_USER_SUCCESS: {
      const userFromBusinessService = action.user
      const {
        id,
        attributes: { pictures },
      } = userFromBusinessService

      return {
        ...updateCommunityUserField(state, id, { pictures }, { recurse: true }),
        userUpdateErrors: [],
      }
    }
    default:
      return state
  }
}

export default reducer
