import React from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'

import useScrollHelpers from './useScrollHelpers'

import './styles.scss'

export const RTOptionCarousel = ({ children }) => {
  const {
    showPrevButton,
    showNextButton,

    containerRef,
    prevButtonRef,
    nextButtonRef,

    goBack,
    goForward,
  } = useScrollHelpers()

  return (
    <div className='rt-option-carousel__outer'>
      <div
        ref={containerRef}
        className={`rt-option-carousel__container ${
          showPrevButton ? 'rt-option-carousel__container--show-prev' : ''
        } ${showNextButton ? 'rt-option-carousel__container--show-next' : ''}`}
      >
        {children}
      </div>
      {showPrevButton && (
        <div
          className='rt-option-carousel__page-control rt-option-carousel__page-control--prev'
          {...a11yButtonProps(goBack)}
          ref={prevButtonRef}
        >
          <i className='icon icon-chevron-left' />
        </div>
      )}
      {showNextButton && (
        <div
          className='rt-option-carousel__page-control rt-option-carousel__page-control--next'
          {...a11yButtonProps(goForward)}
          ref={nextButtonRef}
        >
          <i className='icon icon-chevron-right' />
        </div>
      )}
    </div>
  )
}

export const RTOptionCarouselOption = ({
  active,
  attrs,
  children,
  onClick,
}) => (
  <div
    className={`rt-option-carousel__option ${
      active ? 'rt-option-carousel__option--active' : ''
    }`}
    {...a11yButtonProps(onClick)}
    {...attrs}
  >
    {children}
  </div>
)
