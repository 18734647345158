/* eslint-disable camelcase */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { endpoints } from 'common/config'
import { simpleCall } from 'common/helpers/saga'
import { trackEvent } from 'common/metrics'

export function* createCommunity({
  chat_enabled = true,
  community_name: name,
  description = '',
  header,
  is_first = false,
  logo,
  official = false,
  public: public_community = true,
  slug,
  staff_community = false,
  userId,
}) {
  let res = yield simpleCall({
    authenticated: true,
    type: 'communityApp/CREATE',
    url: endpoints.communityBase + '/v1/communities',
    method: 'POST',
    body: {
      slug: encodeURIComponent(slug),
      name,
      description,
      chat_enabled,
      staff_community,
      official,
      header,
      logo,
      public: public_community,
      is_first,
    },
  })

  if (res.data && res.data.id) {
    trackEvent('SocialEvent', {
      label: 'community_created',
      platform: 'web',
      target_id: res.data.id,
      target_type: '',
      user_id: userId,
    })
  }
}

export function* isNameUnique({ name }) {
  try {
    yield call(delay, 500)
    yield put({ type: 'communityApp/IS_NAME_UNIQUE_REQ' })
    const resp = yield (yield call(
      fetch,
      endpoints.communityBase +
        '/v1/communities/unique/name/' +
        encodeURIComponent(name)
    )).json()

    yield put({
      type: 'communityApp/IS_NAME_UNIQUE_RESP',
      isUnique: resp.data.name,
    })
  } catch (e) {
    yield put({
      type: 'communityApp/IS_NAME_UNIQUE_RESP_ERR',
      resp: { error: e },
    })
  }
}

export function* isSlugUnique({ slug }) {
  try {
    yield call(delay, 500)
    yield put({ type: 'communityApp/IS_UNIQUE_REQ' })
    const resp = yield (yield call(
      fetch,
      endpoints.communityBase +
        '/v1/communities/unique/slug/' +
        encodeURIComponent(encodeURIComponent(slug))
    )).json()

    yield put({ type: 'communityApp/IS_UNIQUE_RESP', isUnique: resp.data.slug })
  } catch (e) {
    yield put({ type: 'communityApp/IS_UNIQUE_RESP_ERR', resp: { error: e } })
  }
}

export function* create() {
  yield takeEvery('communityApp/CREATE', createCommunity)
  yield takeLatest('communityApp/IS_UNIQUE', isSlugUnique)
  yield takeLatest('communityApp/IS_NAME_UNIQUE', isNameUnique)
}
