import React from 'react'

import { TAB_SERIES } from '../../helpers/constants'
import EmptySearch from '../shared/EmptySearch'
import WrappedShowCard from '../shared/WrappedShowCard'

import './styles.scss'

const TabSeries = ({ closeSearchV2, series, searchTerm }) =>
  series.data.length === 0 ? (
    <EmptySearch />
  ) : (
    <div className='search-v2-series'>
      {series.data?.map(series => (
        <WrappedShowCard
          key={series.uuid}
          closeSearchV2={closeSearchV2}
          series={series}
          searchTerm={searchTerm}
          tab={TAB_SERIES}
        />
      ))}
    </div>
  )

export default TabSeries
