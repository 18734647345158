import React from 'react'
import { Image, Placeholder } from 'cloudinary-react'

import SecondaryBtn from 'common/components/Buttons/Secondary'

import './styles.scss'

const MerchCardV2 = ({
  cloudinaryPublicId,
  href,
  imageSrc,
  onClick,
  onLoad,
}) => {
  let cloudinaryPublicIdString = cloudinaryPublicId
  if (cloudinaryPublicIdString?.includes('f_auto')) {
    cloudinaryPublicIdString = cloudinaryPublicIdString.split('f_auto')[1]
  }

  return (
    <a
      className='merch-collection-card'
      href={href}
      onClick={onClick}
      rel='noopener noreferrer'
      target='_blank'
    >
      <div className='content'>
        {imageSrc ? (
          <img src={imageSrc} alt='Placeholder' />
        ) : (
          <Image
            className='cld-responsive'
            crop='scale'
            fetchFormat='auto'
            dpr='auto'
            onLoad={onLoad}
            publicId={cloudinaryPublicIdString}
            secure='true'
            width='600'
          >
            <Placeholder type='blur' />
          </Image>
        )}
        <SecondaryBtn
          bg='dark'
          className='merch-collection-card__shop-btn'
          type='button'
        >
          SHOP NOW
        </SecondaryBtn>
      </div>
    </a>
  )
}

export default MerchCardV2
