// Notify others
export const POST_NOTIFY_ALL = 'notifications/POST_NOTIFY_ALL'

// Current user notifications
export const GET_NOTIFICATIONS_REQUEST =
  'notifications/GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS =
  'notifications/GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE =
  'notifications/GET_NOTIFICATIONS_FAILURE'
export const GET_NOTIFICATION = 'notifications/GET_NOTIFICATION'
export const GET_NOTIFICATIONS_UNSEEN_REQUEST =
  'notifications/GET_NOTIFICATIONS_UNSEEN_REQUEST'
export const GET_NOTIFICATIONS_UNSEEN_SUCCESS =
  'notifications/GET_NOTIFICATIONS_UNSEEN_SUCCESS'
export const GET_NOTIFICATIONS_UNSEEN_FAILURE =
  'notifications/GET_NOTIFICATIONS_UNSEEN_FAILURE'
export const PUT_NOTIFICATION_SEEN = 'notifications/PUT_NOTIFICATIONS_SEEN'
export const PUT_NOTIFICATION_SEEN_FAILURE =
  'notifications/PUT_NOTIFICATIONS_SEEN_FAILURE'
export const PUT_ALL_NOTIFICATIONS_SEEN_REQUEST =
  'notifications/PUT_ALL_NOTIFICATIONS_SEEN_REQUEST'
export const PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS =
  'notifications/PUT_ALL_NOTIFICATIONS_SEEN_SUCCESS'
export const PUT_ALL_NOTIFICATIONS_SEEN_FAILURE =
  'notifications/PUT_ALL_NOTIFICATIONS_SEEN_FAILURE'
export const PUT_NOTIFICATION_READ = 'notifications/PUT_NOTIFICATIONS_READ'
export const PUT_NOTIFICATION_READ_FAILURE =
  'notifications/PUT_NOTIFICATIONS_READ_FAILURE'
export const PUT_ALL_NOTIFICATIONS_READ_REQUEST =
  'notifications/PUT_ALL_NOTIFICATIONS_READ_REQUEST'
export const PUT_ALL_NOTIFICATIONS_READ_SUCCESS =
  'notifications/PUT_ALL_NOTIFICATIONS_READ_SUCCESS'
export const PUT_ALL_NOTIFICATIONS_READ_FAILURE =
  'notifications/PUT_ALL_NOTIFICATIONS_READ_FAILURE'

// Settings
export const GET_NOTIFICATION_CATEGORIES_FAILURE =
  'notifications/GET_NOTIFICATION_CATEGORIES_FAILURE'
export const GET_NOTIFICATION_CATEGORIES_REQUEST =
  'notifications/GET_NOTIFICATION_CATEGORIES_REQUEST'
export const GET_NOTIFICATION_CATEGORIES_SUCCESS =
  'notifications/GET_NOTIFICATION_CATEGORIES_SUCCESS'
export const GET_NOTIFICATION_SETTINGS_REQUEST =
  'notifications/GET_NOTIFICATION_SETTINGS_REQUEST'
export const GET_NOTIFICATION_SETTINGS_SUCCESS =
  'notifications/GET_NOTIFICATION_SETTINGS_SUCCESS'
export const GET_NOTIFICATION_SETTINGS_FAILURE =
  'notifications/GET_NOTIFICATION_SETTINGS_FAILURE'
export const GET_NOTIFICATION_SETTING = 'notifications/GET_NOTIFICATION_SETTING'
export const PUT_NOTIFICATION_SETTINGS_REQUEST =
  'notifications/PUT_NOTIFICATION_SETTINGS_REQUEST'
export const PUT_NOTIFICATION_SETTINGS_SUCCESS =
  'notifications/PUT_NOTIFICATION_SETTINGS_SUCCESS'
export const PUT_NOTIFICATION_SETTINGS_FAILURE =
  'notifications/PUT_NOTIFICATION_SETTINGS_FAILURE'
export const POST_NOTIFICATION_SETTINGS_RESET =
  'notifications/POST_NOTIFICATION_SETTINGS_RESET'

// Subscriptions
export const GET_NOTIFICATION_SUBS = 'notifications/GET_NOTIFICATION_SUBS'
export const GET_NOTIFICATION_SUB = 'notifications/GET_NOTIFICATION_SUB'
export const POST_NOTIFICATION_SUB = 'notifications/POST_NOTIFICATION_SUB'
export const DELETE_NOTIFICATION_SUB = 'notifications/DELETE_NOTIFICATION_SUB'

// Unsubscribe
export const PUT_EMAIL_UNSUBSCRIBE_FAILURE =
  'notifications/PUT_EMAIL_UNSUBSCRIBE_FAILURE'
export const PUT_EMAIL_UNSUBSCRIBE_REQUEST =
  'notifications/PUT_EMAIL_UNSUBSCRIBE_REQUEST'
export const PUT_EMAIL_UNSUBSCRIBE_SUCCESS =
  'notifications/PUT_EMAIL_UNSUBSCRIBE_SUCCESS'

// Real time Websocket
export const CONNECT_NOTIFICATION_SOCKET =
  'notifications/CONNECT_NOTIFICATION_SOCKET'
export const DISCONNECT_NOTIFICATION_SOCKET =
  'notifications/DISCONNECT_NOTIFICATION_SOCKET'
export const CONNECT_NOTIFY_SOCKET = 'notifications/CONNECT_NOTIFY_SOCKET'
export const DISCONNECT_NOTIFY_SOCKET = 'notifications/DISCONNECT_NOTIFY_SOCKET'

// Push Events
export const ADD_NOTIFICATION = 'notifications/ADD_NOTIFICATION'
