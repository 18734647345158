import { connect } from 'react-redux'

import {
  getLoggedInUsername,
  getLoggedInUserProfileImage,
  shouldWaitForUserObject,
} from 'auth/selectors'
import { closeMyHub, fetchMyHubData } from 'myHubApp/operations'
import {
  getHubFeedType,
  getMyHubFeed,
  getMyHubShowLoadingScreen,
  getShouldDisplayHub,
} from 'myHubApp/selectors'

import MyHub from '../components/MyHub'

const mapStateToProps = state => {
  const waitForUserObject = shouldWaitForUserObject(state)
  const shouldDisplayHub = getShouldDisplayHub(state)
  const showLoadingScreen = getMyHubShowLoadingScreen(state)

  const profileImage = getLoggedInUserProfileImage(state)
  const username = getLoggedInUsername(state)
  const feed = getMyHubFeed(state)
  const feedType = getHubFeedType(state)

  return {
    feed,
    feedType,
    profileImage,
    shouldDisplayHub,
    showLoadingScreen,
    username,
    waitForUserObject,
  }
}

const mapDispatchToProps = { closeMyHub, fetchMyHubData }

const MyHubContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyHub)

export default MyHubContainer
