import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import BtnBase from '../BtnBase'
import LabelBase from '../LabelBase'
import LinkBase from '../LinkBase'

import './styles.scss'

const TertiaryBtn = React.forwardRef(({ bg, className, ...props }, ref) => {
  const classes = classnames('btn--tertiary', className, {
    'btn--dark-bg': bg === 'dark',
    'btn--light-bg': bg === 'light',
  })

  if (props.linkTo) {
    return <LinkBase className={classes} ref={ref} {...props} />
  }

  if (props.htmlFor) {
    return <LabelBase className={classes} ref={ref} {...props} />
  }

  return <BtnBase className={classes} ref={ref} {...props} />
})

TertiaryBtn.propTypes = {
  bg: PropTypes.oneOf(['dark', 'light']),
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

TertiaryBtn.defaultProps = {
  bg: 'light',
  children: 'Tertiary Button',
  className: '',
  disabled: false,
  onClick: () => null,
  style: {},
}

export default TertiaryBtn
