import React, { Component } from 'react'
import { Tooltip } from 'materialize-css'

import './styles.scss'

class Thumbs extends Component {
  container = React.createRef()

  componentDidMount() {
    Tooltip.init(this.container.current, { enterDelay: 50 })
  }

  render() {
    return (
      <div className='thumb-rating' ref={this.container}>
        <i
          className='icon-thumbs-o-up'
          data-position='top'
          data-tooltip='I like this'
        />
        <i
          className='icon-thumbs-o-down'
          data-position='top'
          data-tooltip='I do not like this'
        />
      </div>
    )
  }
}

export default Thumbs
