import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  PUT_UPDATE_PROFILE,
  PUT_UPDATE_PROFILE_ERR,
  PUT_UPDATE_PROFILE_IMAGE_ERR,
  PUT_UPDATE_PROFILE_IMAGE_RESP,
  RECEIVE_PROFILE,
  REQUEST_PROFILE,
  SAVE_PROFILE,
  TOGGLE_EDITING,
  TOGGLE_FOLLOW,
  TOGGLE_FRIEND,
  TOGGLE_LIKE,
  TOGGLE_SAVING,
  TRIGGER_MESSAGE,
  TRIGGER_UPDATE_COVER,
  TRIGGER_UPDATE_PHOTO,
} from './types'

export function requestProfile() {
  return {
    type: REQUEST_PROFILE,
  }
}

export function receiveProfile(json) {
  return {
    type: RECEIVE_PROFILE,
    user: json,
  }
}

export function saveSidebarProfile(data) {
  return {
    type: SAVE_PROFILE,
    data,
  }
}

export function toggleEditing(isEditing) {
  return {
    type: TOGGLE_EDITING,
    isEditing,
  }
}

export function toggleFollow(userId) {
  return {
    type: TOGGLE_FOLLOW,
    userId,
  }
}

export function toggleFriend(userId) {
  return {
    type: TOGGLE_FRIEND,
    userId,
  }
}

export function toggleLike(contentId) {
  return {
    type: TOGGLE_LIKE,
    contentId,
  }
}

export function toggleSaving() {
  return {
    type: TOGGLE_SAVING,
  }
}

export function triggerMessage(userId) {
  return {
    type: TRIGGER_MESSAGE,
    userId,
  }
}

export function triggerUpdateCover() {
  return {
    type: TRIGGER_UPDATE_COVER,
  }
}

export function triggerUpdatePhoto() {
  return {
    type: TRIGGER_UPDATE_PHOTO,
  }
}

export function receiveUpdateProfile(data) {
  return {
    type: PUT_UPDATE_PROFILE,
    data,
  }
}

export function updateProfileError(error) {
  return {
    type: PUT_UPDATE_PROFILE_ERR,
    error,
  }
}

export function receiveUpdateProfileImage(data) {
  return {
    type: PUT_UPDATE_PROFILE_IMAGE_RESP,
    data,
  }
}

export function errorUpdateProfileImage(data) {
  return {
    type: PUT_UPDATE_PROFILE_IMAGE_ERR,
    data,
  }
}

export function errorFetchingProfile(data) {
  return {
    type: 'PROFILE_GET_ERR',
    data,
  }
}

/**
 * Assigns a image path to a user Picture
 * @param {Object} action - action object
 * @param {Object} action.user - user uuid
 * @param {String} action.awsBucket - aws bucket url
 * @param {String} action.imagePath - path to uploaded image
 * TODO: image path saving is HACKY fix this and the endpoint
 * */
export function updateProfileImage({ user, _awsBucket, imagePath }) {
  return (dispatch, _getState) => {
    const { id } = user
    const parts = imagePath.split('/user/')
    const bucket = parts[0].split('s3.amazonaws.com/')[1]
    const picture = {
      picture_type: 'profile_picture',
      aws_bucket: bucket,
      image_path: `user/${parts[1]}`,
    }
    return rtApi.users
      .updateProfilePicture({ id, picture })
      .then(json => {
        if (json.error) {
          return dispatch(errorUpdateProfileImage(json))
        }
        dispatch(fetchProfile(user.attributes.username))
        dispatch(receiveUpdateProfileImage(json))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
      })
  }
}

export function updateProfile({ id, user }) {
  return (dispatch, _getState) =>
    rtApi.users
      .update({ id, user })
      .then(json => {
        if (json.error) {
          return dispatch(updateProfileError(json.error))
        }
        dispatch(receiveUpdateProfile(json))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(
          updateProfileError('Something went wrong when saving your profile')
        )
      })
}

export function fetchProfile(username) {
  return (dispatch, _getState) => {
    dispatch(requestProfile())
    return rtApi.users
      .fetchProfile({ username })
      .then(json => {
        dispatch(receiveProfile(json))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(errorFetchingProfile(ex))
      })
  }
}
