import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import throttle from 'lodash.throttle'

const useScrollHelpers = () => {
  const [[showPrevButton, showNextButton], setShowButtonsState] = useState([
    false,
    false,
  ])
  const containerRef = useRef()
  const prevButtonRef = useRef()
  const nextButtonRef = useRef()

  const computeShowButtonsState = useCallback(() => {
    const container = containerRef.current
    const canScrollLeft = container.scrollLeft > 0
    // Note that we add a "-10" here because Chrome never quite manages to scroll all the way to the right side. Always within a few pixels.
    const canScrollRight =
      container.offsetWidth + container.scrollLeft < container.scrollWidth - 10
    setShowButtonsState([canScrollLeft, canScrollRight])
  }, [])

  useLayoutEffect(
    () => {
      computeShowButtonsState()

      const throttledComputeShowButtonsState = throttle(
        computeShowButtonsState,
        200
      )

      const container = containerRef.current

      container.addEventListener('scroll', throttledComputeShowButtonsState)
      window.addEventListener('resize', throttledComputeShowButtonsState)

      return () => {
        container.removeEventListener(
          'scroll',
          throttledComputeShowButtonsState
        )
        window.removeEventListener('resize', throttledComputeShowButtonsState)
      }
    },
    [computeShowButtonsState]
  )

  const goBack = useCallback(() => {
    const container = containerRef.current
    const leftDestination = container.scrollLeft - container.offsetWidth + 20
    container.scrollTo({ behavior: 'smooth', left: leftDestination })
  }, [])

  const goForward = useCallback(() => {
    const container = containerRef.current
    const leftDestination = container.scrollLeft + container.offsetWidth - 20
    container.scrollTo({ behavior: 'smooth', left: leftDestination })
  }, [])

  return {
    showPrevButton,
    showNextButton,
    containerRef,
    prevButtonRef,
    nextButtonRef,
    goBack,
    goForward,
  }
}

export default useScrollHelpers
