import { select } from 'redux-saga/effects'

import { trackEvent } from 'common/metrics'

import { SERIES_RSS_SIGNUP_SUCCESSFUL } from 'showsApp/constants'
import { getPendingRssActions, getPendingSeriesUuid } from 'showsApp/selectors'

function* trackRssSignupConversion() {
  const isPendingRssAction = yield select(getPendingRssActions)
  if (isPendingRssAction) {
    const series_uuid = yield select(getPendingSeriesUuid)
    trackEvent(SERIES_RSS_SIGNUP_SUCCESSFUL, {
      series_uuid,
    })
  }
}

export default trackRssSignupConversion
