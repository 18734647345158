import { useEffect, useMemo, useState } from 'react'

const getDateDifference = (date1, date2) => {
  const rawDifference = date2 - date1

  const days = Math.max(Math.floor(rawDifference / (1000 * 60 * 60 * 24)), 0)
  const hours = Math.max(Math.floor(rawDifference / (1000 * 60 * 60)) % 24, 0)
  const minutes = Math.max(Math.floor(rawDifference / (1000 * 60)) % 60, 0)
  const seconds = Math.max(Math.floor(rawDifference / 1000) % 60, 0)

  return { days, hours, minutes, seconds }
}
const useCountdown = date => {
  const targetDate = useMemo(() => new Date(date), [date])

  const [dateDifference, setDateDifference] = useState(() =>
    getDateDifference(new Date(), targetDate)
  )

  useEffect(
    () => {
      const ticker = setInterval(
        () => setDateDifference(getDateDifference(new Date(), targetDate)),
        1000
      )
      return () => clearInterval(ticker)
    },
    [targetDate]
  )
  return dateDifference
}

export default useCountdown
