import { useCallback, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'

import fetchSearchTerm from './fetchSearchTerm'

const useHandleSearchTermChange = ({
  replace,
  searchTerm,
  setSearchResults,
}) => {
  const search = useCallback(
    searchTerm => {
      fetchSearchTerm({
        replace,
        searchTerm,
        setSearchResults,
      })
    },
    [replace, setSearchResults]
  )

  const debouncedSearch = useMemo(() => debounce(search, 200), [search])

  // Whenever the search term changes, run a debounced search for results.
  useEffect(
    () => {
      debouncedSearch(searchTerm)
    },
    [debouncedSearch, searchTerm]
  )
}

export default useHandleSearchTermChange
