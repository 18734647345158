import rtApi from '@roosterteethproductions/svod-api'
import Honeybadger from 'honeybadger-js'

import { logoutSuccess } from 'auth/actions'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from './http-statuses'

export const getPercentDone = ({ item, percentages, unless = false }) => {
  try {
    if (unless) return null

    // Invalid percentages object
    if (percentages === undefined) return null

    // Return if item ID is invalid. Note that .uuid is the old version, and .id is the new version.
    const id = item?.uuid || item?.id
    if (!id) return null

    // Invalid item length
    const itemLength = item?.attributes?.length
    if (typeof itemLength !== 'number') return null
    if (itemLength === 0) return null

    const percentage = percentages.find(p => p?.uuid === id)
    const percentageValue = parseInt(percentage?.value, 10)

    if (Number.isNaN(percentageValue)) return null

    return (percentageValue / itemLength) * 100
  } catch (error) {
    Honeybadger.notify(
      'Encountered an error while calculating percentage for an item. See context for details.',
      { context: { originalError: error, item } }
    )

    return null
  }
}

// Composes logic for getting percentages for episodes. The args requestAction, successAction, and failureAction are to be provided by the app of concern.
export const getEpisodePercentages = ({
  failureAction,
  items,
  requestAction,
  successAction,
}) => (dispatch, getState) => {
  const state = getState()
  const { isAuthenticated } = state.authReducer
  if (isAuthenticated && items && items.length > 0) {
    dispatch(requestAction())
    const ids = items
      .filter(val => val.uuid !== undefined)
      .map(val => `${val.uuid}`)
    rtApi.wtc
      .fetchPositions({ ids })
      .then(json => {
        if (json.from === 'error') {
          return dispatch(
            failureAction({
              message: 'The API server encountered an error',
            })
          )
        }
        dispatch(successAction(json))
      })
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return dispatch(failureAction(ex))
      })
  }

  return {}
}
