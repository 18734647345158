import rtApi from '@roosterteethproductions/svod-api'

import { removeSearchParam, setSearchParam } from 'common/actions/urlActions'

import { receiveHubData, setShowLoadingScreen } from 'myHubApp/actions'
import { SEARCH_PARAM_SHOW_HUB } from 'myHubApp/constants'
import { getHubFeedType } from 'myHubApp/selectors'

export const closeMyHub = () => dispatch => {
  // Prevent loading screen from being visible on subsequent opens unless asked.
  dispatch(setShowLoadingScreen(false))
  dispatch(removeSearchParam(SEARCH_PARAM_SHOW_HUB))
}

export const showMyHub = ({ showLoadingScreen } = {}) => dispatch => {
  // Only show a loading screen if the caller asked for one.
  if (showLoadingScreen) dispatch(setShowLoadingScreen(true))
  dispatch(setSearchParam(SEARCH_PARAM_SHOW_HUB, 'true'))
}

export const fetchMyHubData = () => async (dispatch, getState) => {
  const feedType = getHubFeedType(getState())

  // If there's a mock defined by e2e tests, use that instead.
  const feed = await (window.qaContentBlocksMock ||
    rtApi.feeds.fetchContentBlocks({ type: feedType }))

  dispatch(receiveHubData({ feed, feedType }))
}
