import { trackClick } from 'common/metrics'

const handleHomeClick = (e, pathname, label) => {
  if (pathname === '/') {
    e.preventDefault()
    window.scrollTo(0, 0)
  }

  trackClick(label)
}

export default handleHomeClick
