import React from 'react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import store from 'store'
import { createReduxHistory } from 'store/reduxFirstHistory'

import Goodbye from 'common/components/Goodbye'
import { config } from 'common/config'
import growthbook from 'common/helpers/growthbook'
import { getFeatureFlags } from 'common/selectors'

import App from './rootApp/containers/App'

const reduxHistory = createReduxHistory(store)

const Root = props => {
  const { store } = props
  const { goodbye } = getFeatureFlags(store.getState())

  if (
    process.env.NODE_ENV !== 'production' &&
    config.metrics.showWhyDidYouUpdate
  ) {
    // eslint-disable-next-line no-console
    console.info('"Why Did You Render" enabled')
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
      trackAllPureComponents: false,
    })
  }

  return <Goodbye />
}

export default Root
