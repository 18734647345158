const borrowPosition = ({
  getSourceElem,
  getTargetElem,
  getTargetParent,
  offsetX = 0,
  offsetY = 0,
}) => {
  const sourceElem = getSourceElem()
  const targetElem = getTargetElem()
  if (!sourceElem || !targetElem) return

  const sourceBox = sourceElem.getBoundingClientRect()
  const targetParent = getTargetParent
    ? getTargetParent(targetElem)
    : targetElem.parentElement
  const targetParentBox = targetParent.getBoundingClientRect()

  const left = sourceBox.x - targetParentBox.x + offsetX
  const top = sourceBox.y - targetParentBox.y + offsetY

  targetElem.style.left = `${left}px`
  targetElem.style.top = `${top}px`
  targetElem.style.width = `${sourceBox.width}px`
  targetElem.style.height = `${sourceBox.height}px`
}

export default borrowPosition
