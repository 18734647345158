import React from 'react'
import { Link } from 'react-router-dom'

import { checkIfExternalUri } from 'common/helpers'

// NOTE: This is very similar to `LinkBase`, but as of now, `LinkBase` is only for buttons.
const SafeLink = ({ children, url, ...props }) => {
  if (checkIfExternalUri(url)) {
    return (
      <a rel='noopener noreferrer' target='_blank' href={url} {...props}>
        {children}
      </a>
    )
  }

  return (
    <Link to={url} {...props}>
      {children}
    </Link>
  )
}

export default SafeLink
