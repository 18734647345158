import React from 'react'
import { connect } from 'react-redux'

import ukraineFlagHeart from 'common/assets/icon-support-ukraine.png'
import { Tooltip } from 'common/components'
import { trackEvent } from 'common/metrics'

import { FallingParticlesEasterEggButton } from 'rootApp/components/FallingParticlesEasterEgg'

import './styles.scss'

const heartFlagImage = new Image(90, 90)
heartFlagImage.src = ukraineFlagHeart

function resetParticle(particle) {
  // Number between 15 and 25
  particle.size = 25 - Math.random() * 10
  // Number between 0.5 and 1.0
  particle.alpha = 0.5 + Math.random() * 0.5
}

function drawParticle(context, particle) {
  context.globalAlpha = particle.alpha
  context.drawImage(
    heartFlagImage,
    particle.x,
    particle.y,
    particle.size,
    particle.size
  )
}

const trackClick = user =>
  trackEvent('Heart Button Clicked', {
    user_tier: user?.attributes?.member_tier || 'anon',
  })

const SupportUkraineNavButton = ({ user }) => (
  <FallingParticlesEasterEggButton
    compression={0.8}
    drawParticle={drawParticle}
    particleCount={250}
    resetParticle={resetParticle}
    speed={1.2}
    stopAfter={100}
    element={({ toggleFalling, falling }) => (
      <Tooltip text='Stand with Ukraine'>
        <div
          className={`support-ukraine-nav-button ${falling ? 'active' : ''}`}
          role='button'
          onClick={() => {
            trackClick(user)
            toggleFalling()
          }}
          onKeyPress={toggleFalling}
          tabIndex={0}
        >
          <img src={ukraineFlagHeart} alt='Ukraine flag heart' />
        </div>
      </Tooltip>
    )}
  />
)

const mapStateToProps = state => ({ user: state.authReducer.user })

export default connect(mapStateToProps)(SupportUkraineNavButton)
