import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'

import {
  DELETE_FOLLOW_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  DELETE_PLAYLIST_ITEM_SUCCESS,
  DELETE_PLAYLIST_SUCCESS,
  GET_FEATURED_REQUEST,
  GET_FEATURED_SUCCESS,
  GET_FOLLOWED_SHOWS_SUCCESS,
  GET_FOLLOWED_SUCCESS,
  GET_ITEM_IN_WATCH_LATER_SUCCESS,
  GET_MINE_BY_CONTENT_REQUEST,
  GET_MINE_BY_CONTENT_SUCCESS,
  GET_MINE_SUCCESS,
  GET_PLAYLIST_DATA_SUCCESS,
  GET_PLAYLIST_ITEMS_PERCENT_SUCCESS,
  GET_PLAYLIST_ITEMS_SUCCESS,
  GET_PLAYLIST_ITEMS_UUIDS_SUCCESS,
  GET_PLAYLIST_OWNER_SUCCESS,
  GET_PLAYLIST_SUCCESS,
  GET_PLAYLISTS_SUCCESS,
  GET_WATCH_LATER_DATA_SUCCESS,
  GET_WATCH_LATER_SUCCESS,
  PATCH_PLAYLIST_SUCCESS,
  PUT_FOLLOW_PLAYLIST_SUCCESS,
  PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS,
  PUT_PLAYLIST_ITEM_SUCCESS,
  PUT_PLAYLIST_SUCCESS,
  PUT_REORDER_PLAYLIST_ITEMS_SUCCESS,
} from '../actions/types'

const initialState = {
  allPlaylists: [],
  featuredPlaylists: [],
  followedPlaylists: [],
  followedPlaylistPages: 0,
  followedShows: [],
  myPlaylists: [],
  myPlaylistsByContent: [],
  myPlaylistsPages: 0,
  myShowsPages: 0,
  playlistItemsData: [],
  playlistItemsUuids: [],
  playlistPages: 0,
  watchLater: [],
  watchLaterInfo: null,
  watchLaterUuids: [],
}

const addUsername = (state, users) =>
  state.map(item => {
    const match = users.find(i => i.uuid === item?.attributes?.owner_uuid)
    return Object.assign(
      {},
      item,
      match
        ? {
            attributes: {
              ...item.attributes,
              owner_name: match.username,
            },
          }
        : {}
    )
  })

const updateFeatured = (featuredState, action) => {
  const filterKey = Object.keys(action.filter)[0]
  const filterValue = action.filter[filterKey]
  // it's pulling the filter value for homepage carousels, which breaks discover page
  if (filterValue === true) {
    return featuredState
  }
  let stateModified = false
  const newState = featuredState.map(item => {
    if (item.filter.type === filterKey && item.filter.value === filterValue) {
      stateModified = true
      item.items = action.items
      item.totalPages = action.totalPages
    }
    return item
  })
  if (stateModified) {
    return newState
  }
  return [
    ...featuredState,
    {
      filter: { type: filterKey, value: filterValue },
      items: action.items,
      totalPages: action.totalPages,
    },
  ]
}

export default (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case GET_FEATURED_REQUEST:
      return {
        ...state,
      }
    case GET_FEATURED_SUCCESS:
      return {
        ...state,
        featuredPlaylists: updateFeatured(state.featuredPlaylists, action),
      }
    case GET_FOLLOWED_SHOWS_SUCCESS:
      data = action.items
      if (action.merge) {
        data = [...state.followedShows, ...action.items]
      }
      return {
        ...state,
        allPlaylists: uniqBy([...state.allPlaylists, ...action.items], 'id'),
        followedShows: uniqBy(data, 'id'),
        myShowsPages: action.totalPages,
      }
    case GET_FOLLOWED_SUCCESS:
      data = action.items
      if (action.merge) {
        data = [...state.followedPlaylists, ...action.items]
      }
      return {
        ...state,
        allPlaylists: uniqBy([...state.allPlaylists, ...action.items], 'id'),
        followedPlaylists: uniq(
          data.map(i => (typeof i === 'object' ? i.id : i))
        ),
        followedPlaylistPages: action.totalPages,
      }
    case GET_ITEM_IN_WATCH_LATER_SUCCESS:
      return {
        ...state,
        watchLaterUuids: [...state.watchLaterUuids, ...action.items],
      }
    case GET_MINE_BY_CONTENT_REQUEST:
      return {
        ...state,
        myPlaylistsByContent: [],
      }
    case GET_MINE_BY_CONTENT_SUCCESS:
      return {
        ...state,
        myPlaylistsByContent: action.ids,
      }
    case GET_MINE_SUCCESS:
      data = action.items
      if (action.merge) {
        data = [...state.myPlaylists, ...action.items]
      }
      return {
        ...state,
        allPlaylists: uniqBy([...state.allPlaylists, ...data], 'id'),
        myPlaylists: uniq(data.map(i => (typeof i === 'object' ? i.id : i))),
        myPlaylistsPages: action.totalPages,
      }
    case GET_PLAYLIST_SUCCESS:
      return {
        ...state,
        allPlaylists: uniqBy([action.item, ...state.allPlaylists], 'id'),
      }
    case GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        allPlaylists: uniqBy([...state.allPlaylists, ...action.items], 'id'),
      }
    case GET_PLAYLIST_DATA_SUCCESS:
      return {
        ...state,
        allPlaylists: state.allPlaylists.map(listItem => {
          const match = action.items.find(
            i =>
              i.uuid === listItem?.attributes?.display_content_uuid ||
              i.uuid === listItem?.attributes?.content_uuid
          )
          return match ? Object.assign({}, listItem, { data: match }) : listItem
        }),
      }
    case GET_PLAYLIST_OWNER_SUCCESS:
      return {
        ...state,
        allPlaylists: addUsername(state.allPlaylists, action.items),
      }
    case PUT_PLAYLIST_SUCCESS:
      return {
        ...state,
        allPlaylists: uniqBy([...state.allPlaylists, action.item], 'id'),
        myPlaylists:
          state.myPlaylists.indexOf(action.item.id) === -1
            ? [...state.myPlaylists, action.item.id]
            : state.myPlaylists,
        myPlaylistsByContent:
          state.myPlaylistsByContent.indexOf(action.item.id) === -1
            ? [...state.myPlaylistsByContent, action.item.id]
            : state.myPlaylistsByContent,
      }
    case PATCH_PLAYLIST_SUCCESS:
      const newData = state.allPlaylists.map(item => {
        if (item.id === action.item.id) {
          return Object.assign({}, item, {
            attributes: {
              ...item.attributes,
              ...action.item.attributes,
            },
          })
        }
        return item
      })
      return {
        ...state,
        allPlaylists: newData,
      }
    case GET_PLAYLIST_ITEMS_UUIDS_SUCCESS:
      return {
        ...state,
        playlistItemsUuids: action.items.map(
          item => item.attributes.content_uuid
        ),
        playlistPages: action.totalPages,
      }
    case GET_PLAYLIST_ITEMS_SUCCESS:
      data = action.items
      if (action.merge) {
        data = uniqBy([...state.playlistItemsData, ...data], 'uuid')
      }
      return {
        ...state,
        playlistItemsData: data,
      }
    case GET_WATCH_LATER_DATA_SUCCESS:
      return {
        ...state,
        watchLater: uniqBy([...state.watchLater, ...action.items], 'uuid'),
      }
    case GET_WATCH_LATER_SUCCESS:
      return {
        ...state,
        watchLaterInfo: action.info,
      }
    case PUT_FOLLOW_PLAYLIST_SUCCESS:
      data = uniqBy([...state.followedPlaylists, { ...action.item }], 'id')
      return {
        ...state,
        followedPlaylists: data,
      }
    case DELETE_FOLLOW_PLAYLIST_SUCCESS:
      return {
        ...state,
        followedPlaylists: state.followedPlaylists.filter(
          i => i.id !== action.id
        ),
      }
    case PUT_PLAYLIST_ITEM_BY_CAT_SUCCESS:
      return {
        ...state,
        followedShows:
          action.category === 'followed_series'
            ? [...state.followedShows, action.item]
            : state.followedShows,
        watchLaterUuids:
          action.category === 'watch_later'
            ? [...state.watchLaterUuids, action.item.attributes.content_uuid]
            : state.watchLaterUuids,
      }
    case PUT_PLAYLIST_ITEM_SUCCESS:
      return {
        ...state,
        allPlaylists: state.allPlaylists.map(i =>
          i.id === action.id
            ? Object.assign({}, i, {
                attributes: {
                  ...i.attributes,
                  playlist_items_count: i.attributes.playlist_items_count + 1,
                },
              })
            : i
        ),
        myPlaylistsByContent:
          state.myPlaylistsByContent.indexOf(action.id) === -1
            ? [...state.myPlaylistsByContent, action.id]
            : state.myPlaylistsByContent,
      }
    case DELETE_PLAYLIST_SUCCESS:
      return {
        ...state,
        allPlaylists: state.allPlaylists.filter(i => i.id !== action.id),
        myPlaylists: state.myPlaylists.filter(i => i.id !== action.id),
      }
    case DELETE_PLAYLIST_ITEM_BY_CAT_SUCCESS:
      const match = state.allPlaylists.find(
        a => a.attributes.content_uuid === action.uuid
      )
      return {
        ...state,
        allPlaylists: match
          ? state.allPlaylists.map(i =>
              i.id === match.id
                ? Object.assign({}, i, {
                    attributes: {
                      ...i.attributes,
                      playlist_items_count:
                        i.attributes.playlist_items_count - 1,
                    },
                  })
                : i
            )
          : state.allPlaylists,
        followedShows:
          action.category === 'followed_series'
            ? state.followedShows.filter(
                i => i.attributes.content_uuid !== action.uuid
              )
            : state.followedShows,
        watchLater:
          action.category === 'watch_later'
            ? state.watchLater.filter(i => i.uuid !== action.uuid)
            : state.watchLater,
        watchLaterUuids:
          action.category === 'watch_later'
            ? state.watchLaterUuids.filter(i => i !== action.uuid)
            : state.watchLaterUuids,
      }
    case DELETE_PLAYLIST_ITEM_SUCCESS:
      return {
        ...state,
        allPlaylists: state.allPlaylists.map(i =>
          i.id === action.id
            ? Object.assign({}, i, {
                attributes: {
                  ...i.attributes,
                  playlist_items_count: i.attributes.playlist_items_count - 1,
                },
              })
            : i
        ),
        playlistItemsData: state.playlistItemsData.filter(
          i => i.uuid !== action.uuid
        ),
        myPlaylistsByContent: state.myPlaylistsByContent.filter(
          i => i !== action.id
        ),
        watchLater: state.watchLater.filter(i => i.uuid !== action.uuid),
        watchLaterUuids: state.watchLaterUuids.filter(i => i !== action.uuid),
      }
    case PUT_REORDER_PLAYLIST_ITEMS_SUCCESS:
      return {
        ...state,
        playlistItemsData: action.items,
        watchLater:
          action.playlistId === state?.watchLaterInfo?.id
            ? action.items
            : state.watchLater,
      }
    case GET_PLAYLIST_ITEMS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: action.items,
      }
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    default:
      return state
  }
}
