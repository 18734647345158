import { useCallback } from 'react'

import { trackSearchSelection } from 'common/metrics'

import getAnalyticsTabName from './getAnalyticsTabName'

const useGoToUserPage = ({
  closeSearchV2,
  goToUserPage,
  searchTerm,
  tab,
  user,
}) =>
  useCallback(
    () => {
      closeSearchV2()
      goToUserPage(user.name)
      trackSearchSelection({
        query: searchTerm,
        tab: getAnalyticsTabName(tab),
        type: 'users',
        uuid: user.id,
      })
    },
    [closeSearchV2, user.name, goToUserPage, searchTerm, tab, user.id]
  )

export default useGoToUserPage
