import React, { useCallback } from 'react'

import CommunityGroup from 'common/components/CommunityGroup'
import { swapCommunityAPIForCDN } from 'common/helpers'
import { trackSearchSelection } from 'common/metrics'

import getAnalyticsTabName from '../../helpers/getAnalyticsTabName'

const WrappedCommunityGroup = ({ closeSearchV2, group, searchTerm, tab }) => {
  const { name, logo, header, id, metadata, slug } = group.data || group
  let backgroundImage

  if (header) {
    backgroundImage = swapCommunityAPIForCDN(
      header,
      'c_fill,w_256,h_128,f_auto,q_auto:best'
    )
  }
  const membersCount = metadata?.memberships?.count

  const trackClickAndClose = useCallback(
    () => {
      closeSearchV2()
      trackSearchSelection({
        query: searchTerm,
        tab: getAnalyticsTabName(tab),
        type: 'groups',
        uuid: id,
      })
    },
    [closeSearchV2, id, searchTerm, tab]
  )

  return (
    <div className='search-v2__group'>
      <CommunityGroup
        backgroundImage={backgroundImage}
        closeSearchV2={closeSearchV2}
        logo={logo}
        membersCount={membersCount}
        name={name}
        onClick={trackClickAndClose}
        slug={slug}
      />
    </div>
  )
}

export default WrappedCommunityGroup
