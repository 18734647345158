import React, { useEffect } from 'react'
import { Transition } from 'react-transition-group'

import { trackClick } from 'common/metrics'

import SearchV2 from './index'

// This element always renders, but it contains a search (and associated Transition) that only renders when search is opened.
const SearchV2Outer = ({ searchV2IsOpen, openSearch }) => {
  // Listen for "Alt+F" keyboard shortcut to open search.
  useEffect(
    () => {
      const eventListener = event => {
        if (event.code === 'KeyF' && event.altKey) {
          event.preventDefault()
          trackClick('shortcut_search')
          openSearch()
        }
      }

      document.addEventListener('keydown', eventListener)
      return () => document.removeEventListener('keydown', eventListener)
    },
    [openSearch]
  )

  return (
    // Provide 250ms for the exit transition to occur.
    <Transition
      appear
      in={searchV2IsOpen}
      unmountOnExit
      timeout={{ exit: 500 }}
    >
      {state => <SearchV2 transitionState={state} />}
    </Transition>
  )
}

export default SearchV2Outer
