/* eslint-disable prefer-spread */
/* eslint-disable no-undef, no-return-assign */
import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Linkify from 'react-linkify'
import { Link } from 'react-router-dom'
import reactReplace from 'string-replace-to-array'

import { handleEmojis } from 'common/components/Emoji'
import { RichMediaContainer } from 'common/containers'
import Post from 'common/containers/Post'

import './styles.scss'

class FormattedText extends Component {
  static propTypes = {
    disablePosts: PropTypes.bool,
    emojiSize: PropTypes.number,
    handleClickOnUser: PropTypes.func,
    // this is causing a false positive and I have no idea why
    // eslint-disable-next-line react/no-unused-prop-types
    handleHashtags: PropTypes.bool,
    repost: PropTypes.object,
    richMedia: PropTypes.bool,
    sentByAdmin: PropTypes.bool,
    text: PropTypes.string.isRequired,
    unfurlURL: PropTypes.string,
  }

  static defaultProps = {
    disablePosts: false,
    emojiSize: 12,
    handleClickOnUser: () => null,
    handleHashtags: false,
    richMedia: false,
    sentByAdmin: false,
    repost: null,
  }

  shouldComponentUpdate(nextProps, _nextState) {
    return (
      nextProps.repost !== this.props.repost ||
      nextProps.emojiSize !== this.props.emojiSize ||
      nextProps.richMedia !== this.props.richMedia ||
      nextProps.sentByAdmin !== this.props.sentByAdmin ||
      nextProps.text !== this.props.text ||
      nextProps.unfurlURL !== this.props.unfurlURL
    )
  }

  handleLineBreaks = text => {
    text = typeof text === 'string' ? [text] : text
    return [].concat.apply(
      [],
      text.map((elem, _idx) => {
        // sometimes we have react dom objects
        if (typeof elem !== 'string') {
          return elem
        }
        // sometimes, we already have an array of nodes, so we need to flatten
        // the results into a single array, instead of returning an array of arrays
        return reactReplace(
          elem,
          /\n|\r/g,
          <br key={elem.replace(/[^A-Z0-9]+/gi, '')} />
        )
      })
    )
  }

  // / ///////
  // Events
  // / ////////
  handleMentionClick(e, user) {
    e.preventDefault()
    if (!this.props.handleClickOnUser) {
      return
    }
    this.props.handleClickOnUser(e, user)
  }

  handleMentions(text) {
    text = typeof text === 'string' ? [text] : text
    const mentionPattern = /@((?!(?:\.\s))(?:\w|-|\.)+)/g
    // sometimes, we already have an array of nodes, so we need to flatten
    // the results into a single array, instead of returning an array of arrays
    return [].concat.apply(
      [],
      text.map((elem, _idx) => {
        if (typeof elem === 'string') {
          return reactReplace(elem, mentionPattern, username => {
            const userminusSymbol = username.substring(1, username.length)
            const mentionClass = classnames('mention', {
              me:
                userminusSymbol === this.props.myUserName ||
                (this.props.sentByAdmin &&
                  userminusSymbol.toLowerCase() === 'here'),
            })
            // console.log('metion', elem.slice(0, 8))
            return (
              <span
                className={mentionClass}
                key={`mention-${elem.slice(0, 8)}`}
                onClick={e => this.handleMentionClick(e, userminusSymbol)}
                role='presentation'
              >
                {username}
              </span>
            )
          })
        }

        return elem
      })
    )
  }

  handleHashTags(text) {
    if (!this.props.handleHashTags) {
      return text
    }

    text = typeof text === 'string' ? [text] : text
    return [].concat.apply(
      [],
      text.map((elem, _idx) => {
        // sometimes we have react dom objects
        if (typeof elem !== 'string') {
          return elem
        }
        // sometimes, we already have an array of nodes, so we need to flatten
        // the results into a single array, instead of returning an array of arrays
        return reactReplace(elem, /(\s|^)#(\w{3,})/g, (match, space, tag) => (
          <React.Fragment key={tag}>
            {space}
            <Link to={`/g/tag/${tag}`}>#{tag}</Link>
          </React.Fragment>
        ))
      })
    )
  }

  outputText(text) {
    if (!text) {
      return
    }
    let output = this.handleHashTags(
      this.handleLineBreaks(
        this.handleMentions(handleEmojis(text, this.props.emojiSize))
      )
    )
    // add class for <message deleted> styling
    output = reactReplace(output, '<message deleted>', str => (
      <span className='deleted'>{str}</span>
    ))
    return output
  }

  render() {
    const {
      text,
      richMedia,
      className,
      children,
      unfurlURL,
      disablePosts,
      repost,
    } = this.props
    return (
      <span className={`formatted-text ${className}`}>
        <Linkify properties={{ target: '_blank', rel: 'nofollow' }}>
          {this.outputText(text)}
          {children}
        </Linkify>
        {richMedia && (
          <RichMediaContainer text={text} disablePosts={disablePosts} />
        )}
        {// don't collapse with above statement - only when unfurl url is set
        // should it be displayed. It should not auto fallback to text
        unfurlURL && (
          <RichMediaContainer text={unfurlURL} disablePosts={disablePosts} />
        )}
        {repost && <Post id={repost.id} isRepost />}
      </span>
    )
  }
}

export default FormattedText
