import React from 'react'
import PropTypes from 'prop-types'

import { UserCard } from 'common/components'

import './styles.scss'

function UserCardContainer({ children, users }) {
  if (!children && (!users || !Array.isArray(users))) {
    return null
  }

  return (
    <div className='user-card-container'>
      {children || users.map(user => <UserCard key={user.id} user={user} />)}
    </div>
  )
}

UserCardContainer.propTypes = {
  children: PropTypes.node,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
}

export default UserCardContainer
