import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { config } from 'common/config'

// FIXME: We load this CSS, but we never the component exported by this file. So the CSS actually applies to other components (specifically, `.credit-card-form` in the CreditCardForm component). This is causing a Production bug and due to cascade-reordering, I am not comfortable moving this CSS to our common/styles tree, so for now we'll just import it from here.
import './styles.scss'

class PaymentForm extends Component {
  static propTypes = {
    columns: PropTypes.shape({
      cvv: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      month: PropTypes.string,
      number: PropTypes.string,
      postalCode: PropTypes.string,
      year: PropTypes.string,
    }),
  }

  static defaultProps = {
    columns: {
      cvv: 's12 m2',
      firstName: 's12 m4',
      lastName: 's12 m4',
      month: 's12 m2',
      number: 's12 m6',
      postalCode: 's12 m3',
      year: 's12 m3',
    },
  }

  state = {
    formSubmitted: false,
    first_name: {
      empty: true,
      focus: false,
      valid: false,
      value: null,
    },
    last_name: {
      empty: true,
      focus: false,
      valid: false,
      value: null,
    },
    cvv: {
      empty: true,
      focus: false,
      valid: false,
    },
    month: {
      empty: true,
      focus: false,
      valid: false,
    },
    number: {
      empty: true,
      focus: false,
      valid: false,
    },
    year: {
      empty: true,
      focus: false,
      valid: false,
    },
    postal_code: {
      empty: true,
      focus: false,
      valid: false,
      value: null,
    },
  }

  componentDidMount() {
    const recurlyOptions = {
      publicKey: config.recurly.publicKey,
    }

    window.recurly && window.recurly.configure(recurlyOptions)
    window.recurly && window.recurly.on('change', this.changeHandler)
  }

  componentWillUnmount() {
    window.recurly && window.recurly.off('change', this.changeHandler)
  }

  changeHandler = ({ fields }) => {
    this.setState(fields)
  }

  formError = error => {
    this.setState({
      formSubmitted: true,
    })

    error.fields.map(field =>
      this.setState((state, _props) => ({
        [field]: {
          ...state[field],
          valid: false,
        },
      }))
    )
  }

  getInput = (field, id, name) => {
    const inputClass = classnames({
      'recurly-field-wrapper': true,
      'valid active': this.state[field].valid,
      'invalid active': this.isFieldInvalidActive(field),
    })

    return (
      <input
        autoCorrect='off'
        className={inputClass}
        data-recurly={field}
        id={id}
        name={name}
        onBlur={this.handleInputChange}
        onChange={this.handleInputChange}
        onFocus={this.handleInputChange}
        required
        spellCheck='false'
        type='text'
      />
    )
  }

  getLabel = (field, id, error, label) => {
    const labelClass = classnames({
      'recurly-label': true,
      'valid active':
        field === 'month' ? !this.state.month.empty : this.state[field].valid,
      'invalid active': field !== 'month' && this.isFieldInvalidActive(field),
      focus: this.state[field].focus,
    })

    return (
      <Fragment>
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
        <span className='helper-text' data-error={`${error}`} />
      </Fragment>
    )
  }

  getRecurlyDiv = field => {
    const recurlyClass = classnames({
      'recurly-field-wrapper': true,
      'valid active':
        field === 'month' ? !this.state.month.empty : this.state[field].valid,
      'invalid active': field !== 'month' && this.isFieldInvalidActive(field),
      focus: this.state[field].focus,
    })

    return <div data-recurly={field} className={recurlyClass} />
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name.replace(/\s/g, '_')

    this.setState({
      [name]: {
        empty: value === '',
        focus: target === document.activeElement,
        valid: value !== '',
        value,
      },
    })
  }

  isFieldInvalidActive = field => {
    const { formSubmitted } = this.state
    const { empty, focus, valid } = this.state[field]

    return (!valid && !empty && !focus) || (formSubmitted && !valid && !focus)
  }

  submitForm = (event, form) => {
    const { onValidation } = this.props

    window.recurly.token(form, (error, token) => {
      if (error) {
        this.formError(error)
        onValidation({ valid: false })
      } else {
        onValidation({
          formData: this.state,
          token,
          valid: true,
        })
      }
    })
  }

  render() {
    const { columns, isHidden } = this.props
    const columnClass = 'input-field col'

    const classes = {
      cvv: classnames(columnClass, columns.cvv),
      firstName: classnames(columnClass, columns.firstName),
      lastName: classnames(columnClass, columns.lastName),
      month: classnames(columnClass, columns.month),
      number: classnames(columnClass, columns.number),
      postalCode: classnames(columnClass, columns.postalCode),
      year: classnames(columnClass, columns.year),
    }

    return (
      <div
        className='payment-form-wrapper row'
        style={{ display: isHidden && 'none' }}
      >
        <div className='row'>
          <div className={classes.firstName}>
            {this.getInput('first_name', 'first-name', 'first name')}
            {this.getLabel(
              'first_name',
              'first-name',
              'This field is required',
              'First Name'
            )}
          </div>

          <div className={classes.lastName}>
            {this.getInput('last_name', 'last-name', 'last name')}
            {this.getLabel(
              'last_name',
              'last-name',
              'This field is required',
              'Last Name'
            )}
          </div>
        </div>

        <div className='row'>
          <div className={classes.number}>
            {this.getRecurlyDiv('number')}
            {this.getLabel(
              'number',
              'card',
              'Please enter a valid credit card number',
              'Credit Card'
            )}
          </div>

          <div className={classes.cvv}>
            {this.getRecurlyDiv('cvv')}
            {this.getLabel('cvv', 'cvv', 'Please enter a valid CVV', 'CVV')}
          </div>
        </div>

        <div className='row'>
          <div className={classes.month}>
            {this.getRecurlyDiv('month')}
            {this.getLabel(
              'month',
              'month',
              'Please enter a valid date',
              'Month'
            )}
          </div>

          <div className={classes.year}>
            {this.getRecurlyDiv('year')}
            {this.getLabel('year', 'year', 'Please enter a valid date', 'Year')}
          </div>

          <div className={classes.postalCode}>
            {this.getInput('postal_code', 'postal-code', 'postal code')}
            {this.getLabel(
              'postal_code',
              'postal-code',
              'This field is required',
              'Postal Code'
            )}
          </div>
        </div>

        <input type='hidden' name='recurly-token' data-recurly='token' />
      </div>
    )
  }
}

export default PaymentForm
