import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import { Sidenav } from 'materialize-css'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { MobileAppIcons } from 'common/components'
import { trackClick, trackSignupButtonClick } from 'common/metrics'

import { shopifySession } from 'auth/actions'
import { showModal } from 'modalApp/actions'

import RandomVideo from './RandomVideo'
import SideNavBtns from './SideNavBtns'
import SideNavMenu from './SideNavMenu'

import './styles.scss'

export class SideNav extends Component {
  static propTypes = {
    channels: PropTypes.array,
    featureFlags: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    isLiveEventOn: PropTypes.bool,
    path: PropTypes.string.isRequired,
    user: PropTypes.object,
    searchPath: PropTypes.string,
  }

  leftColRef = React.createRef()

  sidenav = {}

  sidenavRef = React.createRef()

  componentDidMount() {
    this.sidenav = Sidenav.init(this.sidenavRef.current, {
      draggable: false,
      onCloseStart: () => this.hideMenu(),
      onOpenStart: () => this.showMenu(),
      preventScrolling: window.innerWidth < 1200,
    })
    // If menu overlaps content, hide by default
    if (window.innerWidth < 1200) {
      // $screen-xl breakpoint in variables.scss
      this.hideMenu()
    } else {
      this.restoreMenuFromStorage()
    }
    // account for allow/disallow scroll on window size change
    window.addEventListener(
      'resize',
      debounce(() => {
        this.sidenav.options.preventScrolling = window.innerWidth < 1200
      }, 300),
      { passive: true }
    )
  }

  handleHideClick = e => {
    e.preventDefault()
    trackClick('header_close_nav')
  }

  handleLoginClick = e => {
    const { showModal } = this.props

    e.preventDefault()
    showModal('USER_LOGIN')
    trackClick('nav_log_in')
  }

  handleLogoClick = e => {
    const { path } = this.props

    if (path === '/') {
      e.preventDefault()
      window.scrollTo(0, 0)
    }

    trackClick('nav_logo')
  }

  handleSignupClick = () => {
    trackClick('nav_sign_up')
    trackSignupButtonClick('side_nav')
  }

  restoreMenuFromStorage = () => {
    const menuOpen = window.localStorage.getItem('sidenav')
    if (menuOpen === 'closed') {
      this.hideMenu()
    } else {
      this.showMenu()
    }
  }

  hideMenu = e => {
    if (e) {
      e.preventDefault()
    }
    this.sidenav?.close()
    const rootEl = document.getElementById('root')
    if (!rootEl) return
    rootEl.classList.add('left-closed')
    this.leftColRef.current.classList.add('closed')
    window.localStorage.setItem('sidenav', 'closed')
    // fire window resize event for objects that rely on window size
    const event = new Event('resize')
    window.dispatchEvent(event)
  }

  showMenu = e => {
    if (e) {
      e?.preventDefault()
    }
    this.sidenav.open()
    const rootEl = document.getElementById('root')
    if (!rootEl) return
    rootEl.classList.remove('left-closed')
    this.leftColRef.current.classList.remove('closed')
    window.localStorage.setItem('sidenav', 'open')
    // fire window resize event for objects that rely on window size
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  render() {
    const {
      channels,
      featureFlags,
      isAuthenticated,
      isLiveEventOn,
      path,
      user,
    } = this.props
    return (
      <div className='left-col' ref={this.leftColRef}>
        <div
          className={`side-nav ${
            featureFlags.prideMonth ? 'side-nav--pride' : ''
          }`}
          id='slide-out'
          ref={this.sidenavRef}
        >
          <a
            aria-label='Close side nav'
            className='side-nav__hide sidenav-close'
            href='#close-side-nav'
            onClick={this.handleHideClick}
            role='button'
          >
            <i className='side-nav__hide-icon icon-close2' />
          </a>

          <Link
            aria-label='Home'
            className='side-nav__logo'
            onClick={this.handleLogoClick}
            role='button'
            to='/'
          />

          <SideNavMenu
            channels={channels}
            featureFlags={featureFlags}
            isAuthenticated={isAuthenticated}
            isLiveEventOn={isLiveEventOn}
            path={path}
            user={user}
            searchPath={this.props.searchPath}
          />

          {!isAuthenticated && (
            <SideNavBtns
              onLoginClick={this.handleLoginClick}
              onSignupClick={this.handleSignupClick}
            />
          )}

          <RandomVideo user={user} />

          <div className='side-nav-apps'>
            <MobileAppIcons />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { user } = state.authReducer
  const { search } = state.router.location
  return {
    user,
    searchPath: search,
  }
}

const mapDispatchToProps = {
  shopifySession,
  showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav)
