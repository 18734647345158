import { replace } from 'redux-first-history'

import { hasRole, ROLE_ADMIN, ROLE_GLOBAL_MODERATOR } from 'common/helpers'

import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'

import { showModal } from '../../../modalApp/actions'

const UserIsAdmin = connectedReduxRedirect({
  redirectPath: '/',
  authenticatedSelector: state => {
    const { isAuthenticated, user } = state.authReducer
    return (
      (isAuthenticated && hasRole(ROLE_ADMIN, user)) ||
      hasRole(ROLE_GLOBAL_MODERATOR, user)
    )
  },
  redirectAction: path => dispatch => {
    dispatch(replace(path))
    dispatch(showModal('USER_LOGIN'))
  },
  wrapperDisplayName: 'UserIsAdmin',
})

export default UserIsAdmin
