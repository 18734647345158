import React from 'react'
import PropTypes from 'prop-types'

import PaymentIcon from 'common/components/PaymentIcon'

function PaymentType(props) {
  const { lastFour, type } = props

  if (type === 'paypal') {
    return (
      <div className='qa-payment-type'>
        <PaymentIcon type='paypal' />{' '}
        <span className='qa-payment-type-paypal'>PayPal</span>
      </div>
    )
  }
  return (
    <div className='qa-payment-type'>
      <PaymentIcon type={type} />{' '}
      <span className='qa-payment-type-last-four'>
        •••• •••• •••• {lastFour}
      </span>
    </div>
  )
}

PaymentType.propTypes = {
  lastFour: props => {
    if (props.type !== 'paypal') {
      if (typeof props.lastFour !== 'string') {
        return new Error(
          `\`lastFour\` should be a string. Received \`${typeof props.lastFour}\``
        )
      }

      if (props.lastFour.length !== 4) {
        return new Error(
          `\`lastFour\` should be four characters long. Received \`${
            props.lastFour
          }\``
        )
      }
    }
  },
  type: PropTypes.string.isRequired,
}

export default PaymentType
