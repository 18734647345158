import React from 'react'

import { TAB_ALL_RESULTS } from 'rootApp/containers/SearchV2/helpers/constants'

import WrappedShowCard from '../../shared/WrappedShowCard'

const SeriesColumn = ({ closeSearchV2, series, searchTerm }) => (
  <div className='search-v2-all-results__series'>
    {series.map(series => (
      <WrappedShowCard
        key={series.uuid}
        closeSearchV2={closeSearchV2}
        series={series}
        searchTerm={searchTerm}
        tab={TAB_ALL_RESULTS}
      />
    ))}
  </div>
)

export default SeriesColumn
