import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
import defaults from 'lodash.defaults'
import { Modal } from 'materialize-css'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'

export class ModalComp extends Component {
  static propTypes = {
    banner: PropTypes.bool.isRequired,
    card: PropTypes.bool,
    // children: PropTypes.array.isRequired,
    className: PropTypes.string,
    // dispatch: PropTypes.func.isRequired,
    heading: PropTypes.string,
    // props to be passed to materialize modal instaniation
    modalProps: PropTypes.object,
  }

  static defaultProps = {
    card: true,
    heading: '',
  }

  el = document.createElement('div')

  modal = null

  modalRef = React.createRef()

  // ------
  // Lifecycle
  // ------
  componentDidMount() {
    document.body.appendChild(this.el)
    // init and open modal
    const { modalProps } = this.props
    const modalDefaults = {
      endingTop: 0,
      onCloseEnd: () => this.onClose(),
      opacity: 0.9,
      startingTop: '-10vh',
    }
    Modal.init(this.modalRef.current, defaults(modalProps, modalDefaults))
    this.modal = Modal.getInstance(this.modalRef.current)
    this.modal.open()
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
    if (this.modal) {
      this.modal.destroy()

      // Materialize doesn't reset overflow outside .modal('close')
      document.body.style.overflow = ''
    }
  }

  // ------
  // Methods
  // ------
  onClose() {
    if (this.props.onClose) {
      this.props.onClose()
    }
    if (this.modal) {
      this.modal.destroy()
    }
  }

  // ------
  // Render
  // ------
  render() {
    const { banner, card, children, className, heading, theme } = this.props

    const classes = {
      baseModal: classnames('base-modal', className),
      card: classnames('base-modal__card', 'card', {
        'base-modal__card--banner': banner,
      }),
    }
    return createPortal(
      <div className={`theme-${theme}`}>
        <div className={classes.baseModal} ref={this.modalRef}>
          {card ? (
            <Fragment>
              {heading && <h2 className='base-modal__heading'>{heading}</h2>}
              <div className={classes.card}>
                <div className='base-modal__card-content'>{children}</div>
              </div>
            </Fragment>
          ) : (
            children
          )}
        </div>
      </div>,
      this.el
    )
  }
}

const mapStateToProps = (state, _ownProps) => {
  const { theme } = state.rootReducer

  return {
    theme,
  }
}

export default connect(mapStateToProps)(ModalComp)
