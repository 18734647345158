import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import throttle from 'lodash.throttle'

import { isInViewport } from 'common/helpers'

const useRefScrolledIntoView = (
  callback,
  { topOffset, rightOffset, bottomOffset, leftOffset, once = true } = {}
) => {
  // In order to guarantee that we will perform the callback when the ref is loaded, we need a reference to the DOM node that can be used as a hook dependency (`ref` cannot)
  const [domNode, setDomNode] = useState(null)
  const calledRef = useRef(false)
  const ref = useCallback(node => setDomNode(node), [setDomNode])

  const performCallbackIfRefInView = useCallback(
    scrollHandler => {
      if (!domNode) return

      if (
        !isInViewport(domNode, {
          topOffset,
          rightOffset,
          bottomOffset,
          leftOffset,
        })
      ) {
        return
      }

      // If "once" is desired, remove the listener itself once the element has been scrolled into view to prevent unnecessary handlers. We also have to use a ref to state that the callback was called, because some throttled events may have already been queued up even though the listener was removed.
      if (once) {
        if (!calledRef.current) {
          calledRef.current = true
          callback()
        }
        if (scrollHandler) {
          window.removeEventListener('scroll', scrollHandler, true)
        }
        // If we aren't using "once" mode, always perform the callback if the element is in view.
      } else {
        callback()
      }
    },
    [domNode, callback, topOffset, rightOffset, bottomOffset, leftOffset, once]
  )

  // This function passes itself to the callback performer so that it can remove the event listener once the element has been scrolled into view.
  const throttledPerform = useMemo(
    () => throttle(() => performCallbackIfRefInView(throttledPerform), 200),
    [performCallbackIfRefInView]
  )

  useEffect(
    () => {
      if (!domNode) return () => {}

      // On initial load, perform the callback, because the element ("Show more", etc) may already be in view - no need for a scroll event.
      performCallbackIfRefInView(throttledPerform)

      window.addEventListener('scroll', throttledPerform, true)
      return () => window.removeEventListener('scroll', throttledPerform, true)
    },
    [domNode, performCallbackIfRefInView, throttledPerform]
  )

  return ref
}

export default useRefScrolledIntoView
