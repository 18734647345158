import { createSelector } from '@reduxjs/toolkit'
import {
  all,
  indexBy,
  isEmpty,
  isNil,
  omit,
  pathOr,
  prop,
  propEq,
  propOr,
  toPairs,
} from 'ramda'

import {
  getSubscriptionInvoicePrice,
  getSubscriptionPreview,
} from 'subscriptions/selectors'

import { PLAN_ANNUALLY, PLAN_MONTHLY, PLAN_SEMIANNUALLY } from './constants'

export const getSignupReducer = state => state.signupReducer
export const getSelectedPlanCode = state => state?.signupReducer?.selectedPlan
export const plans = state => state?.subscriptions?.plans?.data
export const getShouldShowPaypalOverlay = state =>
  state?.signupReducer?.shouldShowPaypalOverlay

export const getStoredPlanCode = () => {
  const storedPlanCode = localStorage.getItem('selectedPlan')
  if (
    storedPlanCode &&
    [PLAN_ANNUALLY, PLAN_SEMIANNUALLY, PLAN_MONTHLY].includes(storedPlanCode)
  ) {
    return storedPlanCode
  }
  return PLAN_ANNUALLY
}

export const getPlansByCode = createSelector(
  plans,
  indexBy(prop('plan_code'))
)

export const getPlanPrice = state => plan => {
  const plans = getPlansByCode(state)
  return pathOr(null, [plan, 'amount_in_cents'], plans)
}

export const getDetailsForPlan = createSelector(
  getPlansByCode,
  getSelectedPlanCode,
  (plans, selectedPlanCode) => propOr({}, selectedPlanCode, plans)
)

export const getPlanIdForSelectedPlan = createSelector(
  getDetailsForPlan,
  prop('id')
)

export const getPerksForPlan = createSelector(
  getDetailsForPlan,
  propOr([], 'perks')
)

export const hasSelectedPlan = createSelector(
  getSignupReducer,
  propEq('planConfirmed', true)
)

export const getSelectedPlanSubtotal = createSelector(
  getSubscriptionInvoicePrice,
  getPlanPrice,
  getDetailsForPlan,
  (priceInCents, planPrice, planDetails) => {
    if (isEmpty(priceInCents)) {
      return planPrice(planDetails)
    }
    const dollars = +priceInCents / 100
    return dollars.toLocaleString()
  }
)

export const getPlanInterval = createSelector(
  getDetailsForPlan,
  ({ plan_interval_length }) => {
    const codes = {
      12: { short: 'yr', long: 'Yearly' },
      6: { short: '6 mo', long: 'Every 6 Months' },
      1: { short: 'mo', long: 'Monthly' },
    }
    return codes[plan_interval_length]
  }
)

export const getSubtotalWithDiscount = createSelector(
  getDetailsForPlan,
  getSubscriptionInvoicePrice,
  (plan, invoicePrice) => {
    if (isNil(invoicePrice) || invoicePrice === 0) {
      return prop('amount_in_cents', plan)
    }
    return invoicePrice
  }
)

export const getIsBillingFormSubmitting = createSelector(
  getSignupReducer,
  prop('isBillingFormSubmitting')
)

export const getBillingFormFieldValues = createSelector(
  getSignupReducer,
  prop('billingForm')
)

export const getBillingFormValidity = createSelector(
  getBillingFormFieldValues,
  values => {
    const fields = omit(['coupon_code'], values)
    const pairs = toPairs(fields)
    const isValid = (...pair) => {
      const [, value] = pair[0]
      const { valid } = value
      return valid
    }
    const areAllValid = all(isValid, pairs)
    return areAllValid
  }
)

export const getPaymentMethod = createSelector(
  getSignupReducer,
  prop('paymentMethod')
)

export const getBillingDatesInfo = createSelector(
  [getSubscriptionPreview, getDetailsForPlan],
  (preview, details) => {
    if (preview === null) return {}
    const { plan_interval_length, plan_interval_unit } = details
    const {
      invoice: { subtotal_before_discount_in_cents },
      current_term_started_at,
    } = preview

    return {
      current_term_started_at,
      plan_interval_length,
      plan_interval_unit,
      subtotal_before_discount_in_cents,
      preview,
    }
  }
)

export const getCouponCode = createSelector(
  getSignupReducer,
  prop('couponCode')
)

export const getParams = createSelector(
  getSignupReducer,
  prop('params')
)

export const getCampaign = createSelector(
  getParams,
  prop('campaign')
)

export const getIsUsingExistingCreditCard = state => {
  const billingInfo = state.subscriptions?.account?.billing_info
  const { preferExistingPaymentMethod } = getSignupReducer(state)

  return billingInfo && preferExistingPaymentMethod
}
