import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './styles.scss'

function PlayBtn(props) {
  return (
    <Link
      className='play-btn-container'
      to={props.link}
      onClick={props.onClick}
      onMouseDown={e => e.preventDefault()}
    >
      <i className='play icon-play_circle_outline' />
    </Link>
  )
}

PlayBtn.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
}

PlayBtn.defaultProps = {
  link: '',
  onClick: () => null,
}

export default PlayBtn
