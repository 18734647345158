import React, { useCallback, useMemo } from 'react'

import { RTOptionCarouselOption } from 'common/components/RTOptionCarousel'

const TabHeader = ({
  searchPerformed,
  selectedTab,
  selectTab,
  tab,
  text,
  totalItems,
}) => {
  // "false" here tells selectTab that this is the header, not "view more".
  const selectViewMoreTab = useCallback(() => selectTab(false), [selectTab])
  const attrs = useMemo(() => ({ 'data-tab': tab }), [tab])

  return (
    <RTOptionCarouselOption
      key={tab}
      active={selectedTab === tab}
      attrs={attrs}
      onClick={selectViewMoreTab}
    >
      {text}{' '}
      {searchPerformed && typeof totalItems === 'number' ? (
        <span className='search-v2__tab-header-items-count'>
          ({totalItems})
        </span>
      ) : null}
    </RTOptionCarouselOption>
  )
}

export default TabHeader
