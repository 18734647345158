import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { AsyncComponent } from './common/components'

const AsyncAbout = AsyncComponent({
  loader: () => import('./metaPageApp/components/About'),
})
const AsyncCodeOfConduct = AsyncComponent({
  loader: () => import('./metaPageApp/components/CodeOfConduct'),
})
const AsyncContact = AsyncComponent({
  loader: () => import('./metaPageApp/components/Contact'),
})
const AsyncTermsOfUse = AsyncComponent({
  loader: () => import('./metaPageApp/components/TermsOfUse'),
})
const AsyncEpisode = AsyncComponent({
  loader: () => import('./episodeApp/containers/App'),
})
const AsyncEpisodes = AsyncComponent({
  loader: () => import('./episodeApp/containers/Browse'),
})
const AsyncErrorPage = AsyncComponent({
  loader: () => import('./common/components/ErrorPage'),
})
const AsyncFirstPromo = AsyncComponent({
  loader: () => import('./firstPromoApp/containers/App'),
})
const AsyncHome = AsyncComponent({
  loader: () => import('./homeApp/containers/Outer'),
})
const AsyncMyStuff = AsyncComponent({
  loader: () => import('./myStuffApp/containers/App'),
})
const AsyncLive = AsyncComponent({
  loader: () => import('./liveApp/containers/App'),
})
const AsyncLivestreamPage = AsyncComponent({
  loader: () => import('./liveApp/containers/LivestreamPage'),
})
const AsyncPlaylist = AsyncComponent({
  loader: () => import('./myStuffApp/containers/PlaylistPage'),
})
const AsyncProfilePage = AsyncComponent({
  loader: () => import('./profileApp/containers/ProfilePage'),
})
const AsyncSchedule = AsyncComponent({
  loader: () => import('./scheduleApp/containers/App'),
})
const AsyncSettings = AsyncComponent({
  loader: () => import('./settingsApp/containers/App'),
})
const AsyncShows = AsyncComponent({
  loader: () => import('./showsApp/containers/App'),
})
const AsyncShowsV2 = AsyncComponent({
  loader: () => import('./showsApp/containers/AppV2'),
})
const AsyncShowPage = AsyncComponent({
  loader: () => import('./showsApp/containers/ShowPage'),
})
const AsyncCommunity = AsyncComponent({
  loader: () => import('./communityApp/containers/App'),
})
const AsyncPost = AsyncComponent({
  loader: () => import('./postApp/App'),
})

const RtRoutes = ({ location, featureFlags }) => (
  <Routes location={location}>
    <Route exact path='/' Component={AsyncHome} />
    <Route exact path='/about' Component={AsyncAbout} />
    <Route path='/channel/:selectedChannel' Component={AsyncHome} />
    <Route exact path='/code-of-conduct' Component={AsyncCodeOfConduct} />
    <Route exact path='/contact' Component={AsyncContact} />
    <Route path='/episodes/*' Component={AsyncEpisodes} />
    <Route path='/first-promo' Component={AsyncFirstPromo} />
    <Route path='/forgot' Component={AsyncHome} />
    <Route path='/my-watchlist' element={<Navigate to='/mystuff' replace />} />
    <Route path='/library' element={<Navigate to='/mystuff' replace />} />
    <Route path='/mystuff' Component={AsyncMyStuff} />
    <Route path='/live/:slug' Component={AsyncLivestreamPage} />
    <Route path='/live' Component={AsyncLive} />
    <Route path='/premium-rss' Component={AsyncHome} />
    <Route path='/login' Component={AsyncHome} />
    <Route path='/password' Component={AsyncHome} />
    <Route path='/playlist/:id' Component={AsyncPlaylist} />
    <Route exact path='/schedule' Component={AsyncSchedule} />
    <Route path='/settings/*' Component={AsyncSettings} />
    <Route path='/series/:slug' Component={AsyncShowPage} />
    {featureFlags.showsV2 ? (
      <Route path='/series' Component={AsyncShowsV2} />
    ) : (
      <Route path='/series' Component={AsyncShows} />
    )}
    <Route
      path='/episode/:slug'
      // eslint-disable-next-line react/no-unstable-nested-components
      Component={() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { slug } = useParams()
        return <Navigate to={`/watch/${slug}`} replace />
      }}
    />
    <Route
      path='/bonus-feature/:slug'
      // eslint-disable-next-line react/no-unstable-nested-components
      Component={() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { slug } = useParams()
        return <Navigate to={`/watch/${slug}`} replace />
      }}
    />
    <Route path='/watch/:slug' Component={AsyncEpisode} />
    <Route exact path='/terms-of-use' Component={AsyncTermsOfUse} />
    {/* The new Community App accepts all "/g" URLs */}
    {featureFlags.newCommunity ? null : (
      <Route path='/g/user/:username' Component={AsyncProfilePage} />
    )}
    <Route path='/g/post/:id' Component={AsyncPost} />
    <Route path='/g/*' Component={AsyncCommunity} />
    <Route path='*' Component={AsyncErrorPage} />
  </Routes>
)

export default RtRoutes
