import React, { useCallback } from 'react'

import SocialLinks from 'common/components/SocialLinks'
import RandomVideoBox from 'common/containers/RandomVideoBox'
import { trackEvent } from 'common/metrics'

import ContentBlockBase, { Header1, Header2 } from './ContentBlockBase'

const ContentBlockRandomVideo = ({
  contentBlock,
  location,
  showSocialLinks = true,
}) => {
  const onVideoSelected = useCallback(
    randomVideo =>
      trackEvent('Content Block Item Clicked', {
        content_block_display_type: contentBlock?.attributes?.display_type,
        content_block_uuid: contentBlock?.attributes?.uuid,
        item_type: randomVideo?.type,
        item_uuid: randomVideo?.attributes?.content_uuid,
        location,
      }),
    [contentBlock, location]
  )

  const onSocialLinkClick = useCallback(
    url => {
      trackEvent('Content Block Link Clicked', {
        content_block_display_type: contentBlock?.attributes?.display_type,
        content_block_uuid: contentBlock?.attributes?.uuid,
        location,
        target_url: url,
      })
    },
    [contentBlock, location]
  )

  return (
    <ContentBlockBase>
      <div className='content-block__random-container'>
        <RandomVideoBox
          boxType='gradient-blue'
          onVideoSelected={onVideoSelected}
        >
          <Header1>Still not sure what to watch?</Header1>
          <Header2>Let us help you find your next show</Header2>
        </RandomVideoBox>
        {showSocialLinks ? (
          <div className='content-block__random-social-links'>
            <SocialLinks onClick={onSocialLinkClick} />
          </div>
        ) : null}
      </div>
    </ContentBlockBase>
  )
}

export default ContentBlockRandomVideo
