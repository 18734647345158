import React, { useCallback, useEffect, useMemo, useState } from 'react'

import './styles.scss'

const randInRange = (min, max) => Math.random() * (max - min) + min

// NOTE: This must match the CSS or else the animation is ugly.
const ANIMATION_DURATION = 5000

// A background particle effect themed to look like Kokiri Forest spirits. Absolutely positioned so it can be used just about anywhere. Particles use `transform` to get hardware acceleration. Note that when it is mounted, its parent needs to be the height that it will be for the life of the component. This is because `transform` does not allow using parent element percentages, so absolute pixel values must be used and are based on the parent container. It should not be used on components whose height changes from initial mount to subsequent renders. Similarly, on resize, the effect might shift a little bit.
const KokiriParticles = ({
  count = 20,
  particleMinSize = 2,
  particleMaxSize = 4,
}) => {
  const [[parentWidth, parentHeight], setParentDimensions] = useState([
    undefined,
    undefined,
  ])

  const dimensionsRef = useCallback(node => {
    if (!node) return
    setParentDimensions([node.offsetWidth, node.offsetHeight])
  }, [])

  const particles = []

  if (parentWidth && parentHeight) {
    for (let i = 0; i < count; i += 1) {
      particles.push(
        <Particle
          key={i}
          parentHeight={parentHeight}
          parentWidth={parentWidth}
          particleMaxSize={particleMaxSize}
          particleMinSize={particleMinSize}
        />
      )
    }
  }

  return (
    <div ref={dimensionsRef} className='rt-particles'>
      {particles}
    </div>
  )
}

const Particle = ({
  parentWidth,
  parentHeight,
  particleMinSize,
  particleMaxSize,
}) => {
  const [styleOptions, setStyleOptions] = useState(() => {
    const size = `${randInRange(particleMinSize, particleMaxSize)}px`

    return {
      translateX: Math.random() * parentWidth,
      translateY: Math.random() * parentHeight,
      rawProperties: {
        opacity: 0,
        height: size,
        width: size,
        filter: `blur(${Math.floor(Math.random() * 3)}px)`,
      },
    }
  })

  useEffect(
    () => {
      let timeoutId

      const setLeft = () =>
        setStyleOptions(s => ({
          ...s,
          translateX: Math.random() * parentWidth,
        }))

      const setTop = () =>
        setStyleOptions(s => ({
          ...s,
          translateY: Math.random() * parentHeight,
          // Update opacity and filter on every vertical change for subtlety
          rawProperties: {
            ...s.rawProperties,
            // Random opacity between 60% and 100%
            opacity: Math.random() * 0.6 + 0.4,
            filter: `blur(${Math.floor(Math.random() * 3)}px)`,
          },
        }))

      function queueSetLeft() {
        timeoutId = setTimeout(() => {
          setLeft()
          queueSetTop()
        }, ANIMATION_DURATION / 2)
      }

      function queueSetTop() {
        timeoutId = setTimeout(() => {
          setTop()
          queueSetLeft()
        }, ANIMATION_DURATION / 2)
      }

      setTimeout(() => {
        setLeft()
        setTop()
        queueSetLeft()
      }, Math.random() * ANIMATION_DURATION)

      return () => clearTimeout(timeoutId)
    },
    [parentHeight, parentWidth]
  )

  // NOTE: We use two `transform` elements here, an inner one and an outer one. The reason for this is that you cannot apply transitions to translateX and translateY independently for the same element (unlike `left` and `top`).
  const outerStyle = useMemo(
    () => ({
      transform: `translateX(${styleOptions.translateX}px)`,
    }),
    [styleOptions.translateX]
  )

  const innerStyle = useMemo(
    () => ({
      ...styleOptions.rawProperties,
      transform: `translateY(${styleOptions.translateY}px)`,
    }),
    [styleOptions.rawProperties, styleOptions.translateY]
  )

  return (
    <div style={outerStyle} className='rt-particles__particle-outer'>
      <div style={innerStyle} className='rt-particles__particle' />
    </div>
  )
}

export default KokiriParticles
