import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const SideNavLibrary = ({ onClick, _path }) => (
  <ul className='qa-side-nav-my-stuff'>
    <li className='side-nav-menu__item'>
      <NavLink
        className='side-nav-menu__link waves-effect waves-brand'
        onClick={e => {
          onClick({
            e,
            label: 'nav-my-stuff',
            options: { context: 'my-playlists' },
          })
        }}
        to='/mystuff#my-playlists'
      >
        My Playlists
      </NavLink>
    </li>
    <li className='side-nav-menu__item'>
      <NavLink
        className='side-nav-menu__link waves-effect waves-brand'
        onClick={e => {
          onClick({
            e,
            label: 'nav-my-stuff',
            options: { context: 'discover' },
          })
        }}
        to='/mystuff#discover'
      >
        Discover
      </NavLink>
    </li>
    <li className='side-nav-menu__item'>
      <NavLink
        className='side-nav-menu__link waves-effect waves-brand'
        onClick={e => {
          onClick({
            e,
            label: 'nav-my-stuff',
            options: { context: 'followed-playlists' },
          })
        }}
        to='/mystuff#followed-playlists'
      >
        Followed Playlists
      </NavLink>
    </li>
    <li className='side-nav-menu__item'>
      <NavLink
        className='side-nav-menu__link waves-effect waves-brand'
        onClick={e => {
          onClick({
            e,
            label: 'nav-my-stuff',
            options: { context: 'followed-series' },
          })
        }}
        to='/mystuff#followed-series'
      >
        Followed Series
      </NavLink>
    </li>
  </ul>
)

SideNavLibrary.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default SideNavLibrary
