import { POST_ADD_COMMENT_SUCCESS } from 'common/actions/types'
import { addCommentToPost, notice } from 'common/helpers'

const initialState = {}

/**
 * Because posts have a common format and set of interactions we centeralize them
 * here. Try not to make your own. Just store the id/slug elsewhere.
 */
export default (
  state = initialState,
  { type, data: post, req, comment, resp }
) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'common/GET_POST_RESP':
    case 'common/PATCH_POST_RESP':
    case 'common/USER_POST_RESP':
    case 'communityApp/c/createPost_RESP':
      const posts = { ...state, [post.id]: post }
      if (post.repost) {
        post.repostId = post.repost.id
        posts[post.repostId] = post.repost
        delete post.repost
      }
      return posts
    case 'common/GET_USER_FEED_RESP':
    case 'communityApp/c/getFeed_RESP':
    case 'communityApp/c/getMyFeed_RESP':
    case 'communityApp/c/getTaggedPosts_RESP':
    case 'communityApp/EXAMPLE_POSTS_RESP':
      const newstate = { ...state }

      if (resp.stickied_post) {
        newstate[resp.stickied_post.id] = resp.stickied_post
        if (resp.stickied_post.repost) {
          resp.stickied_post.repostId = resp.stickied_post.repost.id
          newstate[resp.stickied_post.repostId] = resp.stickied_post.repost
          delete resp.stickied_post.repost
        }
      }

      for (const p of post) {
        // tagged posts do not have a meta wrapper
        let apost = p
        if (p.post) {
          apost = p.post
        }

        if (apost.repost) {
          apost.repostId = apost.repost.id
          newstate[apost.repostId] = apost.repost
          delete apost.repost
        }
        newstate[apost.id] = apost
      }

      return newstate
    case 'communityApp/g/post/like_RESP':
    case 'communityApp/g/post/unlike_RESP':
      const newpost = {}
      for (const postId of Object.keys(state)) {
        post = state[postId]

        if (req.postId === postId) {
          newpost[postId] = {
            ...post,
            metadata: {
              ...post.metadata,
              likes: resp.likes,
            },
          }
        } else {
          newpost[postId] = state[postId]
        }
      }

      return newpost
    case 'common/DELETE_POST_RESP':
      const deletedPost = { ...state }
      // keep around deleted posts so reposts can still reference them
      for (const id in deletedPost) {
        if (id === req.id) {
          deletedPost[id] = {
            ...deletedPost[id],
            deleted: true,
            body: null,
            body_length: 0,
            repost: null,
            spoiler: null,
            unfurl_url: null,
          }
        }
      }
      return deletedPost
    case POST_ADD_COMMENT_SUCCESS:
      if (!state[comment.topic_uuid]) {
        return state
      }

      notice({
        iconColor: 'blue',
        header: '',
        body: 'Success! Comment added',
      })

      return {
        ...state,
        [comment.topic_uuid]: addCommentToPost({
          comment,
          post: state[comment.topic_uuid],
        }),
      }

    default:
      return state
  }
}
