import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TertiaryBtn from 'common/components/Buttons/Tertiary'

import { showModal } from 'modalApp/actions'

export class FollowBtnBase extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    userUuid: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
    disabled: false,
  }

  // ---------
  // Methods
  // ---------
  toggleFollow = e => {
    const { dispatch, isFollowing, user, userUuid } = this.props

    e.preventDefault()

    if (!user) {
      return this.showLogin()
    }

    if (user && !user?.attributes?.email_verified) {
      dispatch(showModal('VERIFY_EMAIL'))
      return
    }

    const action = isFollowing
      ? 'common/DELETE_USER_FOLLOW'
      : 'common/POST_USER_FOLLOW'
    dispatch({ type: action, id: userUuid })
  }

  showLogin() {
    const { dispatch } = this.props

    dispatch(showModal('USER_LOGIN'))
  }

  // ---------
  // Render
  // ---------

  render() {
    const {
      className,
      dispatch, // eslint-disable-line no-unused-vars
      isFollowing,
      user,
      userUuid,
      ...moreProps
    } = this.props

    if (user?.id === userUuid) {
      return null
    }

    return (
      <TertiaryBtn
        className={classnames('btn-follow', className)}
        onClick={this.toggleFollow}
        {...moreProps}
      >
        {isFollowing ? 'Following' : 'Follow'}
      </TertiaryBtn>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { communityUsers } = state.communityReducer
  const { user } = state.authReducer

  const isFollowing = communityUsers[ownProps.userUuid]
    ? communityUsers[ownProps.userUuid].metadata.followed
    : false

  return {
    isFollowing,
    user,
  }
}

export default connect(mapStateToProps)(FollowBtnBase)
