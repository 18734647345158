export const normalizeCommunityUser = ({
  userFromBusinessService,
  userFromCommunityService,
}) => {
  // Community service user
  const communityServiceFields = {
    badges: userFromCommunityService.badges,
    display_title: userFromCommunityService.display_title,
    id: userFromCommunityService.id,
    member_tier: userFromCommunityService.member_tier,
    posts: userFromCommunityService.posts,
    profile_picture: userFromCommunityService.profile_picture,
    roles: userFromCommunityService.roles,
    username: userFromCommunityService.name,
  }

  const communityServiceMetadataFields = {
    followed: userFromCommunityService.metadata.followed,
    followers_count: userFromCommunityService.metadata.followers_count,
    following_count: userFromCommunityService.metadata.following_count,
    following: userFromCommunityService.metadata.following,
    muted: userFromCommunityService.metadata.muted,
  }

  // The community backend always adds one follower to the followers count. I have no idea why.
  communityServiceMetadataFields.followers_count -= 1

  // Business service user (if provided)
  const businessServiceAttibutesFields = userFromBusinessService
    ? {
        about: userFromBusinessService.attributes.about,
        badges: userFromBusinessService.attributes.badges,
        banned_until: userFromBusinessService.attributes.banned_until,
        cover: userFromBusinessService.attributes.cover,
        created_at: userFromBusinessService.attributes.created_at,
        display_title: userFromBusinessService.attributes.display_title,
        expires_at: userFromBusinessService.attributes.expires_at,
        is_first_plus: userFromBusinessService.attributes.is_first_plus,
        location: userFromBusinessService.attributes.location,
        member_tier_i: userFromBusinessService.attributes.member_tier_i,
        member_tier: userFromBusinessService.attributes.member_tier,
        pictures: userFromBusinessService.attributes.pictures,
        social_connected: userFromBusinessService.attributes.social_connected,
        store_region: userFromBusinessService.attributes.store_region,
        timezone: userFromBusinessService.attributes.timezone,
        used_trial: userFromBusinessService.attributes.used_trial,
        username: userFromBusinessService.attributes.username,
        uuid: userFromBusinessService.attributes.uuid,
      }
    : {}

  return {
    ...businessServiceAttibutesFields,
    ...communityServiceFields,
    ...communityServiceMetadataFields,
  }
}

// Only allow Chelsea to post in `/g/rtnews`. This is an insecure client side check for something that really should be done on the server. But the site is shutting down in two months and we just need a fast solution.
export const authorizedToPost = (communityUsername, subCommunitySlug) => {
  if (subCommunitySlug !== 'rtnews') return true
  return communityUsername === 'Chelsea'
}
