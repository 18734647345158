import uniqBy from 'lodash.uniqby'

import {
  GET_LAST_PLAYED_FAILURE,
  GET_LAST_PLAYED_REQUEST,
  GET_LAST_PLAYED_SUCCESS,
  GET_SHOW_FAILURE,
  GET_SHOW_REQUEST,
  GET_SHOW_SUCCESS,
  GET_SHOWS_FAILURE,
  GET_SHOWS_REQUEST,
  GET_SHOWS_SUCCESS,
  LAST_PLAYED_RESET,
  RESET_SHOWS,
} from '../actions/types'

const initialState = {
  error: null,
  filter: 'nada',
  sort: '',
  isFetching: false,
  lastPlayed: null,
  lastUpdated: null,
  page: 0,
  totalPages: 1,
  shows: [],
}

const showsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SHOWS:
      return initialState
    case GET_LAST_PLAYED_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_LAST_PLAYED_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        lastPlayed: action.lastPlayed,
      }
    case GET_LAST_PLAYED_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case GET_SHOWS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    case GET_SHOWS_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        hasFetched:
          action.filter === state.filter && action.sort === state.sort
            ? !!state.shows.length
            : false,
        page: action.page ?? state.page,
        totalPages: action.totalPages ?? state.totalPages,
        filter: action.filter || state.filter,
        sort: action.sort || state.sort,
        shows:
          action.filter === state.filter && action.sort === state.sort
            ? uniqBy([...state.shows, ...action.shows], 'uuid')
            : action.shows,
      }
    case GET_SHOWS_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    case GET_SHOW_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      }
    case GET_SHOW_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        lastUpdated: action.receivedAt,
        shows: [...state.shows, action.show],
      }
    case GET_SHOW_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      }
    case LAST_PLAYED_RESET:
      return {
        ...state,
        lastPlayed: null,
      }
    default:
      return state
  }
}

export default showsReducer
