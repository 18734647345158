import React from 'react'
import Loadable from 'react-loadable'

import { Preloader } from 'common/components'

const loader = () => <Preloader condition={false} color='#ff9800' />

const AsyncComponent = opts =>
  Loadable(
    Object.assign(
      {
        loading: loader,
        delay: 600,
        timeout: 10,
      },
      opts
    )
  )

export default AsyncComponent
