/* eslint-disable no-unused-vars */
import { addCommentToFeed, addCommentToPost, notice } from 'common/helpers'
const initialState = {
  chatMeta: {},
  communityDoesNotExist: null,
  header: '',
  imageError: '',
  inviteId: null,
  isFetching: false,
  isUpdatingCommunity: false,
  logo: '',
  memberFilter: true,
  members: { list: [], pagination: {}, isFetching: false, canPage: true },
  showEdit: false,
}

let posts
export default (
  state = initialState,
  { type, data, pagination, req, images, resp, ...rest }
) => {
  switch (type) {
    case 'auth/POST_LOGIN_SUCCESS':
    case 'auth/POST_LOGOUT_SUCCESS':
      return initialState
    case 'communityApp/subCommunity_REQ':
      return { ...initialState, isFetching: true }
    case 'communityApp/subCommunity_RESP':
      return {
        ...state,
        ...data,
        isFetching: false,
      }
    case 'communityApp/subCommunity_ERR':
      return {
        ...state,
        communityDoesNotExist: true,
      }
    case 'communityApp/subCommunity/updateCommunity_REQ':
      return {
        ...state,
        isUpdatingCommunity: true,
      }

    case 'communityApp/subCommunity/updateCommunity_RESP':
      return {
        ...state,
        ...data,
        isUpdatingCommunity: false,
        showEdit: false,
      }
    case 'communityApp/g/toggleEdit':
      return {
        ...state,
        showEdit: rest.showEdit,
      }

    case 'communityApp/subCommunity/uploadCommunityAvatar':
      return {
        ...state,
        logo: images[0].url,
      }
    case 'communityApp/subCommunity/uploadCommunityBg':
      return {
        ...state,
        header: images[0].url,
      }
    case 'communityApp/subCommunity/updateCommunityBG_RESP':
    case 'communityApp/subCommunity/updateCommunityAvatar_RESP':
      return {
        ...state,
        updatingImage: false,
      }
    case 'communityApp/subCommunity/updateCommunityAvatar_ERR':
      return {
        ...state,
        imageError: data.error,
      }

    case 'communityApp/subCommunity/SET_INVITE_ID':
      return {
        ...state,
        inviteId: rest.id,
      }

    case 'communityApp/c/acceptInvite_RESP':
      return {
        ...state,
        inviteId: null,
      }
    case 'communityApp/c/getMembers_REQ':
      return {
        ...state,
        members: {
          ...state.members,
          isFetching: true,
        },
      }
    case 'communityApp/c/getMembers_ERR':
      return {
        ...state,
        members: {
          ...state.members,
          isFetching: false,
        },
      }
    case 'communityApp/c/getMembers_RESP':
      let membersList = []

      if (req.reset) {
        membersList = data.map(membership => ({
          ...membership,
          userId: membership.user.id,
          user: null,
        }))
      } else {
        membersList = [
          ...state.members.list,
          ...data.map(membership => ({
            ...membership,
            userId: membership.user.id,
            user: null,
          })),
        ]
      }

      return {
        ...state,
        members: {
          list: membersList,
          pagination,
          isFetching: false,
          canPage: !!pagination.next,
        },
        memberFilter: req.filter,
      }

    // Ban Handlers
    // -------
    case 'community/c/ban_REQ':
      return {
        ...state,
        banError: false,
      }
    case 'community/c/ban_RESP':
      return {
        ...state,
        banMember: data,
      }
    case 'community/c/ban_ERR':
      return {
        ...state,
        banError: true,
      }

    // Unban Handlers
    // -------
    case 'community/c/unban_REQ':
      return {
        ...state,
        unbanError: null,
      }
    case 'community/c/unban_RESP':
      return {
        ...state,
        unbanMember: data,
      }
    case 'community/c/unban_ERR':
      return {
        ...state,
        unbanError: true,
      }
    default:
      return state
  }
}
