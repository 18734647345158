import { call, put } from 'redux-saga/effects'

import { cache as cacheSingleton } from 'common/cache-singleton'
import { getAuthHeaders } from 'common/headers'
import { authenticatedFetch } from 'common/helpers/authenticated-fetch'

// @param url url to be fetched
// @param type type to be dispatched on success/err with _RESP / _ERR
// success will have `resp` with the full json data, `data` with the data attr of resp, `pagination` if applicable
// error will have `error` the full response including status code or whatever was thrown
export function* simpleCall({
  url,
  body = null,
  method = 'GET',
  type,
  // TODO: The cache option is only used in CreatePostModal and getMemberships. When we replace simpleGET, just use cache-singleton directly from those places.
  cache,
  authenticated = false,
  ...rest
}) {
  const reqType = `${type}_REQ`
  const successType = `${type}_RESP`
  const errorType = `${type}_ERR`
  const req = { body, method, url, ...rest }
  yield put({ type: reqType, req })
  const opts = {}

  let xhr = fetch
  if (authenticated) {
    xhr = authenticatedFetch
    opts.headers = getAuthHeaders()
  }

  if (method) {
    opts.method = method
  }

  if (body) {
    opts.body = JSON.stringify(body)
  }

  let response
  try {
    if (cache) {
      response = yield cacheSingleton.getDo(url, () => xhr(url, opts), cache)
    } else {
      try {
        response = yield call(xhr, url, opts)
      } catch (e) {
        response = e
      }
    }

    let resp = {}
    if (response.status < 500) {
      resp = yield response.json()
    }

    if (response.status >= 200 && response.status < 300) {
      // For DELETE requests, the community backend does not return a "data" key in the root object.
      const data = method === 'DELETE' ? resp : resp.data

      yield put({
        type: successType,
        resp,
        pagination: resp.pagination || null,
        data,
        req,
      })
      return resp
    }
    if (response.status === 401) {
      return yield put({ type: 'auth/POST_LOGOUT_SUCCESS' })
    }
    if (response.status === 400) {
      return yield put({ type: errorType, errors: resp.errors, req })
    }
    if (response.status === 404) {
      return yield put({
        type: errorType,
        errors: resp.message || 'not found',
        req,
      })
    }
    throw new Error(resp.message || 'unknown')
  } catch (e) {
    return yield put({
      type: errorType,
      errors: e,
      req,
      response: response || {},
    })
  }
}

export { simpleCall as simpleGET }
