import { useCallback, useEffect, useState } from 'react'

import { trackEvent } from 'common/metrics'

import { HUB_BODY_CLASS } from 'myHubApp/constants'

// When MyHub is displayed, add a class to the body that prevents scrolling so that its contents cannot be inadvertently scrolled underneath the hub (which feels weird)
export const useHubBodyClass = () => {
  useEffect(() => {
    document.body.classList.add(HUB_BODY_CLASS)
    return () => document.body.classList.remove(HUB_BODY_CLASS)
  }, [])
}

export const useLoadingScreen = ({ duration, showLoadingScreen }) => {
  // Show a loading screen initially only if one is desired.
  const [isLoading, setIsLoading] = useState(showLoadingScreen)
  useEffect(
    () => {
      setTimeout(() => {
        if (isLoading) setIsLoading(false)
      }, duration)
    },
    [duration, isLoading]
  )

  return isLoading
}

const useHandleCloseButtonClick = ({ closeMyHub, loaded }) =>
  useCallback(
    () => {
      closeMyHub()
      trackEvent('My Hub Dismissed', {
        loading: !loaded,
        source: 'close_button',
      })
    },
    [closeMyHub, loaded]
  )

const useHandleEscapeKey = ({ closeMyHub, loaded }) =>
  useEffect(
    () => {
      const handleEscapeKey = e => {
        const isEscape = e.code === 'Escape'

        if (!isEscape) return

        closeMyHub()
        trackEvent('My Hub Dismissed', {
          loading: !loaded,
          source: 'escape_key',
        })
      }
      window.addEventListener('keyup', handleEscapeKey)
      return () => window.removeEventListener('keyup', handleEscapeKey)
    },
    [closeMyHub, loaded]
  )

export const useHubClickHandlers = ({ closeMyHub, loaded }) => {
  const handleCloseButtonClick = useHandleCloseButtonClick({
    closeMyHub,
    loaded,
  })
  useHandleEscapeKey({ closeMyHub, loaded })

  return { handleCloseButtonClick }
}
