import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import PaginatorButton from './button'

class Paginator extends Component {
  static propTypes = {
    totalPages: PropTypes.number,
    activePage: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
    maxButtons: PropTypes.number,
    onNextClick: PropTypes.func,
    onPageClick: PropTypes.func,
    onPreviousClick: PropTypes.func,
    onSelect: PropTypes.func,
  }

  static defaultProps = {
    activePage: 1,
    totalPages: 10,
    onNextClick() {},
    onPageClick() {},
    onPreviousClick() {},
  }

  constructor(props) {
    super(props)
    const { activePage, totalPages } = props

    this.state = {
      activePage: activePage > 0 && activePage <= totalPages ? activePage : 1,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activePage !== this.state.activePage) {
      this.setState({ activePage: nextProps.activePage })
    }
  }

  handleClick = (e, i) => {
    const { totalPages, onSelect } = this.props
    e.preventDefault()

    if (i > 0 && i <= totalPages) {
      if (onSelect) {
        onSelect(i)
      }
      this.setState({ activePage: i })
    }
  }

  renderButtons = () => {
    const { totalPages, children, maxButtons = totalPages } = this.props
    const { activePage } = this.state

    if (children) return children

    const buttonsCount = Math.min(maxButtons, totalPages)

    const maxPos = totalPages - buttonsCount
    const halfButtons = parseInt(buttonsCount / 2, 10)

    let hiddenPagesBefore = activePage - halfButtons
    if (hiddenPagesBefore > maxPos) {
      hiddenPagesBefore = maxPos + 1
    }

    const from = Math.max(hiddenPagesBefore, 1)
    const to = Math.min(totalPages, from + maxButtons - 1)

    const buttons = [
      <PaginatorButton
        disabled={activePage === 1}
        key='pagination-0'
        onSelect={e => {
          this.props.onPreviousClick.bind(this)(e)
          this.handleClick(e, activePage - 1)
        }}
      >
        <i className='icon-keyboard_arrow_left' />
      </PaginatorButton>,
    ]

    for (let i = from; i <= to; i += 1) {
      buttons.push(
        <PaginatorButton
          active={i === activePage}
          key={`pagination-${i}`}
          onSelect={e => {
            this.props.onPageClick.bind(this)(e)
            this.handleClick(e, i)
          }}
        >
          {i}
        </PaginatorButton>
      )
    }

    buttons.push(
      <PaginatorButton
        key={`pagination-${totalPages + 1}`}
        disabled={activePage === totalPages}
        onSelect={e => {
          this.props.onNextClick.bind(this)(e)
          this.handleClick(e, activePage + 1)
        }}
      >
        <i className='icon-keyboard_arrow_right' />
      </PaginatorButton>
    )

    return buttons
  }

  render() {
    return (
      <ul className={classnames('pagination', this.props.className)}>
        {this.renderButtons()}
      </ul>
    )
  }
}

export default Paginator
