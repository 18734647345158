import React from 'react'

import RTXLogo from 'common/assets/rtx23_logo_light.png'
import RTXLogoOrange from 'common/assets/rtx23_logo_orange.png'
import useCountdown from 'common/hooks/useCountdown'

import './styles.scss'

const Logo = () => (
  <div className='rtx-countdown__logo-container'>
    <img
      className='rtx-countdown__logo rtx-countdown__logo--white'
      src={RTXLogo}
      alt='RTX logo'
    />
    <img
      className='rtx-countdown__logo rtx-countdown__logo--orange'
      src={RTXLogoOrange}
      alt='RTX logo'
    />
  </div>
)

const CountdownPart = ({ number, label }) => (
  <div className='rtx-countdown__countdown-part'>
    <div className='rtx-countdown__countdown-part-top'>{number}</div>
    <div className='rtx-countdown__countdown-part-bottom'>{label}</div>
  </div>
)

const CountdownColon = () => (
  <div className='rtx-countdown__countdown-colon'>:</div>
)

const RtxCountdown = ({ date }) => {
  const { days, hours, minutes, seconds } = useCountdown(date)

  return (
    <div className='top-nav__rtx-countdown-container'>
      <a
        className='rtx-countdown'
        href='https://www.rtxaustin.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Logo />
        <CountdownPart number={days} label='Days' />
        <CountdownColon />
        <CountdownPart number={hours} label='Hours' />
        <CountdownColon />
        <CountdownPart number={minutes} label='Min' />
        <CountdownColon />
        <CountdownPart number={seconds} label='Sec' />
        <Logo />
      </a>
    </div>
  )
}

export default RtxCountdown
