import React, { Component } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TertiaryBtn from 'common/components/Buttons/Tertiary'
import { notice } from 'common/helpers'

import { showModal } from 'modalApp/actions'

function isMuted(mutedList, userId) {
  if (!mutedList) {
    return false
  }
  return !!mutedList[userId]
}

export class MuteTertiaryBtn extends Component {
  static propTypes = {
    className: PropTypes.string,
    userUuid: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  state = {
    isMuted: false,
  }

  // ---------
  // Lifecycle
  // ---------
  static getDerivedStateFromProps(props, _state) {
    return {
      isMuted: isMuted(props.muted, props.userUuid),
    }
  }

  componentDidMount() {
    const { dispatch, user, userUuid } = this.props
    if (user) {
      dispatch({
        type: 'communityApp/MY_MUTED',
        userId: user.id,
        targetUserId: userUuid,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, user, userUuid, mutedStatus } = this.props
    const { isMuted } = this.state
    if (user && (prevProps.user !== user || prevProps.userUuid !== userUuid)) {
      dispatch({
        type: 'communityApp/MY_MUTED',
        userId: user.id,
        targetUserId: userUuid,
      })
    }

    if (isMuted !== prevState.isMuted && mutedStatus !== null) {
      isMuted ? this.showMutedToast() : this.showUnmutedToast()
    }
  }

  // ---------
  // Methods
  // ---------
  showLogin = () => {
    this.props.dispatch(showModal('USER_LOGIN', { user: '' }))
  }

  showMutedToast = () => {
    const { userName } = this.props
    notice({
      header: 'Success!',
      body: `@${userName} muted`,
    })
  }

  showUnmutedToast = () => {
    const { userName } = this.props
    notice({
      header: 'Success!',
      body: `@${userName} unmuted`,
    })
  }

  toggleMute = () => {
    const { dispatch, userUuid, user } = this.props
    const { isMuted } = this.state

    if (!user) {
      return this.showLogin()
    }

    const action = isMuted ? 'communityApp/UNMUTE' : 'communityApp/MUTE'
    dispatch({ type: action, targetUserId: userUuid, userId: user.id })
  }

  // ---------
  // Render
  // ---------

  render() {
    const { className } = this.props

    const { isMuted } = this.state

    // dispatch isn't allowed on button elements, which props gets passed to in TertiaryBtn
    const btnProps = { ...this.props }
    delete btnProps.dispatch
    delete btnProps.userUuid
    delete btnProps.myFollowing
    delete btnProps.mutedStatus
    delete btnProps.userName

    const text = isMuted ? 'Unmute' : 'Mute'
    const stateClasses = classnames({ primary: !isMuted })

    const iconClasses = classnames({
      'icon-eye-slash': !isMuted,
      'icon-eye': isMuted,
    })
    return (
      <TertiaryBtn
        {...btnProps}
        className={`${className} ${stateClasses}`}
        onClick={this.toggleMute}
      >
        <i className={`icon muted-icon ${iconClasses}`} />
        {text}
      </TertiaryBtn>
    )
  }
}

const mapStateToProps = (state, _ownProps) => {
  const { mutes } = state.communityReducer
  const { user } = state.authReducer

  return {
    muted: mutes.muted,
    mutedStatus: mutes.mutedStatus,
    user,
  }
}

export default connect(mapStateToProps)(MuteTertiaryBtn)
