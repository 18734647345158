import rtApi from '@roosterteethproductions/svod-api'

import { trackClick } from 'common/metrics'

import {
  CLOSE_MOBILE_NAV,
  CLOSE_SEARCH_V2,
  GET_BADGES_FAILURE,
  GET_BADGES_REQUEST,
  GET_BADGES_SUCCESS,
  GET_CHANNELS_FAILURE,
  GET_CHANNELS_REQUEST,
  GET_CHANNELS_SUCCESS,
  OPEN_MOBILE_NAV,
  OPEN_SEARCH_V2,
  SELECT_CHANNEL,
  SET_VIDEO_PLAYER_STATE,
} from './types'

export const selectChannel = channel => ({
  type: SELECT_CHANNEL,
  channel,
})

/* CHANNEL FUNCTIONS */
export const requestChannels = () => ({
  type: GET_CHANNELS_REQUEST,
})

export const receiveChannels = channels => ({
  type: GET_CHANNELS_SUCCESS,
  channels,
})

export const fetchChannelsFailure = ex => ({
  type: GET_CHANNELS_FAILURE,
  error: ex.message,
})

export const fetchChannels = () => dispatch => {
  dispatch(requestChannels())
  rtApi.channels
    .fetchAll()
    .then(json => dispatch(receiveChannels(json.data)))
    .catch(ex => dispatch(fetchChannelsFailure(ex)))
}

/* Badges */
export const requestBadges = () => ({
  type: GET_BADGES_REQUEST,
})

export const receiveBadges = badges => ({
  type: GET_BADGES_SUCCESS,
  badges,
})

export const fetchBadgesFailure = ex => ({
  type: GET_BADGES_FAILURE,
  error: ex.message,
})

export const fetchBadges = () => dispatch => {
  dispatch(requestBadges())
  rtApi.users
    .fetchBadges()
    .then(json => dispatch(receiveBadges(json)))
    .catch(ex => dispatch(fetchBadgesFailure(ex)))
}

/* Persistent Player State */
// will be oneOf ['closed','maximized','minimized','userMaximized']
export const setVideoPlayerState = newState => ({
  type: SET_VIDEO_PLAYER_STATE,
  playerState: newState,
})

export const openSearchV2 = () => dispatch => dispatch({ type: OPEN_SEARCH_V2 })

export const closeSearchV2 = () => dispatch => {
  trackClick('search_close')
  dispatch({ type: CLOSE_SEARCH_V2 })
}

export const openMobileNav = () => {
  document.documentElement.classList.add('disable-scroll')
  return { type: OPEN_MOBILE_NAV }
}
export const closeMobileNav = () => {
  document.documentElement.classList.remove('disable-scroll')
  return { type: CLOSE_MOBILE_NAV }
}
