import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const MaterializePreloader = ({
  children,
  condition = false,
  color = '#c9373f',
  size = 'medium',
}) => {
  const spinner = (
    <div className='materialize-preloader qa-materialize-preloader'>
      <div className={`preloader-wrapper ${size} active`}>
        <div
          className='materialize-preloader__spinner spinner-layer'
          style={{ borderColor: color }}
        >
          <div className='circle-clipper left'>
            <div className='circle' />
          </div>
          <div className='gap-patch'>
            <div className='circle' />
          </div>
          <div className='circle-clipper right'>
            <div className='circle' />
          </div>
        </div>
      </div>
    </div>
  )

  return condition ? children : spinner
}

MaterializePreloader.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  condition: PropTypes.bool,
  size: PropTypes.string,
}

export default MaterializePreloader
