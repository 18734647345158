import { createSelector } from '@reduxjs/toolkit'
import { path, pathOr, prop } from 'ramda'

export const getAuthReducer = state => state.authReducer
export const isLoggedIn = state => state.authReducer.isAuthenticated
export const getLoggedInUser = state => state.authReducer.user
export const getLoggedInUsername = state =>
  getLoggedInUser(state)?.attributes?.username
export const getLoggedInUserId = state => getLoggedInUser(state)?.id
export const isVerified = state =>
  getLoggedInUser(state)?.attributes?.email_verified

export const getLoggedInUserProfileImage = (state, size = 'original') => {
  const pictures = getLoggedInUser(state)?.attributes?.pictures

  if (!pictures) return undefined

  const pictureSet = pictures[size]
  return pictureSet?.profile
}

// Components can use this to see if they should wait for the user object. The root app will always attempt to fetch the user object if the auth reducer believes it is authenticated. Once the user has been successfully loaded, the auth reducer will define user. Otherwise if the user object fails to load, then the auth reducer will set isAuthenticated to false and user to null.
export const shouldWaitForUserObject = state =>
  isLoggedIn(state) && getLoggedInUser(state) === null

export const getUserTier = createSelector(
  getLoggedInUser,
  pathOr('anon', ['attributes', 'member_tier'])
)

export const getIsAuthenticated = createSelector(
  getAuthReducer,
  prop('isAuthenticated')
)

export const getRssLoginUrl = createSelector(
  getLoggedInUser,
  path(['attributes', 'supporting_cast_login_url'])
)

export const getYouTubeAuth = state => state.commonReducer.youtubeAuth

export const getYouTubeChannels = createSelector(
  getYouTubeAuth,
  prop('channels')
)

export const getSelectedChannel = createSelector(
  getYouTubeAuth,
  prop('selectedChannel')
)
