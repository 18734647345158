/* eslint-disable camelcase */
import { takeEvery } from 'redux-saga/effects'
import { simpleCall } from 'common/helpers/saga'
import { endpoints } from 'common/config'
import { trackEvent } from 'common/metrics'

export function* muteUser({ userId, targetUserId }) {
  let res = yield simpleCall({
    authenticated: true,
    url: endpoints.communityBase + `/v1/users/${userId}/mute`,
    type: 'communityApp/MUTE',
    method: 'POST',
    body: {
      users: [targetUserId],
    },
  })
  if (res.muted && res.muted.length > 0) {
    trackMute({ targetUserId, userId })
  }
}

function trackMute({ targetUserId, userId }) {
  trackEvent('SocialEvent', {
    label: 'user_muted',
    platform: 'web',
    target_id: targetUserId,
    target_type: 'user',
    user_id: userId,
  })
}

export function* unmuteUser({ userId, targetUserId }) {
  yield simpleCall({
    authenticated: true,
    url: endpoints.communityBase + `/v1/users/${userId}/unmute/${targetUserId}`,
    type: 'communityApp/UNMUTE',
    method: 'DELETE',
  })
}

/**
 * Gets a list of users that have been muted by `userId`
 * @param {String} userId - id of user to fetch list for
 */
export function* getMutedUsers({ userId, targetUserId }) {
  yield simpleCall({
    authenticated: true,
    method: 'POST',
    url: endpoints.communityBase + `/v1/users/${userId}/muted`,
    type: 'communityApp/MY_MUTED',
    body: {
      users: [targetUserId],
    },
  })
}

export function* mute() {
  yield takeEvery('communityApp/MUTE', muteUser)
  yield takeEvery('communityApp/UNMUTE', unmuteUser)
  yield takeEvery('communityApp/MY_MUTED', getMutedUsers)
}
