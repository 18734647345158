import rtApi from '@roosterteethproductions/svod-api'

import { trackClick } from 'common/metrics'

import { fetchGroupSearch, fetchUserSearch } from './fetchers'

const anyResults = fetchResponses =>
  fetchResponses.some(resp => resp.data.length > 0)

const fetchSearchTerm = ({ searchTerm, setSearchResults }) => {
  if (searchTerm.length >= 3) {
    setSearchResults(searchResults => ({ ...searchResults, loading: true }))
    const uriEncodedTerm = encodeURIComponent(searchTerm)

    trackClick('search_query', { context: searchTerm })

    Promise.all([
      rtApi.search.fetchEpisodes({ perPage: 100, term: uriEncodedTerm }),
      rtApi.search.fetchShows({ perPage: 100, term: uriEncodedTerm }),
      rtApi.search.fetchBonusFeatures({ perPage: 100, term: uriEncodedTerm }),
      // TODO: See if we still want to support automatic user search by adding the "@" sign.
      fetchUserSearch({ term: uriEncodedTerm }),
      fetchGroupSearch({ term: uriEncodedTerm }),
    ]).then(fetchResponses => {
      const [episodes, series, bonusFeatures, users, groups] = fetchResponses

      if (!anyResults(fetchResponses))
        trackClick('no_results', { context: searchTerm })

      setSearchResults(searchResults => ({
        ...searchResults,
        searchPerformed: true,
        loading: false,
        episodes,
        series,
        bonusFeatures,
        users,
        groups,
      }))
    })
  } else {
    setSearchResults(searchResults => ({
      ...searchResults,
      searchPerformed: false,
    }))
  }
}

export default fetchSearchTerm
