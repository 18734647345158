import { addOrReplacePositionObj, isTimeInFuture } from 'common/helpers'

import {
  GET_EPISODES_REQUEST,
  GET_EPISODES_SUCCESS,
  GET_EPS_PERCENT_FAILURE,
  GET_EPS_PERCENT_REQUEST,
  GET_EPS_PERCENT_SUCCESS,
  GET_FEATURED_REQUEST,
  GET_FEED_CAROUSEL_REQUEST,
  GET_FEED_CAROUSEL_SUCCESS,
  GET_LIVESTREAMS_REQUEST,
  GET_LIVESTREAMS_SUCCESS,
  GET_MERCH_FAILURE,
  GET_MERCH_REQUEST,
  GET_MERCH_SUCCESS,
  GET_PLAYLIST_DATA_SUCCESS,
  GET_PLAYLIST_OWNER_SUCCESS,
  GET_PLAYLISTS_SUCCESS,
  GET_SHOWS_REQUEST,
  GET_SHOWS_SUCCESS,
} from '../actions/types'

const addUsername = (data, users) =>
  data.map(item => {
    const match = users.find(i => i.uuid === item?.attributes?.owner_uuid)
    return Object.assign(
      {},
      item,
      match
        ? {
            attributes: {
              ...item.attributes,
              owner_name: match.username,
            },
          }
        : {}
    )
  })

const addPlaylistImages = (data, episodes) =>
  data.map(item => {
    const match = episodes.find(
      i =>
        i.uuid === item?.attributes?.display_content_uuid ||
        i.uuid === item?.attributes?.content_uuid
    )
    return match ? Object.assign({}, item, { data: match }) : item
  })

const initialState = {
  carousels: [],
  itemPercentages: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SHOWS_REQUEST:
      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: 'shows',
            filter: action.filter,
            data: [],
            isFetching: true,
          },
        ],
      }
    case GET_SHOWS_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(obj => {
          if (
            obj.type === 'shows' &&
            obj.filter.name === action.filter.name &&
            obj.filter.value === action.filter.value
          ) {
            return {
              type: 'shows',
              filter: action.filter,
              data: action.items,
              isFetching: false,

              lastUpdated: action.lastUpdated,
            }
          }
          return obj
        }),
      }
    case GET_EPISODES_REQUEST:
      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: 'episodes',
            filter: action.filter,
            data: [],
            isFetching: true,
          },
        ],
      }
    case GET_EPISODES_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(obj => {
          if (
            obj.type === 'episodes' &&
            obj.filter.name === action.filter.name &&
            obj.filter.value === action.filter.value
          ) {
            return {
              type: 'episodes',
              filter: action.filter,
              data: action.items,
              isFetching: false,

              lastUpdated: action.lastUpdated,
            }
          }
          return obj
        }),
      }
    case GET_EPS_PERCENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case GET_EPS_PERCENT_SUCCESS:
      return {
        ...state,
        itemPercentages: state.itemPercentages.concat(action.items),
        lastUpdated: Date.now(),
        isFetching: false,
      }
    case GET_EPS_PERCENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    case 'video/POST_VIDEO_SAVE_POSITION_SUCCESS':
      addOrReplacePositionObj(state.itemPercentages, {
        from: 'es',
        uuid: action.episodeId,
        value: action.played,
      })
      return {
        ...state,
        lastUpdated: Date.now(),
      }
    case GET_LIVESTREAMS_REQUEST:
      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: 'livestreams',
            filter: { type: 'livestreams', name: 'everything' },
            data: [],
            isFetching: true,
          },
          {
            type: 'livestreams',
            filter: { type: 'livestreams', name: 'comingSoon' },
            data: [],
            isFetching: true,
          },
        ],
      }
    case GET_LIVESTREAMS_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(obj => {
          if (
            obj.type === 'livestreams' &&
            obj.filter.name === 'everything' &&
            obj.filter.type === 'livestreams'
          ) {
            return {
              type: 'livestreams',
              filter: { type: 'livestreams', name: 'everything' },
              data: action.livestreams,
              isFetching: false,
            }
          }
          if (
            obj.type === 'livestreams' &&
            obj.filter.name === 'comingSoon' &&
            obj.filter.type === 'livestreams'
          ) {
            return {
              type: 'livestreams',
              filter: { type: 'livestreams', name: 'comingSoon' },
              data: action.livestreams.filter(i =>
                isTimeInFuture(i.attributes.starts_at)
              ),
              isFetching: false,
            }
          }
          return obj
        }),
      }
    case GET_FEED_CAROUSEL_REQUEST:
      let carouselType = action.carouselType

      if (carouselType === 'episode') carouselType = 'episodes'

      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: carouselType,
            data: [],
            filter: { type: action.carouselType, name: action.id },
            isFetching: true,
          },
        ],
      }
    case GET_FEED_CAROUSEL_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(carousel => {
          if (carousel.filter.name === action.id) {
            return {
              ...carousel,
              data: action.items,
              isFetching: false,
            }
          }
          return carousel
        }),
      }
    case GET_FEATURED_REQUEST:
      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: 'playlist',
            filter: { type: 'playlist', name: action.filter },
            data: [],
            isFetching: true,
          },
        ],
      }
    case GET_PLAYLISTS_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(obj => {
          if (
            obj.type === 'playlist' &&
            obj.filter.name === action.filter.name
          ) {
            return {
              type: 'playlist',
              filter: action.filter,
              data: action.items,
              isFetching: false,
            }
          }
          return obj
        }),
      }
    case GET_PLAYLIST_DATA_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(carousel => {
          if (carousel.type === 'playlist') {
            return {
              ...carousel,
              data: addPlaylistImages(carousel.data, action.items),
            }
          }
          return carousel
        }),
      }
    case GET_PLAYLIST_OWNER_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(carousel => {
          if (carousel.type === 'playlist') {
            return {
              ...carousel,
              data: addUsername(carousel.data, action.items),
            }
          }
          return carousel
        }),
      }
    case GET_MERCH_REQUEST:
      return {
        ...state,
        carousels: [
          ...state.carousels,
          {
            type: 'merch',
            filter: action.filter,
            data: [],
            isFetching: true,
          },
        ],
      }
    case GET_MERCH_FAILURE:
      return {
        ...state,
        carousels: state.carousels.filter(
          obj =>
            !(
              obj.type === 'merch' &&
              obj.filter.name === action.filter.name &&
              obj.filter.type === action.filter.type
            )
        ),
      }
    case GET_MERCH_SUCCESS:
      return {
        ...state,
        carousels: state.carousels.map(obj => {
          if (
            obj.type === 'merch' &&
            obj.filter.name === action.filter.name &&
            obj.filter.type === action.filter.type
          ) {
            return {
              type: 'merch',
              filter: action.filter,
              data: action.collection
                .filter(item => item.image !== null)
                .slice(0, 30),
              isFetching: false,

              lastUpdated: action.lastUpdated,
            }
          }
          return obj
        }),
      }
    default:
      return state
  }
}
