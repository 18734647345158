import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PrettyDate extends Component {
  static propTypes = {
    date: PropTypes.any.isRequired,
    format: PropTypes.oneOf(['numeric', 'short', 'long']).isRequired,
    // set to force to specific timezone, defaults to the browser's current timezone
    timeZone: PropTypes.string,
  }

  static defaultProps = {
    timeZone: '',
  }

  constructor(props) {
    super(props)

    this.formatDate = this.formatDate.bind(this)
    this.getIso = this.getIso.bind(this)
  }

  getIso() {
    const { date } = this.props

    return new Date(date).toISOString()
  }

  formatDate() {
    const { date, format, timeZone } = this.props
    const localeOpts = {
      day: 'numeric',
      month: format,
      year: 'numeric',
    }

    if (timeZone) {
      localeOpts.timeZone = timeZone
    }

    return new window.Date(date).toLocaleString('en-us', localeOpts)
  }

  paymentIconClass = () => {}

  render() {
    return (
      <time className='qa-pretty-date' dateTime={this.getIso()}>
        {this.formatDate()}
      </time>
    )
  }
}

export default PrettyDate
