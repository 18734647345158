import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null,
  isLinked: false,
  channels: [],
  selectedChannel: null,
}
const youtubeSlice = createSlice({
  name: 'youtubeAuth',
  initialState,
  reducers: {
    handleSuccess(state) {
      state.isLinked = true
      state.error = null
    },
    handleError(state, action) {
      state.isLinked = false
      state.error = action.payload
    },
    handleChannelsSuccess(state, action) {
      state.channels = action.payload
    },
    setSelectedChannel(state, action) {
      state.selectedChannel = action.payload
    },
  },
})

export const {
  handleChannelsSuccess,
  handleError,
  handleSuccess,
  setSelectedChannel,
} = youtubeSlice.actions
export default youtubeSlice.reducer
