import { GET_GENRE_REQUEST, GET_GENRE_SUCCESS } from '../actions/types'

const initialState = {
  availableGenres: [],
  lastUpdated: null,
}

// we have a single page app per channel so no need to store more than one featured in our state
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENRE_REQUEST:
      return {
        ...state,
        availableGenres: [],
      }
    case GET_GENRE_SUCCESS:
      return {
        ...state,
        availableGenres: action.genres,
        lastUpdated: action.receivedAt,
      }
    default:
      return state
  }
}
