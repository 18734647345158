const initialState = { log: [], pagination: {} }

export default (state = initialState, { type, pagination, data: log }) => {
  switch (type) {
    case 'communityApp/manage/GET_LOG_RESP':
      return {
        ...state,
        pagination,
        log,
      }
    case 'communityApp/manage/GET_MORE_LOG_RESP':
      return {
        ...state,
        pagination,
        log: [...state.log.log, ...log],
      }
    default:
      return state
  }
}
