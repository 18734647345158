import rtApi from '@roosterteethproductions/svod-api'

import {
  HTTP_RT_INVALID_UPSTREAM_API_TOKEN,
  timeoutPromise,
} from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  GET_VIDEO_FAILURE,
  GET_VIDEO_REQUEST,
  GET_VIDEO_SUCCESS,
  POST_VIDEO_LAST_PLAYED_SUCCESS,
  POST_VIDEO_SAVE_POSITION_SUCCESS,
} from './types'

// --------
// GET video actions
// --------
const requestVideo = id => ({
  type: GET_VIDEO_REQUEST,
  id,
})

const receiveVideo = video => ({
  type: GET_VIDEO_SUCCESS,
  video,
  receivedAt: Date.now(),
})

export const requestVideoFailure = error => ({
  type: GET_VIDEO_FAILURE,
  error,
})

export const fetchVideo = id => (dispatch, getState) => {
  dispatch(requestVideo(id))
  const state = getState()
  const { isAuthenticated } = state.authReducer

  rtApi.episodes
    .fetchVideo({ id, authenticated: isAuthenticated, watch: true })
    .then(json => {
      // if we don't have data, we have some access issue
      if (json.data) {
        dispatch(receiveVideo(json.data[0]))
      } else {
        return dispatch(requestVideoFailure(json.status))
      }
    })
    .catch(ex => {
      let error = ex.message
      if (ex.status === 403 || ex.status === 401) {
        error = 'paywall'
      }
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return dispatch(requestVideoFailure(error))
    })
}

// --------
// Saved video position actions
// --------
const receiveSavedPosition = (episodeId, played) => ({
  type: POST_VIDEO_SAVE_POSITION_SUCCESS,
  episodeId,
  played,
})

export const saveVideoPosition = (item_id, time, percentage) => (
  dispatch,
  _getState
) =>
  rtApi.wtc
    .savePosition({ item_id, time, percentage })
    .then(json => dispatch(receiveSavedPosition(item_id, json.value)))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(ex.message)
    })

export const fetchSavedPosition = id => {
  if (id) {
    return (dispatch, _getState) =>
      // @note we don't want this taking forever, otherwise the user might watch
      // a chunk of video and then get jumped backwards or forwards, instead
      // we just timeout of slow responses and let the user watch
      timeoutPromise(
        2000,
        new Error('played position timed out'),
        rtApi.wtc.fetchPosition({ id })
      )
        .then(json => dispatch(receiveSavedPosition(id, json.value)))
        .catch(ex => {
          if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
            dispatch(logoutSuccess())
          }
          return Promise.reject(ex.message)
        })
  }
  return {}
}

// --------
// Save Last Played Video in Show actions
// --------

const receiveLastPlayed = () => ({
  type: POST_VIDEO_LAST_PLAYED_SUCCESS,
})

export const saveLastPlayed = (showSlug, episodeSlug) => (
  dispatch,
  _getState
) => {
  // don't try to save last played for bonus features
  if (showSlug && episodeSlug) {
    rtApi.wtc
      .saveLastPlayed({ item_id: showSlug, last_played_id: episodeSlug })
      .then(json => dispatch(receiveLastPlayed(showSlug, json)))
      .catch(ex => {
        if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
          dispatch(logoutSuccess())
        }
        return Promise.reject(ex.message)
      })
  }
}
