import { GET_USER_SUCCESS, POST_LOGOUT_SUCCESS } from 'auth/actions/types'
import { PLAN_MONTHLY } from 'signupApp/constants'
import { PAYMENT_METHOD_CREDIT } from 'signupApp/containers/Billing/constants'
import {
  APPLY_COUPON,
  ATTEMPT_SIGN_IN_WITH_3PARTY,
  HIDE_PAYPAL_OVERLAY,
  SET_3PARTY_SIGNIN_ERROR,
  SET_BILLING_ERROR,
  SET_BILLING_FORM_FIELD,
  SET_BILLING_FORM_SUBMITTING,
  SET_CREDIT_CARD_IS_VALID,
  SET_EMAIL_ERROR,
  SET_FIRST_NAME_ERROR,
  SET_LAST_NAME_ERROR,
  SET_PASSWORD_ERROR,
  SET_PAYMENT_METHOD,
  SET_POSTAL_CODE_ERROR,
  SET_PREFER_EXISTING_PAYMENT_METHOD,
  SET_TERMS_AGREED_CHECKED,
  SET_TERMS_AGREED_CHECKED_ERROR,
  SET_URL_PARAMS_TO_STATE,
  SET_USERNAME_ERROR,
  SHOW_PAYPAL_OVERLAY,
  SOFT_RESET_SIGNUP_FLOW_FORMS,
  UPDATE_COUPON,
  UPDATE_EMAIL,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME,
  UPDATE_PASSWORD,
  UPDATE_POSTAL_CODE,
  UPDATE_SELECTED_PLAN,
  UPDATE_USERNAME,
} from 'signupApp/types'

const DEFAULT_CAMPAIGN = 'standard'

export const initialState = {
  // This is the coupon code that is updated by the form.
  couponCode: '',
  couponCodeError: null,
  // When a user hits "apply", it updates this part of the state and saves it for checkout. This means that a user can hit "apply" for a valid coupon code and then accidentally change the contents of the coupon field. As long as they don't hit "apply" again, their coupon is still applied and they still see an updated price, which be the price at checkout.
  appliedCouponCode: null,
  email: '',
  emailError: null,
  isBillingFormSubmitting: false,
  paymentMethod: PAYMENT_METHOD_CREDIT,
  password: '',
  passwordError: null,
  username: '',
  usernameError: null,
  params: {
    campaign: DEFAULT_CAMPAIGN,
  },
  passwordValidity: {
    passwordHasSpecialChar: false,
    passwordHasNumbers: false,
    passwordHasChar: false,
    passwordLongEnough: false,
    passwordIsValid: false,
  },
  firstName: '',
  firstNameError: null,
  lastName: '',
  lastNameError: null,
  postalCode: '',
  postalCodeError: null,
  // We must wait for onChange events from Recurly to update this.
  creditCardIsValid: false,
  billingError: null,
  selectedPlan: PLAN_MONTHLY,
  shouldShowPaypalOverlay: false,
  // If there an existing payment method, use it by default until the user clicks "Edit".
  preferExistingPaymentMethod: true,
  termsAgreedChecked: false,
  termsAgreedCheckedError: null,
  thirdPartySigninError: null,
}

const allItemsAreTruthy = array => array.every(Boolean)

export const getPasswordValidity = password => {
  const passwordValidityCriteria = {
    passwordHasSpecialChar: !!password.match(/[#?!@$%^&*-]/g),
    passwordHasNumbers: !!password.match(/\d+/g),
    passwordHasChar: !!password.match(/[a-z]+/gi),
    passwordLongEnough: password.length >= 8,
  }
  return {
    ...passwordValidityCriteria,
    // Convenience: Check to see if any of the criteria is not fulfilled and provide a "passwordIsValid" key.
    passwordIsValid: allItemsAreTruthy(Object.values(passwordValidityCriteria)),
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    // When a user initially loads /signup, we call this to make sure that we get rid of the contents of this reducer in case any user of the same browser was on /signup beforehand, so that there's no password/email/etc left over.
    case SOFT_RESET_SIGNUP_FLOW_FORMS: {
      return {
        ...initialState,
        // Don't clear username, paymentMethod, or selectedPlan upon form completion, because we want those to be the correct values if the logged-in user returns to the form to upgrade their account. Instead, these get cleared on logout (POST_LOGOUT_SUCCESS)
        paymentMethod: state.paymentMethod,
        username: state.username,
        selectedPlan: state.selectedPlan,
      }
    }
    case SET_URL_PARAMS_TO_STATE: {
      return {
        ...state,
        params: { ...state.params, ...action.params },
      }
    }
    case UPDATE_EMAIL: {
      return { ...state, email: action.newEmail, emailError: null }
    }
    case UPDATE_PASSWORD: {
      const password = action.newPassword
      const passwordValidity = getPasswordValidity(password)
      return {
        ...state,
        password,
        passwordError: null,
        passwordValidity,
        // If an existing email is used but an incorrect password is provided, we use the `emailError` field and just tell the user that the email exists. But they can still use the form as a login form by changing their password to the correct one.
        emailError: null,
      }
    }
    case UPDATE_USERNAME: {
      return {
        ...state,
        username: action.newUsername,
        usernameError: null,
      }
    }
    case UPDATE_FIRST_NAME: {
      return { ...state, firstName: action.newFirstName, firstNameError: null }
    }
    case UPDATE_LAST_NAME: {
      return { ...state, lastName: action.newLastName, lastNameError: null }
    }
    case UPDATE_POSTAL_CODE: {
      return {
        ...state,
        postalCode: action.newPostalCode,
        postalCodeError: null,
      }
    }
    case SET_TERMS_AGREED_CHECKED: {
      return {
        ...state,
        termsAgreedChecked: action.termsAgreedChecked,
        termsAgreedCheckedError: null,
      }
    }
    case SET_TERMS_AGREED_CHECKED_ERROR: {
      return { ...state, termsAgreedCheckedError: action.error }
    }
    case SET_EMAIL_ERROR: {
      return {
        ...state,
        emailError: action.error,
      }
    }
    case SET_PASSWORD_ERROR: {
      return {
        ...state,
        passwordError: action.error,
      }
    }
    case SET_USERNAME_ERROR: {
      return {
        ...state,
        usernameError: action.error,
      }
    }
    case SET_FIRST_NAME_ERROR: {
      return {
        ...state,
        firstNameError: action.error,
      }
    }
    case SET_LAST_NAME_ERROR: {
      return {
        ...state,
        lastNameError: action.error,
      }
    }
    case SET_POSTAL_CODE_ERROR: {
      return {
        ...state,
        postalCodeError: action.error,
      }
    }
    case ATTEMPT_SIGN_IN_WITH_3PARTY: {
      return {
        ...state,
        thirdPartySigninError: null,
      }
    }
    case SET_3PARTY_SIGNIN_ERROR: {
      return {
        ...state,
        thirdPartySigninError: action.error,
      }
    }
    case UPDATE_SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: action.selectedPlan,
      }
    }
    case SET_BILLING_FORM_SUBMITTING: {
      return {
        ...state,
        isBillingFormSubmitting: action.isSubmitting,
      }
    }

    case SHOW_PAYPAL_OVERLAY: {
      return {
        ...state,
        shouldShowPaypalOverlay: true,
      }
    }
    case HIDE_PAYPAL_OVERLAY: {
      return {
        ...state,
        shouldShowPaypalOverlay: false,
      }
    }
    case SET_BILLING_FORM_FIELD: {
      return {
        ...state,
        billingForm: {
          ...state.billingForm,
          [action.field]: action.value,
        },
      }
    }
    case SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        // Clear billing errors and credit card form errors when switching between payment methods.
        firstNameError: null,
        lastNameError: null,
        postalCodeError: null,
        billingError: null,
      }
    }
    case SET_PREFER_EXISTING_PAYMENT_METHOD: {
      return {
        ...state,
        preferExistingPaymentMethod: action.value,
      }
    }
    case SET_BILLING_ERROR: {
      return {
        ...state,
        billingError: action.error,
      }
    }
    // From auth/actions
    case GET_USER_SUCCESS: {
      return {
        ...state,
        // Sometimes getUserObject can be called when the user is on the customize form and has entered text. In this case, prefer the text that they have entered.
        username: state.username || action.user.attributes.username,
        usernameError: null,
      }
    }
    // From auth/actions
    case POST_LOGOUT_SUCCESS: {
      return initialState
    }
    case UPDATE_COUPON: {
      return {
        ...state,
        couponCode: action.couponCode,
        couponCodeError: null,
      }
    }
    case APPLY_COUPON: {
      return { ...state, appliedCouponCode: action.couponCode }
    }
    case SET_CREDIT_CARD_IS_VALID: {
      return {
        ...state,
        creditCardIsValid: action.isValid,
      }
    }
    default:
      return state
  }
}

export default reducer
