import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ExternalLink from 'common/components/ExternalLink'

import './styles.scss'

class MerchCard extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    onClick: PropTypes.func,
  }

  static defaultProps = {
    item: {},
    onClick: () => null,
  }

  render() {
    const { item, onClick } = this.props

    return (
      <div className='merch-card'>
        {item?.image && (
          <ExternalLink
            className='merch-card__link'
            data-featured-merch={item.title}
            href={item.url}
            onClick={onClick}
          >
            <div
              className='merch-card__image'
              style={{ backgroundImage: `url('${item.image.src}')` }}
            />
            <p className='merch-card__info'>{item.title}</p>
          </ExternalLink>
        )}
      </div>
    )
  }
}

export default MerchCard
