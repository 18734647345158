import { notice } from 'common/helpers'

/**
 * Filter a list of files by size; trigger notices for too-large files
 * @param {FileList} sourceFiles - list of files to filter
 * @returns {Array}
 * TODO: add options for things like file size, file type
 */
export function restrictFiles(sourceFiles) {
  const files = Array.from(sourceFiles)
  const updatedFiles = []
  let skippedFile = false
  for (const file of files) {
    if (file.size < 21.243e6) {
      // 21mb
      updatedFiles.push(file)
    } else {
      skippedFile = true
    }
  }

  if (skippedFile) {
    notice({
      iconColor: 'red',
      header: '',
      body: 'Skipped file. File is too large.',
    })
  }

  return updatedFiles
}
