import React from 'react'

import fixedGroups from 'common/helpers/fixedGroups'

import WrappedCommunityGroup from './tabs/shared/WrappedCommunityGroup'
import WrappedEpisodeCard from './tabs/shared/WrappedEpisodeCard'
import WrappedShowCard from './tabs/shared/WrappedShowCard'

const Column = ({ children, title1, title2 }) => (
  <div className='search-v2-recommendations__column'>
    <div className='search-v2-recommendations__column-header'>
      <div className='search-v2-recommendations__column-header-title-1'>
        {title1}
      </div>
      <div className='search-v2-recommendations__column-header-title-2'>
        {title2}
      </div>
    </div>
    <div className='search-v2-recommendations__column-contents'>{children}</div>
  </div>
)

const Recommendations = ({
  closeSearchV2,
  recommendedEpisodes,
  recommendedSeries,
  searchTerm,
}) => (
  <div className='search-v2-recommendations'>
    <Column title1='Top searched' title2='Series'>
      <div className='search-v2-recommendations__series-column'>
        {recommendedSeries?.map(series => (
          <WrappedShowCard
            closeSearchV2={closeSearchV2}
            key={series.uuid}
            series={series}
            searchTerm={searchTerm}
            tab='recommended'
          />
        ))}
      </div>
    </Column>
    <Column title1='Top searched' title2='Episodes'>
      <div className='search-v2-recommendations__episodes-column'>
        {recommendedEpisodes?.map(episode => (
          <WrappedEpisodeCard
            key={episode.uuid}
            closeSearchV2={closeSearchV2}
            episode={episode}
            searchTerm={searchTerm}
            tab='recommended'
          />
        ))}
      </div>
    </Column>
    <Column title1='Explore our' title2='Groups'>
      <div className='search-v2-recommendations__groups-column'>
        {fixedGroups?.map(group => (
          <WrappedCommunityGroup
            key={group.uuid}
            closeSearchV2={closeSearchV2}
            group={group}
            searchTerm={searchTerm}
            tab='recommended'
          />
        ))}
      </div>
    </Column>
  </div>
)

export default Recommendations
