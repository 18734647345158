import rtApi from '@roosterteethproductions/svod-api'

import { HTTP_RT_INVALID_UPSTREAM_API_TOKEN } from 'common/helpers'

import { logoutSuccess } from 'auth/actions/loginActions'

import {
  GET_GENERATED_USERNAMES_FAILURE,
  GET_GENERATED_USERNAMES_REQUEST,
  GET_GENERATED_USERNAMES_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  PUT_USER_FAILURE,
  PUT_USER_REQUEST,
  PUT_USER_SETTINGS_FAILURE,
  PUT_USER_SETTINGS_REQUEST,
  PUT_USER_SETTINGS_SUCCESS,
  PUT_USER_SUCCESS,
} from './types'

// Get User Actions

export const getUserRequest = () => ({
  type: GET_USER_REQUEST,
})

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  user,
})

export const getUserError = message => ({
  type: GET_USER_FAILURE,
  message: `${message}`, // cast to string
})

/**
 * Fetches the latest user object from RT's Business service and dispatches `getUserSuccess` with the object. While other actions modify user information across various backend services and return user objects, this user object is the "source of truth" and should be called if the most up-to-date user information is needed.
 *
 * @returns {Promise<undefined>} A promise that resolves to undefined. The purpose of the function is to dispatch on success or errors, so its promise simply indicates that this has happened, but it does not return anything of value.
 */
export const getUserObject = () => (dispatch, _getState) => {
  dispatch(getUserRequest())
  return rtApi.users
    .fetchMyUser()
    .then(json => Promise.resolve(dispatch(getUserSuccess(json))))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      dispatch(getUserError(ex))
      return Promise.reject(new Error())
    })
}

const getGeneratedUsernamesRequest = () => ({
  type: GET_GENERATED_USERNAMES_REQUEST,
})

const getGeneratedUsernamesSuccess = ({ usernames }) => ({
  type: GET_GENERATED_USERNAMES_SUCCESS,
  usernames,
})

const getGeneratedUsernamesError = message => ({
  type: GET_GENERATED_USERNAMES_FAILURE,
  message: String(message),
})

export const getGeneratedUsernames = () => (dispatch, _getState) => {
  dispatch(getGeneratedUsernamesRequest())

  return rtApi.users
    .generateUsernames()
    .then(json => Promise.resolve(getGeneratedUsernamesSuccess(json)))
    .catch(ex => {
      dispatch(getGeneratedUsernamesError(ex))

      return Promise.reject(new Error())
    })
}

export const postValidateAttributes = ({ password, username }) => (
  _dispatch,
  _getState
) => rtApi.users.validate({ username, password })

export const putUserRequest = () => ({
  type: PUT_USER_REQUEST,
})

export const putUserSuccess = user => ({
  type: PUT_USER_SUCCESS,
  user,
})

export const putUserFailure = ({ message }) => ({
  type: PUT_USER_FAILURE,
  message,
})

export const putUser = (user, errorCallback) => (dispatch, getState) => {
  const state = getState()
  const { id } = state.authReducer.user

  dispatch(putUserRequest())
  return rtApi.users
    .update({ id, user, errorCallback })
    .then(json => Promise.resolve(dispatch(putUserSuccess(json))))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(putUserFailure(ex)))
    })
}

/* Settings */
const putUserSettingsRequest = () => ({
  type: PUT_USER_SETTINGS_REQUEST,
})

const putUserSettingsSuccess = user => ({
  type: PUT_USER_SETTINGS_SUCCESS,
  user,
})

const putUserSettingsFailure = ({ message }) => ({
  type: PUT_USER_SETTINGS_FAILURE,
  message,
})

export const putUserSettings = (preferences, errorCallback) => (
  dispatch,
  getState
) => {
  const state = getState()
  const { id } = state.authReducer.user

  dispatch(putUserSettingsRequest())
  return rtApi.users
    .preferences({ id, preferences, errorCallback })
    .then(json => Promise.resolve(dispatch(putUserSettingsSuccess(json))))
    .catch(ex => {
      if (ex.status === HTTP_RT_INVALID_UPSTREAM_API_TOKEN) {
        dispatch(logoutSuccess())
      }
      return Promise.reject(dispatch(putUserSettingsFailure(ex)))
    })
}
