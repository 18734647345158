import React, { useCallback } from 'react'

import { a11yButtonProps } from 'common/helpers/accessibility'

import { RTDropdown } from '../RTDropdown'

import './styles.scss'

const ListItem = ({ display, onClick, divider, selected, value }) => {
  const handleClick = useCallback(() => onClick(value), [onClick, value])

  if (divider) return <li className='rt-dropdown-list-divider' />

  return (
    <li className={selected ? 'selected' : ''}>
      <div {...a11yButtonProps(handleClick)}>{display}</div>
    </li>
  )
}

const RTDropdownList = ({
  ariaLabel,
  children,
  onClick,
  options = [],
  position,
  selected,
}) => (
  <RTDropdown
    triggerElement={({ isOpened, open, ref }) => (
      <div
        aria-label={ariaLabel}
        className='rt-dropdown-list-button'
        ref={ref}
        {...a11yButtonProps(open)}
      >
        {children}{' '}
        {isOpened ? (
          <i className='icon icon-chevron-up' />
        ) : (
          <i className='icon icon-chevron-down' />
        )}
      </div>
    )}
    minWidth={175}
    position={position || 'bottom'}
    dropdownContents={
      <ul className='rt-dropdown-list'>
        {options.map(({ display, divider, value }, index) => (
          <ListItem
            key={divider ? `divider-${index}` : value}
            display={display}
            divider={divider}
            onClick={onClick}
            selected={selected === value}
            value={value}
          />
        ))}
      </ul>
    }
  />
)

export default RTDropdownList
