import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const PaginationIcons = ({ totalPages = 0, currentPage = 0 }) => (
  <ul className='pagination-icons'>
    {[...Array(totalPages)].map((x, i) => (
      <li
        key={`pag${totalPages / i}`}
        className={i === currentPage ? 'active' : ''}
      />
    ))}
  </ul>
)

PaginationIcons.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default PaginationIcons
