import React, { useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectRandomAvatar } from 'common/actions/avatarActions'

import { showModal } from 'modalApp/actions'

import {
  EditAvatarAvatar,
  EditAvatarBase,
  EditAvatarButton,
  EditAvatarIcon,
} from './styled'

export function EditAvatar({
  className,
  onSuccessOverride,
  shouldRandomizeOnInit,
  selectRandomAvatar,
  showModal,
  size,
  src,
}) {
  useEffect(
    () => {
      if (shouldRandomizeOnInit) selectRandomAvatar()
    },
    [selectRandomAvatar, shouldRandomizeOnInit]
  )

  return (
    <EditAvatarBase
      className={classnames('qa-edit-avatar', className)}
      size={size}
    >
      <EditAvatarAvatar size={size} src={src} />
      <EditAvatarButton
        onClick={() => {
          showModal('USER_AVATAR', { onSuccessOverride })
        }}
      >
        <span className='visuallyhidden'>Edit Avatar</span>
        <EditAvatarIcon />
      </EditAvatarButton>
    </EditAvatarBase>
  )
}

EditAvatar.propTypes = {
  className: PropTypes.string,
  selectRandomAvatar: PropTypes.func.isRequired,
  shouldRandomizeOnInit: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  size: PropTypes.number,
  src: PropTypes.string,
}

EditAvatar.defaultProps = {
  shouldRandomizeOnInit: false,
}

const mapDispatchToProps = {
  showModal,
  selectRandomAvatar,
}

export default connect(
  null,
  mapDispatchToProps
)(EditAvatar)
