/* eslint-disable no-return-assign */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { showModal } from 'modalApp/actions'

import SideNavComp from '../components/side-nav'

const MAX_COMMUNITIES_DISPLAYED = 3
// import './App.scss'

class SideNav extends Component {
  static propTypes = {}

  createCommunity(e) {
    e.preventDefault()
    this.props.create()
  }

  render() {
    return (
      <SideNavComp
        featureFlags={this.props.featureFlags}
        className={this.props.className}
        memberOf={this.props.memberOf}
        moderatorOf={this.props.moderatorOf}
        totalMemberships={this.props.totalMemberships}
        invites={this.props.invites}
        totalInvites={this.props.totalInvites}
        totalModeratorships={this.props.totalModeratorships}
        communityBase={this.props.communityBase}
        createCommunity={e => this.createCommunity(e)}
        user={this.props.user}
      />
    )
  }
}

function mapStateToProps(state) {
  const { memberships, invites } = state.communityReducer
  const { featureFlags } = state.commonReducer
  const { user } = state.authReducer
  const memberOf = []
  const moderatorOf = []
  for (const membership of memberships.list) {
    if (membership.moderator) {
      moderatorOf.push(membership)
    } else {
      memberOf.push(membership)
    }
  }

  return {
    featureFlags,
    moderatorOf: moderatorOf.slice(0, MAX_COMMUNITIES_DISPLAYED),
    memberOf: memberOf.slice(0, MAX_COMMUNITIES_DISPLAYED),
    // this is a lie, we do not have totals from the db returned
    totalMemberships: memberOf.length,
    invites: invites.list.slice(0, MAX_COMMUNITIES_DISPLAYED),
    totalInvites: invites.total,
    totalModeratorships: moderatorOf.length,
    communityBase: state.router.location.pathname,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    create: () => dispatch(showModal('COMMUNITY_CREATE')),
    getInvitations: () => dispatch({ type: 'communityApp/INVITES' }),
    getMemberships: () => dispatch({ type: 'communityApp/MEMBERSHIPS' }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav)
