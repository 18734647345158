import { endpoints } from 'common/config'

// Community APIs don't exist in rtApi yet
export const fetchUserSearch = ({ term }) =>
  fetch(`${endpoints.communityBase}/v1/users/search?q=${term}&limit=100`).then(
    resp => resp.json()
  )

export const fetchGroupSearch = async ({ term }) => {
  const groups = await fetch(
    `${endpoints.communityBase}/v1/communities/search?q=${term}`
  )
    .then(resp => resp.json())
    .then(json => json.data)

  return { data: groups, total_results: groups.length }
}
