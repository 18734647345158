import rtApi from '@roosterteethproductions/svod-api'

import { getEpisodePercentages } from 'common/helpers'

import {
  GET_SEASON_EPS_FAILURE,
  GET_SEASON_EPS_PERCENT_FAILURE,
  GET_SEASON_EPS_PERCENT_REQUEST,
  GET_SEASON_EPS_PERCENT_SUCCESS,
  GET_SEASON_EPS_REQUEST,
  GET_SEASON_EPS_SUCCESS,
  GET_SEASONS_FAILURE,
  GET_SEASONS_REQUEST,
  GET_SEASONS_SUCCESS,
} from './types'

// Season episodes functions

export const requestSeasons = showId => ({
  type: GET_SEASONS_REQUEST,
  showId,
})

export const requestSeasonEpisodes = seasonId => ({
  type: GET_SEASON_EPS_REQUEST,
  seasonId,
})

const receiveSeasons = (showId, items) => ({
  type: GET_SEASONS_SUCCESS,
  showId,
  items: items.data,
})

const receiveSeasonEpisodes = (seasonId, items) => ({
  type: GET_SEASON_EPS_SUCCESS,
  seasonId,
  items: items.data,
  total: items.total_results,
})

const fetchSeasonsFailure = ex => ({
  type: GET_SEASONS_FAILURE,
  error: ex.message,
})

const fetchSeasonEpisodesFailure = ex => ({
  type: GET_SEASON_EPS_FAILURE,
  error: ex.message,
})

export const fetchSeasonEpisodes = (seasonLinks, limit) => {
  const { seasonLink, seasonSlug } = seasonLinks
  const order = seasonLink.split('=')[1]
  return dispatch => {
    dispatch(requestSeasonEpisodes(seasonLinks.seasonSlug))
    rtApi.seasons
      .fetchEpisodes({ id: seasonSlug, order, perPage: limit })
      .then(json =>
        dispatch(receiveSeasonEpisodes(seasonLinks.seasonSlug, json))
      )
      .then(json => dispatch(fetchSeasonEpisodesPercentage(json)))
      .catch(ex => dispatch(fetchSeasonEpisodesFailure(ex)))
  }
}

export const fetchSeasons = (showId, showLink) => {
  const order = showLink.split('=')[1]

  return dispatch => {
    dispatch(requestSeasons(showId))
    rtApi.shows
      .fetchSeasons({ id: showId, order })
      .then(json => dispatch(receiveSeasons(showId, json)))
      .catch(ex => dispatch(fetchSeasonsFailure(ex)))
  }
}

// Get SeasonEpisodes Watched percentage
const requestSeasonEpisodesPercent = () => ({
  type: GET_SEASON_EPS_PERCENT_REQUEST,
})

const receiveSeasonEpisodesPercent = items => ({
  type: GET_SEASON_EPS_PERCENT_SUCCESS,
  items,
})

const fetchSeasonEpisodesPercentFailure = ex => ({
  type: GET_SEASON_EPS_PERCENT_FAILURE,
  error: ex.message,
})

export const fetchSeasonEpisodesPercentage = seasonData =>
  getEpisodePercentages({
    failureAction: fetchSeasonEpisodesPercentFailure,
    items: seasonData.items,
    requestAction: requestSeasonEpisodesPercent,
    successAction: receiveSeasonEpisodesPercent,
  })
