import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CreatePostBtn } from 'common/components'
import { isTimeInFuture, notice } from 'common/helpers'

import { showModal } from 'modalApp/actions'

export class CreatePostContainer extends Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  static defaultProps = {
    user: null,
  }

  handleClick = () => {
    const { postText, showModal, topicId, user } = this.props
    const post = postText ? { body: postText } : null

    if (!user) {
      showModal('USER_LOGIN', { topicId })
      return
    }

    if (user && !user?.attributes?.email_verified) {
      showModal('VERIFY_EMAIL')
      return
    }

    if (isTimeInFuture(user?.attributes?.banned_until)) {
      this.notifyBannedUser()
      return
    }

    showModal('CREATE_POST', { topicId, post })
  }

  notifyBannedUser = () => {
    const bannedUntil = moment(
      this.props.user?.attributes?.banned_until
    ).format('MM-DD-YYYY')

    notice({
      body: `You are banned until ${bannedUntil}`,
      header: 'Error',
      icon: 'icon-close2',
      iconColor: 'red',
    })
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { postText, showModal, topicId, user, ...moreProps } = this.props

    return <CreatePostBtn bg='dark' onClick={this.handleClick} {...moreProps} />
  }
}

const mapStateToProps = (state, _ownProps) => {
  const { user } = state.authReducer

  return {
    user,
  }
}

const mapDispatchToProps = {
  showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePostContainer)
