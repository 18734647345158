import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const AvatarLinkBase = styled(Link)`
  display: inline-flex;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`
