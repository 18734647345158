import { all, take } from 'redux-saga/effects'
import { admin } from './admin'
import { autocomplete } from './autocomplete'
import { browse } from './browse'
import { communityV2 } from './communityV2.js'
import { communityUser } from './community-user.js'
import { create } from './create'
import { log } from '../sub-community/sagas/log'
import { memberships } from './memberships'
import { mute } from './mute'
import { related } from '../sub-community/sagas/related'
import { settings } from './settings'
import { subCommunity } from '../sub-community/sagas/sub-community'
import { taggedPosts } from './taggedPosts'
import { userSearch } from './user-search'

export function* community() {
  yield all([
    communityV2(),
    admin(),
    autocomplete(),
    browse(),
    communityUser(),
    create(),
    log(),
    memberships(),
    mute(),
    related(),
    settings(),
    subCommunity(),
    taggedPosts(),
    userSearch(),
  ])
}
