export function notice({
  icon = 'icon-check2',
  iconColor = 'green',
  hideTime = 3000,
  header,
  body, // sadly this has to be a string until we switch off of materialize
  classes = '',
}) {
  const toast = `
    <span class='toast-icon ${icon} ${iconColor} circle'></span>
    <div class='toast-body'>
      ${header ? `<small class='toast-header'>${header}</small>` : ''}
      <div>${body}</div>
    </div>
  `
  window.M.toast({
    classes: `toast-ghost toast-with-icon ${classes}`,
    displayLength: hideTime,
    html: toast,
  })
}
